"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _accounts = require("@/api/accounts");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _system = require("@/api/system");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      form: {
        uid: '',
        amount: '',
        postscript: '',
        remark: ''
      },
      rules: {
        uid: [{
          required: true,
          message: '请选择用户'
        }],
        amount: [{
          required: true,
          message: '请输入金额'
        }]
      },
      userList: [],
      searchLoading: false,
      myHeaders: {
        "X-Token": (0, _auth.getToken)()
      }
    };
  },
  computed: {
    fileUrl: function fileUrl() {
      return _settingMer.default.https + "/excel/import";
    }
  },
  methods: {
    // 下载
    downLoad: function downLoad() {
      this.$downloadFile({
        url: _settingMer.default.https + "/excel/templateDownload?type=import_sandpay_transfer&token=" + (0, _auth.getToken)(),
        name: 'template.xlsx'
      });
    },
    // 上传成功
    handleSuccess: function handleSuccess(response) {
      if (response.status === 200) {
        this.$message.success('上传成功');
        this.getList();
      } else {
        this.$message.error(response.message);
      }
      this.$refs.uploadImg.clearFiles();
    },
    searchUserAction: function searchUserAction(data) {
      var _this = this;
      var params = {
        keyword: data,
        page: 1,
        size: 10
      };
      (0, _user.userLstApi)(params).then(function (res) {
        _this.userList = res.data.list;
      });
    },
    onSubmit: function onSubmit() {
      var _this2 = this;
      this.$confirm("\u786E\u8BA4\u8F6C\u8D26?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _accounts.sandtransferPay)(_this2.form).then(function () {
          _this2.$message.success('转账成功');
          _this2.form = {
            uid: '',
            amount: '',
            postscript: '',
            remark: ''
          };
        }).catch(function (message) {});
      }).catch(function () {});
    }
  }
};