var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          staticStyle: { "font-weight": "bold" },
          attrs: { "label-width": "", "label-position": "left" },
        },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              [
                _c(
                  "el-form-item",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: { width: "60px", display: "inline-block" },
                      },
                      [_vm._v("成交收费")]
                    ),
                    _vm._v(" "),
                    _c("el-switch", {
                      staticStyle: { width: "40px" },
                      model: {
                        value: _vm.inventoryConfig.switch.sold_price_switch,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.inventoryConfig.switch,
                            "sold_price_switch",
                            $$v
                          )
                        },
                        expression: "inventoryConfig.switch.sold_price_switch",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.inventoryConfig.switch.sold_price_switch
                  ? _vm._l(
                      _vm.inventoryConfig.price.sold_price,
                      function (item, index) {
                        return _c(
                          "el-form-item",
                          { key: "C_" + index },
                          [
                            _c("el-input", {
                              staticStyle: { width: "120px" },
                              attrs: {
                                placeholder: "自定义名称",
                                disabled: true,
                              },
                              model: {
                                value: item.name,
                                callback: function ($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "80px" },
                              model: {
                                value: item.number,
                                callback: function ($$v) {
                                  _vm.$set(item, "number", $$v)
                                },
                                expression: "item.number",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100px" },
                                model: {
                                  value: item.unit,
                                  callback: function ($$v) {
                                    _vm.$set(item, "unit", $$v)
                                  },
                                  expression: "item.unit",
                                },
                              },
                              [
                                _c(
                                  "el-option",
                                  { attrs: { value: 1, label: "元/件" } },
                                  [_vm._v("元/件")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-option",
                                  { attrs: { value: 2, label: "%" } },
                                  [_vm._v("%落槌价")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-option",
                                  { attrs: { value: 3, label: "元/件" } },
                                  [_vm._v("元/版")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    )
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-left": "40px" } },
              [
                _c(
                  "el-form-item",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          width: "100px",
                          display: "inline-block",
                        },
                      },
                      [_vm._v("未成交收费")]
                    ),
                    _vm._v(" "),
                    _c("el-switch", {
                      staticStyle: { width: "40px" },
                      model: {
                        value: _vm.inventoryConfig.switch.unsold_price_switch,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.inventoryConfig.switch,
                            "unsold_price_switch",
                            $$v
                          )
                        },
                        expression:
                          "inventoryConfig.switch.unsold_price_switch",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.inventoryConfig.switch.unsold_price_switch
                  ? _vm._l(
                      _vm.inventoryConfig.price.unsold_price,
                      function (item, index) {
                        return _c(
                          "el-form-item",
                          { key: "U_" + index },
                          [
                            _c("el-input", {
                              staticStyle: { width: "120px" },
                              attrs: { placeholder: "自定义名称" },
                              model: {
                                value: item.name,
                                callback: function ($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "80px" },
                              model: {
                                value: item.number,
                                callback: function ($$v) {
                                  _vm.$set(item, "number", $$v)
                                },
                                expression: "item.number",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100px" },
                                model: {
                                  value: item.unit,
                                  callback: function ($$v) {
                                    _vm.$set(item, "unit", $$v)
                                  },
                                  expression: "item.unit",
                                },
                              },
                              [
                                _c(
                                  "el-option",
                                  { attrs: { value: 1, label: "元/件" } },
                                  [_vm._v("元/件")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-option",
                                  { attrs: { value: 2, label: "%" } },
                                  [_vm._v("%保留价")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-option",
                                  { attrs: { value: 3, label: "元/件" } },
                                  [_vm._v("元/版")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    )
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-left": "40px" } },
              [
                _c(
                  "el-form-item",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          width: "100px",
                          display: "inline-block",
                        },
                      },
                      [_vm._v("其他费用")]
                    ),
                    _vm._v(" "),
                    _c("el-switch", {
                      staticStyle: { width: "40px" },
                      model: {
                        value: _vm.inventoryConfig.switch.other_price_switch,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.inventoryConfig.switch,
                            "other_price_switch",
                            $$v
                          )
                        },
                        expression: "inventoryConfig.switch.other_price_switch",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.inventoryConfig.switch.other_price_switch
                  ? _vm._l(
                      _vm.inventoryConfig.price.other_price,
                      function (item, index) {
                        return _c(
                          "el-form-item",
                          { key: "o_" + index },
                          [
                            _c("el-input", {
                              staticStyle: { width: "120px" },
                              attrs: { placeholder: "自定义名称" },
                              model: {
                                value: item.name,
                                callback: function ($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "80px" },
                              model: {
                                value: item.number,
                                callback: function ($$v) {
                                  _vm.$set(item, "number", $$v)
                                },
                                expression: "item.number",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100px" },
                                model: {
                                  value: item.unit,
                                  callback: function ($$v) {
                                    _vm.$set(item, "unit", $$v)
                                  },
                                  expression: "item.unit",
                                },
                              },
                              [
                                _c(
                                  "el-option",
                                  { attrs: { value: 1, label: "元/件" } },
                                  [_vm._v("元/件")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-option",
                                  { attrs: { value: 3, label: "元/件" } },
                                  [_vm._v("元/版")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "formFixedBox" },
        [
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary" },
              on: { click: _vm.saveconfig },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }