"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchCreateApi = batchCreateApi;
exports.batchEditMarketMediaOperateApi = batchEditMarketMediaOperateApi;
exports.batchEditMarketMediaTagApi = batchEditMarketMediaTagApi;
exports.checkPromotionAccountApi = checkPromotionAccountApi;
exports.checkPromotionAccountLoginApi = checkPromotionAccountLoginApi;
exports.copyMakeApi = copyMakeApi;
exports.createAuctionCutApi = createAuctionCutApi;
exports.createDefaultChannelsApi = createDefaultChannelsApi;
exports.createDefaultMediaApi = createDefaultMediaApi;
exports.createVenueMediaApi = createVenueMediaApi;
exports.deleteMediaApi = deleteMediaApi;
exports.deletePromotionAccountApi = deletePromotionAccountApi;
exports.editFieldValueApi = editFieldValueApi;
exports.editPromotionAccountApi = editPromotionAccountApi;
exports.generateMarketTweetAuctionColorMatchingApi = generateMarketTweetAuctionColorMatchingApi;
exports.generateMarketTweetImg = generateMarketTweetImg;
exports.generateMarketTweetImgDetail = generateMarketTweetImgDetail;
exports.generateMarketTweetTextIntro = generateMarketTweetTextIntro;
exports.getAlbumIntroDataApi = getAlbumIntroDataApi;
exports.getArtAiTemplateListApi = getArtAiTemplateListApi;
exports.getAuctionCutApi = getAuctionCutApi;
exports.getChannelsTypeApi = getChannelsTypeApi;
exports.getIceVideoBackgroundMusicListApi = getIceVideoBackgroundMusicListApi;
exports.getIceVideoFontListApi = getIceVideoFontListApi;
exports.getIceVideoVoiceListApi = getIceVideoVoiceListApi;
exports.getMarketMediaConfigApi = getMarketMediaConfigApi;
exports.getMarketTweetImgLoginStatus = getMarketTweetImgLoginStatus;
exports.getMarketTweetImgStatus = getMarketTweetImgStatus;
exports.getMarketTweetTextField = getMarketTweetTextField;
exports.getMediaTypeApi = getMediaTypeApi;
exports.getMerchantTokenApi = getMerchantTokenApi;
exports.getOrderDetailApi = getOrderDetailApi;
exports.getPromotionAccountDetailApi = getPromotionAccountDetailApi;
exports.getPromotionAccountListApi = getPromotionAccountListApi;
exports.getVenuelntroDataApi = getVenuelntroDataApi;
exports.getVideoGenerateDataApi = getVideoGenerateDataApi;
exports.groupListApi = groupListApi;
exports.iceVideoVoiceListApi = iceVideoVoiceListApi;
exports.makeSameApi = makeSameApi;
exports.marketMediaDetailApi = marketMediaDetailApi;
exports.orderUpdApi = orderUpdApi;
exports.promoDeleApi = promoDeleApi;
exports.promoUpdateApi = promoUpdateApi;
exports.promotionAccountTopApi = promotionAccountTopApi;
exports.promotionChannelsApi = promotionChannelsApi;
exports.promotionChannelsDeleApi = promotionChannelsDeleApi;
exports.promotionChannelsUpdApi = promotionChannelsUpdApi;
exports.promotionCreateApi = promotionCreateApi;
exports.promotionListApi = promotionListApi;
exports.promotionStatisticsListApi = promotionStatisticsListApi;
exports.recoverMakeApi = recoverMakeApi;
exports.releaseDeleteApi = releaseDeleteApi;
exports.releaseListApi = releaseListApi;
exports.releaseUpdateApi = releaseUpdateApi;
exports.releasedApi = releasedApi;
exports.saveMarketMediaConfigApi = saveMarketMediaConfigApi;
exports.setVenueMediaAuctionIdsApi = setVenueMediaAuctionIdsApi;
exports.venueMediaListApi = venueMediaListApi;
exports.videoGenerateApi = videoGenerateApi;
exports.videoListApi = videoListApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 拍卖会制作列表
 */
function venueMediaListApi(data) {
  return _request.default.get("marketMedia/list", data);
  // return request.get(`marketMedia/venueMediaList`, data);
}

/**
 * @description 拍卖会制作列表
 */
function videoListApi(data) {
  return _request.default.get("marketMedia/videoList", data);
}

/**
 * @description 创建制作
 */
function createVenueMediaApi(data) {
  return _request.default.post("marketMedia/createVenueMedia", data);
}

/**
 * @description 设置制作
 */
function setVenueMediaAuctionIdsApi(data) {
  return _request.default.post("marketMedia/setVenueMediaAuctionIds", data);
}

/**
 * @description 视频生成
 */
function videoGenerateApi(data) {
  return _request.default.post("marketMedia/videoGenerate", data);
}

/**
 * @description 获取视频生成数据
 */
function getVideoGenerateDataApi(data) {
  return _request.default.post("marketMedia/getVideoGenerateData", data);
}

/**
 * @description 获取推广渠道分组列表
 */
function groupListApi(data) {
  return _request.default.get("marketMedia/promotionChannels/list", data);
}

/**
 * @description 任务列表
 */
function promotionListApi(data) {
  return _request.default.get("marketMedia/promotion/list", data);
}

/**
 * @description 推广计划
 */
function promotionStatisticsListApi(data) {
  return _request.default.get("marketMedia/promotion/statistics", data);
}

/**
 * @description 添加推广渠道
 */
function promotionCreateApi(data) {
  return _request.default.post("marketMedia/promotionChannels/create", data);
}

/**
 * @description 批量创建推广任务
 */
function batchCreateApi(data) {
  return _request.default.post("marketMedia/promotion/batchCreate", data);
}

/**
 * @description 发布推广任务
 */
function releasedApi(data) {
  return _request.default.post("marketMedia/promotion/released", data);
}

/**
 * @description 复制营销矩阵
 */
function copyMakeApi(data) {
  return _request.default.post("marketMedia/copyMake", data);
}

/**
 * @description 推广计划
 */
function marketMediaDetailApi(id) {
  return _request.default.get("marketMedia/detail/".concat(id));
}

/**
 * @description 编辑字段值
 */
function editFieldValueApi(id, data) {
  return _request.default.post("marketMedia/editFieldValue/".concat(id), data);
}

/**
 * @description 获取视频制作模板
 */
function getArtAiTemplateListApi(data) {
  return _request.default.get("marketMedia/getArtAiTemplateList", data);
}

/**
 * @description 
 */
function getMediaTypeApi() {
  return _request.default.get("marketMedia/getMediaType");
}

/**
 * @description 获取推广渠道类型
 */
function getChannelsTypeApi() {
  return _request.default.get("marketMedia/promotionChannels/typeData");
}

/**
 * @description 删除推广任务
 */
function promoDeleApi(data) {
  return _request.default.post("marketMedia/promotion/delete", data);
}

/**
 * @description 更新推广任务
 */
function promoUpdateApi(id, data) {
  return _request.default.post("marketMedia/promotion/update/".concat(id), data);
}

/**
 * @description 创建默认计划
 */
function createDefaultMediaApi(data) {
  return _request.default.post("marketMedia/createDefaultMedia", data);
}

/**
 * @description 删除营销素材
 */
function deleteMediaApi(data) {
  return _request.default.post("marketMedia/delete", data);
}

/**
 * @description 修改推广渠道
 */
function promotionChannelsUpdApi(id, data) {
  return _request.default.post("marketMedia/promotionChannels/update/".concat(id), data);
}

/**
 * @description 删除资源
 */
function promotionChannelsDeleApi(id) {
  return _request.default.post("marketMedia/promotionChannels/delete/".concat(id));
}

/**
 * @description 修改推广渠道
 */
function createDefaultChannelsApi() {
  return _request.default.post("marketMedia/promotionChannels/createDefaultChannels", {});
}

/**
 * @description 编辑推广渠道
 */
function promotionChannelsApi(id, data) {
  return _request.default.post("marketMedia/promotionChannels/edit/".concat(id), data);
}

/**
 * @description 
 */
function getMarketMediaConfigApi() {
  return _request.default.get("config/getConfigClassKeys/marketMediaConfig");
}

/**
 * @description 
 */
function saveMarketMediaConfigApi(data) {
  return _request.default.post("config/save/marketMediaConfig", data);
}

/**
 * @description 
 */
function iceVideoVoiceListApi() {
  return _request.default.get("marketMedia/config/iceVideoVoiceList");
}

/**
 * @description 获取拍卖会相关文案
 */
function getVenuelntroDataApi(data) {
  return _request.default.get("/auction/statistical/getVenueIntroData", data);
}
/**
 * @description 获取专场相关文案
 */
function getAlbumIntroDataApi(data) {
  return _request.default.get("/auction/statistical/getAlbumIntroData", data);
}
/**
 * @description 获取字体列表
 */
function getIceVideoFontListApi() {
  return _request.default.get("/marketMedia/config/iceVideoFontList");
}
/**
 * @description 获取音色列表
 */
function getIceVideoVoiceListApi() {
  return _request.default.get("/marketMedia/config/iceVideoVoiceList");
}
/**
 * @description 获取背景音乐列表
 */
function getIceVideoBackgroundMusicListApi() {
  return _request.default.get("/marketMedia/config/iceVideoBackgroundMusicList");
}
/**
 * @description 拍品直播回放可剪辑的
 */
function getAuctionCutApi(data) {
  return _request.default.get("/marketMedia/getAuctionCutVideoRecordInfo", data);
}
/**
 * @description 剪辑拍品直播拍回放视频
 */
function createAuctionCutApi(data) {
  return _request.default.post("marketMedia/createAuctionCutVideoRecord", data);
}
/**
 * @description 制作同款
 */
function makeSameApi(data) {
  return _request.default.post("marketMedia/makeSame", data);
}

/**
 * @description AI 生成配色
 */
function generateMarketTweetAuctionColorMatchingApi(id, data) {
  return _request.default.post("/marketMedia/generateMarketTweetAuctionColorMatching/".concat(id), data);
}
/**
 * @description 恢复营销素材
 */
function recoverMakeApi(data) {
  return _request.default.post("marketMedia/recoverMake", data);
}
/**
 * @description 生成推文文字简介
 */
function generateMarketTweetTextIntro(id, data) {
  return _request.default.post("marketMedia/generateMarketTweetTextIntro/".concat(id), data);
}
/**
 * @description 生成图文
 */
function generateMarketTweetImg(data) {
  return _request.default.post("marketMedia/generatePoster", data);
}
/**
 * @description 图文字段
 */
function getMarketTweetTextField(data) {
  return _request.default.get("marketMedia/getPosterSelectField", data);
}
/**
 * @description 生成图文详情
 */
function generateMarketTweetImgDetail(id, data) {
  return _request.default.get("marketMedia/detail/".concat(id), data);
}
/**
 * @description 获取图文生成状态
 */
function getMarketTweetImgStatus(data) {
  return _request.default.get("marketMedia/getGeneratePosterStatus", data);
}
/**
 * @description 获取图文生成登录状态
 */
function getMarketTweetImgLoginStatus(data) {
  return _request.default.post("system/admin/loginPoster", data);
}

/**
 * @description 获取推广账号列表  
 */
function getPromotionAccountListApi(data) {
  return _request.default.get("marketMedia/promotionAccount/list", data);
}

/**
 * @description 推广账号置顶
 */
function promotionAccountTopApi(id, data) {
  return _request.default.post("marketMedia/promotionAccount/setTop/".concat(id), data);
}

/**
 * @description 检测推广账号状态
 */
function checkPromotionAccountApi(data) {
  return _request.default.post("marketMedia/promotionAccount/checkAccountStatus", data);
}

/**
 * @description 编辑推广账号
 */
function editPromotionAccountApi(id, data) {
  return _request.default.post("marketMedia/promotionAccount/update/".concat(id), data);
}

/**
 * @description 获取推广账号详情
 */
function getPromotionAccountDetailApi(id, data) {
  return _request.default.get("marketMedia/promotionAccount/detail/".concat(id), data);
}

/**
 * @description 一键检测登录状态
 */
function checkPromotionAccountLoginApi(data) {
  return _request.default.post("marketMedia/promotionAccount/checkAccountLoginStatus", data);
}

/**
 * @description 批量修改标签
 */
function batchEditMarketMediaTagApi(data) {
  return _request.default.post("marketMedia/promotionAccount/batchBindLabel", data);
}

/**
 * @description 批量修改运营人员
 */
function batchEditMarketMediaOperateApi(data) {
  return _request.default.post("marketMedia/promotionAccount/batchUpdateManagerAdmin", data);
}

/**
 * @description 删除推广账号
 */
function deletePromotionAccountApi(id, data) {
  return _request.default.post("marketMedia/promotionAccount/delete/".concat(id), data);
}

/**
 * @description
 */
function getOrderDetailApi(data) {
  return _request.default.get("marketMedia/order/detail", data);
}

/**
 * @description 
 */
function getMerchantTokenApi() {
  return _request.default.get("artart/merchant/getMerchantToken");
}

/**
 * @description 
 */
function orderUpdApi(data) {
  return _request.default.post("marketMedia/order/update", data);
}

/**
 * 一键发布
 */
function releaseListApi(data) {
  return _request.default.get("marketMedia/release/list", data);
}

/**
 * 修改一键发布
 */
function releaseUpdateApi(data) {
  return _request.default.post("marketMedia/release/update", data);
}

/**
 * 删除一键发布
 */
function releaseDeleteApi(data) {
  return _request.default.post("marketMedia/release/delete", data);
}