var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            "append-to-body": true,
            width: "1000px",
            "before-close": _vm.handleClose,
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "menuBox", attrs: { slot: "title" }, slot: "title" },
            [
              _c(
                "div",
                {
                  staticClass: "menuItem",
                  class: _vm.tabActive == "1" ? "activeMenu" : "",
                  on: {
                    click: function ($event) {
                      return _vm.changeTab("1")
                    },
                  },
                },
                [_vm._v("素材库")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "menuItem",
                  class: _vm.tabActive == "2" ? "activeMenu" : "",
                  on: {
                    click: function ($event) {
                      return _vm.changeTab("2")
                    },
                  },
                },
                [_vm._v("相关拍品")]
              ),
            ]
          ),
          _vm._v(" "),
          _vm.visible && _vm.tabActive == "1"
            ? _c("upload-index", {
                attrs: {
                  isMore: _vm.isMore,
                  changeType: _vm.changeType,
                  changeCategory: _vm.changeCategory,
                  attacCategoryId: _vm.attacCategoryId,
                },
                on: { getImage: _vm.getImage },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.visible && _vm.tabActive == "2"
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "flex auctionBox" },
                  _vm._l(_vm.auctionList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "aucItem",
                        class: item.product_id == _vm.product_id ? "on" : "",
                        on: {
                          click: function ($event) {
                            return _vm.changImage(item)
                          },
                        },
                      },
                      [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: item.product.image + "!m640",
                              expression: "`${item.product.image}!m640`",
                            },
                          ],
                          staticStyle: { "object-fit": "contain" },
                        }),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { "margin-top": "10px" } },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        "page-sizes": [12, 20, 40, 60, 100],
                        "page-size": _vm.tableData.limit,
                        "pager-count": 5,
                        "current-page": _vm.tableData.page,
                        layout: "total, sizes, prev, pager, next",
                        total: _vm.tableData.total,
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.pageChange,
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }