var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.item.hidden
    ? _c(
        "div",
        [
          _vm.hasOneShowingChild(_vm.item.children, _vm.item) &&
          (!_vm.onlyOneChild.children || _vm.onlyOneChild.noShowingChildren) &&
          !_vm.item.alwaysShow
            ? [
                _vm.onlyOneChild
                  ? _c(
                      "app-link",
                      {
                        attrs: { to: _vm.resolvePath(_vm.onlyOneChild.route) },
                      },
                      [
                        _c(
                          "el-menu-item",
                          {
                            class: {
                              "submenutype-title-noDropdown": !_vm.isNest,
                            },
                            attrs: {
                              index: _vm.resolvePath(
                                _vm.roterPre + "/" + _vm.onlyOneChild.route
                              ),
                            },
                          },
                          [
                            !_vm.onlyOneChild.pid
                              ? [
                                  _vm.onlyOneChild.icon_url
                                    ? _c("ytx-icon", {
                                        staticStyle: {
                                          "margin-right": "10px",
                                          width: "16px",
                                          height: "16px",
                                          position: "relative",
                                          bottom: "2px",
                                        },
                                        attrs: {
                                          icon:
                                            "icon-" +
                                            _vm.onlyOneChild.icon_url +
                                            (_vm.active ==
                                            "" + _vm.roterPre + _vm.item.route
                                              ? "act"
                                              : ""),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "subment-span" }, [
                                    _vm._v(_vm._s(_vm.onlyOneChild.menu_name)),
                                  ]),
                                ]
                              : [
                                  _c("svg-icon", {
                                    staticStyle: {
                                      "margin-right": "8px",
                                      width: "16px",
                                      height: "16px",
                                      position: "relative",
                                      bottom: "2px",
                                    },
                                    attrs: {
                                      iconClass:
                                        "icon-" + _vm.onlyOneChild.icon_url,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "subment-span" }, [
                                    _vm._v(_vm._s(_vm.onlyOneChild.menu_name)),
                                  ]),
                                ],
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _c(
                "el-submenu",
                {
                  ref: "subMenu",
                  attrs: {
                    index: _vm.resolvePath(_vm.roterPre + "/" + _vm.item.route),
                    "popper-class": "ytx-left-menu",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _vm.item
                        ? _c("item", {
                            attrs: {
                              "icon-ytx": _vm.item && _vm.item.icon_url,
                              title: _vm.item.menu_name.substring(0, 2),
                              icon_url: _vm.item && _vm.item.icon_url,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "flex",
                      class: _vm.menuType
                        ? "flex-col"
                        : "align-items-c flex-wrap",
                    },
                    _vm._l(_vm.item.children, function (child) {
                      return _c("sidebar-item", {
                        key: child.route,
                        staticClass: "nest-menu",
                        class: _vm.menuType ? "new-menu" : "",
                        attrs: {
                          "is-nest": true,
                          item: child,
                          "base-path": _vm.resolvePath(child.route),
                        },
                      })
                    }),
                    1
                  ),
                ],
                2
              ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }