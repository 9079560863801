var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
      style: _vm.menuType
        ? "height: 75px;line-height: 75px;margin-bottom: 10px;"
        : "",
    },
    [
      _c(
        "transition",
        { attrs: { name: "sidebarLogoFade" } },
        [
          _vm.collapse
            ? _c(
                "router-link",
                {
                  key: "collapse",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: _vm.roterPre + "/artai/consoleBoard" },
                },
                [
                  _vm.$store.getters.mer_logo
                    ? _c("img", {
                        staticClass: "sidebar-logo-small",
                        attrs: {
                          src: require("@/assets/images/ariailogo.png"),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                ]
              )
            : _c(
                "router-link",
                {
                  key: "expand",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: _vm.roterPre + "/artai/consoleBoard" },
                },
                [
                  _vm.$store.getters.mer_logo
                    ? _c("img", {
                        staticClass: "sidebar-logo-big",
                        attrs: {
                          src: require("@/assets/images/ariailogo.png"),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                ]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }