var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "dialog-mask" }, [
      _c("div", { staticClass: "dialog-wrapper" }, [
        _vm.title
          ? _c("div", { staticClass: "dialog-t" }, [
              _vm._v(_vm._s(_vm.title) + " "),
              _c("img", {
                staticStyle: { cursor: "pointer" },
                attrs: { src: require("@/assets/images/poster/close.png") },
                on: {
                  click: function ($event) {
                    return _vm.$emit("cancel")
                  },
                },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "dialog-content",
            class: { "no-title": !_vm.title, padding: _vm.isPadding },
          },
          [
            _vm._v("\n        " + _vm._s(_vm.content) + "\n        "),
            _vm._t("default"),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "dialog-button" }, [
          _c(
            "div",
            {
              staticClass: "confirm-btn",
              on: {
                click: function ($event) {
                  return _vm.$emit("confirm")
                },
              },
            },
            [_vm._v(_vm._s(_vm.confirmTxt))]
          ),
          _vm._v(" "),
          !_vm.noCancel
            ? _c(
                "div",
                {
                  staticClass: "cancel-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancel")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.cancelTxt))]
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }