"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.number.is-finite");
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    prop: String,
    label: String,
    width: [Number, String],
    minWidth: String,
    align: String,
    type: {
      type: String,
      default: ''
    },
    index: {
      type: [Number, Function],
      default: undefined
    },
    columnKey: {
      type: String,
      default: ''
    },
    fixed: {
      type: [String, Boolean],
      default: false
    },
    sortable: {
      type: [Boolean, String],
      default: false
    },
    sortMethod: {
      type: [Function],
      default: null
    },
    sortOrders: {
      type: Array,
      default: function _default() {
        return ['ascending', 'descending', null];
      }
    },
    resizable: {
      type: Boolean,
      default: true
    },
    formatter: {
      type: [Function],
      default: null
    },
    showOverflowTooltip: {
      type: Boolean,
      default: false
    },
    headerAlign: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    },
    labelClassName: {
      type: String,
      default: ''
    },
    selectable: {
      type: [Function],
      default: null
    },
    reserveSelection: {
      type: Boolean,
      default: false
    },
    searchKey: {
      type: String,
      default: ''
    },
    searchOtherKey: {
      type: String,
      default: ''
    },
    searchType: {
      type: String,
      default: 'input'
    },
    selectProp: {
      type: [Object, Boolean],
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    selectFilter: {
      type: Boolean,
      default: false
    },
    searchQuery: {
      type: [Object, String],
      default: ''
    },
    searchClear: {
      type: Boolean,
      default: false
    },
    options: {
      type: [Array, String],
      default: ''
    },
    permission: {
      type: String,
      default: ''
    },
    permissionColumn: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      hasDefault: false,
      hasHeader: false,
      openSetkey: '',
      topHeightClass: 'goTop15',
      defaultProps: {
        expandTrigger: 'click',
        multiple: false,
        checkStrictly: true,
        emitPath: true,
        lazy: false,
        value: 'value',
        label: 'label',
        children: 'children',
        disabled: 'disabled',
        leaf: 'leaf'
      },
      value: '',
      timeValue: [],
      cascaderValue: [],
      valueTwo: '',
      filteValue: ''
    };
  },
  watch: {
    searchQuery: function searchQuery(val) {
      if (!val[this.searchKey]) {
        this.value = '';
        this.valueTwo = '';
        this.timeValue = [];
        this.cascaderValue = [];
      }
    }
  },
  computed: {
    isChecked: function isChecked() {
      var _this = this;
      if (this.multiple) {
        var valueArr = this.value.split(',');
        var d = this.options.map(function (m) {
          var check = false;
          if (valueArr.indexOf("".concat(m.value)) !== -1) {
            check = true;
          }
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, m), {}, {
            check: check
          });
        });
        return d;
      } else {
        var _d = this.options.map(function (m) {
          var check = false;
          if (m.value === _this.value) {
            check = true;
          }
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, m), {}, {
            check: check
          });
        });
        return _d;
      }
    },
    searchShowValue: function searchShowValue() {
      var _this2 = this;
      // 搜索回显value换label
      if (!this.searchQuery || !this.searchQuery[this.searchKey]) {
        return '';
      }
      if (this.searchType == 'input' || this.searchType == 'date' || this.searchType == 'time') {
        return this.searchQuery[this.searchKey];
      } else if (this.searchType == 'inputPacker') {
        return "".concat(this.searchQuery[this.searchKey]).concat(this.searchOtherKey ? '-' + this.searchQuery[this.searchOtherKey] : '');
      } else if (this.searchType == 'radio') {
        if (this.multiple) {
          var d = this.options.map(function (m) {
            return m.value;
          });
          var valueArr = [];
          this.searchQuery[this.searchKey].split(',').forEach(function (m) {
            var index;
            if (Number.isFinite(Number(m))) index = d.indexOf(Number(m));else index = d.indexOf(m);
            if (index !== -1) {
              valueArr.push(_this2.options[index].label);
            }
          });
          return valueArr.length ? valueArr.join(',') : '';
        } else {
          var _d2 = this.options.map(function (m) {
            return m.value;
          });
          var index = _d2.indexOf(this.searchQuery[this.searchKey]);
          if (index === -1) {
            return '';
          }
          return this.options[index].label;
        }
      } else if (this.searchType == 'cascader') {
        var prop = Object.assign({}, this.defaultProps, this.selectProp);
        var data = {};
        for (var i = 0; i < this.options.length; i += 1) {
          var m = this.options[i];
          data[m[prop.value]] = m[prop.label];
          if (m[prop.children]) {
            for (var _i = 0; _i < m[prop.children].length; _i += 1) {
              var c = m[prop.children][_i];
              data[c[prop.value]] = c[prop.label];
              if (c[prop.children]) {
                for (var _i2 = 0; _i2 < c[prop.children].length; _i2 += 1) {
                  var child = c[prop.children][_i2];
                  data[child[prop.value]] = child[prop.label];
                }
              }
            }
          }
        }
        return this.searchQuery[this.searchKey].split(',').map(function (m) {
          return data[m];
        }).join(',');
      } else {
        return '';
      }
    },
    newProp: function newProp() {
      return this.prop.split('.');
    },
    hasSearchRecode: function hasSearchRecode() {
      return Object.keys(this.searchQuery).length > 0;
    }
  },
  mounted: function mounted() {
    this.hasDefault = !!this.$scopedSlots.default;
    this.hasHeader = !!this.$scopedSlots.header;
  },
  methods: {
    selectSearch: (0, _utils.debounce)(function () {
      this.$emit('selectFilterChange', this.filteValue);
    }, 300),
    // isChecked(val) {
    //   if (this.multiple) {
    //     return this.value.split(',').indexOf(`${val}`) !== -1
    //   } else {
    //     return this.value === val;
    //   }
    // },
    changeValue: function changeValue(index) {
      var m = this.options[index];
      var valueArr = "".concat(this.value).split(',');
      if (this.multiple) {
        var _index = valueArr.indexOf("".concat(m.value));
        if (_index === -1) {
          valueArr.push(m.value);
        } else {
          valueArr.splice(_index, 1);
        }
        this.value = valueArr.filter(function (val) {
          return !!val;
        }).join(',');
      } else {
        this.value = m.value;
      }
      this.search();
    },
    // onAfterEnter(str) {
    //   this.openSetkey = str;
    //   this.$nextTick(() => {
    //     setTimeout(() => {
    //       this.$refs[str].focus();
    //       this.$refs[str].select();
    //     }, 100)
    //   });
    // },
    inpFocus: function inpFocus(e, key) {
      if (key) this.openSetkey = key;
      e.currentTarget.select();
    },
    inpBlur: function inpBlur() {
      this.openSetkey = '';
    },
    setTop: function setTop() {
      if (this.hasSearchRecode) {
        this.topHeightClass = 'goTop50';
      } else {
        this.topHeightClass = 'goTop15';
      }
    },
    changeCascader: function changeCascader(e) {
      // 级联选择 多级
      var data = Object.assign({}, this.searchQuery);
      if (e.length) {
        data[this.searchKey] = e.join(',');
      } else {
        delete data[this.searchKey];
      }
      this.$emit('changeVal', data);
    },
    onchangeDate: function onchangeDate(e) {
      // 选择时间区间
      var data = Object.assign({}, this.searchQuery);
      if (e) {
        data[this.searchKey] = e.join('-');
      } else {
        delete data[this.searchKey];
      }
      this.$emit('changeVal', data);
    },
    onchangeTime: function onchangeTime(e) {
      // 选择时间区间
      var data = Object.assign({}, this.searchQuery);
      if (e) {
        data[this.searchKey] = e;
      } else {
        delete data[this.searchKey];
      }
      this.$emit('changeVal', data);
    },
    search: function search() {
      var data = Object.assign({}, this.searchQuery);
      if (this.value) {
        data[this.searchKey] = this.value;
        if (this.searchOtherKey) data[this.searchOtherKey] = this.valueTwo;
      } else {
        if (this.searchOtherKey) delete data[this.searchOtherKey];
        delete data[this.searchKey];
      }
      this.$emit('changeVal', data);
    },
    clearsearch: function clearsearch(key) {
      var data = Object.assign({}, this.searchQuery);
      this.value = '';
      delete data[key];
      if (this.searchOtherKey) delete data[this.searchOtherKey];
      if (this.selectFilter) {
        this.filteValue = '';
        this.$emit('selectFilterChange', '');
      }
      this.$emit('changeVal', data);
    },
    clearAll: function clearAll() {
      if (this.selectFilter) {
        this.filteValue = '';
        this.$emit('selectFilterChange', '');
      }
      this.$emit('changeVal', {});
    }
  }
};