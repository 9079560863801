var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.content.attrlist
      ? _c(
          "div",
          { staticClass: "attr-list mt20" },
          [
            _vm._l(_vm.content.attrlist, function (item, index) {
              return _c("div", { key: index, staticClass: "attr-item flex" }, [
                _c("div", { staticClass: "attr-name" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "attr-sort-image" }, [
                  index === 0
                    ? _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.goattrDown(index)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  index > 0
                    ? _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.goattrUp(index)
                          },
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("el-checkbox", {
                      model: {
                        value: item.selected,
                        callback: function ($$v) {
                          _vm.$set(item, "selected", $$v)
                        },
                        expression: "item.selected",
                      },
                    }),
                  ],
                  1
                ),
              ])
            }),
            _vm._v(" "),
            _c("div", { staticClass: "attr-item flex" }, [
              _c("div", { staticClass: "attr-name" }, [_vm._v("拍品图片")]),
              _vm._v(" "),
              _c("div", { staticClass: "attr-sort-image" }),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", {
                    model: {
                      value: _vm.content.auctionSlideFlag,
                      callback: function ($$v) {
                        _vm.$set(_vm.content, "auctionSlideFlag", $$v)
                      },
                      expression: "content.auctionSlideFlag",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }