"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
var _control = _interopRequireDefault(require("@/components/customPage/control.vue"));
var _choose = _interopRequireDefault(require("@/components/customPage/choose.vue"));
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
var _slider = _interopRequireDefault(require("@/components/customPage/slider.vue"));
var _select = _interopRequireDefault(require("@/components/hyperlinkSelect/select.vue"));
var _selectCategory = _interopRequireDefault(require("@/components/customChildren/selectCategory.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    control: _control.default,
    choose: _choose.default,
    colour: _colour.default,
    slider: _slider.default,
    selectCategory: _selectCategory.default,
    hyperlinkSelect: _select.default
  },
  data: function data() {
    return {
      styleData: [{
        value: 1,
        src: 'https://saas.cdn.yunzongbu.cn/image/20211013/b2b72c44fca1b113071328fcf9247bf0.png',
        active: true
      }, {
        value: 2,
        src: 'https://saas.cdn.yunzongbu.cn/image/20211013/9d8a65912aea9ad305ddf8b25c0af19d.png',
        active: true
      }, {
        value: 3,
        src: 'https://saas.cdn.yunzongbu.cn/image/20211013/a1ed8515c4c1e306187276cf908104ee.png',
        active: true
      }, {
        value: 4,
        src: 'https://saas.cdn.yunzongbu.cn/image/20211013/f1804c0972137bb5941213a464c2b966.png',
        active: true
      }, {
        value: 5,
        src: 'https://saas.cdn.yunzongbu.cn/image/20211013/23294ee4a8c84e4938455e8eff74f433.png',
        active: true
      }]
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    content: {
      get: function get() {
        return this.$store.getters.content;
      },
      set: function set(value) {
        var data = {
          key: 'content',
          value: value
        };
        this.$store.commit('finish/setAttribute', data);
      }
    },
    facade: {
      get: function get() {
        return this.$store.getters.facade;
      },
      set: function set(value) {
        var data = {
          key: 'facade',
          value: value
        };
        this.$store.commit('finish/setAttribute', data);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    if (!this.facade.isSet) {
      (0, _system.getConfigClassKeys)('weChatTemplateContactInformation').then(function (res) {
        if (res.data.contactInformation) {
          _this.content = JSON.parse(res.data.contactInformation);
          _this.facade.isSet = true;
        }
      });
    }
  },
  methods: {
    uploadIconAction: function uploadIconAction(res, file) {
      var _this2 = this;
      this.$modalUpload(function (img) {
        _this2.content.title_img = img[0];
      }, 1);
    },
    delImg: function delImg() {
      this.content.title_img = '';
    },
    setCategory: function setCategory() {
      this.$refs.setCategoryView.changeshow(this.content.morelink);
    },
    selectMessage: function selectMessage(data) {
      this.content.morelink = data.data;
    },
    addAction: function addAction(key) {
      if (!this.content[key] || this.content[key].length <= 0) {
        this.content[key] = [{
          name: '',
          address: '',
          other: ''
        }];
      } else {
        this.content[key] = this.content[key].concat({
          name: '',
          address: '',
          other: ''
        });
      }
    },
    delAction: function delAction(key, i) {
      this.content[key].splice(i, 1);
    }
  }
};