var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: {
          name: _vm.$route.query.name,
          "has-back": true,
          "is-fixed": true,
        },
      }),
      _vm._v(" "),
      _vm.key == "print_auction_order_bill_info"
        ? _c(
            "div",
            { staticClass: "print-view" },
            [_c("PrintAuctionOrderBillInfo")],
            1
          )
        : _vm.key == "print_auction_transaction_order_bill_info"
        ? _c(
            "div",
            { staticClass: "print-view" },
            [_c("PrintAuctionTransactionOrderBillInfo")],
            1
          )
        : _vm.key == "print_entrust_auction_end"
        ? _c(
            "div",
            { staticClass: "print-view" },
            [_c("PrintEntrustAuctionEnd")],
            1
          )
        : _vm.key == "print_erp_contract_check_auction"
        ? _c(
            "div",
            { staticClass: "print-view" },
            [_c("PrintErpContractCheckAuction")],
            1
          )
        : _vm.key == "print_entrust_auction_create"
        ? _c(
            "div",
            { staticClass: "print-view" },
            [_c("PrintEntrustAuctionCreate")],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }