var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "banner-control mb20",
          attrs: {
            model: _vm.content,
            "label-width": "90px",
            size: "mini",
            "label-position": "left",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "auction-select-add",
              on: { click: _vm.handleAdditem },
            },
            [
              _c("i", {
                staticClass: "el-icon-plus",
                staticStyle: { "margin-right": "8px" },
              }),
              _vm._v(" "),
              _c("a", { staticClass: "fontW500 ft16" }, [_vm._v("选择专场")]),
            ]
          ),
          _vm._v(" "),
          _vm.content.data && _vm.content.data.length > 0
            ? _c(
                "div",
                { staticClass: "pt10" },
                [
                  _c(
                    "draggable",
                    _vm._b(
                      {
                        staticClass: "board-column-content flex flex-wrap",
                        attrs: { list: _vm.content.data },
                      },
                      "draggable",
                      _vm.$attrs,
                      false
                    ),
                    _vm._l(_vm.content.data, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "kuang1 item-align-center mt10",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "flex-one flex item-align-center",
                              staticStyle: { overflow: "hidden" },
                            },
                            [
                              _c("div", { staticClass: "album-img" }, [
                                _c("img", {
                                  attrs: { src: item.image, alt: "" },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "flex-one ml10 ft12",
                                  staticStyle: { overflow: "hidden" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex align-items-c" },
                                    [
                                      _c("div", [_vm._v("名称：")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "flex-one" },
                                        [
                                          _c("el-input", {
                                            staticStyle: {
                                              width: "100%",
                                              "font-size": "12px",
                                            },
                                            attrs: { size: "mini" },
                                            model: {
                                              value: item.name,
                                              callback: function ($$v) {
                                                _vm.$set(item, "name", $$v)
                                              },
                                              expression: "item.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "flex align-items-c mt5" },
                                    [
                                      _c("div", [_vm._v("预展：")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "flex-one" },
                                        [
                                          _c("el-input", {
                                            staticStyle: {
                                              width: "100%",
                                              "font-size": "12px",
                                            },
                                            attrs: { size: "mini" },
                                            model: {
                                              value:
                                                item.scheduled_show_time_format,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "scheduled_show_time_format",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.scheduled_show_time_format",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "flex align-items-c mt5" },
                                    [
                                      _c("div", [_vm._v("开拍：")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "flex-one" },
                                        [
                                          _c("el-input", {
                                            staticStyle: {
                                              width: "100%",
                                              "font-size": "12px",
                                            },
                                            attrs: { size: "mini" },
                                            model: {
                                              value: item.stime_format,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "stime_format",
                                                  $$v
                                                )
                                              },
                                              expression: "item.stime_format",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "flex align-items-c mt5" },
                                    [
                                      _c("div", [_vm._v("直播：")]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "flex-one" },
                                        [
                                          _c("el-input", {
                                            staticStyle: {
                                              width: "100%",
                                              "font-size": "12px",
                                            },
                                            attrs: { size: "mini" },
                                            model: {
                                              value: item.live_stime_format,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "live_stime_format",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.live_stime_format",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          index === 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "go-down-img ml10",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goDown(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          index > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "go-up-img ml10",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goUp(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "del-img",
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(index)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("selectView", {
        ref: "selectView",
        attrs: {
          "has-more-data": "1",
          "current-data": [],
          query: { venue_id: _vm.venue_id, album_id: _vm.album_id },
        },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }