var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pageInfo
    ? _c(
        "control",
        { attrs: { title: "公众号基础内容", is_tab: false } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "80px",
                size: "small",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "mt20",
                  attrs: { label: "封面图", required: "" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      staticStyle: { width: "141px", height: "60px" },
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "coverImg")
                        },
                      },
                    },
                    [
                      _vm.pageInfo.coverImg
                        ? _c("img", {
                            staticStyle: {
                              width: "141px",
                              height: "60px",
                              display: "block",
                            },
                            attrs: { src: _vm.pageInfo.coverImg },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "标题", required: "" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入公众号图文标题",
                      maxlength: "64",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.pageInfo.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "title", $$v)
                      },
                      expression: "pageInfo.title",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "作者" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入作者",
                      maxlength: "8",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.pageInfo.author,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "author", $$v)
                      },
                      expression: "pageInfo.author",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "摘要" } },
                [
                  _c("el-input", {
                    staticStyle: { "padding-bottom": "40px" },
                    attrs: {
                      type: "textarea",
                      rows: 4,
                      placeholder: "请输入摘要",
                      maxlength: "120",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.pageInfo.digest,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "digest", $$v)
                      },
                      expression: "pageInfo.digest",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "合集" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入合集" },
                    model: {
                      value: _vm.pageInfo.collection,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "collection", $$v)
                      },
                      expression: "pageInfo.collection",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "原文地址" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder:
                        "图文消息的原文地址，即点击“阅读原文”后的URL",
                    },
                    model: {
                      value: _vm.pageInfo.content_source_url,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageInfo, "content_source_url", $$v)
                      },
                      expression: "pageInfo.content_source_url",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }