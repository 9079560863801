var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex align-items-c mb20" },
    [
      _c("el-input", {
        attrs: { type: "text", placeholder: "设置标题内容" },
        nativeOn: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return (function () {})($event)
          },
        },
        model: {
          value: _vm.content.title,
          callback: function ($$v) {
            _vm.$set(_vm.content, "title", $$v)
          },
          expression: "content.title",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }