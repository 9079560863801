var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "posterHead" },
    [
      _vm.hasWz
        ? _c("div", { staticClass: "box" }, [
            _c(
              "div",
              {
                staticClass: "item",
                class: { active: _vm.routeName == "wz_index" },
                on: { click: _vm.onOpenModule1 },
              },
              [_vm._v("我的文章 " + _vm._s(_vm.headNumber.wz_index))]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "item",
                class: { active: _vm.routeName == "wz_MyTemplate" },
                on: { click: _vm.onOpenModule },
              },
              [_vm._v("我的模板 " + _vm._s(_vm.headNumber.wz_MyTemplate))]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "item",
                class: { active: _vm.routeName == "wz_template" },
                on: { click: _vm.onOpenTemplate },
              },
              [
                _vm._v(
                  "模板广场 " +
                    _vm._s(
                      _vm.headNumber.wz_template > 1000
                        ? _vm.headNumber.wz_template + "+"
                        : _vm.headNumber.wz_template
                    )
                ),
              ]
            ),
            _vm._v(" "),
            _vm.routeName == "wz_MyTemplate" || _vm.routeName == "wz_template"
              ? _c(
                  "div",
                  {
                    staticClass: "quickly-create l",
                    on: { click: _vm.createModel },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/article/add.png"),
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("制作模版")]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "quickly-create",
                on: { click: _vm.onOpenPoster },
              },
              [
                _c("img", {
                  attrs: {
                    src: "https://saas.cdn.yunzongbu.cn/merchant/333425/20241226/image/1735206531762109995.png",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("一键生成")]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "zw" }),
      _vm._v(" "),
      _c("createPoster", {
        ref: "createPosterRef",
        on: { onRefresh: _vm.onRefresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }