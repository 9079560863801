var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "auctionBox mt20" },
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox" },
          [
            _c(
              "el-dropdown",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: "merchantSystemExcelExport:auctionData",
                    expression: "'merchantSystemExcelExport:auctionData'",
                  },
                ],
                attrs: { type: "primary", placement: "top" },
              },
              [
                _c("el-button", { attrs: { type: "primary", size: "small" } }, [
                  _vm._v("导出拍品"),
                ]),
                _vm._v(" "),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "el-dropdown-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.exportData("")
                              },
                            },
                          },
                          [_vm._v("拍品表格")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.getExportFileList("")
                              },
                            },
                          },
                          [_vm._v("导出记录")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dropdown",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: "merchantSystemExcelExport:auctionData",
                    expression: "'merchantSystemExcelExport:auctionData'",
                  },
                ],
                attrs: { type: "primary", placement: "top" },
              },
              [
                _c("el-button", { attrs: { type: "primary", size: "small" } }, [
                  _vm._v("导出二维码"),
                ]),
                _vm._v(" "),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "el-dropdown-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.exportEWMData(1)
                              },
                            },
                          },
                          [_vm._v("小程序码")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.exportEWMData(2)
                              },
                            },
                          },
                          [_vm._v("二维码")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.getExportFileList("auctionQrcode")
                              },
                            },
                          },
                          [_vm._v("导出记录")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 175px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "auctionRef",
              attrs: {
                data: _vm.list,
                "element-loading-text": "Loading",
                height: "100%",
                fit: "",
                border: "",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "selection", width: "30", align: "center" },
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "排序",
                  width: "45",
                  align: "center",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input-number", {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission:disabled",
                              value: "merchantAuctionEditOrderNum",
                              expression: "'merchantAuctionEditOrderNum'",
                              arg: "disabled",
                            },
                          ],
                          staticClass: "sortCol",
                          staticStyle: { width: "50px" },
                          attrs: { min: 0, controls: false },
                          on: {
                            change: function (val) {
                              return _vm.editSortAction(val, scope.$index)
                            },
                            focus: function ($event) {
                              return _vm.focus($event)
                            },
                          },
                          model: {
                            value: scope.row.order_num,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "order_num", $$v)
                            },
                            expression: "scope.row.order_num",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "状态",
                  width: "70",
                  prop: "",
                  "search-key": "status",
                  "search-type": "radio",
                  options: [
                    { label: "全部", value: "" },
                    { label: "进行中", value: "2" },
                    { label: "预展中", value: "1" },
                    { label: "已结束", value: "4" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.auction_status === -2
                          ? _c("el-tag", { attrs: { size: "small" } }, [
                              _vm._v("已撤拍"),
                            ])
                          : scope.row.time_status === 1
                          ? _c(
                              "el-tag",
                              { attrs: { size: "small", type: "danger" } },
                              [_vm._v("\n            预展中")]
                            )
                          : scope.row.time_status === 2
                          ? _c("el-tag", { attrs: { size: "small" } }, [
                              _vm._v("进行中"),
                            ])
                          : scope.row.time_status === 3 ||
                            scope.row.time_status === 4
                          ? _c(
                              "el-tag",
                              { attrs: { size: "small", type: "info" } },
                              [_vm._v("已结束")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "LOT",
                  width: "60",
                  prop: "",
                  "search-key": "lot",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " + _vm._s(scope.row.lot) + "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "图片",
                  width: "50",
                  prop: "",
                  align: "center",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          staticStyle: {
                            width: "25px",
                            "margin-top": "5px",
                            cursor: "pointer",
                          },
                          attrs: {
                            src:
                              scope.row.product.image.split(",")[0] + "!120a",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.showImageAction(
                                scope.row.product.slider_image
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "拍品名称",
                  "min-width": "150",
                  prop: "",
                  "search-key": "keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", {}, [
                          scope.row.product
                            ? _c("span", [
                                _vm._v(_vm._s(scope.row.product.store_name)),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "拍品ID",
                  width: "80",
                  prop: "",
                  "search-key": "product_id",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.product_id
                          ? _c("span", [_vm._v(_vm._s(scope.row.product_id))])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "成交状态",
                  width: "90",
                  "search-key": "auction_status",
                  "search-type": "radio",
                  options: [
                    { label: "交割失败", value: "-1" },
                    { label: "保留价待确认", value: "3" },
                    { label: "流拍", value: "4" },
                    { label: "成交", value: "5" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.auction_status == -1
                          ? [_vm._v("\n            交割失败\n          ")]
                          : scope.row.auction_status == 3
                          ? [_vm._v("\n            保留价待确认\n          ")]
                          : scope.row.auction_status == 4
                          ? [_vm._v("\n            流拍\n          ")]
                          : scope.row.auction_status > 4
                          ? [_vm._v("成交\n          ")]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "委托人",
                  width: "120",
                  prop: "",
                  "search-type": "radio",
                  "select-filter": "",
                  "search-key": "entrust_user_keyword",
                  options: _vm.EnUserList,
                  "search-query": _vm.searchQuery,
                },
                on: {
                  changeVal: _vm.searchAction,
                  selectFilterChange: _vm.entrustInit,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.erpV2Product && row.erpV2Product.entrustUser
                          ? _c(
                              "div",
                              { staticClass: "nowrap" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "nowrap",
                                    staticStyle: {
                                      "padding-bottom": "0",
                                      width: "100%",
                                      "text-align": "left",
                                    },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDetails(
                                          row.erpV2Product.entrustUser
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          row.erpV2Product.entrustUser.nickname
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.erpV2Product.entrustUser.real_name
                                      ) +
                                      " "
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(row.erpV2Product.entrustUser.phone)
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "所属专场",
                  "min-width": "150",
                  prop: "",
                  "search-key": "album_id",
                  "search-type": "radio",
                  "select-filter": "",
                  options: _vm.albumlist,
                  "search-query": _vm.searchQuery,
                },
                on: {
                  changeVal: _vm.searchAction,
                  selectFilterChange: _vm.onSelectFilterChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.album
                          ? [
                              _vm._v(
                                " " + _vm._s(scope.row.album.album_name) + " "
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "拍卖时间",
                  width: "180",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.start_time
                          ? _c("div", [
                              _vm._v(
                                "开拍: " +
                                  _vm._s(
                                    _vm.formatDate(
                                      scope.row.start_time * 1000,
                                      "{y}-{m}-{d} {h}:{i}"
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.end_time
                          ? _c("div", [
                              _vm._v(
                                "结拍: " +
                                  _vm._s(
                                    _vm.formatDate(
                                      scope.row.end_time * 1000,
                                      "{y}-{m}-{d} {h}:{i}"
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "拍品价格",
                  width: "150",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "起拍价: " +
                              _vm._s(
                                scope.row.album.fee_type.replace("CNY", "RMB")
                              ) +
                              " " +
                              _vm._s(scope.row.startPriceFormat)
                          ),
                        ]),
                        _vm._v(" "),
                        scope.row.reference_price &&
                        scope.row.reference_price != 0
                          ? _c("div", [
                              _vm._v(
                                "参考价: " +
                                  _vm._s(
                                    scope.row.album.fee_type.replace(
                                      "CNY",
                                      "RMB"
                                    )
                                  ) +
                                  " " +
                                  _vm._s(scope.row.reference_price)
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.auction_status == 3
                          ? _c("div", [_vm._v("落槌价: 保留价待确认")])
                          : scope.row.auction_status == 4
                          ? _c("div", [_vm._v("落槌价: 流拍 可洽购")])
                          : scope.row.auction_status == 5 ||
                            scope.row.auction_status == 6
                          ? _c("div", [
                              _vm._v(
                                "落槌价: " +
                                  _vm._s(
                                    scope.row.album.fee_type.replace(
                                      "CNY",
                                      "RMB"
                                    )
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.formatPriceNumber(scope.row.now_price)
                                  )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "拍卖规则",
                  width: "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.bond_price
                          ? _c("div", [
                              _vm._v(
                                "保证金: " +
                                  _vm._s(
                                    scope.row.album.fee_type.replace(
                                      "CNY",
                                      "RMB"
                                    )
                                  ) +
                                  " " +
                                  _vm._s(scope.row.bond_price)
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.bid_range_id && scope.row.bidRange
                          ? _c("div", [
                              _vm._v(
                                "竞价阶梯: " + _vm._s(scope.row.bidRange.title)
                              ),
                            ])
                          : scope.row.plus_price && scope.row.plus_price != 0
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s("加价幅度:" + scope.row.plus_price) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  permission: "merchantAuctionList",
                  "permission-column": "participationInfo",
                  label: "围观",
                  width: "80",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "has-click",
                            on: {
                              click: function ($event) {
                                return _vm.showAlbumVisitDataAction(
                                  scope.row.album_id,
                                  scope.row.click_uv,
                                  scope.row.product.browse,
                                  scope.row.product_id
                                )
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v(_vm._s(scope.row.click_uv))]),
                            _vm._v("人 "),
                            _c("span", [
                              _vm._v(_vm._s(scope.row.product.browse)),
                            ]),
                            _vm._v("次\n          "),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  permission: "merchantAuctionList",
                  "permission-column": "participationInfo",
                  label: "出价",
                  width: "50",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "has-click",
                            on: {
                              click: function ($event) {
                                return _vm.showAuctionBidRecordAction(
                                  scope.row.product_id
                                )
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v(_vm._s(scope.row.bid_num_uv))]),
                            _vm._v("人\n          "),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "推荐",
                  width: "50",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.product.is_mer_recommend
                          ? [
                              _c(
                                "el-button",
                                {
                                  staticClass: "but",
                                  staticStyle: { color: "red !important" },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.recommendAction(scope)
                                    },
                                  },
                                },
                                [_vm._v("取消 ")]
                              ),
                            ]
                          : [
                              _c(
                                "el-button",
                                {
                                  staticClass: "but",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.recommendAction(scope)
                                    },
                                  },
                                },
                                [_vm._v("推荐 ")]
                              ),
                            ],
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "",
                  width: "36",
                  prop: "",
                  fixed: "right",
                  align: "center",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "TableRightBtn",
                          [
                            _c("template", { slot: "pop_icon" }, [
                              _c("i", {
                                staticClass: "el-icon-link",
                                staticStyle: { color: "#69d" },
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "template",
                              { slot: "pop_btn" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyXcxLink(
                                          scope.row,
                                          $event
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "tuiguang-lianjie",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("小程序链接 "),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyH5Link(scope.row, $event)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "tuiguang-lianjie",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("H5链接 "),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showImgAction(1, scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "tuiguang-xiaochengxu",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("小程序码 "),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showImgAction(2, scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "tuiguang-erweima",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("二维码 "),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showImgAction(3, scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "tuiguang-haibao",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("小程序海报 "),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showImgAction(4, scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "tuiguang-haibao",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("二维码海报 "),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "",
                  width: "36",
                  fixed: "right",
                  align: "center",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "TableRightBtn",
                          [
                            scope.row.time_status != 3 ||
                            scope.row.time_status != 4
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "but",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onIsNoAuction(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "commonedit",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("VIP号牌可参拍 "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "merchantAuctionOrderLst",
                                    expression: "'merchantAuctionOrderLst'",
                                  },
                                ],
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.goPageAction(
                                      "/merchant/order/list/auctionMeeting?auction_name=" +
                                        scope.row.product.store_name
                                    )
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    "icon-class": "order-maijiadingdan",
                                    "class-name": "blue",
                                  },
                                }),
                                _vm._v("买家订单 "),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            scope.row.auction_status == 3
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { "font-size": "14px" },
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.bleedingTransfer(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "kehu-set",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v("确认成交"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "template",
                              { slot: "pop_btn" },
                              [
                                scope.row.auction_status === 1 ||
                                scope.row.auction_status === 2
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: "merchantAuctionUpdate",
                                            expression:
                                              "'merchantAuctionUpdate'",
                                          },
                                        ],
                                        staticClass: "but",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editAuctionAction(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "commonedit",
                                            "class-name": "blue",
                                          },
                                        }),
                                        _vm._v("编辑 "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.erp_product_id
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: "/basic/goodslist",
                                            expression: "'/basic/goodslist'",
                                          },
                                        ],
                                        staticClass: "ft12 m0 plr2",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getbasicdetail(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "auction-huopin",
                                            "class-name": "blue",
                                          },
                                        }),
                                        _vm._v("货品档案 "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.auction_status === 4 ||
                                scope.row.auction_status === -1 ||
                                (scope.row.auction_status === 5 &&
                                  !(
                                    scope.row.order_info &&
                                    scope.row.order_info.order_id
                                  ))
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: "merchantAuctionPrivateDeal",
                                            expression:
                                              "'merchantAuctionPrivateDeal'",
                                          },
                                        ],
                                        staticClass: "but",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.arrangeAPurchaseAction(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "order-siqia",
                                            "class-name": "blue",
                                          },
                                        }),
                                        _vm._v("私洽成交 "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.auction_status === 1 ||
                                scope.row.auction_status === 2
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: "merchantProxyBid",
                                            expression: "'merchantProxyBid'",
                                          },
                                        ],
                                        staticClass: "but",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.proxyBidAction(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class":
                                              "zhuanchang-weituochujia",
                                            "class-name": "blue",
                                          },
                                        }),
                                        _vm._v("委托出价 "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                (scope.row.auction_status > 2 ||
                                  scope.row.auction_status == -1 ||
                                  scope.row.auction_status == -2) &&
                                scope.row.product.is_show !== 0
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: "merchantHideAuction",
                                            expression: "'merchantHideAuction'",
                                          },
                                        ],
                                        staticClass: "but",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.hideAuction(
                                              scope.row,
                                              0,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "zhuanchang-yincang",
                                            "class-name": "blue",
                                          },
                                        }),
                                        _vm._v("隐藏拍品 "),
                                      ],
                                      1
                                    )
                                  : (scope.row.auction_status > 2 ||
                                      scope.row.auction_status == -1 ||
                                      scope.row.auction_status == -2) &&
                                    scope.row.product.is_show === 0
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: "merchantHideAuction",
                                            expression: "'merchantHideAuction'",
                                          },
                                        ],
                                        staticClass: "but",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.hideAuction(
                                              scope.row,
                                              1,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "zhuanchang-yincang",
                                            "class-name": "blue",
                                          },
                                        }),
                                        _vm._v("取消隐藏 "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                (scope.row.auction_status === 1 ||
                                  scope.row.auction_status === 2) &&
                                !scope.row.is_live_top
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: "merchantAuctionSetLiveTop",
                                            expression:
                                              "'merchantAuctionSetLiveTop'",
                                          },
                                        ],
                                        staticClass: "but",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.goLiveUpAction(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "zhuanchang-zhiding",
                                            "class-name": "blue",
                                          },
                                        }),
                                        _vm._v("直播间置顶 "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.auction_status > 4 ||
                                scope.row.auction_status == 4 ||
                                scope.row.auction_status == -1
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: "merchantAuctionInfo",
                                            expression: "'merchantAuctionInfo'",
                                          },
                                        ],
                                        staticClass: "but",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editAuctionAction(
                                              scope.row,
                                              1
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "commonlook",
                                            "class-name": "blue",
                                          },
                                        }),
                                        _vm._v("查看拍品 "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.auction_status === 4 &&
                                scope.row.auction_type == 1
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: "merchantReAuction",
                                            expression: "'merchantReAuction'",
                                          },
                                        ],
                                        staticClass: "but",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.auctionAgain(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class":
                                              "auction-chongxinshangpai",
                                            "class-name": "blue",
                                          },
                                        }),
                                        _vm._v("重新上拍 "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.live_video_record &&
                                scope.row.live_video_record.video
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "but",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showVideoAction(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "commonlook",
                                            "class-name": "blue",
                                          },
                                        }),
                                        _vm._v("视频回放 "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.auction_status === 1 ||
                                scope.row.auction_status === 2
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: "merchantSyncToVideo",
                                            expression: "'merchantSyncToVideo'",
                                          },
                                        ],
                                        staticClass: "but",
                                        attrs: { type: "text" },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "live-tongbu",
                                            "class-name": "blue",
                                          },
                                        }),
                                        _vm._v("同步到视频号 "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                (scope.row.auction_status === 2 ||
                                  scope.row.auction_status === 1) &&
                                scope.row.auction_type == 3 &&
                                scope.row.album.album_status == 2
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: "merchantAuctionDel",
                                            expression: "'merchantAuctionDel'",
                                          },
                                        ],
                                        staticClass: "but",
                                        staticStyle: { color: "red" },
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.revokeAuctionAction(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "commondelete",
                                            color: "red",
                                          },
                                        }),
                                        _vm._v("撤拍 "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.auction_status === 1 ||
                                scope.row.auction_status === 2
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission",
                                            value: "merchantAuctionDel",
                                            expression: "'merchantAuctionDel'",
                                          },
                                        ],
                                        staticClass: "but",
                                        staticStyle: { color: "red" },
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delAuctionAction(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "commondelete",
                                            color: "red",
                                          },
                                        }),
                                        _vm._v("删除 "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-b orderFoot" },
        [
          _c(
            "div",
            { staticClass: "flex-one" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    disabled: _vm.selectRows.length == 0,
                    type: "primary",
                  },
                  on: { click: _vm.onAllDele },
                },
                [_vm._v("删除")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [10, 20, 40, 60, 80],
              "page-size": _vm.queryParams.limit,
              "current-page": _vm.queryParams.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.total,
            },
            on: {
              "current-change": _vm.pageChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "VIP号牌是否可参拍",
            visible: _vm.VIPVisible,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.VIPVisible = $event
            },
          },
        },
        [
          _c(
            "el-radio-group",
            {
              attrs: { size: "small" },
              model: {
                value: _vm.stateForm.status,
                callback: function ($$v) {
                  _vm.$set(_vm.stateForm, "status", $$v)
                },
                expression: "stateForm.status",
              },
            },
            [
              _c("el-radio", { attrs: { label: "0" } }, [
                _vm._v(" 全部用户可出价 "),
              ]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "1" } }, [
                _vm._v(" VIP用户可出价 "),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c justify-c mt40" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubStatus } },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.imgData.show,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.imgData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _vm.imgData.show
                ? _c(
                    "el-carousel",
                    { attrs: { height: "500px" } },
                    _vm._l(_vm.imgData.imgList, function (item, index) {
                      return _c("el-carousel-item", { key: index }, [
                        _c(
                          "div",
                          {
                            staticClass: "flex align-items-c justify-c",
                            staticStyle: {
                              height: "500px",
                              background: "#f7f7f7",
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: {
                                "max-width": "500px",
                                "max-height": "500px",
                              },
                              attrs: { src: item },
                            }),
                          ]
                        ),
                      ])
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "私洽成交",
            visible: _vm.arrangeAPurchase.show,
            "close-on-click-modal": false,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.arrangeAPurchase, "show", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "flex align-items-c fWeight500" }, [
            _vm._v(
              "\n      拍品：" +
                _vm._s(_vm.arrangeAPurchase.tuluhao) +
                " | " +
                _vm._s(_vm.arrangeAPurchase.title) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("购买人：")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "w300",
                  attrs: {
                    placeholder: "真实姓名/手机号/昵称搜索",
                    filterable: "",
                    remote: "",
                    "remote-method": _vm.searchUserAction,
                    loading: _vm.searchLoading,
                  },
                  model: {
                    value: _vm.arrangeAPurchase.uid,
                    callback: function ($$v) {
                      _vm.$set(_vm.arrangeAPurchase, "uid", $$v)
                    },
                    expression: "arrangeAPurchase.uid",
                  },
                },
                _vm._l(_vm.userList, function (m, i) {
                  return _c(
                    "el-option",
                    {
                      key: "user" + i,
                      attrs: { value: m.uid, label: m.nickname },
                    },
                    [
                      _c("div", { staticClass: "avatar flex align-items-c" }, [
                        _c("img", { attrs: { src: m.avatar, alt: "" } }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "line1 flex-one",
                            staticStyle: { "line-height": "18px" },
                          },
                          [
                            _c("div", [
                              m.real_name
                                ? _c("span", [
                                    _vm._v(_vm._s(m.real_name) + " | "),
                                  ])
                                : _vm._e(),
                              _vm._v(_vm._s(m.nickname)),
                            ]),
                            _vm._v(" "),
                            _c("div", [_vm._v(_vm._s(m.phone))]),
                          ]
                        ),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("输入金额：")]),
              _vm._v(" "),
              _c("el-input-number", {
                staticClass: "w200",
                attrs: { min: 0, placeholder: "请输入私恰金额" },
                model: {
                  value: _vm.arrangeAPurchase.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.arrangeAPurchase, "price", $$v)
                  },
                  expression: "arrangeAPurchase.price",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bottom-btn mt40" },
            [
              _c(
                "el-button",
                { on: { click: _vm.clearArrangeAPurchaseAction } },
                [_vm._v("\n        取消\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.arrangeAPurchase.btnLoading,
                  },
                  on: { click: _vm.arrangeAPurchaseConfirmAction },
                },
                [_vm._v("\n        确认\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "委托出价",
            visible: _vm.proxyData.show,
            "close-on-click-modal": false,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.proxyData, "show", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "flex align-items-c fWeight500" }, [
            _vm._v(
              "\n      拍品：" +
                _vm._s(_vm.proxyData.tuluhao) +
                " | " +
                _vm._s(_vm.proxyData.title) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("委托类型：")]),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.proxyData.bid_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.proxyData, "bid_type", $$v)
                    },
                    expression: "proxyData.bid_type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("加一口")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("代理出价")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex align-items-c mt20" }, [
            _c("span", { staticClass: "w100" }, [_vm._v("特殊号牌：")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ts-code flex flex-one" },
              _vm._l(_vm.showNumberList, function (m, i) {
                return _c(
                  "div",
                  {
                    key: "showNumberList" + i,
                    staticClass: "item flex align-items-c",
                    class: _vm.changeUser.uid == m.uid ? "curr" : "",
                    on: {
                      click: function ($event) {
                        return _vm.clickNumberAction(m)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "l-name line1" }, [
                      _vm._v(_vm._s(m.user.nickname)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "l-code ml10" }, [
                      _vm._v(_vm._s(m.number)),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          !_vm.changeUser.uid
            ? _c(
                "div",
                { staticClass: "flex align-items-c mt20" },
                [
                  _c("span", { staticClass: "w100" }, [_vm._v("出价人：")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      attrs: {
                        placeholder: "输入出价人昵称搜索",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.searchUserAction,
                        loading: _vm.searchLoading,
                      },
                      model: {
                        value: _vm.proxyData.uid,
                        callback: function ($$v) {
                          _vm.$set(_vm.proxyData, "uid", $$v)
                        },
                        expression: "proxyData.uid",
                      },
                    },
                    _vm._l(_vm.userList, function (m, i) {
                      return _c(
                        "el-option",
                        {
                          key: "user" + i,
                          attrs: { value: m.uid, label: m.nickname },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "avatar flex align-items-c" },
                            [
                              _c("img", { attrs: { src: m.avatar, alt: "" } }),
                              _vm._v(" "),
                              _c("div", { staticClass: "line1 flex-one" }, [
                                _vm._v(_vm._s(m.nickname)),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c mt20" },
            [
              _c("span", { staticClass: "w100" }, [_vm._v("输入价格：")]),
              _vm._v(" "),
              _c("el-input-number", {
                staticClass: "w200",
                attrs: { min: 0 },
                model: {
                  value: _vm.proxyData.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.proxyData, "price", $$v)
                  },
                  expression: "proxyData.price",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.changeUser.uid
            ? _c(
                "div",
                { staticClass: "flex align-items-c mt20" },
                [
                  _c("span", { staticClass: "w100" }, [
                    _vm._v("随机号牌出价："),
                  ]),
                  _vm._v(" "),
                  _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    model: {
                      value: _vm.proxyData.anonymous,
                      callback: function ($$v) {
                        _vm.$set(_vm.proxyData, "anonymous", $$v)
                      },
                      expression: "proxyData.anonymous",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bottom-btn mt40" },
            [
              _c("el-button", { on: { click: _vm.clearProxyBid } }, [
                _vm._v("\n        取消\n      "),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.proxyData.btnLoading },
                  on: { click: _vm.proxyBidConfirmAction },
                },
                [_vm._v("\n        确认\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.showImg,
            "before-close": _vm.beforeCloseImg,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex align-items-c justify-c" }, [
            _c("img", {
              staticStyle: { width: "400px" },
              attrs: { src: _vm.imgUrl },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showVideo.show,
            "close-on-click-modal": false,
            "before-close": _vm.closeBeforeAction,
            center: "",
            title: "视频回放",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showVideo, "show", $event)
            },
          },
        },
        [
          _vm.showVideo.show
            ? _c("video", { attrs: { id: "id_test_video" } })
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.showbasicflag
        ? [
            _c("basicdetail", {
              attrs: { id: _vm.basicproid, showdetail: _vm.showbasicflag },
              on: { close: _vm.detailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("albumVisitData", { ref: "albumVisitEL" }),
      _vm._v(" "),
      _c("auctionBidRecord", {
        ref: "auctionBidRecordEL",
        on: { load: _vm.load },
      }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: {
                editMark: _vm.load,
                close: _vm.userdetailclose,
                editPhone: _vm.editPhoneAction,
              },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }