var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "banner-control mb20",
      attrs: {
        model: _vm.content,
        "label-width": "90px",
        size: "mini",
        "label-position": "left",
      },
    },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "item-align-center" },
          [
            _c(
              "el-radio-group",
              {
                staticClass: "le-radio-group",
                on: { input: _vm.changeLogoType },
                model: {
                  value: _vm.type,
                  callback: function ($$v) {
                    _vm.type = $$v
                  },
                  expression: "type",
                },
              },
              [
                _c("el-radio", { attrs: { label: 1 } }, [_vm._v("企业资料")]),
                _vm._v(" "),
                _c("el-radio", { attrs: { label: 2 } }, [_vm._v("自定义")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.type === 2
        ? _vm._l(_vm.content.data, function (item, index) {
            return _c(
              "div",
              {
                key: "logo" + index,
                staticClass: "upload-img-view item-flex-center",
                staticStyle: {
                  width: "90px",
                  height: "90px",
                  "margin-top": "20px",
                  cursor: "pointer",
                },
                on: {
                  click: function ($event) {
                    return _vm.uploadIconAction(1, index)
                  },
                },
              },
              [
                item.url
                  ? _c(
                      "div",
                      {
                        staticClass: "del-upload-img",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.delImg(index)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                            alt: "",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                item.url
                  ? _c("img", {
                      staticStyle: { width: "90px", height: "90px" },
                      attrs: { src: item.url + "!120a" },
                    })
                  : _c("img", {
                      staticClass: "upload-icon",
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                        alt: "",
                      },
                    }),
              ]
            )
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }