var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "aucOrder", staticStyle: { "padding-bottom": "0" } },
    [
      _c(
        "div",
        {
          staticClass: "flex justify-b",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "el-radio-group",
            {
              attrs: { type: "button", disabled: _vm.listLoading },
              on: { change: _vm.changeStatusAction },
              model: {
                value: _vm.status,
                callback: function ($$v) {
                  _vm.status = $$v
                },
                expression: "status",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "" } }, [
                _vm._v(
                  "全部" +
                    _vm._s(
                      "(" + _vm.orderChartType.all
                        ? _vm.orderChartType.all
                        : 0 + ")"
                    )
                ),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "8" } }, [
                _vm._v(
                  "待确认 " +
                    _vm._s(
                      "(" + _vm.orderChartType.waitConfirm
                        ? _vm.orderChartType.waitConfirm
                        : 0 + ")"
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "1" } }, [
                _vm._v(
                  "待付款 " +
                    _vm._s(
                      "(" + _vm.orderChartType.unpaid
                        ? _vm.orderChartType.unpaid
                        : 0 + ")"
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "2" } }, [
                _vm._v(
                  "待发货 " +
                    _vm._s(
                      "(" + _vm.orderChartType.unshipped
                        ? _vm.orderChartType.unshipped
                        : 0 + ")"
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "3" } }, [
                _vm._v(
                  "待收货 " +
                    _vm._s(
                      "(" + _vm.orderChartType.untake
                        ? _vm.orderChartType.untake
                        : 0 + ")"
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "4" } }, [
                _vm._v(
                  "待评价 " +
                    _vm._s(
                      "(" + _vm.orderChartType.unevaluate
                        ? _vm.orderChartType.unevaluate
                        : 0 + ")"
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "5" } }, [
                _vm._v(
                  "已完成 " +
                    _vm._s(
                      "(" + _vm.orderChartType.complete
                        ? _vm.orderChartType.complete
                        : 0 + ")"
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "9" } }, [
                _vm._v(
                  "已举回 " +
                    _vm._s(
                      "(" + _vm.orderChartType.juhui
                        ? _vm.orderChartType.juhui
                        : 0 + ")"
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "6" } }, [
                _vm._v(
                  "已退款 " +
                    _vm._s(
                      "(" + _vm.orderChartType.refund
                        ? _vm.orderChartType.refund
                        : 0 + ")"
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-radio-button", { attrs: { label: "7" } }, [
                _vm._v(
                  "已取消 " +
                    _vm._s(
                      "(" + _vm.orderChartType.cancel
                        ? _vm.orderChartType.cancel
                        : 0 + ")"
                    ) +
                    "\n      "
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.changeheaderconfig },
            },
            [_vm._v("字段配置")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 224px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "orderTableRef",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                height: "100%",
                data: _vm.tableData.data,
                "span-method": _vm.objectSpanMethod,
                size: "mini",
                "highlight-current-row": "",
                "cell-class-name": _vm.addTdClass,
              },
              on: {
                "selection-change": _vm.handleSelectionChange,
                "sort-change": _vm.changeSortAction,
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                key: "1",
                attrs: { type: "selection", width: "36", align: "center" },
              }),
              _vm._v(" "),
              _vm._l(_vm.headerList, function (item, index) {
                return [
                  item.field == "warehouseOrder.order_no"
                    ? [
                        _vm.tableFrom.status == 2
                          ? _c("ytx-table-column", {
                              key: item.field,
                              attrs: { label: "出库单", width: "160" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        row.warehouseOrder
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onSee(
                                                      row.warehouseOrder.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      row.warehouseOrder
                                                        .order_no
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : _vm._e(),
                      ]
                    : item.field == "warehouseOrder.outStatusText"
                    ? [
                        _vm.tableFrom.status == 2
                          ? _c("ytx-table-column", {
                              key: item.field,
                              attrs: { label: "出库状态", width: "80" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        row.warehouseOrder
                                          ? [
                                              _vm._v(
                                                _vm._s(
                                                  row.warehouseOrder
                                                    .outStatusText
                                                )
                                              ),
                                            ]
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : _vm._e(),
                      ]
                    : item.field == "erpProduct.product_no"
                    ? [
                        _c("ytx-table-column", {
                          key: item.field,
                          attrs: {
                            label: "库号",
                            width: "120",
                            "search-key": "product_no",
                            "search-query": _vm.searchQuery,
                          },
                          on: { changeVal: _vm.searchAction },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    (scope.row.orderProductOne &&
                                      scope.row.orderProductOne.erpProduct &&
                                      scope.row.orderProductOne.erpProduct
                                        .product_no) ||
                                    (scope.row.orderProduct &&
                                      scope.row.orderProduct[0] &&
                                      scope.row.orderProduct[0].erpProduct &&
                                      scope.row.orderProduct[0].erpProduct
                                        .product_no)
                                      ? [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                scope.row.orderProductOne
                                                  ? scope.row.orderProductOne
                                                      .erpProduct.product_no
                                                  : scope.row.orderProduct[0]
                                                      .erpProduct.product_no
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == "delivery_id"
                    ? [
                        _vm.tableFrom.status == 3
                          ? _c("ytx-table-column", {
                              key: item.field,
                              attrs: {
                                label: "快递单号",
                                width: "140",
                                "search-key": "delivery_id",
                                "search-query": _vm.searchQuery,
                              },
                              on: { changeVal: _vm.searchAction },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openLogistics(row)
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(row.delivery_id))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : _vm._e(),
                      ]
                    : item.field == 6
                    ? [
                        !_vm.tableFrom.status ||
                        (_vm.tableFrom.status &&
                          (_vm.tableFrom.status == 2 ||
                            _vm.tableFrom.status == 3 ||
                            _vm.tableFrom.status == 5))
                          ? [
                              _c("ytx-table-column", {
                                key: item.field,
                                attrs: {
                                  permission: "merchantAuctionOrderLst",
                                  "permission-column": "user_address",
                                  label: "收货信息",
                                  width: "300",
                                  prop: "",
                                  "search-key": "delivery_keyword",
                                  "search-query": _vm.searchQuery,
                                },
                                on: { changeVal: _vm.searchAction },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.user_address.indexOf(
                                            "https://"
                                          ) === 0
                                            ? [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "cur nowrap",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openUrlAction(
                                                          scope.row.user_address
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.user_address
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : [
                                                _c(
                                                  "el-popover",
                                                  {
                                                    attrs: {
                                                      placement: "top-start",
                                                      trigger: "hover",
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.real_name
                                                        ) +
                                                          " " +
                                                          _vm._s(
                                                            scope.row.user_phone
                                                          )
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.user_address
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onCopy(
                                                              "" +
                                                                scope.row
                                                                  .real_name +
                                                                scope.row
                                                                  .user_phone +
                                                                scope.row
                                                                  .user_address +
                                                                " 保价金额：" +
                                                                scope.row
                                                                  .delivery_insured_price
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("复制")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "nowrap",
                                                        attrs: {
                                                          slot: "reference",
                                                        },
                                                        slot: "reference",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row
                                                                .real_name
                                                            ) +
                                                              " " +
                                                              _vm._s(
                                                                scope.row
                                                                  .user_phone
                                                              )
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row
                                                                .user_address
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                scope.row
                                                  .delivery_insured_price &&
                                                scope.row.delivery_type != 2
                                                  ? _c("div", [
                                                      _vm._v(
                                                        "保价金额：" +
                                                          _vm._s(
                                                            _vm.formatPriceNumber(
                                                              scope.row
                                                                .delivery_insured_price,
                                                              2
                                                            )
                                                          )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ],
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          : _vm._e(),
                      ]
                    : item.field == "entrustUser"
                    ? [
                        _c("ytx-table-column", {
                          key: item.field + "-1",
                          attrs: {
                            label: "委托方信息",
                            width: "140",
                            "search-key": "entrust_user_keyword",
                            "search-query": _vm.searchQuery,
                          },
                          on: { changeVal: _vm.searchAction },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    row.orderProduct &&
                                    row.orderProduct[0] &&
                                    row.orderProduct[0].entrustUser
                                      ? _c(
                                          "div",
                                          { staticClass: "nowrap" },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "nowrap",
                                                staticStyle: {
                                                  "padding-bottom": "0",
                                                  width: "100%",
                                                  "text-align": "left",
                                                },
                                                attrs: { type: "text" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      row.orderProduct[0]
                                                        .entrustUser.nickname
                                                    ) +
                                                    " \n                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.orderProduct[0]
                                                      .entrustUser.real_name
                                                  ) +
                                                  " "
                                              ),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    row.orderProduct[0]
                                                      .entrustUser.phone
                                                  )
                                                ),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    row.orderProductOne &&
                                    row.orderProductOne.entrustUser
                                      ? _c(
                                          "div",
                                          { staticClass: "nowrap" },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "nowrap",
                                                staticStyle: {
                                                  "padding-bottom": "0",
                                                  width: "100%",
                                                  "text-align": "left",
                                                },
                                                attrs: { type: "text" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      row.orderProductOne
                                                        .entrustUser.nickname
                                                    ) +
                                                    " \n                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.orderProductOne
                                                      .entrustUser.real_name
                                                  ) +
                                                  " "
                                              ),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    row.orderProductOne
                                                      .entrustUser.phone
                                                  )
                                                ),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == "3"
                    ? [
                        _c("ytx-table-column", {
                          key: item.field + "-1",
                          attrs: {
                            label: "买家信息",
                            width: "140",
                            "search-key": "user_keyword",
                            "search-query": _vm.searchQuery,
                          },
                          on: { changeVal: _vm.searchAction },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "nowrap" },
                                      [
                                        row.user
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "nowrap",
                                                staticStyle: {
                                                  "padding-bottom": "0",
                                                  width: "100%",
                                                  "text-align": "left",
                                                },
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onDetails(row)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(row.user.nickname) +
                                                    " "
                                                ),
                                                row.user.mark
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "(" +
                                                          _vm._s(
                                                            row.user.mark
                                                          ) +
                                                          ")"
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        row.user
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(row.user.real_name) +
                                                  " "
                                              ),
                                              _c("span", [
                                                _vm._v(_vm._s(row.user.phone)),
                                              ]),
                                            ])
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == 4
                    ? [
                        _c("ytx-table-column", {
                          key: item.field,
                          attrs: {
                            label: "号牌",
                            width: "60",
                            permission: "merchantAuctionOrderLst",
                            "permission-column": "number",
                            "search-key": "number",
                            "search-query": _vm.searchQuery,
                          },
                          on: { changeVal: _vm.searchAction },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    !row.isSearch && row.userNumberPlate
                                      ? [
                                          _vm._v(
                                            _vm._s(row.userNumberPlate.number)
                                          ),
                                        ]
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == 5
                    ? [
                        _c("ytx-table-column", {
                          key: item.field + "-11",
                          attrs: {
                            label: "拍卖会",
                            width: "150",
                            prop: "",
                            "show-overflow-tooltip": "",
                            "search-key": "venue_id",
                            "search-type": "radio",
                            selectFilter: "",
                            options: _vm.venuelistOption,
                            "search-query": _vm.searchQuery,
                          },
                          on: {
                            changeVal: _vm.searchAction,
                            selectFilterChange: _vm.onSelectFilterChange,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("div", { staticClass: "nowrap" }, [
                                      _vm._v(_vm._s(row.venue_name)),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("ytx-table-column", {
                          key: item.field + "-12",
                          attrs: {
                            label: "专场",
                            width: "150",
                            prop: "",
                            "show-overflow-tooltip": "",
                            "search-key": "album_id",
                            "search-type": "radio",
                            selectFilter: "",
                            options: _vm.albumList,
                            "search-query": _vm.searchQuery,
                          },
                          on: {
                            changeVal: _vm.searchAction,
                            selectFilterChange: _vm.onSelectAlbumFilterChange,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("div", { staticClass: "nowrap" }, [
                                      _vm._v(
                                        _vm._s(
                                          row.album ? row.album.album_name : ""
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == 9
                    ? [
                        _c("ytx-table-column", {
                          key: item.field,
                          attrs: {
                            label: "拍品名称",
                            "min-width": "130",
                            "search-key": "auction_keyword",
                            "search-query": _vm.searchQuery,
                            "show-overflow-tooltip": "",
                          },
                          on: { changeVal: _vm.searchAction },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    (scope.row.orderProductOne &&
                                      scope.row.orderProductOne.erpProduct &&
                                      scope.row.orderProductOne.erpProduct
                                        .id) ||
                                    (scope.row.orderProduct &&
                                      scope.row.orderProduct[0] &&
                                      scope.row.orderProduct[0].erpProduct &&
                                      scope.row.orderProduct[0].erpProduct.id)
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "nowrap",
                                            staticStyle: {
                                              "padding-bottom": "0",
                                              width: "100%",
                                              "text-align": "left",
                                            },
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.getdetail(scope.row)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                LOT " +
                                                _vm._s(
                                                  scope.row.orderProductOne
                                                    ? scope.row.orderProductOne
                                                        .cart_info.product.lot
                                                    : scope.row.orderProduct[0]
                                                        .cart_info.product.lot
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _c("div", { staticClass: "nowrap" }, [
                                          _vm._v(
                                            "\n                LOT " +
                                              _vm._s(
                                                scope.row.orderProductOne
                                                  ? scope.row.orderProductOne
                                                      .cart_info.product.lot
                                                  : scope.row.orderProduct[0]
                                                      .cart_info.product.lot
                                              ) +
                                              "\n              "
                                          ),
                                        ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "nowrap" }, [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.orderProductOne
                                            ? scope.row.orderProductOne
                                                .cart_info.product.store_name
                                            : scope.row.orderProduct[0]
                                                .cart_info.product.store_name
                                        ) + "\n              "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == 12
                    ? [
                        _c("ytx-table-column", {
                          key: item.field,
                          attrs: {
                            label: "订单状态",
                            width: "95",
                            "search-key": "status",
                            "search-type": "radio",
                            "search-query": _vm.searchQuery,
                            options: _vm.statusOptionsList,
                          },
                          on: { changeVal: _vm.searchAction },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.status == -2
                                      ? [_c("div", [_vm._v("已取消")])]
                                      : scope.row.status == -1
                                      ? [_c("div", [_vm._v("已退款")])]
                                      : scope.row.status == -6
                                      ? _c(
                                          "div",
                                          { staticClass: "flex" },
                                          [
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  effect: "dark",
                                                  content: "代拍支付待确认",
                                                  placement: "top",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "nowrap",
                                                    staticStyle: {
                                                      width: "0",
                                                      flex: "1",
                                                    },
                                                  },
                                                  [_vm._v("代拍支付待确认")]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "mini",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.configPayAction(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("确认收款")]
                                            ),
                                          ],
                                          1
                                        )
                                      : scope.row.status == -7
                                      ? [_c("div", [_vm._v("已举回")])]
                                      : scope.row.is_refund !== 0
                                      ? [_c("div", [_vm._v("售后中")])]
                                      : scope.row.status == -3 ||
                                        scope.row.status == 0
                                      ? [_c("div", [_vm._v("待结算")])]
                                      : scope.row.status == -4
                                      ? [_c("div", [_vm._v("转账待审核")])]
                                      : scope.row.status == 1 &&
                                        scope.row.is_out_warehouse_order == 1
                                      ? [_c("div", [_vm._v("待出库")])]
                                      : scope.row.status == 1 &&
                                        scope.row.delivery_type == 2
                                      ? [_c("div", [_vm._v("待自提")])]
                                      : scope.row.status == 1 &&
                                        scope.row.delivery_type == 3
                                      ? [_c("div", [_vm._v("待他人提货")])]
                                      : scope.row.status == 1
                                      ? [_c("div", [_vm._v("待发货")])]
                                      : scope.row.status == 2
                                      ? [_c("div", [_vm._v("待收货")])]
                                      : scope.row.status == 3
                                      ? [_c("div", [_vm._v("待评价")])]
                                      : scope.row.status == 4
                                      ? [_c("div", [_vm._v("已完成")])]
                                      : scope.row.status == -5
                                      ? [_c("div", [_vm._v("待确认")])]
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == 10
                    ? [
                        _c("ytx-table-column", {
                          key: item.field,
                          attrs: {
                            label: "结拍时间",
                            width: "170",
                            "search-key": "date",
                            "search-type": "date",
                            "search-query": _vm.searchQuery,
                          },
                          on: { changeVal: _vm.searchAction },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(_vm._s(scope.row.create_time)),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == 11
                    ? [
                        _c("ytx-table-column", {
                          key: item.field,
                          attrs: {
                            label: "支付时间",
                            width: "170",
                            prop: "payTime",
                            "search-key": "pay_time",
                            "search-query": _vm.searchQuery,
                            sortable: "custom",
                            "search-type": "date",
                          },
                          on: { changeVal: _vm.searchAction },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(_vm._s(scope.row.pay_time)),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == 2
                    ? [
                        _c("ytx-table-column", {
                          key: item.field,
                          attrs: {
                            label: "订单编号",
                            width: "200",
                            prop: "",
                            "search-key": "order_sn",
                            "search-query": _vm.searchQuery,
                          },
                          on: { changeVal: _vm.searchAction },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(scope.row.order_sn) +
                                        "\n            "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _vm.tableFrom.status > 1
                          ? _c("ytx-table-column", {
                              key: item.field + "-no",
                              attrs: {
                                label: "收款单编号",
                                width: "200",
                                "search-query": _vm.searchQuery,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        row.recharge_order
                                          ? [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.printAction(
                                                        row,
                                                        "pay"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      row.recharge_order
                                                        .out_trade_no
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : _vm._e(),
                      ]
                    : item.field == "print_invoice_no"
                    ? [
                        _c("ytx-table-column", {
                          key: item.field,
                          attrs: {
                            label: "发票编号",
                            width: "200",
                            prop: "",
                            "search-key": "print_invoice_no",
                            "search-query": _vm.searchQuery,
                          },
                          on: { changeVal: _vm.searchAction },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          scope.row.extend_data &&
                                            scope.row.extend_data.print
                                            ? scope.row.extend_data.print
                                                .invoice_no
                                            : ""
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == 14
                    ? [
                        _c("ytx-table-column", {
                          key: item.field,
                          attrs: {
                            permission: "merchantAuctionOrderLst",
                            "permission-column": "commission_price",
                            label: "成交价",
                            width: "160",
                            prop: "",
                            "search-query": _vm.searchQuery,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v("落槌价: "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.fee_type.replace(
                                              "CNY",
                                              "RMB"
                                            )
                                          ) +
                                            " " +
                                            _vm._s(
                                              _vm.formatPriceNumber(
                                                scope.row.orderProductOne
                                                  ? scope.row.orderProductOne
                                                      .product_price
                                                  : scope.row.orderProduct[0]
                                                      .product_price,
                                                2
                                              )
                                            )
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v("佣金: "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatPriceNumber(
                                              scope.row.commission_price,
                                              2
                                            )
                                          ) +
                                            " / " +
                                            _vm._s(
                                              _vm.formatPriceNumber(
                                                scope.row.commission_rate,
                                                2
                                              )
                                            ) +
                                            "%"
                                        ),
                                      ]),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == 16
                    ? [
                        _c("ytx-table-column", {
                          key: item.field,
                          attrs: {
                            permission: "merchantAuctionOrderLst",
                            "permission-column": "commission_price",
                            label: "实际支付",
                            width: "120",
                            prop: "",
                            "search-query": _vm.searchQuery,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.pay_price
                                            ? (scope.row.album.feeTypeInfo
                                                ? scope.row.album.feeTypeInfo.mer_fee_type.replace(
                                                    "CNY",
                                                    "RMB"
                                                  )
                                                : "") +
                                                " " +
                                                _vm.formatPriceNumber(
                                                  scope.row.pay_price,
                                                  2
                                                )
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == 7
                    ? [
                        _c("ytx-table-column", {
                          key: item.field,
                          attrs: {
                            label: "图片",
                            width: "50",
                            align: "center",
                            prop: "",
                            "search-query": _vm.searchQuery,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "right",
                                          width: "200",
                                          trigger: "hover",
                                        },
                                      },
                                      [
                                        _c("el-image", {
                                          staticStyle: { width: "175px" },
                                          attrs: {
                                            src: scope.row.orderProductOne
                                              ? scope.row.orderProductOne
                                                  .cart_info.product.image +
                                                "!m640"
                                              : scope.row.orderProduct[0]
                                                  .cart_info.product.image +
                                                "!m640",
                                            "preview-src-list": [
                                              scope.row.orderProductOne
                                                ? scope.row.orderProductOne
                                                    .cart_info.product.image
                                                : scope.row.orderProduct[0]
                                                    .cart_info.product.image,
                                            ],
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-image", {
                                          staticStyle: {
                                            width: "25px",
                                            height: "25px",
                                            "margin-top": "4px",
                                          },
                                          attrs: {
                                            slot: "reference",
                                            src: scope.row.orderProductOne
                                              ? scope.row.orderProductOne
                                                  .cart_info.product.image +
                                                "!m640"
                                              : scope.row.orderProduct[0]
                                                  .cart_info.product.image +
                                                "!m640",
                                            fit: "cover",
                                          },
                                          slot: "reference",
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == "total_postage"
                    ? _c("ytx-table-column", {
                        key: item.field,
                        attrs: {
                          label: "运费",
                          width: "50",
                          prop: "",
                          "search-query": _vm.searchQuery,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  [
                                    _vm._v(
                                      _vm._s(
                                        row.total_postage
                                          ? row.total_postage
                                          : "-"
                                      )
                                    ),
                                  ],
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "15"
                    ? _c("ytx-table-column", {
                        key: item.field,
                        attrs: {
                          label: "优惠",
                          width: "50",
                          prop: "",
                          "search-query": _vm.searchQuery,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.admin_discount_price
                                          ? "-" +
                                              _vm.formatPriceNumber(
                                                scope.row.admin_discount_price,
                                                2
                                              )
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "total_price"
                    ? _c("ytx-table-column", {
                        key: item.field,
                        attrs: {
                          label: "合计金额",
                          width: "120",
                          prop: "",
                          "search-query": _vm.searchQuery,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.fee_type.replace("CNY", "RMB")
                                      ) +
                                        " " +
                                        _vm._s(
                                          scope.row.total_price
                                            ? _vm.formatPriceNumber(
                                                scope.row.total_price,
                                                2
                                              )
                                            : "-"
                                        )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : item.field == "delivery_type"
                    ? _c("ytx-table-column", {
                        key: item.field,
                        attrs: {
                          label: "发货方式",
                          width: "120",
                          "search-key": "delivery_type",
                          "search-type": "radio",
                          "search-query": _vm.searchQuery,
                          options: [
                            { label: "待确认", value: "0" },
                            { label: "快递", value: 1 },
                            { label: "自提", value: 2 },
                            { label: "委托他人提货", value: 3 },
                          ],
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.delivery_type == 0
                                    ? _c("span", [_vm._v("待确认")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.delivery_type == 1
                                    ? _c("span", [_vm._v("快递")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.delivery_type == 2
                                    ? _c("span", [_vm._v("自提")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.delivery_type == 3
                                    ? _c("span", [_vm._v("委托他人提货")])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : [
                        (item.field != 16 && item.field != 13) ||
                        item.field == 16 ||
                        item.field == 13
                          ? _c("ytx-table-column", {
                              key: item.field,
                              attrs: {
                                type: item.field,
                                index: index,
                                width: item.width,
                                label: item.name,
                                align:
                                  item.field > 3 && item.field < 17
                                    ? "left"
                                    : "left",
                                "show-overflow-tooltip": "",
                                prop: "",
                                "search-query": _vm.searchQuery,
                                "permission-column":
                                  item.permissionColumn || "",
                                permission: item.permission || "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        item.field == 11
                                          ? [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(scope.row.pay_time)
                                                ),
                                              ]),
                                            ]
                                          : item.field == 18
                                          ? [
                                              _c("div", [
                                                _vm._v(_vm._s(scope.row.mark)),
                                              ]),
                                            ]
                                          : item.field == 19
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "flex",
                                                  staticStyle: {
                                                    height: "100%",
                                                  },
                                                },
                                                [
                                                  scope.row.remark_images
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex flex-wrap",
                                                        },
                                                        _vm._l(
                                                          scope.row.remark_images.split(
                                                            ","
                                                          ),
                                                          function (m, i) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key:
                                                                  "remark_images" +
                                                                  i,
                                                                staticClass:
                                                                  "mr5",
                                                              },
                                                              [
                                                                _c("img", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "25px",
                                                                    height:
                                                                      "25px",
                                                                    "margin-top":
                                                                      "5px",
                                                                    cursor:
                                                                      "pointer",
                                                                  },
                                                                  attrs: {
                                                                    src:
                                                                      m +
                                                                      "!120a",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showImgAction(
                                                                          m
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "nowrap",
                                                      staticStyle: {
                                                        flex: "1",
                                                        width: "0",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(scope.row.remark)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : _vm._e(),
                      ],
                ]
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "",
                  width: "36",
                  prop: "",
                  "search-query": _vm.searchQuery,
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c("svg-icon", {
                          staticStyle: { margin: "0" },
                          attrs: {
                            "icon-class": "IconamoonFlagLight",
                            color: "#333",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "left",
                              width: "60",
                              "popper-class": "btn-pop-view",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "flex-item-center",
                                  staticStyle: {
                                    cursor: "pointer",
                                    padding: "10px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setOrderBannerAction(
                                        scope.$index,
                                        scope.row.order_id,
                                        "red"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      "icon-class": "IconamoonFlagLight",
                                      color: "#ff6969",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "flex-item-center",
                                  staticStyle: {
                                    cursor: "pointer",
                                    padding: "10px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setOrderBannerAction(
                                        scope.$index,
                                        scope.row.order_id,
                                        "yellow"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      "icon-class": "IconamoonFlagLight",
                                      color: "#ffaa00",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "flex-item-center",
                                  staticStyle: {
                                    cursor: "pointer",
                                    padding: "10px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setOrderBannerAction(
                                        scope.$index,
                                        scope.row.order_id,
                                        "blue"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      "icon-class": "IconamoonFlagLight",
                                      color: "#77aaff",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "flex-item-center",
                                  staticStyle: {
                                    cursor: "pointer",
                                    padding: "10px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setOrderBannerAction(
                                        scope.$index,
                                        scope.row.order_id,
                                        ""
                                      )
                                    },
                                  },
                                },
                                [_vm._v("\n                空\n              ")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  padding: "10px 0",
                                },
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                scope.row.extend_data &&
                                scope.row.extend_data.banner_color
                                  ? [
                                      scope.row.extend_data.banner_color ==
                                      "red"
                                        ? _c(
                                            "div",
                                            { staticClass: "flex-item-center" },
                                            [
                                              _c("svg-icon", {
                                                staticStyle: { margin: "0" },
                                                attrs: {
                                                  "icon-class":
                                                    "IconamoonFlagLight",
                                                  color: "#ff6969",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : scope.row.extend_data.banner_color ==
                                          "yellow"
                                        ? _c(
                                            "div",
                                            { staticClass: "flex-item-center" },
                                            [
                                              _c("svg-icon", {
                                                staticStyle: { margin: "0" },
                                                attrs: {
                                                  "icon-class":
                                                    "IconamoonFlagLight",
                                                  color: "#ffaa00",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : scope.row.extend_data.banner_color ==
                                          "blue"
                                        ? _c(
                                            "div",
                                            { staticClass: "flex-item-center" },
                                            [
                                              _c("svg-icon", {
                                                staticStyle: { margin: "0" },
                                                attrs: {
                                                  "icon-class":
                                                    "IconamoonFlagLight",
                                                  color: "#77aaff",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "",
                  width: "36",
                  prop: "",
                  "search-query": _vm.searchQuery,
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("i", { staticClass: "el-icon-printer" })]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.orderPrintRecord &&
                        scope.row.orderPrintRecord.create_time
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content:
                                    scope.row.orderPrintRecord.create_time,
                                  placement: "top-end",
                                  effect: "light",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-document-checked cur",
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              !_vm.tableFrom.status ||
              _vm.tableFrom.status == 8 ||
              _vm.tableFrom.status == 1 ||
              _vm.tableFrom.status == 2 ||
              _vm.tableFrom.status == 3 ||
              _vm.tableFrom.status == 4 ||
              _vm.tableFrom.status == 5
                ? [
                    _c("ytx-table-column", {
                      key: "20",
                      attrs: {
                        label: "",
                        width: "36",
                        fixed: "right",
                        align: "center",
                        "search-clear": true,
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "TableRightBtn",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onOrderLog(
                                              scope.row.order_id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "order-jilu",
                                            "class-name": "blue",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                订单记录\n              "
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "template",
                                      { slot: "pop_btn" },
                                      [
                                        scope.row.is_refund === 0 &&
                                        scope.row.status > 0
                                          ? [
                                              _c(
                                                "el-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "permission",
                                                      rawName: "v-permission",
                                                      value:
                                                        "merchantCreateAuctionRefund",
                                                      expression:
                                                        "'merchantCreateAuctionRefund'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "text",
                                                    size: "small",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showRefundAction(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "icon-class":
                                                        "order-return",
                                                      "class-name": "blue",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "\n                    手动退款\n                  "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.row.is_refund !== 0
                                          ? [
                                              _c(
                                                "el-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "permission",
                                                      rawName: "v-permission",
                                                      value:
                                                        "merchantCreateAuctionRefund",
                                                      expression:
                                                        "'merchantCreateAuctionRefund'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "text",
                                                    size: "small",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onRefundDetail(
                                                        scope.row.order_sn
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "icon-class":
                                                        "commonlook",
                                                      "class-name": "blue",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "\n                    查看退款单\n                  "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          : scope.row.status == -3 ||
                                            scope.row.status == 0
                                          ? [
                                              scope.row.uid !== 8888
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "permission",
                                                          rawName:
                                                            "v-permission",
                                                          value:
                                                            "merchantStoreOrderAuctionOrderSettlement",
                                                          expression:
                                                            "'merchantStoreOrderAuctionOrderSettlement'",
                                                        },
                                                      ],
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.settlementAction(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("svg-icon", {
                                                        attrs: {
                                                          "icon-class":
                                                            "order-jiesuan",
                                                          "class-name": "blue",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n                    结算\n                  "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              scope.row.uid !== 8888
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.sendPaymentReminder(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("svg-icon", {
                                                        attrs: {
                                                          "icon-class":
                                                            "order-cuikuan",
                                                          "class-name": "blue",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n                    催款\n                  "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          : scope.row.status == 1
                                          ? [
                                              [
                                                scope.row.delivery_type == 1
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "permission",
                                                            rawName:
                                                              "v-permission",
                                                            value:
                                                              "merchantStoreOrderDelivery",
                                                            expression:
                                                              "'merchantStoreOrderDelivery'",
                                                          },
                                                        ],
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.sendAction(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("svg-icon", {
                                                          attrs: {
                                                            "icon-class":
                                                              "order-send",
                                                            "class-name":
                                                              "blue",
                                                          },
                                                        }),
                                                        _vm._v(
                                                          "\n                      发货\n                    "
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : scope.row.delivery_type ==
                                                      2 ||
                                                    scope.row.delivery_type == 3
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "permission",
                                                            rawName:
                                                              "v-permission",
                                                            value:
                                                              "merchantStoreOrderDelivery",
                                                            expression:
                                                              "'merchantStoreOrderDelivery'",
                                                          },
                                                        ],
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.sendAction(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("svg-icon", {
                                                          attrs: {
                                                            "icon-class":
                                                              "common-shenhe",
                                                            "class-name":
                                                              "blue",
                                                          },
                                                        }),
                                                        _vm._v(
                                                          "\n                      确认自提\n                    "
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "el-button",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "permission",
                                                            rawName:
                                                              "v-permission",
                                                            value:
                                                              "merchantStoreOrderDelivery",
                                                            expression:
                                                              "'merchantStoreOrderDelivery'",
                                                          },
                                                        ],
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.sendAction(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("svg-icon", {
                                                          attrs: {
                                                            "icon-class":
                                                              "order-send",
                                                            "class-name":
                                                              "blue",
                                                          },
                                                        }),
                                                        _vm._v(
                                                          "\n                      发货\n                    "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                            ]
                                          : scope.row.status == 2
                                          ? [
                                              _c(
                                                "el-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "permission",
                                                      rawName: "v-permission",
                                                      value:
                                                        "merchantStoreOrderUpdateDelivery",
                                                      expression:
                                                        "'merchantStoreOrderUpdateDelivery'",
                                                    },
                                                  ],
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editDeliveryAction(
                                                        scope.row.order_id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "icon-class":
                                                        "order-send",
                                                      "class-name": "blue",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "\n                    修改发货\n                  "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.row.status == -3 ||
                                        scope.row.status == 0 ||
                                        scope.row.status == -5
                                          ? [
                                              _c(
                                                "el-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "permission",
                                                      rawName: "v-permission",
                                                      value:
                                                        "merchantStoreOrderUpdateAuctionOrder",
                                                      expression:
                                                        "'merchantStoreOrderUpdateAuctionOrder'",
                                                    },
                                                  ],
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editOrderAction(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "icon-class":
                                                        "commonedit",
                                                      "class-name": "blue",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "\n                    修改\n                  "
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "permission",
                                                      rawName: "v-permission",
                                                      value:
                                                        "merchantStoreOrderCancelAuctionOrder",
                                                      expression:
                                                        "'merchantStoreOrderCancelAuctionOrder'",
                                                    },
                                                  ],
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editOrderAction(
                                                        scope.row,
                                                        "del"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "icon-class":
                                                        "order-cencle",
                                                      "class-name": "blue",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "\n                    取消\n                  "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            directives: [
                                              {
                                                name: "permission",
                                                rawName: "v-permission",
                                                value:
                                                  "merchantStoreOrderRemark",
                                                expression:
                                                  "'merchantStoreOrderRemark'",
                                              },
                                            ],
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.setRemarkAction(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "order-remark",
                                                "class-name": "blue",
                                              },
                                            }),
                                            _vm._v(
                                              "\n                  备注\n                "
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        scope.row.status > 1 &&
                                        scope.row.delivery_type == 1
                                          ? [
                                              _c(
                                                "el-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "permission",
                                                      rawName: "v-permission",
                                                      value:
                                                        "merchantStoreOrderExpress",
                                                      expression:
                                                        "'merchantStoreOrderExpress'",
                                                    },
                                                  ],
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openLogistics(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "icon-class":
                                                        "order-wuliu",
                                                      "class-name": "blue",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "\n                    物流跟踪\n                  "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        809509864
                      ),
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.tableFrom.status == 9
                ? [
                    _c("ytx-table-column", {
                      key: "20",
                      attrs: {
                        label: "",
                        width: "36",
                        fixed: "right",
                        align: "center",
                        "search-clear": true,
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "TableRightBtn",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "ft13",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onUpdSta(
                                              scope.row.order_id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 修改状态 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1157652025
                      ),
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.tableFrom.status == 6 || _vm.tableFrom.status == 7
                ? [
                    _c("ytx-table-column", {
                      attrs: {
                        label: "",
                        width: "120",
                        fixed: "right",
                        align: "center",
                        "search-clear": true,
                        "search-query": _vm.searchQuery,
                      },
                      on: { changeVal: _vm.searchAction },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "ft13",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onOrderLog(
                                          scope.row.order_id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        "icon-class": "order-jilu",
                                        "class-name": "blue",
                                      },
                                    }),
                                    _vm._v(
                                      "\n              订单记录\n            "
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2632268787
                      ),
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-b orderFoot" },
        [
          _c(
            "div",
            { staticClass: "flex-one" },
            [
              _c("span", [
                _vm._v(_vm._s("已选择  " + _vm.changeOrder.length + "  条")),
              ]),
              _vm._v(" "),
              [1].includes(Number(_vm.tableFrom.status))
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "4px" },
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.onPrintDeal("pay")
                        },
                      },
                    },
                    [_vm._v("打印成交明细\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              [1, 2, 3, 5].includes(Number(_vm.tableFrom.status)) &&
              _vm.mer_id == 338807
                ? [
                    _c(
                      "el-dropdown",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: "merchantStoreOrderGetAuctionOrderBillInfo",
                            expression:
                              "'merchantStoreOrderGetAuctionOrderBillInfo'",
                          },
                        ],
                        attrs: { type: "primary", placement: "top" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary", size: "small" },
                          },
                          [_vm._v("打印结算单")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "left",
                                    },
                                    attrs: { size: "small", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.printAllAction("pay")
                                      },
                                    },
                                  },
                                  [_vm._v("直接打印")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "left",
                                    },
                                    attrs: { size: "small", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.printCoverAction("pay")
                                      },
                                    },
                                  },
                                  [_vm._v("使用套打")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : [1, 2, 3, 5].includes(Number(_vm.tableFrom.status))
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: "merchantStoreOrderGetAuctionOrderBillInfo",
                          expression:
                            "'merchantStoreOrderGetAuctionOrderBillInfo'",
                        },
                      ],
                      staticStyle: { "margin-left": "4px" },
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.printAllAction("pay")
                        },
                      },
                    },
                    [_vm._v("打印结算单\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$store.getters.is_multilingual
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: "merchantStoreOrderGetAuctionOrderBillInfo",
                          expression:
                            "'merchantStoreOrderGetAuctionOrderBillInfo'",
                        },
                      ],
                      staticStyle: { "margin-left": "4px" },
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.invoicePrintViewAction("invoice")
                        },
                      },
                    },
                    [_vm._v("打印发票\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.tableFrom.status == 1
                ? [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: "merchantStoreOrderAuctionOrderSettlement",
                            expression:
                              "'merchantStoreOrderAuctionOrderSettlement'",
                          },
                        ],
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.settlementAllAction },
                      },
                      [_vm._v("结算")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown",
                      { attrs: { type: "primary", placement: "top" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary", size: "small" },
                          },
                          [_vm._v("修改")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value:
                                      "merchantStoreOrderUpdateAuctionOrder",
                                    expression:
                                      "'merchantStoreOrderUpdateAuctionOrder'",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "left",
                                    },
                                    attrs: { size: "small", type: "text" },
                                    on: { click: _vm.updOrder },
                                  },
                                  [_vm._v("修改订单")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value:
                                      "merchantStoreOrderUpdateAuctionOrder",
                                    expression:
                                      "'merchantStoreOrderUpdateAuctionOrder'",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "left",
                                    },
                                    attrs: { size: "small", type: "text" },
                                    on: { click: _vm.editCommissionAction },
                                  },
                                  [_vm._v("修改佣金")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: "merchantAuctionOrderJuhui",
                            expression: "'merchantAuctionOrderJuhui'",
                          },
                        ],
                        staticStyle: { "margin-left": "10px" },
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.onSign },
                      },
                      [_vm._v("举回")]
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.tableFrom.status == 2
                ? [
                    _c(
                      "el-dropdown",
                      { attrs: { type: "primary", placement: "top" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary", size: "small" },
                          },
                          [_vm._v("发货")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "left",
                                    },
                                    attrs: { size: "small", type: "text" },
                                    on: { click: _vm.onOutbound },
                                  },
                                  [_vm._v("创建出库单")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "merchantStoreOrderDelivery",
                                    expression: "'merchantStoreOrderDelivery'",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "left",
                                    },
                                    attrs: { size: "small", type: "text" },
                                    on: { click: _vm.sendAllAction },
                                  },
                                  [_vm._v("填写物流信息")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-dropdown",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantSystemExcelExport:auction_order",
                      expression: "'merchantSystemExcelExport:auction_order'",
                    },
                  ],
                  attrs: { type: "primary", placement: "top" },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "primary", size: "small" },
                    },
                    [_vm._v("导出")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                width: "100%",
                                "text-align": "left",
                              },
                              attrs: { size: "small", type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.getExportFileList("auction_order")
                                },
                              },
                            },
                            [_vm._v("查看导出记录")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                width: "100%",
                                "text-align": "left",
                              },
                              attrs: { size: "small", type: "text" },
                              on: { click: _vm.exportTransOrder },
                            },
                            [_vm._v("导出成交明细")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                width: "100%",
                                "text-align": "left",
                              },
                              attrs: { size: "small", type: "text" },
                              on: { click: _vm.exportOrder },
                            },
                            [_vm._v("导出订单表格")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.tableFrom.status == 2
                        ? _c(
                            "el-dropdown-item",
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "text-align": "left",
                                  },
                                  attrs: { size: "small", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.exportOrder(
                                        "auctionOrderDelivery_sf"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("导出发货表")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                width: "100%",
                                "text-align": "left",
                              },
                              attrs: { size: "small", type: "text" },
                              on: { click: _vm.exportAuctionCertOrder },
                            },
                            [_vm._v("导出拍品证书")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.tableData.total,
              disabled: _vm.listLoading,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("Settlement", { ref: "settlement", on: { payOk: _vm.getList } }),
      _vm._v(" "),
      _c("Send", {
        ref: "sendEL",
        attrs: { "express-list": _vm.expressList },
        on: { sendOk: _vm.getList },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editOrder.show,
            "close-on-click-modal": false,
            title: _vm.editOrder.del ? "取消订单" : "修改订单",
            width: "650px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editOrder, "show", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "edit-order item-align-center mt20" }, [
            _c("div", { staticClass: "item-align-center item" }, [
              _c("div", { staticClass: "l" }, [_vm._v("拍品信息：")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "r", staticStyle: { "font-weight": "500" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.editOrder.order.lot
                        ? "LOT " + _vm.editOrder.order.lot
                        : _vm.editOrder.order.name
                    ) + "\n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "edit-order item-align-center mt20" }, [
            _c("div", { staticClass: "item-align-center item" }, [
              _c("div", { staticClass: "l" }, [_vm._v("买家信息：")]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "r flex item-align-center",
                  staticStyle: { "font-weight": "500", cursor: "pointer" },
                  on: { click: _vm.editUserAction },
                },
                [
                  _vm.editOrder.user
                    ? _c("div", [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.editOrder.editUser
                                ? _vm.editOrder.editUser.nickname
                                : _vm.editOrder.user.nickname
                            ) +
                              _vm._s(
                                _vm.editOrder.numberPlate
                                  ? "：号牌" + _vm.editOrder.numberPlate.number
                                  : ""
                              ) +
                              "\n            "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editOrder.userAccount
                    ? _c(
                        "div",
                        {
                          staticClass: "ft12 color-gray",
                          staticStyle: { "margin-left": "10px" },
                        },
                        [
                          _vm._v(
                            "钱包可用余额 " +
                              _vm._s(_vm.feeTypeInfo.type) +
                              " " +
                              _vm._s(
                                _vm.formatPriceNumber(
                                  _vm.editOrder.userAccount.balance,
                                  2
                                )
                              ) +
                              "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.editOrder.del
                    ? _c("div", { staticClass: "edit" }, [_vm._v("修改")])
                    : _vm._e(),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "edit-order item-align-center mt20" }, [
            _c("div", { staticClass: "item-align-center item" }, [
              _c("div", { staticClass: "l" }, [
                _vm._v("佣金"),
                _vm.editOrder.album.feeTypeInfo
                  ? _c("span", { staticClass: "ft12 color-gray" }, [
                      _vm._v(
                        "(" +
                          _vm._s(
                            _vm.editOrder.album.feeTypeInfo.type == "CNY"
                              ? "RMB"
                              : _vm.editOrder.album.feeTypeInfo.type
                          ) +
                          ")"
                      ),
                    ])
                  : _vm._e(),
                _vm._v("："),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "r" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.commission_price) + "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-align-center item" }, [
              _c("div", { staticClass: "l" }, [
                _vm._v("成交价"),
                _vm.editOrder.album.feeTypeInfo
                  ? _c("span", { staticClass: "ft12 color-gray" }, [
                      _vm._v(
                        "(" +
                          _vm._s(
                            _vm.editOrder.album.feeTypeInfo.type == "CNY"
                              ? "RMB"
                              : _vm.editOrder.album.feeTypeInfo.type
                          ) +
                          ")"
                      ),
                    ])
                  : _vm._e(),
                _vm._v("："),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "r" }, [
                _vm._v("\n          " + _vm._s(_vm.all_price) + "\n        "),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "edit-order item-align-center mt20" }, [
            _c("div", { staticClass: "item-align-center item" }, [
              _c("div", { staticClass: "l" }, [
                _vm._v("落槌价"),
                _vm.editOrder.album.feeTypeInfo
                  ? _c("span", { staticClass: "ft12 color-gray" }, [
                      _vm._v(
                        "(" +
                          _vm._s(
                            _vm.editOrder.album.feeTypeInfo.type == "CNY"
                              ? "RMB"
                              : _vm.editOrder.album.feeTypeInfo.type
                          ) +
                          ")"
                      ),
                    ])
                  : _vm._e(),
                _vm._v("："),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "r" },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "150px" },
                    attrs: {
                      type: "text",
                      controls: false,
                      disabled: _vm.editOrder.del != "",
                    },
                    model: {
                      value: _vm.editOrder.order.auction_now_price,
                      callback: function ($$v) {
                        _vm.$set(_vm.editOrder.order, "auction_now_price", $$v)
                      },
                      expression: "editOrder.order.auction_now_price",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.editOrder.proxyPlatformFrozenInfo
              ? _c("div", { staticClass: "item-align-center item" }, [
                  _c("div", { staticClass: "l" }, [
                    _vm._v(
                      _vm._s(
                        _vm.editOrder.proxyPlatformFrozenInfo.bond_type == 1
                          ? "扣除"
                          : "冻结"
                      ) + "保证金"
                    ),
                    _c("span", { staticClass: "ft12 color-gray" }, [
                      _vm._v("(" + _vm._s(_vm.feeTypeInfo.type) + ")"),
                    ]),
                    _vm._v("："),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "r" },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "150px" },
                        attrs: { type: "text", controls: false },
                        model: {
                          value:
                            _vm.editOrder.proxyPlatformFrozenInfo.pay_money,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.editOrder.proxyPlatformFrozenInfo,
                              "pay_money",
                              $$v
                            )
                          },
                          expression:
                            "editOrder.proxyPlatformFrozenInfo.pay_money",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _c("div", { staticClass: "item-align-center item" }, [
                  _c("div", { staticClass: "l" }, [
                    _vm._v(
                      _vm._s(
                        _vm.editOrder.order.bond_type == 1 ? "扣除" : "冻结"
                      ) + "保证金"
                    ),
                    _c("span", { staticClass: "ft12 color-gray" }, [
                      _vm._v("(" + _vm._s(_vm.feeTypeInfo.type) + ")"),
                    ]),
                    _vm._v("："),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "r" },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "150px" },
                        attrs: { type: "text", controls: false },
                        model: {
                          value: _vm.bondMoney,
                          callback: function ($$v) {
                            _vm.bondMoney = $$v
                          },
                          expression: "bondMoney",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
          ]),
          _vm._v(" "),
          !_vm.editOrder.del
            ? [
                _c("div", {
                  staticStyle: {
                    "border-top": "1px solid #ccc",
                    margin: "20px 0",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { "font-size": "18px", color: "#000" } },
                  [_vm._v("设置优惠")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "edit-order item-align-center mt20" },
                  [
                    _c("div", { staticClass: "item-align-center item" }, [
                      _c("div", { staticClass: "l" }, [_vm._v("佣金(%)：")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "r" },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "150px" },
                            attrs: { controls: false, min: 0, max: 100 },
                            model: {
                              value: _vm.editOrder.order.commission_rate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editOrder.order,
                                  "commission_rate",
                                  $$v
                                )
                              },
                              expression: "editOrder.order.commission_rate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "edit-order item-align-center mt20" },
                  [
                    _c("div", { staticClass: "item-align-center item" }, [
                      _c("div", { staticClass: "l" }, [
                        _vm._v("优惠金额"),
                        _vm.editOrder.album.feeTypeInfo
                          ? _c("span", { staticClass: "ft12 color-gray" }, [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    _vm.editOrder.album.feeTypeInfo.type ==
                                      "CNY"
                                      ? "RMB"
                                      : _vm.editOrder.album.feeTypeInfo.type
                                  ) +
                                  ")"
                              ),
                            ])
                          : _vm._e(),
                        _vm._v("："),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "r" },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "150px" },
                            attrs: { controls: false, max: _vm.all_price },
                            model: {
                              value: _vm.editOrder.order.admin_discount_price,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editOrder.order,
                                  "admin_discount_price",
                                  $$v
                                )
                              },
                              expression:
                                "editOrder.order.admin_discount_price",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "edit-order item-align-center mt20" },
                  [
                    _c("div", { staticClass: "item-align-center item" }, [
                      _c("div", { staticClass: "l" }, [
                        _vm._v("确认成交价"),
                        _vm.editOrder.album.feeTypeInfo
                          ? _c("span", { staticClass: "ft12 color-gray" }, [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    _vm.editOrder.album.feeTypeInfo.type ==
                                      "CNY"
                                      ? "RMB"
                                      : _vm.editOrder.album.feeTypeInfo.type
                                  ) +
                                  ")"
                              ),
                            ])
                          : _vm._e(),
                        _vm._v("："),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "r" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.allPrice) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "flex mt40" }, [
                  _c(
                    "div",
                    { staticClass: "close-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          staticStyle: { "margin-right": "20px" },
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.editOkAction },
                        },
                        [_vm._v("\n            确认修改\n          ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "close-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { size: "small" },
                          on: { click: _vm.closeEditAction },
                        },
                        [_vm._v("关闭")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            : [
                _c("div", { staticClass: "flex mt40" }, [
                  _c(
                    "div",
                    { staticClass: "close-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          staticStyle: { "margin-right": "20px" },
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.closeOrderAction(1)
                            },
                          },
                        },
                        [_vm._v("\n            扣除保证金\n          ")]
                      ),
                      _vm._v(" "),
                      _c("div"),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "close-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          staticStyle: { "margin-right": "20px" },
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.closeOrderAction(2)
                            },
                          },
                        },
                        [_vm._v("\n            解冻保证金\n          ")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("保证金回到"),
                        _c("br"),
                        _vm._v("客户余额"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "close-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.closeOrderAction(3)
                            },
                          },
                        },
                        [_vm._v("退回保证金")]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("保证金回到"),
                        _c("br"),
                        _vm._v("客户银行卡"),
                      ]),
                    ],
                    1
                  ),
                ]),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showImg,
            "close-on-click-modal": false,
            center: "",
            title: "查看",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.showImgUrl + "!m640", alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.refundData.show,
            "close-on-click-modal": false,
            center: "",
            title: "手动退款",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.refundData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "item-flex-center mt20 ml20",
              staticStyle: { "justify-content": "start" },
            },
            [
              _c("div", { staticClass: "l" }, [_vm._v("退款金额：")]),
              _vm._v(" "),
              _c("el-input-number", {
                attrs: { max: _vm.refundData.max_refund_price },
                model: {
                  value: _vm.refundData.refund_price,
                  callback: function ($$v) {
                    _vm.$set(_vm.refundData, "refund_price", $$v)
                  },
                  expression: "refundData.refund_price",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-flex-center mt20 ml20",
              staticStyle: { "justify-content": "start" },
            },
            [
              _c("div", { staticClass: "l" }, [_vm._v("订单修改为：")]),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.refundData.origin_order_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.refundData, "origin_order_status", $$v)
                    },
                    expression: "refundData.origin_order_status",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "-1" } }, [
                    _vm._v("已退款"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "1" } }, [_vm._v("待发货")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "2" } }, [_vm._v("待收货")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "4" } }, [_vm._v("已完成")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-flex-center mt20" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.refundData.show = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.refundConfirmDataAction },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editCommissionData.show,
            "close-on-click-modal": false,
            center: "",
            title: "批量修改佣金",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editCommissionData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "item-flex-center mt20" },
            [
              _c("div", { staticClass: "l" }, [_vm._v("修改佣金为：")]),
              _vm._v(" "),
              _c("el-input", {
                staticClass: "w200",
                model: {
                  value: _vm.editCommissionData.commission_rate,
                  callback: function ($$v) {
                    _vm.$set(_vm.editCommissionData, "commission_rate", $$v)
                  },
                  expression: "editCommissionData.commission_rate",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "ml5" }, [_vm._v("%")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-flex-center mt20" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editCommissionData.show = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.editCommissionDataAction },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("ChangeUser", {
        ref: "changeUser",
        on: { changeUid: _vm.changeUidAction },
      }),
      _vm._v(" "),
      _c("SettlementPrint", { ref: "printView", on: { load: _vm.getList } }),
      _vm._v(" "),
      _c("SettlementPrintInvoice", {
        ref: "invoicePrintView",
        on: { load: _vm.getList },
      }),
      _vm._v(" "),
      _c("SettlementPrintCoverNew", {
        ref: "printCoverView",
        on: { load: _vm.getList },
      }),
      _vm._v(" "),
      _c("TransPrint", { ref: "transPrintRef", on: { load: _vm.getList } }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: {
                editMark: _vm.getList,
                close: _vm.userdetailclose,
                editPhone: _vm.editPhoneAction,
              },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "物流查询",
            visible: _vm.dialogLogistics,
            width: "350px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogLogistics = $event
            },
          },
        },
        [
          _vm.orderDatalist
            ? _c("logistics-from", {
                attrs: {
                  "order-datalist": _vm.orderDatalist,
                  result: _vm.result,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作记录",
            visible: _vm.dialogVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.LogLoading,
                  expression: "LogLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { border: "", data: _vm.tableDataLog.data },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "order_id",
                  align: "center",
                  label: "订单ID",
                  "min-width": "80",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "change_message",
                  label: "操作记录",
                  align: "center",
                  "min-width": "280",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "change_time",
                  label: "操作时间",
                  align: "center",
                  "min-width": "280",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFromLog.limit,
                  "current-page": _vm.tableFromLog.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableDataLog.total,
                },
                on: {
                  "size-change": _vm.handleSizeChangeLog,
                  "current-change": _vm.pageChangeLog,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("SetColumn", {
        ref: "setColumnEL",
        attrs: { types: "pm_order_list" },
      }),
      _vm._v(" "),
      _c("GoodsDeta", { ref: "goods" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visibleBol,
            title: "创建出库单",
            top: "5vh",
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visibleBol = $event
            },
          },
        },
        [
          _vm._l(_vm.outBoundForm, function (item, index) {
            return _c(
              "div",
              { key: index, staticStyle: { "margin-bottom": "20px" } },
              [
                _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                  _c("span", [_vm._v("收件信息：")]),
                  _vm._v(
                    " " +
                      _vm._s(item.real_name) +
                      " " +
                      _vm._s(item.user_phone) +
                      " " +
                      _vm._s(item.user_address) +
                      " \n        "
                  ),
                  item.delivery_type == 1
                    ? _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v("保价金额：" + _vm._s(item.deliveryNum)),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: item.productData, size: "mini", border: "" },
                  },
                  [
                    _c("ytx-table-column", {
                      attrs: {
                        label: "图片",
                        width: "50",
                        prop: "",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("img", {
                                  staticStyle: {
                                    width: "40px",
                                    height: "40px",
                                  },
                                  attrs: {
                                    src: scope.row.cart_info.product.image,
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: { label: "库号", "min-width": "80", prop: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.erpProduct.product_no) +
                                    "\n            "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: { label: "图录号", "min-width": "80", prop: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.cart_info.product.lot) +
                                    "\n            "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: { label: "拍品名称", "min-width": "80", prop: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      scope.row.cart_info.product.store_name
                                    ) +
                                    "\n            "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: { label: "货架", "min-width": "80", prop: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      scope.row.erpProduct.shelf
                                        ? scope.row.erpProduct.shelf.shelf_no
                                        : ""
                                    ) +
                                    "\n            "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    item.delivery_type == 1
                      ? _c("ytx-table-column", {
                          attrs: {
                            label: "保价金额",
                            "min-width": "80",
                            prop: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          scope.row.delivery_insured_price
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("ytx-table-column", {
                      attrs: { label: "用户备注", "min-width": "80", prop: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.user_remark) +
                                    "\n            "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "flex",
                    staticStyle: { "margin-top": "10px" },
                  },
                  [
                    _c("div", { staticStyle: { width: "25%" } }, [
                      _vm._v("出库库房：" + _vm._s(item.warehouseInfo.name)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "25%" } }, [
                      _vm._v("出库类型：销售出库"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "25%" } }, [
                      _vm._v("库管：" + _vm._s(item.warehouseInfo.name)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "25%" } }, [
                      _vm._v("发货方式：\n          "),
                      item.delivery_type == 1
                        ? _c("span", [_vm._v("快递")])
                        : _vm._e(),
                      _vm._v(" "),
                      item.delivery_type == 2
                        ? _c("span", [_vm._v("自提")])
                        : _vm._e(),
                      _vm._v(" "),
                      item.delivery_type == 3
                        ? _c("span", [_vm._v("委托他人提货")])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "25%" } }, [
                      _vm._v("制单人：" + _vm._s(item.adminInfo.real_name)),
                    ]),
                  ]
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm.reductjson.erp_v2_out_warehouse == 1
            ? _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "审批人" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selwidth",
                          attrs: {
                            placeholder: "请选择审批人",
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.reviewAduitId,
                            callback: function ($$v) {
                              _vm.reviewAduitId = $$v
                            },
                            expression: "reviewAduitId",
                          },
                        },
                        _vm._l(_vm.reductjson.adminData, function (item) {
                          return _c("el-option", {
                            key: item.merchant_admin_id,
                            attrs: {
                              label: item.real_name,
                              value: item.merchant_admin_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onOutSubmit } },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.showOnedetail, width: "1000px" },
          on: {
            "update:visible": function ($event) {
              _vm.showOnedetail = $event
            },
          },
        },
        [
          _vm.showOnedetail
            ? _c("outwareOrder", { attrs: { detailjson: _vm.detailjson } })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }