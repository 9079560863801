var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full overflow-hidden" },
    [
      _c("div", {}, [
        _c(
          "div",
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticClass: "list-table hasAbsolute",
                attrs: {
                  data: _vm.list,
                  border: "",
                  "element-loading-text": "Loading",
                  fit: "",
                  stripe: "",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "页面名称", prop: "name", width: "240" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "flex align-items-c relative",
                              style:
                                scope.$index == 1 || scope.$index == 2
                                  ? ""
                                  : "",
                            },
                            [
                              _c("div", { staticClass: "line1" }, [
                                _vm._v(_vm._s(scope.row.name)),
                              ]),
                              _vm._v(" "),
                              scope.$index == 1 || scope.$index == 2
                                ? [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "light",
                                          content:
                                            "未启用时，与微信小程序共用首页",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-warning-outline cur ml5",
                                        }),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              scope.$index < 3
                                ? [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "permission",
                                            rawName: "v-permission:disabled",
                                            value:
                                              "merchantFitmentDiypageUpdate",
                                            expression:
                                              "'merchantFitmentDiypageUpdate'",
                                            arg: "disabled",
                                          },
                                        ],
                                        staticClass: "ml5",
                                        staticStyle: {
                                          position: "relative",
                                          bottom: "2px",
                                        },
                                      },
                                      [
                                        _c("el-switch", {
                                          staticStyle: {
                                            transform: "scale(.8)",
                                          },
                                          attrs: {
                                            "active-color": "#13ce66",
                                            "inactive-color": "#cccccc",
                                            "active-value": 1,
                                            "inactive-value": 0,
                                            disabled: scope.$index === 0,
                                          },
                                          model: {
                                            value: scope.row.isOpen,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "isOpen", $$v)
                                            },
                                            expression: "scope.row.isOpen",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "站内链接", width: "400" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "color-gray line1",
                              staticStyle: {
                                height: "36px",
                                "line-height": "36px",
                              },
                            },
                            [_vm._v(_vm._s(scope.row.page))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "发布状态" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.is_release === null
                            ? void 0
                            : scope.row.is_release !== 1
                            ? _c(
                                "el-tag",
                                {
                                  staticClass: "cur",
                                  attrs: {
                                    size: "small",
                                    type: "danger",
                                    effect: "plain",
                                  },
                                },
                                [_vm._v("\n              未发布\n            ")]
                              )
                            : _c(
                                "el-tag",
                                {
                                  staticClass: "cur",
                                  attrs: {
                                    type: "info",
                                    size: "small",
                                    effect: "plain",
                                  },
                                },
                                [_vm._v("\n              已发布\n            ")]
                              ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "自动发布", "min-width": "140" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.release_plate_time) +
                              "\n            "
                          ),
                          scope.row.is_release !== 1
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setAutoRelease(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("设置")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "196", align: "right" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "merchantFitmentDiypageUpdate",
                                  expression: "'merchantFitmentDiypageUpdate'",
                                },
                              ],
                              attrs: {
                                type: "text",
                                icon: "el-icon-edit-outline",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.editDiy(scope.row.link)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", icon: "el-icon-view" },
                              on: {
                                click: function ($event) {
                                  return _vm.previewAction(scope.row.type)
                                },
                              },
                            },
                            [_vm._v("预览")]
                          ),
                          _vm._v(" "),
                          scope.row.is_release !== 1 &&
                          scope.row.is_release !== null
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: "merchantFitmentDiypageCreate",
                                      expression:
                                        "'merchantFitmentDiypageCreate'",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-position",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.sendAction(scope.row.type)
                                    },
                                  },
                                },
                                [_vm._v("发布")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "common-setting" }, [
        _c("div", { staticClass: "item" }, [
          _c(
            "div",
            { staticClass: "t" },
            [
              _vm._v("\n        通用色系\n        "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantFitmentDiypageUpdate",
                      expression: "'merchantFitmentDiypageUpdate'",
                    },
                  ],
                  staticStyle: { "font-size": "18px" },
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.showSettingAction("page")
                    },
                  },
                },
                [_vm._v("修改")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("TemplatePage", {
                attrs: { facade: _vm.templateColor.page, h: "93px" },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c(
            "div",
            { staticClass: "t" },
            [
              _vm._v("\n        列表风格\n        "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantFitmentDiypageUpdate",
                      expression: "'merchantFitmentDiypageUpdate'",
                    },
                  ],
                  staticStyle: { "font-size": "18px" },
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.showSettingAction("list")
                    },
                  },
                },
                [_vm._v("修改")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("TemplateList", {
                attrs: { facade: _vm.templateColor.list, h: "93px" },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c(
            "div",
            { staticClass: "t" },
            [
              _vm._v("\n        标题栏\n        "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantFitmentDiypageUpdate",
                      expression: "'merchantFitmentDiypageUpdate'",
                    },
                  ],
                  staticStyle: { "font-size": "18px" },
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.showSettingAction("title")
                    },
                  },
                },
                [_vm._v("修改")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("Title", {
                attrs: { facade: _vm.templateColor.title, only: "1" },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c(
            "div",
            { staticClass: "t" },
            [
              _vm._v("\n        底部导航\n        "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantFitmentDiypageUpdate",
                      expression: "'merchantFitmentDiypageUpdate'",
                    },
                  ],
                  staticStyle: { "font-size": "18px" },
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.showSettingAction("footer")
                    },
                  },
                },
                [_vm._v("修改")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "content flex align-items-c",
              staticStyle: { height: "70px" },
            },
            [
              _c("TemplateFooter", {
                attrs: { facade: _vm.templateColor.footer },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c(
            "div",
            { staticClass: "t" },
            [
              _vm._v("\n        图片圆角\n        "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantFitmentDiypageUpdate",
                      expression: "'merchantFitmentDiypageUpdate'",
                    },
                  ],
                  staticStyle: { "font-size": "18px" },
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.showSettingAction("img")
                    },
                  },
                },
                [_vm._v("修改")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content", staticStyle: { height: "70px" } },
            [
              _c("TemplateImg", {
                attrs: { facade: _vm.templateColor.img, only: "1" },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c(
            "div",
            { staticClass: "t" },
            [
              _vm._v("\n        TAB栏\n        "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantFitmentDiypageUpdate",
                      expression: "'merchantFitmentDiypageUpdate'",
                    },
                  ],
                  staticStyle: { "font-size": "18px" },
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.showSettingAction("tab")
                    },
                  },
                },
                [_vm._v("修改")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content", staticStyle: { height: "70px" } },
            [
              _c("TemplateTab", {
                attrs: { facade: _vm.templateColor.tab, only: "1" },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c(
            "div",
            { staticClass: "t" },
            [
              _vm._v("\n        按钮\n        "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantFitmentDiypageUpdate",
                      expression: "'merchantFitmentDiypageUpdate'",
                    },
                  ],
                  staticStyle: { "font-size": "18px" },
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.showSettingAction("button")
                    },
                  },
                },
                [_vm._v("修改")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content", staticStyle: { height: "70px" } },
            [
              _c("TemplateButton", {
                attrs: { facade: _vm.templateColor.button, only: "1" },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c(
            "div",
            { staticClass: "t" },
            [
              _vm._v("\n        标签\n        "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantFitmentDiypageUpdate",
                      expression: "'merchantFitmentDiypageUpdate'",
                    },
                  ],
                  staticStyle: { "font-size": "18px" },
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.showSettingAction("tag")
                    },
                  },
                },
                [_vm._v("修改")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content", staticStyle: { height: "70px" } },
            [
              _c("TemplateTag", {
                attrs: { facade: _vm.templateColor.tag, only: "1" },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c(
            "div",
            { staticClass: "t" },
            [
              _vm._v("\n        优惠券\n        "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantFitmentDiypageUpdate",
                      expression: "'merchantFitmentDiypageUpdate'",
                    },
                  ],
                  staticStyle: { "font-size": "18px" },
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.showSettingAction("coupon")
                    },
                  },
                },
                [_vm._v("修改")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content", staticStyle: { height: "70px" } },
            [
              _c("templateCoupon", {
                attrs: { facade: _vm.templateColor.coupon, only: "1" },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showImg,
            "close-on-click-modal": false,
            center: "",
            title: _vm.showImgType == 1 ? "查看二维码" : "预览二维码",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.showImgUrl, alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showSetting,
            "close-on-click-modal": false,
            "show-close": false,
            width: _vm.key == "footer" ? "850px" : "790px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSetting = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("div", [_vm._v(_vm._s(_vm.title))]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.handleClose },
                },
                [_c("i", { staticClass: "el-icon-close" })]
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "dialog-con flex" }, [
            _c("div", { staticClass: "flex-one" }, [
              _c("div", { staticClass: "t" }, [_vm._v("效果预览")]),
              _vm._v(" "),
              _vm.key === "page"
                ? _c(
                    "div",
                    [
                      _c("TemplatePage", {
                        attrs: { facade: _vm.settingData },
                      }),
                    ],
                    1
                  )
                : _vm.key === "list"
                ? _c(
                    "div",
                    [
                      _c("TemplateList", {
                        attrs: { facade: _vm.settingData, count: 3 },
                      }),
                    ],
                    1
                  )
                : _vm.key === "title"
                ? _c(
                    "div",
                    [
                      _c("Title", {
                        attrs: { facade: _vm.settingData, h: "93px" },
                      }),
                    ],
                    1
                  )
                : _vm.key === "footer"
                ? _c(
                    "div",
                    [
                      _c("TemplateFooter", {
                        attrs: { facade: _vm.settingData, h: "93px" },
                      }),
                    ],
                    1
                  )
                : _vm.key === "img"
                ? _c(
                    "div",
                    [_c("TemplateImg", { attrs: { facade: _vm.settingData } })],
                    1
                  )
                : _vm.key === "tab"
                ? _c(
                    "div",
                    [_c("TemplateTab", { attrs: { facade: _vm.settingData } })],
                    1
                  )
                : _vm.key === "button"
                ? _c(
                    "div",
                    [
                      _c("TemplateButton", {
                        attrs: { facade: _vm.settingData },
                      }),
                    ],
                    1
                  )
                : _vm.key === "tag"
                ? _c(
                    "div",
                    [_c("TemplateTag", { attrs: { facade: _vm.settingData } })],
                    1
                  )
                : _vm.key === "coupon"
                ? _c(
                    "div",
                    [
                      _c("TemplateCoupon", {
                        attrs: { facade: _vm.settingData },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "h20" }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "shu" }),
            _vm._v(" "),
            _c("div", { staticClass: "flex-one flex-col flex" }, [
              _vm.key != "footer"
                ? _c("div", { staticClass: "t" }, [_vm._v("样式设置")])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "mb10",
                  style: _vm.key == "footer" ? "width: 375px;" : "",
                },
                [
                  _vm.key == "footer"
                    ? [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              margin: "0 -20px",
                              "padding-top": "12px",
                            },
                          },
                          [
                            _vm.showSetting
                              ? _c("footerAttribute", {
                                  attrs: { commonset: "1" },
                                  model: {
                                    value: _vm.settingData,
                                    callback: function ($$v) {
                                      _vm.settingData = $$v
                                    },
                                    expression: "settingData",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    : [
                        _vm.modules[_vm.moduleName]
                          ? _c(_vm.modules[_vm.moduleName].attribute, {
                              tag: "component",
                              model: {
                                value: _vm.settingData,
                                callback: function ($$v) {
                                  _vm.settingData = $$v
                                },
                                expression: "settingData",
                              },
                            })
                          : _vm._e(),
                      ],
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "b flex align-items-c justify-c" }, [
                _c(
                  "div",
                  { staticClass: "flex-one" },
                  [
                    _c("el-button", { attrs: { type: "text" } }, [
                      _vm._v("恢复至默认"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { padding: "10px 30px" },
                        on: { click: _vm.handleClose },
                      },
                      [_vm._v("取消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { padding: "10px 30px" },
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.saveAction(_vm.key)
                          },
                        },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "h20" }),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.autoRelease.show,
            "close-on-click-modal": false,
            center: "",
            title: "设置自动发布时间",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.autoRelease, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "item-flex-center mt10" },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  "value-format": "timestamp",
                  placeholder: "选择自动发布时间",
                },
                model: {
                  value: _vm.autoRelease.data,
                  callback: function ($$v) {
                    _vm.$set(_vm.autoRelease, "data", $$v)
                  },
                  expression: "autoRelease.data",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-flex-center mt20" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.autoRelease.show = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.saveAutoRelease(2)
                    },
                  },
                },
                [_vm._v("取消设置")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveAutoRelease(1)
                    },
                  },
                },
                [_vm._v("设置")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }