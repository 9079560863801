var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.onSystemClick },
              },
              [_vm._v("系统分配")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "small",
                  icon: "el-icon-circle-plus-outline",
                },
                on: { click: _vm.onAdd },
              },
              [_vm._v("新增委托")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-radio-group",
        {
          attrs: { type: "button" },
          on: { change: _vm.statusChange },
          model: {
            value: _vm.status,
            callback: function ($$v) {
              _vm.status = $$v
            },
            expression: "status",
          },
        },
        [
          _c("el-radio-button", { attrs: { label: "1" } }, [
            _vm._v("代理出价"),
          ]),
          _vm._v(" "),
          _c("el-radio-button", { attrs: { label: "0" } }, [
            _vm._v("电话委托"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: { height: "calc(100vh - 210px)", "padding-top": "20px" },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.status == "0",
                  expression: "status == '0'",
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "phoneRef",
              attrs: {
                data: _vm.list,
                height: "100%",
                "element-loading-text": "Loading",
                fit: "",
                border: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "头像",
                  width: "50",
                  prop: "",
                  align: "center",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.entrustUser && scope.row.entrustUser.avatar
                          ? _c("img", {
                              staticStyle: {
                                width: "25px",
                                height: "25px",
                                cursor: "pointer",
                                "margin-top": "5px",
                              },
                              attrs: { src: scope.row.entrustUser.avatar },
                              on: {
                                click: function ($event) {
                                  return _vm.onDetails(scope.row)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "姓名",
                  "min-width": "180",
                  prop: "",
                  "search-key": "entrust_user_name",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.entrustUser
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDetails(row.entrustUser)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(row.entrustUser.real_name) +
                                    " \n\t\t\t\t\t\t"
                                ),
                                row.entrustUser && row.entrustUser.nickname
                                  ? _c("span", [
                                      _vm._v(
                                        "(" +
                                          _vm._s(row.entrustUser.nickname) +
                                          ")"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "手机号",
                  width: "100",
                  prop: "",
                  "search-key": "entrust_user_phone",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.entrustUser && _vm.status == "0"
                          ? _c("div", [_vm._v(_vm._s(row.entrustUser.phone))])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "号牌",
                  width: "120",
                  prop: "",
                  "search-key": "number",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.number
                          ? [
                              row.number.number
                                ? _c("span", [
                                    _vm._v(_vm._s(row.number.number)),
                                  ])
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "专场",
                  "min-width": "100",
                  prop: "",
                  "search-key": "album_id",
                  "search-type": "radio",
                  options: _vm.albumlist,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.album && _vm.status == "0"
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v("(" + _vm._s(row.album.album_name) + ")"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "LOT号",
                  width: "100",
                  prop: "",
                  "search-key": "lot",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(row.auction.lot) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "委托金额",
                  width: "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(row.entrust_price) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "负责人",
                  width: "100",
                  prop: "",
                  "search-key": "manage_admin_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.manageAdmin
                          ? _c("span", [
                              _vm._v(_vm._s(row.manageAdmin.real_name)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "竞买结果",
                  width: "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              row.auctiontime_status == 3 &&
                                row.auction.now_user == row.entrust_uid
                                ? "买到"
                                : "-"
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "落槌价",
                  width: "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.auction.time_status == 3
                          ? _c("span", [_vm._v(_vm._s(row.auction.now_price))])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "创建时间",
                  width: "180",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(row.create_time) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "创建人",
                  width: "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.createAdmin
                          ? _c("span", [
                              _vm._v(_vm._s(row.createAdmin.real_name)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "备注",
                  "min-width": "150",
                  "show-overflow-tooltip": "",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(row.remark)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "",
                  width: "36",
                  fixed: "right",
                  align: "center",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "TableRightBtn",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-printer",
                                  type: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onPrint(row)
                                  },
                                },
                              },
                              [_vm._v("打印")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDistri(row)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    "icon-class": "commonimport",
                                    "class-name": "blue",
                                  },
                                }),
                                _vm._v("分配"),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onUpd(row)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    "icon-class": "commonedit",
                                    "class-name": "blue",
                                  },
                                }),
                                _vm._v("编辑"),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticStyle: { display: "block", color: "red" },
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delAlbum(row.id)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    "icon-class": "commondelete",
                                    color: "red",
                                  },
                                }),
                                _vm._v("删除"),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.status == "1",
                  expression: "status == '1'",
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: {
                data: _vm.list,
                height: "100%",
                "element-loading-text": "Loading",
                fit: "",
                border: "",
                "highlight-current-row": "",
              },
              on: { "sort-change": _vm.changeSortAction },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "头像",
                  width: "50",
                  prop: "",
                  align: "center",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.user && scope.row.user.avatar
                          ? _c("img", {
                              staticStyle: {
                                width: "25px",
                                height: "25px",
                                cursor: "pointer",
                                "margin-top": "5px",
                              },
                              attrs: { src: scope.row.user.avatar },
                              on: {
                                click: function ($event) {
                                  return _vm.onDetails(scope.row)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "姓名",
                  "min-width": "180",
                  prop: "",
                  "search-key": "user_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.user
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDetails(row.user)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(row.user.real_name) +
                                    " \n\t\t\t\t\t\t\t"
                                ),
                                row.user && row.user.nickname
                                  ? _c("span", [
                                      _vm._v(
                                        "(" + _vm._s(row.user.nickname) + ")"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "手机号",
                  width: "100",
                  prop: "",
                  "search-key": "user_phone",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.user && _vm.status == "1"
                          ? _c("div", [_vm._v(_vm._s(row.user.phone))])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "号牌",
                  width: "120",
                  prop: "",
                  "search-key": "number",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.number
                          ? [
                              row.number.number
                                ? _c("span", [
                                    _vm._v(_vm._s(row.number.number)),
                                  ])
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "号牌类型",
                  width: "120",
                  prop: "",
                  "search-key": "number_type",
                  "search-type": "radio",
                  options: [
                    { label: "普通号牌", value: "1" },
                    { label: "特殊号牌", value: "2" },
                    { label: "免保号牌", value: "3" },
                    { label: "现场号牌", value: "4" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.number
                          ? _c("span", [
                              _vm._v(_vm._s(scope.row.number.number_type)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "专场",
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                  prop: "",
                  "search-key": "album_id",
                  "search-type": "radio",
                  options: _vm.albumlist,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.auction.album && _vm.status == "1"
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(_vm._s(row.auction.album.album_name)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "图片",
                  width: "50",
                  prop: "",
                  align: "center",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.auction.product
                          ? _c("img", {
                              staticStyle: {
                                width: "25px",
                                height: "25px",
                                cursor: "pointer",
                                "margin-top": "5px",
                              },
                              attrs: { src: scope.row.auction.product.image },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "拍品名称",
                  width: "100",
                  "show-overflow-tooltip": "",
                  prop: "",
                  "search-key": "auction_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.auction.product && _vm.status == "1"
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(_vm._s(row.auction.product.store_name)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "LOT号",
                  width: "100",
                  prop: "lot",
                  "search-key": "lot",
                  "search-type": "input",
                  sortable: "custom",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(row.auction.lot) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "代理价",
                  width: "140",
                  prop: "proxyPrice",
                  sortable: "custom",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          _vm._s(
                            row.auction.album.fee_type.replace("CNY", "RMB")
                          ) +
                            " " +
                            _vm._s(
                              _vm.formatPriceNumber(Number(row.proxy_price))
                            )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "竞买结果",
                  width: "100",
                  prop: "",
                  "search-key": "is_buy",
                  "search-type": "radio",
                  options: [
                    { label: "买到", value: "1" },
                    { label: "未买到", value: "0" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              row.auction.time_status == 3 &&
                                row.auction.now_user == row.uid
                                ? "买到"
                                : "-"
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "落槌价",
                  width: "100",
                  prop: "nowPrice",
                  sortable: "custom",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.auction.time_status == 3
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  row.auction.album.fee_type.replace(
                                    "CNY",
                                    "RMB"
                                  )
                                ) +
                                  " " +
                                  _vm._s(row.auction.now_price)
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "创建时间",
                  width: "180",
                  prop: "createTime",
                  sortable: "custom",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(row.create_time) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex align-items-c goodslist-page-box" },
        [
          _c(
            "div",
            { staticClass: "flex-one flex" },
            [
              _c(
                "el-dropdown",
                { attrs: { type: "primary", placement: "top" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "8px" },
                      attrs: { type: "primary", size: "small" },
                    },
                    [_vm._v("导出")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "text" },
                              on: { click: _vm.exportOrder },
                            },
                            [_vm._v("导出表格")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "text" },
                              on: { click: _vm.getExportFileList },
                            },
                            [_vm._v("导出记录")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-size": _vm.queryParams.limit,
              "current-page": _vm.queryParams.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.total,
            },
            on: {
              "current-change": _vm.pageChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "分配员工", visible: _vm.visible, width: "250px" },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c(
                "el-select",
                {
                  staticClass: "w200",
                  attrs: { placeholder: "请选择员工" },
                  model: {
                    value: _vm.manage_admin_id,
                    callback: function ($$v) {
                      _vm.manage_admin_id = $$v
                    },
                    expression: "manage_admin_id",
                  },
                },
                _vm._l(_vm.adminarr, function (m, i) {
                  return _c("el-option", {
                    key: i,
                    attrs: { value: m.merchant_admin_id, label: m.real_name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-flex-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.butLoad },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: {
                editMark: _vm.load,
                close: function ($event) {
                  _vm.showdetail = false
                },
              },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c("TelephoneBidPrint", { ref: "telephone" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.distriVisible,
            title: "系统分配",
            top: "5vh",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.distriVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "负责人",
                    "label-width": "120px",
                    required: "",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "selwidth",
                      attrs: { placeholder: "请选择业务员", multiple: "" },
                      model: {
                        value: _vm.admin_ids,
                        callback: function ($$v) {
                          _vm.admin_ids = $$v
                        },
                        expression: "admin_ids",
                      },
                    },
                    _vm._l(_vm.userList, function (item) {
                      return _c("el-option", {
                        key: item.merchant_admin_id,
                        attrs: {
                          label: item.real_name,
                          value: item.merchant_admin_id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onDistriSubmit },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }