"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _artai = require("@/api/artai");
var _aiMark = require("@/api/aiMark");
var _aiOpen = require("@/api/aiOpen");
var _user = require("@/api/user.js");
var _payMent = _interopRequireDefault(require("./payMent.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    payment: _payMent.default
  },
  data: function data() {
    return {
      comInfo: {},
      intInfo: {},
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        type: 99,
        scoreTypeId: ''
      },
      tabList: [{
        name: '全部',
        value: 99
      }, {
        name: '获得',
        value: 0
      }, {
        name: '消耗',
        value: 1
      }]
    };
  },
  // computed: {
  // },
  created: function created() {
    var _this = this;
    (0, _aiMark.getMerchantTokenApi)().then(function (res) {
      localStorage.setItem('open3dtoken', res.data.token);
      (0, _aiOpen.getScoreInfoApi)().then(function (res) {
        // console.log(res);
        _this.intInfo = res.data;
        _this.tableFrom.scoreTypeId = res.data.scoreTypeId;
        _this.gitList();
      });
    });
    var cInfo = localStorage.getItem('artai_comInfo');
    if (cInfo) {
      console.log(cInfo);
      this.comInfo = JSON.parse(cInfo);
    }
    (0, _user.getBaseInfo)().then(function (res) {
      // console.log(res);
      _this.comInfo = res.data;
      localStorage.setItem('artai_comInfo', JSON.stringify(res.data));
    });
    // accountBalanceApi({ type: 7 }).then(res => {
    //   console.log(res);
    //   this.intInfo = res.data;
    // })
    // this.gitList();
  },
  methods: {
    onTabChange: function onTabChange(value) {
      this.tableFrom.type = value;
      this.gitList();
    },
    onOpenPoint: function onOpenPoint() {
      this.$refs.paymentRef.openMask((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.comInfo), {}, {
        usableScore: this.intInfo.usableScore
      }));
      // if (window.self !== window.top) {
      //   window.parent.postMessage(
      //     {
      //       type: 'onJumpOtherPage',
      //       data: 'https://artart.cn/openIPIP/topUp/artaiRechange'
      //     },
      //     '*'
      //   );
      // } else {
      //   window.open('https://artart.cn/openIPIP/topUp/artaiRechange');
      // }
    },
    gitList: function gitList() {
      var _this2 = this;
      (0, _aiOpen.getScoreInfoListApi)(this.tableFrom.type, this.tableFrom).then(function (res) {
        // console.log(res);
        _this2.tableData.data = res.rows;
        _this2.tableData.total = res.total;
      });
      // accountBalanceRecordListApi(this.tableFrom).then(res => {
      //   console.log(res);
      //   this.tableData.data = res.data.list;
      //   this.tableData.total = res.data.count;
      // })
    },
    pageChange: function pageChange(page) {
      this.tableFrom.pageNum = page;
      this.gitList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.gitList();
    }
  }
};