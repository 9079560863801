"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.auctionDeliveryForm = auctionDeliveryForm;
exports.auctionOrderDeliveryApi = auctionOrderDeliveryApi;
exports.auctionOrderDetailApi = auctionOrderDetailApi;
exports.auctionOrderListApi = auctionOrderListApi;
exports.auctionOrderRefundReceiving = auctionOrderRefundReceiving;
exports.auctionOrderSettlement = auctionOrderSettlement;
exports.auctionOrderStatusNum = auctionOrderStatusNum;
exports.auctionOrderSwitchRefund = auctionOrderSwitchRefund;
exports.auctionStatusOrderListApi = auctionStatusOrderListApi;
exports.auctionUpdateDeliveryForm = auctionUpdateDeliveryForm;
exports.batchCreate = batchCreate;
exports.batchUpdateAuctionCommissionRateApi = batchUpdateAuctionCommissionRateApi;
exports.cancelAuctionOrder = cancelAuctionOrder;
exports.chartApi = chartApi;
exports.confirmProxyPlatformOrderPayApi = confirmProxyPlatformOrderPayApi;
exports.confirmReceiptApi = confirmReceiptApi;
exports.createAuctionRefundApi = createAuctionRefundApi;
exports.createNftOrderRefundApi = createNftOrderRefundApi;
exports.createWarehouseOrder = createWarehouseOrder;
exports.deliveryApi = deliveryApi;
exports.downloadFileApi = downloadFileApi;
exports.editorderDeliveryApi = editorderDeliveryApi;
exports.exportAuctionOrderApi = exportAuctionOrderApi;
exports.exportFileLstApi = exportFileLstApi;
exports.exportOrderApi = exportOrderApi;
exports.getAuctionOrderBillInfo = getAuctionOrderBillInfo;
exports.getAuctionOrderSettlementInfo = getAuctionOrderSettlementInfo;
exports.getAuctionTransactionOrderBillInfo = getAuctionTransactionOrderBillInfo;
exports.getExpress = getExpress;
exports.getGoodsOrderBillInfo = getGoodsOrderBillInfo;
exports.getOrderStatusNumber = getOrderStatusNumber;
exports.getSendDeliveryInfoApi = getSendDeliveryInfoApi;
exports.getUpdateAuctionOrderInfo = getUpdateAuctionOrderInfo;
exports.invoiceApi = invoiceApi;
exports.invoiceDetailApi = invoiceDetailApi;
exports.invoiceInfoApi = invoiceInfoApi;
exports.invoiceOrderListApi = invoiceOrderListApi;
exports.invoiceReceiptFormApi = invoiceReceiptFormApi;
exports.invoiceUpdateApi = invoiceUpdateApi;
exports.invoiceorderMarkApi = invoiceorderMarkApi;
exports.orderCancellationApi = orderCancellationApi;
exports.orderDeleteApi = orderDeleteApi;
exports.orderDeliveryApi = orderDeliveryApi;
exports.orderDetailApi = orderDetailApi;
exports.orderHeadListApi = orderHeadListApi;
exports.orderListApi = orderListApi;
exports.orderLogApi = orderLogApi;
exports.orderPrintApi = orderPrintApi;
exports.orderProductCreateOut = orderProductCreateOut;
exports.orderRemarkApi = orderRemarkApi;
exports.orderUpdateApi = orderUpdateApi;
exports.preCreateWarehouseOrder = preCreateWarehouseOrder;
exports.refundExpressApi = refundExpressApi;
exports.refundorderDeleteApi = refundorderDeleteApi;
exports.refundorderDetailApi = refundorderDetailApi;
exports.refundorderExpressApi = refundorderExpressApi;
exports.refundorderListApi = refundorderListApi;
exports.refundorderLogApi = refundorderLogApi;
exports.refundorderMarkApi = refundorderMarkApi;
exports.refundorderStatusApi = refundorderStatusApi;
exports.saveOrderPrintNoApi = saveOrderPrintNoApi;
exports.sendPaymentReminderApi = sendPaymentReminderApi;
exports.setAuctionOrderJuhui = setAuctionOrderJuhui;
exports.setAuctionOrderJuhuiRecovery = setAuctionOrderJuhuiRecovery;
exports.setOrderBannerApi = setOrderBannerApi;
exports.takeChartApi = takeChartApi;
exports.takeOrderListApi = takeOrderListApi;
exports.updateAuctionOrder = updateAuctionOrder;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 订单 -- 列表
 */
function orderListApi(data) {
  return _request.default.get('store/order/lst', data);
}

/**
 * @description 订单 -- 手动退款
 */
function createAuctionRefundApi(data) {
  return _request.default.post("store/refund/createAuctionRefund", data);
}

/**
 * @description 订单 -- 表头
 */
function chartApi() {
  return _request.default.get('store/order/chart');
}

/**
 * @description 订单 -- 编辑
 */
function orderUpdateApi(id, data) {
  return _request.default.post("store/order/update/".concat(id), data);
}

/**
 * @description 订单 -- 发货
 */
function orderDeliveryApi(id) {
  return _request.default.get("store/order/delivery/".concat(id, "/form"));
}
/**
 * @description 订单 -- 修改物流
 */
function editorderDeliveryApi(id) {
  return _request.default.get("store/order/update_delivery/".concat(id, "/form"));
}
/**
 * @description 订单 -- 详情
 */
function orderDetailApi(id) {
  return _request.default.get("store/order/detail/".concat(id));
}

/**
 * @description 订单 -- 记录
 */
function orderLogApi(id, data) {
  return _request.default.get("store/order/log/".concat(id), data);
}

/**
 * @description 订单 -- 备注from
 */
function orderRemarkApi(id) {
  return _request.default.get("store/order/remark/".concat(id, "/form"));
}

/**
 * @description 订单 -- 删除
 */
function orderDeleteApi(id) {
  return _request.default.post("store/order/delete/".concat(id));
}

/**
 * @description 订单 -- 催款
 */
function sendPaymentReminderApi(data) {
  return _request.default.post("store/order/sendPaymentReminder", data);
}

/**
 * @description 订单 -- 确认代拍订单支付
 */
function confirmProxyPlatformOrderPayApi(data) {
  return _request.default.post("store/order/confirmProxyPlatformOrderPay", data);
}
/**
 * @description 订单 -- 打印
 */
function orderPrintApi(id) {
  return _request.default.get("store/order/printer/".concat(id));
}

/**
 * @description 退款订单 -- 列表
 */
// export function refundorderListApi(data) {
//   return request.get('store/refundorder/lst', data);
// }

/**
 * @description 退款订单 -- 列表
 */
function refundorderListApi(data) {
  return _request.default.get('store/refund/list', data);
}

/**
 * @description 退款订单 -- 详情
 */
function refundorderDetailApi(id) {
  return _request.default.get("store/refundorder/detail/".concat(id));
}

/**
 * @description 退款订单 -- 审核from
 */
//  export function refundorderStatusApi(id) {
//   return request.get(`store/refundorder/status/${id}/form`);
// }
/**
 * @description 退款订单 -- 审核from
 */
function refundorderStatusApi(id) {
  return _request.default.get("store/refund/status/".concat(id, "/form"));
}

/**
 * @description 退款订单 -- 备注from
 */
function refundorderMarkApi(id) {
  return _request.default.get("store/refundorder/mark/".concat(id, "/form"));
}

/**
 * @description 退款订单 -- 记录from
 */
function refundorderLogApi(id) {
  return _request.default.get("store/refundorder/log/".concat(id));
}

/**
 * @description 退款订单 -- 删除
 */
function refundorderDeleteApi(id) {
  return _request.default.get("store/refundorder/delete/".concat(id));
}
/**
 * @description 退款订单 -- 确认收货
 */
// export function confirmReceiptApi(id) {
//   return request.post(`store/refundorder/refund/${id}`);
// }
function confirmReceiptApi(id) {
  return _request.default.post("store/refund/confirmRefundPrice/".concat(id));
}

/**
 * @description 获取物流信息
 */
function getExpress(id) {
  return _request.default.get("store/order/express/".concat(id));
}

/**
 * @description 退款单获取物流信息
 */
function refundorderExpressApi(id) {
  return _request.default.get("store/refundorder/express/".concat(id));
}
/**
 * @description 导出订单
 */
function exportOrderApi(data) {
  return _request.default.post("store/order/auctionOrderExport", data);
}
/**
 * @description 导出文件列表
 */
function exportFileLstApi(data) {
  return _request.default.get("excel/lst", data);
}
/**
 * @description 下载
 */
function downloadFileApi(id) {
  return _request.default.get("excel/download/".concat(id));
}
/**
 * @description 订单核销
 */
function orderCancellationApi(code) {
  return _request.default.post("store/order/verify/".concat(code));
}
/**
 * @description 订单 -- 头部
 */
function orderHeadListApi() {
  return _request.default.get("store/order/filtter");
}
/**
 * @description 核销订单 -- 表头
 */
function takeChartApi() {
  return _request.default.get('store/order/takechart');
}
/**
 * @description 核销订单 -- 列表
 */
function takeOrderListApi(data) {
  return _request.default.get('store/order/takelst', data);
}
/**
 * @description 发票管理 -- 列表
 */
// export function invoiceOrderListApi(data) {
//   return request.get('store/receipt/lst', data);
// }
function invoiceOrderListApi(data) {
  return _request.default.get('store/receipt/list', data);
}
// 开票 form
function invoiceReceiptFormApi(id) {
  return _request.default.get("store/receipt/makeReceiptForm/".concat(id));
}
/**
 * @description 发票 -- 备注from
 */
function invoiceorderMarkApi(id) {
  return _request.default.get("store/receipt/mark/".concat(id, "/form"));
}
/**
 * @description 发票 -- 开票信息
 */
function invoiceInfoApi(data) {
  return _request.default.get("store/receipt/set_recipt", data);
}
/**
 * @description 发票 -- 开票
 */
function invoiceApi(data) {
  return _request.default.post("store/receipt/save_recipt", data);
}
/**
 * @description 发票 -- 详情
 */
function invoiceDetailApi(id) {
  return _request.default.get("store/receipt/detail/".concat(id));
}
/**
 * @description 发票 -- 编辑
 */
function invoiceUpdateApi(id, data) {
  return _request.default.post("store/receipt/update/".concat(id), data);
}

/**
 * @description 订单  -- 拍卖 -- 表头
 */
function auctionOrderStatusNum() {
  return _request.default.get('auction/order/orderStatusNum');
}

/**
 * @description 订单 -- 拍品 -- 列表
 */
function auctionOrderListApi(data) {
  return _request.default.get('store/order/lst', data);
}

/**
 * @description 订单 -- 拍品 -- 列表 -- 合并
 */
function auctionStatusOrderListApi(data) {
  return _request.default.get('store/order/albumGroupList', data);
}

/**
 * @description 订单 -- 拍卖 -- 详情
 */
function auctionOrderDetailApi(id) {
  return _request.default.get("auction/order/detail/".concat(id));
}

/**
 * @description 订单 -- 拍卖 -- 处理售后
 */
function auctionOrderSwitchRefund(data) {
  return _request.default.post('auction/order/refund/switchRefund', data);
}

/**
 * @description 订单 -- 拍卖 -- 处理售后
 */
function auctionOrderRefundReceiving(data) {
  return _request.default.post('auction/order/refund/confirmRefundPrice', data);
}

/**
 * @description 订单 -- 拍卖 --发货
 */
function auctionOrderDeliveryApi(id) {
  return _request.default.get("auction/order/sendDeliveryForm/".concat(id));
}

/**
 * @description 导出订单
 */
function exportAuctionOrderApi(data) {
  var newData = data;
  newData.type = 'auction_order';
  return _request.default.post("auction/order/excel", newData);
}

/**
 * @description 订单 -- 拍卖 -- 状态
 */
function getOrderStatusNumber(data) {
  return _request.default.get("store/order/statusNumber", data);
}

/**
 * @description 订单 -- 拍卖 -- 获取修改拍品订单信息
 */
function getUpdateAuctionOrderInfo(data) {
  return _request.default.get("store/order/getUpdateAuctionOrderInfo", data);
}
/**
 * @description 订单 -- 拍卖 -- 修改订单
 */
function updateAuctionOrder(data) {
  return _request.default.post("store/order/updateAuctionOrder", data);
}
/**
 * @description 订单 -- 拍卖 -- 取消订单
 */
function cancelAuctionOrder(data) {
  return _request.default.post("store/order/cancelAuctionOrder", data);
}
/**
 * @description 订单 -- 拍卖 -- 批量修改订单佣金
 */
function batchUpdateAuctionCommissionRateApi(data) {
  return _request.default.post("store/order/batchUpdateAuctionCommissionRate", data);
}
/**
 * @description 订单 -- 拍卖 -- 获取发货form
 */
function auctionDeliveryForm(data) {
  return _request.default.get("store/order/deliveryForm", data);
}
/**
 * @description 订单 -- 拍卖 -- 获取发货form
 */
function auctionUpdateDeliveryForm(id) {
  return _request.default.get("store/order/updateDeliveryForm/".concat(id));
}

/**
 * @description 订单 -- 拍卖 -- 获取订单结算信息
 */
function getAuctionOrderSettlementInfo(data) {
  return _request.default.get("store/order/getAuctionOrderSettlementInfo", data);
}
/**
 * @description 订单 -- 拍卖 -- 订单结算
 */
function auctionOrderSettlement(data) {
  return _request.default.post("store/order/auctionOrderSettlement", data);
}
/**
 * @description 订单 -- 拍卖 -- 打印
 */
function getAuctionOrderBillInfo(data) {
  return _request.default.get("store/order/getAuctionOrderBillInfo", data);
}

/**
 * @description 订单 -- 拍卖 -- 打印
 */
function getAuctionTransactionOrderBillInfo(data) {
  return _request.default.get("store/order/getAuctionTransactionOrderBillInfo", data);
}

/**
 * @description 订单 -- 商品 -- 打印
 */
function getGoodsOrderBillInfo(data) {
  return _request.default.get("store/order/getOrderBillInfo", data);
}

/**
 * @description 订单 -- 请求发货信息
 */
function getSendDeliveryInfoApi(data) {
  return _request.default.get("store/order/getSendDeliveryInfo", data);
}

/**
 * @description 订单 -- 发货
 */
function deliveryApi(data) {
  return _request.default.post("store/order/delivery", data);
}

/**
 * @description   nft -- 手动退款
 */
function createNftOrderRefundApi(data) {
  return _request.default.post("store/refund/createNftOrderRefund", data);
}

/**
 * @description   退货订单物流查询
 */
function refundExpressApi(id) {
  return _request.default.get("store/refund/express/".concat(id));
}

/**
 * @description   设置订单举回
 */
function setAuctionOrderJuhui(data) {
  return _request.default.post("store/order/setAuctionOrderJuhui", data);
}

/**
 * @description   举回订单恢复
 */
function setAuctionOrderJuhuiRecovery(data) {
  return _request.default.post("store/order/setAuctionOrderJuhuiRecovery", data);
}

/**
 * @description   添加订单打印日志
 */
function batchCreate(data) {
  return _request.default.post("order/printRecord/batchCreate", data);
}

/**
 * @description   添加标识
 */
function setOrderBannerApi(data) {
  return _request.default.post("store/order/setOrderBanner", data);
}
/**
 * @description   根据订单id创建出库单
 */
function orderProductCreateOut(data) {
  return _request.default.post("store/order/orderProductCreateOut", data);
}
function preCreateWarehouseOrder(data) {
  return _request.default.post("store/order/preCreateWarehouseOrder", data);
}
function createWarehouseOrder(data) {
  return _request.default.post("store/order/createWarehouseOrder", data);
}
function saveOrderPrintNoApi(data) {
  return _request.default.post("store/order/saveOrderPrintNo", data);
}