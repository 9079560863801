"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auction = _interopRequireDefault(require("@/views/aiMarketing/module/auction"));
var _aiMark = require("@/api/aiMark");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    SelectAuctionView: _auction.default
  },
  data: function data() {
    return {
      moduleVisible: false,
      moduleForm: {
        marketMake: {}
      },
      relation_id: '',
      loadBut: false
    };
  },
  mounted: function mounted() {},
  methods: {
    onSame: function onSame() {
      var _this = this;
      // console.log(this.moduleForm);
      this.relation_id = this.moduleForm.make_type == 2 ? this.moduleForm.relation_id : '';
      this.$nextTick(function () {
        _this.$refs.selectAuctionViewRef.changeshow(_this.moduleForm.make_type == 3 ? 1 : 0);
      });
    },
    onAuctionMessage: function onAuctionMessage(data) {
      var _this2 = this;
      //拍品选择确认
      var idsArr = data.map(function (item) {
        return item.id;
      });
      (0, _aiMark.makeSameApi)({
        market_make_id: this.moduleForm.market_make_id,
        auction_ids: idsArr
      }).then(function (res) {
        _this2.$message.success('操作成功');
        _this2.moduleVisible = false;
        _this2.$emit('load', res.data.market_make_id);
      });
    },
    onOpenInit: function onOpenInit(row) {
      this.moduleForm = row;
      this.moduleVisible = true;
      // marketMediaDetailApi(market_make_id).then(res => {
      //   console.log(res);
      //   this.moduleVisible = true;
      //   this.moduleForm = {...res.data, released_plan_date: time};
      // })
    },
    onCopyText: function onCopyText() {
      var input = document.createElement('input'); // 直接构建input
      input.value = this.moduleForm.extend_data.video_info.title; // 设置内容

      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand('Copy'); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功');
    },
    mackTitChange: function mackTitChange() {
      (0, _aiMark.editFieldValueApi)({
        field: 'videoIntroText',
        value: this.moduleForm.extend_data.video_info.title
      }).then(function (res) {});
    },
    onDownload: function onDownload() {
      var _this3 = this;
      this.loadBut = true;
      fetch(this.moduleForm.extend_data.video_info.url).then(function (res) {
        return res.blob();
      }).then(function (blob) {
        var name;
        if (_this3.moduleForm.make_type == 1) name = "".concat(_this3.moduleForm.make_title, "-\u62CD\u5356\u4F1A\u89C6\u9891");else if (_this3.moduleForm.make_type == 2) name = "".concat(_this3.moduleForm.make_title, "-\u4E13\u573A\u89C6\u9891");else if (_this3.moduleForm.make_type == 3 || _this3.moduleForm.make_type == 4) name = "".concat(_this3.moduleForm.make_title, "-\u62CD\u54C1\u89C6\u9891");
        var a = document.createElement("a");
        var objectUrl = window.URL.createObjectURL(blob);
        a.download = "".concat(name, ".mp4");
        a.href = objectUrl;
        a.click();
        window.URL.revokeObjectURL(objectUrl);
        a.remove();
        _this3.loadBut = false;
      });
    }
  }
};