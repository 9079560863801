var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.collectMoney.show,
        title: "",
        top: "5vh",
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.collectMoney, "show", $event)
        },
      },
    },
    [
      _c("div", { staticClass: "all-h-print" }, [
        _vm.collectMoney.show
          ? _c("div", { attrs: { id: "printorder" } }, [
              _c(
                "div",
                { staticStyle: { display: "flex", "align-items": "center" } },
                [
                  _c(
                    "div",
                    { staticStyle: { flex: "1" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center",
                          },
                        },
                        [
                          _c("div", { staticStyle: { flex: "1" } }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "line-height": "1.1",
                                "font-family": "printEN",
                                "font-weight": "600",
                                color: "#000",
                                "text-align": "center",
                                "font-size": "24px",
                                width: "50%",
                              },
                            },
                            [
                              _c("div", {}, [_vm._v("聖佳藝術(香港)有限公司")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { "font-size": "20px" } },
                                [_vm._v("SUNGARI(HK) COMPANY LIMITED")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "5px" } },
                                [_vm._v("買 家 發 票")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { "font-size": "20px" } },
                                [_vm._v("Buyer's Invoice")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                flex: "1",
                                "font-weight": "500",
                                color: "#000",
                                "text-align": "right",
                                "font-size": "12px",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  "No." +
                                    _vm._s(
                                      _vm.orgNo ? _vm.orgNo : _vm.no + 1000000
                                    )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("span", [_vm._v("第1頁")]),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "20px" } },
                                  [_vm._v("共1頁")]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            color: "#000",
                            "line-height": "1.3",
                            "font-weight": "500",
                            "font-size": "14px",
                          },
                        },
                        [
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    width: "110px",
                                  },
                                },
                                [_vm._v("拍賣會 Auction:")]
                              ),
                              _c(
                                "span",
                                { staticStyle: { "font-weight": "600" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.collectMoney.data.venue_info.title
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.collectMoney.data.userVerifyInfo
                              ? _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        width: "110px",
                                      },
                                    },
                                    [_vm._v("買家姓名 Buyer:")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-weight": "600",
                                        "font-size": "14px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.collectMoney.data.userVerifyInfo
                                            .real_name
                                        )
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm.collectMoney.data.userInfo
                              ? _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        width: "110px",
                                      },
                                    },
                                    [_vm._v("買家姓名 Buyer:")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-weight": "600",
                                        "font-size": "14px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.collectMoney.data.userInfo
                                            .nickname
                                        )
                                      ),
                                    ]
                                  ),
                                ])
                              : _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        width: "110px",
                                      },
                                    },
                                    [_vm._v("買家姓名 Buyer:")]
                                  ),
                                ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "text-align": "right" } },
                            [
                              _c("div", [
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "400" } },
                                  [_vm._v("號牌 PADDLE NO.")]
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "600" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.collectMoney.data.numberPlate
                                            ? _vm.collectMoney.data.numberPlate
                                                .number
                                            : ""
                                        )
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "400" } },
                                  [_vm._v("日期 Date:")]
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "600" } },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.parseTime(new Date()))
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(
                        _vm.collectMoney.data.orderList,
                        function (item, ind) {
                          return _c(
                            "div",
                            { key: ind, staticClass: "ptd-box" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ptd",
                                  staticStyle: {
                                    background: "#eee",
                                    "box-sizing": "border-box",
                                    "-webkit-print-color-adjust": "exact",
                                    "print-color-adjust": "exact",
                                    "color-adjust": "exact",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-title",
                                      staticStyle: { width: "80px" },
                                    },
                                    [
                                      _c("span", [_vm._v("圖錄號")]),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-family": "'printEN'",
                                          },
                                        },
                                        [_vm._v("LOT NO.")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-title",
                                      staticStyle: { flex: "1" },
                                    },
                                    [
                                      _c("span", [_vm._v("拍賣品摘要")]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-family": "'printEN'",
                                          },
                                        },
                                        [_vm._v("DESCRIPTION")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-title",
                                      staticStyle: { width: "120px" },
                                    },
                                    [
                                      _c("span", [_vm._v("落槌價")]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-family": "'printEN'",
                                          },
                                        },
                                        [_vm._v("HAMMER PRICE")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-family": "'printEN'",
                                          },
                                        },
                                        [_vm._v("(HKD)")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-title",
                                      staticStyle: { width: "120px" },
                                    },
                                    [
                                      _c("span", [_vm._v("傭金")]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-family": "'printEN'",
                                          },
                                        },
                                        [_vm._v("COMMISSION")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-family": "'printEN'",
                                          },
                                        },
                                        [_vm._v("(HKD)")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-title",
                                      staticStyle: { width: "120px" },
                                    },
                                    [
                                      _c("span", [_vm._v("雜項費用")]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-family": "'printEN'",
                                          },
                                        },
                                        [_vm._v("OTHER EXPENSE")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-family": "'printEN'",
                                          },
                                        },
                                        [_vm._v("(HKD)")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-title",
                                      staticStyle: { width: "140px" },
                                    },
                                    [
                                      _c("span", [_vm._v("金額")]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-family": "'printEN'",
                                          },
                                        },
                                        [_vm._v("SUBTOTAL")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-family": "'printEN'",
                                          },
                                        },
                                        [_vm._v("(HKD)")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(item, function (m, i) {
                                return _c(
                                  "div",
                                  { key: "order" + i, staticClass: "ptd" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v(_vm._s(m.auction_info.lot))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        staticStyle: {
                                          flex: "1",
                                          "justify-content": "flex-start",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(m.auction_info.store_name)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        staticStyle: { width: "120px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatPriceNumber(
                                              m.product_price,
                                              2
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        staticStyle: { width: "120px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatPriceNumber(
                                              m.commission_price,
                                              2
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        staticStyle: { width: "120px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            m.total_postage &&
                                              m.delivery_type == 1
                                              ? _vm.formatPriceNumber(
                                                  m.total_postage,
                                                  2
                                                )
                                              : ""
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        staticStyle: { width: "140px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatPriceNumber(
                                              m.payable_price,
                                              2
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              _vm._v(" "),
                              item.length < 5
                                ? _vm._l(5 - item.length, function (m, i) {
                                    return _c(
                                      "div",
                                      {
                                        key: "orderOther" + i,
                                        staticClass: "ptd",
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "ptd-body",
                                          staticStyle: { width: "80px" },
                                        }),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "ptd-body",
                                          staticStyle: {
                                            flex: "1",
                                            "justify-content": "flex-start",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "ptd-body",
                                          staticStyle: { width: "120px" },
                                        }),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "ptd-body",
                                          staticStyle: { width: "120px" },
                                        }),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "ptd-body",
                                          staticStyle: { width: "120px" },
                                        }),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "ptd-body",
                                          staticStyle: { width: "140px" },
                                        }),
                                      ]
                                    )
                                  })
                                : _vm._e(),
                            ],
                            2
                          )
                        }
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ptd-box",
                          staticStyle: {
                            "margin-top": "0",
                            "border-top": "none",
                          },
                        },
                        [
                          _c("div", { staticClass: "ptd" }, [
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { flex: "1" },
                              },
                              [_c("div", [_vm._v("本頁小計 Subtotals")])]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { width: "120px" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(_vm.total_price, 2)
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { width: "120px" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.data
                                        .total_commission_price,
                                      2
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { width: "120px" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.collectMoney.data.total_postage
                                      ? _vm.formatPriceNumber(
                                          _vm.collectMoney.data.total_postage,
                                          2
                                        )
                                      : ""
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { width: "140px" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.data.total_payable_price,
                                      2
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "ptd" }, [
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: {
                                  flex: "1",
                                  "justify-content": "flex-start",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "text-align": "left" } },
                                  [_vm._v("總金額合計 Total Amount")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { width: "500px" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        _vm.collectMoney.data.total_pay_price,
                                        2
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.feeTypeInfo.mer_fee_type == "CNY"
                            ? _c("div", { staticClass: "ptd" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-body",
                                    staticStyle: { flex: "1" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { "text-align": "left" } },
                                      [
                                        _vm._v(
                                          "總金額（大寫）Total Amount In Words"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-body",
                                    staticStyle: { width: "500px" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.collectMoney.data.total_pay_price ==
                                          0
                                          ? ""
                                          : _vm.collectMoney.data
                                              .total_pay_price_rmb
                                      )
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            "line-height": "1.2",
                            color: "#000",
                            "margin-top": "10px",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v("財務部:")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "font-family": "'printEN'" } },
                            [_vm._v("Financial Dept.")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "font-family": "'printEN'" } },
                            [_vm._v("(with Company Chop)")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            "line-height": "1.2",
                            color: "#000",
                            "margin-top": "15px",
                            position: "relative",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "font-weight": "bold" } },
                            [
                              _c("span", [
                                _vm._v("香港中環雪廠街16號西洋會所大廈20層"),
                              ]),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "20px" } },
                                [
                                  _vm._v(
                                    "20th Floor, Club Lusitano, 16 Ice House Street, Central, Hong Kong"
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticStyle: { "margin-top": "5px" } }, [
                            _c("span", [_vm._v("Tel: (852) 2661 0833")]),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "20px" } },
                              [_vm._v("Email: infohk@sungari.art")]
                            ),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "20px" } },
                              [_vm._v("Website: www.sungari.art")]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-left": "5px",
                        "font-weight": "600",
                        color: "#000",
                      },
                    },
                    [
                      _c("div", [_vm._v("買")]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { "margin-top": "5px" } }, [
                        _vm._v("家"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { "margin-top": "5px" } }, [
                        _vm._v("存"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { "margin-top": "5px" } }, [
                        _vm._v("據"),
                      ]),
                    ]
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "default" },
              on: { click: _vm.bindPrintorder },
            },
            [_vm._v("打印")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }