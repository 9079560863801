var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fontW400 ft14 color-black mb20" }, [
    _vm._v("请在“设置-协议配置-参拍须知”设置完内容后添加组件"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }