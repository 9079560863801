"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.typed.uint8-array");
require("core-js/modules/es6.regexp.split");
var _typeof2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.string.link");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _guide = _interopRequireDefault(require("@/components/guide/guide"));
var _pagetitle = _interopRequireDefault(require("@/components/pagetitle/pagetitle"));
var _attribute = _interopRequireDefault(require("@/views/decoration/modules/page/attribute"));
var _attribute2 = _interopRequireDefault(require("@/views/decoration/modules/package/attribute"));
var _attribute3 = _interopRequireDefault(require("@/views/decoration/modules/footer/attribute"));
var _panels = _interopRequireDefault(require("../../libs/panels.js"));
var _modules = _interopRequireDefault(require("@/views/decoration/modules"));
var _system = require("@/api/system");
var _vuex = require("vuex");
var _request = _interopRequireDefault(require("@/api/request"));
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var _createNamespacedHelp = (0, _vuex.createNamespacedHelpers)('setting'),
  mapGetters = _createNamespacedHelp.mapGetters;
var _default = exports.default = {
  name: 'CustomPage',
  components: {
    pageAttribute: _attribute.default,
    titleAttribute: _attribute2.default,
    guide: _guide.default,
    pagetitle: _pagetitle.default,
    footerAttribute: _attribute3.default
  },
  data: function data() {
    return {
      panels: _panels.default,
      modules: _modules.default,
      is_totop: false,
      fullHeight: document.documentElement.clientHeight,
      qrcode: {
        wechat: {}
      },
      avatar: '',
      headerHover: false,
      footerHover: false,
      pageType: this.$route.query.type,
      isEdit: false,
      showImg: false,
      showImgType: 1,
      showImgUrl: ''
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, mapGetters({
    getStore: 'getStore',
    version: 'getVersion'
  })), {}, {
    scrollHeight: function scrollHeight() {
      return {
        height: parseInt(this.fullHeight) - 65 + 'px'
      };
    },
    pageInfo: function pageInfo() {
      return this.$store.state.finish.pageInfo;
    },
    index: function index() {
      return this.$store.state.finish.index;
    },
    attribute: function attribute() {
      return this.$store.getters.attribute;
    },
    is_attribute: function is_attribute() {
      return this.$store.state.finish.pages.length > 0;
    },
    is_lock: function is_lock() {
      return this.$store.state.finish.is_lock;
    },
    hasTab: function hasTab() {
      var has = false;
      if (this.$store.state.finish.pages && this.$store.state.finish.pages.length) {
        this.$store.state.finish.pages.forEach(function (m) {
          if (m.name === 'tag') {
            has = true;
          }
        });
      }
      return has;
    },
    footerData: function footerData() {
      var d = [{
        url: '',
        url_after: '',
        title: '导航文字',
        link: {
          link: '',
          name: '',
          name1: ''
        }
      }, {
        url: '',
        url_after: '',
        title: '导航文字',
        link: {
          link: '',
          name: '',
          name1: ''
        }
      }, {
        url: '',
        url_after: '',
        title: '导航文字',
        link: {
          link: '',
          name: '',
          name1: ''
        }
      }, {
        url: '',
        url_after: '',
        title: '导航文字',
        link: {
          link: '',
          name: '',
          name1: ''
        }
      }, {
        url: '',
        url_after: '',
        title: '导航文字',
        link: {
          link: '',
          name: '',
          name1: ''
        }
      }];
      if (this.pageInfo.footerSetting.use_type === 1) {
        return d;
      } else {
        return this.pageInfo.footerSetting.content;
      }
    },
    footerBackground: function footerBackground() {
      if (this.pageInfo.hide_footer) {
        return 'none';
      } else {
        return this.pageInfo.footerSetting.footer_background;
      }
    }
  }),
  created: function created() {
    this.avatar = localStorage.getItem('avatar');
  },
  methods: {
    handleTplOpen: function handleTplOpen() {
      var _this2 = this;
      return new Promise(function (resolve) {
        _this2.$confirm('选择新模板后，当前页面内容将被替换且不被保存， 请确认是否需要手动保存。', '提示', {
          confirmButtonText: '继续选择模板',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(function () {
          resolve(true);
        }).catch(function () {
          resolve(false);
        });
      });
    },
    /**
     * 处理返回顶部
     * @return {[type]} [description]
     */
    handleTotop: function handleTotop() {
      this.scrollbar.$el.firstChild.scrollTop = 0;
    },
    /**
     * 处理返回顶部
     * @return {[type]} [description]
     */
    handleGuide: function handleGuide() {
      window.localStorage.setItem('is_guide', 0);
      this.$refs.guide.is_close = false;
      this.$refs.guide.onSetPage();
    },
    /**
     * 处理名称
     * @param  {[type]} value [description]
     * @return {[type]}       [description]
     */
    getPageTitle: function getPageTitle(value) {
      if (value && Number(value.length) === 13) {
        return '默认微页面' + this.pageInfo.id;
      } else {
        return value || '默认微页面';
      }
    },
    /**
     * [handlePageName description]
     * @return {[type]} [description]
     */
    handlePageName: function handlePageName() {
      this.$store.commit('finish/setIndex', -2);
    },
    /**
     * 设置组件排序
     * @return {[type]} [description]
     */
    handlePackage: function handlePackage() {
      this.$store.commit('finish/setIndex', -1);
    },
    handleFooterPage: function handleFooterPage() {
      this.$store.commit('finish/setIndex', -3);
    },
    /**
     * 执行页面保存操作
     * @return {[type]} [description]
     */
    handlePageSave: function handlePageSave() {
      var _this3 = this;
      var loading = this.$loading({
        lock: true,
        text: '保存中……',
        spinner: 'el-icon-loading'
      });
      if (this.pageInfo.title === '默认微页') {
        return;
      }
      this.actionPageSave().then(function () {
        loading.close();
        _this3.$message.success('保存成功');
        _this3.isEdit = true;
      }).catch(function (error) {
        loading.close();
        console.error('错误信息', error);
      });
    },
    /**
     * 处理数据保存
     * @return {[type]} [description]
     */
    actionPageSave: function actionPageSave() {
      var _this = this;
      return new Promise(function (resolve, reject) {
        var content = _this.$store.state.finish.pages;
        var total = 0;
        var tagIndex = null;
        for (var index in content) {
          var item = content[index];
          if (item.name === 'tag' && item.content.loadMore) {
            tagIndex = index;
            total++;
          }
          if (total > 1) {
            _this.$message({
              message: '微页面仅允许添加一个启动分页的选项卡组件，请删减！',
              type: 'error'
            });
            reject(false);
            return;
          }
        }
        if (total > 1) {
          _this.$message({
            message: '微页面仅允许添加一个启动分页的选项卡组件，请删减！',
            type: 'error'
          });
          reject(false);
          return;
        }
        if (tagIndex !== null) {
          content.push(content[tagIndex]);
          content.splice(tagIndex, 1);
          content[content.length - 1].content.data.forEach(function (m) {
            if (m.link && m.link.content) {
              m.link.content.system_count = 0;
            }
          });
        }
        var newcontent = _this.formatJsonStr(JSON.stringify(content));
        console.log('newcontent', newcontent);
        console.log(_this.formatJsonStr(JSON.stringify(_this.pageInfo.headerSetting)));
        var data = {
          name: _this.pageInfo.name,
          content: JSON.stringify(newcontent),
          config_data: JSON.stringify({
            background: _this.pageInfo.background,
            background_f: (0, _index.setRgbaTo16)(_this.pageInfo.background),
            hide_header: _this.pageInfo.hide_header,
            hide_header_view: _this.pageInfo.hide_header_view,
            headerSetting: _this.formatJsonStr(JSON.stringify(_this.pageInfo.headerSetting)),
            bg_image: _this.pageInfo.bg_image,
            bg_image_mode: _this.pageInfo.bg_image_mode,
            share_image: _this.pageInfo.share_image,
            share_txt: _this.pageInfo.share_txt,
            hide_footer: _this.pageInfo.hide_footer,
            show_copyright: _this.pageInfo.show_copyright,
            footerSetting: _this.formatJsonStr(JSON.stringify(_this.pageInfo.footerSetting)),
            login_config: _this.pageInfo.login_config
          }),
          is_release: 0,
          type: _this.pageInfo.type
        };
        if (data.content.indexOf('educational-media/custom/custom') !== -1) {
          data.content = data.content.replace(/educational-media\/custom\/custom/g, 'pages/custom/custom');
        }
        if (data.config_data.indexOf('educational-media/custom/custom') !== -1) {
          data.config_data = data.config_data.replace(/educational-media\/custom\/custom/g, 'pages/custom/custom');
        }
        if (!data.type) {
          if (_this.pageType) {
            data.type = _this.pageType;
          } else {
            data.type = 'diy_page';
          }
        }
        var action = _system.diypageCreate;
        if (_this.pageInfo.id) {
          action = _system.diypageEdit;
        }
        action(_this.pageInfo.id, data).then(function (res) {
          _this.pageInfo.id = res.data.id;
          resolve(true);
        }).catch(function (message) {
          return reject(message);
        });
        // _this.pageInfo.content = JSON.stringify(content);
        // _this.$heshop
        //   .pages('put', _this.pageInfo)
        //   .then(function () {
        //     resolve(true);
        //   })
        //   .catch(() => {
        //     reject(false);
        //   });
      });
    },
    sendAction: function sendAction() {
      var _this4 = this;
      if (!this.isEdit) {
        this.$message.error('请先保存您修改的样式');
        return false;
      }
      this.$modalYouSure("\u70B9\u51FB\u786E\u8BA4\u540E\uFF0C\u5C0F\u7A0B\u5E8F\u9875\u9762\u4F1A\u5373\u65F6\u5E94\u7528\u81F3\u8BE5\u65B0\u6A21\u677F\u98CE\u683C\uFF0C\u8BF7\u786E\u8BA4\uFF01").then(function () {
        var data = {};
        if (_this4.pageType) {
          data.type = _this4.pageType;
        } else {
          data.id = _this4.pageInfo.id;
        }
        (0, _system.releasePageApi)(data).then(function () {
          _this4.$message.success('发布成功');
          _this4.isEdit = false;
        }).catch(function () {});
      }).catch(function () {});
    },
    formatJsonStr: function formatJsonStr(str) {
      if (str === null || str === '{}' || str === undefined) {
        return str;
      }
      try {
        var json = {};
        if (typeof str === 'string') {
          json = JSON.parse(str);
        } else {
          json = str;
        }
        for (var k in json) {
          var kv = json[k];
          try {
            // 数组
            if (Array.isArray(kv)) {
              try {
                // json字符串处理
                var sub = kv;
                for (var i = 0; i < sub.length; i++) {
                  if ((0, _typeof2.default)(sub[i]) === 'object') {
                    sub[i] = this.formatJsonStr(sub[i]);
                  }
                }
                json[k] = sub;
              } catch (e) {
                console.log(e);
              }
              continue;
            }
            if (kv && typeof kv === 'string') {
              // if(k.indexOf('_f')>-1){
              //   delete json[k];
              // }
              if ((kv.indexOf('#') !== -1 || kv.indexOf('rgb') !== -1) && k.indexOf('_f') === -1) {
                var kvf = k + '_f';
                json[kvf] = (0, _index.setRgbaTo16)(kv);
              }
              if (k == 'img_height') {
                json[k] = Number(kv);
              }
              if (k == 'img_width') {
                json[k] = Number(kv);
              }
            }
            if ((0, _typeof2.default)(kv) === 'object') {
              json[k] = this.formatJsonStr(kv);
            }
          } catch (e2) {}
        }
        return json;
      } catch (e) {}
      return str;
    },
    downloadFile: function downloadFile(content, fileName) {
      // 下载base64图片
      var base64ToBlob = function base64ToBlob(code) {
        var parts = code.split(';base64,');
        var contentType = parts[0].split(':')[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;
        var uInt8Array = new Uint8Array(rawLength);
        for (var i = 0; i < rawLength; ++i) {
          uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], {
          type: contentType
        });
      };
      var aLink = document.createElement('a');
      var blob = base64ToBlob(content); // new Blob([content]);
      var evt = document.createEvent('HTMLEvents');
      evt.initEvent('click', true, true); // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName;
      aLink.href = URL.createObjectURL(blob);
      aLink.click();
    },
    /**
     * 设置为首页
     * 确认设置该微页面为首页？
     * @return {[type]} [description]
     */
    handlePageSetting: function handlePageSetting() {
      // this.$confirm('确认设置该微页面为首页？', '提示', {
      //   confirmButtonText: '确认',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      //   center: true
      // })
      //   .then(() => {
      //
      //   })
      //   .catch(() => {});
      // this.$message.error('暂未开放');
    },
    backAction: function backAction() {
      this.$router.back();
      // this.$router.push({ path: '/merchant/pageManagement/renovation/diy' });
    },
    /**
     * 拖拽拿起事件
     * sort 排序  drag 拖拽
     * @param  {[type]} $event [description]
     * @return {[type]}        [description]
     */
    dragStart: function dragStart($event) {
      if (this.hasTab && $event.name === 'tag') {
        this.$message.error('选项卡每个页面最多添加一个');
        return;
      }
      this.$store.commit('finish/setComs', $event);
    },
    /**
     * 拖拽放下事件
     * @param  {[type]} $event [description]
     * @return {[type]}        [description]
     */
    dragEnd: function dragEnd($event) {
      this.$store.commit('finish/setComs', '');
      $event.preventDefault();
    },
    mouseOver: function mouseOver(key) {
      this[key] = true;
    },
    mouseleave: function mouseleave(key) {
      this[key] = false;
    },
    handleCancelAction: function handleCancelAction() {
      this.$refs.childView.id = this.pageInfo.id;
      this.$refs.childView.loadPageInfo();
    },
    previewAction: function previewAction(type) {
      var path = '/pages/home/home?is_preview=1&id=';
      if (!this.pageType) {
        path = "/pages/home/home?is_preview=1&id=".concat(this.pageInfo.id);
      }
      this.showImgUrl = '';
      this.previewCode(path);
    },
    previewCode: function previewCode(path) {
      var _this5 = this;
      this.showImgType = 2;
      this.showImg = true;
      var action = process.env.VUE_APP_BASE_API + '/api/sharePoster';
      var mer_id = this.$store.getters.mer_id;
      action += '?return_format=2&poster_type=diyQrcode&qrcode_type=1&Independent_qrcode=1&path=' + encodeURIComponent(path) + '&mer_id=' + mer_id;
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      _request.default.get(action).then(function (res) {
        loading.close();
        _this5.showImgUrl = res.data.base_image;
      }).catch(function () {
        loading.close();
        _this5.showImg = false;
      });
    }
  }
};