var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "posterMain" },
    [
      _c("posterHeader", { on: { onRefresh: _vm.onRefresh } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "nodi", staticStyle: { "overflow-x": "auto" } },
        [
          _c(
            "div",
            { staticClass: "poster-type-container" },
            [
              _vm._l(_vm.labelList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "poster-type-item cur",
                    class: _vm.activeTag.includes(item.label_id)
                      ? "type-active"
                      : "",
                    on: {
                      click: function ($event) {
                        return _vm.tagChange(item.label_id)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.label_name))]
                )
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { color: "#6415D7 !important" },
                  attrs: { size: "small", type: "text" },
                  on: { click: _vm.onAdd },
                },
                [_vm._v(" 管理标签 ")]
              ),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isShow
        ? _c("grid-manager", {
            attrs: {
              gridList: _vm.posterList,
              "is-loading": _vm.loadTemplate,
              filed: "generate_result",
              footHeight: 55,
            },
            on: { loadMore: _vm.onloadMore },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    var index = ref.index
                    return [
                      _c("div", { staticClass: "posterEndItem" }, [
                        row.generate_result_status == 1
                          ? _c(
                              "div",
                              {
                                staticClass: "poster-item-con",
                                style: {
                                  height: "calc(" + row.boxheight + " - 55px)",
                                },
                              },
                              [
                                _c("div", { staticClass: "poster-item-dia" }, [
                                  _c("img", {
                                    staticClass: "poster-loading-img",
                                    attrs: {
                                      src: require("@/assets/images/poster/loading.gif"),
                                      alt: "",
                                    },
                                  }),
                                ]),
                              ]
                            )
                          : _c(
                              "div",
                              { staticClass: "poster-item-con" },
                              [
                                row.generate_result_status == 2
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "position-img",
                                          staticStyle: { right: "90px" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onUpd(row)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                effect: "dark",
                                                content: "编辑",
                                                placement: "top",
                                                enterable: false,
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("@/assets/images/poster/upd.png"),
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "position-img",
                                          staticStyle: { right: "50px" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onSwitchResult(row.id)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                effect: "dark",
                                                content: "确认",
                                                placement: "top",
                                                enterable: false,
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("@/assets/images/poster/dui.png"),
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                row.generate_result_status == 3
                                  ? [
                                      row.is_like == 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "position-img",
                                              staticStyle: { right: "90px" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onSwitchLink(
                                                    row.id,
                                                    1
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    effect: "dark",
                                                    content: "点赞",
                                                    placement: "top",
                                                    enterable: false,
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: require("@/assets/images/poster/zan.png"),
                                                      alt: "",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "position-img",
                                              staticStyle: { right: "90px" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onSwitchLink(
                                                    row.id,
                                                    0
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    effect: "dark",
                                                    content: "点赞",
                                                    placement: "top",
                                                    enterable: false,
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: require("@/assets/images/poster/redlod.png"),
                                                      alt: "",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "position-img",
                                          staticStyle: { right: "50px" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.downloadImage(row)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                effect: "dark",
                                                content: "下载",
                                                placement: "top",
                                                enterable: false,
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("@/assets/images/poster/lod.png"),
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "position-img",
                                    staticStyle: { right: "10px" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delAction(row)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: "删除",
                                          placement: "top",
                                          enterable: false,
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/images/poster/del.png"),
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                row.isGroup
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "position-img noBack",
                                        class: row.isGroup ? "posY" : "",
                                        staticStyle: { left: "10px" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onCheckChange(
                                              row,
                                              false,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              effect: "dark",
                                              content: "选择",
                                              placement: "top",
                                              enterable: false,
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/images/poster/check.png"),
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass: "position-img noBack",
                                        staticStyle: { left: "10px" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onCheckChange(
                                              row,
                                              true,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              effect: "dark",
                                              content: "选择",
                                              placement: "top",
                                              enterable: false,
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/images/poster/checknull.png"),
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                _vm._v(" "),
                                row.generate_result
                                  ? _c("el-image", {
                                      staticClass: "posIimg",
                                      attrs: {
                                        src: "" + row.generate_result.imageUrl,
                                        "preview-src-list": [
                                          row.generate_result.imageUrl,
                                        ],
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              2
                            ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex posterItemTextStatus" },
                          [
                            row.generate_result
                              ? _c(
                                  "div",
                                  { staticClass: "poster-item-title-text" },
                                  [
                                    _vm._v(
                                      _vm._s(row.generate_result.height) +
                                        "*" +
                                        _vm._s(row.generate_result.width)
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "poster-item-status" }, [
                              row.generate_result_status == 2
                                ? _c("div", { staticClass: "hb-conStatus" }, [
                                    _vm._v("待确认"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              row.generate_result_status == 3
                                ? _c("div", { staticClass: "hb3-conStatus" }, [
                                    _vm._v("已确认"),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "poster-item-title" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(row.update_time) +
                              "\n          "
                          ),
                        ]),
                      ]),
                    ]
                  },
                },
              ],
              null,
              false,
              750707965
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "flex justify-b orderFootOne" }, [
        _c(
          "div",
          { staticClass: "flex-one flex" },
          [
            _vm.selectAll
              ? _c(
                  "el-button",
                  {
                    staticStyle: {
                      color: "#6415D7 !important",
                      "margin-right": "7px",
                    },
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onALlSelect(false)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "position-sele-img",
                      attrs: {
                        src: require("@/assets/images/poster/check.png"),
                        alt: "",
                      },
                    }),
                    _vm._v("\n        取消 "),
                    _c("span", [
                      _vm._v("已选" + _vm._s(_vm.selectKeys.length)),
                    ]),
                  ]
                )
              : _c(
                  "el-button",
                  {
                    staticStyle: {
                      color: "#6415D7 !important",
                      "margin-right": "7px",
                      "font-s ize": "14px",
                    },
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onALlSelect(true)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "position-sele-img",
                      attrs: {
                        src: require("@/assets/images/poster/checknull.png"),
                        alt: "",
                      },
                    }),
                    _vm._v("\n        全选 "),
                    _c("span", [
                      _vm._v("已选" + _vm._s(_vm.selectKeys.length)),
                    ]),
                  ]
                ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticStyle: {
                  background: "#6415D7",
                  border: "1px solid #6415D7",
                  "border-radius": "18px",
                },
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.onDownloadAll },
              },
              [
                _c("img", {
                  staticClass: "position-sele-img",
                  attrs: {
                    src: require("@/assets/images/poster/xw.png"),
                    alt: "",
                  },
                }),
                _vm._v("\n        批量下载\n      "),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticStyle: {
                  background: "#6415D7",
                  border: "1px solid #6415D7",
                  "border-radius": "18px",
                },
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.onAllHandTag },
              },
              [_vm._v(" 添加标签 ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("addtag", {
        ref: "addtagRef",
        on: { onRefTag: _vm.onRefTag, handTag: _vm.onHandTag },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }