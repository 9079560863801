"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _vueImgCutter = _interopRequireDefault(require("vue-img-cutter"));
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    ImgCutter: _vueImgCutter.default
  },
  data: function data() {
    return {
      visible: false,
      cutWidth: 200,
      cutHeight: 200,
      boxWidth: 800,
      boxHeight: 800
    };
  },
  // computed: {
  // },
  created: function created() {},
  methods: {
    open: function open(url, sizeArr) {
      var _this = this;
      this.boxWidth = Number(sizeArr[0]);
      this.boxHeight = Number(sizeArr[1]);
      this.cutWidth = Number(sizeArr[0]);
      this.cutHeight = Number(sizeArr[1]);
      this.visible = true;
      setTimeout(function () {
        _this.$refs.imgCutterModal.handleOpen({
          name: url,
          src: url,
          width: 200,
          //远程图片的原始宽度
          height: 200
        });
      }, 100);
    },
    cutDown: function cutDown(row) {
      this.visible = false;
      this.$emit('susse', row);
    },
    onCancel: function onCancel() {
      this.visible = false;
    }
  }
};