var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _vm._l(_vm.viewData, function (m, i) {
        return [
          _vm.facade.auction_spacing_image && i > 0
            ? _c(
                "section",
                {
                  key: "m" + i,
                  staticStyle: { display: "flex", "align-items": "center" },
                  style: {
                    margin:
                      (i > 0 ? _vm.facade.item_margin : 0) +
                      "px " +
                      _vm.facade.page_margin +
                      "px",
                  },
                },
                [
                  _c(
                    "section",
                    {
                      style:
                        "flex:1;border-top:" +
                        _vm.facade.auction_spacing_height +
                        "px " +
                        (_vm.facade.auction_spacing_type == "dashed"
                          ? "dashed"
                          : "solid") +
                        " " +
                        _vm.facade.auction_spacing_color +
                        ";",
                    },
                    [
                      _c("svg", {
                        staticStyle: {
                          float: "left",
                          "line-height": "0",
                          width: "0",
                          "vertical-align": "top",
                        },
                        attrs: { viewBox: "0 0 1 1" },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.facade.auction_spacing_image
                    ? _c("img", {
                        style:
                          "display:block;width:" +
                          (_vm.facade.auction_spacing_image_width || 0) +
                          "px;margin: 0 " +
                          (_vm.facade.auction_spacing_image_margin || 0) +
                          "px",
                        attrs: {
                          src: _vm.facade.auction_spacing_image,
                          alt: "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "section",
                    {
                      style:
                        "flex:1;border-top:" +
                        _vm.facade.auction_spacing_height +
                        "px " +
                        (_vm.facade.auction_spacing_type == "dashed"
                          ? "dashed"
                          : "solid") +
                        " " +
                        _vm.facade.auction_spacing_color +
                        ";",
                    },
                    [
                      _c("svg", {
                        staticStyle: {
                          float: "left",
                          "line-height": "0",
                          width: "0",
                          "vertical-align": "top",
                        },
                        attrs: { viewBox: "0 0 1 1" },
                      }),
                    ]
                  ),
                ]
              )
            : i > 0
            ? _c("section", {
                key: "m" + i,
                style: {
                  height: (i > 0 ? _vm.facade.item_margin : 0) + "px",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "section",
            {
              key: i,
              style: {
                margin: "0 " + _vm.facade.page_margin + "px",
                borderRadius: _vm.facade.radius + "px",
                overflow: "hidden",
              },
            },
            [
              _c("section", { staticStyle: { display: "flex" } }, [
                _c("section", { staticStyle: { flex: "1" } }, [
                  _c(
                    "section",
                    {
                      style: {
                        background: _vm.getAllColor(m, "item_background"),
                      },
                    },
                    [
                      (_vm.facade.style == 4 && !_vm.checkShowMainLongImg(m)) ||
                      ((_vm.facade.style == 4 ||
                        _vm.facade.style == 5 ||
                        _vm.facade.style == 6 ||
                        _vm.facade.style == 7) &&
                        (!_vm.facade.showLongImage ||
                          (_vm.facade.showLongImage &&
                            (!m.columnArr || !m.columnArr.length) &&
                            (!m.rowArr || !m.rowArr.length))))
                        ? _c(
                            "section",
                            { style: { padding: _vm.imgPadding } },
                            [
                              m.id && _vm.miniAppid
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "weapp_image_link",
                                      attrs: {
                                        "data-miniprogram-appid": _vm.miniAppid,
                                        "data-miniprogram-path":
                                          "pages/auctionDetail/index?id=" +
                                          m.id,
                                        "data-miniprogram-nickname":
                                          _vm.miniName,
                                        href: "",
                                        "data-miniprogram-type": "image",
                                        "data-miniprogram-servicetype": "2",
                                        "wah-hotarea": "click",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "rich_pages wxw-img",
                                        staticStyle: { display: "block" },
                                        attrs: {
                                          "data-ratio": "1",
                                          "data-s": "300,640",
                                          "data-fail": "0",
                                          "data-src": m.image,
                                          src: m.image,
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  )
                                : _c("img", {
                                    staticStyle: { display: "block" },
                                    attrs: { src: m.image, alt: "" },
                                  }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      m.desc && _vm.facade.style == 6
                        ? _c(
                            "section",
                            {
                              style: {
                                padding:
                                  "0 " +
                                  _vm.facade.item_padding +
                                  "px " +
                                  _vm.facade.item_padding +
                                  "px " +
                                  _vm.facade.item_padding +
                                  "px",
                              },
                            },
                            [
                              _c(
                                "section",
                                {
                                  style: {
                                    marginTop: _vm.facade.desc_margin + "px",
                                    color: _vm.getAllColor(m, "desc_color"),
                                    fontWeight: _vm.facade.desc_weight,
                                    fontSize: _vm.facade.desc_size + "px",
                                    textAlign: _vm.facade.desc_text_align,
                                    lineHeight:
                                      _vm.facade.desc_line_height + "px",
                                    whiteSpace: "pre-wrap",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n        " + _vm._s(m.desc) + "\n      "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.style == 2
                        ? _c(
                            "section",
                            {
                              style: {
                                padding:
                                  "0 " +
                                  _vm.facade.item_padding +
                                  "px " +
                                  _vm.facade.item_padding +
                                  "px " +
                                  _vm.facade.item_padding +
                                  "px",
                              },
                            },
                            [
                              m.slider_image && m.slider_image.length > 0
                                ? _c(
                                    "section",
                                    _vm._l(m.slider_image, function (im, i) {
                                      return _c(
                                        "section",
                                        {
                                          key: "im" + i,
                                          staticStyle: {
                                            display: "flex",
                                            width: "100%",
                                            "flex-flow": "column",
                                            "box-sizing": "border-box",
                                          },
                                          style:
                                            i != 0 ? "margin-top: 10px;" : "",
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              display: "block",
                                              width: "100%",
                                            },
                                            attrs: { src: im, alt: "" },
                                          }),
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.facade.style == 3 || _vm.facade.style == 5
                        ? _c(
                            "section",
                            {
                              style: {
                                padding:
                                  (_vm.facade.style == 5
                                    ? _vm.facade.item_padding
                                    : 0) +
                                  "px " +
                                  _vm.facade.item_padding +
                                  "px " +
                                  _vm.facade.item_padding +
                                  "px " +
                                  _vm.facade.item_padding +
                                  "px",
                              },
                            },
                            [
                              _c(
                                "section",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    width: "100%",
                                    "flex-wrap": "wrap",
                                    "box-sizing": "border-box",
                                  },
                                },
                                _vm._l(m.slider_image, function (im, i) {
                                  return _c(
                                    "section",
                                    {
                                      key: "im" + i,
                                      staticStyle: {
                                        width: "50%",
                                        "box-sizing": "border-box",
                                      },
                                      style: {
                                        padding:
                                          "0 " +
                                          (i % 2 == 0
                                            ? _vm.facade.square_padding / 2
                                            : 0) +
                                          "px " +
                                          _vm.facade.square_padding +
                                          "px " +
                                          (i % 2 == 0
                                            ? 0
                                            : _vm.facade.square_padding / 2) +
                                          "px",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          display: "block",
                                          width: "100%",
                                        },
                                        attrs: { src: im + "!120a", alt: "" },
                                      }),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "section",
                        {
                          style: {
                            padding:
                              "0 " +
                              _vm.facade.item_padding +
                              "px " +
                              _vm.facade.item_padding +
                              "px " +
                              _vm.facade.item_padding +
                              "px",
                          },
                        },
                        [
                          _vm.content.auctionSlideFlag &&
                          (_vm.facade.style == 1 ||
                            _vm.facade.style == 6 ||
                            _vm.facade.style == 7)
                            ? [
                                _vm.content.playmodel == 1
                                  ? _c(
                                      "section",
                                      {
                                        staticClass:
                                          "__web-inspector-hide-shortcut__",
                                        staticStyle: {
                                          margin: "0px 0%",
                                          "box-sizing": "border-box",
                                        },
                                      },
                                      [
                                        _c(
                                          "section",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                              width: "100%",
                                              "vertical-align": "top",
                                              "overflow-x": "auto",
                                              "box-sizing": "border-box",
                                            },
                                          },
                                          [
                                            _c(
                                              "section",
                                              {
                                                staticStyle: {
                                                  overflow: "hidden",
                                                  "box-sizing": "border-box",
                                                },
                                                style:
                                                  "width:" +
                                                  m.slider_image.length * 100 +
                                                  "%;max-width: " +
                                                  m.slider_image.length * 100 +
                                                  "% !important;",
                                              },
                                              _vm._l(
                                                m.slider_image,
                                                function (im, i) {
                                                  return _c(
                                                    "section",
                                                    {
                                                      key: "s" + i,
                                                      staticStyle: {
                                                        display: "inline-block",
                                                        "vertical-align":
                                                          "middle",
                                                        width: "20%",
                                                        "box-sizing":
                                                          "border-box",
                                                      },
                                                      style:
                                                        "width: " +
                                                        100 /
                                                          m.slider_image
                                                            .length +
                                                        "%;",
                                                    },
                                                    [
                                                      _c(
                                                        "section",
                                                        {
                                                          staticStyle: {
                                                            "text-align":
                                                              "center",
                                                            "margin-top":
                                                              "10px",
                                                            "margin-bottom":
                                                              "10px",
                                                            "line-height": "0",
                                                            "box-sizing":
                                                              "border-box",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "section",
                                                            {
                                                              staticStyle: {
                                                                "max-width":
                                                                  "100%",
                                                                "vertical-align":
                                                                  "middle",
                                                                display:
                                                                  "inline-block",
                                                                "line-height":
                                                                  "0",
                                                                "box-sizing":
                                                                  "border-box",
                                                              },
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticClass:
                                                                  "rich_pages wxw-img",
                                                                staticStyle: {
                                                                  "vertical-align":
                                                                    "middle",
                                                                  "max-width":
                                                                    "100%",
                                                                  width:
                                                                    "372px !important",
                                                                  "box-sizing":
                                                                    "border-box",
                                                                  height:
                                                                    "auto !important",
                                                                  visibility:
                                                                    "visible !important",
                                                                },
                                                                attrs: {
                                                                  "data-ratio":
                                                                    "1",
                                                                  "data-s":
                                                                    "300,640",
                                                                  "data-src":
                                                                    im +
                                                                    "?x-oss-process=image/auto-orient,1/resize,m_pad,w_" +
                                                                    _vm.swiperWidth +
                                                                    ",h_" +
                                                                    _vm.swiperHeight +
                                                                    ",color_" +
                                                                    _vm.rgbaTo16color(
                                                                      m
                                                                    ) +
                                                                    "/quality,q_100/format,webp",
                                                                  "data-w":
                                                                    "1080",
                                                                  "data-original-style":
                                                                    "vertical-align: middle;max-width: 100%;width: 100%;box-sizing: border-box;",
                                                                  "data-index":
                                                                    "110",
                                                                  src:
                                                                    im +
                                                                    "?x-oss-process=image/auto-orient,1/resize,m_pad,w_" +
                                                                    _vm.swiperWidth +
                                                                    ",h_" +
                                                                    _vm.swiperHeight +
                                                                    ",color_" +
                                                                    _vm.rgbaTo16color(
                                                                      m
                                                                    ) +
                                                                    "/quality,q_100/format,webp",
                                                                  _width:
                                                                    "100%",
                                                                  crossorigin:
                                                                    "anonymous",
                                                                  alt: "图片",
                                                                  "data-fail":
                                                                    "0",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "section",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "flex-flow": "row",
                                              "text-align": "center",
                                              "justify-content": "center",
                                              "box-sizing": "border-box",
                                            },
                                            style: {
                                              marginTop:
                                                (_vm.facade.animate_margin ||
                                                  0) + "px",
                                            },
                                          },
                                          [
                                            _c(
                                              "section",
                                              {
                                                staticStyle: {
                                                  display: "inline-block",
                                                  "vertical-align": "middle",
                                                  width: "auto",
                                                  flex: "0 0 0%",
                                                  "align-self": "center",
                                                  height: "auto",
                                                  "line-height": "0",
                                                  "box-sizing": "border-box",
                                                },
                                              },
                                              [
                                                _c(
                                                  "section",
                                                  {
                                                    staticStyle: {
                                                      transform:
                                                        "perspective(0px)",
                                                      "-webkit-transform":
                                                        "perspective(0px)",
                                                      "-moz-transform":
                                                        "perspective(0px)",
                                                      "-o-transform":
                                                        "perspective(0px)",
                                                      "transform-style": "flat",
                                                      "box-sizing":
                                                        "border-box",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "section",
                                                      {
                                                        staticStyle: {
                                                          margin: "0px 0%",
                                                          transform:
                                                            "rotateY(180deg)",
                                                          "-webkit-transform":
                                                            "rotateY(180deg)",
                                                          "-moz-transform":
                                                            "rotateY(180deg)",
                                                          "-o-transform":
                                                            "rotateY(180deg)",
                                                          "box-sizing":
                                                            "border-box",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "section",
                                                          {
                                                            style:
                                                              "display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 4px 0px 4px 6px;border-color: rgba(255, 255, 255, 0) rgb(240, 244, 255) rgba(255, 255, 255, 0) " +
                                                              (_vm.facade
                                                                .animate_color ||
                                                                "rgb(124, 124, 124)") +
                                                              ";box-sizing: border-box;",
                                                          },
                                                          [
                                                            _c("svg", {
                                                              staticStyle: {
                                                                float: "left",
                                                                "line-height":
                                                                  "0",
                                                                width: "0",
                                                                "vertical-align":
                                                                  "top",
                                                              },
                                                              attrs: {
                                                                viewBox:
                                                                  "0 0 1 1",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "section",
                                              {
                                                staticStyle: {
                                                  display: "inline-block",
                                                  "vertical-align": "middle",
                                                  width: "auto",
                                                  flex: "0 0 auto",
                                                  "align-self": "center",
                                                  "min-width": "10%",
                                                  "max-width": "100%",
                                                  height: "auto",
                                                  "border-width": "0px",
                                                  "box-sizing": "border-box",
                                                },
                                              },
                                              [
                                                _c(
                                                  "section",
                                                  {
                                                    staticStyle: {
                                                      margin: "0px 0%",
                                                      "box-sizing":
                                                        "border-box",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "section",
                                                      {
                                                        style:
                                                          "color: " +
                                                          (_vm.facade
                                                            .animate_color ||
                                                            "rgb(124, 124, 124)") +
                                                          " ;padding: 4px 8px;font-size: 12px;box-sizing: border-box;",
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticStyle: {
                                                              margin: "0px",
                                                              padding: "0px",
                                                              "box-sizing":
                                                                "border-box",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "左右滑动查看"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "section",
                                              {
                                                staticStyle: {
                                                  display: "inline-block",
                                                  "vertical-align": "middle",
                                                  width: "auto",
                                                  flex: "0 0 0%",
                                                  height: "auto",
                                                  "line-height": "0",
                                                  "align-self": "center",
                                                  "box-sizing": "border-box",
                                                },
                                              },
                                              [
                                                _c(
                                                  "section",
                                                  {
                                                    staticStyle: {
                                                      margin: "0px 0%",
                                                      "box-sizing":
                                                        "border-box",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "section",
                                                      {
                                                        style:
                                                          "display: inline-block;width: 0px;height: 0px;vertical-align: top;overflow: hidden;border-style: solid;border-width: 4px 0px 4px 6px;border-color: rgba(255, 255, 255, 0) rgb(240, 244, 255) rgba(255, 255, 255, 0) " +
                                                          (_vm.facade
                                                            .animate_color ||
                                                            "rgb(124, 124, 124)") +
                                                          ";box-sizing: border-box;",
                                                      },
                                                      [
                                                        _c("svg", {
                                                          staticStyle: {
                                                            float: "left",
                                                            "line-height": "0",
                                                            width: "0",
                                                            "vertical-align":
                                                              "top",
                                                          },
                                                          attrs: {
                                                            viewBox: "0 0 1 1",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm.content.playmodel == 2
                                  ? _c(
                                      "section",
                                      {
                                        staticStyle: {
                                          transform:
                                            "translate3d(0px, 0px, 0px)",
                                          "-webkit-transform":
                                            "translate3d(0px, 0px, 0px)",
                                          "-moz-transform":
                                            "translate3d(0px, 0px, 0px)",
                                          "-o-transform":
                                            "translate3d(0px, 0px, 0px)",
                                          "text-align": "center",
                                          "box-sizing": "border-box",
                                        },
                                      },
                                      [_vm._m(0, true)]
                                    )
                                  : _vm.content.playmodel == 3
                                  ? _c(
                                      "section",
                                      {
                                        staticStyle: {
                                          display: "inline-block",
                                          width: "100%",
                                          "vertical-align": "top",
                                          overflow: "hidden",
                                          "align-self": "flex-start",
                                          "line-height": "0",
                                          "box-sizing": "border-box",
                                        },
                                        style: {
                                          marginTop:
                                            "" +
                                            (_vm.facade.style == 1
                                              ? "0"
                                              : "10px"),
                                        },
                                      },
                                      [
                                        m.slider_image.length > 1
                                          ? [
                                              _c(
                                                "svg",
                                                {
                                                  staticStyle: {
                                                    display: "block",
                                                    margin: "0 auto",
                                                  },
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    viewBox:
                                                      "0 0 " +
                                                      _vm.swiperWidth +
                                                      " " +
                                                      _vm.swiperHeight,
                                                    width: "100%",
                                                  },
                                                },
                                                _vm._l(
                                                  m.slider_image,
                                                  function (im, i) {
                                                    return _c(
                                                      "g",
                                                      {
                                                        key: i,
                                                        attrs: {
                                                          transform:
                                                            "translate(" +
                                                            _vm.swiperWidth +
                                                            " 0)",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "g",
                                                          [
                                                            _c(
                                                              "foreignObject",
                                                              {
                                                                attrs: {
                                                                  width:
                                                                    _vm.swiperWidth,
                                                                  height:
                                                                    _vm.swiperHeight,
                                                                  x: "0",
                                                                  y:
                                                                    0 -
                                                                    _vm.swiperHeight /
                                                                      2,
                                                                },
                                                              },
                                                              [
                                                                _c("svg", {
                                                                  style:
                                                                    "width: 100%; background-color: transparent; background-position: 0% 0%;background-repeat: no-repeat; background-size: 100%; background-image: url(" +
                                                                    im +
                                                                    "?x-oss-process=image/auto-orient,1/resize,m_pad,w_" +
                                                                    _vm.swiperWidth +
                                                                    ",h_" +
                                                                    _vm.swiperHeight +
                                                                    ",color_" +
                                                                    _vm.rgbaTo16color(
                                                                      m
                                                                    ) +
                                                                    "/quality,q_100/format,webp);background-attachment: scroll;        -webkit-tap-highlight-color: transparent; user-select: none; visibility: visible; pointer-events: none;",
                                                                  attrs: {
                                                                    viewBox:
                                                                      "0 0 " +
                                                                      _vm.swiperWidth +
                                                                      " " +
                                                                      _vm.swiperHeight,
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "animateTransform",
                                                              {
                                                                attrs: {
                                                                  begin:
                                                                    i * 3 +
                                                                    (i > 1
                                                                      ? i - 1
                                                                      : 0) +
                                                                    "s",
                                                                  calcMode:
                                                                    "undefined",
                                                                  fill: "freeze",
                                                                  attributeName:
                                                                    "transform",
                                                                  type: "scale",
                                                                  repeatCount:
                                                                    "indefinite",
                                                                  dur:
                                                                    m
                                                                      .slider_image
                                                                      .length *
                                                                    4,
                                                                  values:
                                                                    i > 0
                                                                      ? _vm.scale2
                                                                      : _vm.scale1,
                                                                  keyTimes:
                                                                    i > 0
                                                                      ? _vm.keyTimes2(
                                                                          m
                                                                            .slider_image
                                                                            .length
                                                                        )
                                                                      : _vm.keyTimes1(
                                                                          m
                                                                            .slider_image
                                                                            .length
                                                                        ),
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c("animateTransform", {
                                                          attrs: {
                                                            attributeName:
                                                              "transform",
                                                            type: "translate",
                                                            fill: "freeze",
                                                            begin:
                                                              i * 3 +
                                                              (i > 1
                                                                ? i - 1
                                                                : 0) +
                                                              "s",
                                                            restart: "never",
                                                            repeatCount:
                                                              "indefinite",
                                                            dur:
                                                              m.slider_image
                                                                .length * 4,
                                                            values:
                                                              i > 0
                                                                ? _vm.values2
                                                                : _vm.values1,
                                                            keyTimes:
                                                              i > 0
                                                                ? _vm.keyTimes2(
                                                                    m
                                                                      .slider_image
                                                                      .length
                                                                  )
                                                                : _vm.keyTimes1(
                                                                    m
                                                                      .slider_image
                                                                      .length
                                                                  ),
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          : [
                                              m.id && _vm.miniAppid
                                                ? _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "weapp_image_link",
                                                      attrs: {
                                                        "data-miniprogram-appid":
                                                          _vm.miniAppid,
                                                        "data-miniprogram-path":
                                                          "pages/auctionDetail/index?id=" +
                                                          m.id,
                                                        "data-miniprogram-nickname":
                                                          _vm.miniName,
                                                        href: "",
                                                        "data-miniprogram-type":
                                                          "image",
                                                        "data-miniprogram-servicetype":
                                                          "2",
                                                        "wah-hotarea": "click",
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "rich_pages wxw-img",
                                                        staticStyle: {
                                                          display: "block",
                                                        },
                                                        attrs: {
                                                          "data-ratio": "1",
                                                          "data-s": "300,640",
                                                          "data-fail": "0",
                                                          "data-src": m.image,
                                                          src: m.image,
                                                          alt: "",
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _c("img", {
                                                    staticStyle: {
                                                      display: "block",
                                                    },
                                                    attrs: {
                                                      src: m.image,
                                                      alt: "",
                                                    },
                                                  }),
                                            ],
                                      ],
                                      2
                                    )
                                  : _vm.content.playmodel == 4
                                  ? _c(
                                      "section",
                                      {
                                        staticStyle: {
                                          display: "inline-block",
                                          width: "100%",
                                          "vertical-align": "top",
                                          overflow: "hidden",
                                          "align-self": "flex-start",
                                          "line-height": "0",
                                          "box-sizing": "border-box",
                                          "margin-top": "10px",
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticStyle: { display: "block" },
                                            attrs: {
                                              viewBox:
                                                "0 0 " +
                                                _vm.swiperWidth +
                                                " " +
                                                _vm.swiperHeight,
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c(
                                              "g",
                                              [
                                                _c("foreignObject", {
                                                  attrs: {
                                                    width: "100%",
                                                    height: "100%",
                                                    x: "0",
                                                    y: "0",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              m.slider_image,
                                              function (im, index) {
                                                return _c(
                                                  "g",
                                                  { key: index },
                                                  [
                                                    _c(
                                                      "foreignObject",
                                                      {
                                                        attrs: {
                                                          width: "100%",
                                                          height: "100%",
                                                          x: "0",
                                                          y: "0",
                                                        },
                                                      },
                                                      [
                                                        _c("svg", {
                                                          staticStyle: {
                                                            width: "100%",
                                                            "background-color":
                                                              "transparent",
                                                            "background-position":
                                                              "0% 0%",
                                                            "background-repeat":
                                                              "no-repeat",
                                                            "background-size":
                                                              "100%",
                                                            "background-attachment":
                                                              "scroll",
                                                            "-webkit-tap-highlight-color":
                                                              "transparent",
                                                            "user-select":
                                                              "none",
                                                            visibility:
                                                              "visible",
                                                            "pointer-events":
                                                              "none",
                                                          },
                                                          style:
                                                            "background-image: url(" +
                                                            im +
                                                            "?x-oss-process=image/auto-orient,1/resize,m_pad,w_" +
                                                            _vm.swiperWidth +
                                                            ",h_" +
                                                            _vm.swiperHeight +
                                                            ",color_" +
                                                            _vm.rgbaTo16color(
                                                              m
                                                            ) +
                                                            "/quality,q_100/format,webp)",
                                                          attrs: {
                                                            viewBox:
                                                              "0 0 " +
                                                              _vm.swiperWidth +
                                                              " " +
                                                              _vm.swiperHeight,
                                                            xmlns:
                                                              "http://www.w3.org/2000/svg",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    index % 4 == 0
                                                      ? _c("animateTransform", {
                                                          attrs: {
                                                            type: "translate",
                                                            fill: "freeze",
                                                            dur: "4s",
                                                            values:
                                                              "0 " +
                                                              _vm.swiperHeight +
                                                              ";0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0",
                                                            restart: "never",
                                                            keySplines:
                                                              "0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1",
                                                            keyTimes:
                                                              "0;0.13;0.25;0.38;0.50;0.63;0.75;0.88;1.00",
                                                            calcMode: "spline",
                                                            attributeName:
                                                              "transform",
                                                            repeatCount:
                                                              "indefinite",
                                                          },
                                                        })
                                                      : index % 3 == 0
                                                      ? _c("animateTransform", {
                                                          attrs: {
                                                            type: "translate",
                                                            fill: "freeze",
                                                            dur: "4s",
                                                            values:
                                                              "0 -" +
                                                              _vm.swiperHeight +
                                                              ";0 -" +
                                                              _vm.swiperHeight +
                                                              ";0 -" +
                                                              _vm.swiperHeight +
                                                              ";0 0;0 0;0 0;0 0;0 0;0 0",
                                                            restart: "never",
                                                            keySplines:
                                                              "0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1",
                                                            keyTimes:
                                                              "0;0.13;0.25;0.38;0.50;0.63;0.75;0.88;1.00",
                                                            calcMode: "spline",
                                                            attributeName:
                                                              "transform",
                                                            repeatCount:
                                                              "indefinite",
                                                          },
                                                        })
                                                      : index % 2 == 0
                                                      ? _c("animateTransform", {
                                                          attrs: {
                                                            type: "translate",
                                                            fill: "freeze",
                                                            dur: "4s",
                                                            values:
                                                              _vm.swiperWidth +
                                                              " 0;" +
                                                              _vm.swiperWidth +
                                                              " 0;" +
                                                              _vm.swiperWidth +
                                                              " 0;" +
                                                              _vm.swiperWidth +
                                                              " 0;" +
                                                              _vm.swiperWidth +
                                                              " 0;0 0;0 0;0 0;0 0",
                                                            restart: "never",
                                                            keySplines:
                                                              "0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1",
                                                            keyTimes:
                                                              "0;0.13;0.25;0.38;0.50;0.63;0.75;0.88;1.00",
                                                            calcMode: "spline",
                                                            attributeName:
                                                              "transform",
                                                            repeatCount:
                                                              "indefinite",
                                                          },
                                                        })
                                                      : _c("animateTransform", {
                                                          attrs: {
                                                            type: "translate",
                                                            fill: "freeze",
                                                            dur: "4s",
                                                            values:
                                                              "-" +
                                                              _vm.swiperWidth +
                                                              " 0;-" +
                                                              _vm.swiperWidth +
                                                              " 0;-" +
                                                              _vm.swiperWidth +
                                                              " 0;-" +
                                                              _vm.swiperWidth +
                                                              " 0;-" +
                                                              _vm.swiperWidth +
                                                              " 0;-" +
                                                              _vm.swiperWidth +
                                                              " 0;-" +
                                                              _vm.swiperWidth +
                                                              " 0;0 0;0 0",
                                                            restart: "never",
                                                            keySplines:
                                                              "0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1;0 0 1 1;0.60 0 0.10 1",
                                                            keyTimes:
                                                              "0;0.13;0.25;0.38;0.50;0.63;0.75;0.88;1.00",
                                                            calcMode: "spline",
                                                            attributeName:
                                                              "transform",
                                                            repeatCount:
                                                              "indefinite",
                                                          },
                                                        }),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    )
                                  : _vm.content.playmodel == 5
                                  ? _c(
                                      "section",
                                      {
                                        staticStyle: {
                                          display: "inline-block",
                                          width: "100%",
                                          "vertical-align": "top",
                                          overflow: "hidden",
                                          "align-self": "flex-start",
                                          "line-height": "0",
                                          "box-sizing": "border-box",
                                          "margin-top": "10px",
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticStyle: {
                                              display: "block",
                                              "box-sizing": "border-box",
                                            },
                                            attrs: {
                                              viewBox:
                                                "0 0 " +
                                                _vm.swiperWidth +
                                                " " +
                                                _vm.swiperHeight,
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c(
                                              "g",
                                              [
                                                _c(
                                                  "foreignObject",
                                                  {
                                                    attrs: {
                                                      width: _vm.swiperWidth,
                                                      height: _vm.swiperHeight,
                                                      x: "0",
                                                      y: "0",
                                                    },
                                                  },
                                                  [
                                                    _c("svg", {
                                                      staticStyle: {
                                                        width: "100%",
                                                        "background-color":
                                                          "transparent",
                                                        "background-position":
                                                          "0% 0%",
                                                        "background-repeat":
                                                          "no-repeat",
                                                        "background-size":
                                                          "100%",
                                                        "background-attachment":
                                                          "scroll",
                                                        "-webkit-tap-highlight-color":
                                                          "transparent",
                                                        "user-select": "none",
                                                        visibility: "visible",
                                                        "pointer-events":
                                                          "none",
                                                      },
                                                      style:
                                                        "background-image: url(" +
                                                        m.slider_image[0] +
                                                        "?x-oss-process=image/auto-orient,1/resize,m_pad,w_" +
                                                        _vm.swiperWidth +
                                                        ",h_" +
                                                        _vm.swiperHeight +
                                                        ",color_" +
                                                        _vm.rgbaTo16color(m) +
                                                        "/quality,q_100/format,webp)",
                                                      attrs: {
                                                        viewBox:
                                                          "0 0 " +
                                                          _vm.swiperWidth +
                                                          " " +
                                                          _vm.swiperHeight,
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            m.slider_image[0] &&
                                            m.slider_image[1]
                                              ? _c(
                                                  "g",
                                                  [
                                                    _c(
                                                      "foreignObject",
                                                      {
                                                        attrs: {
                                                          width:
                                                            _vm.swiperWidth,
                                                          height:
                                                            _vm.swiperHeight,
                                                          x: "0",
                                                          y: "0",
                                                        },
                                                      },
                                                      [
                                                        _c("svg", {
                                                          staticStyle: {
                                                            width: "100%",
                                                            "background-color":
                                                              "white",
                                                            "background-position":
                                                              "0% 0%",
                                                            "background-repeat":
                                                              "no-repeat",
                                                            "background-size":
                                                              "100%",
                                                            "background-image":
                                                              "''",
                                                            "background-attachment":
                                                              "scroll",
                                                            "-webkit-tap-highlight-color":
                                                              "transparent",
                                                            "user-select":
                                                              "none",
                                                            visibility:
                                                              "visible",
                                                            "pointer-events":
                                                              "none",
                                                          },
                                                          attrs: {
                                                            viewBox:
                                                              "0 0 " +
                                                              _vm.swiperWidth +
                                                              " " +
                                                              _vm.swiperHeight,
                                                            xmlns:
                                                              "http://www.w3.org/2000/svg",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "g",
                                                      {
                                                        staticStyle: {
                                                          visibility: "visible",
                                                          "pointer-events":
                                                            "none",
                                                        },
                                                        attrs: {
                                                          transform:
                                                            "translate(" +
                                                            _vm.swiperWidth /
                                                              2 +
                                                            " " +
                                                            _vm.swiperHeight /
                                                              2 +
                                                            ")",
                                                        },
                                                      },
                                                      [
                                                        _c("animateTransform", {
                                                          attrs: {
                                                            attributeName:
                                                              "transform",
                                                            type: "scale",
                                                            values:
                                                              "1 1;0 1;1 1;1 1",
                                                            dur: "6",
                                                            restart: "never",
                                                            keyTimes:
                                                              "0;0.0417;0.0833;1",
                                                            calcMode: "spline",
                                                            keySplines:
                                                              "0.1 0 1 0.5;0 0.1 0.5 1;0 0 0 0",
                                                            fill: "freeze",
                                                            additive: "sum",
                                                            begin: "5.5s",
                                                            repeatCount:
                                                              "indefinite",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "g",
                                                          {
                                                            staticStyle: {
                                                              visibility:
                                                                "visible",
                                                            },
                                                            attrs: {
                                                              transform:
                                                                "translate(-" +
                                                                _vm.swiperWidth /
                                                                  2 +
                                                                " -" +
                                                                _vm.swiperHeight /
                                                                  2 +
                                                                ")",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "foreignObject",
                                                              {
                                                                attrs: {
                                                                  width:
                                                                    _vm.swiperWidth,
                                                                  height:
                                                                    _vm.swiperHeight,
                                                                  x: "0",
                                                                  y: "0",
                                                                },
                                                              },
                                                              [
                                                                _c("svg", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                    "background-color":
                                                                      "transparent",
                                                                    "background-position":
                                                                      "0% 0%",
                                                                    "background-repeat":
                                                                      "no-repeat",
                                                                    "background-size":
                                                                      "100%",
                                                                    "background-attachment":
                                                                      "scroll",
                                                                    "-webkit-tap-highlight-color":
                                                                      "transparent",
                                                                    "user-select":
                                                                      "none",
                                                                    visibility:
                                                                      "visible",
                                                                    "pointer-events":
                                                                      "none",
                                                                  },
                                                                  style:
                                                                    "background-image: url(" +
                                                                    m
                                                                      .slider_image[0] +
                                                                    "?x-oss-process=image/auto-orient,1/resize,m_pad,w_" +
                                                                    _vm.swiperWidth +
                                                                    ",h_" +
                                                                    _vm.swiperHeight +
                                                                    ",color_" +
                                                                    _vm.rgbaTo16color(
                                                                      m
                                                                    ) +
                                                                    "/quality,q_100/format,webp);",
                                                                  attrs: {
                                                                    viewBox:
                                                                      "0 0 " +
                                                                      _vm.swiperWidth +
                                                                      " " +
                                                                      _vm.swiperHeight,
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "g",
                                                              [
                                                                _c(
                                                                  "foreignObject",
                                                                  {
                                                                    attrs: {
                                                                      width:
                                                                        _vm.swiperWidth,
                                                                      height:
                                                                        _vm.swiperHeight,
                                                                      x: "0",
                                                                      y: "0",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("svg", {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100%",
                                                                          "background-color":
                                                                            "transparent",
                                                                          "background-position":
                                                                            "0% 0%",
                                                                          "background-repeat":
                                                                            "no-repeat",
                                                                          "background-size":
                                                                            "100%",
                                                                          "background-attachment":
                                                                            "scroll",
                                                                          "-webkit-tap-highlight-color":
                                                                            "transparent",
                                                                          "user-select":
                                                                            "none",
                                                                          visibility:
                                                                            "visible",
                                                                          "pointer-events":
                                                                            "none",
                                                                        },
                                                                      style:
                                                                        "background-image: url(" +
                                                                        m
                                                                          .slider_image[1] +
                                                                        "?x-oss-process=image/auto-orient,1/resize,m_pad,w_" +
                                                                        _vm.swiperWidth +
                                                                        ",h_" +
                                                                        _vm.swiperHeight +
                                                                        ",color_" +
                                                                        _vm.rgbaTo16color(
                                                                          m
                                                                        ) +
                                                                        "/quality,q_100/format,webp);",
                                                                      attrs: {
                                                                        viewBox:
                                                                          "0 0 " +
                                                                          _vm.swiperWidth +
                                                                          " " +
                                                                          _vm.swiperHeight,
                                                                        xmlns:
                                                                          "http://www.w3.org/2000/svg",
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "animateTransform",
                                                                  {
                                                                    attrs: {
                                                                      attributeName:
                                                                        "transform",
                                                                      type: "translate",
                                                                      values:
                                                                        "0 20000;0 20000;0 0;0 0",
                                                                      fill: "freeze",
                                                                      begin:
                                                                        "5.75s",
                                                                      restart:
                                                                        "never",
                                                                      repeatCount:
                                                                        "indefinite",
                                                                      additive:
                                                                        "sum",
                                                                      keyTimes:
                                                                        "0;0.0417;0.0417;1",
                                                                      dur: "6",
                                                                    },
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c("animate", {
                                                                  attrs: {
                                                                    attributeName:
                                                                      "opacity",
                                                                    values:
                                                                      "0;0;1;1",
                                                                    fill: "freeze",
                                                                    begin:
                                                                      "5.75s",
                                                                    restart:
                                                                      "never",
                                                                    repeatCount:
                                                                      "indefinite",
                                                                    keyTimes:
                                                                      "0;0.0417;0.0417;1",
                                                                    dur: "6",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("animateTransform", {
                                                      attrs: {
                                                        attributeName:
                                                          "transform",
                                                        type: "translate",
                                                        values:
                                                          "0 20000;0 20000;0 0;0 0",
                                                        fill: "freeze",
                                                        begin: "6s",
                                                        restart: "never",
                                                        repeatCount:
                                                          "indefinite",
                                                        keyTimes: "0;0;0;1",
                                                        dur: "6",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("animate", {
                                                      attrs: {
                                                        attributeName:
                                                          "opacity",
                                                        values: "0;0;1;1",
                                                        fill: "freeze",
                                                        begin: "6s",
                                                        restart: "never",
                                                        repeatCount:
                                                          "indefinite",
                                                        keyTimes: "0;0;0;1",
                                                        dur: "6",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            m.slider_image[1] &&
                                            m.slider_image[2]
                                              ? _c(
                                                  "g",
                                                  [
                                                    _c(
                                                      "foreignObject",
                                                      {
                                                        attrs: {
                                                          width:
                                                            _vm.swiperWidth,
                                                          height:
                                                            _vm.swiperHeight,
                                                          x: "0",
                                                          y: "0",
                                                        },
                                                      },
                                                      [
                                                        _c("svg", {
                                                          staticStyle: {
                                                            width: "100%",
                                                            "background-color":
                                                              "white",
                                                            "background-position":
                                                              "0% 0%",
                                                            "background-repeat":
                                                              "no-repeat",
                                                            "background-size":
                                                              "100%",
                                                            "background-image":
                                                              "",
                                                            "background-attachment":
                                                              "scroll",
                                                            "-webkit-tap-highlight-color":
                                                              "transparent",
                                                            "user-select":
                                                              "none",
                                                            visibility:
                                                              "visible",
                                                            "pointer-events":
                                                              "none",
                                                          },
                                                          attrs: {
                                                            viewBox:
                                                              "0 0 " +
                                                              _vm.swiperWidth +
                                                              " " +
                                                              _vm.swiperHeight,
                                                            xmlns:
                                                              "http://www.w3.org/2000/svg",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "g",
                                                      {
                                                        staticStyle: {
                                                          visibility: "visible",
                                                          "pointer-events":
                                                            "none",
                                                        },
                                                        attrs: {
                                                          transform:
                                                            "translate(" +
                                                            _vm.swiperWidth /
                                                              2 +
                                                            " " +
                                                            _vm.swiperHeight /
                                                              2 +
                                                            ")",
                                                        },
                                                      },
                                                      [
                                                        _c("animateTransform", {
                                                          attrs: {
                                                            attributeName:
                                                              "transform",
                                                            type: "scale",
                                                            values:
                                                              "1 1;0 1;1 1;1 1",
                                                            dur: "6",
                                                            restart: "never",
                                                            keyTimes:
                                                              "0;0.0417;0.0833;1",
                                                            calcMode: "spline",
                                                            keySplines:
                                                              "0.1 0 1 0.5;0 0.1 0.5 1;0 0 0 0",
                                                            fill: "freeze",
                                                            additive: "sum",
                                                            begin: "4s",
                                                            repeatCount:
                                                              "indefinite",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "g",
                                                          {
                                                            staticStyle: {
                                                              visibility:
                                                                "visible",
                                                            },
                                                            attrs: {
                                                              transform:
                                                                "translate(-" +
                                                                _vm.swiperWidth /
                                                                  2 +
                                                                " -" +
                                                                _vm.swiperHeight /
                                                                  2 +
                                                                ")",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "foreignObject",
                                                              {
                                                                attrs: {
                                                                  width:
                                                                    _vm.swiperWidth,
                                                                  height:
                                                                    _vm.swiperHeight,
                                                                  x: "0",
                                                                  y: "0",
                                                                },
                                                              },
                                                              [
                                                                _c("svg", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                    "background-color":
                                                                      "transparent",
                                                                    "background-position":
                                                                      "0% 0%",
                                                                    "background-repeat":
                                                                      "no-repeat",
                                                                    "background-size":
                                                                      "100%",
                                                                    "background-attachment":
                                                                      "scroll",
                                                                    "-webkit-tap-highlight-color":
                                                                      "transparent",
                                                                    "user-select":
                                                                      "none",
                                                                    visibility:
                                                                      "visible",
                                                                    "pointer-events":
                                                                      "none",
                                                                  },
                                                                  style:
                                                                    "background-image: url(" +
                                                                    m
                                                                      .slider_image[1] +
                                                                    "?x-oss-process=image/auto-orient,1/resize,m_pad,w_" +
                                                                    _vm.swiperWidth +
                                                                    ",h_" +
                                                                    _vm.swiperHeight +
                                                                    ",color_" +
                                                                    _vm.rgbaTo16color(
                                                                      m
                                                                    ) +
                                                                    "/quality,q_100/format,webp)",
                                                                  attrs: {
                                                                    viewBox:
                                                                      "0 0 " +
                                                                      _vm.swiperWidth +
                                                                      " " +
                                                                      _vm.swiperHeight,
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "g",
                                                              [
                                                                _c(
                                                                  "foreignObject",
                                                                  {
                                                                    attrs: {
                                                                      width:
                                                                        _vm.swiperWidth,
                                                                      height:
                                                                        _vm.swiperHeight,
                                                                      x: "0",
                                                                      y: "0",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("svg", {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100%",
                                                                          "background-color":
                                                                            "transparent",
                                                                          "background-position":
                                                                            "0% 0%",
                                                                          "background-repeat":
                                                                            "no-repeat",
                                                                          "background-size":
                                                                            "100%",
                                                                          "background-attachment":
                                                                            "scroll",
                                                                          "-webkit-tap-highlight-color":
                                                                            "transparent",
                                                                          "user-select":
                                                                            "none",
                                                                          visibility:
                                                                            "visible",
                                                                          "pointer-events":
                                                                            "none",
                                                                        },
                                                                      style:
                                                                        "background-image: url(" +
                                                                        m
                                                                          .slider_image[2] +
                                                                        "?x-oss-process=image/auto-orient,1/resize,m_pad,w_" +
                                                                        _vm.swiperWidth +
                                                                        ",h_" +
                                                                        _vm.swiperHeight +
                                                                        ",color_" +
                                                                        _vm.rgbaTo16color(
                                                                          m
                                                                        ) +
                                                                        "/quality,q_100/format,webp)",
                                                                      attrs: {
                                                                        viewBox:
                                                                          "0 0 " +
                                                                          _vm.swiperWidth +
                                                                          " " +
                                                                          _vm.swiperHeight,
                                                                        xmlns:
                                                                          "http://www.w3.org/2000/svg",
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "animateTransform",
                                                                  {
                                                                    attrs: {
                                                                      attributeName:
                                                                        "transform",
                                                                      type: "translate",
                                                                      values:
                                                                        "0 20000;0 20000;0 0;0 0",
                                                                      fill: "freeze",
                                                                      begin:
                                                                        "4.25s",
                                                                      restart:
                                                                        "never",
                                                                      repeatCount:
                                                                        "indefinite",
                                                                      additive:
                                                                        "sum",
                                                                      keyTimes:
                                                                        "0;0.2917;0.2917;1",
                                                                      dur: "6",
                                                                    },
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c("animate", {
                                                                  attrs: {
                                                                    attributeName:
                                                                      "opacity",
                                                                    values:
                                                                      "0;0;1;1",
                                                                    fill: "freeze",
                                                                    begin:
                                                                      "4.25s",
                                                                    restart:
                                                                      "never",
                                                                    repeatCount:
                                                                      "indefinite",
                                                                    keyTimes:
                                                                      "0;0.2917;0.2917;1",
                                                                    dur: "6",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("animateTransform", {
                                                      attrs: {
                                                        attributeName:
                                                          "transform",
                                                        type: "translate",
                                                        values:
                                                          "0 20000;0 20000;0 0;0 0",
                                                        fill: "freeze",
                                                        begin: "4.5s",
                                                        restart: "never",
                                                        repeatCount:
                                                          "indefinite",
                                                        keyTimes:
                                                          "0;0.25;0.25;1",
                                                        dur: "6",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("animate", {
                                                      attrs: {
                                                        attributeName:
                                                          "opacity",
                                                        values: "0;0;1;1",
                                                        fill: "freeze",
                                                        begin: "4.5s",
                                                        restart: "never",
                                                        repeatCount:
                                                          "indefinite",
                                                        keyTimes:
                                                          "0;0.25;0.25;1",
                                                        dur: "6",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            m.slider_image[2] &&
                                            m.slider_image[3]
                                              ? _c(
                                                  "g",
                                                  [
                                                    _c(
                                                      "foreignObject",
                                                      {
                                                        attrs: {
                                                          width:
                                                            _vm.swiperWidth,
                                                          height:
                                                            _vm.swiperHeight,
                                                          x: "0",
                                                          y: "0",
                                                        },
                                                      },
                                                      [
                                                        _c("svg", {
                                                          staticStyle: {
                                                            width: "100%",
                                                            "background-color":
                                                              "white",
                                                            "background-position":
                                                              "0% 0%",
                                                            "background-repeat":
                                                              "no-repeat",
                                                            "background-size":
                                                              "100%",
                                                            "background-image":
                                                              "",
                                                            "background-attachment":
                                                              "scroll",
                                                            "-webkit-tap-highlight-color":
                                                              "transparent",
                                                            "user-select":
                                                              "none",
                                                            visibility:
                                                              "visible",
                                                            "pointer-events":
                                                              "none",
                                                          },
                                                          attrs: {
                                                            viewBox:
                                                              "0 0 " +
                                                              _vm.swiperWidth +
                                                              " " +
                                                              _vm.swiperHeight,
                                                            xmlns:
                                                              "http://www.w3.org/2000/svg",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "g",
                                                      {
                                                        staticStyle: {
                                                          visibility: "visible",
                                                          "pointer-events":
                                                            "none",
                                                        },
                                                        attrs: {
                                                          transform:
                                                            "translate(" +
                                                            _vm.swiperWidth /
                                                              2 +
                                                            " " +
                                                            _vm.swiperHeight /
                                                              2 +
                                                            ")",
                                                        },
                                                      },
                                                      [
                                                        _c("animateTransform", {
                                                          attrs: {
                                                            attributeName:
                                                              "transform",
                                                            type: "scale",
                                                            values:
                                                              "1 1;0 1;1 1;1 1",
                                                            dur: "6",
                                                            restart: "never",
                                                            keyTimes:
                                                              "0;0.0417;0.0833;1",
                                                            calcMode: "spline",
                                                            keySplines:
                                                              "0.1 0 1 0.5;0 0.1 0.5 1;0 0 0 0",
                                                            fill: "freeze",
                                                            additive: "sum",
                                                            begin: "2.5s",
                                                            repeatCount:
                                                              "indefinite",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "g",
                                                          {
                                                            staticStyle: {
                                                              visibility:
                                                                "visible",
                                                            },
                                                            attrs: {
                                                              transform:
                                                                "translate(-" +
                                                                _vm.swiperWidth /
                                                                  2 +
                                                                " -" +
                                                                _vm.swiperHeight /
                                                                  2 +
                                                                ")",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "foreignObject",
                                                              {
                                                                attrs: {
                                                                  width:
                                                                    _vm.swiperWidth,
                                                                  height:
                                                                    _vm.swiperHeight,
                                                                  x: "0",
                                                                  y: "0",
                                                                },
                                                              },
                                                              [
                                                                _c("svg", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                    "background-color":
                                                                      "transparent",
                                                                    "background-position":
                                                                      "0% 0%",
                                                                    "background-repeat":
                                                                      "no-repeat",
                                                                    "background-size":
                                                                      "100%",
                                                                    "background-image":
                                                                      "url(https://saas.cdn.yunzongbu.cn/merchant/23/2024725/image/1721893238584912700.jpg!m640)",
                                                                    "background-attachment":
                                                                      "scroll",
                                                                    "-webkit-tap-highlight-color":
                                                                      "transparent",
                                                                    "user-select":
                                                                      "none",
                                                                    visibility:
                                                                      "visible",
                                                                    "pointer-events":
                                                                      "none",
                                                                  },
                                                                  style:
                                                                    m
                                                                      .slider_image[2] +
                                                                    "?x-oss-process=image/auto-orient,1/resize,m_pad,w_" +
                                                                    _vm.swiperWidth +
                                                                    ",h_" +
                                                                    _vm.swiperHeight +
                                                                    ",color_" +
                                                                    _vm.rgbaTo16color(
                                                                      m
                                                                    ) +
                                                                    "/quality,q_100/format,webp",
                                                                  attrs: {
                                                                    viewBox:
                                                                      "0 0 " +
                                                                      _vm.swiperWidth +
                                                                      " " +
                                                                      _vm.swiperHeight,
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "g",
                                                              [
                                                                _c(
                                                                  "foreignObject",
                                                                  {
                                                                    attrs: {
                                                                      width:
                                                                        _vm.swiperWidth,
                                                                      height:
                                                                        _vm.swiperHeight,
                                                                      x: "0",
                                                                      y: "0",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("svg", {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100%",
                                                                          "background-color":
                                                                            "transparent",
                                                                          "background-position":
                                                                            "0% 0%",
                                                                          "background-repeat":
                                                                            "no-repeat",
                                                                          "background-size":
                                                                            "100%",
                                                                          "background-attachment":
                                                                            "scroll",
                                                                          "-webkit-tap-highlight-color":
                                                                            "transparent",
                                                                          "user-select":
                                                                            "none",
                                                                          visibility:
                                                                            "visible",
                                                                          "pointer-events":
                                                                            "none",
                                                                        },
                                                                      style:
                                                                        m
                                                                          .slider_image[3] +
                                                                        "?x-oss-process=image/auto-orient,1/resize,m_pad,w_" +
                                                                        _vm.swiperWidth +
                                                                        ",h_" +
                                                                        _vm.swiperHeight +
                                                                        ",color_" +
                                                                        _vm.rgbaTo16color(
                                                                          m
                                                                        ) +
                                                                        "/quality,q_100/format,webp",
                                                                      attrs: {
                                                                        viewBox:
                                                                          "0 0 " +
                                                                          _vm.swiperWidth +
                                                                          " " +
                                                                          _vm.swiperHeight,
                                                                        xmlns:
                                                                          "http://www.w3.org/2000/svg",
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "animateTransform",
                                                                  {
                                                                    attrs: {
                                                                      attributeName:
                                                                        "transform",
                                                                      type: "translate",
                                                                      values:
                                                                        "0 20000;0 20000;0 0;0 0",
                                                                      fill: "freeze",
                                                                      begin:
                                                                        "2.75s",
                                                                      restart:
                                                                        "never",
                                                                      repeatCount:
                                                                        "indefinite",
                                                                      additive:
                                                                        "sum",
                                                                      keyTimes:
                                                                        "0;0.5417;0.5417;1",
                                                                      dur: "6",
                                                                    },
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c("animate", {
                                                                  attrs: {
                                                                    attributeName:
                                                                      "opacity",
                                                                    values:
                                                                      "0;0;1;1",
                                                                    fill: "freeze",
                                                                    begin:
                                                                      "2.75s",
                                                                    restart:
                                                                      "never",
                                                                    repeatCount:
                                                                      "indefinite",
                                                                    keyTimes:
                                                                      "0;0.5417;0.5417;1",
                                                                    dur: "6",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("animateTransform", {
                                                      attrs: {
                                                        attributeName:
                                                          "transform",
                                                        type: "translate",
                                                        values:
                                                          "0 20000;0 20000;0 0;0 0",
                                                        fill: "freeze",
                                                        begin: "3s",
                                                        restart: "never",
                                                        repeatCount:
                                                          "indefinite",
                                                        keyTimes: "0;0.5;0.5;1",
                                                        dur: "6",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("animate", {
                                                      attrs: {
                                                        attributeName:
                                                          "opacity",
                                                        values: "0;0;1;1",
                                                        fill: "freeze",
                                                        begin: "3s",
                                                        restart: "never",
                                                        repeatCount:
                                                          "indefinite",
                                                        keyTimes: "0;0.5;0.5;1",
                                                        dur: "6",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            m.slider_image[3] &&
                                            m.slider_image[4]
                                              ? _c(
                                                  "g",
                                                  [
                                                    _c(
                                                      "foreignObject",
                                                      {
                                                        attrs: {
                                                          width:
                                                            _vm.swiperWidth,
                                                          height:
                                                            _vm.swiperHeight,
                                                          x: "0",
                                                          y: "0",
                                                        },
                                                      },
                                                      [
                                                        _c("svg", {
                                                          staticStyle: {
                                                            width: "100%",
                                                            "background-color":
                                                              "white",
                                                            "background-position":
                                                              "0% 0%",
                                                            "background-repeat":
                                                              "no-repeat",
                                                            "background-size":
                                                              "100%",
                                                            "background-image":
                                                              "",
                                                            "background-attachment":
                                                              "scroll",
                                                            "-webkit-tap-highlight-color":
                                                              "transparent",
                                                            "user-select":
                                                              "none",
                                                            visibility:
                                                              "visible",
                                                            "pointer-events":
                                                              "none",
                                                          },
                                                          attrs: {
                                                            viewBox:
                                                              "0 0 " +
                                                              _vm.swiperWidth +
                                                              " " +
                                                              _vm.swiperHeight,
                                                            xmlns:
                                                              "http://www.w3.org/2000/svg",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "g",
                                                      {
                                                        staticStyle: {
                                                          visibility: "visible",
                                                          "pointer-events":
                                                            "none",
                                                        },
                                                        attrs: {
                                                          transform:
                                                            "translate(" +
                                                            _vm.swiperWidth /
                                                              2 +
                                                            " " +
                                                            _vm.swiperHeight /
                                                              2 +
                                                            ")",
                                                        },
                                                      },
                                                      [
                                                        _c("animateTransform", {
                                                          attrs: {
                                                            attributeName:
                                                              "transform",
                                                            type: "scale",
                                                            values:
                                                              "1 1;0 1;1 1;1 1",
                                                            dur: "6",
                                                            restart: "never",
                                                            keyTimes:
                                                              "0;0.0417;0.0833;1",
                                                            calcMode: "spline",
                                                            keySplines:
                                                              "0.1 0 1 0.5;0 0.1 0.5 1;0 0 0 0",
                                                            fill: "freeze",
                                                            additive: "sum",
                                                            begin: "1s",
                                                            repeatCount:
                                                              "indefinite",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "g",
                                                          {
                                                            staticStyle: {
                                                              visibility:
                                                                "visible",
                                                            },
                                                            attrs: {
                                                              transform:
                                                                "translate(-" +
                                                                _vm.swiperWidth /
                                                                  2 +
                                                                " -" +
                                                                _vm.swiperHeight /
                                                                  2 +
                                                                ")",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "foreignObject",
                                                              {
                                                                attrs: {
                                                                  width:
                                                                    _vm.swiperWidth,
                                                                  height:
                                                                    _vm.swiperHeight,
                                                                  x: "0",
                                                                  y: "0",
                                                                },
                                                              },
                                                              [
                                                                _c("svg", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                    "background-color":
                                                                      "transparent",
                                                                    "background-position":
                                                                      "0% 0%",
                                                                    "background-repeat":
                                                                      "no-repeat",
                                                                    "background-size":
                                                                      "100%",
                                                                    "background-attachment":
                                                                      "scroll",
                                                                    "-webkit-tap-highlight-color":
                                                                      "transparent",
                                                                    "user-select":
                                                                      "none",
                                                                    visibility:
                                                                      "visible",
                                                                    "pointer-events":
                                                                      "none",
                                                                  },
                                                                  style:
                                                                    "background-image: url(" +
                                                                    m
                                                                      .slider_image[3] +
                                                                    "?x-oss-process=image/auto-orient,1/resize,m_pad,w_" +
                                                                    _vm.swiperWidth +
                                                                    ",h_" +
                                                                    _vm.swiperHeight +
                                                                    ",color_" +
                                                                    _vm.rgbaTo16color(
                                                                      m
                                                                    ) +
                                                                    "/quality,q_100/format,webp);",
                                                                  attrs: {
                                                                    viewBox:
                                                                      "0 0 " +
                                                                      _vm.swiperWidth +
                                                                      " " +
                                                                      _vm.swiperHeight,
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "g",
                                                              [
                                                                _c(
                                                                  "foreignObject",
                                                                  {
                                                                    attrs: {
                                                                      width:
                                                                        _vm.swiperWidth,
                                                                      height:
                                                                        _vm.swiperHeight,
                                                                      x: "0",
                                                                      y: "0",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("svg", {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100%",
                                                                          "background-color":
                                                                            "transparent",
                                                                          "background-position":
                                                                            "0% 0%",
                                                                          "background-repeat":
                                                                            "no-repeat",
                                                                          "background-size":
                                                                            "100%",
                                                                          "background-attachment":
                                                                            "scroll",
                                                                          "-webkit-tap-highlight-color":
                                                                            "transparent",
                                                                          "user-select":
                                                                            "none",
                                                                          visibility:
                                                                            "visible",
                                                                          "pointer-events":
                                                                            "none",
                                                                        },
                                                                      style:
                                                                        "background-image: url(" +
                                                                        m
                                                                          .slider_image[4] +
                                                                        "?x-oss-process=image/auto-orient,1/resize,m_pad,w_" +
                                                                        _vm.swiperWidth +
                                                                        ",h_" +
                                                                        _vm.swiperHeight +
                                                                        ",color_" +
                                                                        _vm.rgbaTo16color(
                                                                          m
                                                                        ) +
                                                                        "/quality,q_100/format,webp);",
                                                                      attrs: {
                                                                        viewBox:
                                                                          "0 0 " +
                                                                          _vm.swiperWidth +
                                                                          " " +
                                                                          _vm.swiperHeight,
                                                                        xmlns:
                                                                          "http://www.w3.org/2000/svg",
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "animateTransform",
                                                                  {
                                                                    attrs: {
                                                                      attributeName:
                                                                        "transform",
                                                                      type: "translate",
                                                                      values:
                                                                        "0 20000;0 20000;0 0;0 0",
                                                                      fill: "freeze",
                                                                      begin:
                                                                        "1.25s",
                                                                      restart:
                                                                        "never",
                                                                      repeatCount:
                                                                        "indefinite",
                                                                      additive:
                                                                        "sum",
                                                                      keyTimes:
                                                                        "0;0.7917;0.7917;1",
                                                                      dur: "6",
                                                                    },
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c("animate", {
                                                                  attrs: {
                                                                    attributeName:
                                                                      "opacity",
                                                                    values:
                                                                      "0;0;1;1",
                                                                    fill: "freeze",
                                                                    begin:
                                                                      "1.25s",
                                                                    restart:
                                                                      "never",
                                                                    repeatCount:
                                                                      "indefinite",
                                                                    keyTimes:
                                                                      "0;0.7917;0.7917;1",
                                                                    dur: "6",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("animateTransform", {
                                                      attrs: {
                                                        attributeName:
                                                          "transform",
                                                        type: "translate",
                                                        values:
                                                          "0 20000;0 20000;0 0;0 0",
                                                        fill: "freeze",
                                                        begin: "1.5s",
                                                        restart: "never",
                                                        repeatCount:
                                                          "indefinite",
                                                        keyTimes:
                                                          "0;0.75;0.75;1",
                                                        dur: "6",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("animate", {
                                                      attrs: {
                                                        attributeName:
                                                          "opacity",
                                                        values: "0;0;1;1",
                                                        fill: "freeze",
                                                        begin: "1.5s",
                                                        restart: "never",
                                                        repeatCount:
                                                          "indefinite",
                                                        keyTimes:
                                                          "0;0.75;0.75;1",
                                                        dur: "6",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm.content.playmodel == 6
                                  ? _c(
                                      "section",
                                      {
                                        staticStyle: {
                                          display: "inline-block",
                                          width: "100%",
                                          "vertical-align": "top",
                                          overflow: "hidden",
                                          "align-self": "flex-start",
                                          "line-height": "0",
                                          "box-sizing": "border-box",
                                          "margin-top": "10px",
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticStyle: {
                                              width: "100%",
                                              "pointer-events": "none",
                                              display: "block",
                                            },
                                            attrs: {
                                              viewBox:
                                                "0 0 " +
                                                _vm.swiperWidth +
                                                " " +
                                                _vm.swiperHeight,
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              x: "0",
                                              y: "0",
                                            },
                                          },
                                          [
                                            _c(
                                              "g",
                                              [
                                                _c("foreignObject", {
                                                  attrs: {
                                                    width: "100%",
                                                    height: "100%",
                                                    x: "0",
                                                    y: "0",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "g",
                                              [
                                                _c("animateTransform", {
                                                  attrs: {
                                                    attributeName: "transform",
                                                    type: "translate",
                                                    keyTimes:
                                                      "0;0.10;0.20;0.30;0.40;0.50;0.60;0.70;0.80;0.90;1.00",
                                                    values:
                                                      "0 0;0 0;-" +
                                                      _vm.swiperWidth +
                                                      " 0;-" +
                                                      _vm.swiperWidth +
                                                      " 0;-" +
                                                      _vm.swiperWidth * 2 +
                                                      " 0;-" +
                                                      _vm.swiperWidth * 2 +
                                                      " 0;-" +
                                                      _vm.swiperWidth * 3 +
                                                      " 0;-" +
                                                      _vm.swiperWidth * 3 +
                                                      " 0;-" +
                                                      _vm.swiperWidth * 4 +
                                                      " 0;-" +
                                                      _vm.swiperWidth * 4 +
                                                      " 0;-" +
                                                      _vm.swiperWidth * 5 +
                                                      " 0",
                                                    repeatCount: "indefinite",
                                                    fill: "freeze",
                                                    begin: "0s",
                                                    dur: "5s",
                                                    calcMode: "linear",
                                                    restart: "never",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "g",
                                                  [
                                                    _c("rect", {
                                                      staticStyle: {
                                                        "pointer-events":
                                                          "painted",
                                                        "box-sizing":
                                                          "border-box",
                                                      },
                                                      attrs: {
                                                        width: "100%",
                                                        height: "100%",
                                                        opacity: "0",
                                                        fill: "#ffffff",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _vm._l(
                                                      m.slider_image,
                                                      function (im, index) {
                                                        return _c(
                                                          "g",
                                                          { key: index },
                                                          [
                                                            _c(
                                                              "foreignObject",
                                                              {
                                                                attrs: {
                                                                  width: "100%",
                                                                  height:
                                                                    "100%",
                                                                  x:
                                                                    index *
                                                                    _vm.swiperWidth,
                                                                  y: "0",
                                                                },
                                                              },
                                                              [
                                                                _c("svg", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                    "background-color":
                                                                      "transparent",
                                                                    "background-position":
                                                                      "0% 0%",
                                                                    "background-repeat":
                                                                      "no-repeat",
                                                                    "background-size":
                                                                      "cover",
                                                                    "background-attachment":
                                                                      "scroll",
                                                                    "-webkit-tap-highlight-color":
                                                                      "transparent",
                                                                    "user-select":
                                                                      "none",
                                                                    visibility:
                                                                      "visible",
                                                                    "pointer-events":
                                                                      "none",
                                                                  },
                                                                  style:
                                                                    "background-image: url(" +
                                                                    im +
                                                                    "?x-oss-process=image/auto-orient,1/resize,m_pad,w_" +
                                                                    _vm.swiperWidth +
                                                                    ",h_" +
                                                                    _vm.swiperHeight +
                                                                    ",color_" +
                                                                    _vm.rgbaTo16color(
                                                                      m
                                                                    ) +
                                                                    "/quality,q_100/format,webp)",
                                                                  attrs: {
                                                                    viewBox:
                                                                      "0 0 " +
                                                                      _vm.swiperWidth +
                                                                      " " +
                                                                      _vm.swiperHeight,
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm.content.playmodel == 7
                                  ? _c(
                                      "section",
                                      {
                                        staticStyle: {
                                          display: "inline-block",
                                          width: "100%",
                                          "vertical-align": "top",
                                          overflow: "hidden",
                                          "align-self": "flex-start",
                                          "line-height": "0",
                                          "box-sizing": "border-box",
                                          "margin-top": "10px",
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticStyle: {
                                              width: "100%",
                                              "pointer-events": "none",
                                              display: "block",
                                            },
                                            attrs: {
                                              viewBox: "0 0 1080 720",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              x: "0",
                                              y: "0",
                                            },
                                          },
                                          [
                                            _c(
                                              "g",
                                              [
                                                _c("animateTransform", {
                                                  attrs: {
                                                    attributeName: "transform",
                                                    type: "translate",
                                                    keyTimes: "0;1",
                                                    values:
                                                      "0 0;0 -" +
                                                      (m.slider_image.length -
                                                        1) *
                                                        720,
                                                    repeatCount: "indefinite",
                                                    fill: "freeze",
                                                    begin: "0s",
                                                    dur:
                                                      m.slider_image.length *
                                                        4 +
                                                      "s",
                                                    calcMode: "linear",
                                                    restart: "never",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "g",
                                                  [
                                                    _c("rect", {
                                                      staticStyle: {
                                                        "pointer-events":
                                                          "painted",
                                                        "box-sizing":
                                                          "border-box",
                                                      },
                                                      attrs: {
                                                        width: "100%",
                                                        height: "100%",
                                                        opacity: "0",
                                                        fill: "#ffffff",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _vm._l(
                                                      m.slider_image,
                                                      function (im, index) {
                                                        return _c(
                                                          "g",
                                                          { key: index },
                                                          [
                                                            _c(
                                                              "foreignObject",
                                                              {
                                                                attrs: {
                                                                  width: "100%",
                                                                  height:
                                                                    "100%",
                                                                  x: "0",
                                                                  y:
                                                                    720 * index,
                                                                },
                                                              },
                                                              [
                                                                _c("svg", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                    "background-color":
                                                                      "transparent",
                                                                    "background-position":
                                                                      "0% 0%",
                                                                    "background-repeat":
                                                                      "no-repeat",
                                                                    "background-size":
                                                                      "cover",
                                                                    "background-attachment":
                                                                      "scroll",
                                                                    "-webkit-tap-highlight-color":
                                                                      "transparent",
                                                                    "user-select":
                                                                      "none",
                                                                    visibility:
                                                                      "visible",
                                                                    "pointer-events":
                                                                      "none",
                                                                  },
                                                                  style:
                                                                    "background-image: url(" +
                                                                    im +
                                                                    "!m640);",
                                                                  attrs: {
                                                                    viewBox:
                                                                      "0 0 1080 720",
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm.content.playmodel == 8
                                  ? _c(
                                      "section",
                                      {
                                        staticStyle: {
                                          display: "inline-block",
                                          width: "100%",
                                          "vertical-align": "top",
                                          overflow: "hidden",
                                          "align-self": "flex-start",
                                          "line-height": "0",
                                          "box-sizing": "border-box",
                                          "margin-top": "10px",
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticStyle: { display: "block" },
                                            attrs: {
                                              viewBox: "0 0 1080 1080",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c(
                                              "g",
                                              [
                                                _c("foreignObject", {
                                                  attrs: {
                                                    width: "100%",
                                                    height: "100%",
                                                    x: "0",
                                                    y: "0",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            m.slider_image[0]
                                              ? _c(
                                                  "g",
                                                  [
                                                    _c(
                                                      "foreignObject",
                                                      {
                                                        attrs: {
                                                          width: "100%",
                                                          height: "100%",
                                                          x: "0",
                                                          y: "0",
                                                        },
                                                      },
                                                      [
                                                        _c("svg", {
                                                          staticStyle: {
                                                            width: "100%",
                                                            "background-color":
                                                              "transparent",
                                                            "background-position":
                                                              "0% 0%",
                                                            "background-repeat":
                                                              "no-repeat",
                                                            "background-size":
                                                              "100%",
                                                            "background-attachment":
                                                              "scroll",
                                                            "-webkit-tap-highlight-color":
                                                              "transparent",
                                                            "user-select":
                                                              "none",
                                                            visibility:
                                                              "visible",
                                                            "pointer-events":
                                                              "none",
                                                          },
                                                          style:
                                                            "background-image: url(" +
                                                            m.slider_image[0] +
                                                            "!m640);",
                                                          attrs: {
                                                            viewBox:
                                                              "0 0 1080 1080",
                                                            xmlns:
                                                              "http://www.w3.org/2000/svg",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("animateTransform", {
                                                      attrs: {
                                                        type: "translate",
                                                        fill: "freeze",
                                                        dur: "10s",
                                                        values:
                                                          "0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;-108 0;1080 0",
                                                        restart: "never",
                                                        keySplines:
                                                          "0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0",
                                                        keyTimes:
                                                          "0;0.20;0.23;0.25;0.45;0.47;0.50;0.70;0.72;0.75;0.95;0.97;1.00",
                                                        calcMode: "spline",
                                                        attributeName:
                                                          "transform",
                                                        repeatCount:
                                                          "indefinite",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            m.slider_image[1]
                                              ? _c(
                                                  "g",
                                                  [
                                                    _c(
                                                      "foreignObject",
                                                      {
                                                        attrs: {
                                                          width: "100%",
                                                          height: "100%",
                                                          x: "0",
                                                          y: "0",
                                                        },
                                                      },
                                                      [
                                                        _c("svg", {
                                                          staticStyle: {
                                                            width: "100%",
                                                            "background-color":
                                                              "transparent",
                                                            "background-position":
                                                              "0% 0%",
                                                            "background-repeat":
                                                              "no-repeat",
                                                            "background-size":
                                                              "100%",
                                                            "background-attachment":
                                                              "scroll",
                                                            "-webkit-tap-highlight-color":
                                                              "transparent",
                                                            "user-select":
                                                              "none",
                                                            visibility:
                                                              "visible",
                                                            "pointer-events":
                                                              "none",
                                                          },
                                                          style:
                                                            m.slider_image[1] +
                                                            "!m640",
                                                          attrs: {
                                                            viewBox:
                                                              "0 0 1080 1080",
                                                            xmlns:
                                                              "http://www.w3.org/2000/svg",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("animateTransform", {
                                                      attrs: {
                                                        type: "translate",
                                                        fill: "freeze",
                                                        dur: "10s",
                                                        values:
                                                          "0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;108 0;-1080 0;-1080 0;-1080 0;-1080 0",
                                                        restart: "never",
                                                        keySplines:
                                                          "0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0",
                                                        keyTimes:
                                                          "0;0.20;0.23;0.25;0.45;0.47;0.50;0.70;0.72;0.75;0.95;0.97;1.00",
                                                        calcMode: "spline",
                                                        attributeName:
                                                          "transform",
                                                        repeatCount:
                                                          "indefinite",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            m.slider_image[2]
                                              ? _c(
                                                  "g",
                                                  [
                                                    _c(
                                                      "foreignObject",
                                                      {
                                                        attrs: {
                                                          width: "100%",
                                                          height: "100%",
                                                          x: "0",
                                                          y: "0",
                                                        },
                                                      },
                                                      [
                                                        _c("svg", {
                                                          staticStyle: {
                                                            width: "100%",
                                                            "background-color":
                                                              "transparent",
                                                            "background-position":
                                                              "0% 0%",
                                                            "background-repeat":
                                                              "no-repeat",
                                                            "background-size":
                                                              "100%",
                                                            "background-attachment":
                                                              "scroll",
                                                            "-webkit-tap-highlight-color":
                                                              "transparent",
                                                            "user-select":
                                                              "none",
                                                            visibility:
                                                              "visible",
                                                            "pointer-events":
                                                              "none",
                                                          },
                                                          style:
                                                            "background-image: url(" +
                                                            m.slider_image[2] +
                                                            "!m640);",
                                                          attrs: {
                                                            viewBox:
                                                              "0 0 1080 1080",
                                                            xmlns:
                                                              "http://www.w3.org/2000/svg",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("animateTransform", {
                                                      attrs: {
                                                        type: "translate",
                                                        fill: "freeze",
                                                        dur: "10s",
                                                        values:
                                                          "0 0;0 0;0 0;0 0;0 0;-108 0;1080 0;1080 0;1080 0;1080 0;1080 0;1080 0;1080 0",
                                                        restart: "never",
                                                        keySplines:
                                                          "0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0",
                                                        keyTimes:
                                                          "0;0.20;0.23;0.25;0.45;0.47;0.50;0.70;0.72;0.75;0.95;0.97;1.00",
                                                        calcMode: "spline",
                                                        attributeName:
                                                          "transform",
                                                        repeatCount:
                                                          "indefinite",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            m.slider_image[3]
                                              ? _c(
                                                  "g",
                                                  [
                                                    _c(
                                                      "foreignObject",
                                                      {
                                                        attrs: {
                                                          width: "100%",
                                                          height: "100%",
                                                          x: "0",
                                                          y: "0",
                                                        },
                                                      },
                                                      [
                                                        _c("svg", {
                                                          staticStyle: {
                                                            width: "100%",
                                                            "background-color":
                                                              "transparent",
                                                            "background-position":
                                                              "0% 0%",
                                                            "background-repeat":
                                                              "no-repeat",
                                                            "background-size":
                                                              "100%",
                                                            "background-attachment":
                                                              "scroll",
                                                            "-webkit-tap-highlight-color":
                                                              "transparent",
                                                            "user-select":
                                                              "none",
                                                            visibility:
                                                              "visible",
                                                            "pointer-events":
                                                              "none",
                                                          },
                                                          style:
                                                            "background-image: url(" +
                                                            m.slider_image[3] +
                                                            "!m640);",
                                                          attrs: {
                                                            viewBox:
                                                              "0 0 1080 1080",
                                                            xmlns:
                                                              "http://www.w3.org/2000/svg",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("animateTransform", {
                                                      attrs: {
                                                        type: "translate",
                                                        fill: "freeze",
                                                        dur: "10s",
                                                        values:
                                                          "0 0;0 0;108 0;-1080 0;-1080 0;-1080 0;-1080 0;-1080 0;-1080 0;-1080 0;-1080 0;-1080 0;-1080 0",
                                                        restart: "never",
                                                        keySplines:
                                                          "0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0;0.42 0 0.58 1.0",
                                                        keyTimes:
                                                          "0;0.20;0.23;0.25;0.45;0.47;0.50;0.70;0.72;0.75;0.95;0.97;1.00",
                                                        calcMode: "spline",
                                                        attributeName:
                                                          "transform",
                                                        repeatCount:
                                                          "indefinite",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.facade.showLongImage
                            ? [
                                _vm.facade.longMode == 2
                                  ? [
                                      _vm._l(m.rowArr, function (im, index) {
                                        return _c(
                                          "section",
                                          {
                                            key: index,
                                            staticStyle: {
                                              display: "flex",
                                              width: "100%",
                                              "flex-flow": "column",
                                              "box-sizing": "border-box",
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                display: "block",
                                                width: "100%",
                                              },
                                              attrs: {
                                                src:
                                                  im.url +
                                                  "?x-oss-process=image/rotate,90",
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        )
                                      }),
                                      _vm._v(" "),
                                      _vm._l(m.columnArr, function (im, index) {
                                        return _c(
                                          "section",
                                          {
                                            key: index,
                                            staticStyle: {
                                              display: "flex",
                                              width: "100%",
                                              "flex-flow": "column",
                                              "box-sizing": "border-box",
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                display: "block",
                                                width: "100%",
                                              },
                                              attrs: {
                                                src: "" + im.url,
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        )
                                      }),
                                    ]
                                  : _vm.facade.longMode == 1 &&
                                    ((_vm.facade.style == 4 &&
                                      _vm.checkShowMainLongImg(m)) ||
                                      _vm.facade.style != 4)
                                  ? [
                                      _vm._l(m.rowArr, function (im, index) {
                                        return [
                                          index == 0
                                            ? [
                                                _c(
                                                  "section",
                                                  {
                                                    key: "row" + index,
                                                    staticStyle: {
                                                      display: "flex",
                                                      width: "100%",
                                                      "flex-flow": "column",
                                                      "box-sizing":
                                                        "border-box",
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        display: "block",
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        src:
                                                          im.url +
                                                          "?x-oss-process=image/auto-orient,1/resize,m_lfit,w_2000/format,webp",
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "section",
                                                  {
                                                    key: "row2" + index,
                                                    staticStyle: {
                                                      display: "flex",
                                                      width: "100%",
                                                      "flex-flow": "column",
                                                      "box-sizing":
                                                        "border-box",
                                                      "margin-top": "3px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "section",
                                                      {
                                                        staticStyle: {
                                                          "z-index": "2",
                                                          "box-sizing":
                                                            "border-box",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "section",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                              width: "100%",
                                                              "flex-flow":
                                                                "column",
                                                              "box-sizing":
                                                                "border-box",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "section",
                                                              {
                                                                staticStyle: {
                                                                  "z-index":
                                                                    "1",
                                                                  "box-sizing":
                                                                    "border-box",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "section",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "flex",
                                                                        width:
                                                                          "100%",
                                                                        "flex-flow":
                                                                          "column",
                                                                        "box-sizing":
                                                                          "border-box",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "section",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "z-index":
                                                                              "1",
                                                                            "box-sizing":
                                                                              "border-box",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "section",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                margin:
                                                                                  "10px 0px",
                                                                                "box-sizing":
                                                                                  "border-box",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "section",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    display:
                                                                                      "flex",
                                                                                    width:
                                                                                      "100%",
                                                                                    "flex-flow":
                                                                                      "column",
                                                                                    "box-sizing":
                                                                                      "border-box",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "section",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        display:
                                                                                          "inline-block",
                                                                                        width:
                                                                                          "100%",
                                                                                        "vertical-align":
                                                                                          "top",
                                                                                        overflow:
                                                                                          "hidden",
                                                                                        "align-self":
                                                                                          "flex-start",
                                                                                        "line-height":
                                                                                          "0",
                                                                                        "box-sizing":
                                                                                          "border-box",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "svg",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            width:
                                                                                              "100%",
                                                                                            "pointer-events":
                                                                                              "none",
                                                                                            display:
                                                                                              "block",
                                                                                            "box-sizing":
                                                                                              "border-box",
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            viewBox:
                                                                                              "0 0 1080 " +
                                                                                              720,
                                                                                            xlink:
                                                                                              "http://www.w3.org/1999/xlink",
                                                                                            xmlns:
                                                                                              "http://www.w3.org/2000/svg",
                                                                                            x: "0",
                                                                                            y: "0",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                 \n                                "
                                                                                        ),
                                                                                        _c(
                                                                                          "g",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                "box-sizing":
                                                                                                  "border-box",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "animateTransform",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    "box-sizing":
                                                                                                      "border-box",
                                                                                                  },
                                                                                                attrs:
                                                                                                  {
                                                                                                    attributeName:
                                                                                                      "transform",
                                                                                                    type: "translate",
                                                                                                    keyTimes:
                                                                                                      "0;0.5;1",
                                                                                                    values:
                                                                                                      "0 0;-" +
                                                                                                      ((im.width /
                                                                                                        im.height) *
                                                                                                        720 -
                                                                                                        1080) +
                                                                                                      " 0;0 0",
                                                                                                    repeatCount:
                                                                                                      "indefinite",
                                                                                                    fill: "freeze",
                                                                                                    begin:
                                                                                                      "0s",
                                                                                                    dur:
                                                                                                      im.width /
                                                                                                        200 +
                                                                                                      "s",
                                                                                                    calcMode:
                                                                                                      "linear",
                                                                                                    restart:
                                                                                                      "never",
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "g",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    "box-sizing":
                                                                                                      "border-box",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "rect",
                                                                                                  {
                                                                                                    staticStyle:
                                                                                                      {
                                                                                                        "pointer-events":
                                                                                                          "painted",
                                                                                                        "box-sizing":
                                                                                                          "border-box",
                                                                                                      },
                                                                                                    attrs:
                                                                                                      {
                                                                                                        width:
                                                                                                          "100%",
                                                                                                        height:
                                                                                                          "100%",
                                                                                                        opacity:
                                                                                                          "0",
                                                                                                        fill: "#ffffff",
                                                                                                      },
                                                                                                  }
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  " "
                                                                                                ),
                                                                                                _c(
                                                                                                  "g",
                                                                                                  {
                                                                                                    staticStyle:
                                                                                                      {
                                                                                                        "box-sizing":
                                                                                                          "border-box",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "foreignObject",
                                                                                                      {
                                                                                                        staticStyle:
                                                                                                          {
                                                                                                            "box-sizing":
                                                                                                              "border-box",
                                                                                                          },
                                                                                                        attrs:
                                                                                                          {
                                                                                                            width:
                                                                                                              (im.width /
                                                                                                                im.height) *
                                                                                                              720,
                                                                                                            height: 720,
                                                                                                            x: "0",
                                                                                                            y: "0",
                                                                                                          },
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "svg",
                                                                                                          {
                                                                                                            staticStyle:
                                                                                                              {
                                                                                                                width:
                                                                                                                  "100%",
                                                                                                                "background-position":
                                                                                                                  "0% 0%",
                                                                                                                "background-repeat":
                                                                                                                  "no-repeat",
                                                                                                                "background-size":
                                                                                                                  "cover",
                                                                                                                "background-attachment":
                                                                                                                  "scroll",
                                                                                                                "-webkit-tap-highlight-color":
                                                                                                                  "transparent",
                                                                                                                visibility:
                                                                                                                  "visible",
                                                                                                                "pointer-events":
                                                                                                                  "none",
                                                                                                                "box-sizing":
                                                                                                                  "border-box",
                                                                                                                "user-select":
                                                                                                                  "none",
                                                                                                              },
                                                                                                            style:
                                                                                                              "background-image: url('" +
                                                                                                              im.url +
                                                                                                              "?x-oss-process=image/auto-orient,1/resize,m_lfit,w_2000/format,webp');",
                                                                                                            attrs:
                                                                                                              {
                                                                                                                space:
                                                                                                                  "default",
                                                                                                                viewBox:
                                                                                                                  "0 0 " +
                                                                                                                  (im.width /
                                                                                                                    im.height) *
                                                                                                                    720 +
                                                                                                                  " " +
                                                                                                                  720,
                                                                                                                xlink:
                                                                                                                  "http://www.w3.org/1999/xlink",
                                                                                                                xml: "",
                                                                                                                xmlns:
                                                                                                                  "http://www.w3.org/2000/svg",
                                                                                                                "data-lazy-bgimg":
                                                                                                                  im.url,
                                                                                                                "data-fail":
                                                                                                                  "0",
                                                                                                              },
                                                                                                          }
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                        ]
                                      }),
                                      _vm._v(" "),
                                      _vm._l(m.columnArr, function (im, index) {
                                        return _c(
                                          "section",
                                          {
                                            key: "column" + index,
                                            staticStyle: {
                                              margin: "10px 0px 0px",
                                              "box-sizing": "border-box",
                                              display: "flex",
                                            },
                                          },
                                          [
                                            index == 0
                                              ? [
                                                  _c(
                                                    "section",
                                                    {
                                                      staticStyle: {
                                                        width: "80px",
                                                        "margin-right": "3px",
                                                      },
                                                    },
                                                    [
                                                      m.id && _vm.miniAppid
                                                        ? _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "weapp_image_link",
                                                              attrs: {
                                                                "data-miniprogram-appid":
                                                                  _vm.miniAppid,
                                                                "data-miniprogram-path":
                                                                  "pages/auctionDetail/index?id=" +
                                                                  m.id,
                                                                "data-miniprogram-nickname":
                                                                  _vm.miniName,
                                                                href: "",
                                                                "data-miniprogram-type":
                                                                  "image",
                                                                "data-miniprogram-servicetype":
                                                                  "2",
                                                                "wah-hotarea":
                                                                  "click",
                                                              },
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticStyle: {
                                                                  display:
                                                                    "block",
                                                                  width: "80px",
                                                                },
                                                                attrs: {
                                                                  src:
                                                                    im.url +
                                                                    "!m640",
                                                                  alt: "",
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _c("img", {
                                                            staticStyle: {
                                                              display: "block",
                                                              width: "80px",
                                                            },
                                                            attrs: {
                                                              src:
                                                                im.url +
                                                                "!m640",
                                                              alt: "",
                                                            },
                                                          }),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "section",
                                                    {
                                                      staticStyle: {
                                                        flex: "1",
                                                        "vertical-align": "top",
                                                        overflow: "hidden",
                                                        "align-self":
                                                          "flex-start",
                                                        "box-sizing":
                                                          "border-box",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "svg",
                                                        {
                                                          staticStyle: {
                                                            width: "100%",
                                                            "pointer-events":
                                                              "none",
                                                            display: "block",
                                                            "box-sizing":
                                                              "border-box",
                                                          },
                                                          attrs: {
                                                            viewBox:
                                                              "0 0 1080 1473",
                                                            xlink:
                                                              "http://www.w3.org/1999/xlink",
                                                            xmlns:
                                                              "http://www.w3.org/2000/svg",
                                                            x: "0",
                                                            y: "0",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                 \n                "
                                                          ),
                                                          _c(
                                                            "g",
                                                            {
                                                              staticStyle: {
                                                                "box-sizing":
                                                                  "border-box",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "animateTransform",
                                                                {
                                                                  staticStyle: {
                                                                    "box-sizing":
                                                                      "border-box",
                                                                  },
                                                                  attrs: {
                                                                    attributeName:
                                                                      "transform",
                                                                    type: "translate",
                                                                    keyTimes:
                                                                      "0;0.10;0.50;0.60;1.00",
                                                                    values:
                                                                      "0 0;0 0;0 -" +
                                                                      (im.height /
                                                                        im.width) *
                                                                        1080 +
                                                                      ";0 -" +
                                                                      (im.height /
                                                                        im.width) *
                                                                        1080 +
                                                                      ";0 -" +
                                                                      (im.height /
                                                                        im.width) *
                                                                        1080 *
                                                                        2,
                                                                    repeatCount:
                                                                      "indefinite",
                                                                    fill: "freeze",
                                                                    begin: "0s",
                                                                    dur:
                                                                      ((im.height /
                                                                        im.width) *
                                                                        1080) /
                                                                        200 +
                                                                      "s",
                                                                    calcMode:
                                                                      "linear",
                                                                    restart:
                                                                      "never",
                                                                  },
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "g",
                                                                {
                                                                  staticStyle: {
                                                                    "box-sizing":
                                                                      "border-box",
                                                                  },
                                                                },
                                                                [
                                                                  _c("rect", {
                                                                    staticStyle:
                                                                      {
                                                                        "pointer-events":
                                                                          "painted",
                                                                        "box-sizing":
                                                                          "border-box",
                                                                      },
                                                                    attrs: {
                                                                      width:
                                                                        "100%",
                                                                      height:
                                                                        "100%",
                                                                      opacity:
                                                                        "0",
                                                                      fill: "#ffffff",
                                                                    },
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "g",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "box-sizing":
                                                                            "border-box",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "foreignObject",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "box-sizing":
                                                                                "border-box",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                              height:
                                                                                "" +
                                                                                (im.height /
                                                                                  im.width) *
                                                                                  1080 *
                                                                                  2,
                                                                              x: "0",
                                                                              y: "0",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "svg",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "100%",
                                                                                  "background-position":
                                                                                    "0% 0%",
                                                                                  "background-repeat":
                                                                                    "no-repeat",
                                                                                  "background-size":
                                                                                    "cover",
                                                                                  "background-attachment":
                                                                                    "scroll",
                                                                                  "-webkit-tap-highlight-color":
                                                                                    "transparent",
                                                                                  visibility:
                                                                                    "visible",
                                                                                  "pointer-events":
                                                                                    "none",
                                                                                  "box-sizing":
                                                                                    "border-box",
                                                                                  "user-select":
                                                                                    "none",
                                                                                },
                                                                              style:
                                                                                "background-image: url('" +
                                                                                im.url +
                                                                                "!m640');",
                                                                              attrs:
                                                                                {
                                                                                  space:
                                                                                    "default",
                                                                                  viewBox:
                                                                                    "0 0 1080 " +
                                                                                    (im.height /
                                                                                      im.width) *
                                                                                      1080,
                                                                                  xlink:
                                                                                    "http://www.w3.org/1999/xlink",
                                                                                  xml: "",
                                                                                  xmlns:
                                                                                    "http://www.w3.org/2000/svg",
                                                                                  "data-lazy-bgimg":
                                                                                    im.url +
                                                                                    "!m640",
                                                                                  "data-fail":
                                                                                    "0",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "g",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "box-sizing":
                                                                            "border-box",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "foreignObject",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "box-sizing":
                                                                                "border-box",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                              height:
                                                                                "" +
                                                                                (im.height /
                                                                                  im.width) *
                                                                                  1080 *
                                                                                  2,
                                                                              x: "0",
                                                                              y:
                                                                                "" +
                                                                                (im.height /
                                                                                  im.width) *
                                                                                  1080,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "svg",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "100%",
                                                                                  "background-position":
                                                                                    "0% 0%",
                                                                                  "background-repeat":
                                                                                    "no-repeat",
                                                                                  "background-size":
                                                                                    "cover",
                                                                                  "background-attachment":
                                                                                    "scroll",
                                                                                  "-webkit-tap-highlight-color":
                                                                                    "transparent",
                                                                                  visibility:
                                                                                    "visible",
                                                                                  "pointer-events":
                                                                                    "none",
                                                                                  "box-sizing":
                                                                                    "border-box",
                                                                                  "user-select":
                                                                                    "none",
                                                                                },
                                                                              style:
                                                                                "background-image: url('" +
                                                                                im.url +
                                                                                "!m640');",
                                                                              attrs:
                                                                                {
                                                                                  space:
                                                                                    "default",
                                                                                  viewBox:
                                                                                    "0 0 1080 " +
                                                                                    (im.height /
                                                                                      im.width) *
                                                                                      1080,
                                                                                  xlink:
                                                                                    "http://www.w3.org/1999/xlink",
                                                                                  xml: "",
                                                                                  xmlns:
                                                                                    "http://www.w3.org/2000/svg",
                                                                                  "data-lazy-bgimg":
                                                                                    im.url,
                                                                                  "data-fail":
                                                                                    "0",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "g",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "box-sizing":
                                                                            "border-box",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "foreignObject",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "box-sizing":
                                                                                "border-box",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                              height:
                                                                                "" +
                                                                                (im.height /
                                                                                  im.width) *
                                                                                  1080 *
                                                                                  2,
                                                                              x: "0",
                                                                              y:
                                                                                "" +
                                                                                (im.height /
                                                                                  im.width) *
                                                                                  1080 *
                                                                                  2,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "svg",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "100%",
                                                                                  "background-position":
                                                                                    "0% 0%",
                                                                                  "background-repeat":
                                                                                    "no-repeat",
                                                                                  "background-size":
                                                                                    "cover",
                                                                                  "background-attachment":
                                                                                    "scroll",
                                                                                  "-webkit-tap-highlight-color":
                                                                                    "transparent",
                                                                                  visibility:
                                                                                    "visible",
                                                                                  "pointer-events":
                                                                                    "none",
                                                                                  "box-sizing":
                                                                                    "border-box",
                                                                                  "user-select":
                                                                                    "none",
                                                                                },
                                                                              style:
                                                                                "background-image: url('" +
                                                                                im.url +
                                                                                "!m640');",
                                                                              attrs:
                                                                                {
                                                                                  space:
                                                                                    "default",
                                                                                  viewBox:
                                                                                    "0 0 1080 " +
                                                                                    (im.height /
                                                                                      im.width) *
                                                                                      1080,
                                                                                  xlink:
                                                                                    "http://www.w3.org/1999/xlink",
                                                                                  xml: "",
                                                                                  xmlns:
                                                                                    "http://www.w3.org/2000/svg",
                                                                                  "data-lazy-bgimg":
                                                                                    im.url,
                                                                                  "data-fail":
                                                                                    "0",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      }),
                                    ]
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("section", [
                    _c(
                      "section",
                      {
                        style: {
                          padding:
                            "0 " +
                            _vm.facade.item_padding +
                            "px " +
                            _vm.facade.item_padding +
                            "px " +
                            _vm.facade.item_padding +
                            "px",
                        },
                      },
                      [
                        _c(
                          "section",
                          {
                            style: {
                              padding:
                                (_vm.facade.background_padding_top || 0) +
                                "px " +
                                (_vm.facade.background_padding_right || 0) +
                                "px " +
                                (_vm.facade.background_padding_bottom || 0) +
                                "px " +
                                (_vm.facade.background_padding_left || 0) +
                                "px",
                              marginTop:
                                (_vm.facade.content_toppadding || 0) + "px",
                              background: _vm.getAllColor(
                                m,
                                "content_background"
                              ),
                            },
                          },
                          [
                            _vm.facade.personalizedDecoration > 0
                              ? [
                                  _c("section", {
                                    style: {
                                      width: "100%",
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(_vm.svgTopImg(m)),
                                    },
                                  }),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "section",
                              {
                                staticStyle: {
                                  display: "grid",
                                  "grid-template-columns": "1fr",
                                  "grid-template-rows": "auto",
                                },
                              },
                              [
                                _vm.facade.personalizedDecoration > 0
                                  ? _c("section", {
                                      staticStyle: {
                                        "grid-column": "1 / -1",
                                        "grid-row": "1 / -1",
                                        "z-index": "1",
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(_vm.svgCenterImg(m)),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "section",
                                  {
                                    staticStyle: {
                                      "grid-column": "1 / -1",
                                      "grid-row": "1 / -1",
                                      "z-index": "2",
                                    },
                                    style: _vm.contentBackground(m),
                                  },
                                  [
                                    _vm.content.attrlist &&
                                    _vm.content.attrlist.length
                                      ? [
                                          _vm._l(
                                            _vm.content.attrlist,
                                            function (item, index) {
                                              return [
                                                item.key == "lot"
                                                  ? [
                                                      item.selected &&
                                                      m[item.key]
                                                        ? _c(
                                                            "section",
                                                            {
                                                              key: index,
                                                              style: {
                                                                paddingTop:
                                                                  _vm.facade
                                                                    .lot_margin +
                                                                  "px",
                                                                color:
                                                                  _vm.getAllColor(
                                                                    m,
                                                                    "lot_color"
                                                                  ),
                                                                fontWeight:
                                                                  _vm.facade
                                                                    .lot_weight,
                                                                fontSize:
                                                                  _vm.facade
                                                                    .lot_size +
                                                                  "px",
                                                                textAlign:
                                                                  _vm.facade
                                                                    .lot_text_align,
                                                                fontFamily:
                                                                  _vm.facade
                                                                    .lot_fontFamily,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    " +
                                                                  _vm._s(
                                                                    m[item.key]
                                                                  ) +
                                                                  "\n                  "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  : item.key == "start_price" ||
                                                    item.key ==
                                                      "reference_price" ||
                                                    item.key == "success_price"
                                                  ? [
                                                      item.selected &&
                                                      m[item.key]
                                                        ? _c(
                                                            "section",
                                                            {
                                                              key: index,
                                                              style: {
                                                                paddingTop:
                                                                  _vm.facade
                                                                    .price_margin +
                                                                  "px",
                                                                color:
                                                                  _vm.getAllColor(
                                                                    m,
                                                                    "price_color"
                                                                  ),
                                                                fontWeight:
                                                                  _vm.facade
                                                                    .price_weight,
                                                                fontSize:
                                                                  _vm.facade
                                                                    .price_size +
                                                                  "px",
                                                                textAlign:
                                                                  _vm.facade
                                                                    .price_text_align,
                                                                whiteSpace:
                                                                  "pre-wrap",
                                                                fontFamily:
                                                                  _vm.facade
                                                                    .price_fontFamily,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  m[item.key]
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  : item.key == "attr"
                                                  ? [
                                                      item.selected &&
                                                      m.attr_info &&
                                                      m.attr_info[0] &&
                                                      m.attr_info[0]
                                                        .attr_name &&
                                                      m.attr_info[0].attr_value
                                                        ? _c(
                                                            "section",
                                                            {
                                                              key: index,
                                                              style: {
                                                                paddingTop:
                                                                  _vm.facade
                                                                    .attr_margin +
                                                                  "px",
                                                              },
                                                            },
                                                            [
                                                              _vm._l(
                                                                m.attr_info,
                                                                function (
                                                                  attr,
                                                                  attrIndex
                                                                ) {
                                                                  return [
                                                                    attr.attr_name &&
                                                                    attr.attr_value
                                                                      ? [
                                                                          _c(
                                                                            "section",
                                                                            {
                                                                              key:
                                                                                index +
                                                                                "_attr_" +
                                                                                attrIndex,
                                                                              style:
                                                                                {
                                                                                  color:
                                                                                    _vm.getAllColor(
                                                                                      m,
                                                                                      "attr_color"
                                                                                    ),
                                                                                  fontWeight:
                                                                                    _vm
                                                                                      .facade
                                                                                      .attr_weight,
                                                                                  fontSize:
                                                                                    _vm
                                                                                      .facade
                                                                                      .attr_size +
                                                                                    "px",
                                                                                  textAlign:
                                                                                    _vm
                                                                                      .facade
                                                                                      .attr_text_align,
                                                                                  lineHeight:
                                                                                    _vm
                                                                                      .facade
                                                                                      .attr_line_height +
                                                                                    "px",
                                                                                  fontFamily:
                                                                                    _vm
                                                                                      .facade
                                                                                      .attr_fontFamily,
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      attr.attr_name
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .facade
                                                                                    .attrSeparator
                                                                                )
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  style:
                                                                                    {
                                                                                      color:
                                                                                        _vm.getAllColor(
                                                                                          m,
                                                                                          "attr_value_color"
                                                                                        ),
                                                                                      fontWeight:
                                                                                        _vm
                                                                                          .facade
                                                                                          .attr_value_weight,
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      attr.attr_value
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                  ]
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  : item.key == "name"
                                                  ? [
                                                      item.selected &&
                                                      m[item.key]
                                                        ? _c(
                                                            "section",
                                                            {
                                                              key: index,
                                                              style: {
                                                                paddingTop:
                                                                  _vm.facade
                                                                    .title_margin +
                                                                  "px",
                                                                color:
                                                                  _vm.getAllColor(
                                                                    m,
                                                                    "title_color"
                                                                  ),
                                                                fontWeight:
                                                                  _vm.facade
                                                                    .title_weight,
                                                                fontSize:
                                                                  _vm.facade
                                                                    .title_size +
                                                                  "px",
                                                                textAlign:
                                                                  _vm.facade
                                                                    .title_text_align,
                                                                whiteSpace:
                                                                  "pre-wrap",
                                                                fontFamily:
                                                                  _vm.facade
                                                                    .title_fontFamily,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  m[item.key]
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  : item.key == "content" &&
                                                    _vm.facade.show_area == 2
                                                  ? void 0
                                                  : item.key == "content"
                                                  ? [
                                                      item.selected &&
                                                      m[item.key]
                                                        ? _c(
                                                            "section",
                                                            {
                                                              key: index,
                                                              style: {
                                                                paddingTop:
                                                                  _vm.facade
                                                                    .content_margin +
                                                                  "px",
                                                                color:
                                                                  _vm.getAllColor(
                                                                    m,
                                                                    "content_color"
                                                                  ),
                                                                fontWeight:
                                                                  _vm.facade
                                                                    .content_weight,
                                                                fontSize:
                                                                  _vm.facade
                                                                    .content_size +
                                                                  "px",
                                                                textAlign:
                                                                  _vm.facade
                                                                    .content_text_align,
                                                                lineHeight:
                                                                  _vm.facade
                                                                    .content_line_height +
                                                                  "px",
                                                                whiteSpace:
                                                                  "pre-wrap",
                                                                fontFamily:
                                                                  _vm.facade
                                                                    .content_fontFamily,
                                                              },
                                                            },
                                                            [
                                                              _c("section", {
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      m[
                                                                        item.key
                                                                      ]
                                                                    ),
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  : [
                                                      item.selected &&
                                                      m[item.key]
                                                        ? _c(
                                                            "section",
                                                            {
                                                              key: index,
                                                              style: {
                                                                paddingTop:
                                                                  _vm.facade
                                                                    .desc_margin +
                                                                  "px",
                                                                color:
                                                                  _vm.getAllColor(
                                                                    m,
                                                                    "desc_color"
                                                                  ),
                                                                fontWeight:
                                                                  _vm.facade
                                                                    .desc_weight,
                                                                fontSize:
                                                                  _vm.facade
                                                                    .desc_size +
                                                                  "px",
                                                                textAlign:
                                                                  _vm.facade
                                                                    .desc_text_align,
                                                                lineHeight:
                                                                  _vm.facade
                                                                    .desc_line_height +
                                                                  "px",
                                                                whiteSpace:
                                                                  "pre-wrap",
                                                                fontFamily:
                                                                  _vm.facade
                                                                    .desc_fontFamily,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  m[item.key]
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                              ]
                                            }
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.facade.personalizedDecoration > 0
                              ? [
                                  _c("section", {
                                    style: {
                                      width: "100%",
                                      marginTop: "-4px",
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(_vm.svgBottomImg(m)),
                                    },
                                  }),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.content.attrlist && _vm.content.attrlist.length
                              ? [
                                  _vm._l(
                                    _vm.content.attrlist,
                                    function (item, index) {
                                      return [
                                        item.key == "content" &&
                                        _vm.facade.show_area == 2 &&
                                        item.selected &&
                                        m[item.key]
                                          ? [
                                              _c(
                                                "section",
                                                {
                                                  key: index,
                                                  style: {
                                                    paddingTop:
                                                      _vm.facade
                                                        .content_margin + "px",
                                                    color: _vm.getAllColor(
                                                      m,
                                                      "content_color"
                                                    ),
                                                    fontWeight:
                                                      _vm.facade.content_weight,
                                                    fontSize:
                                                      _vm.facade.content_size +
                                                      "px",
                                                    textAlign:
                                                      _vm.facade
                                                        .content_text_align,
                                                    lineHeight:
                                                      _vm.facade
                                                        .content_line_height +
                                                      "px",
                                                    whiteSpace: "pre-wrap",
                                                  },
                                                },
                                                [
                                                  _c("section", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        m[item.key]
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      {
        staticStyle: {
          display: "inline-block",
          width: "100%",
          height: "526px",
          "vertical-align": "top",
          "overflow-y": "auto",
          "-webkit-overflow-scrolling": "touch",
          padding: "10px",
          "background-color": "rgba(156, 128, 79, 0.09)",
          "box-sizing": "border-box",
        },
      },
      [
        _c(
          "section",
          { staticStyle: { overflow: "hidden", "box-sizing": "border-box" } },
          [
            _c(
              "section",
              {
                staticStyle: {
                  "text-align": "justify",
                  "box-sizing": "border-box",
                },
              },
              [
                _c("img", {
                  staticStyle: { display: "block", width: "100%" },
                  attrs: {
                    src: "https://saas.cdn.yunzongbu.cn/merchant/23/202486/image/1722939142292239903.webp!m640",
                    alt: "",
                  },
                }),
              ]
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }