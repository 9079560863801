var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showVisible,
            "close-on-click-modal": false,
            "show-close": false,
            width: "650px",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "header-btn",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("span", [_vm._v("上传图片")]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one" }, [
                !_vm.isClient
                  ? _c(
                      "span",
                      {
                        staticClass: "ml20",
                        staticStyle: {
                          color: "#ae0000",
                          "font-weight": "500",
                          "font-size": "12px",
                        },
                      },
                      [_vm._v("该功能只能在客户端使用")]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "close", on: { click: _vm.hideAction } },
                [
                  _vm.isStart && !_vm.isFinish
                    ? _c("el-button", { attrs: { type: "text" } }, [
                        _vm._v("隐藏到后台执行"),
                      ])
                    : _c("i", { staticClass: "el-icon-close" }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "describe" },
            [
              _vm.isFinish
                ? [
                    _c("div", { staticClass: "finish-img item-flex-center" }, [
                      _c("div", { staticClass: "img item-flex-center" }, [
                        _c("i", { staticClass: "el-icon-check" }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "finish-text" }, [
                      _vm._v("上传完成"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "finish-status" }, [
                      _vm._v(
                        "成功上传" + _vm._s(_vm.current) + "张图片\n          "
                      ),
                      _vm.uploadErrorList && _vm.uploadErrorList.length
                        ? _c(
                            "span",
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "bottom",
                                    title: "查看失败原因",
                                    width: "360",
                                    trigger: "hover",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { color: "#FF5D5D" } },
                                    _vm._l(
                                      _vm.uploadErrorList,
                                      function (m, i) {
                                        return _c(
                                          "div",
                                          {
                                            key: "uploadErrorList_" + i,
                                            staticClass: "flex",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "flex-one" },
                                              [_vm._v(_vm._s(m.currentFile))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "error-txt ml10" },
                                              [_vm._v(_vm._s(m.error))]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#FF5D5D",
                                        cursor: "pointer",
                                      },
                                      attrs: { slot: "reference" },
                                      slot: "reference",
                                    },
                                    [
                                      _vm._v(
                                        " 共" +
                                          _vm._s(_vm.uploadErrorList.length) +
                                          "张上传失败"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "upload-btn",
                        staticStyle: { "margin-top": "30px" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-folder-opened",
                              size: "medium",
                            },
                            on: { click: _vm.uploadAction },
                          },
                          [_vm._v("继续上传")]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm.isStart
                ? [
                    _c("div", { staticClass: "progress item-flex-center" }, [
                      _c(
                        "div",
                        {
                          staticClass: "p-txt",
                          staticStyle: { "padding-top": "20px" },
                        },
                        [
                          _vm._v(
                            "当前处理第" +
                              _vm._s(_vm.current) +
                              "张/共" +
                              _vm._s(_vm.total) +
                              "张"
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "p-txt",
                          staticStyle: { "padding-top": "10px" },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.percent))]), _vm._v("%")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "p",
                          staticStyle: { "padding-top": "10px" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "l" },
                            [
                              _c("el-progress", {
                                attrs: {
                                  "text-inside": true,
                                  "stroke-width": 24,
                                  percentage: _vm.percent,
                                  "show-text": false,
                                  status: "success",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "close",
                              on: { click: _vm.closeAction },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.uploadErrorList && _vm.uploadErrorList.length
                      ? [
                          _c("div", { staticClass: "error-view" }, [
                            _c("div", { staticClass: "error-title" }, [
                              _vm._v(
                                _vm._s(_vm.uploadErrorList.length) +
                                  "张上传失败"
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "error-list" },
                              _vm._l(_vm.uploadErrorList, function (m, i) {
                                return _c(
                                  "div",
                                  {
                                    key: "uploadErrorList_" + i,
                                    staticClass: "item",
                                  },
                                  [
                                    _c("div", { staticClass: "flex-one" }, [
                                      _vm._v(_vm._s(m.currentFile)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "error-txt ml10" },
                                      [_vm._v(_vm._s(m.error))]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                        ]
                      : _vm._e(),
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "upload-btn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-folder-opened",
                              size: "medium",
                            },
                            on: { click: _vm.uploadAction },
                          },
                          [_vm._v("选择文件夹")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "upload-tip" }, [
                      _c("div", [
                        _c("img", {
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/merchant/23/2025113/image/173673988549289320.png",
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "一次只能选择一个文件夹，上拍图将与入库图自动完成匹配。"
                          ),
                        ]),
                      ]),
                    ]),
                  ],
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }