var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "banner-control",
          attrs: {
            model: _vm.content,
            "label-width": "90px",
            size: "mini",
            "label-position": "left",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "auction-select-add",
              on: { click: _vm.handleAdditem },
            },
            [
              _c("i", {
                staticClass: "el-icon-plus",
                staticStyle: { "margin-right": "8px" },
              }),
              _vm._v(" "),
              _c("a", { staticClass: "fontW500 ft16" }, [_vm._v("选择拍品")]),
            ]
          ),
          _vm._v(" "),
          _vm.content.data && _vm.content.data.length > 0
            ? _c(
                "div",
                { staticClass: "mt20" },
                [
                  _c(
                    "draggable",
                    _vm._b(
                      {
                        staticClass: "board-column-content flex flex-wrap",
                        attrs: { list: _vm.content.data },
                      },
                      "draggable",
                      _vm.$attrs,
                      false
                    ),
                    _vm._l(_vm.content.data, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "kuang1 item-align-center",
                          staticStyle: {
                            overflow: "hidden",
                            position: "relative",
                          },
                        },
                        [
                          _c("div", [
                            _c("img", {
                              staticStyle: {
                                width: "20px",
                                height: "20px",
                                "margin-right": "10px",
                              },
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/merchant/23/20241223/image/1734947020513869844.png!120a",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "flex-one ft12 flex",
                              staticStyle: { overflow: "hidden" },
                            },
                            [
                              _c("div", { staticClass: "auction-img mr10" }, [
                                item.slider_image && item.slider_image[0]
                                  ? _c("img", {
                                      attrs: {
                                        src: item.slider_image[0] + "!120a",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                item.slider_image
                                  ? _c("div", { staticClass: "tips" }, [
                                      _vm._v(
                                        _vm._s(item.slider_image.length) + "张"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _vm.content.attrlist &&
                              _vm.content.attrlist.length
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "flex-one",
                                      staticStyle: { overflow: "hidden" },
                                    },
                                    [
                                      _vm._l(
                                        _vm.content.attrlist,
                                        function (it, index) {
                                          return [
                                            it.selected &&
                                            item[it.key] &&
                                            it.key != "content"
                                              ? _c(
                                                  "div",
                                                  {
                                                    key: "attr" + index,
                                                    staticClass: "line1",
                                                    class: "key_" + it.key,
                                                    staticStyle: {
                                                      "line-height": "20px",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item[it.key]))]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "go-down-img ml10",
                              on: {
                                click: function ($event) {
                                  return _vm.editAuction(index)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: { width: "16px", height: "16px" },
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024919/image/1726732000092969257.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "del-img",
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(index)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: { width: "20px", height: "20px" },
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("selectView", {
        ref: "selectView",
        attrs: {
          "has-more-data": "1",
          "all-image": "true",
          query: { venue_id: _vm.venue_id, album_id: _vm.album_id },
          "current-data": [],
        },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editAuctionShow,
            top: "5vh",
            width: "1000px",
            title: "修改拍品信息",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editAuctionShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "mt10" }, [
            _c(
              "div",
              { staticStyle: { "font-size": "16px", "font-weight": "500" } },
              [_vm._v("设置拍品图片")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "change-img flex flex-wrap mt10" },
              _vm._l(_vm.editAuctionData.sliderImageInfo, function (m, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "img",
                    class: {
                      curr:
                        _vm.editAuctionData.slider_image.indexOf(m.url) !== -1,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setSliderImg(m.url)
                      },
                    },
                  },
                  [_c("img", { attrs: { src: m.url + "!m640", alt: "" } })]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt20 flex" }, [
            _c("div", { staticClass: "flex-one" }, [
              _c(
                "div",
                { staticStyle: { "font-size": "16px", "font-weight": "500" } },
                [_vm._v("设置拍品信息")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "mt10",
                  staticStyle: {
                    "max-height": "530px",
                    "overflow-y": "scroll",
                    "padding-right": "30px",
                  },
                },
                [
                  _vm.content.attrlist && _vm.content.attrlist.length
                    ? [
                        _vm._l(_vm.content.attrlist, function (it, index) {
                          return [
                            it.key == "attr" && it.selected
                              ? [
                                  _vm.editAuctionData.attr_info &&
                                  _vm.editAuctionData.attr_info.length
                                    ? _vm._l(
                                        _vm.editAuctionData.attr_info,
                                        function (attrVal, attrIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key:
                                                "attr" +
                                                attrIndex +
                                                "_val" +
                                                index,
                                              staticClass: "flex align-items-c",
                                              class: index > 0 ? "mt10" : "",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "w200" },
                                                [
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      "font-size": "12px",
                                                    },
                                                    attrs: {
                                                      rows: "4",
                                                      placeholder:
                                                        "请输入属性名",
                                                    },
                                                    model: {
                                                      value: attrVal.attr_name,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          attrVal,
                                                          "attr_name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "attrVal.attr_name",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "ml5 mr5" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.facade.attrSeparator
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "flex-one" },
                                                [
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      "font-size": "12px",
                                                    },
                                                    attrs: {
                                                      rows: "4",
                                                      placeholder:
                                                        "请输入属性值",
                                                    },
                                                    model: {
                                                      value: attrVal.attr_value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          attrVal,
                                                          "attr_value",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "attrVal.attr_value",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "ml10 cur",
                                                  on: { click: _vm.addAttr },
                                                },
                                                [_vm._v("+")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "ml10 cur",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeAttr(
                                                        attrIndex
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("-")]
                                              ),
                                            ]
                                          )
                                        }
                                      )
                                    : _vm._e(),
                                ]
                              : it.selected
                              ? _c(
                                  "div",
                                  {
                                    key: "attr" + index,
                                    class: index > 0 ? "mt10" : "",
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { "font-size": "12px" },
                                      attrs: {
                                        type:
                                          it.key == "content" ? "textarea" : "",
                                        rows: "4",
                                        placeholder: "请输入" + it.name,
                                      },
                                      model: {
                                        value: _vm.editAuctionData[it.key],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editAuctionData,
                                            it.key,
                                            $$v
                                          )
                                        },
                                        expression: "editAuctionData[it.key]",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mt20 item-flex-center",
              staticStyle: {
                "padding-top": "20px",
                "border-top": "1px solid #eee",
              },
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editAuctionShow = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveEditAuction },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }