var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: (_vm.id ? "修改" : "创建") + "出库单", "has-back": "" },
      }),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "demo-promoterForm",
              attrs: {
                model: _vm.formValidate,
                rules: _vm.rules,
                "label-width": "100px",
                inline: "",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "库房", prop: "out_warehouse_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: {
                        disabled: _vm.id != "",
                        placeholder: "请选择库房",
                        clearable: "",
                        filterable: "",
                      },
                      on: { change: _vm.selectActiveGoods },
                      model: {
                        value: _vm.formValidate.out_warehouse_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "out_warehouse_id", $$v)
                        },
                        expression: "formValidate.out_warehouse_id",
                      },
                    },
                    _vm._l(_vm.storeArr, function (item) {
                      return _c("el-option", {
                        key: item.warehouse_id,
                        attrs: { label: item.name, value: item.warehouse_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "出库类型", prop: "out_type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: {
                        placeholder: "出库类型",
                        clearable: "",
                        filterable: "",
                        disabled: _vm.isQuick,
                      },
                      on: { change: _vm.onChangeOutType },
                      model: {
                        value: _vm.formValidate.out_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "out_type", $$v)
                        },
                        expression: "formValidate.out_type",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: "销售出库",
                          value: "out_warehouse_order",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: {
                          label: "退回出库",
                          value: "out_warehouse_refund",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: {
                          label: "借出出库",
                          value: "out_warehouse_borrow",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.reductjson.erp_v2_out_warehouse == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "审批人" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selwidth",
                          attrs: {
                            placeholder: "请选择审批人",
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.reviewAduitId,
                            callback: function ($$v) {
                              _vm.reviewAduitId = $$v
                            },
                            expression: "reviewAduitId",
                          },
                        },
                        _vm._l(_vm.reductjson.adminData, function (item) {
                          return _c("el-option", {
                            key: item.merchant_admin_id,
                            attrs: {
                              label: item.real_name,
                              value: item.merchant_admin_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "物流方式", prop: "out_mode" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: {
                        placeholder: "物流方式",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.formValidate.out_mode,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "out_mode", $$v)
                        },
                        expression: "formValidate.out_mode",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "物流", value: "express" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "自提", value: "self_take" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: {
                          label: "委托他人自提",
                          value: "entrust_self_take",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formValidate.out_mode == "express"
                ? _c(
                    "el-form-item",
                    {
                      attrs: { label: "收货人姓名", prop: "express_user_name" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "150px" },
                          attrs: {
                            placeholder: "请选择收货人",
                            filterable: "",
                            remote: "",
                            "remote-method": _vm.searchUserAction,
                          },
                          on: { change: _vm.onUserChange },
                          model: {
                            value: _vm.formValidate.express_user_name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formValidate,
                                "express_user_name",
                                $$v
                              )
                            },
                            expression: "formValidate.express_user_name",
                          },
                        },
                        [
                          _vm._l(_vm.usershList, function (m, i) {
                            return _c(
                              "el-option",
                              {
                                key: "user" + i,
                                attrs: { value: m.uid, label: m.nickname },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "40px",
                                        height: "40px",
                                        "border-radius": "50%",
                                        "margin-right": "12px",
                                      },
                                      attrs: { src: m.avatar, alt: "" },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "12px",
                                          "line-height": "18px",
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            " " + _vm._s(m.nickname) + " "
                                          ),
                                          m.real_name
                                            ? _c("span", [
                                                _vm._v(
                                                  "(" +
                                                    _vm._s(m.real_name) +
                                                    ")"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [_vm._v(_vm._s(m.phone))]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c("template", { slot: "empty" }, [
                            _c("div", { staticClass: "select-empty" }, [
                              _vm._v("没有此用户"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "select-button" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: { click: _vm.createUser },
                                  },
                                  [_vm._v(" 确认创建 ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formValidate.out_mode == "express"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "收货人地址",
                        prop: "express_user_address",
                      },
                    },
                    [
                      _vm.addrList.length > 0
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "150px" },
                              attrs: {
                                placeholder: "请选择地址",
                                clearable: "",
                                filterable: "",
                              },
                              on: { change: _vm.onUserAddre },
                              model: {
                                value: _vm.formValidate.express_user_address,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formValidate,
                                    "express_user_address",
                                    $$v
                                  )
                                },
                                expression: "formValidate.express_user_address",
                              },
                            },
                            _vm._l(_vm.addrList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.detail,
                                  value: item.detail,
                                },
                              })
                            }),
                            1
                          )
                        : _c("el-input", {
                            staticStyle: { width: "150px" },
                            model: {
                              value: _vm.formValidate.express_user_address,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "express_user_address",
                                  $$v
                                )
                              },
                              expression: "formValidate.express_user_address",
                            },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formValidate.out_mode == "express"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "收货人手机号",
                        prop: "express_user_phone",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "150px" },
                        model: {
                          value: _vm.formValidate.express_user_phone,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formValidate,
                              "express_user_phone",
                              $$v
                            )
                          },
                          expression: "formValidate.express_user_phone",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: {
                      rows: 3,
                      type: "textarea",
                      placeholder: "请输入备注",
                    },
                    model: {
                      value: _vm.formValidate.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "remark", $$v)
                      },
                      expression: "formValidate.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { "margin-top": "30px" },
                  attrs: {
                    data: _vm.goodsArr,
                    size: "small",
                    "highlight-current-row": "",
                    border: "",
                  },
                },
                [
                  _vm._l(_vm.pushheaderList, function (item, index) {
                    return [
                      item.field == "image"
                        ? [
                            _c("el-table-column", {
                              key: index,
                              attrs: {
                                label: item.name,
                                width: item.width,
                                index: index,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "demo-image__preview",
                                            staticStyle: { cursor: "pointer" },
                                          },
                                          _vm._l(
                                            scope.row.warehouse_image,
                                            function (it, itIndex) {
                                              return _c(
                                                "div",
                                                {
                                                  key:
                                                    "img_" +
                                                    index +
                                                    "_" +
                                                    itIndex,
                                                  staticClass: "img",
                                                },
                                                [
                                                  it
                                                    ? _c("img", {
                                                        staticStyle: {
                                                          width: "30px",
                                                          height: "30px",
                                                        },
                                                        attrs: {
                                                          src: it + "!120a",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        : item.field == "order_num"
                        ? [
                            _c("el-table-column", {
                              key: index,
                              attrs: {
                                label: item.name,
                                prop: "",
                                "min-width": item.width,
                                index: index,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          [
                                            _c("el-input", {
                                              staticClass: "sortCol",
                                              on: {
                                                focus: function ($event) {
                                                  return _vm.focus($event)
                                                },
                                              },
                                              model: {
                                                value: scope.row.order_num,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "order_num",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.order_num",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        : item.field == "product_no"
                        ? [
                            _c("el-table-column", {
                              key: index,
                              attrs: {
                                label: item.name,
                                prop: "",
                                "min-width": item.width,
                                index: index,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(scope.row.product_no) +
                                              "\n                  "
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        : item.field == "product_name"
                        ? [
                            _c("el-table-column", {
                              key: index,
                              attrs: {
                                label: item.name,
                                prop: "",
                                "min-width": item.width,
                                index: index,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(scope.row.product_name) +
                                            "\n              "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        : item.field == "label"
                        ? [
                            _c("el-table-column", {
                              key: index,
                              attrs: {
                                label: item.name,
                                "min-width": "200",
                                prop: "label",
                                index: index,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              scope.row.labelNames.join(",")
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        : item.field == "shelf_id"
                        ? [
                            _c("el-table-column", {
                              key: index,
                              attrs: {
                                label: "选择货架（选填）",
                                "min-width":
                                  item.width < 120 ? 120 : item.width,
                                index: index,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function (scope) {
                                      return [
                                        _c("div", { staticClass: "headPos" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex align-items-c cur",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.headClick(
                                                    "shelf_id"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    选择货架（选填） "
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-edit-outline",
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              class: {
                                                poaBox: true,
                                                isShow:
                                                  _vm.handName == "shelf_id",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  ref: "shelf_id",
                                                  refInFor: true,
                                                  staticClass: "sortCol",
                                                  attrs: {
                                                    disabled:
                                                      !_vm.formValidate
                                                        .out_warehouse_id,
                                                    placeholder: "请选择货架",
                                                    clearable: "",
                                                    filterable: "",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.onHeadChange(
                                                        "shelf_id"
                                                      )
                                                    },
                                                    blur: function ($event) {
                                                      _vm.handName = ""
                                                    },
                                                    focus: function ($event) {
                                                      return _vm.headClick(
                                                        "shelf_id"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.headObj.shelf_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.headObj,
                                                        "shelf_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "headObj.shelf_id",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.shelfList,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.shelf_id,
                                                      attrs: {
                                                        label: item.shelf_no,
                                                        value: item.shelf_id,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "sortCol",
                                            attrs: {
                                              disabled:
                                                !_vm.formValidate
                                                  .out_warehouse_id,
                                              placeholder: "请选择货架",
                                              clearable: "",
                                              filterable: "",
                                            },
                                            on: {
                                              focus: function ($event) {
                                                return _vm.focus($event)
                                              },
                                            },
                                            model: {
                                              value: scope.row.shelf_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "shelf_id",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.shelf_id",
                                            },
                                          },
                                          _vm._l(
                                            _vm.shelfList,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.shelf_id,
                                                attrs: {
                                                  label: item.shelf_no,
                                                  value: item.shelf_id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        : item.newtype == "attribute"
                        ? [
                            item.name.indexOf("尺寸") != -1
                              ? [
                                  _c("el-table-column", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      "show-overflow-tooltip": "",
                                      width: "100",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("div", {
                                                staticClass: "nowrap cur",
                                                staticStyle: { height: "100%" },
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    scope.row.attribute[
                                                      item.field
                                                    ]
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onClickSize(
                                                      scope.row.attribute[
                                                        item.field
                                                      ],
                                                      scope.$index,
                                                      scope.row,
                                                      item.field
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              : [
                                  _c("el-table-column", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      "min-width": item.width,
                                      index: index,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("el-input", {
                                                staticClass: "sortCol",
                                                attrs: {
                                                  placeholder: "",
                                                  size: "normal",
                                                },
                                                on: {
                                                  focus: function ($event) {
                                                    return _vm.focus($event)
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    scope.row.attribute[
                                                      item.field
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row.attribute,
                                                      item.field,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.attribute[item.field]",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                          ]
                        : _vm._e(),
                    ]
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delGoods(
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                删除\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { type: "primary", loading: _vm.loading },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("formValidate")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            确认" +
                              _vm._s(_vm.$route.query.id ? "修改" : "创建") +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12, offset: 0 } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isQuick ? _c("el-divider") : _vm._e(),
          _vm._v(" "),
          !_vm.isQuick
            ? _c(
                "div",
                { staticStyle: { "font-size": "20px", "font-weight": "500" } },
                [_vm._v("\n      从库中选择\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isQuick
            ? _c(
                "el-table",
                {
                  ref: "outorTabelRef",
                  staticStyle: { "margin-top": "20px" },
                  attrs: {
                    data: _vm.tableData.data,
                    size: "small",
                    "highlight-current-row": "",
                    width: "100%",
                    border: "",
                  },
                },
                [
                  _c("ytx-table-column", {
                    attrs: {
                      label: "序号",
                      prop: "order_num",
                      width: "80",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row.order_num) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4230531482
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "编号",
                      prop: "product_no",
                      "min-width": "100",
                      "search-key": "start_product_no",
                      "search-other-key": "end_product_no",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.product_no)),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2432800275
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "货架",
                      "min-width": "100",
                      prop: "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.shelf
                                ? _c("span", [
                                    _vm._v(_vm._s(scope.row.shelf.shelf_no)),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3876710397
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "LOT",
                      width: "100",
                      prop: "",
                      "search-key": "start_lot",
                      "search-other-key": "end_lot",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row.lot) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2874860778
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "初始名称",
                      "min-width": "120",
                      prop: "",
                      "search-key": "product_name",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row.product_name) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4232949998
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "上拍名称",
                      "min-width": "120",
                      prop: "",
                      "search-key": "auction_name",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(scope.row.auction_name) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3241527758
                    ),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "上拍场次",
                      "min-width": "120",
                      prop: "",
                      "show-overflow-tooltip": "",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.historyPmVenue &&
                              scope.row.historyPmVenue.length
                                ? _c("div", { staticClass: "nowrap" }, [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.historyPmVenue
                                          .map(function (m) {
                                            return m.title
                                          })
                                          .join(",")
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1739467970
                    ),
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.erpsettingList, function (item, index) {
                    return [
                      item.types == "erp_v2_contract_create_product"
                        ? [
                            item.newtype == "attribute"
                              ? [
                                  item.name.indexOf("尺寸") != -1
                                    ? [
                                        _c("el-table-column", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            "show-overflow-tooltip": "",
                                            "min-width": "100",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("div", {
                                                      staticClass: "nowrap cur",
                                                      staticStyle: {
                                                        height: "100%",
                                                      },
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          scope.row.attribute[
                                                            item.field
                                                          ]
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ]
                                    : [
                                        _c("el-table-column", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            "show-overflow-tooltip": "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      { staticClass: "nowrap" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row.attribute[
                                                              item.field
                                                            ]
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                ]
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      item.types == "store_attribute"
                        ? [
                            item.name.indexOf("尺寸") != -1
                              ? [
                                  _c("el-table-column", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      "show-overflow-tooltip": "",
                                      "min-width": "100",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("div", {
                                                staticClass: "nowrap cur",
                                                staticStyle: { height: "100%" },
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    scope.row.attribute[
                                                      item.field
                                                    ]
                                                  ),
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              : [
                                  _c("el-table-column", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      "show-overflow-tooltip": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "nowrap" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.attribute[
                                                        item.field
                                                      ]
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                          ]
                        : _vm._e(),
                    ]
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "操作",
                      width: "60",
                      fixed: "right",
                      align: "center",
                      "search-clear": true,
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "font-size": "14px" },
                                  attrs: { type: "text", size: "small" },
                                  on: { click: _vm.saleGoodsAll },
                                },
                                [_vm._v(" 全选 ")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.saleGoods(
                                            scope.row,
                                            scope.$index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.goodsIdArr.indexOf(
                                              scope.row.id
                                            ) > -1
                                              ? "已选择"
                                              : "选择"
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2744751282
                    ),
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isQuick
            ? _c(
                "div",
                { staticClass: "block paginaBox" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [20, 40, 60, 80],
                      "page-size": _vm.tableFrom.limit,
                      "current-page": _vm.tableFrom.page,
                      layout: "total, prev, pager, next, sizes",
                      total: _vm.tableData.total,
                      background: "",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("AddUserCom", { ref: "userREf" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.showVisible,
            width: "750px",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "top" } },
            [
              _vm._l(_vm.formArr, function (item, ind) {
                return _c(
                  "div",
                  { key: ind, staticClass: "formLine" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "15px" },
                        attrs: { label: "输入:" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "el-input",
                              {
                                staticStyle: {
                                  width: "155px",
                                  "margin-right": "15px",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onChangeSize(item)
                                  },
                                },
                                model: {
                                  value: item.long,
                                  callback: function ($$v) {
                                    _vm.$set(item, "long", $$v)
                                  },
                                  expression: "item.long",
                                },
                              },
                              [
                                _c("template", { slot: "prepend" }, [
                                  _vm._v("长(L)"),
                                ]),
                                _vm._v(" "),
                                _c("template", { slot: "append" }, [
                                  _vm._v("cm"),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: {
                                  width: "155px",
                                  "margin-right": "15px",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onChangeSize(item)
                                  },
                                },
                                model: {
                                  value: item.width,
                                  callback: function ($$v) {
                                    _vm.$set(item, "width", $$v)
                                  },
                                  expression: "item.width",
                                },
                              },
                              [
                                _c("template", { slot: "prepend" }, [
                                  _vm._v("宽(W)"),
                                ]),
                                _vm._v(" "),
                                _c("template", { slot: "append" }, [
                                  _vm._v("cm"),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: {
                                  width: "155px",
                                  "margin-right": "15px",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onChangeSize(item)
                                  },
                                },
                                model: {
                                  value: item.height,
                                  callback: function ($$v) {
                                    _vm.$set(item, "height", $$v)
                                  },
                                  expression: "item.height",
                                },
                              },
                              [
                                _c("template", { slot: "prepend" }, [
                                  _vm._v("高(H)"),
                                ]),
                                _vm._v(" "),
                                _c("template", { slot: "append" }, [
                                  _vm._v("cm"),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "185px" },
                                on: {
                                  change: function ($event) {
                                    return _vm.onChangeSize(item)
                                  },
                                },
                                model: {
                                  value: item.diam,
                                  callback: function ($$v) {
                                    _vm.$set(item, "diam", $$v)
                                  },
                                  expression: "item.diam",
                                },
                              },
                              [
                                _c("template", { slot: "prepend" }, [
                                  _vm._v("直径(D)"),
                                ]),
                                _vm._v(" "),
                                _c("template", { slot: "append" }, [
                                  _vm._v("cm"),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "15px" },
                        attrs: { label: "展示:" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c("el-input", {
                              staticClass: "flex-one sortCol",
                              staticStyle: { "margin-right": "5px" },
                              model: {
                                value: item.sizeStr,
                                callback: function ($$v) {
                                  _vm.$set(item, "sizeStr", $$v)
                                },
                                expression: "item.sizeStr",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "flex align-items-c" },
                              [
                                !item.height && !item.diam
                                  ? _c("div", [_vm._v("平尺小数点:")])
                                  : _vm._e(),
                                _vm._v(" "),
                                !item.height && !item.diam
                                  ? _c(
                                      "el-select",
                                      {
                                        staticClass: "sortCol",
                                        staticStyle: {
                                          height: "36px",
                                          width: "80px",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onChangeSize(item)
                                          },
                                        },
                                        model: {
                                          value: item.toNum,
                                          callback: function ($$v) {
                                            _vm.$set(item, "toNum", $$v)
                                          },
                                          expression: "item.toNum",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { value: 0, label: "0位" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 1, label: "1位" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 2, label: "2位" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 3, label: "3位" },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", [_vm._v("数量:")]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "sortCol",
                                  staticStyle: {
                                    height: "36px",
                                    width: "40px",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onChangeSize(item)
                                    },
                                  },
                                  model: {
                                    value: item.vice,
                                    callback: function ($$v) {
                                      _vm.$set(item, "vice", $$v)
                                    },
                                    expression: "item.vice",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-right": "5px" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        !item.height && !item.diam ? "幅" : "件"
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("el-switch", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.onChangeSize(item)
                                    },
                                  },
                                  model: {
                                    value: item.showVice,
                                    callback: function ($$v) {
                                      _vm.$set(item, "showVice", $$v)
                                    },
                                    expression: "item.showVice",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "text", icon: "el-icon-circle-plus-outline" },
                  on: { click: _vm.onAdd },
                },
                [_vm._v("继续增加")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex justify-e" },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }