var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "400px",
        visible: _vm.visible,
        "show-close": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("div", { staticClass: "poster-dialog" }, [
          _vm._v(_vm._s(!_vm.isUpd ? "添加标签" : "设置标签") + "\n      "),
          _c("img", {
            staticClass: "posterImg",
            attrs: {
              src: require("@/assets/images/poster/close.png"),
              alt: "",
            },
            on: { click: _vm.onClose },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "poster-content" }, [
        _c("div", { staticClass: "flex" }, [
          _c(
            "div",
            { staticClass: "flex-one" },
            [
              _c("div", { staticClass: "poster-formtit" }, [
                _vm._v("标签名称"),
              ]),
              _vm._v(" "),
              !_vm.isUpd
                ? _c("el-input", {
                    staticStyle: { width: "100%" },
                    attrs: { placeholder: "标签名称" },
                    model: {
                      value: _vm.label_name,
                      callback: function ($$v) {
                        _vm.label_name = $$v
                      },
                      expression: "label_name",
                    },
                  })
                : _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "标签名称", multiple: "" },
                      model: {
                        value: _vm.labelArr,
                        callback: function ($$v) {
                          _vm.labelArr = $$v
                        },
                        expression: "labelArr",
                      },
                    },
                    _vm._l(_vm.labelList, function (item) {
                      return _c("el-option", {
                        key: item.label_id,
                        attrs: { label: item.label_name, value: item.label_id },
                      })
                    }),
                    1
                  ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "poster-foot" }, [
        _c("div", { staticClass: "poster-btn", on: { click: _vm.onSubmit } }, [
          _vm._v("确定"),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }