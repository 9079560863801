"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _artai = require("@/api/artai");
var _basic = require("@/api/basic");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      visible: false,
      isUpd: false,
      label_name: '',
      labelList: [],
      labelArr: []
    };
  },
  created: function created() {},
  methods: {
    openUpdTag: function openUpdTag() {
      var _this = this;
      (0, _basic.labelLstApi)({
        page: 1,
        limit: 999,
        type: 2
      }).then(function (res) {
        _this.isUpd = true;
        _this.labelList = res.data.list;
        _this.visible = true;
      });
    },
    onSubmit: function onSubmit() {
      var _this2 = this;
      if (this.isUpd) {
        this.$emit('handTag', this.labelArr);
        this.visible = false;
      } else {
        (0, _artai.labelCreateApi)({
          label_name: this.label_name,
          type: 2
        }).then(function (res) {
          _this2.visible = false;
          _this2.$message.success('操作成功');
          _this2.$emit('onRefTag');
        });
      }
    },
    openPoster: function openPoster() {
      this.isUpd = false;
      this.visible = true;
    },
    onClose: function onClose() {
      this.visible = false;
    }
  }
};