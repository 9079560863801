"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
var _aiMark = require("@/api/aiMark");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {},
  data: function data() {
    return {
      routerUrl: '1',
      ruleData: {},
      voiceList: [],
      defineQrcodeData: [{
        "key": "zcs",
        "name": "1:1正常色",
        "imgUrl": ""
      }, {
        "key": "ss",
        "name": "1:1深色",
        "imgUrl": ""
      }, {
        "key": "qs",
        "name": "1:1浅色",
        "imgUrl": ""
      }, {
        "key": "hzcs",
        "name": "横版正常色",
        "imgUrl": ""
      }, {
        "key": "hss",
        "name": "横版深色",
        "imgUrl": ""
      }, {
        "key": "hqs",
        "name": "横版浅色",
        "imgUrl": ""
      }, {
        "key": "szcs",
        "name": "竖版正常色",
        "imgUrl": ""
      }, {
        "key": "sss",
        "name": "竖版深色",
        "imgUrl": ""
      }, {
        "key": "sqs",
        "name": "竖版浅色",
        "imgUrl": ""
      }]
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _aiMark.getMarketMediaConfigApi)().then(function (res) {
      // console.log(res);
      if (res.data.marketPosterLogo) _this.defineQrcodeData = JSON.parse(res.data.marketPosterLogo);
      _this.ruleData = res.data;
    });
    (0, _aiMark.iceVideoVoiceListApi)().then(function (res) {
      _this.voiceList = res.data.list;
    });
  },
  methods: {
    modalPicImg: function modalPicImg(filed) {
      var _this2 = this;
      this.$modalUpload(function (img) {
        var index = _this2.defineQrcodeData.findIndex(function (item) {
          return item.key == filed;
        });
        _this2.$set(_this2.defineQrcodeData[index], 'imgUrl', img[0]);
      }, 1);
    },
    changeTab: function changeTab() {},
    saveAction: function saveAction() {
      var _this3 = this;
      this.ruleData.marketPosterLogo = JSON.stringify(this.defineQrcodeData);
      (0, _aiMark.saveMarketMediaConfigApi)(this.ruleData).then(function (res) {
        _this3.$message.success('设置成功');
      });
    }
  }
};