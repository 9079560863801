"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _basic = require("@/api/basic");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      inventoryConfig: {
        switch: {
          other_price_switch: 0,
          sold_price_switch: 0,
          unsold_price_switch: 0
        },
        price: {
          other_price: [{
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }],
          sold_price: [{
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }],
          unsold_price: [{
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }, {
            name: "",
            number: "",
            unit: 1
          }]
        }
      }
    };
  },
  mounted: function mounted() {
    this.getconfig();
  },
  methods: {
    getconfig: function getconfig() {
      var _this = this;
      (0, _basic.contractConfig)().then(function (res) {
        console.log(res);
        if (res.status == 200) {
          if (res.data.price && res.data.price.sold_price && res.data.price.sold_price.length > 1) {
            if (res.data.price.sold_price.length == 3) res.data.price.sold_price.push({
              name: "",
              number: "",
              unit: 1
            });
            _this.inventoryConfig = res.data;
            _this.configflag = true;
            _this.$forceUpdate();
          }
        }
      });
    },
    saveconfig: function saveconfig() {
      var _this2 = this;
      (0, _basic.editcontractConfig)({
        entrust_order_field_config: JSON.stringify(this.inventoryConfig)
      }).then(function (res) {
        if (res.status == 200) {
          _this2.showconfig = false;
          _this2.$message.success("保存成功");
        }
      });
    }
  }
};