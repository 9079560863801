var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showconfig,
            direction: "rtl",
            width: "700px",
            title: "字段配置",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showconfig = $event
            },
          },
        },
        [
          _vm.types == "erp_v2_product_list"
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "template-list flex ft16",
                    staticStyle: { color: "#000" },
                  },
                  [
                    _c("div", { staticClass: "t fWeight500" }, [
                      _vm._v("导航模板："),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex-one flex flex-wrap" },
                      _vm._l(_vm.tempList, function (m, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "item",
                            on: {
                              click: function ($event) {
                                return _vm.changeTemplateAction(m.template_id)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "txt" }, [
                              _vm._v(_vm._s(m.template_name)),
                            ]),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "icon",
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/merchant/333425/2025116/image/1737037713116454013.png",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delTemplateAction(m.template_id)
                                },
                              },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "flex edit-config" }, [
            _c("div", { staticClass: "flex-one" }, [
              _c("div", { staticClass: "t ft14 fWeight500" }, [
                _vm._v("已选择"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "c" },
                [
                  _c(
                    "draggable",
                    _vm._b(
                      {
                        attrs: { list: _vm.showList, "set-data": _vm.setData },
                      },
                      "draggable",
                      _vm.$attrs,
                      false
                    ),
                    _vm._l(_vm.showList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: "show_" + index,
                          staticClass: "draw-list",
                          attrs: { draggable: "false" },
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "mb2",
                            attrs: { "icon-class": "drag_new" },
                          }),
                          _vm._v(" "),
                          _c("el-checkbox", {
                            staticClass: "checkbox",
                            on: {
                              change: function ($event) {
                                return _vm.changeselected(index, "show")
                              },
                            },
                            model: {
                              value: item.select,
                              callback: function ($$v) {
                                _vm.$set(item, "select", $$v)
                              },
                              expression: "item.select",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "name ml5 flex",
                              staticStyle: {
                                "justify-content": "space-between",
                              },
                            },
                            [
                              _c("span", [_vm._v(_vm._s(item.name))]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    item.attr_type > 0 ? "属性" : "系统字段"
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-one ml40" }, [
              _c("div", { staticClass: "t ft14 fWeight500" }, [
                _vm._v("未选择"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "c" },
                _vm._l(_vm.hideList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: "hide_" + index,
                      staticClass: "draw-list",
                      attrs: { draggable: "false" },
                    },
                    [
                      _c("el-checkbox", {
                        staticClass: "checkbox",
                        on: {
                          change: function ($event) {
                            return _vm.changeselected(index, "hide")
                          },
                        },
                        model: {
                          value: item.select,
                          callback: function ($$v) {
                            _vm.$set(item, "select", $$v)
                          },
                          expression: "item.select",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "name ml5 flex",
                          staticStyle: { "justify-content": "space-between" },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(item.name))]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(item.attr_type > 0 ? "属性" : "系统字段")
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt40 mb20" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.saveheadervonfig },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.saveTemplate },
                },
                [_vm._v("另存模板")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showSetName,
            direction: "rtl",
            width: "400px",
            title: "设置模版名称",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSetName = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: { placeholder: "输入模板名称" },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveAction } },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }