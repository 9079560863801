var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "infinite-scroll",
            rawName: "v-infinite-scroll",
            value: _vm.loadmore,
            expression: "loadmore",
          },
        ],
        staticClass: "posterMain",
        attrs: {
          "infinite-scroll-distance": 150,
          "infinite-scroll-immediate": false,
        },
      },
      [
        _c("posterHeader", {
          ref: "posterHeaderEL",
          on: {
            onRefresh: _vm.onRefresh,
            createTemplate: _vm.createTemplate,
            getNumber: _vm.getNumber,
          },
        }),
        _vm._v(" "),
        _c("img", {
          staticStyle: {
            width: "100%",
            "margin-top": "10px",
            display: "block",
          },
          attrs: { src: require("@/assets/images/poster/advert.png"), alt: "" },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "poster-type-container" }, [
          _c(
            "div",
            {
              staticClass: "poster-type-itemOne",
              class: _vm.activeType == "1" ? "type-active" : "",
              on: {
                click: function ($event) {
                  return _vm.typeChange("1")
                },
              },
            },
            [_vm._v("我的收藏 " + _vm._s(_vm.myRelationCount))]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "poster-type-itemOne",
              class: _vm.activeType == "" ? "type-active" : "",
              on: {
                click: function ($event) {
                  return _vm.typeChange("")
                },
              },
            },
            [_vm._v("我的专属 " + _vm._s(_vm.myCustomCount))]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "imgWaterFall",
            staticClass: "flex",
            staticStyle: { "margin-bottom": "20px" },
          },
          [
            _vm.templist.length
              ? _c(
                  "div",
                  { staticClass: "posterEndBox" },
                  _vm._l(_vm.realColumn, function (m, colI) {
                    return _c(
                      "div",
                      {
                        key: "realColumn" + colI,
                        staticClass: "col",
                        style: "width: " + 100 / _vm.realColumn + "%;",
                      },
                      [
                        _vm._l(_vm.templist, function (item, index) {
                          return [
                            index % _vm.realColumn == colI
                              ? [
                                  _c(
                                    "div",
                                    { key: "item_" + colI + "_" + index },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "posterEndItem" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "item",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.previewTemplate(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm.activeType == ""
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "position-img",
                                                      staticStyle: {
                                                        right: "10px",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.goTemplateAction(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          attrs: {
                                                            effect: "dark",
                                                            content: "编辑",
                                                            placement: "top",
                                                            enterable: false,
                                                          },
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("@/assets/images/poster/upd.png"),
                                                              alt: "",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : [
                                                    _vm.type_ids.includes(
                                                      item.template_id + ""
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "position-img",
                                                            staticStyle: {
                                                              right: "10px",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-tooltip",
                                                              {
                                                                attrs: {
                                                                  effect:
                                                                    "dark",
                                                                  content:
                                                                    "取消收藏",
                                                                  placement:
                                                                    "top",
                                                                  enterable: false,
                                                                },
                                                              },
                                                              [
                                                                _c("img", {
                                                                  attrs: {
                                                                    src: require("@/assets/images/poster/sc.png"),
                                                                    alt: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.onDelCollect(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "position-img",
                                                            staticStyle: {
                                                              right: "10px",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-tooltip",
                                                              {
                                                                attrs: {
                                                                  effect:
                                                                    "dark",
                                                                  content:
                                                                    "收藏",
                                                                  placement:
                                                                    "top",
                                                                  enterable: false,
                                                                },
                                                              },
                                                              [
                                                                _c("img", {
                                                                  attrs: {
                                                                    src: require("@/assets/images/poster/scBlack.png"),
                                                                    alt: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.onCollect(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                  ],
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "sameBtn",
                                                  staticStyle: {
                                                    right: "50px",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.onClickTempl(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("做同款")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "cover" },
                                                [
                                                  _c("i", {
                                                    style: {
                                                      backgroundImage:
                                                        "url(" +
                                                        item.image +
                                                        ")",
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "name line2" },
                                                [_vm._v(_vm._s(item.title))]
                                              ),
                                              _vm._v(" "),
                                              item.config_data &&
                                              item.config_data.digest
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "desc line2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.config_data
                                                            .digest
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "time" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "l" },
                                                    [
                                                      _vm._v(
                                                        "更新于 " +
                                                          _vm._s(
                                                            item.update_time
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "other-data" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  item.templateTagText
                                                    ? item.templateTagText +
                                                        " | "
                                                    : ""
                                                ) + _vm._s(item.create_time)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.loadTemplate && _vm.templist.length == 0
              ? _c("div", { staticClass: "no-data" }, [_vm._v("暂无模版")])
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.visible,
              title: " ",
              width: "430px",
              top: "5vh",
              "custom-class": "dialog-hide-bg",
              "show-close": false,
              "close-on-click-modal": false,
              "before-close": _vm.closeAction,
            },
          },
          [
            _vm.visible
              ? _c("preview", {
                  attrs: {
                    pages: _vm.previewData,
                    temid: _vm.previewDetail.template_id,
                    title: _vm.previewDetail.title,
                    "page-info": _vm.previewDetail.config_data,
                    price: _vm.previewDetail.template_price,
                    type: "outer",
                  },
                  on: { closeAction: _vm.closeAction },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }