var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    { attrs: { title: _vm.content.show_name } },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "80px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "item-align-center mt20" },
                [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("类型"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.content.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.content, "type", $$v)
                        },
                        expression: "content.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("公众号"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("视频号"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.content.type == 1
                ? [
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                        _vm._v("公众号二维码"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex-one" }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.uploadIconAction(5)
                            },
                          },
                        },
                        [
                          _vm.content.ewm_link
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg(5)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.content.ewm_link
                            ? _c("img", {
                                attrs: { src: _vm.content.ewm_link + "!120a" },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                    ]),
                  ]
                : _vm.content.type == 2
                ? [
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                        _vm._v("视频号ID"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex-one" }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "flex-one" },
                        [
                          _c("el-input", {
                            attrs: { type: "text", placeholder: "输入地址" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                              },
                            },
                            model: {
                              value: _vm.content.link,
                              callback: function ($$v) {
                                _vm.$set(_vm.content, "link", $$v)
                              },
                              expression: "content.link",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-align-center mt20" },
                [
                  _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                    _vm._v("展示风格"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "le-radio-group",
                      model: {
                        value: _vm.facade.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "type", $$v)
                        },
                        expression: "facade.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("模块展示"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("悬浮展示"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.facade.type === 1
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "mt20", attrs: { label: "展示标题" } },
                        [
                          _c(
                            "div",
                            { staticClass: "item-align-center" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#009406",
                                  "inactive-color": "#cccccc",
                                },
                                model: {
                                  value: _vm.content.show_title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.content, "show_title", $$v)
                                  },
                                  expression: "content.show_title",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.content.show_title
                        ? _c("div", { staticClass: "item-align-center mt20" }, [
                            _c(
                              "div",
                              { staticClass: "w80 fontW400 ft14 color-black" },
                              [_vm._v("设置标题")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "upload-img-view item-flex-center",
                                on: {
                                  click: function ($event) {
                                    return _vm.uploadIconAction(1)
                                  },
                                },
                              },
                              [
                                _vm.content.title_icon
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "del-upload-img",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.delImg(1)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.content.title_icon
                                  ? _c("img", {
                                      attrs: {
                                        src: _vm.content.title_icon + "!120a",
                                      },
                                    })
                                  : _c("img", {
                                      staticClass: "upload-icon",
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                        alt: "",
                                      },
                                    }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "flex-one ml20" },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder: "输入标题",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                    },
                                  },
                                  model: {
                                    value: _vm.content.title_txt,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.content, "title_txt", $$v)
                                    },
                                    expression: "content.title_txt",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("设置内容")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "upload-img-view item-flex-center",
                            staticStyle: { width: "90px", height: "90px" },
                            on: {
                              click: function ($event) {
                                return _vm.uploadIconAction(2)
                              },
                            },
                          },
                          [
                            _vm.content.logo
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "del-upload-img",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg(2)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.content.logo
                              ? _c("img", {
                                  staticStyle: {
                                    width: "90px",
                                    height: "90px",
                                  },
                                  attrs: { src: _vm.content.logo + "!120a" },
                                })
                              : _c("img", {
                                  staticClass: "upload-icon",
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                    alt: "",
                                  },
                                }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex-one ml20" }, [
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: "输入标题",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                  },
                                },
                                model: {
                                  value: _vm.content.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.content, "name", $$v)
                                  },
                                  expression: "content.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "mt10" },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: "输入简介",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                  },
                                },
                                model: {
                                  value: _vm.content.desc,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.content, "desc", $$v)
                                  },
                                  expression: "content.desc",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-align-center mt20" }, [
                        _c(
                          "div",
                          { staticClass: "w80 fontW400 ft14 color-black" },
                          [_vm._v("按钮文字")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex-one" },
                          [
                            _c("el-input", {
                              attrs: { type: "text", placeholder: "输入标题" },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                },
                              },
                              model: {
                                value: _vm.content.button_txt,
                                callback: function ($$v) {
                                  _vm.$set(_vm.content, "button_txt", $$v)
                                },
                                expression: "content.button_txt",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm.facade.type === 2
                ? _c("div", [
                    _c("div", { staticClass: "item-align-center mt20" }, [
                      _c(
                        "div",
                        { staticClass: "w80 fontW400 ft14 color-black" },
                        [_vm._v("设置内容")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center",
                          on: {
                            click: function ($event) {
                              return _vm.uploadIconAction(4)
                            },
                          },
                        },
                        [
                          _vm.content.title_icon2
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg(4)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.content.title_icon2
                            ? _c("img", {
                                attrs: {
                                  src: _vm.content.title_icon2 + "!120a",
                                },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-img-view item-flex-center ml10",
                          on: {
                            click: function ($event) {
                              return _vm.uploadIconAction(3)
                            },
                          },
                        },
                        [
                          _vm.content.title_icon_right
                            ? _c(
                                "div",
                                {
                                  staticClass: "del-upload-img",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delImg(3)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.content.title_icon_right
                            ? _c("img", {
                                attrs: {
                                  src: _vm.content.title_icon_right + "!120a",
                                },
                              })
                            : _c("img", {
                                staticClass: "upload-icon",
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                  alt: "",
                                },
                              }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex-one ml20" },
                        [
                          _c("el-input", {
                            attrs: { type: "text", placeholder: "输入内容" },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                              },
                            },
                            model: {
                              value: _vm.content.title_txt,
                              callback: function ($$v) {
                                _vm.$set(_vm.content, "title_txt", $$v)
                              },
                              expression: "content.title_txt",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _vm.facade.type === 2
            ? _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.facade,
                    "label-width": "80px",
                    size: "mini",
                    "label-position": "left",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "位置" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "le-radio-group",
                          model: {
                            value: _vm.facade.position,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "position", $$v)
                            },
                            expression: "facade.position",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("居左"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("居右"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "距底距离" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 600 },
                        model: {
                          value: _vm.facade.bottom_margin,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "bottom_margin", $$v)
                          },
                          expression: "facade.bottom_margin",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "圆角" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.bottom_radius,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "bottom_radius", $$v)
                          },
                          expression: "facade.bottom_radius",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "背景颜色" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#FFFFFF" },
                        model: {
                          value: _vm.facade.bottom_background,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "bottom_background", $$v)
                          },
                          expression: "facade.bottom_background",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "边框颜色" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#FFFFFF" },
                        model: {
                          value: _vm.facade.bottom_border,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "bottom_border", $$v)
                          },
                          expression: "facade.bottom_border",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "颜色" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#003d7c" },
                        model: {
                          value: _vm.facade.bottom_color,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "bottom_color", $$v)
                          },
                          expression: "facade.bottom_color",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "h30" }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "文字大小" } },
                    [
                      _c("slider", {
                        attrs: { min: 10, max: 100 },
                        model: {
                          value: _vm.facade.bottom_size,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "bottom_size", $$v)
                          },
                          expression: "facade.bottom_size",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "文字风格" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "le-radio-group",
                          model: {
                            value: _vm.facade.bottom_style,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "bottom_style", $$v)
                            },
                            expression: "facade.bottom_style",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 400 } }, [
                            _vm._v("常规"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 500 } }, [
                            _vm._v("加粗"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 300 } }, [
                            _vm._v("加细"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.facade.type === 1
            ? _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.facade,
                    "label-width": "80px",
                    size: "mini",
                    "label-position": "left",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "左右边距" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.page_padding,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "page_padding", $$v)
                          },
                          expression: "facade.page_padding",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "圆角" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.page_radius,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "page_radius", $$v)
                          },
                          expression: "facade.page_radius",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "背景颜色" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#FFFFFF" },
                        model: {
                          value: _vm.facade.background_color,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "background_color", $$v)
                          },
                          expression: "facade.background_color",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "边框颜色" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#FFFFFF" },
                        model: {
                          value: _vm.facade.border_color,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "border_color", $$v)
                          },
                          expression: "facade.border_color",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "h30" }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "标题颜色" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#003d7c" },
                        model: {
                          value: _vm.facade.title_color,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "title_color", $$v)
                          },
                          expression: "facade.title_color",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "距左距离" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 375 },
                        model: {
                          value: _vm.facade.title_margin_left,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "title_margin_left", $$v)
                          },
                          expression: "facade.title_margin_left",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "距上距离" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.title_margin_top,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "title_margin_top", $$v)
                          },
                          expression: "facade.title_margin_top",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "文字大小" } },
                    [
                      _c("slider", {
                        attrs: { min: 10, max: 100 },
                        model: {
                          value: _vm.facade.title_size,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "title_size", $$v)
                          },
                          expression: "facade.title_size",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "文字风格" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "le-radio-group",
                          model: {
                            value: _vm.facade.title_style,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "title_style", $$v)
                            },
                            expression: "facade.title_style",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 400 } }, [
                            _vm._v("常规"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 500 } }, [
                            _vm._v("加粗"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 300 } }, [
                            _vm._v("加细"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "h30" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "item-align-center mt20" }, [
                    _c(
                      "div",
                      { staticClass: "w80 fontW400 ft14 color-black" },
                      [_vm._v("图标宽高")]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "item-align-center" },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "80px" },
                              attrs: { type: "text", placeholder: "宽" },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                },
                              },
                              model: {
                                value: _vm.facade.img_width,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "img_width", $$v)
                                },
                                expression: "facade.img_width",
                              },
                            },
                            [
                              _c("template", { slot: "suffix" }, [
                                _vm._v("px"),
                              ]),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: { margin: "0 6px" },
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211013/33a849246582240105bfeb1ca2d2ff16.png",
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "80px" },
                              attrs: { type: "text", placeholder: "宽" },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                },
                              },
                              model: {
                                value: _vm.facade.img_height,
                                callback: function ($$v) {
                                  _vm.$set(_vm.facade, "img_height", $$v)
                                },
                                expression: "facade.img_height",
                              },
                            },
                            [
                              _c("template", { slot: "suffix" }, [
                                _vm._v("px"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "图标圆角" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 375 },
                        model: {
                          value: _vm.facade.img_radius,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "img_radius", $$v)
                          },
                          expression: "facade.img_radius",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "距左距离" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.img_margin_left,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "img_margin_left", $$v)
                          },
                          expression: "facade.img_margin_left",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "距上距离" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.img_margin_top,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "img_margin_top", $$v)
                          },
                          expression: "facade.img_margin_top",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "h30" }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "名称颜色" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#003d7c" },
                        model: {
                          value: _vm.facade.name_color,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "name_color", $$v)
                          },
                          expression: "facade.name_color",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "距左距离" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 375 },
                        model: {
                          value: _vm.facade.name_margin_left,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "name_margin_left", $$v)
                          },
                          expression: "facade.name_margin_left",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "距上距离" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.name_margin_top,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "name_margin_top", $$v)
                          },
                          expression: "facade.name_margin_top",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "文字大小" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.name_size,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "name_size", $$v)
                          },
                          expression: "facade.name_size",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "文字风格" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "le-radio-group",
                          model: {
                            value: _vm.facade.name_style,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "name_style", $$v)
                            },
                            expression: "facade.name_style",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 400 } }, [
                            _vm._v("常规"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 500 } }, [
                            _vm._v("加粗"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 300 } }, [
                            _vm._v("加细"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "h30" }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "介绍颜色" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#003d7c" },
                        model: {
                          value: _vm.facade.desc_color,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "desc_color", $$v)
                          },
                          expression: "facade.desc_color",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "距上距离" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.desc_margin_top,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "desc_margin_top", $$v)
                          },
                          expression: "facade.desc_margin_top",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "文字大小" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.desc_size,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "desc_size", $$v)
                          },
                          expression: "facade.desc_size",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "文字风格" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "le-radio-group",
                          model: {
                            value: _vm.facade.desc_style,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "desc_style", $$v)
                            },
                            expression: "facade.desc_style",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 400 } }, [
                            _vm._v("常规"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 500 } }, [
                            _vm._v("加粗"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 300 } }, [
                            _vm._v("加细"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "h30" }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "按钮背景" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#003d7c" },
                        model: {
                          value: _vm.facade.button_background_color,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "button_background_color", $$v)
                          },
                          expression: "facade.button_background_color",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "按钮文字" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#003d7c" },
                        model: {
                          value: _vm.facade.button_color,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "button_color", $$v)
                          },
                          expression: "facade.button_color",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "距左距离" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 375 },
                        model: {
                          value: _vm.facade.button_margin_left,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "button_margin_left", $$v)
                          },
                          expression: "facade.button_margin_left",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "距上距离" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.button_margin_top,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "button_margin_top", $$v)
                          },
                          expression: "facade.button_margin_top",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "按钮圆角" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.button_radius,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "button_radius", $$v)
                          },
                          expression: "facade.button_radius",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "文字大小" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.button_size,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "button_size", $$v)
                          },
                          expression: "facade.button_size",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "文字风格" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "le-radio-group",
                          model: {
                            value: _vm.facade.button_desc_style,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "button_desc_style", $$v)
                            },
                            expression: "facade.button_desc_style",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 400 } }, [
                            _vm._v("常规"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 500 } }, [
                            _vm._v("加粗"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 300 } }, [
                            _vm._v("加细"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }