"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _index = require("@/utils/index");
var _system = require("@/api/system");
var _auction = require("@/api/auction");
var _store = _interopRequireDefault(require("@/store"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'App',
  data: function data() {
    return {
      code: '',
      signSync: false,
      isSend: false,
      count: 60,
      adminPhone: '',
      excelData: {}
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    getPhoneCode: function getPhoneCode(state) {
      return state.permission.getPhoneCode;
    }
  })),
  watch: {
    getPhoneCode: function getPhoneCode(val, oldVal) {
      if (val && !oldVal) {
        this.loadInit();
        var eData = localStorage.getItem('excelData');
        if (eData) {
          this.excelData = JSON.parse(eData);
        }
        _store.default.dispatch('permission/getPhoneCode', false);
      }
    } // $route(to, from) {
    //   let gopath = to.path.replace('/merchant', '');
    //   if(gopath!='/404' && gopath!='/' && gopath !="/login" && gopath.indexOf('/recentlyLaunched')==-1 && gopath.indexOf('/nolimit')==-1){
    //     if(!this.$store.getters.userPression[gopath]){
    //       this.$router.push('/merchant/nolimit/nolimit');
    //     }
    //   }
    // }
  },
  created: function created() {
    // console.log(this.$store.getters.userPression);
    // window.addEventListener('resize', this.handleResize);
    this.$store.dispatch('user/getFontList').then(function (res) {
      if (!window.isLoadFontJs) {
        var fonts = res;
        var style = document.createElement('style');
        style.type = "text/css";
        var text = '';
        for (var i = 0; i < fonts.length; i++) {
          text += " @font-face {\n            font-family:'".concat(fonts[i].name, "';\n            src:url('").concat(fonts[i].show_url, "')\n          }");
        }
        style.innerText = text;
        document.getElementsByTagName('head')[0].appendChild(style);
      }
      window.isLoadFontJs = true;
    });
  },
  // destroyed() {
  //   window.removeEventListener('resize', this.handleResize);
  // },
  methods: {
    // handleResize() {
    //   this.$store.dispatch('user/set_merchant_logo', window.innerHeight);
    // },
    loadInit: function loadInit() {
      var _this = this;
      (0, _auction.proxyPlatformSignStatusApi)().then(function (res) {
        _this.adminPhone = res.data.adminPhone;
      });
      this.signSync = true;
    },
    sendMsg: function sendMsg() {
      var _this2 = this;
      if (this.isSend) return;
      (0, _system.sendVerificationCodeApi)({
        export_type: this.excelData.export_type
      }).then();
      this.isSend = true;
      (0, _index.countDown)(60, function (h, m, s) {
        if (s) {
          _this2.count = s;
        } else {
          _this2.isSend = false;
        }
      });
    },
    confirmSign: function confirmSign() {
      var _this3 = this;
      if (!this.code) {
        this.$message.error('请输入验证码');
        return;
      }
      if (isNaN(this.code)) {
        this.$message.error('验证码只能为数字');
        return;
      }
      if (this.lock) return;
      this.lock = true;
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.excelData.sms_code = this.code;
      (0, _system.exportexcel)(this.excelData).then(function (res) {
        _this3.signSync = false;
        _this3.code = '';
        loading.close();
        localStorage.setItem('excelData', '');
        var h = _this3.$createElement;
        _this3.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this3.$msgbox.close();
                // this.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
        _this3.lock = false;
      }).catch(function () {
        loading.close();
        localStorage.setItem('excelData', '');
        _this3.signSync = false;
        _this3.code = '';
        _this3.lock = false;
      });
    }
  }
};