var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt20" },
    [
      _c(
        "div",
        { staticStyle: { padding: "0 20px" } },
        [
          _c(
            "div",
            {
              staticClass: "sign flex align-items-c",
              on: { click: _vm.showSignAction },
            },
            [
              _vm.status === 1
                ? [
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024425/image/1714027634559888153.png!120a",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v("已签署《艺天下开放平台云代拍接口技术服务协议》"),
                    ]),
                  ]
                : _vm.status === 0
                ? [
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024425/image/1714028080281534922.png!120a",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "no-sign" }, [
                      _vm._v(
                        "点击签署《艺天下开放平台云代拍接口技术服务协议》"
                      ),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "mt20 ft12",
              attrs: {
                data: _vm.list,
                "element-loading-text": "Loading",
                fit: "",
                "highlight-current-row": "",
                "span-method": _vm.objectSpanMethod,
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "专场封面", width: "95" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c("div", { staticStyle: { "margin-left": "-10px" } }, [
                          _vm._v("专场封面"),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          staticStyle: {
                            width: "70px",
                            "margin-left": "-10px",
                          },
                          attrs: { src: scope.row.album_image + "!120a" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "专场名称",
                  "min-width": "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            scope.row.live_id ? [_vm._v("【直播】")] : _vm._e(),
                            _vm._v(_vm._s(scope.row.album_name)),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "拍卖时间", "min-width": "165" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            "预展：" +
                              _vm._s(
                                _vm.formatDate(
                                  scope.row.scheduled_show_time * 1000,
                                  "{y}-{m}-{d} {h}:{i}"
                                )
                              )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "开拍：" +
                              _vm._s(
                                _vm.formatDate(
                                  scope.row.album_start_time * 1000,
                                  "{y}-{m}-{d} {h}:{i}"
                                )
                              )
                          ),
                        ]),
                        _vm._v(" "),
                        scope.row.album_type === 1 &&
                        scope.row.end_type === 2 &&
                        scope.row.album_status !== 3
                          ? _c("div", [
                              _vm._v(
                                "结拍：" +
                                  _vm._s(
                                    _vm.formatDate(
                                      scope.row.first_end_time * 1000,
                                      "{y}-{m}-{d} {h}:{i}"
                                    )
                                  ) +
                                  " 开始"
                              ),
                            ])
                          : scope.row.album_type === 1
                          ? _c("div", [
                              _vm._v(
                                "结拍：" +
                                  _vm._s(
                                    _vm.formatDate(
                                      scope.row.album_end_time * 1000,
                                      "{y}-{m}-{d} {h}:{i}"
                                    )
                                  )
                              ),
                            ])
                          : scope.row.album_end_time
                          ? _c("div", [
                              _vm._v(
                                "结拍：" +
                                  _vm._s(
                                    _vm.formatDate(
                                      scope.row.album_end_time * 1000,
                                      "{y}-{m}-{d} {h}:{i}"
                                    )
                                  )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "保证金", "min-width": "120", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.bond_type == 2
                          ? [
                              _c("div", [_vm._v("比例保证金")]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v("1:" + _vm._s(scope.row.bond_bid_ratio)),
                              ]),
                            ]
                          : Number(scope.row.venue.bond_price)
                          ? [
                              _c("div", [_vm._v("拍卖会保证金")]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      scope.row.venue.bond_price,
                                      2
                                    )
                                  )
                                ),
                              ]),
                            ]
                          : Number(scope.row.bond_price)
                          ? [
                              _c("div", [_vm._v("专场保证金")]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      scope.row.bond_price,
                                      2
                                    )
                                  )
                                ),
                              ]),
                            ]
                          : [_c("div", [_vm._v("无保证金")])],
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "推数据", "min-width": "80", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.platformData &&
                        scope.row.platformData.await_push_auction_num
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.pushAction(
                                          scope.row.album_id,
                                          scope.row.platformData.appid,
                                          scope.row.platformData.is_pay
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "推数据" +
                                        _vm._s(
                                          scope.row.platformData
                                            .await_push_auction_num
                                        )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : scope.row.platformData &&
                            scope.row.platformData.push_auction_num
                          ? _c("div", [
                              _vm._v(
                                "已推送" +
                                  _vm._s(
                                    scope.row.platformData.push_auction_num
                                  )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "本场代拍",
                  "min-width": "150",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.platformData
                          ? _c(
                              "div",
                              [
                                scope.row.platformData.appInfo
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.platformData.appInfo.name
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" | "),
                                scope.row.platformData.bond_status == 2
                                  ? [_vm._v("已交保证金")]
                                  : scope.row.platformData.bond_status == 1
                                  ? [_vm._v("免保证金")]
                                  : scope.row.platformData.bond_status == 0
                                  ? [_vm._v("未交保证金")]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _c("div", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "本场代拍",
                  "min-width": "80",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.platformData &&
                        scope.row.platformData.await_pull_auction_num
                          ? _c("div", { staticClass: "color-yellow" }, [
                              _vm._v(
                                "待拉取" +
                                  _vm._s(
                                    scope.row.platformData
                                      .await_pull_auction_num
                                  )
                              ),
                            ])
                          : scope.row.platformData &&
                            scope.row.platformData.pull_auction_num
                          ? _c("div", [
                              _vm._v(
                                "已拉取" +
                                  _vm._s(
                                    scope.row.platformData.pull_auction_num
                                  )
                              ),
                            ])
                          : _c("div", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "技术服务费",
                  "min-width": "80",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.platformData && scope.row.platformData.is_pay
                          ? _c("div", [_vm._v("已支付")])
                          : scope.row.platformData
                          ? [
                              _c("span", { staticClass: "color-red" }, [
                                _vm._v("待支付"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.payServicePriceAction(
                                        scope.row.album_id,
                                        scope.row.platformData.appid
                                      )
                                    },
                                  },
                                },
                                [_vm._v("支付")]
                              ),
                            ]
                          : _c("div", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "140",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "ft12 p5-0",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showChangePlatformAction(
                                      scope.row.album_id
                                    )
                                  },
                                },
                              },
                              [_vm._v("设置代拍")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "paginaBox flex", staticStyle: { height: "54px" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-size": _vm.queryParams.limit,
                  "current-page": _vm.queryParams.page,
                  layout: "total, prev, pager, next, sizes",
                  total: _vm.total,
                },
                on: { "current-change": _vm.pageChange },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择本场合作的代拍平台",
            visible: _vm.changePlatform,
            "close-on-click-modal": false,
            center: "",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.changePlatform = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "change-platform" },
            [
              _c("div", { staticClass: "flex align-items-c" }, [
                _c("div", { staticClass: "l flex-one" }, [_vm._v("代拍平台")]),
                _vm._v(" "),
                _c("div", { staticClass: "c" }, [_vm._v("是否免保")]),
                _vm._v(" "),
                _c("div", { staticClass: "r w80 flex justify-end" }, [
                  _vm._v("是否合作"),
                ]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.platformList, function (m, i) {
                return _c(
                  "div",
                  { key: i, staticClass: "flex align-items-c platform-item" },
                  [
                    m.avatar
                      ? _c("div", { staticClass: "icon" }, [
                          _c("img", {
                            attrs: { src: m.avatar + "!120a", alt: "" },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-one name line1" }, [
                      _vm._v(_vm._s(m.name)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: m.isConfig,
                            expression: "m.isConfig",
                          },
                        ],
                        staticClass: "c",
                      },
                      [
                        m.isSet
                          ? _c(
                              "span",
                              {
                                staticClass: "mr10",
                                staticStyle: {
                                  "font-size": "12px",
                                  color: "#666",
                                },
                              },
                              [_vm._v("免保号牌：" + _vm._s(m.numberPlate))]
                            )
                          : [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: m.isSetExemptNumberPlate,
                                    expression: "m.isSetExemptNumberPlate",
                                  },
                                ],
                                staticClass: "w100 mr10",
                                attrs: {
                                  placeholder: "输入号牌",
                                  disabled:
                                    !m.isSetExemptNumberPlate || !m.isConfig,
                                },
                                model: {
                                  value: m.numberPlate,
                                  callback: function ($$v) {
                                    _vm.$set(m, "numberPlate", $$v)
                                  },
                                  expression: "m.numberPlate",
                                },
                              }),
                            ],
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ccc",
                            "active-value": 1,
                            "inactive-value": 0,
                            disabled: !m.isConfig,
                          },
                          model: {
                            value: m.isSetExemptNumberPlate,
                            callback: function ($$v) {
                              _vm.$set(m, "isSetExemptNumberPlate", $$v)
                            },
                            expression: "m.isSetExemptNumberPlate",
                          },
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "r w80 flex justify-end" },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ccc",
                            "active-value": 1,
                            "inactive-value": 0,
                          },
                          model: {
                            value: m.isConfig,
                            callback: function ($$v) {
                              _vm.$set(m, "isConfig", $$v)
                            },
                            expression: "m.isConfig",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt40 mb20" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.changePlatform = false
                        },
                      },
                    },
                    [_vm._v("取消操作")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.sycsAlbum },
                    },
                    [_vm._v("确认设置")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.signSync,
            "close-on-click-modal": false,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.signSync = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "protocol" },
            [
              _c("div", { staticClass: "t" }, [
                _vm._v("艺天下开放平台云代拍接口技术服务协议"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "c" }, [
                _c("div", { staticClass: "flex" }, [
                  _c("div", { staticClass: "l" }, [_vm._v("甲方：")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-one" }, [
                    _vm._v(_vm._s(_vm.companyName)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex" }, [
                  _c("div", { staticClass: "l" }, [_vm._v("乙方：")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-one" }, [
                    _vm._v("北京艺天下文化科技有限公司"),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "t" }, [
                _vm._v("艺天下云代拍API接口服务协议条款如下："),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _c("p", [
                  _vm._v(
                    "1.协议的签署：当甲方同意条款、确认签约后，则表示甲方已完全同意本协议的全部条款。"
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "2.免责声明：乙方提供的服务是：通过API，把甲方的拍卖数据同步给第三方，并且选择跟第三方合作和执行数据同步的操作，都由甲方自行决策、操作完成，所以甲方与第三方合作过程中，乙方不承担任何纠纷和责任。"
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "3.收费方案：乙方提供的API，需要持续投入技术资源与第三方系统协作，保持稳定运行和技术迭代，过程中涉及到人力和云服务等各类成本，为了让云代拍API服务持久良性的运行，所以乙方制定了付费使用方案，即甲方在执行数据同步时，每件拍品支付1元。"
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v("4.扣费方式：每次同步数据时，从系统余额里扣款。"),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "5.数据的说明：执行同步的数据均为甲方已经公开的信息，不涉及保密信息，数据包括拍卖图录、直播推流、最新出价、成交价等。"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.status === 0
                ? [
                    _c("div", { staticClass: "admin-phone-confirm" }, [
                      _vm._v("管理员手机短信确认"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex mt20" }, [
                      _c("div", { staticClass: "phone-confirm flex" }, [
                        _c("div", { staticClass: "phone" }, [
                          _vm._v(_vm._s(_vm.adminPhone)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "code" },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "4",
                                placeholder: "请输入验证码",
                              },
                              model: {
                                value: _vm.code,
                                callback: function ($$v) {
                                  _vm.code = $$v
                                },
                                expression: "code",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "send", on: { click: _vm.sendMsg } },
                          [
                            !_vm.isSend
                              ? _c("span", [_vm._v("发送短信")])
                              : _c("span", [
                                  _vm._v(_vm._s(_vm.count) + "秒后可重发"),
                                ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "protocol-confirm",
                        on: { click: _vm.confirmSign },
                      },
                      [_vm._v("确认签约")]
                    ),
                  ]
                : _vm.status === 1
                ? [
                    _c("div", { staticClass: "admin-phone-confirm finish" }, [
                      _vm._v("已完成签约"),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }