"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/dialogComfirm/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ConfirmFroms',
  components: {
    ConfirmIndex: _index.default
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      visible: false,
      noCancel: false,
      cancelTxt: '',
      confirmTxt: '',
      isPadding: false,
      success: function success() {},
      cancel: function cancel() {}
    };
  },
  watch: {
    // show() {
    //   this.visible = this.show
    // }
  },
  methods: {
    confirmAction: function confirmAction() {
      this.visible = false;
      if (this.success) this.success();
    },
    cancelAction: function cancelAction() {
      this.visible = false;
      if (!this.noCancel) {
        if (this.cancel) this.cancel();
      }
    }
  }
};