var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.facade,
        "label-width": "90px",
        size: "mini",
        "label-position": "left",
      },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "区域外间距" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "radius-list t" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageMarginTop,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageMarginTop", $$v)
                    },
                    expression: "facade.pageMarginTop",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list b" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageMarginBottom,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageMarginBottom", $$v)
                    },
                    expression: "facade.pageMarginBottom",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list l" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageMarginLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageMarginLeft", $$v)
                    },
                    expression: "facade.pageMarginLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list r" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageMarginRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageMarginRight", $$v)
                    },
                    expression: "facade.pageMarginRight",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "区域内间距" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "radius-list t" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pagePaddingTop,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pagePaddingTop", $$v)
                    },
                    expression: "facade.pagePaddingTop",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list b" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pagePaddingBottom,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pagePaddingBottom", $$v)
                    },
                    expression: "facade.pagePaddingBottom",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list l" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pagePaddingLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pagePaddingLeft", $$v)
                    },
                    expression: "facade.pagePaddingLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list r" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pagePaddingRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pagePaddingRight", $$v)
                    },
                    expression: "facade.pagePaddingRight",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "区域圆角" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "radius-list tl" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageRadiusTopLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageRadiusTopLeft", $$v)
                    },
                    expression: "facade.pageRadiusTopLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list tr" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageRadiusTopRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageRadiusTopRight", $$v)
                    },
                    expression: "facade.pageRadiusTopRight",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list bl" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageRadiusBottomLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageRadiusBottomLeft", $$v)
                    },
                    expression: "facade.pageRadiusBottomLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list br" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageRadiusBottomRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageRadiusBottomRight", $$v)
                    },
                    expression: "facade.pageRadiusBottomRight",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "背景颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#F5F5F5" },
            model: {
              value: _vm.facade.pageBackground,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "pageBackground", $$v)
              },
              expression: "facade.pageBackground",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "内容位置" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.align,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "align", $$v)
                },
                expression: "facade.align",
              },
            },
            [
              _c("el-radio", { attrs: { label: "left" } }, [_vm._v("居左")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "center" } }, [_vm._v("居中")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "right" } }, [_vm._v("居右")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "标题颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#999999" },
            model: {
              value: _vm.facade.titleColor,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "titleColor", $$v)
              },
              expression: "facade.titleColor",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "标题字号" } },
        [
          _c("slider", {
            attrs: { min: 12, max: 50 },
            model: {
              value: _vm.facade.titleFontSize,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "titleFontSize", $$v)
              },
              expression: "facade.titleFontSize",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "标题样式" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.titleFontWeight,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "titleFontWeight", $$v)
                },
                expression: "facade.titleFontWeight",
              },
            },
            [
              _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "h30" }),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "位置颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#999999" },
            model: {
              value: _vm.facade.pageColor,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "pageColor", $$v)
              },
              expression: "facade.pageColor",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "位置字号" } },
        [
          _c("slider", {
            attrs: { min: 12, max: 50 },
            model: {
              value: _vm.facade.fontSize,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "fontSize", $$v)
              },
              expression: "facade.fontSize",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "位置样式" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.fontWeight,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "fontWeight", $$v)
                },
                expression: "facade.fontWeight",
              },
            },
            [
              _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "h30" }),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "地址颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#999999" },
            model: {
              value: _vm.facade.addressColor,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "addressColor", $$v)
              },
              expression: "facade.addressColor",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "地址字号" } },
        [
          _c("slider", {
            attrs: { min: 12, max: 50 },
            model: {
              value: _vm.facade.addressFontSize,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "addressFontSize", $$v)
              },
              expression: "facade.addressFontSize",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "地址样式" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.addressontWeight,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "addressontWeight", $$v)
                },
                expression: "facade.addressontWeight",
              },
            },
            [
              _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }