var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "posterMain" },
    [
      _c("posterHeader"),
      _vm._v(" "),
      _c("img", {
        staticStyle: { width: "100%", "margin-top": "10px", display: "block" },
        attrs: { src: require("@/assets/images/poster/advert.png"), alt: "" },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "nodi",
          staticStyle: { "overflow-x": "auto", "margin-top": "20px" },
        },
        [
          _c("div", { staticClass: "poster-type-container" }, [
            _c(
              "div",
              {
                staticClass: "poster-type-item",
                class: _vm.activeTag == "collection" ? "type-active" : "",
                on: {
                  click: function ($event) {
                    return _vm.tagChange("collection")
                  },
                },
              },
              [_vm._v("我的收藏 " + _vm._s(_vm.typeLength))]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "poster-type-item",
                class: _vm.activeTag == "my" ? "type-active" : "",
                on: {
                  click: function ($event) {
                    return _vm.tagChange("my")
                  },
                },
              },
              [_vm._v("我的专属 " + _vm._s(_vm.myLength))]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("grid-manager", {
        staticStyle: { "margin-top": "20px" },
        attrs: {
          gridList: _vm.templist,
          footHeight: 35,
          "is-loading": _vm.loadTemplate,
        },
        on: { loadMore: _vm.onloadMore },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "img-box" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "hb-conStatus",
                        on: {
                          click: function ($event) {
                            return _vm.onClickTempl(row)
                          },
                        },
                      },
                      [_vm._v("做同款")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "position-img",
                        staticStyle: { right: "10px" },
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content: "收藏",
                              placement: "top",
                              enterable: false,
                            },
                          },
                          [
                            _vm.type_ids.includes(row.template_id + "")
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/poster/sc.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDelCollect(row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-image", {
                      staticClass: "posIimg",
                      attrs: {
                        src: "" + row.preview_image,
                        "preview-src-list": [row.preview_image],
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "flex possname" }, [
                  _c("div", { staticClass: "flex-one" }, [
                    _vm._v(_vm._s(row.name)),
                  ]),
                  _vm._v(" "),
                  row.status == 0
                    ? _c("div", { staticClass: "hb-conStatus" }, [
                        _vm._v("已下架"),
                      ])
                    : _c("div", { staticClass: "hb3-conStatus" }, [
                        _vm._v("已上架"),
                      ]),
                ]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("createPoster", {
        ref: "createPosterRef",
        on: { onRefresh: _vm.onRefresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }