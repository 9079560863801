"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _auction = require("@/api/auction");
var _index = _interopRequireDefault(require("@/components/uploadPictureArtai/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UploadFroms',
  components: {
    UploadIndex: _index.default
  },
  props: {
    deFrom: {
      type: String,
      default: ''
    },
    changeType: {
      type: String,
      default: '1'
    },
    changeCategory: {
      type: String,
      default: '1'
    },
    attacCategoryId: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      visible: false,
      callback: function callback() {},
      isMore: '',
      tabActive: '1',
      auctionList: [],
      tableData: {
        page: 1,
        limit: 24,
        total: 0
      },
      product_id: ''
    };
  },
  watch: {
    visible: function visible(val) {
      if (val) {
        this.getFileList(1);
      }
    }
  },
  methods: {
    changImage: function changImage(row) {
      // console.log(row.product.image);
      this.product_id = row.product_id;
      this.callback([row.product.image]);
      this.visible = false;
    },
    changeTab: function changeTab(val) {
      this.tabActive = val;
    },
    pageChange: function pageChange(page) {
      this.tableData.page = page;
      this.getFileList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableData.page = 1;
      this.tableData.limit = val;
      this.getFileList();
    },
    getFileList: function getFileList() {
      var _this = this;
      var params = JSON.parse(this.deFrom);
      var model = {
        page: this.tableData.page,
        limit: this.tableData.limit
      };
      if (params) model = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, model), params);
      console.log(model);
      (0, _auction.auctionSearchListApi)(model).then(function (res) {
        console.log(res);
        _this.tableData.total = res.data.count;
        _this.auctionList = res.data.list;
      });
    },
    handleClose: function handleClose() {
      this.visible = false;
    },
    getImage: function getImage(img) {
      this.callback(img);
      this.visible = false;
    }
  }
};