var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.facade,
        "label-width": "90px",
        size: "mini",
        "label-position": "left",
      },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "模版字段" } },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.facade.temp,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "temp", $$v)
                },
                expression: "facade.temp",
              },
            },
            [
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "pageBackground" } }, [
                    _vm._v("背景颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "borderColor" } }, [
                    _vm._v("边框颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "textBackground" } }, [
                    _vm._v("内容背景颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-checkbox",
                    { attrs: { label: "venueNameBackground" } },
                    [_vm._v("拍卖会名称背景颜色")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "venueNameColor" } }, [
                    _vm._v("拍卖会名称文字颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "titleColor" } }, [
                    _vm._v("标题文字颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "preTimeTextColor" } }, [
                    _vm._v("预展时间标签颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "preTimeColor" } }, [
                    _vm._v("预展时间颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "timeTextColor" } }, [
                    _vm._v("开拍时间标签颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "timeColor" } }, [
                    _vm._v("开拍时间颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "liveTimeTextColor" } }, [
                    _vm._v("直播时间标签颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "liveTimeColor" } }, [
                    _vm._v("直播时间颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "addressTagColor" } }, [
                    _vm._v("地址标签颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "addressBriefColor" } }, [
                    _vm._v("简要地址颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "addressNameColor" } }, [
                    _vm._v("详细地址颜色"),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }