"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
var _artai = require("@/api/artai");
var _utils = require("@/utils");
var _header = _interopRequireDefault(require("./compents/header.vue"));
var _createPoster = _interopRequireDefault(require("./createPoster.vue"));
var _GridManager = _interopRequireDefault(require("@/views/artai/components/GridManager.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    posterHeader: _header.default,
    GridManager: _GridManager.default,
    createPoster: _createPoster.default
  },
  data: function data() {
    return {
      colWidth: 300,
      countHeight: 0,
      columnHeights: [],
      columnNums: 2,
      gap: 15,
      isReturnPage: false,
      page: 1,
      limit: 20,
      activeType: '',
      activeTag: '',
      tagList: [],
      temTypeList: [],
      templist: [],
      loadTemplate: true,
      type_ids: []
    };
  },
  created: function created() {
    this.getTag();
    this.getList();
    this.getColList();
  },
  mounted: function mounted() {
    // const ggg = this.$refs.imgWaterFall.getBoundingClientRect().width/315;
    // this.columnNums = Math.floor(ggg)
  },
  methods: {
    getColList: function getColList() {
      var _this = this;
      (0, _artai.relationGetTypeIdsApi)({
        type: 1
      }).then(function (res) {
        _this.type_ids = res.data.type_ids;
      });
    },
    onCollect: function onCollect(row) {
      var _this2 = this;
      (0, _artai.marketMediaCollCreateApi)({
        type: 1,
        type_id: row.template_id
      }).then(function (res) {
        _this2.getColList();
      });
    },
    onDelCollect: function onDelCollect(row) {
      var _this3 = this;
      this.$modalConfirm({
        title: '确认',
        content: '确认取消收藏吗',
        success: function success() {
          (0, _artai.relationDeleteApi)({
            type: 1,
            type_id: row.template_id
          }).then(function (res) {
            _this3.getColList();
          });
        },
        cancel: function cancel() {}
      });
    },
    posterNameFilter: function posterNameFilter(row) {
      if (this.temTypeList) {
        var str = '';
        var r = this.temTypeList.find(function (item) {
          return item.type === row.data_type;
        });
        str = "".concat(r ? r.name : '', "\u6D77\u62A5 ").concat(row.width, "*").concat(row.height);
        return str;
      } else return '';
    },
    onRefresh: function onRefresh() {
      this.$router.replace({
        name: 'poster'
      });
      // location.reload(true);
    },
    onClickTempl: function onClickTempl(row) {
      this.$refs.createPosterRef.openSamePoster(row);
    },
    onSwitch: function onSwitch(row) {
      var _this4 = this;
      this.$confirm("\u786E\u5B9A".concat(row.status === 0 ? '上' : '下', "\u67B6\u5417"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _artai.templateUpdateApi)({
          template_id: row.template_id,
          status: row.status === 0 ? 1 : 0
        }).then(function (res) {
          _this4.page = 1;
          _this4.getList();
        });
      }).catch(function () {});
    },
    getTag: function getTag() {
      var _this5 = this;
      (0, _artai.getTagListApi)({}).then(function (res) {
        // console.log(res);
        _this5.tagList = res.data.rows;
      });
      (0, _artai.getTemplateTypeListApi)({}).then(function (res) {
        // console.log(res);
        _this5.temTypeList = res.data;
      });
    },
    changeTag: function changeTag() {},
    typeChange: function typeChange(val) {
      this.page = 1;
      this.activeType = val;
      this.getList();
    },
    tagChange: function tagChange(val) {
      this.page = 1;
      this.activeTag = val;
      this.getList();
    },
    load: (0, _utils.throttle)(function () {
      this.page += 1;
      this.getList();
    }, 500),
    onloadMore: function onloadMore() {
      if (this.isReturnPage) return false;
      if (this.loadTemplate) return false;
      this.loadTemplate = true;
      this.page += 1;
      this.getList();
    },
    getList: function getList() {
      var _this6 = this;
      // console.log('加载');
      var model = {
        page: this.page,
        limit: this.limit,
        data_type: this.activeType,
        tag_ids: this.activeTag
      };
      (0, _artai.getTemplateListApi)(model).then(function (res) {
        _this6.isReturnPage = res.data.rows.length < _this6.limit ? true : false;
        _this6.templist = _this6.page == 1 ? res.data.rows : [].concat((0, _toConsumableArray2.default)(_this6.templist), (0, _toConsumableArray2.default)(res.data.rows));
        _this6.loadTemplate = false;
      });
    },
    handHei: function handHei(listData) {
      this.columnHeights.length = 0;
      var widthLimit = this.colWidth * this.columnNums; //  + gap * (columnNums - 1) // 每行宽度
      var cloneList = JSON.parse(JSON.stringify(listData));
      for (var i = 0; i < cloneList.length; i++) {
        var index = i % this.columnNums;
        var item = cloneList[i];
        item.height = Number(item.height) / Number(item.width) * this.colWidth; // 图片高度
        item.left = index * (widthLimit / this.columnNums + this.gap); // 定位
        item.top = this.columnHeights[index] + this.gap || 0; // 定位
        // 找出最短边
        if (isNaN(this.columnHeights[index])) {
          this.columnHeights[index] = Number(item.height);
        } else {
          index = this.columnHeights.indexOf(Math.min.apply(Math, (0, _toConsumableArray2.default)(this.columnHeights)));
          item.left = index * (widthLimit / this.columnNums + this.gap);
          item.top = this.columnHeights[index] + this.gap || 0;
          this.columnHeights[index] = Number(item.height) + this.columnHeights[index] + this.gap;
        }
      }
      this.countHeight = Math.max.apply(Math, (0, _toConsumableArray2.default)(this.columnHeights));
      // console.log(cloneList);

      return cloneList;
    }
  }
};