"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _settings = require("@/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      roterPre: _settings.roterPre,
      routerUrl: ''
    };
  },
  computed: {
    key: function key() {
      return this.$route.path;
    },
    pression: function pression() {
      var gopath = this.$route.path.replace('/merchant', '');
      return this.$store.getters.userPression[gopath] || {};
    },
    getBtnPression: function getBtnPression() {
      return this.$store.getters.userPression['merchantErpV2WarehouseConfig'];
    }
  },
  mounted: function mounted() {
    this.routerUrl = this.$route.path;
  },
  methods: {
    changeTab: function changeTab(data) {
      var _this = this;
      if (data !== this.routerUrl) {
        this.$router.push({
          path: data
        });
        this.routerUrl = data;
        setTimeout(function () {
          _this.routerUrl = _this.$route.path;
        });
      }
    },
    createPush: function createPush() {
      this.$router.push({
        path: '/merchant/warehouse/createpushorder'
      });
    },
    createOut: function createOut() {
      this.$router.push({
        path: '/merchant/warehouse/createoutorder'
      });
    },
    createTransfers: function createTransfers() {
      this.$router.push({
        path: '/merchant/warehouse/transferscreate'
      });
    },
    createCheck: function createCheck() {
      this.$refs.warehouse.checkcreate();
    },
    goroute: function goroute(type) {
      if (type == 3) {
        // this.getconfig();
        // this.showconfig = true;
        this.$router.push({
          path: '/merchant/warehouse/setColumns?tab=3'
        });
      } else if (type == 4) {
        this.$router.push({
          path: '/merchant/warehouse/warehouseList'
        });
      } else if (type == 1) {
        this.$router.push({
          path: '/merchant/warehouse/setColumns?tab=1'
        });
      } else if (type == 5) {
        this.$router.push({
          path: '/merchant/warehouse/warehouseRules'
        });
      } else if (type == 2) {
        this.$router.push({
          path: '/merchant/warehouse/setColumns?tab=2'
        });
      }
    }
  }
};