"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.link");
require("core-js/modules/es6.regexp.split");
var _control = _interopRequireDefault(require("@/components/customPage/control.vue"));
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
var _slider = _interopRequireDefault(require("@/components/customPage/slider.vue"));
var _selectCategory = _interopRequireDefault(require("@/components/customChildren/selectCategory.vue"));
var _system = require("@/api/system");
var _setting = require("@/api/setting");
var _request = _interopRequireDefault(require("@/api/request"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    control: _control.default,
    colour: _colour.default,
    slider: _slider.default,
    selectCategory: _selectCategory.default
  },
  data: function data() {
    return {
      showselectImage: false,
      selectImageArr: [],
      albumId: '',
      selectedItem: null,
      selectedIndex: ''
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    content: {
      get: function get() {
        return this.$store.getters.content;
      },
      set: function set(value) {
        var data = {
          key: 'content',
          value: value
        };
        this.$store.commit('finish/setAttribute', data);
      }
    },
    facade: {
      get: function get() {
        return this.$store.getters.facade;
      },
      set: function set(value) {
        var data = {
          key: 'facade',
          value: value
        };
        this.$store.commit('finish/setAttribute', data);
      }
    }
  },
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {},
  /**
   * 页面渲染前
   * @return {[type]} [description]
   */
  created: function created() {},
  mounted: function mounted() {
    if (this.content.type == 4 && this.content.data && this.content.data[0] && this.content.data[0].url) {
      this.selectedItem = {
        url: this.content.data[0].url,
        albumId: this.content.data[0].link.link.split('?')[1].split('=')[1]
      };
      this.albumId = this.content.data[0].link.link.split('?')[1].split('=')[1];
    }
  },
  methods: {
    changeType: function changeType(e) {
      var _this = this;
      if (e === '2' || e === '3') {
        (0, _system.getConfigClassKeys)('weChatTemplateContactInformation').then(function (res) {
          if (res.data.contact_image && e === '3') {
            _this.content = JSON.parse(res.data.contact_image);
          } else if (res.data.recommend_articles && e === '2') {
            _this.content = JSON.parse(res.data.recommend_articles);
            console.log(_this.content);
          }
        });
      } else if (e === '4') {
        // if(this.selectImageArr && this.selectImageArr.length){
        if (this.selectedItem) {
          this.content = {
            data: [{
              title: '',
              url: this.selectedItem.url,
              link: {
                link: 'pages/albumDetail/index?id=' + this.albumId,
                name: '其他',
                name1: '自定义链接'
              }
            }],
            type: '4'
          };
          return;
        }
        // else{
        //   this.content = {
        //     data: [{
        //         title: '',
        //         url: '',
        //         link: {}
        //       }],
        //     type: '4'
        //   };
        // }

        // }else{
        //   this.content = {
        //       data: [{
        //           title: '',
        //           url: '',
        //           link: {}
        //         }],
        //       type: '4'
        //     };
        // }

        var id = this.$route.query.album_id;
        var venue_id = this.$route.query.venue_id;
        var action = process.env.VUE_APP_BASE_API + '/api/sharePoster?poster_type=pmAlbumV2&posterPurpose=album';
        var mer_id = this.$store.getters.mer_id;
        if (id) {
          action += '&id=' + id;
        } else if (venue_id) {
          action += '&venue_id=' + venue_id;
        }
        action += '&mer_id=' + mer_id;
        action += '&count=' + 1;
        var loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        _request.default.get(action).then(function (res) {
          loading.close();
          _this.albumId = res.data.albumId;
          _this.content = {
            data: [{
              title: '',
              url: res.data.data[0].url,
              link: {
                link: 'pages/albumDetail/index?id=' + _this.albumId,
                name: '其他',
                name1: '自定义链接'
              }
            }],
            type: '4'
          };
        }).catch(function (err) {
          loading.close();
        });
        // this.content = {
        //   data: [
        //     {
        //       title: '',
        //       url: 'https://saas.cdn.yunzongbu.cn/merchant/23/2024815/image/1723726168628465805.webp',
        //       link: {
        //         link: "pages/albumDetail/index?id="+id,
        //         name: "其他",
        //         name1: "自定义链接"
        //       }
        //     }
        //   ],
        //   type: '4'
        // };
      }
    },
    /**
     * 换一换
     * @return {[type]} [description]
     */
    handleExchange: function handleExchange(norefresh) {
      var _this2 = this;
      // if(norefresh && this.selectImageArr && this.selectImageArr.length){
      //   return;
      // }
      this.selectedIndex = '';
      var id = this.$route.query.album_id;
      var venue_id = this.$route.query.venue_id;
      var action = process.env.VUE_APP_BASE_API + '/api/sharePoster?poster_type=pmAlbumV2&posterPurpose=album';
      var mer_id = this.$store.getters.mer_id;
      if (id) {
        action += '&id=' + id;
      } else if (venue_id) {
        action += '&venue_id=' + venue_id;
      }
      action += '&mer_id=' + mer_id;
      action += '&count=' + 3;
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      _request.default.get(action).then(function (res) {
        loading.close();
        _this2.selectImageArr = res.data.data;
        _this2.showselectImage = true;
        _this2.albumId = res.data.albumId;
      }).catch(function (err) {
        loading.close();
      });
    },
    selectCoverAlbumImage: function selectCoverAlbumImage(item, index) {
      this.selectedItem = item;
      this.selectedIndex = index;
    },
    cursorCoverAlbumImage: function cursorCoverAlbumImage() {
      if (!this.selectedItem) {
        this.$message({
          message: '请选择图片',
          type: 'warning'
        });
        return;
      }
      this.showselectImage = false;
      this.content = {
        data: [{
          title: '',
          url: this.selectedItem.url,
          link: {
            link: 'pages/albumDetail/index?id=' + this.albumId,
            name: '其他',
            name1: '自定义链接'
          }
        }],
        type: '4'
      };
    },
    /**
     * 删除元素
     * @return {[type]} [description]
     */
    handleDelete: function handleDelete(index) {
      this.content.data.splice(index, 1);
    },
    /**
     * 添加元素
     * @return {[type]} [description]
     */
    handleAdditem: function handleAdditem() {
      if (this.content.data.length < 20) {
        this.content.data.push({
          title: '文字',
          url: '',
          link: {}
        });
      } else {
        this.$message({
          message: '最多添加20条内容',
          type: 'warning'
        });
      }
    },
    goUp: function goUp(index) {
      if (index === 0) {
        return;
      }
      this.content.data.splice(index - 1, 0, this.content.data[index]);
      this.content.data.splice(index + 1, 1);
    },
    goDown: function goDown(index) {
      if (index === this.content.data - 1) {
        return;
      }
      this.content.data.splice(index + 2, 0, this.content.data[index]);
      this.content.data.splice(index, 1);
    },
    uploadIconAction: function uploadIconAction(i) {
      var _this3 = this;
      this.$modalUpload(function (img) {
        _this3.content.data[i].url = img[0];
      }, 1);
    },
    delImg: function delImg(i) {
      this.content.data[i].url = '';
    },
    setCategory: function setCategory(i) {
      this.saveIndex = i;
      this.$refs.setCategoryView.changeshow(this.content.data[i].link);
    },
    selectMessage: function selectMessage(data) {
      this.content.data[this.saveIndex].link = data.data;
    }
  }
};