"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _control = _interopRequireDefault(require("@/components/customPage/control.vue"));
var _choice = _interopRequireDefault(require("@/components/customPage/choice.vue"));
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
var _slider = _interopRequireDefault(require("@/components/customPage/slider.vue"));
var _choose = _interopRequireDefault(require("@/components/customPage/choose.vue"));
var _system = require("@/api/system");
var _select = _interopRequireDefault(require("@/components/hyperlinkSelect/select.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    control: _control.default,
    choice: _choice.default,
    colour: _colour.default,
    slider: _slider.default,
    choose: _choose.default,
    hyperlinkSelect: _select.default
  },
  props: {
    cData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    loadMore: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  data: function data() {
    return {
      type: 1,
      contentdef: {},
      styleData: [{
        value: 1,
        src: 'https://saas.cdn.yunzongbu.cn/image/20211015/0a4639a5c33001d5987e8ca38eef4749.png',
        name: '单列模式'
      }, {
        value: 2,
        src: 'https://saas.cdn.yunzongbu.cn/image/20211015/3556c0a102d605c08a4c66e93b8a258d.png',
        name: '双列模式'
      }, {
        value: 3,
        src: 'https://saas.cdn.yunzongbu.cn/image/20211015/309e882b8982cc3241920f825f91825a.png',
        name: '三列模式'
      }, {
        value: 4,
        src: 'https://saas.cdn.yunzongbu.cn/image/20211015/51efec5b8f2b8b4fdec00c15219df8f4.png',
        name: '列表模式'
      }, {
        value: 5,
        src: 'https://saas.cdn.yunzongbu.cn/image/20211015/11a71bedaece1cc9e689d4fb6ebd51c7.png',
        name: '左右滑动'
      }],
      imgStyleData: [{
        value: 1,
        src: 'https://saas.cdn.yunzongbu.cn/image/20211015/c87bfce979dc31c36dc8b964dafa1c19.png',
        name: '瀑布流'
      }, {
        value: 2,
        src: 'https://saas.cdn.yunzongbu.cn/image/20211022/c719f4bdc74747264108875c4397d185.png',
        name: '完整图片'
      }, {
        value: 3,
        src: 'https://saas.cdn.yunzongbu.cn/image/20211015/7369d6f13f0a6a1ef2ac4141b9bdc1e4.png',
        name: '截取图片'
      }],
      typeSetting: null,
      oldSetting: null
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    content: {
      get: function get() {
        return this.$store.getters.content;
      },
      set: function set(value) {
        var data = {
          key: 'content',
          value: value
        };
        this.$store.commit('finish/setAttribute', data);
      }
    },
    facade: {
      get: function get() {
        return this.$store.getters.facade;
      },
      set: function set(value) {
        var data = {
          key: 'facade',
          value: value
        };
        this.$store.commit('finish/setAttribute', data);
      }
    }
  },
  /**
   * 页面渲染前
   * @return {[type]} [description]
   */
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    this.$store.dispatch('user/getEnterInfo').then(function (res) {
      console.log(res);
      if (res.mer_logo) {
        _this.content.data = [{
          url: "".concat(res.mer_logo)
        }];
      }
    });
  },
  /**
   * 页面加载执行
   * @return {[type]} [description]
   */
  methods: {
    changeLogoType: function changeLogoType() {
      var _this2 = this;
      console.log(this.type);
      if (this.type == 1) {
        this.$store.dispatch('user/getEnterInfo').then(function (res) {
          console.log(res);
          if (res.mer_logo) {
            _this2.content.data = [{
              url: "".concat(res.mer_logo)
            }];
          }
        });
      }
    },
    setColor: function setColor(key) {
      if (this.facade.theme !== key) {
        this.facade.theme = key;
        switch (key) {
          case 1:
            this.facade.item_background = '#FFFFFF';
            this.facade.title_color = '#333333';
            this.facade.desc_color = '#999999';
            break;
          case 2:
            this.facade.item_background = '#F5F5F5';
            this.facade.title_color = '#000000';
            this.facade.desc_color = '#999999';
            break;
          case 3:
            this.facade.item_background = '#FB6270';
            this.facade.title_color = '#FFFFFF';
            this.facade.desc_color = '#FFB4BB';
            break;
          case 4:
            this.facade.item_background = '#518862';
            this.facade.title_color = '#FFFFFF';
            this.facade.desc_color = '#A7C3B0';
            break;
          case 5:
            this.facade.item_background = '#839EC8';
            this.facade.title_color = '#FFFFFF';
            this.facade.desc_color = '#C9D7EF';
            break;
          case 6:
            this.facade.item_background = '#CCA373';
            this.facade.title_color = '#FFFFFF';
            this.facade.desc_color = '#F9E4CA';
            break;
          case 7:
            this.facade.item_background = '#333333';
            this.facade.title_color = '#FFFFFF';
            this.facade.desc_color = '#999999';
            break;
        }
      }
    },
    delImg: function delImg(index) {
      this.content.data[index].url = '';
    },
    /**
     * 删除元素
     * @return {[type]} [description]
     */
    handleDelete: function handleDelete(index) {
      this.content.data.splice(index, 1);
    },
    /**
     * 添加元素
     * @return {[type]} [description]
     */
    handleAdditem: function handleAdditem() {
      this.$refs.selectView.changeshow();
    },
    goUp: function goUp(index) {
      if (index === 0) {
        return;
      }
      this.content.data.splice(index - 1, 0, this.content.data[index]);
      this.content.data.splice(index + 1, 1);
    },
    goDown: function goDown(index) {
      if (index === this.content.data - 1) {
        return;
      }
      this.content.data.splice(index + 2, 0, this.content.data[index]);
      this.content.data.splice(index, 1);
    },
    uploadIconAction: function uploadIconAction() {
      var _this3 = this;
      this.$modalUpload(function (img) {
        // if (res && res.url) {
        //   const newFile = file;
        //   newFile.url = res.url;
        //   this.content.data[0].url = res.url;
        // }
        var data = [];
        img.forEach(function (m) {
          data.push({
            url: "".concat(m)
          });
        });
        _this3.content.data = data;
        _this3.$forceUpdate();
        // this.content.data = this.content.data.concat(data);
      }, 9);
    },
    selectMessage: function selectMessage(data) {
      console.log(data.data);
      var d = [];
      data.data.forEach(function (m) {
        if (m.slider_image && m.slider_image.length > 0) {
          m.slider_image.forEach(function (img, i) {
            d.push({
              image: "".concat(img, "!m640"),
              name: m.name
            });
          });
        } else {
          d.push(m);
        }
      });
      this.content.data = this.content.data.concat(d);
    }
  }
};