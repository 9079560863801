"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("@/styles/element-variables.scss");
var _index = _interopRequireDefault(require("./index.vue"));
var _vue = _interopRequireDefault(require("vue"));
var confirmFrom = {};
confirmFrom.install = function (Vue, options) {
  var ToastConstructor = Vue.extend(_index.default);
  // 生成一个该子类的实例
  var instance = new ToastConstructor();
  instance.$mount(document.createElement('div'));
  document.body.appendChild(instance.$el);
  Vue.prototype.$modalConfirm = function (data) {
    instance.visible = true;
    instance.title = data.title || '';
    instance.content = data.content || '';
    instance.isPadding = data.isPadding;
    instance.noCancel = data.noCancel || false;
    instance.cancelTxt = data.cancelTxt || '取消';
    instance.confirmTxt = data.confirmTxt || '确认';
    instance.success = data.success;
    instance.cancel = data.cancel;
  };
};
var _default = exports.default = confirmFrom;