"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _auction = require("@/api/auction");
var _user = require("@/api/user.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      albumList: [],
      queryParams: {
        page: 1,
        limit: 20,
        status: '',
        venue_id: this.$route.query.vid || '',
        isSearch: false
      },
      total: 0,
      list: [],
      listLoading: true,
      btnLoading: false,
      printData: [],
      orderConfirmData: {
        show: false
      },
      printObj2: {
        id: 'printConfirm',
        popTitle: ''
      }
    };
  },
  computed: {
    feeTypeObj: function feeTypeObj() {
      return this.$store.getters.feeTypeInfo;
    }
  },
  created: function created() {
    this.load();
  },
  methods: {
    load: function load() {
      var _this = this;
      this.listLoading = true;
      (0, _auction.albumlist)(this.queryParams).then(function (response) {
        _this.total = response.data.count;
        _this.list = response.data.list;
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.load();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.limit = val;
      this.load();
    },
    loadPrintAction: function loadPrintAction(m) {
      var _this2 = this;
      this.btnLoading = true;
      var nTime = new Date(m.album_start_time * 1000);
      this.albumPriceData = {
        name: m.venue.title,
        commission: m.commission_config_json.after_commission ? m.commission_config_json.after_commission : m.commission_config_json.commission,
        mLogo: '',
        mName: '',
        d: "".concat(nTime.getFullYear(), "\u5E74").concat(nTime.getMonth() + 1, "\u6708").concat(nTime.getDate(), "\u65E5"),
        mer_id: m.mer_id
      };
      (0, _user.getBaseInfo)().then(function (res) {
        _this2.albumPriceData.mLogo = res.data.mer_logo;
        _this2.albumPriceData.mName = res.data.company_name;
      });
      this.printData = [];
      var data = {
        album_id: m.album_id,
        page: 1,
        limit: 50,
        venue_id: m.venue_id
      };
      this.loadPrintAuction(data);
    },
    loadPrintAuction: function loadPrintAuction(params) {
      var _this3 = this;
      (0, _auction.auctionList)(params).then(function (res) {
        var d = [];
        res.data.list.forEach(function (m) {
          console.log(m);
          d = d.concat([{
            title: m.product.store_name,
            tuluhao: m.lot
          }]);
        });
        _this3.printData = _this3.printData.concat(d);
        console.log(_this3.printData);
        if (_this3.printData.length < res.data.count) {
          var data = params;
          data.page += 1;
          _this3.loadPrintAuction(data);
        } else {
          _this3.btnLoading = false;
          // const nTime = new Date();
          _this3.orderConfirmData = {
            show: true
          };
        }
      });
    }
  }
};