var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "posterMain" },
    [
      _c("posterHeader", {
        ref: "videoHeadRef",
        on: { getNumber: _vm.getNumber },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "flex justify-start flex-wrap",
          staticStyle: { "margin-top": "10px" },
        },
        [
          _c("div", { staticClass: "poster-type-container ml24" }, [
            _c(
              "div",
              {
                staticClass: "poster-type-itemOne",
                class: _vm.levelType == 1 ? "type-active" : "",
                on: {
                  click: function ($event) {
                    return _vm.leveTagChange(1)
                  },
                },
              },
              [_vm._v("普通 " + _vm._s(_vm.ordinaryTemplateCount))]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "poster-type-itemOne",
                class: _vm.levelType == 2 ? "type-active" : "",
                on: {
                  click: function ($event) {
                    return _vm.leveTagChange(2)
                  },
                },
              },
              [_vm._v("高级 " + _vm._s(_vm.seniorTemplateCount))]
            ),
          ]),
          _vm._v(" "),
          _vm.weidu.sysAttrValueList
            ? _c(
                "div",
                { staticClass: "poster-type-container ml24" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "poster-type-itemOne",
                      class:
                        _vm.tableFrom.contentType == "" ? "type-active" : "",
                      on: {
                        click: function ($event) {
                          return _vm.tagChange("", "contentType")
                        },
                      },
                    },
                    [_vm._v("全部")]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.weidu.sysAttrValueList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "poster-type-itemOne",
                        class:
                          _vm.tableFrom.contentType == item.id
                            ? "type-active"
                            : "",
                        on: {
                          click: function ($event) {
                            return _vm.tagChange(item.id, "contentType")
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.attrValueName))]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.dingwei.sysAttrValueList
            ? _c(
                "div",
                { staticClass: "poster-type-container ml24" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "poster-type-itemOne",
                      class:
                        _vm.tableFrom.contentDefine == "" ? "type-active" : "",
                      on: {
                        click: function ($event) {
                          return _vm.tagChange("", "contentDefine")
                        },
                      },
                    },
                    [_vm._v("全部")]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.dingwei.sysAttrValueList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "poster-type-itemOne",
                        class:
                          _vm.tableFrom.contentDefine == item.id
                            ? "type-active"
                            : "",
                        on: {
                          click: function ($event) {
                            return _vm.tagChange(item.id, "contentDefine")
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.attrValueName))]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "poster-type-container" }, [
            _c(
              "div",
              {
                staticClass: "poster-type-itemOne",
                class: _vm.tableFrom.ratio == "" ? "type-active" : "",
                on: {
                  click: function ($event) {
                    return _vm.tagChange("", "ratio")
                  },
                },
              },
              [_vm._v("全部")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "poster-type-itemOne",
                class: _vm.tableFrom.ratio == "16:9" ? "type-active" : "",
                on: {
                  click: function ($event) {
                    return _vm.tagChange("16:9", "ratio")
                  },
                },
              },
              [_vm._v("16:9")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "poster-type-itemOne",
                class: _vm.tableFrom.ratio == "9:16" ? "type-active" : "",
                on: {
                  click: function ($event) {
                    return _vm.tagChange("9:16", "ratio")
                  },
                },
              },
              [_vm._v("9:16")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "poster-type-itemOne",
                class: _vm.tableFrom.ratio == "4:3" ? "type-active" : "",
                on: {
                  click: function ($event) {
                    return _vm.tagChange("4:3", "ratio")
                  },
                },
              },
              [_vm._v("4:3")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "poster-type-itemOne",
                class: _vm.tableFrom.ratio == "3:4" ? "type-active" : "",
                on: {
                  click: function ($event) {
                    return _vm.tagChange("3:4", "ratio")
                  },
                },
              },
              [_vm._v("3:4")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "poster-type-itemOne",
                class: _vm.tableFrom.ratio == "1:1" ? "type-active" : "",
                on: {
                  click: function ($event) {
                    return _vm.tagChange("1:1", "ratio")
                  },
                },
              },
              [_vm._v("1:1")]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex justify-start" }, [
        _c(
          "div",
          { staticClass: "poster-type-container" },
          [
            _c(
              "div",
              {
                staticClass: "poster-type-itemTwo",
                class: _vm.tableFrom.labelList == "" ? "type-activeOne" : "",
                on: {
                  click: function ($event) {
                    return _vm.tagChange("", "labelList")
                  },
                },
              },
              [_vm._v("全部")]
            ),
            _vm._v(" "),
            _vm._l(_vm.tagList, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "poster-type-itemTwo",
                  class:
                    _vm.tableFrom.labelList == item.id ? "type-activeOne" : "",
                  on: {
                    click: function ($event) {
                      return _vm.tagChange(item.id, "labelList")
                    },
                  },
                },
                [_vm._v(_vm._s(item.name))]
              )
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("grid-manager", {
        staticStyle: { "margin-top": "10px" },
        attrs: {
          gridList: _vm.templist,
          footHeight: 35,
          "is-loading": _vm.loadTemplate,
        },
        on: { loadMore: _vm.onloadMore },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              var index = ref.index
              return [
                _c(
                  "div",
                  {
                    staticClass: "img-box",
                    on: {
                      mouseenter: function ($event) {
                        return _vm.playVideo(row, index)
                      },
                      mouseleave: function ($event) {
                        return _vm.stopVideo(row, index)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "img-view",
                        staticStyle: { height: "calc(100% - 35px)" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "hb-conStatus",
                            on: {
                              click: function ($event) {
                                return _vm.onClickTempl(row)
                              },
                            },
                          },
                          [_vm._v("做同款")]
                        ),
                        _vm._v(" "),
                        _vm.type_ids.includes(row.id + "")
                          ? _c(
                              "div",
                              {
                                staticClass: "position-img",
                                staticStyle: { right: "10px" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDelCollect(row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: "取消收藏",
                                      placement: "top",
                                      enterable: false,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/poster/sc.png"),
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "position-img",
                                staticStyle: { right: "10px" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onCollect(row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: "收藏",
                                      placement: "top",
                                      enterable: false,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/poster/scBlack.png"),
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                        _vm._v(" "),
                        _c("video", {
                          ref: "videos" + index,
                          staticStyle: { width: "100%", display: "block" },
                          attrs: { src: row.exampleVideo, muted: "" },
                          domProps: { muted: true },
                        }),
                        _vm._v(" "),
                        _c("el-image", {
                          staticClass: "posIimg",
                          style:
                            _vm.videoIndex === index
                              ? "z-index: -1"
                              : "z-index: 9",
                          attrs: { src: "" + row.coverImage },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex videoItemTit" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.levelType == 2,
                                expression: "row.levelType == 2",
                              },
                            ],
                            attrs: {
                              content: "高级",
                              placement: "top",
                              effect: "light",
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "leveImg",
                              attrs: {
                                src: require("@/assets/images/poster/leve2.png"),
                                alt: "",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.levelType == 3,
                                expression: "row.levelType == 3",
                              },
                            ],
                            attrs: {
                              content: "定制",
                              placement: "top",
                              effect: "light",
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "leveImg",
                              attrs: {
                                src: require("@/assets/images/poster/leveg.png"),
                                alt: "",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex flex-one" }, [
                          _c("div", { staticClass: "videott" }, [
                            _vm._v(_vm._s(row.templateName)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "10px" } },
                            [_vm._v(_vm._s(row.ratio))]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }