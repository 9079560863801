var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "taskContainer" },
    [
      _c("div", { staticClass: "poster-type-container" }, [
        _c(
          "div",
          {
            staticClass: "poster-type-itemOne",
            class: _vm.status == "" ? "type-active" : "",
            on: {
              click: function ($event) {
                return _vm.typeChange("")
              },
            },
          },
          [_vm._v("全部")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "poster-type-itemOne",
            class: _vm.status == "0" ? "type-active" : "",
            on: {
              click: function ($event) {
                return _vm.typeChange("0")
              },
            },
          },
          [_vm._v("待发布")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "poster-type-itemOne",
            class: _vm.status == "1" ? "type-active" : "",
            on: {
              click: function ($event) {
                return _vm.typeChange("1")
              },
            },
          },
          [_vm._v("已发布")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 220px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              staticClass: "no-height",
              attrs: { border: "", height: "100%", data: _vm.resultData },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                key: "1",
                attrs: { type: "selection", width: "40", align: "center" },
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "发布日期",
                  width: "105",
                  "search-key": "released_plan_date",
                  "search-type": "date",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.released_d
                          ? _c(
                              "div",
                              {
                                staticClass: "column-c",
                                on: {
                                  click: function ($event) {
                                    return _vm.setColumnAction(
                                      scope.$index,
                                      "date",
                                      scope.row.released_d
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.released_d))]
                            )
                          : _c("div", {
                              staticClass: "set-col",
                              on: {
                                click: function ($event) {
                                  return _vm.setColumnAction(
                                    scope.$index,
                                    "date",
                                    scope.row.released_d
                                  )
                                },
                              },
                            }),
                        _vm._v(" "),
                        _vm.setI === scope.$index && _vm.setType === "date"
                          ? _c("div", { staticClass: "set-col" }, [
                              _c(
                                "div",
                                { staticClass: "set has-bor" },
                                [
                                  _c("el-date-picker", {
                                    ref: "valComponentEL",
                                    staticStyle: {
                                      width: "100%",
                                      border: "none",
                                    },
                                    attrs: {
                                      type: "date",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      placeholder: "选择日期",
                                      clearable: false,
                                    },
                                    on: {
                                      blur: _vm.clearSetAction,
                                      change: _vm.editDataAction,
                                    },
                                    model: {
                                      value: _vm.val,
                                      callback: function ($$v) {
                                        _vm.val = $$v
                                      },
                                      expression: "val",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "时间",
                  width: "80",
                  "search-key": "released_plan_time",
                  "search-type": "time",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.released_t
                          ? _c(
                              "div",
                              {
                                staticClass: "column-c",
                                on: {
                                  click: function ($event) {
                                    return _vm.setColumnAction(
                                      scope.$index,
                                      "time",
                                      scope.row.released_t
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.released_t))]
                            )
                          : _c("div", {
                              staticClass: "set-col",
                              on: {
                                click: function ($event) {
                                  return _vm.setColumnAction(
                                    scope.$index,
                                    "time",
                                    scope.row.released_t
                                  )
                                },
                              },
                            }),
                        _vm._v(" "),
                        _vm.setI === scope.$index && _vm.setType === "time"
                          ? _c("div", { staticClass: "set-col" }, [
                              _c(
                                "div",
                                { staticClass: "set has-bor" },
                                [
                                  _c("el-time-select", {
                                    ref: "valComponentEL",
                                    staticStyle: {
                                      width: "100%",
                                      border: "none",
                                    },
                                    attrs: {
                                      "picker-options": {
                                        start: "00:00",
                                        step: "00:10",
                                        end: "23:50",
                                      },
                                      clearable: false,
                                      "value-format": "HH:mm",
                                      placeholder: "选择时间",
                                    },
                                    on: {
                                      blur: _vm.clearSetAction,
                                      change: _vm.editDataAction,
                                    },
                                    model: {
                                      value: _vm.val,
                                      callback: function ($$v) {
                                        _vm.val = $$v
                                      },
                                      expression: "val",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "视频",
                  width: "80",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.extend_data.video_info &&
                        scope.row.extend_data.video_info.url
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onOpenDetails(scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "描述文案",
                  "min-width": "300",
                  "search-key": "video_title",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.extend_data.video_info.title
                          ? _c(
                              "div",
                              {
                                staticClass: "column-c",
                                on: {
                                  click: function ($event) {
                                    return _vm.setColumnAction(
                                      scope.$index,
                                      "video_title",
                                      scope.row.extend_data.video_info.title
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(scope.row.extend_data.video_info.title)
                                ),
                              ]
                            )
                          : _c("div", {
                              staticClass: "set-col",
                              on: {
                                click: function ($event) {
                                  return _vm.setColumnAction(
                                    scope.$index,
                                    "video_title",
                                    scope.row.extend_data.video_info.title
                                  )
                                },
                              },
                            }),
                        _vm._v(" "),
                        _vm.setI === scope.$index &&
                        _vm.setType === "video_title"
                          ? _c("div", { staticClass: "set-col" }, [
                              _c(
                                "div",
                                { staticClass: "set" },
                                [
                                  _c("el-input", {
                                    ref: "valComponentEL",
                                    attrs: {
                                      type: "textarea",
                                      placeholder: "请输入内容",
                                      resize: "none",
                                    },
                                    on: {
                                      blur: _vm.clearSetAction,
                                      change: _vm.editDataAction,
                                    },
                                    model: {
                                      value: _vm.val,
                                      callback: function ($$v) {
                                        _vm.val = $$v
                                      },
                                      expression: "val",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "标签",
                  "min-width": "200",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.extend_data.video_info.label &&
                        typeof scope.row.extend_data.video_info.label ==
                          "string"
                          ? _c(
                              "div",
                              {
                                staticClass: "column-c",
                                on: {
                                  click: function ($event) {
                                    return _vm.setColumnAction(
                                      scope.$index,
                                      "video_label",
                                      scope.row.extend_data.video_info.label
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(scope.row.extend_data.video_info.label)
                                ),
                              ]
                            )
                          : scope.row.extend_data.video_info.label
                          ? _c(
                              "div",
                              {
                                staticClass: "column-c",
                                on: {
                                  click: function ($event) {
                                    return _vm.setColumnAction(
                                      scope.$index,
                                      "video_label",
                                      scope.row.extend_data.video_info.label
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.extend_data.video_info.label
                                      .map(function (m) {
                                        return "#" + m
                                      })
                                      .join("")
                                  )
                                ),
                              ]
                            )
                          : _c("div", {
                              staticClass: "set-col",
                              on: {
                                click: function ($event) {
                                  return _vm.setColumnAction(
                                    scope.$index,
                                    "video_label",
                                    scope.row.extend_data.video_info.label
                                  )
                                },
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "渠道",
                  "min-width": "100",
                  "search-key": "channels_type",
                  "search-type": "radio",
                  "search-query": _vm.searchQuery,
                  options: [
                    { label: "抖音", value: "douyin" },
                    { label: "视频号", value: "tencent" },
                  ],
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.account && scope.row.account.channelsTypeText
                          ? _c("span", [
                              _vm._v(
                                _vm._s(scope.row.account.channelsTypeText)
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "账号",
                  width: "180",
                  "search-key": "account_id",
                  "search-type": "radio",
                  multiple: "",
                  options: _vm.userOptions,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.account && scope.row.account.account_name
                          ? _c(
                              "div",
                              {
                                staticClass: "column-c",
                                on: {
                                  click: function ($event) {
                                    return _vm.setColumnAction(
                                      scope.$index,
                                      "account_id",
                                      scope.row.account.account_id
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.account &&
                                      scope.row.account.account_name
                                  )
                                ),
                              ]
                            )
                          : _c("div", {
                              staticClass: "set-col",
                              on: {
                                click: function ($event) {
                                  return _vm.setColumnAction(
                                    scope.$index,
                                    "account_id",
                                    ""
                                  )
                                },
                              },
                            }),
                        _vm._v(" "),
                        _vm.setI === scope.$index &&
                        _vm.setType === "account_id"
                          ? _c("div", { staticClass: "set-col" }, [
                              _c(
                                "div",
                                { staticClass: "set has-bor" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      ref: "valComponentEL",
                                      attrs: {
                                        placeholder: "请选择账号",
                                        "automatic-dropdown": "",
                                      },
                                      on: {
                                        blur: _vm.clearSetAction,
                                        change: _vm.editDataAction,
                                      },
                                      model: {
                                        value: _vm.val,
                                        callback: function ($$v) {
                                          _vm.val = $$v
                                        },
                                        expression: "val",
                                      },
                                    },
                                    _vm._l(_vm.userList, function (m, i) {
                                      return _c("el-option", {
                                        key: "user" + i,
                                        attrs: { value: m.id, label: m.label },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "状态",
                  width: "80",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.is_released == 1
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v("发布中"),
                            ])
                          : scope.row.is_released == 2
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v("已发布"),
                            ])
                          : scope.row.is_released == 0
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v("未发布"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "发布时间",
                  width: "180",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "nowrap" }, [
                          _vm._v(_vm._s(scope.row.released_time)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "操作",
                  width: "80",
                  prop: "",
                  align: "center",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onDele(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-b orderFootOne" },
        [
          _c(
            "div",
            { staticClass: "flex-one" },
            [
              _c(
                "el-button",
                {
                  staticStyle: {
                    "margin-left": "4px",
                    background: "#6415D7",
                    border: "1px solid #6415D7",
                  },
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.delAll },
                },
                [_vm._v("批量删除")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.total,
            },
            on: {
              "current-change": _vm.pageChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "videoDialog",
          attrs: {
            title: "",
            visible: _vm.moduleVisible,
            "close-on-click-modal": true,
            top: "5vh",
            "show-close": false,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.moduleVisible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("div", { staticClass: "dialogBody" }, [
            _c("div", { staticClass: "flex dialogCon" }, [
              _c("span", [
                _vm._v("视频类型:\n          "),
                _vm.moduleForm.make_type == 1
                  ? _c("span", [_vm._v("拍卖会介绍视频")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.moduleForm.make_type == 2
                  ? _c("span", [_vm._v("专场介绍视频")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.moduleForm.make_type == 3 || _vm.moduleForm.make_type == 4
                  ? _c("span", [_vm._v("拍品介绍视频")])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "计划发布时间:\n          " +
                    _vm._s(_vm.moduleForm.released_plan_date) +
                    " " +
                    _vm._s(_vm.moduleForm.released_plan_time) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _vm.moduleForm.make_type == 3 || _vm.moduleForm.make_type == 4
                ? _c("span", [
                    _vm._v(
                      "拍品信息:\n          " +
                        _vm._s(
                          _vm.moduleForm.makeContentData.product.store_name
                        ) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", [
                _vm.moduleForm.make_type == 2
                  ? _c("span", [
                      _vm._v(
                        "所属拍卖会: " +
                          _vm._s(_vm.moduleForm.makeContentData.venue.title)
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.moduleForm.make_type == 3 || _vm.moduleForm.make_type == 4
                  ? _c("span", [
                      _vm._v(
                        "\n            所属专场: " +
                          _vm._s(_vm.moduleForm.makeContentData.venue.title) +
                          " - " +
                          _vm._s(
                            _vm.moduleForm.makeContentData.album.album_name
                          ) +
                          "\n          "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex" }, [
              _vm.moduleForm.extend_data &&
              _vm.moduleForm.extend_data.video_info
                ? _c("div", { staticStyle: { width: "50%" } }, [
                    _c("video", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        controls: "",
                        src: _vm.moduleForm.extend_data.video_info.url,
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "10px",
                          "text-align": "center",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              background: "#6415D7",
                              border: "1px solid #6415D7",
                            },
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.onDownload },
                          },
                          [_vm._v("下载视频")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "审批", visible: _vm.showreview, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.showreview = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: { rows: 3, type: "textarea" },
            model: {
              value: _vm.mask,
              callback: function ($$v) {
                _vm.mask = $$v
              },
              expression: "mask",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showreview = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handlereview(-1)
                    },
                  },
                },
                [_vm._v("拒绝")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: {
                    background: "#6415D7",
                    border: "1px solid #6415D7",
                  },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlereview(1)
                    },
                  },
                },
                [_vm._v("同意")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑任务",
            visible: _vm.channelVisible,
            "close-on-click-modal": false,
            width: "450px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.channelVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "账号渠道", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      on: { change: _vm.getChannList },
                      model: {
                        value: _vm.channelForm.channels,
                        callback: function ($$v) {
                          _vm.$set(_vm.channelForm, "channels", $$v)
                        },
                        expression: "channelForm.channels",
                      },
                    },
                    _vm._l(_vm.channelType.channels, function (item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "账号", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w300",
                      model: {
                        value: _vm.channelForm.channels_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.channelForm, "channels_id", $$v)
                        },
                        expression: "channelForm.channels_id",
                      },
                    },
                    _vm._l(_vm.channList, function (item) {
                      return _c("el-option", {
                        key: item.account_name,
                        attrs: { label: item.account_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml20",
                  on: {
                    click: function ($event) {
                      _vm.channelVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showSetLabel
        ? _c(
            "dialogComfirm",
            {
              attrs: { title: "修改标签" },
              on: {
                confirm: _vm.confirmTagAction,
                cancel: function ($event) {
                  _vm.showSetLabel = false
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-wrap" },
                _vm._l(_vm.tagList, function (m, i) {
                  return _c(
                    "div",
                    { key: i, staticClass: "tag-item" },
                    [
                      _c(
                        "el-tag",
                        {
                          attrs: { closable: "" },
                          on: {
                            close: function ($event) {
                              return _vm.closeTagAction(i)
                            },
                          },
                        },
                        [_vm._v(_vm._s(m))]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tag-inp" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入标签，按回车确认" },
                    on: { change: _vm.changeTagAction },
                    model: {
                      value: _vm.tagVal,
                      callback: function ($$v) {
                        _vm.tagVal = $$v
                      },
                      expression: "tagVal",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("videoDetails", { ref: "videoViewRef" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }