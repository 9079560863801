"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _settings = require("@/settings");
var _layout = _interopRequireDefault(require("@/layout"));
var _systemForm = _interopRequireDefault(require("./modules/systemForm"));
var _config = _interopRequireDefault(require("./modules/config"));
var _setting = _interopRequireDefault(require("./modules/setting"));
var _group = _interopRequireDefault(require("./modules/group"));
var _product = _interopRequireDefault(require("./modules/product"));
var _marketing = _interopRequireDefault(require("./modules/marketing"));
var _order = _interopRequireDefault(require("./modules/order"));
var _accounts = _interopRequireDefault(require("./modules/accounts"));
var _artai = _interopRequireDefault(require("./modules/artai"));
var _user = _interopRequireDefault(require("./modules/user"));
var _station = _interopRequireDefault(require("./modules/station"));
var _auction = _interopRequireDefault(require("./modules/auction"));
var _decoration = _interopRequireDefault(require("./modules/decoration"));
var _renovation = _interopRequireDefault(require("./modules/renovation"));
var _live = _interopRequireDefault(require("./modules/live"));
var _cms = _interopRequireDefault(require("./modules/cms"));
var _digitalCollection = _interopRequireDefault(require("./modules/digitalCollection"));
var _basic = _interopRequireDefault(require("./modules/basic"));
var _contract = _interopRequireDefault(require("./modules/contract"));
var _reviews = _interopRequireDefault(require("./modules/reviews"));
var _warehouse = _interopRequireDefault(require("./modules/warehouse"));
var _digitalHall = _interopRequireDefault(require("./modules/digitalHall"));
var _open3d = _interopRequireDefault(require("./modules/open3d"));
var _ticket = _interopRequireDefault(require("./modules/ticket"));
var _member = _interopRequireDefault(require("./modules/member"));
var _authenticate = _interopRequireDefault(require("./modules/authenticate"));
var _questionnaire = _interopRequireDefault(require("./modules/questionnaire"));
var _work = _interopRequireDefault(require("./modules/work"));
var _frame = _interopRequireDefault(require("./modules/frame"));
var _auctionreport = _interopRequireDefault(require("./modules/auctionreport"));
_vue.default.use(_vueRouter.default);

/* Layout */

/* Router Modules */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [_renovation.default, _systemForm.default, _config.default, _setting.default, _group.default, _product.default, _auction.default, _ticket.default, _live.default, _cms.default, _marketing.default, _order.default, _accounts.default, _artai.default, _user.default, _station.default, _decoration.default, _basic.default, _contract.default, _reviews.default, _warehouse.default, _digitalCollection.default, _digitalHall.default, _open3d.default, _member.default, _authenticate.default, _questionnaire.default, _work.default, _frame.default, _auctionreport.default, {
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: _settings.roterPre,
  component: _layout.default,
  children: [{
    path: "".concat(_settings.roterPre, "/dashboard"),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/index'));
      });
    },
    name: 'Dashboard',
    meta: {
      title: '控制台',
      icon: 'dashboard',
      affix: true,
      permissionKey: '/dashboard'
    }
  }]
}, {
  path: '/',
  component: _layout.default,
  redirect: "".concat(_settings.roterPre, "/dashboard"),
  children: [{
    path: "".concat(_settings.roterPre, "/dashboard"),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/index'));
      });
    },
    name: 'Dashboard',
    meta: {
      title: '控制台',
      icon: 'dashboard',
      affix: true,
      permissionKey: '/'
    }
  }]
}, {
  path: "".concat(_settings.roterPre, "/login"),
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: "".concat(_settings.roterPre, "/client_login"),
  name: 'clientLogin',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/client_index'));
    });
  },
  hidden: true
}, {
  path: "".concat(_settings.roterPre, "/auctionConsole"),
  name: 'auctionConsole',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/auctionConsole'));
    });
  },
  meta: {
    title: '拍卖师助理控制台',
    noCache: true,
    permissionKey: '/auctionConsole'
  },
  hidden: true
}, {
  path: "".concat(_settings.roterPre, "/diy"),
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/decoration/customMain'));
    });
  },
  redirect: "".concat(_settings.roterPre, "/diy/page"),
  meta: {
    title: '自定义页面',
    noCache: true
  },
  children: [{
    path: 'page',
    name: 'pageDiy',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/decoration/customPage'));
      });
    },
    meta: {
      title: '自定义页面',
      noCache: true
    },
    hidden: true
  }]
}, {
  path: "".concat(_settings.roterPre, "/mine"),
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/decoration/mineMain'));
    });
  },
  redirect: "".concat(_settings.roterPre, "/mine/page"),
  meta: {
    title: '个人中心配置',
    noCache: true
  },
  children: [{
    path: 'page',
    name: 'mineDiy',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/decoration/minePage'));
      });
    },
    meta: {
      title: '个人中心配置',
      noCache: true
    },
    hidden: true
  }]
}, {
  path: "".concat(_settings.roterPre, "/weChat"),
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/decoration/weChatMain'));
    });
  },
  redirect: "".concat(_settings.roterPre, "/mine/weChat"),
  meta: {
    title: '推文配置',
    noCache: true
  },
  children: [{
    path: 'page',
    name: 'mineDiy',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/decoration/weChat'));
      });
    },
    meta: {
      title: '推文配置',
      noCache: true
    },
    hidden: true
  }]
}, {
  path: "".concat(_settings.roterPre, "/weChatWz"),
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/decoration/weChatMainWz'));
    });
  },
  redirect: "".concat(_settings.roterPre, "/mine/weChatWz"),
  meta: {
    title: '推文配置',
    noCache: true
  },
  children: [{
    path: 'page',
    name: 'wzDiy',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/decoration/weChatWz'));
      });
    },
    meta: {
      title: '推文配置',
      noCache: true
    },
    hidden: true
  }]
}, {
  path: "".concat(_settings.roterPre, "/posterUpd"),
  name: 'posterUpd',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/artai/poster/updatePoster'));
    });
  },
  meta: {
    title: '编辑海报',
    noCache: true
  },
  hidden: true
}, {
  path: "".concat(_settings.roterPre, "/assemblyDetail"),
  name: 'assemblyDetail',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/artai/sp/assemblyDetail'));
    });
  },
  meta: {
    title: '编辑标准视频',
    noCache: true
  }
}, {
  path: "".concat(_settings.roterPre, "/basics"),
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/decoration/basicsMain'));
    });
  },
  redirect: "".concat(_settings.roterPre, "/basics/page"),
  meta: {
    title: '基础页面配置',
    noCache: true
  },
  children: [{
    path: 'page',
    name: 'basicsDiy',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/decoration/basicsPage'));
      });
    },
    meta: {
      title: '基础页面配置',
      noCache: true
    },
    hidden: true
  }]
}, {
  path: "".concat(_settings.roterPre, "/template"),
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/decoration/templateMain'));
    });
  },
  redirect: "".concat(_settings.roterPre, "/template/page"),
  meta: {
    title: '经典模板配置',
    noCache: true
  },
  children: [{
    path: 'page',
    name: 'templateDiy',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/decoration/templatePage'));
      });
    },
    meta: {
      title: '经典模板配置',
      noCache: true
    },
    hidden: true
  }]
}, {
  path: "".concat(_settings.roterPre, "/examineTakePicture"),
  name: 'examineTakePicture',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/basic/components/examineTakePicture'));
    });
  },
  meta: {
    title: '审核图片',
    noCache: true
  },
  hidden: true
}, {
  path: "".concat(_settings.roterPre, "/digital_hall/manage/edit"),
  name: 'digitalHallEdit',
  meta: {
    title: '编辑展厅',
    activeMenu: "".concat(_settings.roterPre, "/digital_hall/manage/list")
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/digitalHall/edit'));
    });
  },
  hidden: true
}, {
  path: "".concat(_settings.roterPre, "/digital_hall/manage/edit3d"),
  name: 'digitalHallEdit3d',
  meta: {
    title: '编辑展厅',
    activeMenu: "".concat(_settings.roterPre, "/digital_hall/manage/list")
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/digitalHall/edit3d'));
    });
  },
  hidden: true
}, {
  path: "".concat(_settings.roterPre, "/blindAlbumControl"),
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/decoration/blindMain'));
    });
  },
  redirect: "".concat(_settings.roterPre, "/blindAlbumControl/page"),
  meta: {
    title: '基础页面配置',
    noCache: true
  },
  children: [{
    path: 'page',
    name: 'basicsDiy',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/decoration/blindPage'));
      });
    },
    meta: {
      title: '基础页面配置',
      noCache: true
    },
    hidden: true
  }]
}, {
  path: "".concat(_settings.roterPre, "/obschange"),
  name: 'obschange',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/obschange'));
    });
  },
  meta: {
    title: 'obs动画控制',
    noCache: true
  },
  hidden: true
}, {
  path: "".concat(_settings.roterPre, "/obsconsole"),
  name: 'obsconsole',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/obsconsole'));
    });
  },
  meta: {
    title: 'obs控制台',
    noCache: true
  },
  hidden: true
}, {
  path: "".concat(_settings.roterPre, "/set_led_setting"),
  name: 'auctionSetled',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/auction/setting/screen_led_setting'));
    });
  },
  meta: {
    title: 'LED设置',
    noCache: true
  },
  hidden: true
}, {
  path: "".concat(_settings.roterPre, "/set_led_setting_there"),
  name: 'auctionSetled',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/auction/setting/screen_led_setting_there'));
    });
  },
  meta: {
    title: 'LED设置',
    noCache: true
  },
  hidden: true
}, {
  path: "".concat(_settings.roterPre, "/set_onescreen_setting"),
  name: 'auctionOnescreenSetting',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/auction/setting/screen_one_setting'));
    });
  },
  meta: {
    title: '单屏设置',
    noCache: true
  },
  hidden: true
}, {
  path: "".concat(_settings.roterPre, "/led"),
  name: 'auctionScreenled',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/led'));
    });
  },
  meta: {
    title: 'LED',
    noCache: true
  },
  hidden: true
}, {
  path: "".concat(_settings.roterPre, "/ledWideScreen"),
  name: 'ledWideScreen',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/ledWideScreen'));
    });
  },
  meta: {
    title: 'LED长屏',
    noCache: true
  },
  hidden: true
}, {
  path: "".concat(_settings.roterPre, "/ledThereCenter"),
  name: 'auctionScreenledThere',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/led3'));
    });
  },
  meta: {
    title: 'LED3-中',
    noCache: true
  },
  hidden: true
}, {
  path: "".concat(_settings.roterPre, "/ledThereLeft"),
  name: 'auctionScreenledThere',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/led3Left'));
    });
  },
  meta: {
    title: 'LED3-左',
    noCache: true
  },
  hidden: true
}, {
  path: "".concat(_settings.roterPre, "/ledThereRight"),
  name: 'auctionScreenledThere',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/led3Right'));
    });
  },
  meta: {
    title: 'LED3-右',
    noCache: true
  },
  hidden: true
}, {
  path: "".concat(_settings.roterPre, "/ledOneScreen"),
  name: 'auctionledOneScreen',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/ledOneScreen'));
    });
  },
  meta: {
    title: 'lED-单屏',
    noCache: true
  },
  hidden: true
}, {
  path: "".concat(_settings.roterPre, "/set_screen_projection"),
  name: 'auctionSetScreenProjection',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/auction/setting/screen_projection'));
    });
  },
  meta: {
    title: '投屏设置',
    noCache: true
  },
  hidden: true
}, {
  path: "".concat(_settings.roterPre, "/screen_projection"),
  name: 'auctionScreenProjection',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/screen_projection'));
    });
  },
  meta: {
    title: '现场投屏',
    noCache: true
  },
  hidden: true
}, {
  path: "".concat(_settings.roterPre, "/auctioneer"),
  name: 'auctionAuctioneer',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/auctioneer'));
    });
  },
  meta: {
    title: '主持人屏',
    noCache: true
  },
  hidden: true
}, {
  path: _settings.roterPre + '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/error-page/404'));
    });
  },
  hidden: true
}, {
  path: _settings.roterPre + '/setting/icons',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/components/iconFrom/index'));
    });
  },
  name: 'icons'
}, {
  path: _settings.roterPre + '/setting/uploadPicture',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/components/uploadPicture/index.vue'));
    });
  },
  name: 'uploadPicture'
}, {
  path: _settings.roterPre + '/setting/storeProduct',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/components/goodList/index.vue'));
    });
  },
  name: 'uploadPicture'
}, {
  path: _settings.roterPre + '/setting/broadcastProduct',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/components/importGoods/goodList.vue'));
    });
  },
  name: 'broadcastProduct'
}, {
  path: _settings.roterPre + '/setting/userList',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/components/userList/index.vue'));
    });
  },
  name: 'uploadPicture'
}, {
  path: _settings.roterPre + '/order/export',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/components/exportFile/index.vue'));
    });
  },
  name: 'exportFileList'
}, {
  path: _settings.roterPre + '/admin/widget/image',
  name: "images",
  meta: {
    title: '上传图片'
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/components/uploadPicture/index'));
    });
  }
}, {
  path: _settings.roterPre + '/admin/widget/video',
  name: "video",
  meta: {
    title: '上传视频'
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/components/uploadVideo/index'));
    });
  }
}, {
  path: "".concat(_settings.roterPre, "/recentlyLaunched"),
  component: _layout.default,
  children: [{
    path: ':type',
    meta: {
      title: '近期上线',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/error-page/recentlyLaunched/type'));
      });
    }
  }]
}, {
  path: "".concat(_settings.roterPre, "/nolimit"),
  component: _layout.default,
  meta: {
    title: '无权限',
    noCache: true
  },
  children: [{
    path: 'nolimit',
    meta: {
      title: '无权限',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/error-page/nolimit'));
      });
    }
  }]
}, {
  path: '*',
  redirect: _settings.roterPre + '/404',
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: 'history',
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;