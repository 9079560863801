var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: "background: " + _vm.facade.backgroundColor + ";" },
    [
      _vm.content.style == 1
        ? [
            _c(
              "div",
              {
                staticClass: "auction-list column-one",
                style:
                  "padding: " +
                  (_vm.isComponent ? 30 : 0) +
                  "px " +
                  _vm.facade.pageMargin / 2 +
                  "px",
              },
              _vm._l(_vm.defaultData, function (m, i) {
                return _c(
                  "div",
                  {
                    key: "a-c" + i,
                    staticClass: "item",
                    class: { m0: i == 0 },
                    style: {
                      background: _vm.facade.itemGradientBackground
                        ? "linear-gradient(" +
                          _vm.facade.itemGradientDirection +
                          ", " +
                          _vm.facade.itemBackground +
                          ", " +
                          _vm.facade.itemGradientBackground +
                          ")"
                        : _vm.facade.itemBackground,
                      "margin-top": _vm.facade.itemMarginTop / 2 + "px",
                      "border-top-left-radius":
                        _vm.facade.itemTopLeftRadius / 2 + "px",
                      "border-top-right-radius":
                        _vm.facade.itemTopRightRadius / 2 + "px",
                      "border-bottom-left-radius":
                        _vm.facade.itemBottomLeftRadius / 2 + "px",
                      "border-bottom-right-radius":
                        _vm.facade.itemBottomRightRadius / 2 + "px",
                      "box-shadow": _vm.facade.itemShadow
                        ? "0 0 " +
                          _vm.facade.itemShadowSize / 2 +
                          "px " +
                          _vm.facade.itemShadow
                        : "",
                      overflow: "hidden",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "img",
                        style: {
                          background: _vm.facade.imgBackground,
                          overflow: "hidden",
                          "border-top-left-radius":
                            _vm.facade.imgTopLeftRadius / 2 + "px",
                          "border-top-right-radius":
                            _vm.facade.imgTopRightRadius / 2 + "px",
                          "border-bottom-left-radius":
                            _vm.facade.imgBottomLeftRadius / 2 + "px",
                          "border-bottom-right-radius":
                            _vm.facade.imgBottomRightRadius / 2 + "px",
                        },
                      },
                      [_c("img", { attrs: { src: m.image } })]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "desc",
                        style:
                          "padding: " +
                          _vm.facade.descMarginTop / 2 +
                          "px " +
                          _vm.facade.descMarginRight / 2 +
                          "px " +
                          _vm.facade.descMarginBottom / 2 +
                          "px " +
                          _vm.facade.descMarginLeft / 2 +
                          "px;",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "lot",
                            style:
                              "color: " +
                              _vm.facade.lotColor +
                              ";font-weight: " +
                              _vm.facade.lotFontWeight +
                              ";font-size: " +
                              _vm.facade.lotFontSize / 2 +
                              "px;margin-top: " +
                              _vm.facade.lotMarginTop / 2 +
                              "px;",
                          },
                          [_vm._v("拍品 1001")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "name line2",
                            style:
                              "margin-top: " +
                              _vm.facade.nameMarginTop / 2 +
                              "px;font-size: " +
                              _vm.facade.nameFontSize / 2 +
                              "px;line-height: " +
                              _vm.facade.nameLineHeight / 2 +
                              "px;max-height: " +
                              _vm.facade.nameLineHeight +
                              "px;font-weight: " +
                              _vm.facade.nameFontWeight +
                              ";color: " +
                              _vm.facade.nameColor +
                              ";",
                          },
                          [
                            _vm._v(
                              "新疆天然和田玉青玉无暇好玉在新疆特大优惠专新疆天然和田玉青玉无暇好玉在新疆特大优惠专"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "store-info line1",
                            style:
                              "margin-top: " +
                              _vm.facade.attrMarginTop / 2 +
                              "px;font-size: " +
                              _vm.facade.attrFontSize / 2 +
                              "px;line-height: " +
                              _vm.facade.attrLineHeight / 2 +
                              "px;max-height: " +
                              _vm.facade.attrLineHeight / 2 +
                              "px;font-weight: " +
                              _vm.facade.attrFontWeight +
                              ";color: " +
                              _vm.facade.attrColor +
                              ";",
                          },
                          [_vm._v("拍品属性/拍品介绍")]
                        ),
                        _vm._v(" "),
                        i >= 2
                          ? _c(
                              "div",
                              {
                                staticClass: "flex align-items-c",
                                style:
                                  "color: " +
                                  _vm.facade.priceTextColor +
                                  ";font-weight: " +
                                  _vm.facade.priceTextFontWeight +
                                  ";font-size:" +
                                  _vm.facade.priceTextFontSize / 2 +
                                  "px;margin-top: " +
                                  _vm.facade.priceMarginTop / 2 +
                                  "px;",
                              },
                              [_c("span", [_vm._v("起拍价 RMB 1,000")])]
                            )
                          : i == 1
                          ? _c(
                              "div",
                              {
                                staticClass: "flex align-items-c",
                                style:
                                  "color: " +
                                  _vm.facade.priceColor +
                                  ";font-weight: " +
                                  _vm.facade.priceTextFontWeight +
                                  ";font-size:" +
                                  _vm.facade.priceTextFontSize / 2 +
                                  "px;margin-top: " +
                                  _vm.facade.priceMarginTop / 2 +
                                  "px;",
                              },
                              [_c("span", [_vm._v("当前价 RMB 1,000")])]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "flex align-items-c",
                                style:
                                  "color: " +
                                  _vm.facade.priceTextColor +
                                  ";font-weight: " +
                                  _vm.facade.priceTextFontWeight +
                                  ";font-size:" +
                                  _vm.facade.priceTextFontSize / 2 +
                                  "px;margin-top: " +
                                  _vm.facade.priceMarginTop / 2 +
                                  "px;",
                              },
                              [_c("span", [_vm._v("成交价 RMB 1,000")])]
                            ),
                        _vm._v(" "),
                        i >= 2
                          ? _c(
                              "div",
                              {
                                staticClass: "bid-count",
                                style:
                                  "margin-top: " +
                                  _vm.facade.appraisalPriceTop / 2 +
                                  "px;font-size: " +
                                  _vm.facade.appraisalPriceFontSize / 2 +
                                  "px;line-height: " +
                                  40 / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.appraisalPriceFontWeight +
                                  ";color: " +
                                  _vm.facade.appraisalPriceColor +
                                  ";",
                              },
                              [
                                _vm._v(
                                  "\n            估价 RMB 12,000-15,000\n          "
                                ),
                              ]
                            )
                          : i == 1
                          ? _c(
                              "div",
                              {
                                staticClass: "bid-now",
                                style:
                                  "margin-top: " +
                                  _vm.facade.appraisalPriceTop / 2 +
                                  "px;font-size: " +
                                  _vm.facade.appraisalPriceFontSize / 2 +
                                  "px;line-height: " +
                                  40 / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.ingStatusFontWeight +
                                  ";color: " +
                                  _vm.facade.ingStatusColor +
                                  ";",
                              },
                              [
                                _c("div", [_vm._v("拍卖中")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex-one",
                                    style:
                                      "margin-left: 0.213333rem;color: " +
                                      _vm.facade.bidCountColor +
                                      ";",
                                  },
                                  [_vm._v("出价5次")]
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "end-view",
                                style:
                                  "margin-top: " +
                                  _vm.facade.appraisalPriceTop / 2 +
                                  "px;font-size: " +
                                  _vm.facade.appraisalPriceFontSize / 2 +
                                  "px;line-height: " +
                                  40 / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.appraisalPriceFontWeight +
                                  ";color: " +
                                  _vm.facade.appraisalPriceColor +
                                  ";",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-right": "0.133333rem",
                                    },
                                  },
                                  [_vm._v("出价5次")]
                                ),
                                _vm._v(" "),
                                _c("div", [_vm._v("10月20日 18:30结拍")]),
                              ]
                            ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        : _vm.content.style == 2
        ? [
            _c(
              "div",
              {
                staticClass: "flex",
                style: {
                  padding:
                    (_vm.isComponent ? "30px" : "0") +
                    " " +
                    (_vm.facade.pageMargin > _vm.facade.itemMargin
                      ? (_vm.facade.pageMargin - _vm.facade.itemMargin) / 2
                      : 0) +
                    "px " +
                    (_vm.isComponent ? "30px" : "0") +
                    " " +
                    (_vm.facade.pageMargin > _vm.facade.itemMargin
                      ? _vm.facade.pageMargin / 2
                      : _vm.facade.itemMargin / 2) +
                    "px ",
                },
              },
              _vm._l(2, function (m, i) {
                return _c(
                  "div",
                  {
                    key: "auctionC" + i,
                    style: {
                      flex: 1,
                      "margin-right": _vm.facade.itemMargin / 2 + "px",
                      overflow: "hidden",
                    },
                  },
                  _vm._l(2, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: "auctionD" + index,
                        staticClass: "item",
                        class: { m0: index == 0 },
                        style: {
                          background: _vm.facade.itemGradientBackground
                            ? "linear-gradient(" +
                              _vm.facade.itemGradientDirection +
                              ", " +
                              _vm.facade.itemBackground +
                              ", " +
                              _vm.facade.itemGradientBackground +
                              ")"
                            : _vm.facade.itemBackground,
                          "margin-top": _vm.facade.itemMarginTop / 2 + "px",
                          "border-top-left-radius":
                            _vm.facade.itemTopLeftRadius / 2 + "px",
                          "border-top-right-radius":
                            _vm.facade.itemTopRightRadius / 2 + "px",
                          "border-bottom-left-radius":
                            _vm.facade.itemBottomLeftRadius / 2 + "px",
                          "border-bottom-right-radius":
                            _vm.facade.itemBottomRightRadius / 2 + "px",
                          "box-shadow": _vm.facade.itemShadow
                            ? "0 0 " +
                              _vm.facade.itemShadowSize / 2 +
                              "px " +
                              _vm.facade.itemShadow
                            : "",
                          overflow: "hidden",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "img",
                            style: {
                              background: _vm.facade.imgBackground,
                              overflow: "hidden",
                              "border-top-left-radius":
                                _vm.facade.imgTopLeftRadius / 2 + "px",
                              "border-top-right-radius":
                                _vm.facade.imgTopRightRadius / 2 + "px",
                              "border-bottom-left-radius":
                                _vm.facade.imgBottomLeftRadius / 2 + "px",
                              "border-bottom-right-radius":
                                _vm.facade.imgBottomRightRadius / 2 + "px",
                              height:
                                _vm.facade.imgStyle === 1 ? "auto" : "150px",
                            },
                          },
                          [
                            _c("el-image", {
                              staticStyle: {
                                width: "100%",
                                height: "100%",
                                display: "block",
                              },
                              attrs: {
                                src: _vm.defaultData[i * 2 + index].image,
                                fit:
                                  _vm.facade.imgStyle == 3
                                    ? "cover"
                                    : "contain",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "desc",
                            style:
                              "padding: " +
                              _vm.facade.descMarginTop / 2 +
                              "px " +
                              _vm.facade.descMarginRight / 2 +
                              "px " +
                              _vm.facade.descMarginBottom / 2 +
                              "px " +
                              _vm.facade.descMarginLeft / 2 +
                              "px;",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "lot",
                                style:
                                  "color: " +
                                  _vm.facade.lotColor +
                                  ";font-weight: " +
                                  _vm.facade.lotFontWeight +
                                  ";font-size: " +
                                  _vm.facade.lotFontSize / 2 +
                                  "px;margin-top: " +
                                  _vm.facade.lotMarginTop / 2 +
                                  "px;",
                              },
                              [_vm._v("拍品 1001")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "name line2",
                                style:
                                  "margin-top: " +
                                  _vm.facade.nameMarginTop / 2 +
                                  "px;font-size: " +
                                  _vm.facade.nameFontSize / 2 +
                                  "px;line-height: " +
                                  _vm.facade.nameLineHeight / 2 +
                                  "px;max-height: " +
                                  _vm.facade.nameLineHeight +
                                  "px;font-weight: " +
                                  _vm.facade.nameFontWeight +
                                  ";color: " +
                                  _vm.facade.nameColor +
                                  ";",
                              },
                              [
                                _vm._v(
                                  "新疆天然和田玉青玉无暇好玉在新疆特大优惠专新疆天然和田玉青玉无暇好玉在新疆特大优惠专"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "store-info line1",
                                style:
                                  "margin-top: " +
                                  _vm.facade.attrMarginTop / 2 +
                                  "px;font-size: " +
                                  _vm.facade.attrFontSize / 2 +
                                  "px;line-height: " +
                                  _vm.facade.attrLineHeight / 2 +
                                  "px;max-height: " +
                                  _vm.facade.attrLineHeight / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.attrFontWeight +
                                  ";color: " +
                                  _vm.facade.attrColor +
                                  ";",
                              },
                              [_vm._v("拍品属性/拍品介绍")]
                            ),
                            _vm._v(" "),
                            i == 1 && index == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "flex align-items-c",
                                    style:
                                      "color: " +
                                      _vm.facade.priceTextColor +
                                      ";font-weight: " +
                                      _vm.facade.priceTextFontWeight +
                                      ";font-size:" +
                                      _vm.facade.priceTextFontSize / 2 +
                                      "px;margin-top: " +
                                      _vm.facade.priceMarginTop / 2 +
                                      "px;",
                                  },
                                  [_c("span", [_vm._v("起拍价 RMB 1,000")])]
                                )
                              : i == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "flex align-items-c",
                                    style:
                                      "color: " +
                                      _vm.facade.priceColor +
                                      ";font-weight: " +
                                      _vm.facade.priceTextFontWeight +
                                      ";font-size:" +
                                      _vm.facade.priceTextFontSize / 2 +
                                      "px;margin-top: " +
                                      _vm.facade.priceMarginTop / 2 +
                                      "px;",
                                  },
                                  [_c("span", [_vm._v("当前价 RMB 1,000")])]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "flex align-items-c",
                                    style:
                                      "color: " +
                                      _vm.facade.priceTextColor +
                                      ";font-weight: " +
                                      _vm.facade.priceTextFontWeight +
                                      ";font-size:" +
                                      _vm.facade.priceTextFontSize / 2 +
                                      "px;margin-top: " +
                                      _vm.facade.priceMarginTop / 2 +
                                      "px;",
                                  },
                                  [_c("span", [_vm._v("成交价 RMB 1,000")])]
                                ),
                            _vm._v(" "),
                            i == 1 && index == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "bid-count",
                                    style:
                                      "margin-top: " +
                                      _vm.facade.appraisalPriceTop / 2 +
                                      "px;font-size: " +
                                      _vm.facade.appraisalPriceFontSize / 2 +
                                      "px;line-height: " +
                                      40 / 2 +
                                      "px;font-weight: " +
                                      _vm.facade.appraisalPriceFontWeight +
                                      ";color: " +
                                      _vm.facade.appraisalPriceColor +
                                      ";",
                                  },
                                  [
                                    _vm._v(
                                      "\n              估价 RMB 12,000-15,000\n            "
                                    ),
                                  ]
                                )
                              : i == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "bid-now",
                                    style:
                                      "margin-top: " +
                                      _vm.facade.appraisalPriceTop / 2 +
                                      "px;font-size: " +
                                      _vm.facade.appraisalPriceFontSize / 2 +
                                      "px;line-height: " +
                                      40 / 2 +
                                      "px;font-weight: " +
                                      _vm.facade.ingStatusFontWeight +
                                      ";color: " +
                                      _vm.facade.ingStatusColor +
                                      ";",
                                  },
                                  [
                                    _c("div", [_vm._v("拍卖中")]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex-one",
                                        style:
                                          "margin-left: 0.213333rem;color: " +
                                          _vm.facade.bidCountColor +
                                          ";",
                                      },
                                      [_vm._v("出价5次")]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "end-view",
                                    style:
                                      "margin-top: " +
                                      _vm.facade.appraisalPriceTop / 2 +
                                      "px;font-size: " +
                                      _vm.facade.appraisalPriceFontSize / 2 +
                                      "px;line-height: " +
                                      40 / 2 +
                                      "px;font-weight: " +
                                      _vm.facade.appraisalPriceFontWeight +
                                      ";color: " +
                                      _vm.facade.appraisalPriceColor +
                                      ";",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "margin-right": "0.133333rem",
                                        },
                                      },
                                      [_vm._v("出价5次")]
                                    ),
                                    _vm._v(" "),
                                    _c("div", [_vm._v("10月20日 18:30结拍")]),
                                  ]
                                ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                )
              }),
              0
            ),
          ]
        : _vm.content.style == 3
        ? [
            _c(
              "div",
              {
                staticClass: "flex flex-wrap",
                style: {
                  padding:
                    (_vm.isComponent ? "30px" : "0") +
                    " " +
                    (_vm.facade.pageMargin > _vm.facade.itemMargin
                      ? (_vm.facade.pageMargin - _vm.facade.itemMargin) / 2
                      : 0) +
                    "px " +
                    (_vm.isComponent ? "30px" : "0") +
                    " " +
                    (_vm.facade.pageMargin > _vm.facade.itemMargin
                      ? _vm.facade.pageMargin / 2
                      : _vm.facade.itemMargin / 2) +
                    "px ",
                },
              },
              _vm._l(_vm.defaultData, function (item, index) {
                return _c(
                  "div",
                  {
                    key: "auctionD" + index,
                    style: {
                      "margin-right": _vm.facade.itemMargin / 2 + "px",
                      overflow: "hidden",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        class: { m0: index < 3 },
                        style: {
                          background: _vm.facade.itemGradientBackground
                            ? "linear-gradient(" +
                              _vm.facade.itemGradientDirection +
                              ", " +
                              _vm.facade.itemBackground +
                              ", " +
                              _vm.facade.itemGradientBackground +
                              ")"
                            : _vm.facade.itemBackground,
                          "margin-top": _vm.facade.itemMarginTop / 2 + "px",
                          "border-top-left-radius":
                            _vm.facade.itemTopLeftRadius / 2 + "px",
                          "border-top-right-radius":
                            _vm.facade.itemTopRightRadius / 2 + "px",
                          "border-bottom-left-radius":
                            _vm.facade.itemBottomLeftRadius / 2 + "px",
                          "border-bottom-right-radius":
                            _vm.facade.itemBottomRightRadius / 2 + "px",
                          "box-shadow": _vm.facade.itemShadow
                            ? "0 0 " +
                              _vm.facade.itemShadowSize / 2 +
                              "px " +
                              _vm.facade.itemShadow
                            : "",
                          overflow: "hidden",
                          width: _vm.listThreeWidth,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "img",
                            style: {
                              background: _vm.facade.imgBackground,
                              overflow: "hidden",
                              "border-top-left-radius":
                                _vm.facade.imgTopLeftRadius / 2 + "px",
                              "border-top-right-radius":
                                _vm.facade.imgTopRightRadius / 2 + "px",
                              "border-bottom-left-radius":
                                _vm.facade.imgBottomLeftRadius / 2 + "px",
                              "border-bottom-right-radius":
                                _vm.facade.imgBottomRightRadius / 2 + "px",
                              height:
                                _vm.facade.imgStyle === 1 ? "auto" : "120px",
                            },
                          },
                          [
                            _c("el-image", {
                              staticStyle: {
                                width: "100%",
                                height: "100%",
                                display: "block",
                              },
                              attrs: {
                                src: item.image,
                                fit:
                                  _vm.facade.imgStyle == 3
                                    ? "cover"
                                    : "contain",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "desc",
                            style:
                              "padding: " +
                              _vm.facade.descMarginTop / 2 +
                              "px " +
                              _vm.facade.descMarginRight / 2 +
                              "px " +
                              _vm.facade.descMarginBottom / 2 +
                              "px " +
                              _vm.facade.descMarginLeft / 2 +
                              "px;",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "lot",
                                style:
                                  "color: " +
                                  _vm.facade.lotColor +
                                  ";font-weight: " +
                                  _vm.facade.lotFontWeight +
                                  ";font-size: " +
                                  _vm.facade.lotFontSize / 2 +
                                  "px;margin-top: " +
                                  _vm.facade.lotMarginTop / 2 +
                                  "px;",
                              },
                              [_vm._v("拍品 1001")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "name line2",
                                style:
                                  "margin-top: " +
                                  _vm.facade.nameMarginTop / 2 +
                                  "px;font-size: " +
                                  _vm.facade.nameFontSize / 2 +
                                  "px;line-height: " +
                                  _vm.facade.nameLineHeight / 2 +
                                  "px;max-height: " +
                                  _vm.facade.nameLineHeight +
                                  "px;font-weight: " +
                                  _vm.facade.nameFontWeight +
                                  ";color: " +
                                  _vm.facade.nameColor +
                                  ";",
                              },
                              [
                                _vm._v(
                                  "新疆天然和田玉青玉无暇好玉在新疆特大优惠专新疆天然和田玉青玉无暇好玉在新疆特大优惠专"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "store-info line1",
                                style:
                                  "margin-top: " +
                                  _vm.facade.attrMarginTop / 2 +
                                  "px;font-size: " +
                                  _vm.facade.attrFontSize / 2 +
                                  "px;line-height: " +
                                  _vm.facade.attrLineHeight / 2 +
                                  "px;max-height: " +
                                  _vm.facade.attrLineHeight / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.attrFontWeight +
                                  ";color: " +
                                  _vm.facade.attrColor +
                                  ";",
                              },
                              [_vm._v("拍品属性/拍品介绍")]
                            ),
                            _vm._v(" "),
                            _vm.i >= 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "flex align-items-c",
                                    style:
                                      "color: " +
                                      _vm.facade.priceTextColor +
                                      ";font-weight: " +
                                      _vm.facade.priceTextFontWeight +
                                      ";font-size:" +
                                      _vm.facade.priceTextFontSize / 2 +
                                      "px;margin-top: " +
                                      _vm.facade.priceMarginTop / 2 +
                                      "px;",
                                  },
                                  [_c("span", [_vm._v("起拍价 RMB 1,000")])]
                                )
                              : _vm.i == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "flex align-items-c",
                                    style:
                                      "color: " +
                                      _vm.facade.priceColor +
                                      ";font-weight: " +
                                      _vm.facade.priceTextFontWeight +
                                      ";font-size:" +
                                      _vm.facade.priceTextFontSize / 2 +
                                      "px;margin-top: " +
                                      _vm.facade.priceMarginTop / 2 +
                                      "px;",
                                  },
                                  [_c("span", [_vm._v("当前价 RMB 1,000")])]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "flex align-items-c",
                                    style:
                                      "color: " +
                                      _vm.facade.priceTextColor +
                                      ";font-weight: " +
                                      _vm.facade.priceTextFontWeight +
                                      ";font-size:" +
                                      _vm.facade.priceTextFontSize / 2 +
                                      "px;margin-top: " +
                                      _vm.facade.priceMarginTop / 2 +
                                      "px;",
                                  },
                                  [_c("span", [_vm._v("成交价 RMB 1,000")])]
                                ),
                            _vm._v(" "),
                            _vm.i >= 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "bid-count",
                                    style:
                                      "margin-top: " +
                                      _vm.facade.appraisalPriceTop / 2 +
                                      "px;font-size: " +
                                      _vm.facade.appraisalPriceFontSize / 2 +
                                      "px;line-height: " +
                                      40 / 2 +
                                      "px;font-weight: " +
                                      _vm.facade.appraisalPriceFontWeight +
                                      ";color: " +
                                      _vm.facade.appraisalPriceColor +
                                      ";",
                                  },
                                  [
                                    _vm._v(
                                      "\n              估价 RMB 12,000-15,000\n            "
                                    ),
                                  ]
                                )
                              : _vm.i == 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "bid-now",
                                    style:
                                      "margin-top: " +
                                      _vm.facade.appraisalPriceTop / 2 +
                                      "px;font-size: " +
                                      _vm.facade.appraisalPriceFontSize / 2 +
                                      "px;line-height: " +
                                      40 / 2 +
                                      "px;font-weight: " +
                                      _vm.facade.ingStatusFontWeight +
                                      ";color: " +
                                      _vm.facade.ingStatusColor +
                                      ";",
                                  },
                                  [
                                    _c("div", [_vm._v("拍卖中")]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex-one",
                                        style:
                                          "margin-left: 0.213333rem;color: " +
                                          _vm.facade.bidCountColor +
                                          ";",
                                      },
                                      [_vm._v("出价5次")]
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "end-view",
                                    style:
                                      "margin-top: " +
                                      _vm.facade.appraisalPriceTop / 2 +
                                      "px;font-size: " +
                                      _vm.facade.appraisalPriceFontSize / 2 +
                                      "px;line-height: " +
                                      40 / 2 +
                                      "px;font-weight: " +
                                      _vm.facade.appraisalPriceFontWeight +
                                      ";color: " +
                                      _vm.facade.appraisalPriceColor +
                                      ";",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "margin-right": "0.133333rem",
                                        },
                                      },
                                      [_vm._v("出价5次")]
                                    ),
                                    _vm._v(" "),
                                    _c("div", [_vm._v("10月20日 18:30结拍")]),
                                  ]
                                ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        : _vm.content.style == 4
        ? [
            _c(
              "div",
              {
                staticClass: "auction-column-line",
                style:
                  "padding: " +
                  (_vm.isComponent ? 30 : 0) +
                  "px " +
                  _vm.facade.pageMargin / 2 +
                  "px",
              },
              _vm._l(_vm.defaultData, function (m, i) {
                return _c(
                  "div",
                  {
                    key: "a-c" + i,
                    staticClass: "item flex",
                    class: { m0: i == 0 },
                    style: {
                      background: _vm.facade.itemGradientBackground
                        ? "linear-gradient(" +
                          _vm.facade.itemGradientDirection +
                          ", " +
                          _vm.facade.itemBackground +
                          ", " +
                          _vm.facade.itemGradientBackground +
                          ")"
                        : _vm.facade.itemBackground,
                      "margin-top": _vm.facade.itemMarginTop / 2 + "px",
                      "border-top-left-radius":
                        _vm.facade.itemTopLeftRadius / 2 + "px",
                      "border-top-right-radius":
                        _vm.facade.itemTopRightRadius / 2 + "px",
                      "border-bottom-left-radius":
                        _vm.facade.itemBottomLeftRadius / 2 + "px",
                      "border-bottom-right-radius":
                        _vm.facade.itemBottomRightRadius / 2 + "px",
                      "box-shadow": _vm.facade.itemShadow
                        ? "0 0 " +
                          _vm.facade.itemShadowSize / 2 +
                          "px " +
                          _vm.facade.itemShadow
                        : "",
                      overflow: "hidden",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "img",
                        style: {
                          width: _vm.facade.imgWidth / 2 + "px",
                          height: _vm.facade.imgHeight / 2 + "px",
                          background: _vm.facade.imgBackground,
                          overflow: "hidden",
                          "border-top-left-radius":
                            _vm.facade.imgTopLeftRadius / 2 + "px",
                          "border-top-right-radius":
                            _vm.facade.imgTopRightRadius / 2 + "px",
                          "border-bottom-left-radius":
                            _vm.facade.imgBottomLeftRadius / 2 + "px",
                          "border-bottom-right-radius":
                            _vm.facade.imgBottomRightRadius / 2 + "px",
                        },
                      },
                      [
                        _c("el-image", {
                          staticStyle: {
                            width: "100%",
                            height: "100%",
                            display: "block",
                          },
                          attrs: {
                            src: m.image,
                            fit:
                              _vm.facade.imgStyle === 2 ? "contain" : "cover",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "desc flex-one",
                        style:
                          "padding: " +
                          _vm.facade.descMarginTop / 2 +
                          "px " +
                          _vm.facade.descMarginRight / 2 +
                          "px " +
                          _vm.facade.descMarginBottom / 2 +
                          "px " +
                          _vm.facade.descMarginLeft / 2 +
                          "px;",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "lot",
                            style:
                              "color: " +
                              _vm.facade.lotColor +
                              ";font-weight: " +
                              _vm.facade.lotFontWeight +
                              ";font-size: " +
                              _vm.facade.lotFontSize / 2 +
                              "px;margin-top: " +
                              _vm.facade.lotMarginTop / 2 +
                              "px;",
                          },
                          [_vm._v("拍品 1001")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "name line2",
                            style:
                              "margin-top: " +
                              _vm.facade.nameMarginTop / 2 +
                              "px;font-size: " +
                              _vm.facade.nameFontSize / 2 +
                              "px;line-height: " +
                              _vm.facade.nameLineHeight / 2 +
                              "px;max-height: " +
                              _vm.facade.nameLineHeight +
                              "px;font-weight: " +
                              _vm.facade.nameFontWeight +
                              ";color: " +
                              _vm.facade.nameColor +
                              ";",
                          },
                          [
                            _vm._v(
                              "新疆天然和田玉青玉无暇好玉在新疆特大优惠专新疆天然和田玉青玉无暇好玉在新疆特大优惠专"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "store-info line1",
                            style:
                              "margin-top: " +
                              _vm.facade.attrMarginTop / 2 +
                              "px;font-size: " +
                              _vm.facade.attrFontSize / 2 +
                              "px;line-height: " +
                              _vm.facade.attrLineHeight / 2 +
                              "px;max-height: " +
                              _vm.facade.attrLineHeight / 2 +
                              "px;font-weight: " +
                              _vm.facade.attrFontWeight +
                              ";color: " +
                              _vm.facade.attrColor +
                              ";",
                          },
                          [_vm._v("拍品属性/拍品介绍")]
                        ),
                        _vm._v(" "),
                        i >= 2
                          ? _c(
                              "div",
                              {
                                staticClass: "flex align-items-c",
                                style:
                                  "color: " +
                                  _vm.facade.priceTextColor +
                                  ";font-weight: " +
                                  _vm.facade.priceTextFontWeight +
                                  ";font-size:" +
                                  _vm.facade.priceTextFontSize / 2 +
                                  "px;margin-top: " +
                                  _vm.facade.priceMarginTop / 2 +
                                  "px;",
                              },
                              [_c("span", [_vm._v("起拍价 RMB 1,000")])]
                            )
                          : i == 1
                          ? _c(
                              "div",
                              {
                                staticClass: "flex align-items-c",
                                style:
                                  "color: " +
                                  _vm.facade.priceColor +
                                  ";font-weight: " +
                                  _vm.facade.priceTextFontWeight +
                                  ";font-size:" +
                                  _vm.facade.priceTextFontSize / 2 +
                                  "px;margin-top: " +
                                  _vm.facade.priceMarginTop / 2 +
                                  "px;",
                              },
                              [_c("span", [_vm._v("当前价 RMB 1,000")])]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "flex align-items-c",
                                style:
                                  "color: " +
                                  _vm.facade.priceTextColor +
                                  ";font-weight: " +
                                  _vm.facade.priceTextFontWeight +
                                  ";font-size:" +
                                  _vm.facade.priceTextFontSize / 2 +
                                  "px;margin-top: " +
                                  _vm.facade.priceMarginTop / 2 +
                                  "px;",
                              },
                              [_c("span", [_vm._v("成交价 RMB 1,000")])]
                            ),
                        _vm._v(" "),
                        i >= 2
                          ? _c(
                              "div",
                              {
                                staticClass: "bid-count",
                                style:
                                  "margin-top: " +
                                  _vm.facade.appraisalPriceTop / 2 +
                                  "px;font-size: " +
                                  _vm.facade.appraisalPriceFontSize / 2 +
                                  "px;line-height: " +
                                  40 / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.appraisalPriceFontWeight +
                                  ";color: " +
                                  _vm.facade.appraisalPriceColor +
                                  ";",
                              },
                              [
                                _vm._v(
                                  "\n            估价 RMB 12,000-15,000\n          "
                                ),
                              ]
                            )
                          : i == 1
                          ? _c(
                              "div",
                              {
                                staticClass: "bid-now",
                                style:
                                  "margin-top: " +
                                  _vm.facade.appraisalPriceTop / 2 +
                                  "px;font-size: " +
                                  _vm.facade.appraisalPriceFontSize / 2 +
                                  "px;line-height: " +
                                  40 / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.ingStatusFontWeight +
                                  ";color: " +
                                  _vm.facade.ingStatusColor +
                                  ";",
                              },
                              [
                                _c("div", [_vm._v("拍卖中")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex-one",
                                    style:
                                      "margin-left: 0.213333rem;color: " +
                                      _vm.facade.bidCountColor +
                                      ";",
                                  },
                                  [_vm._v("出价5次")]
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "end-view",
                                style:
                                  "margin-top: " +
                                  _vm.facade.appraisalPriceTop / 2 +
                                  "px;font-size: " +
                                  _vm.facade.appraisalPriceFontSize / 2 +
                                  "px;line-height: " +
                                  40 / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.appraisalPriceFontWeight +
                                  ";color: " +
                                  _vm.facade.appraisalPriceColor +
                                  ";",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-right": "0.133333rem",
                                    },
                                  },
                                  [_vm._v("出价5次")]
                                ),
                                _vm._v(" "),
                                _c("div", [_vm._v("10月20日 18:30结拍")]),
                              ]
                            ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        : _vm.content.style == 5
        ? [
            _c(
              "div",
              {
                staticClass: "auction-column flex",
                style:
                  "padding: " +
                  (_vm.isComponent ? 30 : 0) +
                  "px " +
                  _vm.facade.pageMargin / 2 +
                  "px;overflow: hidden;",
              },
              _vm._l(_vm.defaultData, function (m, i) {
                return _c(
                  "div",
                  {
                    key: "a-c" + i,
                    staticClass: "item",
                    style: {
                      background: _vm.facade.itemGradientBackground
                        ? "linear-gradient(" +
                          _vm.facade.itemGradientDirection +
                          ", " +
                          _vm.facade.itemBackground +
                          ", " +
                          _vm.facade.itemGradientBackground +
                          ")"
                        : _vm.facade.itemBackground,
                      "border-top-left-radius":
                        _vm.facade.itemTopLeftRadius / 2 + "px",
                      "border-top-right-radius":
                        _vm.facade.itemTopRightRadius / 2 + "px",
                      "border-bottom-left-radius":
                        _vm.facade.itemBottomLeftRadius / 2 + "px",
                      "border-bottom-right-radius":
                        _vm.facade.itemBottomRightRadius / 2 + "px",
                      "box-shadow": _vm.facade.itemShadow
                        ? "0 0 " +
                          _vm.facade.itemShadowSize / 2 +
                          "px " +
                          _vm.facade.itemShadow
                        : "",
                      overflow: "hidden",
                      width: _vm.facade.imgWidth / 2 + "px",
                      "margin-right": _vm.facade.itemMargin / 2 + "px",
                      "flex-shrink": 0,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "img",
                        style: {
                          width: _vm.facade.imgWidth / 2 + "px",
                          height: _vm.facade.imgHeight / 2 + "px",
                          background: _vm.facade.imgBackground,
                          overflow: "hidden",
                          "border-top-left-radius":
                            _vm.facade.imgTopLeftRadius / 2 + "px",
                          "border-top-right-radius":
                            _vm.facade.imgTopRightRadius / 2 + "px",
                          "border-bottom-left-radius":
                            _vm.facade.imgBottomLeftRadius / 2 + "px",
                          "border-bottom-right-radius":
                            _vm.facade.imgBottomRightRadius / 2 + "px",
                        },
                      },
                      [
                        _c("el-image", {
                          staticStyle: {
                            width: "100%",
                            height: "100%",
                            display: "block",
                          },
                          attrs: {
                            src: m.image,
                            fit:
                              _vm.facade.imgStyle === 2 ? "contain" : "cover",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "desc",
                        style:
                          "padding: " +
                          _vm.facade.descMarginTop / 2 +
                          "px " +
                          _vm.facade.descMarginRight / 2 +
                          "px " +
                          _vm.facade.descMarginBottom / 2 +
                          "px " +
                          _vm.facade.descMarginLeft / 2 +
                          "px;",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "lot",
                            style:
                              "color: " +
                              _vm.facade.lotColor +
                              ";font-weight: " +
                              _vm.facade.lotFontWeight +
                              ";font-size: " +
                              _vm.facade.lotFontSize / 2 +
                              "px;margin-top: " +
                              _vm.facade.lotMarginTop / 2 +
                              "px;",
                          },
                          [_vm._v("拍品 1001")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "name line2",
                            style:
                              "margin-top: " +
                              _vm.facade.nameMarginTop / 2 +
                              "px;font-size: " +
                              _vm.facade.nameFontSize / 2 +
                              "px;line-height: " +
                              _vm.facade.nameLineHeight / 2 +
                              "px;max-height: " +
                              _vm.facade.nameLineHeight +
                              "px;font-weight: " +
                              _vm.facade.nameFontWeight +
                              ";color: " +
                              _vm.facade.nameColor +
                              ";",
                          },
                          [
                            _vm._v(
                              "新疆天然和田玉青玉无暇好玉在新疆特大优惠专新疆天然和田玉青玉无暇好玉在新疆特大优惠专"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "store-info line1",
                            style:
                              "margin-top: " +
                              _vm.facade.attrMarginTop / 2 +
                              "px;font-size: " +
                              _vm.facade.attrFontSize / 2 +
                              "px;line-height: " +
                              _vm.facade.attrLineHeight / 2 +
                              "px;max-height: " +
                              _vm.facade.attrLineHeight / 2 +
                              "px;font-weight: " +
                              _vm.facade.attrFontWeight +
                              ";color: " +
                              _vm.facade.attrColor +
                              ";",
                          },
                          [_vm._v("拍品属性/拍品介绍")]
                        ),
                        _vm._v(" "),
                        i >= 2
                          ? _c(
                              "div",
                              {
                                staticClass: "flex align-items-c",
                                style:
                                  "color: " +
                                  _vm.facade.priceTextColor +
                                  ";font-weight: " +
                                  _vm.facade.priceTextFontWeight +
                                  ";font-size:" +
                                  _vm.facade.priceTextFontSize / 2 +
                                  "px;margin-top: " +
                                  _vm.facade.priceMarginTop / 2 +
                                  "px;",
                              },
                              [_c("span", [_vm._v("起拍价 RMB 1,000")])]
                            )
                          : i == 1
                          ? _c(
                              "div",
                              {
                                staticClass: "flex align-items-c",
                                style:
                                  "color: " +
                                  _vm.facade.priceColor +
                                  ";font-weight: " +
                                  _vm.facade.priceTextFontWeight +
                                  ";font-size:" +
                                  _vm.facade.priceTextFontSize / 2 +
                                  "px;margin-top: " +
                                  _vm.facade.priceMarginTop / 2 +
                                  "px;",
                              },
                              [_c("span", [_vm._v("当前价 RMB 1,000")])]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "flex align-items-c",
                                style:
                                  "color: " +
                                  _vm.facade.priceTextColor +
                                  ";font-weight: " +
                                  _vm.facade.priceTextFontWeight +
                                  ";font-size:" +
                                  _vm.facade.priceTextFontSize / 2 +
                                  "px;margin-top: " +
                                  _vm.facade.priceMarginTop / 2 +
                                  "px;",
                              },
                              [_c("span", [_vm._v("成交价 RMB 1,000")])]
                            ),
                        _vm._v(" "),
                        i >= 2
                          ? _c(
                              "div",
                              {
                                staticClass: "bid-count",
                                style:
                                  "margin-top: " +
                                  _vm.facade.appraisalPriceTop / 2 +
                                  "px;font-size: " +
                                  _vm.facade.appraisalPriceFontSize / 2 +
                                  "px;line-height: " +
                                  40 / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.appraisalPriceFontWeight +
                                  ";color: " +
                                  _vm.facade.appraisalPriceColor +
                                  ";",
                              },
                              [
                                _vm._v(
                                  "\n            估价 RMB 12,000-15,000\n          "
                                ),
                              ]
                            )
                          : i == 1
                          ? _c(
                              "div",
                              {
                                staticClass: "bid-now",
                                style:
                                  "margin-top: " +
                                  _vm.facade.appraisalPriceTop / 2 +
                                  "px;font-size: " +
                                  _vm.facade.appraisalPriceFontSize / 2 +
                                  "px;line-height: " +
                                  40 / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.ingStatusFontWeight +
                                  ";color: " +
                                  _vm.facade.ingStatusColor +
                                  ";",
                              },
                              [
                                _c("div", [_vm._v("拍卖中")]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex-one",
                                    style:
                                      "margin-left: 0.213333rem;color: " +
                                      _vm.facade.bidCountColor +
                                      ";",
                                  },
                                  [_vm._v("出价5次")]
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "end-view",
                                style:
                                  "margin-top: " +
                                  _vm.facade.appraisalPriceTop / 2 +
                                  "px;font-size: " +
                                  _vm.facade.appraisalPriceFontSize / 2 +
                                  "px;line-height: " +
                                  40 / 2 +
                                  "px;font-weight: " +
                                  _vm.facade.appraisalPriceFontWeight +
                                  ";color: " +
                                  _vm.facade.appraisalPriceColor +
                                  ";",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-right": "0.133333rem",
                                    },
                                  },
                                  [_vm._v("出价5次")]
                                ),
                                _vm._v(" "),
                                _c("div", [_vm._v("10月20日 18:30结拍")]),
                              ]
                            ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        : _vm.content.style == 6
        ? [
            _c(
              "div",
              {
                style:
                  "padding: " +
                  (_vm.isComponent ? 30 : 0) +
                  "px " +
                  _vm.facade.pageMargin / 2 +
                  "px;overflow: hidden;",
              },
              _vm._l(_vm.facade.line || 1, function (item) {
                return _c(
                  "div",
                  {
                    key: "line" + item,
                    staticClass: "auction-column flex",
                    style:
                      "margin-top: " +
                      (item != 1 ? _vm.facade.auctionLineMargin : 0) / 2 +
                      "px",
                  },
                  _vm._l(_vm.defaultData, function (m, i) {
                    return _c(
                      "div",
                      {
                        key: "a-c" + i,
                        staticClass: "item",
                        style: {
                          background: _vm.facade.itemGradientBackground
                            ? "linear-gradient(" +
                              _vm.facade.itemGradientDirection +
                              ", " +
                              _vm.facade.itemBackground +
                              ", " +
                              _vm.facade.itemGradientBackground +
                              ")"
                            : _vm.facade.itemBackground,
                          "border-top-left-radius":
                            _vm.facade.itemTopLeftRadius / 2 + "px",
                          "border-top-right-radius":
                            _vm.facade.itemTopRightRadius / 2 + "px",
                          "border-bottom-left-radius":
                            _vm.facade.itemBottomLeftRadius / 2 + "px",
                          "border-bottom-right-radius":
                            _vm.facade.itemBottomRightRadius / 2 + "px",
                          "box-shadow": _vm.facade.itemShadow
                            ? "0 0 " +
                              _vm.facade.itemShadowSize / 2 +
                              "px " +
                              _vm.facade.itemShadow
                            : "",
                          overflow: "hidden",
                          width: _vm.facade.imgWidth / 2 + "px",
                          "margin-right": _vm.facade.itemMargin / 2 + "px",
                          "flex-shrink": 0,
                          position: "relative",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "img",
                            style: {
                              width: _vm.facade.imgWidth / 2 + "px",
                              height: _vm.facade.imgHeight / 2 + "px",
                              background: _vm.facade.imgBackground,
                              overflow: "hidden",
                              "border-top-left-radius":
                                _vm.facade.imgTopLeftRadius / 2 + "px",
                              "border-top-right-radius":
                                _vm.facade.imgTopRightRadius / 2 + "px",
                              "border-bottom-left-radius":
                                _vm.facade.imgBottomLeftRadius / 2 + "px",
                              "border-bottom-right-radius":
                                _vm.facade.imgBottomRightRadius / 2 + "px",
                            },
                          },
                          [
                            _c("el-image", {
                              staticStyle: {
                                width: "100%",
                                height: "100%",
                                display: "block",
                              },
                              attrs: { src: m.image, fit: "cover" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            style: {
                              position: "absolute",
                              left: 0,
                              bottom: 0,
                              right: 0,
                              background:
                                "linear-gradient(to bottom, " +
                                _vm.nameBackground(0) +
                                " 0%, " +
                                _vm.nameBackground(0.5) +
                                "100%)",
                              padding:
                                _vm.facade.descMarginTop / 2 +
                                "px " +
                                _vm.facade.descMarginRight / 2 +
                                "px " +
                                _vm.facade.descMarginBottom / 2 +
                                "px " +
                                _vm.facade.descMarginLeft / 2 +
                                "px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "name line1",
                                style: {
                                  color: _vm.facade.nameColor,
                                  "font-weight": _vm.facade.nameFontWeight,
                                  "font-size":
                                    _vm.facade.nameFontSize / 2 + "px",
                                },
                              },
                              [_vm._v("LOT109 " + _vm._s(m.name))]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                )
              }),
              0
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }