var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox" },
          [
            _c(
              "el-dropdown",
              { attrs: { type: "primary", placement: "bottom" } },
              [
                _c("el-button", { attrs: { type: "primary", size: "small" } }, [
                  _vm._v("导出成交数据"),
                ]),
                _vm._v(" "),
                _c(
                  "el-dropdown-menu",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: "merchantSystemExcelExport:exportStatistical",
                        expression:
                          "'merchantSystemExcelExport:exportStatistical'",
                      },
                    ],
                    attrs: { slot: "dropdown" },
                    slot: "dropdown",
                  },
                  [
                    _c(
                      "el-dropdown-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: { click: _vm.showExportField },
                          },
                          [_vm._v("导出成交数据表格")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: { click: _vm.getExportssFileList },
                          },
                          [_vm._v("成交数据导出记录")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dropdown",
              { attrs: { type: "primary", placement: "bottom" } },
              [
                _c("el-button", { attrs: { type: "primary", size: "small" } }, [
                  _vm._v("导出排行数据"),
                ]),
                _vm._v(" "),
                _c(
                  "el-dropdown-menu",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: "merchantSystemExcelExport:exportStatistical",
                        expression:
                          "'merchantSystemExcelExport:exportStatistical'",
                      },
                    ],
                    attrs: { slot: "dropdown" },
                    slot: "dropdown",
                  },
                  [
                    _c(
                      "el-dropdown-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: { click: _vm.exportTJ },
                          },
                          [_vm._v("导出表格")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: { click: _vm.getExportTJ },
                          },
                          [_vm._v("导出记录")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "top-data flex" }, [
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "t" }, [
            _vm._v(_vm._s(_vm.venueStatistical.deal_price)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "b" }, [_vm._v("总成交额")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "t" }, [
            _vm._v(_vm._s(_vm.venueStatistical.deal_ratio || 0) + "%"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "c" }, [
            _c("span", [_vm._v(_vm._s(_vm.venueStatistical.deal_num || 0))]),
            _vm._v("/" + _vm._s(_vm.venueStatistical.auction_num || 0) + "件"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "b" }, [_vm._v("成交率")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "t" }, [
            _vm._v(_vm._s(_vm.venueStatistical.click_uv)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "b" }, [_vm._v("围观人数")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "t" }, [
            _vm._v(_vm._s(_vm.venueStatistical.number_num)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "b" }, [_vm._v("办牌人数")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "t" }, [
            _vm._v(_vm._s(_vm.venueStatistical.deal_user_num)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "b" }, [_vm._v("成交买家人数")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "t" }, [
            _vm._v(_vm._s(_vm.venueStatistical.online_bid_num)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "b" }, [_vm._v("网络买家出价次数")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "t" }, [
            _vm._v(_vm._s(_vm.venueStatistical.offline_bid_num)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "b" }, [_vm._v("现场买家出价次数")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "album-data-list flex" }, [
        _c(
          "div",
          { staticClass: "album-data-l" },
          _vm._l(_vm.albumStatistical, function (m, i) {
            return _c("div", { key: "album" + i }, [
              i % 2 == 0
                ? _c(
                    "div",
                    {
                      staticClass: "item",
                      on: {
                        click: function ($event) {
                          return _vm.goAlbumDataAction(m.album_id, m.album_name)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "l" }, [
                        _c("div", { staticClass: "t" }, [
                          _vm._v(_vm._s(m.album_name)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "b" }, [
                          _vm._v("成交额："),
                          _c("span", { staticClass: "h" }, [
                            _vm._v(
                              "￥" +
                                _vm._s(_vm.formatPriceNumber(m.deal_price, 2))
                            ),
                          ]),
                          _vm._v("成交率："),
                          _c("span", [_vm._v(_vm._s(m.deal_ratio) + "%")]),
                          _c("span", { staticClass: "bold" }, [
                            _vm._v(_vm._s(m.deal_num)),
                          ]),
                          _c("span", { staticClass: "gray" }, [
                            _vm._v("/" + _vm._s(m.auction_num) + "件"),
                          ]),
                          _vm._v("\n            实际支付金额："),
                          _c("span", [
                            _vm._v(
                              "￥" +
                                _vm._s(
                                  _vm.formatPriceNumber(
                                    m.pay_price ? m.pay_price : 0,
                                    2
                                  )
                                )
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "r" },
                        [
                          _c(
                            "el-button",
                            { attrs: { type: "text", size: "mini" } },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "commonlook" },
                              }),
                              _vm._v("查看详情"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "data" }, [
                        _vm._v("占比" + _vm._s(m.price_ratio) + "%"),
                      ]),
                    ]
                  )
                : _vm._e(),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "album-data-r" },
          _vm._l(_vm.albumStatistical, function (m, i) {
            return _c("div", { key: "album" + i }, [
              i % 2 != 0
                ? _c(
                    "div",
                    {
                      staticClass: "item",
                      on: {
                        click: function ($event) {
                          return _vm.goAlbumDataAction(m.album_id, m.album_name)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "l" }, [
                        _c("div", { staticClass: "t" }, [
                          _vm._v(_vm._s(m.album_name)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "b" }, [
                          _vm._v("成交额："),
                          _c("span", { staticClass: "h" }, [
                            _vm._v(
                              "￥" +
                                _vm._s(_vm.formatPriceNumber(m.deal_price, 2))
                            ),
                          ]),
                          _vm._v("成交率："),
                          _c("span", [_vm._v(_vm._s(m.deal_ratio) + "%")]),
                          _c("span", { staticClass: "bold" }, [
                            _vm._v(_vm._s(m.deal_num)),
                          ]),
                          _c("span", { staticClass: "gray" }, [
                            _vm._v("/" + _vm._s(m.auction_num) + "件"),
                          ]),
                          _vm._v("\n            实际支付金额："),
                          _c("span", [
                            _vm._v(
                              "￥" +
                                _vm._s(
                                  _vm.formatPriceNumber(
                                    m.pay_price ? m.pay_price : 0,
                                    2
                                  )
                                )
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "r" },
                        [
                          _c(
                            "el-button",
                            { attrs: { type: "text", size: "mini" } },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "commonlook" },
                              }),
                              _vm._v("查看详情"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "data" }, [
                        _vm._v("占比" + _vm._s(m.price_ratio) + "%"),
                      ]),
                    ]
                  )
                : _vm._e(),
            ])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "echarts-data" }, [
        _c("div", { staticClass: "l" }, [
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "title flex align-items-c" }, [
              _c("div", { staticClass: "flex-one lt" }, [
                _vm._v("成交类目占比"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      on: {
                        change: function ($event) {
                          return _vm.changeTypeAction("echart1")
                        },
                      },
                      model: {
                        value: _vm.echart1.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.echart1, "type", $$v)
                        },
                        expression: "echart1.type",
                      },
                    },
                    [
                      _c(
                        "el-radio-button",
                        { attrs: { label: "userNumData" } },
                        [_vm._v("人数")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio-button",
                        { attrs: { label: "dealNumData" } },
                        [_vm._v("成交件数")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio-button",
                        { attrs: { label: "dealPriceData" } },
                        [_vm._v("成交额")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.echart1.noData
              ? _c(
                  "div",
                  { staticClass: "d-pos item-flex-center ft14 fWeight500" },
                  [_vm._v("暂无数据统计")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { ref: "echart1", staticClass: "d" }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item item1" }, [
            _c("div", { staticClass: "title flex align-items-c" }, [
              _c("div", { staticClass: "flex-one lt" }, [
                _vm._v("买家性别占比"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      on: {
                        change: function ($event) {
                          return _vm.changeTypeAction("echart3")
                        },
                      },
                      model: {
                        value: _vm.echart3.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.echart3, "type", $$v)
                        },
                        expression: "echart3.type",
                      },
                    },
                    [
                      _c(
                        "el-radio-button",
                        { attrs: { label: "userNumData" } },
                        [_vm._v("人数")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio-button",
                        { attrs: { label: "dealNumData" } },
                        [_vm._v("成交件数")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio-button",
                        { attrs: { label: "dealPriceData" } },
                        [_vm._v("成交额")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.echart3.noData
              ? _c(
                  "div",
                  { staticClass: "d1-pos item-flex-center ft14 fWeight500" },
                  [_vm._v("暂无数据统计")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { ref: "echart3", staticClass: "d1" }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item item1" }, [
            _c("div", { staticClass: "title flex align-items-c" }, [
              _c("div", { staticClass: "flex-one lt" }, [
                _vm._v("号牌类型成交占比"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      on: {
                        change: function ($event) {
                          return _vm.changeTypeAction("echart5")
                        },
                      },
                      model: {
                        value: _vm.echart5.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.echart5, "type", $$v)
                        },
                        expression: "echart5.type",
                      },
                    },
                    [
                      _c(
                        "el-radio-button",
                        { attrs: { label: "userNumData" } },
                        [_vm._v("人数")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio-button",
                        { attrs: { label: "dealNumData" } },
                        [_vm._v("成交件数")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio-button",
                        { attrs: { label: "dealPriceData" } },
                        [_vm._v("成交额")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.echart5.noData
              ? _c(
                  "div",
                  { staticClass: "d1-pos item-flex-center ft14 fWeight500" },
                  [_vm._v("暂无数据统计")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { ref: "echart5", staticClass: "d1" }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "r" }, [
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "title flex align-items-c" }, [
              _c("div", { staticClass: "flex-one lt" }, [
                _vm._v("买家地域占比"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      on: {
                        change: function ($event) {
                          return _vm.changeTypeAction("echart2")
                        },
                      },
                      model: {
                        value: _vm.echart2.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.echart2, "type", $$v)
                        },
                        expression: "echart2.type",
                      },
                    },
                    [
                      _c(
                        "el-radio-button",
                        { attrs: { label: "userNumData" } },
                        [_vm._v("人数")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio-button",
                        { attrs: { label: "dealNumData" } },
                        [_vm._v("成交件数")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio-button",
                        { attrs: { label: "dealPriceData" } },
                        [_vm._v("成交额")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.echart2.noData
              ? _c(
                  "div",
                  { staticClass: "d-pos item-flex-center ft14 fWeight500" },
                  [_vm._v("暂无数据统计")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { ref: "echart2", staticClass: "d" }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item item1" }, [
            _c("div", { staticClass: "title flex align-items-c" }, [
              _c("div", { staticClass: "flex-one lt" }, [
                _vm._v("新老买家占比"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      on: {
                        change: function ($event) {
                          return _vm.changeTypeAction("echart4")
                        },
                      },
                      model: {
                        value: _vm.echart4.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.echart4, "type", $$v)
                        },
                        expression: "echart4.type",
                      },
                    },
                    [
                      _c(
                        "el-radio-button",
                        { attrs: { label: "userNumData" } },
                        [_vm._v("人数")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio-button",
                        { attrs: { label: "dealNumData" } },
                        [_vm._v("成交件数")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio-button",
                        { attrs: { label: "dealPriceData" } },
                        [_vm._v("成交额")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.echart4.noData
              ? _c(
                  "div",
                  { staticClass: "d1-pos item-flex-center ft14 fWeight500" },
                  [_vm._v("暂无数据统计")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { ref: "echart4", staticClass: "d1" }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item item1" }, [
            _c("div", { staticClass: "title flex align-items-c" }, [
              _c("div", { staticClass: "flex-one lt" }, [
                _vm._v("普通号牌和免保号牌占比"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      on: {
                        change: function ($event) {
                          return _vm.changeTypeAction("echart6")
                        },
                      },
                      model: {
                        value: _vm.echart6.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.echart6, "type", $$v)
                        },
                        expression: "echart6.type",
                      },
                    },
                    [
                      _c(
                        "el-radio-button",
                        { attrs: { label: "userNumData" } },
                        [_vm._v("人数")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio-button",
                        { attrs: { label: "dealNumData" } },
                        [_vm._v("成交件数")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio-button",
                        { attrs: { label: "dealPriceData" } },
                        [_vm._v("成交额")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.echart6.noData
              ? _c(
                  "div",
                  { staticClass: "d1-pos item-flex-center ft14 fWeight500" },
                  [_vm._v("暂无数据统计")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { ref: "echart6", staticClass: "d1" }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ranking" }, [
        _c("div", { staticClass: "l" }, [
          _c(
            "div",
            { staticClass: "item" },
            [
              _c(
                "div",
                {
                  staticClass: "title flex align-items-c",
                  on: {
                    click: function ($event) {
                      return _vm.showMoreAction(1, "拍品围观排行")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "flex-one lt" }, [
                    _vm._v("拍品围观排行"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        { attrs: { type: "text", size: "mini" } },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "commonlook" },
                          }),
                          _vm._v("查看更多"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("dataTemplate", {
                attrs: { val: _vm.clickList, type: "1", page: 1 },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c(
                "div",
                {
                  staticClass: "title flex align-items-c",
                  on: {
                    click: function ($event) {
                      return _vm.showMoreAction(4, "买家成交额排行")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "flex-one lt" }, [
                    _vm._v("买家成交额排行"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        { attrs: { type: "text", size: "mini" } },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "commonlook" },
                          }),
                          _vm._v("查看更多"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("dataTemplate", {
                attrs: { val: _vm.ideaPriceRanking, type: "4", page: 1 },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "c" }, [
          _c(
            "div",
            { staticClass: "item" },
            [
              _c(
                "div",
                {
                  staticClass: "title flex align-items-c",
                  on: {
                    click: function ($event) {
                      return _vm.showMoreAction(2, "拍品出价次数排行")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "flex-one lt" }, [
                    _vm._v("拍品出价次数排行"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        { attrs: { type: "text", size: "mini" } },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "commonlook" },
                          }),
                          _vm._v("查看更多"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("dataTemplate", {
                attrs: { val: _vm.bidNumList, type: "2", page: 1 },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c(
                "div",
                {
                  staticClass: "title flex align-items-c",
                  on: {
                    click: function ($event) {
                      return _vm.showMoreAction(5, "买家成交数量排行")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "flex-one lt" }, [
                    _vm._v("买家成交数量排行"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        { attrs: { type: "text", size: "mini" } },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "commonlook" },
                          }),
                          _vm._v("查看更多"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("dataTemplate", {
                attrs: { val: _vm.ideaNumRanking, type: "5", page: 1 },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "r" }, [
          _c(
            "div",
            { staticClass: "item" },
            [
              _c(
                "div",
                {
                  staticClass: "title flex align-items-c",
                  on: {
                    click: function ($event) {
                      return _vm.showMoreAction(3, "拍品成交价排行")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "flex-one lt" }, [
                    _vm._v("拍品成交价排行"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        { attrs: { type: "text", size: "mini" } },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "commonlook" },
                          }),
                          _vm._v("查看更多"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("dataTemplate", {
                attrs: { val: _vm.priceList, type: "3", page: 1 },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c(
                "div",
                {
                  staticClass: "title flex align-items-c",
                  on: {
                    click: function ($event) {
                      return _vm.showMoreAction(6, "买家出价次数排行")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "flex-one lt" }, [
                    _vm._v("买家出价次数排行"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        { attrs: { type: "text", size: "mini" } },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "commonlook" },
                          }),
                          _vm._v("更多"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("dataTemplate", {
                attrs: { val: _vm.bidNumRanking, type: "6", page: 1 },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "special-number-data" }, [
        _c("div", { staticClass: "title flex align-items-c" }, [
          _c("div", { staticClass: "flex-one lt" }, [_vm._v("特殊号牌")]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: "merchantSystemExcelExport:exportStatistical",
                  expression: "'merchantSystemExcelExport:exportStatistical'",
                },
              ],
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "ml10",
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.exportOrder },
                },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "text" },
                  on: { click: _vm.getExportFileList },
                },
                [
                  _c("svg-icon", { attrs: { "icon-class": "commonlook" } }),
                  _vm._v("查看导出记录"),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { padding: "0 32px" } },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                staticClass: "ft12",
                attrs: {
                  data: _vm.list,
                  "element-loading-text": "Loading",
                  fit: "",
                  "highlight-current-row": "",
                },
              },
              [
                _c("template", { slot: "empty" }, [
                  _c("div", { staticClass: "tableEmpty" }, [
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "tableDiv" }, [
                      _vm._v("暂无数据"),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "用户", "min-width": "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.user
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onDetails(
                                            scope.row.user.uid
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.user.real_name ||
                                            scope.row.user.nickname
                                        ) + " "
                                      ),
                                      scope.row.user.real_name
                                        ? _c("span", [
                                            _vm._v(
                                              "(" +
                                                _vm._s(
                                                  scope.row.user.nickname
                                                ) +
                                                ")"
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v("ID:" + _vm._s(scope.row.user.uid)),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { align: "center", label: "成交时号牌" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.number
                            ? _c("div", [
                                _vm._v(_vm._s(scope.row.number.number)),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { align: "center", label: "专场", "min-width": "120" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.album
                            ? _c("div", [
                                _vm._v(_vm._s(scope.row.album.album_name)),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { align: "center", label: "拍品封面", width: "95" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("img", {
                            staticStyle: { width: "50px", cursor: "pointer" },
                            attrs: { src: scope.row.product.image + "!120a" },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "拍品名称", "min-width": "130" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              "LOT" +
                                _vm._s(scope.row.lot) +
                                " " +
                                _vm._s(scope.row.product.store_name)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v("ID:" + _vm._s(scope.row.product_id)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { align: "center", label: "出价次数" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(_vm._s(scope.row.user_bid_num) + "次"),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { align: "center", label: "中拍时间" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(_vm._s(_vm.parseTime(scope.row.end_time))),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { align: "center", label: "中拍价格" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.formatPriceNumber(scope.row.now_price, 2)
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt20 flex justify-e" },
              [
                _c("el-pagination", {
                  attrs: {
                    "page-size": _vm.queryParams.limit,
                    "current-page": _vm.queryParams.page,
                    layout: "total, prev, pager, next, jumper",
                    total: _vm.total,
                  },
                  on: { "current-change": _vm.pageChange },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("dataDrawerTemplate", { ref: "dataDrawerTemplateEL" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.exportField.show,
            "show-close": false,
            width: "600px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.exportField, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("选择导出字段")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "export-field" },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.exportField.change,
                    callback: function ($$v) {
                      _vm.$set(_vm.exportField, "change", $$v)
                    },
                    expression: "exportField.change",
                  },
                },
                _vm._l(_vm.exportFieldList, function (m, i) {
                  return _c(
                    "el-checkbox",
                    { key: "exportFieldList_" + i, attrs: { label: m.value } },
                    [_vm._v(_vm._s(m.label))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-flex-center",
              staticStyle: { padding: "20px 0 20px 0" },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { padding: "12px 30px", "font-size": "16px" },
                  on: { click: _vm.cancelAction },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: {
                    padding: "12px 30px",
                    "font-size": "16px",
                    "margin-left": "20px",
                  },
                  attrs: { type: "primary" },
                  on: { click: _vm.exportRecord },
                },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, uid: _vm.uid },
              on: { editMark: _vm.load, close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }