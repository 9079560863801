var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", {
        attrs: { name: (_vm.id ? "修改" : "创建") + "入库单", "has-back": "" },
      }),
      _vm._v(" "),
      _c(
        "el-card",
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "150px" },
              attrs: { placeholder: "库房", clearable: "", filterable: "" },
              on: { change: _vm.pushshelf },
              model: {
                value: _vm.formValidate.warehouse_id,
                callback: function ($$v) {
                  _vm.$set(_vm.formValidate, "warehouse_id", $$v)
                },
                expression: "formValidate.warehouse_id",
              },
            },
            _vm._l(_vm.storeArr, function (item) {
              return _c("el-option", {
                key: item.warehouse_id,
                attrs: { label: item.name, value: item.warehouse_id },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticStyle: { width: "150px" },
              attrs: { placeholder: "入库类型", clearable: "" },
              on: { change: _vm.onChange },
              model: {
                value: _vm.formValidate.enter_type,
                callback: function ($$v) {
                  _vm.$set(_vm.formValidate, "enter_type", $$v)
                },
                expression: "formValidate.enter_type",
              },
            },
            [
              _c("el-option", {
                attrs: { label: "委托入库", value: "enter_warehouse_entrust" },
              }),
              _vm._v(" "),
              _c("el-option", {
                attrs: { label: "退货入库", value: "enter_warehouse_refund" },
              }),
              _vm._v(" "),
              _c("el-option", {
                attrs: { label: "返还入库", value: "enter_warehouse_return" },
              }),
              _vm._v(" "),
              _c("el-option", {
                attrs: { label: "临时入库", value: "enter_warehouse_temp" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.reductjson.erp_v2_enter_warehouse == 1
            ? _c(
                "el-select",
                {
                  staticClass: "selwidth",
                  attrs: {
                    placeholder: "请选择审批人",
                    clearable: "",
                    filterable: "",
                  },
                  model: {
                    value: _vm.reviewAduitId,
                    callback: function ($$v) {
                      _vm.reviewAduitId = $$v
                    },
                    expression: "reviewAduitId",
                  },
                },
                _vm._l(_vm.reductjson.adminData, function (item) {
                  return _c("el-option", {
                    key: item.merchant_admin_id,
                    attrs: {
                      label: item.real_name,
                      value: item.merchant_admin_id,
                    },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.formValidate.enter_type == "enter_warehouse_temp"
            ? _c(
                "el-select",
                {
                  staticClass: "selwidth",
                  attrs: {
                    placeholder: "请选择业务员",
                    clearable: "",
                    filterable: "",
                  },
                  model: {
                    value: _vm.salesmanAdminId,
                    callback: function ($$v) {
                      _vm.salesmanAdminId = $$v
                    },
                    expression: "salesmanAdminId",
                  },
                },
                _vm._l(_vm.userList, function (item, index) {
                  return _c("el-option", {
                    key: "salesmanAdminId_" + index,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: {
                data: _vm.enterGoodsList,
                size: "small",
                "highlight-current-row": "",
                border: "",
              },
              on: { "header-dragend": _vm.changeColumnWidth },
            },
            [
              _vm._l(_vm.pushheaderList, function (item, index) {
                return [
                  item.field == "image"
                    ? [
                        _c("el-table-column", {
                          key: index,
                          attrs: {
                            label: item.name,
                            width: item.width,
                            index: index,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "demo-image__preview",
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.modalPicTap(
                                              "2",
                                              "",
                                              "",
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      _vm._l(
                                        scope.row.slider_image,
                                        function (it, itIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key:
                                                "img_" + index + "_" + itIndex,
                                              staticClass: "img",
                                            },
                                            [
                                              it
                                                ? _c("img", {
                                                    staticStyle: {
                                                      width: "30px",
                                                      height: "30px",
                                                    },
                                                    attrs: {
                                                      src: it + "!120a",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "del",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.delImgAction(
                                                        scope.$index,
                                                        itIndex
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-close",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == "cate_id"
                    ? [
                        _c("el-table-column", {
                          key: index,
                          attrs: {
                            label: item.name,
                            prop: "",
                            "min-width": item.width,
                            index: index,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function (scope) {
                                  return [
                                    _c("div", { staticClass: "headPos" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex align-items-c cur",
                                          on: {
                                            click: function ($event) {
                                              return _vm.headClick("cate_id")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item.name) +
                                              " "
                                          ),
                                          _c("i", {
                                            staticClass: "el-icon-edit-outline",
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          class: {
                                            poaBox: true,
                                            isShow: _vm.handName == "cate_id",
                                          },
                                        },
                                        [
                                          _c("el-cascader", {
                                            ref: "cate_id",
                                            refInFor: true,
                                            staticClass: "sortCol",
                                            attrs: {
                                              options: _vm.categoryList,
                                              props: {
                                                expandTrigger: "hover",
                                                value: "store_category_id",
                                                label: "cate_name",
                                              },
                                              placeholder: item.name,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.onHeadChange(
                                                  "cate_id"
                                                )
                                              },
                                              blur: function ($event) {
                                                _vm.handName = ""
                                              },
                                              focus: function ($event) {
                                                return _vm.headClick("cate_id")
                                              },
                                            },
                                            model: {
                                              value: _vm.headObj.cate_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.headObj,
                                                  "cate_id",
                                                  $$v
                                                )
                                              },
                                              expression: "headObj.cate_id",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                },
                              },
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      [
                                        _c("el-cascader", {
                                          staticClass: "sortCol",
                                          attrs: {
                                            value: scope.row.cate_id,
                                            options: _vm.categoryList,
                                            props: {
                                              expandTrigger: "hover",
                                              value: "store_category_id",
                                              label: "cate_name",
                                            },
                                          },
                                          on: {
                                            change: function (val) {
                                              return _vm.changeCateAction(
                                                val,
                                                scope.$index
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == "order_num"
                    ? [
                        _c("el-table-column", {
                          key: index,
                          attrs: {
                            label: item.name,
                            prop: "",
                            "min-width": item.width,
                            index: index,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          staticClass: "sortCol",
                                          on: {
                                            focus: function ($event) {
                                              return _vm.focus($event)
                                            },
                                          },
                                          model: {
                                            value: scope.row.order_num,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "order_num",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.order_num",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == "product_no"
                    ? [
                        _c("el-table-column", {
                          key: index,
                          attrs: {
                            label: item.name,
                            prop: "",
                            "min-width": item.width,
                            index: index,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          staticClass: "sortCol",
                                          on: {
                                            focus: function ($event) {
                                              return _vm.focus($event)
                                            },
                                          },
                                          model: {
                                            value: scope.row.product_no,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "product_no",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.product_no",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == "product_name"
                    ? [
                        _c("el-table-column", {
                          key: index,
                          attrs: {
                            label: item.name,
                            prop: "",
                            "min-width": item.width,
                            index: index,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    !scope.row.id
                                      ? _c(
                                          "div",
                                          [
                                            _c("el-input", {
                                              staticClass: "sortCol",
                                              class: {
                                                err: !scope.row.product_name,
                                              },
                                              on: {
                                                focus: function ($event) {
                                                  return _vm.focus($event)
                                                },
                                              },
                                              model: {
                                                value: scope.row.product_name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "product_name",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.product_name",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _c("div", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(scope.row.product_name) +
                                              "\n              "
                                          ),
                                        ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == "label"
                    ? [
                        _c("el-table-column", {
                          key: index,
                          attrs: {
                            label: item.name,
                            "min-width": "200",
                            prop: "label",
                            index: index,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        ref: "label",
                                        refInFor: true,
                                        staticClass: "sortCol",
                                        attrs: {
                                          filterable: "",
                                          placeholder: "请选择",
                                          multiple: "",
                                          "collapse-tags": "",
                                        },
                                        on: {
                                          focus: function ($event) {
                                            return _vm.focus($event)
                                          },
                                        },
                                        model: {
                                          value: scope.row.label_ids,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "label_ids",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.label_ids",
                                        },
                                      },
                                      _vm._l(_vm.labelarr, function (item) {
                                        return _c("el-option", {
                                          key: item.label_id,
                                          attrs: {
                                            label: item.label_name,
                                            value: item.label_id,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.field == "shelf_id"
                    ? [
                        _c("el-table-column", {
                          key: index,
                          attrs: {
                            label: "选择货架（选填）",
                            "min-width": item.width < 120 ? 120 : item.width,
                            index: index,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function (scope) {
                                  return [
                                    _c("div", { staticClass: "headPos" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "flex align-items-c cur",
                                          on: {
                                            click: function ($event) {
                                              return _vm.headClick("shelf_id")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  选择货架（选填) "
                                          ),
                                          _c("i", {
                                            staticClass: "el-icon-edit-outline",
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          class: {
                                            poaBox: true,
                                            isShow: _vm.handName == "shelf_id",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              ref: "shelf_id",
                                              refInFor: true,
                                              staticClass: "sortCol",
                                              attrs: {
                                                disabled:
                                                  !_vm.formValidate
                                                    .warehouse_id,
                                                placeholder: "请选择货架",
                                                clearable: "",
                                                filterable: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onHeadChange(
                                                    "shelf_id"
                                                  )
                                                },
                                                blur: function ($event) {
                                                  _vm.handName = ""
                                                },
                                                focus: function ($event) {
                                                  return _vm.headClick(
                                                    "shelf_id"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.headObj.shelf_id,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.headObj,
                                                    "shelf_id",
                                                    $$v
                                                  )
                                                },
                                                expression: "headObj.shelf_id",
                                              },
                                            },
                                            _vm._l(
                                              _vm.shelfList,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.shelf_id,
                                                  attrs: {
                                                    label: item.shelf_no,
                                                    value: item.shelf_id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                },
                              },
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "sortCol",
                                        attrs: {
                                          disabled:
                                            !_vm.formValidate.warehouse_id,
                                          placeholder: "请选择货架",
                                          clearable: "",
                                          filterable: "",
                                        },
                                        on: {
                                          focus: function ($event) {
                                            return _vm.focus($event)
                                          },
                                        },
                                        model: {
                                          value: scope.row.shelf_id,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "shelf_id", $$v)
                                          },
                                          expression: "scope.row.shelf_id",
                                        },
                                      },
                                      _vm._l(_vm.shelfList, function (item) {
                                        return _c("el-option", {
                                          key: item.shelf_id,
                                          attrs: {
                                            label: item.shelf_no,
                                            value: item.shelf_id,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : item.newtype == "attribute"
                    ? [
                        item.name.indexOf("尺寸") != -1
                          ? [
                              _c("el-table-column", {
                                key: index,
                                attrs: {
                                  label: item.name,
                                  "show-overflow-tooltip": "",
                                  width: "100",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("div", {
                                            staticClass: "nowrap cur",
                                            staticStyle: { height: "100%" },
                                            domProps: {
                                              innerHTML: _vm._s(
                                                scope.row.attribute[item.field]
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onClickSize(
                                                  scope.row.attribute[
                                                    item.field
                                                  ],
                                                  scope.$index,
                                                  scope.row,
                                                  item.field
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          : [
                              _c("el-table-column", {
                                key: index,
                                attrs: {
                                  label: item.name,
                                  "min-width": item.width,
                                  index: index,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-input", {
                                            staticClass: "sortCol",
                                            attrs: {
                                              placeholder: "",
                                              size: "normal",
                                            },
                                            on: {
                                              focus: function ($event) {
                                                return _vm.focus($event)
                                              },
                                            },
                                            model: {
                                              value:
                                                scope.row.attribute[item.field],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row.attribute,
                                                  item.field,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.attribute[item.field]",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                      ]
                    : _vm._e(),
                ]
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delGoods(scope.$index, scope.row)
                                  },
                                },
                              },
                              [_vm._v("\n              移除\n            ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm.formValidate.enter_type == "enter_warehouse_temp"
            ? _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-plus-outline",
                            type: "primary",
                            size: "mini",
                          },
                          on: { click: _vm.createActiveGoods },
                        },
                        [_vm._v("新增")]
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "sortCol",
                        staticStyle: { width: "30px" },
                        model: {
                          value: _vm.addNum,
                          callback: function ($$v) {
                            _vm.addNum = $$v
                          },
                          expression: "addNum",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { on: { click: _vm.createActiveGoods } }, [
                        _vm._v("条"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12, offset: 0 } }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "w300 mt20",
            attrs: { rows: 3, type: "textarea", placeholder: "请输入备注" },
            model: {
              value: _vm.formValidate.remark,
              callback: function ($$v) {
                _vm.$set(_vm.formValidate, "remark", $$v)
              },
              expression: "formValidate.remark",
            },
          }),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("formValidate")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          确认" +
                          _vm._s(_vm.id ? "修改" : "创建") +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12, offset: 0 } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "20px",
                "font-weight": "500",
                "margin-bottom": "20px",
              },
            },
            [
              _vm.formValidate.enter_type == "enter_warehouse_temp"
                ? [_vm._v("未关联合同的货品")]
                : [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          !_vm.formValidate.enter_type
                            ? "选择"
                            : _vm.formValidate.enter_type ==
                              "enter_warehouse_entrust"
                            ? "委托合同待入库货品"
                            : "销售出库的货品"
                        ) +
                        "\n      "
                    ),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  ref: "pushTabelRef",
                  staticClass: "table",
                  attrs: {
                    data: _vm.tableData.data,
                    size: "small",
                    "highlight-current-row": "",
                    width: "100%",
                    border: "",
                  },
                },
                [
                  _c("ytx-table-column", {
                    attrs: {
                      label: "合同编号",
                      "min-width": "140",
                      prop: "",
                      "search-key": "contract_keyword",
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  scope.row.contract
                                    ? scope.row.contract.contract_no
                                    : ""
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "签约时间",
                      prop: "",
                      "min-width": "150",
                      "search-query": _vm.searchQuery,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(" " + _vm._s(scope.row.create_time) + " "),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "业务员",
                      "min-width": "140",
                      prop: "",
                      "search-key": "salesman_admin_id",
                      "search-type": "radio",
                      options: _vm.userList,
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.contract &&
                            scope.row.contract.salesmanAdmin
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.contract.salesmanAdmin.real_name
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.erpsettingList, function (item, index) {
                    return [
                      item.types == "erp_v2_contract_create_product"
                        ? [
                            item.field == "product_no"
                              ? [
                                  _c("el-table-column", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      prop: "product_no",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(scope.row.product_no) +
                                                  "\n                "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            item.field == "order_num"
                              ? [
                                  _c("el-table-column", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      prop: "order_num",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(scope.row.order_num) +
                                                  "\n                "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            item.field == "lot"
                              ? [
                                  _c("el-table-column", {
                                    key: index,
                                    attrs: { label: item.name, prop: "lot" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(scope.row.lot) +
                                                  "\n                "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              : item.field == "product_name"
                              ? [
                                  _c("el-table-column", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      prop: "product_name",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row.product_name
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              : item.field == "image"
                              ? [
                                  _c("el-table-column", {
                                    key: index,
                                    attrs: { label: item.name, width: "135" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "flex" },
                                                [
                                                  scope.row.contract_image &&
                                                  scope.row.contract_image
                                                    .length
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex align-items-c ml10",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "pictrue",
                                                              staticStyle: {
                                                                width: "36px",
                                                                height: "36px",
                                                              },
                                                            },
                                                            [
                                                              _c("el-image", {
                                                                attrs: {
                                                                  src:
                                                                    scope.row
                                                                      .contract_image[0] +
                                                                    "!120a",
                                                                  "preview-src-list":
                                                                    scope.row
                                                                      .contract_image,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              : item.field == "retain_price"
                              ? void 0
                              : item.field == "take_home_price"
                              ? void 0
                              : item.field == "label"
                              ? [
                                  _c("el-table-column", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      "min-width": "100",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row.labelNames.join(
                                                      ","
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              : item.newtype == "attribute"
                              ? [
                                  item.name.indexOf("尺寸") != -1
                                    ? [
                                        _c("el-table-column", {
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            "show-overflow-tooltip": "",
                                            "min-width": "100",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("div", {
                                                      staticClass: "nowrap cur",
                                                      staticStyle: {
                                                        height: "100%",
                                                      },
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          scope.row.attribute[
                                                            item.field
                                                          ]
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ]
                                    : [
                                        _c("el-table-column", {
                                          key: index,
                                          attrs: { label: item.name },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          scope.row.attribute[
                                                            item.field
                                                          ]
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                ]
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      item.types == "store_attribute"
                        ? [
                            item.name.indexOf("尺寸") != -1
                              ? [
                                  _c("el-table-column", {
                                    key: index,
                                    attrs: {
                                      label: item.name,
                                      "show-overflow-tooltip": "",
                                      "min-width": "100",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("div", {
                                                staticClass: "nowrap cur",
                                                staticStyle: { height: "100%" },
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    scope.row.attribute[
                                                      item.field
                                                    ]
                                                  ),
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              : [
                                  _c("el-table-column", {
                                    key: index,
                                    attrs: { label: item.name },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row.attribute[
                                                      item.field
                                                    ]
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                          ]
                        : _vm._e(),
                    ]
                  }),
                  _vm._v(" "),
                  _c("ytx-table-column", {
                    attrs: {
                      label: "操作",
                      width: "60",
                      fixed: "right",
                      align: "center",
                      "search-clear": true,
                      "search-query": _vm.searchQuery,
                    },
                    on: { changeVal: _vm.searchAction },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "font-size": "14px" },
                                attrs: { type: "text", size: "small" },
                                on: { click: _vm.saleGoodsAll },
                              },
                              [_vm._v(" 全选 ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saleGoods(
                                          scope.row,
                                          "goods",
                                          scope.row.entrust_uid
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.goodsIdArr.indexOf(scope.row.id) >
                                            -1
                                            ? "已选择"
                                            : "选择"
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block paginaBox" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 40, 60, 80],
                      "page-size": _vm.tableFrom.limit,
                      "current-page": _vm.tableFrom.page,
                      layout: "total, prev, pager, next, sizes",
                      total: _vm.tableData.total,
                      background: "",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.showVisible,
            width: "750px",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "top" } },
            [
              _vm._l(_vm.formArr, function (item, ind) {
                return _c(
                  "div",
                  { key: ind, staticClass: "formLine" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "15px" },
                        attrs: { label: "输入:" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "el-input",
                              {
                                staticStyle: {
                                  width: "155px",
                                  "margin-right": "15px",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onChangeSize(item)
                                  },
                                },
                                model: {
                                  value: item.long,
                                  callback: function ($$v) {
                                    _vm.$set(item, "long", $$v)
                                  },
                                  expression: "item.long",
                                },
                              },
                              [
                                _c("template", { slot: "prepend" }, [
                                  _vm._v("长(L)"),
                                ]),
                                _vm._v(" "),
                                _c("template", { slot: "append" }, [
                                  _vm._v("cm"),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: {
                                  width: "155px",
                                  "margin-right": "15px",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onChangeSize(item)
                                  },
                                },
                                model: {
                                  value: item.width,
                                  callback: function ($$v) {
                                    _vm.$set(item, "width", $$v)
                                  },
                                  expression: "item.width",
                                },
                              },
                              [
                                _c("template", { slot: "prepend" }, [
                                  _vm._v("宽(W)"),
                                ]),
                                _vm._v(" "),
                                _c("template", { slot: "append" }, [
                                  _vm._v("cm"),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: {
                                  width: "155px",
                                  "margin-right": "15px",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onChangeSize(item)
                                  },
                                },
                                model: {
                                  value: item.height,
                                  callback: function ($$v) {
                                    _vm.$set(item, "height", $$v)
                                  },
                                  expression: "item.height",
                                },
                              },
                              [
                                _c("template", { slot: "prepend" }, [
                                  _vm._v("高(H)"),
                                ]),
                                _vm._v(" "),
                                _c("template", { slot: "append" }, [
                                  _vm._v("cm"),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "185px" },
                                on: {
                                  change: function ($event) {
                                    return _vm.onChangeSize(item)
                                  },
                                },
                                model: {
                                  value: item.diam,
                                  callback: function ($$v) {
                                    _vm.$set(item, "diam", $$v)
                                  },
                                  expression: "item.diam",
                                },
                              },
                              [
                                _c("template", { slot: "prepend" }, [
                                  _vm._v("直径(D)"),
                                ]),
                                _vm._v(" "),
                                _c("template", { slot: "append" }, [
                                  _vm._v("cm"),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "15px" },
                        attrs: { label: "展示:" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c("el-input", {
                              staticClass: "flex-one sortCol",
                              staticStyle: { "margin-right": "5px" },
                              model: {
                                value: item.sizeStr,
                                callback: function ($$v) {
                                  _vm.$set(item, "sizeStr", $$v)
                                },
                                expression: "item.sizeStr",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "flex align-items-c" },
                              [
                                !item.height && !item.diam
                                  ? _c("div", [_vm._v("平尺小数点:")])
                                  : _vm._e(),
                                _vm._v(" "),
                                !item.height && !item.diam
                                  ? _c(
                                      "el-select",
                                      {
                                        staticClass: "sortCol",
                                        staticStyle: {
                                          height: "36px",
                                          width: "80px",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onChangeSize(item)
                                          },
                                        },
                                        model: {
                                          value: item.toNum,
                                          callback: function ($$v) {
                                            _vm.$set(item, "toNum", $$v)
                                          },
                                          expression: "item.toNum",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { value: 0, label: "0位" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 1, label: "1位" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 2, label: "2位" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 3, label: "3位" },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", [_vm._v("数量:")]),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "sortCol",
                                  staticStyle: {
                                    height: "36px",
                                    width: "40px",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onChangeSize(item)
                                    },
                                  },
                                  model: {
                                    value: item.vice,
                                    callback: function ($$v) {
                                      _vm.$set(item, "vice", $$v)
                                    },
                                    expression: "item.vice",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-right": "5px" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        !item.height && !item.diam ? "幅" : "件"
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("el-switch", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.onChangeSize(item)
                                    },
                                  },
                                  model: {
                                    value: item.showVice,
                                    callback: function ($$v) {
                                      _vm.$set(item, "showVice", $$v)
                                    },
                                    expression: "item.showVice",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "text", icon: "el-icon-circle-plus-outline" },
                  on: { click: _vm.onAdd },
                },
                [_vm._v("继续增加")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex justify-e" },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }