"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
var _auction = require("@/api/auction");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    currentData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    flag: {
      type: Boolean,
      default: false
    },
    hasMoreData: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    allImage: {
      type: String,
      default: ''
    },
    query: {
      type: [String, Object],
      default: ''
    }
  },
  data: function data() {
    return {
      showSelectAuction: false,
      resultData: [],
      selectedAuctionIdList: [],
      selectedAuction: [],
      queryParams: {
        page: 1,
        limit: 10
      },
      total: 0,
      auctionName: '',
      venueLoading: false,
      venue_id: '',
      album_id: '',
      venueList: [],
      albumList: [],
      isAll: false,
      parseTime: _utils.parseTime,
      autionLen: 100,
      venueName: '',
      albumName: ''
    };
  },
  watch: {
    flag: function flag(val) {
      this.showSelectAuction = val;
    }
  },
  mounted: function mounted() {
    this.showSelectAuction = this.flag;
  },
  methods: {
    onClose: function onClose() {
      this.showSelectAuction = false;
    },
    onWholeAll: function onWholeAll() {
      var _this = this;
      (0, _auction.auctionSearchListApi)({
        page: 1,
        limit: 50,
        album_id: this.queryParams.album_id,
        order: 'order_num'
      }).then(function (response) {
        _this.next(response.data.list, true);
      });
    },
    changeshow: function changeshow(d) {
      var _this2 = this;
      // if (d && d.album_id) {
      //   const vList = d.venueList.map(m => m.venue_id);
      //   const venueIndex = vList.indexOf(d.venue_id);
      //   if (venueIndex > -1) {
      //     this.venueName = d.venueList[venueIndex].title;
      //   }
      //   const aList = d.albumList.map(m => m.album_id);
      //   const albumIndex = aList.indexOf(d.album_id);
      //   if (albumIndex > -1) {
      //     this.albumName = d.albumList[albumIndex].album_name;
      //   }
      this.queryParams.venue_id = d.venue_id;
      this.queryParams.album_id = d.album_id;

      // if (d.auction_ids) {
      //   this.selectedAuction = JSON.parse(JSON.stringify(d.auction_ids));
      //   this.selectedAuctionIdList = this.selectedAuction.map((m) => m.id);
      //   // if (this.resultData.length === 0) {
      //   //   // this.loadAuction();
      //   // } else {
      //   //   this.setAllStatus();
      //   // }
      // } else {
      //   this.selectedAuction = [];
      //   this.selectedAuctionIdList = [];
      // }
      this.loadAuction();
      // }
      if (this.queryParams.album_id) {
        (0, _auction.auctionSearchListApi)({
          page: 1,
          limit: 1,
          album_id: this.queryParams.album_id,
          order: 'order_num'
        }).then(function (response) {
          _this2.autionLen = response.data.count;
        });
      }

      // this.selectedAuction = [];
      // this.selectedAuctionIdList = [];
      this.showSelectAuction = true;
    },
    searchAAction: function searchAAction() {
      if (this.searchLock) return false;
      this.searchLock = true;
      this.queryParams.keyword = this.auctionName;
      this.queryParams.page = 1;
      this.queryParams.limit = 10;
      this.loadAuction();
    },
    quickSelect: function quickSelect() {
      var _this3 = this;
      if (this.searchLock) return false;
      this.searchLock = true;
      this.queryParams.keyword = this.auctionName;
      this.queryParams.keyword = this.queryParams.keyword.replace(/，/g, ',');
      this.queryParams.page = 1;
      this.queryParams.limit = this.queryParams.keyword.split(',').length;
      this.loadAuction(function (data) {
        _this3.searchLock = false;
        var list = [];
        data.list.forEach(function (m) {
          var i = _this3.selectedAuctionIdList.indexOf(m.product_id);
          if (i === -1) {
            var d = {
              image: m.product.image + '!300',
              name: m.product.store_name,
              id: m.product_id,
              price: m.now_price || m.start_price
            };
            if (_this3.hasMoreData) {
              d.image = m.product.image + '!m640';
              d.attr_info = m.attr_info;
              d.store_info = m.product.store_info;
              d.content = m.content && m.content.content ? m.content.content : '';
              d.desc = '';
              d.category = m.product.storeCategory && m.product.storeCategory[0] ? m.product.storeCategory[0].cate_name : '';
              d.album = m.album ? m.album.album_name : '';
              d.reference_price = m.reference_price;
              d.start_price = m.start_price ? m.start_price : '无底价';
              d.success_price = m.now_price;
              d.lot = m.lot;
              d.video_link = m.product.video_link;
              d.artist_info = m.artist_info;
              // d.cate_name = m.product.cate_id ? m.product.storeCategory[0].cate_name : '';
              d.venue_name = m.venue ? m.venue.title : '';
              if (_this3.allImage) {
                d.slider_image = m.product.slider_image;
              }
            }
            list.push(d);
            // list.reverse();
          }
        });
        if (list.length) {
          _this3.$emit('selectMessage', {
            type: 'auction',
            data: list
          });
          _this3.showSelectAuction = false;
        } else {
          _this3.$message.error('没有查询到拍品信息');
        }
      });
    },
    loadAuction: function loadAuction(fun) {
      var _this4 = this;
      var data = JSON.parse(JSON.stringify(this.queryParams));
      data.order = 'order_num';
      (0, _auction.auctionSearchListApi)(data).then(function (response) {
        if (!fun) {
          _this4.total = response.data.count;
          _this4.resultData = response.data.list;
          _this4.setAllStatus();
          _this4.searchLock = false;
        } else {
          fun(response.data);
        }
      });
    },
    auctionCancelAction: function auctionCancelAction(e, index) {
      this.selectedAuction.splice(index, 1);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      this.setAllStatus();
      if (e) e.preventDefault();
    },
    auctionSelectedAction: function auctionSelectedAction(e, index) {
      var info = this.resultData[index];
      var data = {
        image: info.product.image + '!300',
        name: info.product.store_name,
        id: info.product_id,
        price: info.now_price || info.start_price
      };
      if (this.hasMoreData) {
        data.image = info.product.image + '!m640';
        data.attr_info = info.attr_info;
        data.store_info = info.product.store_info;
        data.content = info.content && info.content.content ? info.content.content : '';
        data.desc = '';
        data.category = info.product.storeCategory && info.product.storeCategory[0] ? info.product.storeCategory[0].cate_name : '';
        data.album = info.album ? info.album.album_name : '';
        data.reference_price = info.reference_price;
        data.start_price = info.start_price ? info.start_price : '无底价';
        data.success_price = info.now_price;
        data.lot = info.lot;
        data.video_link = info.product.video_link;
        data.artist_info = info.artist_info;
        // data.cate_name = info.product.cate_id ? info.product.storeCategory[0].cate_name : '';
        data.venue_name = info.venue ? info.venue.title : '';
      }
      if (this.allImage) {
        data.slider_image = info.product.slider_image;
      }
      this.selectedAuction.push(data);
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      if (this.size === '1') {
        this.cursorSelect();
      }
      this.setAllStatus();
      e.preventDefault();
    },
    cursorSelect: function cursorSelect() {
      // console.log(this.selectedAuction);
      if (!this.selectedAuction || !this.selectedAuction.length) {
        if (this.auctionName) {
          this.quickSelect();
        } else {
          this.$message.error('请先选择拍品');
        }
        return false;
      }
      this.$emit('selectMessage', {
        type: 'auction',
        data: this.selectedAuction
      });
      this.showSelectAuction = false;
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.loadAuction();
    },
    searchVenueAction: function searchVenueAction(data) {
      var _this5 = this;
      var params = {
        keyword: data,
        venue_type: '',
        page: 1,
        limit: 10
      };
      (0, _auction.meetingList)(params).then(function (res) {
        _this5.venueList = res.data.list;
      });
    },
    searchAlbumAction: function searchAlbumAction(data) {
      var _this6 = this;
      var params = {
        keyword: data,
        page: 1,
        limit: 10
      };
      (0, _auction.albumlist)(params).then(function (res) {
        _this6.albumList = res.data.list;
      });
    },
    changeMeetingAction: function changeMeetingAction(e, noLoad) {
      var _this7 = this;
      this.album_id = '';
      var params = {
        venue_id: this.venue_id,
        page: 1,
        limit: 100
      };
      (0, _auction.albumlist)(params).then(function (res) {
        _this7.albumList = res.data.list;
      });
      if (!noLoad) {
        this.searchAAction();
      }
    },
    setAllStatus: function setAllStatus() {
      var isAll = true;
      for (var i = 0; i < this.resultData.length; i += 1) {
        var m = this.resultData[i];
        var index = this.selectedAuctionIdList.indexOf(m.product_id);
        if (index === -1) {
          isAll = false;
          break;
        }
      }
      this.isAll = isAll;
    },
    auctionSelectedAllAction: function auctionSelectedAllAction() {
      if (this.isAll) {
        var allData = JSON.parse(JSON.stringify(this.selectedAuction));
        this.resultData.forEach(function (m) {
          var ids = allData.map(function (m) {
            return m.id;
          });
          var i = ids.indexOf(m.product_id);
          if (i !== -1) {
            allData.splice(i, 1);
          }
        });
        this.selectedAuction = allData;
      } else {
        this.next(this.resultData);
      }
      this.selectedAuctionIdList = this.selectedAuction.map(function (m) {
        return m.id;
      });
      this.setAllStatus();
    },
    next: function next(list, bol) {
      var _this8 = this;
      var data = [];
      list.forEach(function (m) {
        var i = _this8.selectedAuctionIdList.indexOf(m.product_id);
        if (i === -1) {
          var d = {
            image: m.product.image + '!300',
            name: m.product.store_name,
            id: m.product_id,
            price: m.now_price || m.start_price,
            slider_image: m.product.slider_image
          };
          if (_this8.hasMoreData) {
            d.image = m.product.image + '!m640';
            d.attr_info = m.attr_info;
            d.store_info = m.product.store_info;
            d.content = m.content && m.content.content ? m.content.content : '';
            d.desc = '';
            d.category = m.product.storeCategory && m.product.storeCategory[0] ? m.product.storeCategory[0].cate_name : '';
            d.album = m.album ? m.album.album_name : '';
            d.reference_price = m.reference_price;
            d.start_price = m.start_price ? m.start_price : '无底价';
            d.success_price = m.now_price;
            d.lot = m.lot;
            d.video_link = m.product.video_link;
            d.artist_info = m.artist_info;
            d.venue_name = m.venue ? m.venue.title : '';
            if (_this8.allImage) {
              d.slider_image = m.product.slider_image;
            }
          }
          data.push(d);
        }
      });
      this.selectedAuction = data;
      if (bol) {
        this.cursorSelect();
        // this.$emit('selectMessage', {
        //   type: 'auction',
        //   data: list
        // });
        // this.showSelectAuction = false;
      }
    }
  }
};