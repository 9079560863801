var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.facade,
        "label-width": "90px",
        size: "mini",
        "label-position": "left",
      },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "模版字段" } },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.facade.temp,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "temp", $$v)
                },
                expression: "facade.temp",
              },
            },
            [
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "item_background" } }, [
                    _vm._v("拍品背景颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "animate_color" } }, [
                    _vm._v("动画提示颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-checkbox",
                    { attrs: { label: "auction_spacing_color" } },
                    [_vm._v("间距线颜色")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-checkbox",
                    { attrs: { label: "content_background" } },
                    [_vm._v("文字区域背景颜色")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "border_color" } }, [
                    _vm._v("文字区域边框颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-checkbox",
                    { attrs: { label: "border_shawdow_color" } },
                    [_vm._v("文字区域边框阴影颜色")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "lot_color" } }, [
                    _vm._v("LOT颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "title_color" } }, [
                    _vm._v("拍品名称颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "attr_color" } }, [
                    _vm._v("属性名颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "attr_value_color" } }, [
                    _vm._v("属性值颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "price_color" } }, [
                    _vm._v("价格颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "content_color" } }, [
                    _vm._v("详情颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "desc_color" } }, [
                    _vm._v("其他颜色"),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }