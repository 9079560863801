var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "mb20",
      attrs: {
        model: _vm.content,
        "label-width": "90px",
        size: "mini",
        "label-position": "left",
      },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "flex align-items-c mt10" },
          [
            _c(
              "el-radio-group",
              {
                on: { change: _vm.changeType },
                model: {
                  value: _vm.content.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.content, "type", $$v)
                  },
                  expression: "content.type",
                },
              },
              [
                _c("el-radio", { staticClass: "mt10", attrs: { label: "" } }, [
                  _vm._v("普通文字"),
                ]),
                _vm._v(" "),
                _c(
                  "el-radio",
                  { staticClass: "mt10", attrs: { label: "venueEndMessage" } },
                  [_vm._v("拍卖会总结")]
                ),
                _vm._v(" "),
                _c(
                  "el-radio",
                  {
                    staticClass: "mt10",
                    attrs: { label: "venueHighQualityAlbumMessage" },
                  },
                  [_vm._v("专场总结")]
                ),
                _vm._v(" "),
                _c(
                  "el-radio",
                  {
                    staticClass: "mt10",
                    attrs: { label: "venueHighQualityProductMessage" },
                  },
                  [_vm._v("拍品总结")]
                ),
                _vm._v(" "),
                _c(
                  "el-radio",
                  { staticClass: "mt10", attrs: { label: "venue" } },
                  [_vm._v("拍卖会介绍")]
                ),
                _vm._v(" "),
                _c(
                  "el-radio",
                  { staticClass: "mt10", attrs: { label: "album" } },
                  [_vm._v("专场介绍")]
                ),
                _vm._v(" "),
                _c(
                  "el-radio",
                  { staticClass: "mt10", attrs: { label: "auction" } },
                  [_vm._v("拍品介绍")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex align-items-c ft14 mt20" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.setMessageAction } },
            [_vm._v(_vm._s(_vm.content.data ? "点击修改内容" : "点击设置内容"))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }