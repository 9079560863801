"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/uploadPictureArtai/index.vue"));
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Picture',
  components: {
    uploadFrom: _index.default
  },
  data: function data() {
    return {};
  },
  methods: {}
};