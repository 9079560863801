"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
var _auction = require("@/api/auction");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      venue_name: this.$route.query.vname || '',
      venue_id: this.$route.query.vid || '',
      oneNum: 0,
      twoNum: 0,
      liveCount: 0,
      routerUrl: '',
      label: this.$route.query.label || ''
    };
  },
  computed: {
    key: function key() {
      return this.$route.path;
    }
  },
  created: function created() {
    this.oneNum = Number(this.$route.query.oneNumber);
    this.twoNum = Number(this.$route.query.twoNumber);
    this.liveCount = Number(this.$route.query.liveCount);
    this.routerUrl = this.$route.path;
    // liveGroupListApi({venue_id: this.$route.query.vid}).then(res => {
    //   let arr = []
    //   res.data.forEach(item => {
    //     arr.push(...item.data)
    //   });
    //   let newArr = arr.filter(item => [2,3].includes(item.album_type))
    //   this.isNo = newArr.length>0
    // });
  },
  methods: {
    onGOUrl: function onGOUrl(url, type) {
      if (type == 1) {
        this.$router.push({
          path: url
        });
        return false;
      }
      this.$router.push({
        name: url
      });
    },
    changeTab: function changeTab(data) {
      var _this = this;
      if (data.indexOf('/merchant/order/list') == 0) {
        window.open(data);
      } else if (data.indexOf('auctionMeeting') != -1) {
        this.$router.push({
          path: data,
          query: {
            vid: this.venue_id,
            vname: this.venue_name,
            oneNumber: this.oneNum,
            twoNumber: this.twoNum,
            liveCount: this.liveCount,
            label: this.label
          }
        });
      } else if (data !== this.routerUrl) {
        this.$router.replace({
          path: data,
          query: {
            vid: this.venue_id,
            vname: this.venue_name,
            oneNumber: this.oneNum,
            twoNumber: this.twoNum,
            liveCount: this.liveCount,
            label: this.label
          }
        });
        this.routerUrl = data;
        setTimeout(function () {
          _this.routerUrl = _this.$route.path;
        });
      }
    },
    backAction: function backAction() {
      this.$router.replace('/merchant/pageManagement/auction/meeting');
    }
  }
};