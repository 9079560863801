"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
var _createVideo = _interopRequireDefault(require("./createVideo.vue"));
var _createVideoBz = _interopRequireDefault(require("./createVideoBz.vue"));
var _artai = require("@/api/artai");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    createPoster: _createVideo.default,
    createPosterBz: _createVideoBz.default
  },
  data: function data() {
    return {
      headNumber: {},
      routeName: this.$route.name
    };
  },
  // computed: {
  // },
  created: function created() {
    var _this = this;
    var headNumberJSON = sessionStorage.getItem('headNumber');
    var headNumber = headNumberJSON ? JSON.parse(headNumberJSON) : {};
    this.headNumber = headNumber;

    // const hasWz = this.$route.name.indexOf('sp') == 0;
    // this.hasWz = hasWz;

    var order_type = 2;
    // if (hasWz) {
    //   order_type = 1;
    // }
    (0, _artai.getHeaderNumberApi)({
      order_type: order_type
    }).then(function (res) {
      // console.log(res);

      if (order_type == 2) {
        headNumber['video_index'] = res.data.orderCount;
        headNumber['video_MyTemplate'] = res.data.myTemplateCount;
        headNumber['video_template'] = res.data.templateSquareCount;
      }
      _this.headNumber = headNumber;
      sessionStorage.setItem('headNumber', JSON.stringify(headNumber));
      _this.$emit('getNumber', res.data);
    });
  },
  methods: {
    onRefresh: function onRefresh() {
      this.$emit('onRefresh');
    },
    onOpenModule: function onOpenModule(name) {
      this.$router.replace({
        name: name
      });
      // this.$router.replace('/merchant/artai/myTemplate');
    },
    onOpenPosterBz: function onOpenPosterBz(row) {
      this.$refs.createPosterBzRef.openPoster(row);
    },
    onOpenPoster: function onOpenPoster() {
      this.$refs.createPosterRef.openPoster();
    }
  }
};