var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "videoDialog",
          attrs: {
            title: "",
            visible: _vm.moduleVisible,
            "close-on-click-modal": true,
            top: "5vh",
            "show-close": false,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.moduleVisible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("div", { staticClass: "dialogBody" }, [
            _c("div", { staticClass: "flex dialogCon" }, [
              _c("span", [
                _vm._v(
                  "计划发布时间: \n          " +
                    _vm._s(_vm.moduleForm.released_plan_time) +
                    "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex mt10" }, [
              _vm.moduleForm.extend_data &&
              _vm.moduleForm.extend_data.video_info &&
              _vm.moduleForm.extend_data.video_info.url
                ? _c("div", { staticStyle: { width: "40%" } }, [
                    _c("div", { staticClass: "mmBox" }, [
                      _vm.moduleVisible
                        ? _c("video", {
                            staticStyle: {
                              width: "100%",
                              height: "100%",
                              "object-fit": "contain",
                            },
                            attrs: {
                              controls: "",
                              src: _vm.moduleForm.extend_data.video_info.url,
                            },
                          })
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item-flex-center" },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              "margin-top": "10px",
                              background: "#704af3 !important",
                              border: "1px solid #704af3 !important",
                            },
                            attrs: {
                              type: "primary",
                              size: "small",
                              loading: _vm.loadBut,
                            },
                            on: { click: _vm.onDownload },
                          },
                          [_vm._v("下载视频")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one waBox" }, [
                _c("div", { staticClass: "mmBox" }, [
                  _c("div", { staticClass: "textTit" }, [
                    _vm._v("视频描述文案："),
                  ]),
                  _vm._v(" "),
                  _vm.moduleForm.extend_data &&
                  _vm.moduleForm.extend_data.video_info &&
                  _vm.moduleForm.extend_data.video_info.title
                    ? _c("div", { staticClass: "textDesc" }, [
                        _vm._v(
                          _vm._s(_vm.moduleForm.extend_data.video_info.title)
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.moduleForm.extend_data &&
                  _vm.moduleForm.extend_data.video_info &&
                  _vm.moduleForm.extend_data.video_info.label
                    ? _c(
                        "div",
                        { staticClass: "textDesc flex" },
                        _vm._l(
                          _vm.moduleForm.extend_data.video_info.label,
                          function (m, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                staticClass: "mr10",
                                staticStyle: { color: "rgba(74,131,217,1)" },
                              },
                              [_vm._v("#" + _vm._s(m))]
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "item-flex-center" },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          "margin-top": "10px",
                          background: "#704af3 !important",
                          border: "1px solid #704af3 !important",
                        },
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.onCopyText },
                      },
                      [_vm._v("复制文案")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("SelectAuctionView", {
        ref: "selectAuctionViewRef",
        attrs: {
          venue_id: _vm.moduleForm.venue_id,
          relation_id: _vm.relation_id,
          currentData: [],
        },
        on: { selectMessage: _vm.onAuctionMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }