var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.facade,
        "label-width": "90px",
        size: "mini",
        "label-position": "left",
      },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "模版字段" } },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.facade.temp,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "temp", $$v)
                },
                expression: "facade.temp",
              },
            },
            [
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "pageBackground" } }, [
                    _vm._v("背景颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "borderColor" } }, [
                    _vm._v("边框颜色"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-checkbox", { attrs: { label: "pageColor" } }, [
                    _vm._v("文字颜色"),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }