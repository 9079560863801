"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _control = _interopRequireDefault(require("@/components/customPage/control.vue"));
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
var _slider = _interopRequireDefault(require("@/components/customPage/slider.vue"));
var _setting = require("@/api/setting");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    control: _control.default,
    colour: _colour.default,
    slider: _slider.default,
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      message: ''
    };
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    content: {
      get: function get() {
        return this.$store.getters.content;
      },
      set: function set(value) {
        var data = {
          key: 'content',
          value: value
        };
        this.$store.commit('finish/setAttribute', data);
      }
    },
    facade: {
      get: function get() {
        return this.$store.getters.facade;
      },
      set: function set(value) {
        var data = {
          key: 'facade',
          value: value
        };
        this.$store.commit('finish/setAttribute', data);
      }
    }
  },
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {},
  /**
   * 页面渲染前
   * @return {[type]} [description]
   */
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    setMessageAction: function setMessageAction() {
      var _this = this;
      this.message = this.content.data;
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this.$refs['editor1'].setContent(_this.content.data || '');
      });
    },
    saveMessageAction: function saveMessageAction() {
      var message = this.message.replace(/<img[^>]*>/gi, '').replace(/<p style\s*?=\s*?([‘"])[\s\S]*?\1/g, '<p ');
      message = message.replace(/<section/g, '<p');
      message = message.replace(/<\/section/g, '</p');
      message = message.replace(/<p><\/p>/g, '<p><br></p>');
      message = message.replace(/<p/g, '<p style="white-space: normal;margin: 0px;padding: 0px;box-sizing: border-box;"');
      this.message = '';
      this.content.data = message;
      this.dialogVisible = false;
    },
    changeType: function changeType(e) {
      this.$emit('editText', e);
    },
    editColorAction: function editColorAction() {
      if (this.content.type) {
        this.$emit('editText', this.content.type);
      }
    }
  }
};