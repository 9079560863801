"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _auction = require("@/api/auction");
var _basic = require("@/api/basic");
var _utils = require("@/utils");
var _printJs = _interopRequireDefault(require("print-js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    draggable: _vuedraggable.default
  },
  data: function data() {
    return {
      formatPriceNumber: _utils.formatPriceNumber,
      pageLoading: true,
      changeAlbum: 0,
      startLot: 1,
      albumList: [],
      venue_id: '',
      label_ids: '',
      lock: false,
      lineNum: 10,
      viewWidth: 0,
      lockSet: false,
      openPrint: false
    };
  },
  computed: {
    startSort: function startSort() {
      var count = 0;
      for (var i = 0; i < this.changeAlbum; i += 1) {
        count += this.albumList[i].auctionList.length;
      }
      return count;
    },
    viewW: function viewW() {
      return parseInt((this.viewWidth - this.lineNum * 20) / this.lineNum, 10);
    },
    printW: function printW() {
      return parseInt((1000 - 5 * 20) / 5, 10);
    }
  },
  created: function created() {
    var _this = this;
    this.venue_id = this.$route.query.v_id;
    this.label_ids = this.$route.query.label_ids;
    (0, _basic.getBatchEditLotListApi)({
      label_ids: this.label_ids
    }).then(function (res) {
      if (res.data.list && res.data.list[0]) {
        var newArr = [];
        _this.startLot = res.data.list[0].lot || 1;
        var start = '';
        var empty = 0;
        res.data.list.forEach(function (m, i) {
          if (!m.id) {
            // 添加替代数据
            newArr.push({
              warehouse_image: [],
              contract_image: [],
              product_name: '',
              isZW: true
            });
            if (start === '') {
              empty += 1;
            }
          } else {
            // 放回原数据
            newArr.push(m);
            if (start === '' && m.lot) {
              start = m.lot;
            }
          }
        });
        _this.startLot = start - empty > 0 ? start - empty : 1;
        _this.albumList = newArr;
      } else {
        _this.$message.error('出现错误，请返回重试');
        setTimeout(function () {
          _this.$router.back();
        }, 1000);
      }
      _this.pageLoading = false;
    }).catch(function () {
      _this.pageLoading = false;
    });
    // getBatchEditOrderNumLAuctionListApi({ venue_id: this.venue_id }).then(res => {
    //   if (res.data.auctionList) {
    //     const ids = res.data.auctionList.map(m => m.album_id);
    //     const index = ids.indexOf(Number(this.album_id));
    //     if (index !== -1) {
    //       this.changeAlbum = index;
    //     }
    //     this.startLot = res.data.startLot;
    //     this.albumList = res.data.auctionList;
    //   } else {
    //     this.$message.error('出现错误，请返回重试');
    //     setTimeout(() => {
    //       this.$router.back();
    //     }, 1000);
    //   }
    //   this.pageLoading = false;
    // }).catch(() => {
    //   this.pageLoading = false;
    // });
  },
  mounted: function mounted() {
    this.viewWidth = this.$refs.titleWidthEL.clientWidth;
  },
  methods: {
    delImgAction: function delImgAction(i) {
      this.albumList.splice(i, 1);
    },
    addEmptyAction: function addEmptyAction() {
      this.albumList.push({
        warehouse_image: [],
        contract_image: [],
        product_name: '',
        isZW: true
      });
    },
    setData: function setData(e) {},
    saveAction: function saveAction() {
      var _this2 = this;
      if (this.lock) return;
      this.lock = true;
      var data = [];
      var lot = this.startLot || 1;
      this.albumList.forEach(function (album, albumIndex) {
        if (album.id) {
          data.push({
            id: album.id,
            lot: lot
          });
        } else {
          data.push({
            lot: lot
          });
        }
        lot += 1;
      });
      (0, _basic.batchEditLoApi)({
        erpProductData: data,
        label_ids: this.label_ids
      }).then(function (res) {
        _this2.$message.success('保存成功');
        _this2.lock = false;
      }).catch(function () {
        _this2.lock = false;
      });
    },
    lockAction: function lockAction() {
      var _this3 = this;
      this.$confirm("\u786E\u8BA4".concat(this.lockSet ? '解锁' : '锁定', "\u56FE\u5F55\u53F7?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _auction.setLockLotSortApi)({
          album_id: _this3.album_id,
          is_lock_lot_sort: Number(!_this3.lockSet)
        }).then(function (res) {
          _this3.lockSet = !_this3.lockSet;
          _this3.$message.success('锁定成功');
        }).catch(function () {});
      }).catch(function () {});
    },
    printAction: function printAction() {
      this.$nextTick(function () {
        (0, _printJs.default)({
          printable: 'printEL',
          type: 'html',
          header: null,
          targetStyles: ['*'],
          scanStyles: true,
          maxWidth: 1000,
          font_size: 'auto',
          style: "@page { margin: 5mm 10mm 12mm;size: auto; } .paging{page-break-after: always;}"
        });
      });
    }
  }
};