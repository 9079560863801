"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _index = require("@/utils/index");
var _contract = require("@/api/contract");
var _order = require("@/api/order");
var _system = require("@/api/system");
var _user = require("@/api/user.js");
var _printJs = _interopRequireDefault(require("print-js"));
var _zhTW = _interopRequireDefault(require("@/utils/zh-TW.json"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ids: '',
      formatPriceNumber: _index.formatPriceNumber,
      collectMoney: {
        show: false,
        data: ''
      },
      total_price: 0,
      printObj2: {
        id: 'print1',
        popTitle: ''
      },
      total_rmb: '',
      result: [],
      isNo: true,
      merData: {},
      detail: {}
    };
  },
  computed: {
    feeTypeObj: function feeTypeObj() {
      return this.$store.getters.feeTypeInfo;
    }
  },
  mounted: function mounted() {
    var _this = this;
    (0, _user.getBaseInfo)().then(function (res) {
      res.data.company_english_name = res.data.config.company_english_name;
      _this.merData = res.data;
    });
    (0, _system.getConfigClassKeys)('print_config').then(function (res) {
      if (res.data.print_entrust_auction_end) {
        var d = JSON.parse(res.data.print_entrust_auction_end);
        _this.detail = d;
      } else {
        _this.detail = {
          title: {
            china: '委托方拍后告知书',
            english: 'Notification letter from the client after the auction'
          },
          buyerCompany: {
            china: '您好！感谢您对我公司的大力支持，希望您能一如既往地支持我公司，谢谢！',
            english: "Thanks for your support to our company, we beg your understanding and also hope you can support us as usual!"
          },
          tableColumn: [{
            open: true,
            name: '合同号',
            showName: '',
            english: 'CONTRACT NO.',
            w: 135
          }, {
            open: true,
            name: '图录号',
            showName: '',
            english: 'LOT NO.',
            w: 80
          }, {
            open: true,
            name: '拍品名称',
            showName: '',
            english: 'DESCRIPTION'
          }, {
            open: true,
            name: '保留价',
            showName: '',
            english: 'SELES PRICE',
            w: 105
          }, {
            open: true,
            name: '实得价',
            showName: '',
            english: 'NET PROCEEDS',
            w: 125
          }, {
            open: true,
            name: '落槌价',
            showName: '',
            english: 'HAMMER PRICE',
            w: 125
          }, {
            open: true,
            name: '成交状态',
            showName: '',
            english: 'SALES STATUS',
            w: 125
          }, {
            open: true,
            name: '专场名称',
            showName: '',
            english: 'SESSION NAME',
            w: 125
          }],
          settleText: {
            china: '尊敬的委託人您好！非常感謝對我公司的支持和幫助，現將您委託我公司拍賣的作品成交結果呈上，請您查收並核對。懇請您一如既往地支援我公司。謝謝。',
            english: 'Dear client, thank you very much for your support and assistance to our company. We are now submitting the transaction results of the works you entrusted our company for auction. Please check and verify them. Please continue to support our company as always. Thank you.'
          },
          signColumnTab: 'system',
          signColumnCustome: ''
        };
      }
    });
  },
  methods: {
    switchLang: function switchLang(filed) {
      if (this.feeTypeObj.mer_fee_type == 'HKD') {
        return _zhTW.default.TW[filed];
      } else return _zhTW.default.CN[filed];
    },
    bindPrintorder: function bindPrintorder() {
      //console.log("是不是修改的这里啊");
      var that = this;
      (0, _printJs.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
        printable: "printorder",
        type: "html",
        header: null,
        targetStyles: ["*"],
        scanStyles: true,
        maxWidth: 1000,
        font_size: "auto",
        style: "@page {margin:5mm 10mm 12mm}",
        onPrintDialogClose: function onPrintDialogClose() {
          console.log('关闭');
        }
      }, "onPrintDialogClose", function onPrintDialogClose() {
        console.log('pdf');
        that.$emit('load');
      }), "onPdfOpen", function onPdfOpen() {
        console.log('pdf1');
      }));
    },
    loadData: function loadData(data) {
      var _this2 = this;
      this.isNo = true;
      var blTotal = 0;
      var sdTotal = 0;
      var lcTotal = 0;
      data.auctionList.forEach(function (m) {
        blTotal += m.auction ? Number(m.erpProduct.retain_price) : 0;
        sdTotal += m.erpProduct ? Number(m.erpProduct.take_home_price) : 0;
        lcTotal += m.erpProduct ? Number(m.auction.now_price) : 0;
      });
      this.collectMoney = {
        data: data,
        show: true,
        blTotal: blTotal,
        sdTotal: sdTotal,
        lcTotal: lcTotal
      };
      this.$nextTick(function () {
        // 赋值后马上更新
        _this2.compendHeight();
      });
    },
    compendHeight: function compendHeight() {
      var num = 0;
      var heightAll = 0;
      var arr = [];
      var dom = document.getElementsByClassName('calcul');
      var printTopDom = document.getElementsByClassName('all-print-main-el');
      var h = 1350 - printTopDom[0].offsetTop;
      for (var i = 0; i < dom.length; i++) {
        heightAll += Number(window.getComputedStyle(dom[i]).height.replace('px', ''));
        if (num == 0 && heightAll > h) {
          arr.push(i);
          num += 1;
        } else if (num > 0 && heightAll > 1200 * num + h) {
          arr.push(i);
          num += 1;
        }
      }
      ;
      this.result = this.sliceArrayIntoChunks(this.collectMoney.data.auctionList, arr);
      this.isNo = false;
    },
    sliceArrayIntoChunks: function sliceArrayIntoChunks(array, indexes) {
      var arr = [];
      var len = array.length;
      var ind = 0;
      indexes.forEach(function (item, index) {
        arr.push(array.slice(ind, item));
        ind = item;
      });
      arr.push(array.slice(indexes[indexes.length - 1], len));
      return arr;
    }
  }
};