var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "infinite-scroll",
          rawName: "v-infinite-scroll",
          value: _vm.loadmore,
          expression: "loadmore",
        },
      ],
      staticClass: "posterMain",
      attrs: {
        "infinite-scroll-distance": 150,
        "infinite-scroll-immediate": false,
      },
    },
    [
      _c("posterHeader", {
        ref: "posterHeaderEL",
        on: { onRefresh: _vm.onRefresh },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "imgWaterFall",
          staticClass: "flex",
          staticStyle: { "margin-bottom": "20px", "padding-top": "20px" },
        },
        [
          _vm.posterList.length
            ? _c(
                "div",
                { staticClass: "posterEndBox" },
                _vm._l(_vm.realColumn, function (m, colI) {
                  return _c(
                    "div",
                    {
                      key: "realColumn" + colI,
                      staticClass: "col",
                      style: "width: " + 100 / _vm.realColumn + "%;",
                    },
                    [
                      _vm._l(_vm.posterList, function (item, index) {
                        return [
                          index % _vm.realColumn == colI
                            ? [
                                _c(
                                  "div",
                                  { key: "item_" + colI + "_" + index },
                                  [
                                    item.order_status === 1
                                      ? _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "loading",
                                                rawName: "v-loading",
                                                value: item.loading,
                                                expression: "item.loading",
                                              },
                                            ],
                                            staticClass: "posterEndItem",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "item is-loading",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.goonAction(
                                                      item,
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "position-img",
                                                    staticStyle: {
                                                      right: "10px",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.delAction(
                                                          item.order_id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          effect: "dark",
                                                          content: "删除",
                                                          placement: "top",
                                                          enterable: false,
                                                        },
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: require("@/assets/images/poster/del.png"),
                                                            alt: "",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _vm._m(0, true),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "txt" },
                                                  [_vm._v("生成失败")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "btn" },
                                                  [_vm._v("继续生成")]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "other-data" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.generate_demand
                                                      .templateTagText
                                                      ? item.generate_demand
                                                          .templateTagText +
                                                          " | "
                                                      : ""
                                                  ) +
                                                    _vm._s(
                                                      item.generate_demand
                                                        .templateLevelText
                                                    ) +
                                                    "模版 | " +
                                                    _vm._s(item.create_time)
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._l(item.orderData, function (ele, ind) {
                                      return _c(
                                        "div",
                                        {
                                          key:
                                            "item_" +
                                            colI +
                                            "_" +
                                            index +
                                            "_" +
                                            ind,
                                          staticClass: "posterEndItem",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "item",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goEditAction(
                                                    ele.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              ele.generate_result_status == 2
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "position-img",
                                                        staticStyle: {
                                                          right: "50px",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.onSwitchResult(
                                                              ele.id,
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              effect: "dark",
                                                              content: "确认",
                                                              placement: "top",
                                                              enterable: false,
                                                            },
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: require("@/assets/images/poster/dui.png"),
                                                                alt: "",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _vm._v(" "),
                                              ele.generate_result_status == 3
                                                ? [
                                                    ele.is_like == 0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "position-img",
                                                            staticStyle: {
                                                              right: "50px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.onSwitchLink(
                                                                  ele.id,
                                                                  1,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-tooltip",
                                                              {
                                                                attrs: {
                                                                  effect:
                                                                    "dark",
                                                                  content:
                                                                    "点赞",
                                                                  placement:
                                                                    "top",
                                                                  enterable: false,
                                                                },
                                                              },
                                                              [
                                                                _c("img", {
                                                                  attrs: {
                                                                    src: require("@/assets/images/poster/zan.png"),
                                                                    alt: "",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "position-img",
                                                            staticStyle: {
                                                              right: "50px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.onSwitchLink(
                                                                  ele.id,
                                                                  0,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-tooltip",
                                                              {
                                                                attrs: {
                                                                  effect:
                                                                    "dark",
                                                                  content:
                                                                    "取消点赞",
                                                                  placement:
                                                                    "top",
                                                                  enterable: false,
                                                                },
                                                              },
                                                              [
                                                                _c("img", {
                                                                  attrs: {
                                                                    src: require("@/assets/images/poster/redlod.png"),
                                                                    alt: "",
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                  ]
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "position-img",
                                                  staticStyle: {
                                                    right: "10px",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.delAction(
                                                        item.order_id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        effect: "dark",
                                                        content: "删除",
                                                        placement: "top",
                                                        enterable: false,
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: require("@/assets/images/poster/del.png"),
                                                          alt: "",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "cover" },
                                                [
                                                  _c("i", {
                                                    style: {
                                                      backgroundImage:
                                                        "url(" +
                                                        ele.generate_result
                                                          .config_data.image +
                                                        ")",
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "name line2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      ele.generate_result
                                                        .config_data.title
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              ele.generate_result.config_data
                                                .digest
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "desc line2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          ele.generate_result
                                                            .config_data.digest
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "time" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "l" },
                                                    [
                                                      _vm._v(
                                                        "更新于 " +
                                                          _vm._s(
                                                            ele.update_time
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  ele.generate_result_status ==
                                                  2
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "hb-conStatus",
                                                        },
                                                        [_vm._v("待确认")]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  ele.generate_result_status ==
                                                  3
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "hb3-conStatus",
                                                        },
                                                        [_vm._v("已确认")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "other-data" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  item.generate_demand
                                                    .templateTagText
                                                    ? item.generate_demand
                                                        .templateTagText + " | "
                                                    : ""
                                                ) +
                                                  _vm._s(
                                                    item.generate_demand
                                                      .templateLevelText
                                                  ) +
                                                  "模版 | " +
                                                  _vm._s(item.create_time)
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }),
                                  ],
                                  2
                                ),
                              ]
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.loadTemplate && _vm.posterList.length == 0
            ? _c("div", { staticClass: "no-data" }, [_vm._v("暂无模版")])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loading-icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/article/warn.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }