var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("header-breadcrumb", { attrs: { name: "批量上拍", "has-back": "" } }),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "demo-promoterForm",
              attrs: {
                model: _vm.formValidate,
                rules: _vm.rules,
                "label-width": "100px",
                inline: "",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择拍卖会", prop: "venue_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: {
                        placeholder: "请选择拍卖会",
                        clearable: "",
                        filterable: "",
                      },
                      on: { change: _vm.changevenue },
                      model: {
                        value: _vm.formValidate.venue_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "venue_id", $$v)
                        },
                        expression: "formValidate.venue_id",
                      },
                    },
                    _vm._l(_vm.venceList, function (item) {
                      return _c("el-option", {
                        key: item.venue_id,
                        attrs: { label: item.title, value: item.venue_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择专场", prop: "album_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: {
                        placeholder: "请选择专场",
                        clearable: "",
                        filterable: "",
                      },
                      on: { change: _vm.selectalbum },
                      model: {
                        value: _vm.formValidate.album_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "album_id", $$v)
                        },
                        expression: "formValidate.album_id",
                      },
                    },
                    _vm._l(_vm.albumArr, function (item) {
                      return _c("el-option", {
                        key: item.album_id,
                        attrs: { label: item.album_name, value: item.album_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { height: "calc(100vh - 215px)" } },
                [
                  _c(
                    "el-table",
                    {
                      staticClass: "sorttable",
                      attrs: {
                        data: _vm.goodsArr,
                        height: "100%",
                        size: "small",
                        "highlight-current-row": "",
                        "row-key": "product_id",
                      },
                      nativeOn: {
                        paste: function ($event) {
                          return _vm.pasteExcel($event)
                        },
                      },
                    },
                    [
                      _c("template", { slot: "empty" }, [
                        _c("div", { staticClass: "tableEmpty" }, [
                          _c("img", {
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "tableDiv" }, [
                            _vm._v("暂无数据"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "排序", width: "80" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  staticClass: "sortCol",
                                  attrs: { placeholder: "排序" },
                                  model: {
                                    value: scope.row.sort,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "sort", $$v)
                                    },
                                    expression: "scope.row.sort",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "图录号", prop: "lot", width: "60" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "拍品名称",
                          prop: "auction_name",
                          "min-width": "140",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "", "min-width": "120" },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "headPos" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex align-items-c cur",
                                      staticStyle: { padding: "0 9px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.headClick("bid_range_id")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v("\n                竞价阶梯 "),
                                      _c("i", {
                                        staticClass: "el-icon-edit-outline",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        poaBox: true,
                                        isShow: _vm.handName == "bid_range_id",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          ref: "bid_range_id",
                                          staticClass: "sortCol",
                                          attrs: {
                                            clearable: "",
                                            filterable: "",
                                            placeholder: "竞价阶梯",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onHeadChange(
                                                "bid_range_id"
                                              )
                                            },
                                            blur: function ($event) {
                                              _vm.handName = ""
                                            },
                                          },
                                          model: {
                                            value: _vm.headObj.bid_range_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.headObj,
                                                "bid_range_id",
                                                $$v
                                              )
                                            },
                                            expression: "headObj.bid_range_id",
                                          },
                                        },
                                        _vm._l(_vm.bidRList, function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.title,
                                              value: item.id,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "sortCol",
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "竞价阶梯",
                                    },
                                    model: {
                                      value: scope.row.bid_range_id,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "bid_range_id", $$v)
                                      },
                                      expression: "scope.row.bid_range_id",
                                    },
                                  },
                                  _vm._l(_vm.bidRList, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.title,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "", "min-width": "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "headPos" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex align-items-c cur",
                                      staticStyle: { padding: "0 9px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.headClick("start_price")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v("\n                起拍价 "),
                                      _c("i", {
                                        staticClass: "el-icon-edit-outline",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        poaBox: true,
                                        isShow: _vm.handName == "start_price",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        ref: "start_price",
                                        staticClass: "sortCol",
                                        attrs: { placeholder: "起拍价" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onHeadChange(
                                              "start_price"
                                            )
                                          },
                                          blur: function ($event) {
                                            _vm.handName = ""
                                          },
                                        },
                                        model: {
                                          value: _vm.headObj.start_price,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.headObj,
                                              "start_price",
                                              $$v
                                            )
                                          },
                                          expression: "headObj.start_price",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  staticClass: "sortCol",
                                  attrs: { placeholder: "起拍价" },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.inpFocusAction(
                                        "start_price",
                                        scope.$index
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.inpFocusAction("")
                                    },
                                  },
                                  model: {
                                    value: scope.row.start_price,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "start_price", $$v)
                                    },
                                    expression: "scope.row.start_price",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "", "min-width": "120" },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "headPos" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex align-items-c cur",
                                      staticStyle: { padding: "0 9px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.headClick("refOne")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v("\n                估价区间 "),
                                      _c("i", {
                                        staticClass: "el-icon-edit-outline",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        poaBox: true,
                                        isShow: _vm.handName == "refOne",
                                        flex: true,
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        ref: "refOne",
                                        staticClass: "sortCol inptext",
                                        attrs: { placeholder: "最小值" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onHeadChange("refOne")
                                          },
                                          blur: _vm.oneBlue,
                                        },
                                        model: {
                                          value: _vm.headObj.refOne,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.headObj, "refOne", $$v)
                                          },
                                          expression: "headObj.refOne",
                                        },
                                      }),
                                      _vm._v(
                                        "\n                -\n                "
                                      ),
                                      _c("el-input", {
                                        staticClass: "sortCol inptext",
                                        attrs: { placeholder: "最大值" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onHeadChange("refTwo")
                                          },
                                          blur: _vm.oneBlue,
                                        },
                                        model: {
                                          value: _vm.headObj.refTwo,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.headObj, "refTwo", $$v)
                                          },
                                          expression: "headObj.refTwo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "flex" },
                                  [
                                    _c("el-input", {
                                      staticClass: "sortCol inptext",
                                      attrs: { placeholder: "最小值" },
                                      on: {
                                        focus: function ($event) {
                                          return _vm.inpFocusAction(
                                            "refOne",
                                            scope.$index
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.inpFocusAction("")
                                        },
                                      },
                                      model: {
                                        value: scope.row.refOne,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "refOne", $$v)
                                        },
                                        expression: "scope.row.refOne",
                                      },
                                    }),
                                    _vm._v("\n              -\n              "),
                                    _c("el-input", {
                                      staticClass: "sortCol inptext",
                                      attrs: { placeholder: "最大值" },
                                      on: {
                                        focus: function ($event) {
                                          return _vm.inpFocusAction(
                                            "refTwo",
                                            scope.$index
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.inpFocusAction("")
                                        },
                                      },
                                      model: {
                                        value: scope.row.refTwo,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "refTwo", $$v)
                                        },
                                        expression: "scope.row.refTwo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "", "min-width": "140" },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "headPos" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex align-items-c cur",
                                      staticStyle: { padding: "0 9px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.headClick("bond_price")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v("\n                拍品保证金 "),
                                      _c("i", {
                                        staticClass: "el-icon-edit-outline",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        poaBox: true,
                                        isShow: _vm.handName == "bond_price",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        ref: "bond_price",
                                        staticClass: "sortCol",
                                        attrs: { placeholder: "拍品保证金" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onHeadChange(
                                              "bond_price"
                                            )
                                          },
                                          blur: function ($event) {
                                            _vm.handName = ""
                                          },
                                        },
                                        model: {
                                          value: _vm.headObj.bond_price,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.headObj,
                                              "bond_price",
                                              $$v
                                            )
                                          },
                                          expression: "headObj.bond_price",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  staticClass: "sortCol",
                                  attrs: {
                                    placeholder: "保证金",
                                    disabled:
                                      _vm.albumdetail.bond_type == 2 ||
                                      _vm.albumdetail.album_type == 3,
                                  },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.inpFocusAction(
                                        "bond_price",
                                        scope.$index
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.inpFocusAction("")
                                    },
                                  },
                                  model: {
                                    value: scope.row.bond_price,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "bond_price", $$v)
                                    },
                                    expression: "scope.row.bond_price",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "", "min-width": "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "headPos" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex align-items-c cur",
                                      staticStyle: { padding: "0 9px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.headClick("retain_price")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v("\n                保留价 "),
                                      _c("i", {
                                        staticClass: "el-icon-edit-outline",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        poaBox: true,
                                        isShow: _vm.handName == "retain_price",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        ref: "retain_price",
                                        staticClass: "sortCol",
                                        attrs: { placeholder: "保留价" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onHeadChange(
                                              "retain_price"
                                            )
                                          },
                                          blur: function ($event) {
                                            _vm.handName = ""
                                          },
                                        },
                                        model: {
                                          value: _vm.headObj.retain_price,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.headObj,
                                              "retain_price",
                                              $$v
                                            )
                                          },
                                          expression: "headObj.retain_price",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  staticClass: "sortCol",
                                  attrs: { placeholder: "保留价" },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.inpFocusAction(
                                        "retain_price",
                                        scope.$index
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.inpFocusAction("")
                                    },
                                  },
                                  model: {
                                    value: scope.row.retain_price,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "retain_price", $$v)
                                    },
                                    expression: "scope.row.retain_price",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "", "min-width": "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "headPos" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex align-items-c cur",
                                      staticStyle: { padding: "0 9px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.headClick("top_price")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v("\n                封顶价 "),
                                      _c("i", {
                                        staticClass: "el-icon-edit-outline",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        poaBox: true,
                                        isShow: _vm.handName == "top_price",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        ref: "top_price",
                                        staticClass: "sortCol",
                                        attrs: { placeholder: "封顶价" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onHeadChange("top_price")
                                          },
                                          blur: function ($event) {
                                            _vm.handName = ""
                                          },
                                        },
                                        model: {
                                          value: _vm.headObj.top_price,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.headObj,
                                              "top_price",
                                              $$v
                                            )
                                          },
                                          expression: "headObj.top_price",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  staticClass: "sortCol",
                                  attrs: { placeholder: "封顶价" },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.inpFocusAction(
                                        "top_price",
                                        scope.$index
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.inpFocusAction("")
                                    },
                                  },
                                  model: {
                                    value: scope.row.top_price,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "top_price", $$v)
                                    },
                                    expression: "scope.row.top_price",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "", "min-width": "150" },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "headPos" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex align-items-c cur",
                                      staticStyle: { padding: "0 9px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.headClick("postage_type")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v("\n                运费方式 "),
                                      _c("i", {
                                        staticClass: "el-icon-edit-outline",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        poaBox: true,
                                        isShow: _vm.handName == "postage_type",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          ref: "postage_type",
                                          staticClass: "sortCol",
                                          attrs: {
                                            slot: "prepend",
                                            placeholder: "运费类型",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onHeadChange(
                                                "postage_type"
                                              )
                                            },
                                            blur: function ($event) {
                                              _vm.handName = ""
                                            },
                                          },
                                          slot: "prepend",
                                          model: {
                                            value: _vm.headObj.postage_type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.headObj,
                                                "postage_type",
                                                $$v
                                              )
                                            },
                                            expression: "headObj.postage_type",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "固定运费",
                                              value: "2",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "包邮",
                                              value: "3",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "到付",
                                              value: "4",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "sortCol",
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: scope.row.postage_type,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "postage_type", $$v)
                                      },
                                      expression: "scope.row.postage_type",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "固定运费", value: "2" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "包邮", value: "3" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { label: "到付", value: "4" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "", "min-width": "150" },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "headPos" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex align-items-c cur",
                                      staticStyle: { padding: "0 9px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.headClick("postage_price")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v("\n                运费 "),
                                      _c("i", {
                                        staticClass: "el-icon-edit-outline",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        poaBox: true,
                                        isShow: _vm.handName == "postage_price",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        ref: "postage_price",
                                        staticClass:
                                          "input-with-select sortCol",
                                        attrs: {
                                          placeholder: "运费",
                                          disabled:
                                            _vm.headObj.postage_type != "2",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onHeadChange(
                                              "postage_price"
                                            )
                                          },
                                          blur: function ($event) {
                                            _vm.handName = ""
                                          },
                                        },
                                        model: {
                                          value: _vm.headObj.postage_price,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.headObj,
                                              "postage_price",
                                              $$v
                                            )
                                          },
                                          expression: "headObj.postage_price",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  staticClass: "input-with-select sortCol",
                                  attrs: {
                                    placeholder: "运费",
                                    disabled: scope.row.postage_type != "2",
                                  },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.inpFocusAction(
                                        "postage_price",
                                        scope.$index
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.inpFocusAction("")
                                    },
                                  },
                                  model: {
                                    value: scope.row.postage_price,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "postage_price", $$v)
                                    },
                                    expression: "scope.row.postage_price",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "", "min-width": "120" },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "headPos" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex align-items-c cur",
                                      staticStyle: { padding: "0 9px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.headClick("browse")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v("\n                浏览次数 "),
                                      _c("i", {
                                        staticClass: "el-icon-edit-outline",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        poaBox: true,
                                        isShow: _vm.handName == "browse",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        ref: "browse",
                                        staticClass: "sortCol",
                                        attrs: { placeholder: "浏览次数" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onHeadChange("browse")
                                          },
                                          blur: function ($event) {
                                            _vm.handName = ""
                                          },
                                        },
                                        model: {
                                          value: _vm.headObj.browse,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.headObj, "browse", $$v)
                                          },
                                          expression: "headObj.browse",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  staticClass: "sortCol",
                                  attrs: { placeholder: "浏览次数" },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.inpFocusAction(
                                        "browse",
                                        scope.$index
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.inpFocusAction("")
                                    },
                                  },
                                  model: {
                                    value: scope.row.browse,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "browse", $$v)
                                    },
                                    expression: "scope.row.browse",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "80", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delGoods(
                                              scope.$index,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("移除")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c("span", { staticClass: "ft14 mr20" }, [
                        _vm._v("共 " + _vm._s(_vm.goodsArr.length) + " 条"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { type: "primary", loading: _vm.loading },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("formValidate")
                            },
                          },
                        },
                        [_vm._v("\n            确认上架\n          ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12, offset: 0 } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择货品",
            visible: _vm.selectFlag,
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.selectFlag = $event
            },
            close: function ($event) {
              _vm.selectFlag = false
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { inline: "", size: "small", "label-width": "110px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "货品搜索：" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: {
                        placeholder: "请输入货品名称，关键字，产品编号",
                      },
                      model: {
                        value: _vm.tableFrom.keyword,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "keyword", $$v)
                        },
                        expression: "tableFrom.keyword",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "货品ID：" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "selWidth",
                      attrs: { placeholder: "请输入货品ID" },
                      model: {
                        value: _vm.tableFrom.product_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "product_id", $$v)
                        },
                        expression: "tableFrom.product_id",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.getList(1)
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "货品ID",
                  prop: "product_id",
                  "min-width": "50",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "货品图", "min-width": "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.image,
                                "preview-src-list": [scope.row.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "store_name",
                  label: "货品名称",
                  "min-width": "140",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "brand", label: "作者/品牌", "min-width": "90" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.brand
                          ? _c("div", [
                              _vm._v(
                                "\n               " +
                                  _vm._s(scope.row.brand.brand_name) +
                                  "\n             "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "分类", "min-width": "100" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return scope.row.storeCategory
                          ? [
                              _c("span", { staticClass: "mr10" }, [
                                _vm._v(
                                  _vm._s(scope.row.storeCategory.cate_name)
                                ),
                              ]),
                            ]
                          : undefined
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "属性", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          _vm._l(scope.row.attrInfo, function (m, i) {
                            return _c("span", { key: "attr_" + i }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(m.attr_name) +
                                  ":" +
                                  _vm._s(m.attr_value_name) +
                                  "\n                "
                              ),
                            ])
                          }),
                          0
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "创建时间",
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "录入",
                  "min-width": "90",
                  prop: "creator.real_name",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.saleGoods(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.goodsIdArr.indexOf(
                                        scope.row.product_id
                                      ) > -1
                                        ? "已选择货品"
                                        : "选择货品"
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.closeSelect } }, [
                _vm._v("确认"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }