"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _contract = require("@/api/contract");
var _auction = require("@/api/auction");
var _artai = require("@/api/artai");
var _aiOpen = require("@/api/aiOpen");
var _changeAuction = _interopRequireDefault(require("./changeAuction"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    selectView: _changeAuction.default
  },
  data: function data() {
    return {
      visible: false,
      qualityList: [{
        id: '1',
        name: '标准'
      }, {
        id: '2',
        name: '高级'
      }
      // {id: '3',name: '定制'},
      ],
      ratioList: [{
        id: '16:9',
        name: '16:9'
      }, {
        id: '9:16',
        name: '9:16'
      }, {
        id: '4:3',
        name: '4:3'
      }, {
        id: '3:4',
        name: '3:4'
      }, {
        id: '1:1',
        name: '1:1'
      }],
      formValidate: {
        source: '1',
        order_type: 2,
        poster_type: '1811316210128351232',
        poster_quality: '1',
        ratio: '',
        contentDefine: '',
        template_ids: '',
        is_pay: 0,
        productName: ''
      },
      venueList: [],
      albumList: [],
      auctList: [],
      liveList: [],
      templateList: [],
      posterSize: '',
      dingwei: {},
      weidu: {}
    };
  },
  computed: {},
  mounted: function mounted() {
    var _this = this;
    (0, _contract.getLastAuctionApi)({
      page: 1,
      limit: 20
    }).then(function (res) {
      _this.venueList = res.data.list;
    });
    // getLiveListApi({ page: 1, limit: 20 }).then(res => {
    //   this.liveList = res.data.list
    // })
    (0, _aiOpen.attrListApi)({
      useType: 3,
      isHide: true
    }).then(function (res) {
      res.rows.forEach(function (m) {
        if (m.attrName === '内容定义') {
          _this.dingwei = Object.assign({}, m);
        } else if (m.attrName === '内容类型') {
          _this.weidu = Object.assign({}, m);
        }
      });
    });
  },
  methods: {
    handleAdditem: function handleAdditem() {
      if (!this.formValidate.venue_id) {
        this.$message.error('请先选择拍卖会');
        return;
      }
      if (this.formValidate.poster_type == '1811316210145128448' && !this.formValidate.album_id) {
        this.$message.error('请先选择专场');
        return;
      }
      var model = {
        venue_id: this.formValidate.venue_id,
        album_id: this.formValidate.album_id ? this.formValidate.album_id : ''
      };
      this.$refs.selectView.changeshow(model);
    },
    selectMessage: function selectMessage(data) {
      var newdata = JSON.parse(JSON.stringify(data));
      // this.formValidate.productName = `已选择${newdata.data.length}条`
      this.$set(this.formValidate, 'productName', "\u5DF2\u9009\u62E9".concat(newdata.data.length, "\u6761"));
      this.formValidate.auctionIds = newdata.data.map(function (item) {
        return item.id;
      }).join(',');
    },
    handlerSame: function handlerSame(type) {
      // this.formValidate.videoId = id;
      this.formValidate.contentType = type;
      this.visible = true;
    },
    onNext: function onNext() {
      this.visible = false;
      this.$emit('callbackData', this.formValidate);
      // let obj = {
      //   'venue': 'venue_id',
      //   'album': 'album_id',
      //   'auction': 'venue_id',
      // }
      // this.$router.push({
      //   name: 'videoInfo',
      //   query: { id: this.formValidate.videoId, auctionIds: this.formValidate.auctionIds, dataId: this.formValidate[obj[this.formValidate.contentType]] }
      // })
    },
    onSubmit: function onSubmit() {
      // let demand_data = []
      // if (this.formValidate.is_pay == 1) {
      //   demand_data = this.formValidate.demand_data.map(item => {
      //     return {
      //       live_id: item.live_id,
      //       venue_id: item.venue_id,
      //       source: item.source,
      //       album_id: item.album_id,
      //       product_id: item.product_id
      //     }
      //   })
      // }else demand_data = this.formValidate.demand_data;

      // let model = {
      //   order_type: this.formValidate.order_type,
      //   is_pay: this.formValidate.is_pay,
      //   generate_demand: {
      //     poster_type: this.formValidate.poster_type,
      //     demand_data,
      //     poster_size: this.formValidate.poster_size,
      //     generate_count: this.formValidate.generate_count,
      //     poster_quality: this.formValidate.poster_quality,
      //     template_ids: this.formValidate.template_ids,
      //   }
      // }
      // marketMediaCreateApi(model).then(res => {
      //   // console.log(res);
      //   this.visible = false;
      //   if (this.formValidate.order_type == 1) {
      //     this.$emit('onRefresh')
      //   }
      // })
    },
    liveInit: function liveInit(val) {
      var _this2 = this;
      (0, _artai.getLiveListApi)({
        page: 1,
        limit: 20,
        keyword: val
      }).then(function (res) {
        _this2.liveList = res.data.list;
      });
    },
    venueInit: function venueInit(val, index) {
      var _this3 = this;
      (0, _contract.getLastAuctionApi)({
        title: val,
        page: 1,
        limit: 20
      }).then(function (res) {
        // const row = this.formValidate.demand_data[index];
        _this3.venueList = res.data.list;
        // this.$set(this.formValidate.demand_data, index, row)
      });
    },
    getTemplateInit: function getTemplateInit(data_type) {
      var _this4 = this;
      (0, _artai.getTemplateListApi)({
        data_type: data_type
      }).then(function (res) {
        _this4.templateList = res.data.rows;
      });
    },
    onVenueChange: function onVenueChange(val) {
      // if (this.formValidate.poster_type == 'album'||this.formValidate.poster_type == 'auction') {
      this.albumInit('');
      // }
    },
    albumInit: function albumInit(val) {
      var _this5 = this;
      (0, _auction.albumSearchList)({
        page: 1,
        limit: 20,
        keyword: val,
        venue_id: this.formValidate.venue_id
      }).then(function (res) {
        // console.log('1------',res);
        // const row = this.formValidate.demand_data[index];
        _this5.albumList = res.data.list;
        // this.$set(this.formValidate.demand_data, index, row)
      });
    },
    onAlbumChange: function onAlbumChange() {
      this.aucInit('');
    },
    aucInit: function aucInit(val) {
      var _this6 = this;
      (0, _auction.auctionList)({
        page: 1,
        limit: 20,
        venue_id: this.formValidate.venue_id,
        album_id: this.formValidate.album_id,
        keyword: val
      }).then(function (res) {
        // const row = this.formValidate.demand_data[index];
        _this6.auctList = res.data.list;
        // this.$set(this.formValidate.demand_data, index, row)
      });
    },
    onChangeType: function onChangeType(val) {
      this.formValidate.poster_type = val;
    },
    onChangeQuality: function onChangeQuality(val, filed) {
      this.formValidate[filed] = val;
      if (val == '3' && filed == 'poster_quality') this.getTemplateInit(this.formValidate.poster_type);
    },
    openPoster: function openPoster() {
      this.formValidate = {
        source: '1',
        order_type: 2,
        poster_type: '1811316210128351232',
        poster_quality: '1',
        ratio: '',
        contentDefine: '',
        template_ids: '',
        is_pay: 0,
        productName: ''
      };
      this.visible = true;
    },
    onClose: function onClose() {
      // this.formValidate.is_pay = 0;
      // this.onSubmit();
      this.visible = false;
    }
  }
};