"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var auctionRouter = {
  path: "".concat(_settings.roterPre, "/artai"),
  component: _layout.default,
  redirect: "".concat(_settings.roterPre, "/artai/consoleBoard"),
  meta: {
    title: '拍场管理',
    icon: 'el-icon-s-help'
  },
  children: [{
    path: 'consoleBoard',
    name: 'consoleBoard',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/artai/console'));
      });
    },
    meta: {
      title: '控制台',
      noCache: true
    }
  }, {
    path: 'sc',
    name: 'sc',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/artai/sc'));
      });
    },
    meta: {
      title: '素材库',
      noCache: true
    }
  }, {
    path: 'spTemplate',
    name: 'spTemplate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/artai/sp'));
      });
    },
    meta: {
      title: '生成视频',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/artai/spis")
    }
  }, {
    path: 'spMyTemplate',
    name: 'spMyTemplate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/artai/sp/spTemplate'));
      });
    },
    meta: {
      title: '我的模版',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/artai/spis")
    }
  }, {
    path: 'spis',
    name: 'sp',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/artai/sp/mySp'));
      });
    },
    meta: {
      title: '我的视频',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/artai/spis")
    }
  }, {
    path: 'videoInfo',
    name: 'videoInfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/artai/sp/videoInfo'));
      });
    },
    meta: {
      title: '视频编辑',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/artai/spis")
    }
  }, {
    path: 'zb',
    name: 'zb',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/artai/zb'));
      });
    },
    meta: {
      title: '直播剪辑',
      noCache: true
    }
  }, {
    path: 'poster',
    name: 'poster',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/artai/poster/posterEnd'));
      });
    },
    // component: () => import('@/views/artai/poster'),
    meta: {
      title: '生成海报',
      noCache: true
    }
  }, {
    path: 'posterEnd',
    name: 'posterEnd',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/artai/poster/posterMyTemplate'));
      });
    },
    // component: () => import('@/views/artai/poster/posterEnd'),
    meta: {
      title: '海报结果',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/artai/poster")
    }
  }, {
    path: 'posterTemplate',
    name: 'posterTemplate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/artai/poster/posterTemplate'));
      });
    },
    meta: {
      title: '我的模版',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/artai/poster")
    }
  }, {
    path: 'wzTemplate',
    name: 'wz_template',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/artai/wz'));
      });
    },
    meta: {
      title: '生成文章',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/artai/wz")
    }
  }, {
    path: 'wz',
    name: 'wz_index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/artai/wz/result'));
      });
    },
    meta: {
      title: '生成文章',
      noCache: true
    }
  }, {
    path: 'myTemplate',
    name: 'wz_MyTemplate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/artai/wz/myTemplate'));
      });
    },
    meta: {
      title: '我的模版',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/artai/wz")
    }
  }, {
    path: 'fb',
    name: 'fb',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/artai/fb'));
      });
    },
    meta: {
      title: '一键发布',
      noCache: true
    }
  }, {
    path: 'config',
    name: 'config',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/artai/config'));
      });
    },
    meta: {
      title: '系统设置',
      noCache: true
    }
  }]
};
var _default = exports.default = auctionRouter;