"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _print_auction_order_bill_info = _interopRequireDefault(require("./module/print_auction_order_bill_info"));
var _print_auction_transaction_order_bill_info = _interopRequireDefault(require("./module/print_auction_transaction_order_bill_info"));
var _print_entrust_auction_end = _interopRequireDefault(require("./module/print_entrust_auction_end"));
var _print_erp_contract_check_auction = _interopRequireDefault(require("./module/print_erp_contract_check_auction"));
var _print_entrust_auction_create = _interopRequireDefault(require("./module/print_entrust_auction_create"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    PrintAuctionOrderBillInfo: _print_auction_order_bill_info.default,
    PrintAuctionTransactionOrderBillInfo: _print_auction_transaction_order_bill_info.default,
    PrintEntrustAuctionEnd: _print_entrust_auction_end.default,
    PrintErpContractCheckAuction: _print_erp_contract_check_auction.default,
    PrintEntrustAuctionCreate: _print_entrust_auction_create.default
  },
  data: function data() {
    return {
      key: this.$route.query.t || 'print_auction_order_bill_info'
    };
  },
  methods: {}
};