var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container videoMain flex" },
    [
      _c("div", { staticClass: "videoLeftBox" }, [
        _c("div", { staticClass: "breadcrumb flex align-items-c" }, [
          _c("div", { staticClass: "icon" }, [
            _c("img", {
              staticClass: "back",
              attrs: {
                src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024529/image/1716954194860207587.png!120a",
                alt: "返回",
              },
              on: { click: _vm.backAction },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex-one pageName" }, [
            _vm._v("视频内容编辑"),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { ref: "dplayer", staticStyle: { "max-width": "850px" } }),
        _vm._v(" "),
        _c("div", { staticClass: "menuBox" }, [
          _c(
            "div",
            {
              staticClass: "menuItem",
              class: _vm.tabActive == "1" ? "activeMenu" : "",
              on: {
                click: function ($event) {
                  return _vm.changeTab("1")
                },
              },
            },
            [_vm._v("视频片段")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "menuItem",
              class: _vm.tabActive == "2" ? "activeMenu" : "",
              on: {
                click: function ($event) {
                  return _vm.changeTab("2")
                },
              },
            },
            [_vm._v("背景音乐")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "videoHandCont" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tabActive == "1",
                  expression: "tabActive == '1'",
                },
              ],
              staticClass: "fragmentBox",
            },
            [
              _vm.videoInfo.start
                ? _c(
                    "div",
                    {
                      staticClass: "fragmentItem",
                      class: _vm.selectFram == "start" ? "activeFrag" : "",
                      on: {
                        click: function ($event) {
                          return _vm.onClickFram("start")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "fragmentImgBox" }, [
                        _c("img", {
                          attrs: {
                            src:
                              (_vm.videoInfo.start &&
                                _vm.videoInfo.start.firstImage) + "!m640",
                            alt: "",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "fragTit" }, [
                        _vm._v(
                          "入场片段 (" +
                            _vm._s(
                              _vm.videoInfo.start && _vm.videoInfo.start.editNum
                            ) +
                            ")"
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.recycList, function (ele, ind) {
                return _c(
                  "div",
                  { key: ind, staticClass: "recycBox flex" },
                  _vm._l(ele, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "fragmentItem",
                        class:
                          _vm.selectFram ==
                          "segments-" + (index + ind * _vm.segmentLength)
                            ? "activeFrag"
                            : "",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.onClickSeg(index + ind * _vm.segmentLength)
                          },
                        },
                      },
                      [
                        !item.isUpd &&
                        index + ind * _vm.segmentLength == _vm.remainder
                          ? _c(
                              "div",
                              {
                                staticClass: "handImgBox",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onAddSeg($event)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "posterFlexImg",
                                  attrs: {
                                    src: require("@/assets/images/poster/copy.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.isUpd &&
                        index + ind * _vm.segmentLength ==
                          _vm.videoInfo.segments.length - 1
                          ? _c(
                              "div",
                              {
                                staticClass: "handImgBox",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onDelSeg($event)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "posterFlexImg",
                                  attrs: {
                                    src: require("@/assets/images/poster/videoDel.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "fragmentImgBox" }, [
                          _c("img", {
                            attrs: { src: item.firstImage + "!m640", alt: "" },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "fragTit" }, [
                          _vm._v(
                            "循环片段" +
                              _vm._s(index + 1) +
                              " (" +
                              _vm._s(item.editNum) +
                              ")"
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              }),
              _vm._v(" "),
              _vm.videoInfo.end
                ? _c(
                    "div",
                    {
                      staticClass: "fragmentItem",
                      class: _vm.selectFram == "end" ? "activeFrag" : "",
                      on: {
                        click: function ($event) {
                          return _vm.onClickFram("end")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "fragmentImgBox" }, [
                        _c("img", {
                          attrs: {
                            src:
                              (_vm.videoInfo.end &&
                                _vm.videoInfo.end.firstImage) + "!m640",
                            alt: "",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "fragTit" }, [
                        _vm._v(
                          "出场片段 (" +
                            _vm._s(
                              _vm.videoInfo.end && _vm.videoInfo.end.editNum
                            ) +
                            ")"
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tabActive == "2",
                  expression: "tabActive == '2'",
                },
              ],
              staticStyle: { "margin-top": "20px" },
            },
            [
              _c(
                "div",
                { staticClass: "flex" },
                _vm._l(_vm.backgroundMusicList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "musTabItem cur",
                      class: { musTabInd: _vm.musIndex == index },
                      on: {
                        click: function ($event) {
                          _vm.musIndex = index
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.backgroundMusicList.length > 0
                ? _c(
                    "ul",
                    { staticClass: "captionsUl" },
                    _vm._l(
                      _vm.backgroundMusicList[_vm.musIndex].children,
                      function (item, index) {
                        return _c(
                          "li",
                          { key: index, staticClass: "captionsLi" },
                          [
                            _c("div", { staticClass: "flex-one" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex align-items-c" }, [
                              _vm.audioUrl == item.url
                                ? _c("i", {
                                    staticClass: "el-icon-video-pause ziColor",
                                    on: { click: _vm.pauseAudio },
                                  })
                                : _c("i", {
                                    staticClass: "el-icon-video-play ziColor",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onPlayAudio(item.url)
                                      },
                                    },
                                  }),
                            ]),
                            _vm._v(" "),
                            _vm.isUseAudio == item.url
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      background: "#efefef !important",
                                      border: "1px solid #efefef !important",
                                      color: "#b3b3b3",
                                    },
                                    attrs: {
                                      type: "primary",
                                      size: "mini",
                                      disabled: "",
                                    },
                                  },
                                  [_vm._v("已使用")]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "68px",
                                      background: "#704af3 !important",
                                      border: "1px solid #704af3 !important",
                                    },
                                    attrs: { type: "primary", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onUseAudio(
                                          item.url,
                                          item.name
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("使用")]
                                ),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "videoBtnBox flex" },
          [
            _c(
              "el-button",
              {
                staticStyle: {
                  background: "#fff",
                  border: "1px solid #6415D7",
                  color: "#6415D7",
                  width: "80px",
                },
                attrs: { size: "small", type: "primary", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.onSubmit(0)
                  },
                },
              },
              [_vm._v("保存")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticStyle: {
                  background: "#6415D7",
                  border: "1px solid #6415D7",
                },
                attrs: { size: "small", type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.onSubmit(1)
                  },
                },
              },
              [_vm._v("确认生成")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "footTit" }, [
              _c("img", {
                staticClass: "footImg",
                attrs: {
                  src: require("@/assets/images/poster/jifen.png"),
                  alt: "",
                },
              }),
              _vm._v(" 消耗" + _vm._s(_vm.consumePoints) + "积分 "),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "videoRightBox" }, [
        _c(
          "div",
          {
            staticClass: "breadcrumb flex align-items-c justify-b",
            staticStyle: { "padding-right": "16px" },
          },
          [
            _c(
              "div",
              {
                staticClass: "flex-one pageName",
                staticStyle: { "margin-left": "0" },
              },
              [_vm._v("循环片段")]
            ),
            _vm._v(" "),
            _c(
              "el-dropdown",
              [
                _c(
                  "el-button",
                  {
                    staticStyle: {
                      background: "#6415D7",
                      border: "1px solid #6415D7",
                    },
                    attrs: { size: "small", type: "primary" },
                  },
                  [
                    _c("img", {
                      staticClass: "piImg",
                      attrs: {
                        src: require("@/assets/images/poster/pil.png"),
                        alt: "",
                      },
                    }),
                    _vm._v("\n          批量替换\n        "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c("el-dropdown-item", [
                      _c("span", { on: { click: _vm.onSassRep } }, [
                        _vm._v("云总部数据"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("el-dropdown-item", [
                      _c("span", { on: { click: _vm.onBatchRep } }, [
                        _vm._v("素材库数据"),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.replateList
          ? _c(
              "div",
              { staticClass: "rightCont" },
              _vm._l(_vm.replateList.replace, function (ele, ind) {
                return _c(
                  "div",
                  { key: "x" + ele.sort, staticClass: "flex xhBox" },
                  [
                    _vm.handleTIBox(ele.value, 1).length > 0
                      ? _c(
                          "div",
                          {
                            staticClass: "iibox",
                            class:
                              _vm.handleTIBox(ele.value).length > 0 ? "" : "wm",
                          },
                          _vm._l(
                            _vm.handleTIBox(ele.value, 1),
                            function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "updIMgbxo flex" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "imgUpd",
                                      on: {
                                        click: function ($event) {
                                          return _vm.modalPicTap(item, ind)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: item.value + "!m640",
                                          alt: "",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm._m(0, true),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.handleTIBox(ele.value).length > 0
                      ? _c(
                          "div",
                          {
                            staticClass: "ttbox",
                            class:
                              _vm.handleTIBox(ele.value, 1).length > 0
                                ? ""
                                : "mn",
                          },
                          _vm._l(
                            _vm.handleTIBox(ele.value),
                            function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "textBox" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex align-items-c" },
                                    [
                                      _c("div", {
                                        staticClass: "flex-one sortCol",
                                        style: {
                                          fontFamily:
                                            "'" + item.fontClass + "'",
                                        },
                                        attrs: {
                                          contenteditable: "plaintext-only",
                                        },
                                        domProps: {
                                          innerHTML: _vm._s(item.value),
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.writingText(item, $event)
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "left",
                                            width: "250",
                                            trigger: "click",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "padding: 20px;" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "fontConTit" },
                                                [_vm._v("字体设置")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    placeholder: "选择字体",
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    change: function (e) {
                                                      return _vm.changeFont(
                                                        item,
                                                        e
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: item.font_file,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "font_file",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.font_file",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.fontList,
                                                  function (item, index) {
                                                    return _c(
                                                      "el-option",
                                                      {
                                                        key: index,
                                                        staticStyle: {
                                                          height: "30px",
                                                        },
                                                        attrs: {
                                                          value: item.woff,
                                                          label: item.alias,
                                                        },
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticStyle: {
                                                            height: "100%",
                                                          },
                                                          attrs: {
                                                            src: item.preview,
                                                            alt: "",
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex align-items-c",
                                                  staticStyle: {
                                                    "margin-top": "10px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    文字颜色\n                    "
                                                  ),
                                                  _c("el-color-picker", {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: { size: "mini" },
                                                    model: {
                                                      value: item.fill,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "fill",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.fill",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "flex",
                                                  staticStyle: {
                                                    "margin-top": "10px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex-one flex align-items-c",
                                                    },
                                                    [
                                                      _vm._v("文字描边"),
                                                      _c("el-color-picker", {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: { size: "mini" },
                                                        model: {
                                                          value: item.stroke,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "stroke",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.stroke",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex-one flex align-items-c",
                                                    },
                                                    [
                                                      _vm._v("描边宽度"),
                                                      _c("el-input", {
                                                        staticClass: "flex-one",
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: { size: "mini" },
                                                        model: {
                                                          value:
                                                            item.stroke_width,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "stroke_width",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.stroke_width",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex align-items-c",
                                                  staticStyle: {
                                                    "margin-top": "10px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "描边位置\n                    "
                                                  ),
                                                  _c(
                                                    "el-radio-group",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "10px",
                                                      },
                                                      attrs: { size: "mini" },
                                                      model: {
                                                        value:
                                                          item.stroke_over_fill,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "stroke_over_fill",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.stroke_over_fill",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio-button",
                                                        {
                                                          attrs: {
                                                            label: true,
                                                          },
                                                        },
                                                        [_vm._v("内部")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-radio-button",
                                                        {
                                                          attrs: {
                                                            label: false,
                                                          },
                                                        },
                                                        [_vm._v("外部")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                slot: "reference",
                                                effect: "dark",
                                                content: "字体样式",
                                                placement: "top",
                                                enterable: false,
                                              },
                                              slot: "reference",
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "fontImg",
                                                attrs: {
                                                  src: require("@/assets/images/poster/font.png"),
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("audio", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        ref: "audioPlayer",
        attrs: { src: _vm.audioUrl, controls: "" },
        on: { ended: _vm.pauseAudio },
      }),
      _vm._v(" "),
      _c("copper", { ref: "copperRef", on: { susse: _vm.onCopSusse } }),
      _vm._v(" "),
      _c("createVideo", {
        ref: "createVideoRef",
        on: { callbackData: _vm.onVideoCallback },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "iconBox" }, [
      _c("i", {
        staticClass: "el-icon-camera cameraIconfont",
        staticStyle: { color: "#fff" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }