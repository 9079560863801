"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.split");
var _aiMark = require("@/api/aiMark");
var _system = require("@/api/system");
var _setting = require("@/api/setting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      status: 1,
      resultData: [],
      listLoading: true,
      searchQuery: {},
      channelVisible: false,
      channelForm: {},
      channelType: {},
      queryParams: {},
      chkName: '',
      checkedPage: [],
      checkedIds: [],
      // 订单当前页选中的数据

      tableFrom: {
        page: 1,
        limit: 20,
        extract_status: '',
        keywords: '',
        account_type: 2,
        totlal: 0
      },
      searchAdminarr: [],
      adminarr: [],
      showpromotionAccountDraw: false,
      userStatus: '1',
      promotionAccountUserInfo: {},
      promotionAccount: {
        account_name_alias: '',
        manager_admin_id: '',
        account_bind_type: '',
        account_bind_label: '',
        release_method: '0',
        account_bind_info: {
          account: '',
          password: '',
          phone: '',
          email: ''
        },
        status: 1
      },
      maketagList: [],
      maketagJson: {},
      bindcheckTypearr: [],
      tweenTopic: '',
      tweenTopicarr: [],
      rowvideoTopic: '',
      rowvideoTopicarr: [],
      columnvideoTopic: '',
      columnvideoTopicarr: [],
      addtagsFlag: false,
      batchSelectTag: [],
      batchInputTag: '',
      editmanagerFlag: false,
      batchSelectmanager: '',
      butLoad: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.getList();
    (0, _aiMark.getChannelsTypeApi)().then(function (res) {
      var obj = {};
      for (var k in res.data) {
        var arr = [];
        for (var key in res.data[k]) {
          arr.push({
            label: res.data[k][key],
            value: key
          });
        }
        obj[k] = arr;
      }
      // console.log(obj);
      _this.channelType = obj;
      _this.maketagList = obj.make_tag;
      _this.maketagJson = res.data.make_tag;
    });

    // this.addAccount();
    this.getadminList();

    // getPromotionAccountDetailApi(3)

    // 监听来自父页面的消息
    window.addEventListener('message', this.handleMessage);
  },
  beforeDestroy: function beforeDestroy() {
    // 移除监听事件
    window.removeEventListener('message', this.handleMessage);
  },
  methods: {
    batchAddtag: function batchAddtag() {
      this.addtagsFlag = true;
    },
    batchEditmanager: function batchEditmanager() {
      this.editmanagerFlag = true;
    },
    cursoraddTag: function cursoraddTag() {
      var _this2 = this;
      if (!this.batchInputTag) {
        this.$message.warning('请输入标签名称！');
        return;
      }
      (0, _aiMark.batchEditMarketMediaTagApi)({
        ids: this.checkedIds.join(','),
        labels: this.batchInputTag
      }).then(function (res) {
        _this2.$message.success('操作成功');
        _this2.getList();
      });
    },
    cursorEditmanager: function cursorEditmanager() {
      var _this3 = this;
      if (!this.batchSelectmanager) {
        this.$message.error('请选择管理员');
        return;
      }
      (0, _aiMark.batchEditMarketMediaOperateApi)({
        ids: this.checkedIds.join(','),
        manager_admin_id: this.batchSelectmanager
      }).then(function (res) {
        _this3.$message.success('操作成功');
        _this3.getList();
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    handleMessage: function handleMessage(event) {
      // 处理接收到的消息
      // console.log("Received message from parent:", event.data);
      if (event.data.type == 'promotionAccount') {
        var data = event.data.data;
        if (data.type == 'videoplatform') {
          this.handleAccount(data.data);
        }
      }
    },
    refreshLogin: function refreshLogin(status, type) {
      if (status != 1) {
        window.parent.postMessage({
          type: 'refreshLogin',
          status: status,
          videotype: type
        }, '*');
      }
    },
    encodeBase64: function encodeBase64(str) {
      // 首先将中文字符转换为UTF-8编码的字节序列
      var utf8Str = unescape(encodeURIComponent(str));
      // 然后将这些字节序列转换为base64编码
      var base64Encoded = btoa(utf8Str);
      return base64Encoded;
    },
    handleAccount: function handleAccount(data) {
      var _this4 = this;
      var newdata = JSON.parse(JSON.stringify(data));
      newdata.channels_type = data.video_type;
      newdata.origins = this.encodeBase64(JSON.stringify(data.origins));
      (0, _aiMark.checkPromotionAccountApi)(newdata).then(function (res) {
        _this4.$message.success('操作成功');
        _this4.getList();
        window.parent.postMessage({
          type: 'closeAccount'
        }, '*');
      });
    },
    changeTab: function changeTab(e) {
      console.log(e);
      this.userStatus = e;
    },
    onTopinfo: function onTopinfo(row) {
      var _this5 = this;
      (0, _aiMark.promotionAccountTopApi)(row.id, {
        is_top: 1
      }).then(function (res) {
        console.log(res);
        _this5.getList();
      });
    },
    saveEditinfo: function saveEditinfo() {
      var _this6 = this;
      // console.log(this.tweenTopic);
      // console.log(this.rowvideoTopic);
      // console.log(this.columnvideoTopic);
      var jsonpost = JSON.parse(JSON.stringify(this.promotionAccount));
      jsonpost.account_bind_type = this.bindcheckTypearr.join(',');
      jsonpost.account_bind_type_topic = [this.tweenTopic, this.rowvideoTopic, this.columnvideoTopic];
      (0, _aiMark.editPromotionAccountApi)(this.selectid, jsonpost).then(function (res) {
        _this6.$message.success('修改成功');
        _this6.showpromotionAccountDraw = false;
        _this6.getList();
      });
    },
    addAccount: function addAccount() {
      window.parent.postMessage({
        type: 'addAccount'
      }, '*');
    },
    getadminList: function getadminList() {
      var _this7 = this;
      (0, _system.getlistNames)().then(function (res) {
        _this7.searchAdminarr = res.data.list.map(function (item) {
          return {
            label: item.real_name,
            value: item.merchant_admin_id
          };
        });
        _this7.adminarr = res.data.list;
      });
    },
    mackTitChange: function mackTitChange(row) {
      (0, _aiMark.promotionChannelsApi)(row.id, {
        account_name: row.account_name
      }).then(function (res) {});
    },
    onAccount: function onAccount() {
      var _this8 = this;
      (0, _aiMark.createDefaultChannelsApi)().then(function (res) {
        _this8.$message.success('操作成功');
        _this8.getList();
      });
    },
    onUpdHand: function onUpdHand(row) {
      this.showpromotionAccountDraw = true;
      this.promotionAccountUserInfo = {
        account_avatar: row.account_avatar,
        account_name: row.account_name,
        account_unique: row.account_unique
      };
      this.selectid = row.id;
      if (row.account_bind_type) {
        this.bindcheckTypearr = row.account_bind_type.split(',');
      }
      if (row.extend_data) {
        if (row.extend_data.account_bind_info) {
          this.promotionAccount.account_bind_info = row.extend_data.account_bind_info;
        }
        if (row.extend_data.account_bind_type_topic) {
          this.tweenTopic = row.extend_data.account_bind_type_topic[0] || [];
          this.rowvideoTopic = row.extend_data.account_bind_type_topic[1] || [];
          this.columnvideoTopic = row.extend_data.account_bind_type_topic[2] || [];
        }
      }
      this.promotionAccount.release_method = row.release_method + '';
      this.promotionAccount.account_name_alias = row.account_name_alias;
      this.promotionAccount.account_bind_label = row.account_bind_label;
      this.promotionAccount.status = row.account_login_status;
      this.promotionAccount.channels_type = row.channels_type + '';
      if (row.managerAdmin) {
        this.promotionAccount.manager_admin_id = row.managerAdmin.merchant_admin_id;
      }
      // this.channelForm = {
      //   title: "修改资源",
      //   ...row,
      //   channels_type: row.channels_type + "",
      // };
      // this.channelVisible = true;
    },
    onDeleHand: function onDeleHand(row) {
      var _this9 = this;
      this.$confirm("\u786E\u8BA4\u5220\u9664\u6B64\u8D26\u53F7?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _aiMark.deletePromotionAccountApi)(_this9.selectid).then(function (res) {
          _this9.$message.success('操作成功');
          _this9.showpromotionAccountDraw = false;
          _this9.getList();
        });
      }).catch(function () {});
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.queryParams = Object.assign({}, e);
      this.getList(1);
    },
    onDetails: function onDetails(row) {
      this.$router.push({
        name: 'chanDetails',
        query: {
          resData: JSON.stringify(row)
        }
      });
    },
    onAdd: function onAdd() {
      var arr = this.channelForm.account_bind_content;
      var row = arr[arr.length - 1];
      if (row.make_type && row.make_tag) {
        this.channelForm.account_bind_content.push({
          make_type: '',
          make_tag: ''
        });
      }
    },
    onOpenDia: function onOpenDia() {
      this.channelVisible = true;
      this.channelForm = {
        title: '新增资源',
        account_bind_content: [{
          make_type: '',
          make_tag: ''
        }]
      };
    },
    onSubmit: function onSubmit() {
      var _this10 = this;
      var actionUrl = '';
      var model = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.channelForm), {}, {
        account_bind_content_type: this.channelForm.account_bind_content_type.join(',')
      });
      if (this.channelForm.id) {
        actionUrl = (0, _aiMark.promotionChannelsUpdApi)(this.channelForm.id, model);
      } else actionUrl = (0, _aiMark.promotionCreateApi)(model);
      actionUrl.then(function (res) {
        // console.log(res);
        _this10.$message.success('操作成功');
        _this10.channelVisible = false;
        _this10.getList();
      });
    },
    getList: function getList(num) {
      var _this11 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _aiMark.getPromotionAccountListApi)(this.tableFrom).then(function (res) {
        _this11.listLoading = false;
        _this11.resultData = res.data.list;
        _this11.tableFrom.total = res.data.count;
      });
    },
    checkAllLoginStatus: function checkAllLoginStatus() {
      var _this12 = this;
      this.butLoad = true;
      (0, _aiMark.checkPromotionAccountLoginApi)().then(function (res) {
        console.log(res);
        _this12.butLoad = false;
        // this.$message.success('操作成功，请耐心等待检测');
      });
    },
    // 用户
    onHandle: function onHandle(name) {
      this.chkName = this.chkName === name ? '' : name;
      this.changeType(!(this.chkName === ''));
    },
    changeType: function changeType(v) {
      if (v) {
        if (!this.chkName) {
          this.chkName = 'dan';
        }
      } else {
        this.chkName = '';
        this.allCheck = false;
      }
      var index = this.checkedPage.indexOf(this.tableFrom.page);
      if (this.chkName === 'dan') {
        this.checkedPage.push(this.tableFrom.page);
      } else if (index > -1) {
        this.checkedPage.splice(index, 1);
      }
      this.syncCheckedId();
    },
    syncCheckedId: function syncCheckedId() {
      var _this13 = this;
      var ids = this.resultData.map(function (v) {
        return v.id;
      });
      if (this.chkName === 'duo') {
        this.checkedIds = [];
        this.allCheck = true;
      } else if (this.chkName === 'dan') {
        this.allCheck = false;
        ids.forEach(function (id) {
          var index = _this13.checkedIds.indexOf(id);
          if (index === -1) {
            _this13.checkedIds.push(id);
          }
        });
      } else {
        ids.forEach(function (id) {
          var index = _this13.checkedIds.indexOf(id);
          if (index > -1) {
            _this13.checkedIds.splice(index, 1);
          }
        });
      }
    },
    // 分开选择
    changeOne: function changeOne(v, user) {
      if (v) {
        if (this.chkName === 'duo') {
          var index = this.noChecked.indexOf(user.id);
          if (index > -1) this.noChecked.splice(index, 1);
        } else {
          var _index = this.checkedIds.indexOf(user.id);
          if (_index === -1) this.checkedIds.push(user.id);
        }
      } else {
        if (this.chkName === 'duo') {
          var _index2 = this.noChecked.indexOf(user.id);
          if (_index2 === -1) this.noChecked.push(user.id);
        } else {
          var _index3 = this.checkedIds.indexOf(user.id);
          if (_index3 > -1) this.checkedIds.splice(_index3, 1);
        }
      }
    }
  }
};