var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout", staticStyle: { background: "#f7f7f7" } },
    [
      _c(
        "el-container",
        [
          _c(
            "el-header",
            {
              staticStyle: {
                position: "fixed",
                left: "0",
                top: "0",
                right: "0",
              },
              attrs: { height: "64px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "item-align-center",
                  staticStyle: {
                    width: "1345px",
                    margin: "0 auto",
                    height: "64px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "page-back item-align-center",
                      on: { click: _vm.backAction },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211022/b8b0d796b5afef0149e76265443f1cbd.png",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt" }, [_vm._v("返回")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "page-shu" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "page-name item-align-center" }, [
                    _c("div", { staticClass: "l" }, [_vm._v("基础页面")]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211022/6390304e322470c6b1a238158de1ccf4.png",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "r" }, [
                      _vm._v(_vm._s(_vm.pageInfo.name || "")),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-one" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "page-btn item-align-center" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "large" },
                          on: { click: _vm.handlePageSave },
                        },
                        [_vm._v("保存")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "info", size: "large" },
                          on: { click: _vm.handleCancelAction },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.isEdit ? "primary" : "info",
                            size: "large",
                          },
                          on: { click: _vm.sendAction },
                        },
                        [_vm._v("发布")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "info", size: "large" },
                          on: {
                            click: function ($event) {
                              return _vm.previewAction(_vm.$route.query.type)
                            },
                          },
                        },
                        [_vm._v("预览")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-main",
            {
              staticStyle: {
                "padding-top": "64px",
                "box-sizing": "border-box",
                width: "1345px",
                margin: "0 auto",
              },
            },
            [
              _c(
                "div",
                { staticClass: "layout-tool" },
                [
                  _c(
                    "el-scrollbar",
                    { style: _vm.scrollHeight },
                    [
                      _c(
                        "el-collapse",
                        {
                          attrs: {
                            value: [
                              "basics",
                              "advanced",
                              "sales",
                              "other",
                              "marketing",
                            ],
                          },
                        },
                        [
                          _c("div", { staticClass: "align-c fWeight500" }, [
                            _vm._v("组件配置"),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.templateList, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass:
                                  "flex align-items-c left-item justify-b",
                                class: { curr: _vm.editType == item.type },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeType(item.type)
                                  },
                                },
                              },
                              [
                                _c("div", [_vm._v(_vm._s(item.name))]),
                                _vm._v(" "),
                                _vm.editType == item.type
                                  ? _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211215/f87e60793182f42c0c8e0a6a025ded80.png",
                                      },
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: "https://saas.cdn.yunzongbu.cn/image/20211215/4f7d02dd04462554701052b457452fa5.png",
                                      },
                                    }),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "layout-body" },
                [
                  _c(
                    "el-scrollbar",
                    {
                      ref: "scrollbar",
                      style: _vm.scrollHeight,
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handlePackage($event)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "layout-phone",
                          style: {
                            minHeight: "765px",
                            backgroundColor: _vm.pageInfo.background,
                            backgroundImage:
                              "url(" + _vm.pageInfo.bg_image + ")",
                            backgroundRepeat:
                              _vm.pageInfo.bg_image_mode === 3
                                ? "repeat-y"
                                : "no-repeat",
                            backgroundPosition:
                              _vm.pageInfo.bg_image_mode == 5
                                ? "center bottom"
                                : _vm.pageInfo.bg_image_mode == 3 ||
                                  _vm.pageInfo.bg_image_mode == 4
                                ? "center top"
                                : "center",
                            backgroundSize:
                              _vm.pageInfo.bg_image_mode == 2
                                ? "cover"
                                : "375px",
                          },
                        },
                        [
                          _vm.pageInfo.show_header_view
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "layout-phone-header pos-absolute",
                                  style: {
                                    backgroundColor:
                                      _vm.pageInfo.header_background,
                                    backgroundImage:
                                      "url(" +
                                      (_vm.pageInfo.header_color === "white"
                                        ? "https://saas.cdn.yunzongbu.cn/image/20211026/9823014575b33347581d939efbd91aee.png"
                                        : "https://saas.cdn.yunzongbu.cn/image/20211026/a3459168515274a30097453656ad97b1.png") +
                                      ")",
                                  },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "layout-phone-title item-align-center justify-center",
                                        style: {
                                          "z-index": 200,
                                          color: _vm.pageInfo.header_color,
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "back" }, [
                                          _vm.pageInfo.header_color === "white"
                                            ? _c("img", {
                                                attrs: {
                                                  src: "https://saas.cdn.yunzongbu.cn/image/20211029/ccfd6697386671c2bd471fb840c7fa22.png",
                                                  alt: "",
                                                },
                                              })
                                            : _c("img", {
                                                attrs: {
                                                  src: "https://saas.cdn.yunzongbu.cn/image/20211029/6f2d1b371322f39581c1c3d8cc2c730d.png",
                                                  alt: "",
                                                },
                                              }),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "ml5",
                                            style: {
                                              fontSize:
                                                _vm.pageInfo.header_text_size /
                                                  2 +
                                                "px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.pageInfo.title || "")
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.pageInfo.show_header_view
                            ? _c("div", {
                                staticClass: "zhanwei",
                                staticStyle: { height: "88px" },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.pageInfo.show_header_view &&
                          (_vm.editType === "album_detail_page" ||
                            _vm.editType === "auction_detail_page")
                            ? _c(
                                "div",
                                { staticStyle: { position: "relative" } },
                                [
                                  _vm.editType === "album_detail_page"
                                    ? _c("img", {
                                        staticStyle: { display: "block" },
                                        attrs: {
                                          src: "https://saas.cdn.yunzongbu.cn/image/20211022/447940859f11823f4adf5b7fe249b648.png",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass:
                                      "layout-phone-header pos-absolute",
                                    style: {
                                      backgroundColor: "none",
                                      backgroundImage:
                                        "url(" +
                                        (_vm.pageInfo.header_color === "white"
                                          ? "https://saas.cdn.yunzongbu.cn/image/20211026/9823014575b33347581d939efbd91aee.png"
                                          : "https://saas.cdn.yunzongbu.cn/image/20211026/a3459168515274a30097453656ad97b1.png") +
                                        ")",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "layout-phone-body",
                              attrs: { id: "guide002" },
                            },
                            [_c("router-view", { ref: "childView" })],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.editType === "templateAlbumDetail"
                    ? _c("div", { staticClass: "footer" }, [
                        _c("div", { staticClass: "flex align-items-c" }, [
                          _c(
                            "div",
                            {
                              staticClass: "bottom-btn flex-one",
                              style:
                                "margin-left: " +
                                _vm.pageInfo.page_margin / 2 +
                                "px;margin-right: " +
                                _vm.pageInfo.page_margin / 2 +
                                "px;",
                            },
                            [_vm._v("进入拍场")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "bottom-btn flex-one r",
                              style:
                                "margin-right: " +
                                _vm.pageInfo.page_margin / 2 +
                                "px;",
                            },
                            [_vm._v("办牌参拍")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "hengtiao" }),
                      ])
                    : _vm.editType === "templateAuctionDetail"
                    ? _c(
                        "div",
                        {
                          staticClass: "footer",
                          staticStyle: { "box-shadow": "unset" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "bid-top flex align-items-c item-flex-center",
                            },
                            [
                              _c("div", { staticClass: "number" }, [
                                _vm._v(
                                  "\n              号牌 2625 出价额度 50,000\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cz" }, [
                                _vm._v("充值 >"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex align-items-c" }, [
                            _c("div", { staticClass: "flex align-items-c" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "icon-img1 flex align-items-c justify-c ft14 fWeight500",
                                  staticStyle: { "margin-left": "16px" },
                                },
                                [
                                  _vm.pageInfo.concatIcon
                                    ? _c("img", {
                                        attrs: { src: _vm.pageInfo.concatIcon },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "icon-img1 flex align-items-c justify-c ft14 fWeight500",
                                  staticStyle: { "margin-left": "16px" },
                                },
                                [
                                  _vm.pageInfo.shareIcon
                                    ? _c("img", {
                                        attrs: { src: _vm.pageInfo.shareIcon },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex-one bottom-btn r flex align-items-c justify-c ml20",
                              },
                              [_c("div", [_vm._v("立即出价")])]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "hengtiao" }),
                        ]
                      )
                    : _vm.editType === "templateProductDetail"
                    ? _c("div", { staticClass: "footer" }, [
                        _c("div", { staticClass: "flex align-items-c" }, [
                          _c("div", { staticClass: "flex align-items-c" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "icon-img1 flex align-items-c justify-c ft14 fWeight500",
                                staticStyle: { "margin-left": "16px" },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20220513/61871c186a2d4586b8ccd4f5b8046691.png",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "icon-img1 flex align-items-c justify-c ft14 fWeight500",
                                staticStyle: { "margin-left": "16px" },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20220513/ebf49d3cd0fe41d2dd7dc0df2657df17.png",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "icon-img1 flex align-items-c justify-c ft14 fWeight500",
                                staticStyle: { "margin-left": "16px" },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "https://saas.cdn.yunzongbu.cn/image/20220513/8efd05ecb2a89b9cfd01d435d75ccb70.png",
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex-one bottom-btn1 flex align-items-c justify-c ml20",
                            },
                            [
                              _c("div", { staticClass: "l" }, [
                                _vm._v("加入购物车"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "r" }, [
                                _vm._v("立即购买"),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "hengtiao" }),
                      ])
                    : _vm.editType === "nft_detail_page"
                    ? _c(
                        "div",
                        {
                          staticClass: "footer",
                          style:
                            "background: " +
                            _vm.pageInfo.bottom_background +
                            ";box-shadow: none;height: 90px;",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "btn-bottom flex align-items-c justify-b",
                              style:
                                "background: " +
                                _vm.pageInfo.bottom_background +
                                ";",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "l",
                                  style: "color: " + _vm.pageInfo.priceColor,
                                },
                                [
                                  _vm._v("\n              ￥"),
                                  _c("span", [_vm._v("100")]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "r flex align-items-c" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "qd mr10",
                                      style: "color: " + _vm.pageInfo.txtColor,
                                    },
                                    [
                                      _vm._v(
                                        "\n                4.12 09:00 开售\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "btn",
                                      style:
                                        "background: " +
                                        _vm.pageInfo.btnBgColor +
                                        ";border-color: " +
                                        _vm.pageInfo.btnBgColor +
                                        ";color: " +
                                        _vm.pageInfo.btnColor,
                                    },
                                    [
                                      _vm._v(
                                        "\n                购买\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "hengtiao" }),
                        ]
                      )
                    : _vm.editType === "blind_detail_page"
                    ? _c(
                        "div",
                        {
                          staticClass: "footer",
                          style:
                            "background: " +
                            _vm.pageInfo.bottom_background +
                            ";box-shadow: none;height: 90px;",
                        },
                        [
                          _c("div", { staticClass: "blind-bottom" }, [
                            _c("div", { staticClass: "blind-bottom-left" }, [
                              _c("img", {
                                attrs: {
                                  src: _vm.pageInfo.share_image,
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "blind-bottom-text",
                                  style: "color:" + _vm.pageInfo.txtColor,
                                },
                                [_vm._v("分享")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "blind-bottom-center" }, [
                              _c("img", {
                                attrs: {
                                  src: _vm.pageInfo.open_image,
                                  alt: "",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "blind-bottom-right" }, [
                              _c("img", {
                                attrs: {
                                  src: _vm.pageInfo.mode_image,
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "blind-bottom-text",
                                  style: "color:" + _vm.pageInfo.txtColor,
                                },
                                [_vm._v("概率说明")]
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "layout-attribute display-flex",
                  attrs: { id: "guide004" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _vm.index >= 0
                        ? _c(
                            "el-scrollbar",
                            { style: _vm.scrollHeight },
                            [
                              _vm.modules[_vm.attribute]
                                ? _c(_vm.modules[_vm.attribute].set_attribute, {
                                    tag: "component",
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.index == -1
                        ? _c(
                            "el-scrollbar",
                            { style: _vm.scrollHeight },
                            [
                              _vm.editType == "templateAuctionList"
                                ? [
                                    _c("auctionAttribute", {
                                      model: {
                                        value: _vm.pageInfo,
                                        callback: function ($$v) {
                                          _vm.pageInfo = $$v
                                        },
                                        expression: "pageInfo",
                                      },
                                    }),
                                  ]
                                : _vm.editType == "templateAuctionDetail"
                                ? [
                                    _c("auctionPage", {
                                      model: {
                                        value: _vm.pageInfo,
                                        callback: function ($$v) {
                                          _vm.pageInfo = $$v
                                        },
                                        expression: "pageInfo",
                                      },
                                    }),
                                  ]
                                : _vm.editType == "templateAlbumList"
                                ? [
                                    _c("albumAttribute", {
                                      model: {
                                        value: _vm.pageInfo,
                                        callback: function ($$v) {
                                          _vm.pageInfo = $$v
                                        },
                                        expression: "pageInfo",
                                      },
                                    }),
                                  ]
                                : _vm.editType == "templateAlbumDetail"
                                ? [
                                    _c("albumPage", {
                                      model: {
                                        value: _vm.pageInfo,
                                        callback: function ($$v) {
                                          _vm.pageInfo = $$v
                                        },
                                        expression: "pageInfo",
                                      },
                                    }),
                                  ]
                                : _vm.editType == "templateProductList"
                                ? [
                                    _c("productPage", {
                                      model: {
                                        value: _vm.pageInfo,
                                        callback: function ($$v) {
                                          _vm.pageInfo = $$v
                                        },
                                        expression: "pageInfo",
                                      },
                                    }),
                                  ]
                                : _vm.editType == "templateProductDetail"
                                ? [
                                    _c("productAttribute", {
                                      model: {
                                        value: _vm.pageInfo,
                                        callback: function ($$v) {
                                          _vm.pageInfo = $$v
                                        },
                                        expression: "pageInfo",
                                      },
                                    }),
                                  ]
                                : _vm.editType == "templateWordDetail"
                                ? [
                                    _c("wordPage", {
                                      model: {
                                        value: _vm.pageInfo,
                                        callback: function ($$v) {
                                          _vm.pageInfo = $$v
                                        },
                                        expression: "pageInfo",
                                      },
                                    }),
                                  ]
                                : _vm.editType == "templateLogin"
                                ? [
                                    _c("loginPage", {
                                      model: {
                                        value: _vm.pageInfo,
                                        callback: function ($$v) {
                                          _vm.pageInfo = $$v
                                        },
                                        expression: "pageInfo",
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showImg,
            "close-on-click-modal": false,
            center: "",
            title: _vm.showImgType == 1 ? "查看二维码" : "预览二维码",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.showImgUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }