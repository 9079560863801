var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "ft12",
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            fit: "",
            border: "",
            "highlight-current-row": "",
            "span-method": _vm.objectSpanMethod,
          },
        },
        [
          _c("template", { slot: "empty" }, [
            _c("div", { staticClass: "tableEmpty" }, [
              _c("img", {
                attrs: {
                  src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "专场封面", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      staticStyle: { width: "30px" },
                      attrs: { src: scope.row.album_image + "!120a" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.album_status === 1
                      ? _c(
                          "el-tag",
                          { attrs: { size: "small", type: "danger" } },
                          [_vm._v("预展中")]
                        )
                      : scope.row.album_status === 2
                      ? _c("el-tag", { attrs: { size: "small" } }, [
                          _vm._v("进行中"),
                        ])
                      : scope.row.album_status === 3
                      ? _c(
                          "el-tag",
                          { attrs: { size: "small", type: "danger" } },
                          [_vm._v("已结束")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.is_show === 0
                      ? _c(
                          "el-tag",
                          { attrs: { size: "small", type: "info" } },
                          [_vm._v("已隐藏")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "专场名称", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.album_name))]),
                    _vm._v(" "),
                    _c("span", [_vm._v("ID:" + _vm._s(scope.row.album_id))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "专场类型", "min-width": "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c(
                        "div",
                        { staticStyle: { "margin-left": "2px" } },
                        [
                          scope.row.album_type == 1
                            ? [_vm._v("限时拍")]
                            : scope.row.album_type == 2
                            ? [_vm._v("即时拍")]
                            : scope.row.album_type == 3
                            ? [_vm._v("同步拍")]
                            : scope.row.album_type == 4
                            ? [_vm._v("即刻拍")]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "拍卖时间",
              width: "380",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "nowrap" },
                      [
                        !scope.row.isSearch
                          ? [
                              _c("span", { staticStyle: { color: "#999" } }, [
                                _vm._v("预展："),
                              ]),
                              _vm._v(
                                _vm._s(
                                  _vm.formatDate(
                                    scope.row.scheduled_show_time * 1000,
                                    "{y}-{m}-{d} {h}:{i}"
                                  )
                                ) + "\n              "
                              ),
                              _c("span", { staticStyle: { color: "#999" } }, [
                                _vm._v("开拍："),
                              ]),
                              _vm._v(
                                _vm._s(
                                  _vm.formatDate(
                                    scope.row.album_start_time * 1000,
                                    "{y}-{m}-{d} {h}:{i}"
                                  )
                                ) + "\n              "
                              ),
                              scope.row.album_type === 1 &&
                              scope.row.end_type === 2 &&
                              scope.row.album_status !== 3
                                ? [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#999" } },
                                      [_vm._v("结拍：")]
                                    ),
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatDate(
                                          scope.row.first_end_time * 1000,
                                          "{y}-{m}-{d} {h}:{i}"
                                        )
                                      ) + " 开始\n              "
                                    ),
                                  ]
                                : scope.row.album_type === 1
                                ? [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#999" } },
                                      [_vm._v("结拍：")]
                                    ),
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatDate(
                                          scope.row.album_end_time * 1000,
                                          "{y}-{m}-{d} {h}:{i}"
                                        )
                                      )
                                    ),
                                  ]
                                : scope.row.album_end_time
                                ? [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#999" } },
                                      [_vm._v("结拍：")]
                                    ),
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatDate(
                                          scope.row.album_end_time * 1000,
                                          "{y}-{m}-{d} {h}:{i}"
                                        )
                                      )
                                    ),
                                  ]
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "主播设置", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      [
                        scope.row.live &&
                        scope.row.live.extend_data &&
                        scope.row.live.extend_data.live_guests &&
                        scope.row.live.extend_data.live_guests.length
                          ? _c("span", [
                              _vm._v(
                                "已添加" +
                                  _vm._s(
                                    scope.row.live.extend_data.live_guests
                                      .length
                                  ) +
                                  "位"
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.showLiveGuestAction(scope)
                              },
                            },
                          },
                          [_vm._v("设置")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "拍卖厅", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "80px" },
                        on: {
                          change: function ($event) {
                            return _vm.setAlbumGroup(scope.row)
                          },
                        },
                        model: {
                          value: scope.row.group,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "group", $$v)
                          },
                          expression: "scope.row.group",
                        },
                      },
                      [
                        _c("el-option", { attrs: { value: "A" } }, [
                          _vm._v("A"),
                        ]),
                        _vm._v(" "),
                        _c("el-option", { attrs: { value: "B" } }, [
                          _vm._v("B"),
                        ]),
                        _vm._v(" "),
                        _c("el-option", { attrs: { value: "C" } }, [
                          _vm._v("C"),
                        ]),
                        _vm._v(" "),
                        _c("el-option", { attrs: { value: "D" } }, [
                          _vm._v("D"),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "350", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.group != "other"
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.goPushLinkAction(scope.row)
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class": "live-quick",
                                  "class-name": "blue",
                                },
                              }),
                              _vm._v("快推流地址"),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.goPushLinkAction(scope.row, 1)
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class": "live-biaozun",
                                  "class-name": "blue",
                                },
                              }),
                              _vm._v("标准推流地址"),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "/auctionConsole",
                                  expression: "'/auctionConsole'",
                                },
                              ],
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.goConsoleAction(scope.row)
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class": "zhuanchang-chujiakongzhi",
                                  "class-name": "blue",
                                },
                              }),
                              _vm._v("出价控制台"),
                            ],
                            1
                          ),
                        ]
                      : [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.getLivePushUrlAction(scope.row)
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class": "live-quick",
                                  "class-name": "blue",
                                },
                              }),
                              _vm._v("快推流地址"),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.getLivePushUrlAction(
                                    scope.row,
                                    "LVB"
                                  )
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class": "live-biaozun",
                                  "class-name": "blue",
                                },
                              }),
                              _vm._v("标准推流地址"),
                            ],
                            1
                          ),
                        ],
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.showLiveGuest.show,
            "close-on-click-modal": false,
            width: "820px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showLiveGuest, "show", $event)
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c(
              "div",
              [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-weight": "500",
                      "font-size": "16px",
                      color: "#000",
                    },
                  },
                  [_vm._v("直播嘉宾：")]
                ),
                _c("span", { staticStyle: { color: "#999" } }, [
                  _vm._v("上传后用于AI生成直播预告海报，海报中将使用该资料。"),
                ]),
                _vm._v(" "),
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "top-start",
                      title: "查看示例",
                      width: "200",
                      trigger: "hover",
                    },
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/20241213/image/1734096607523625551.png!m640",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { slot: "reference", type: "text" },
                        slot: "reference",
                      },
                      [_vm._v("查看示例")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticStyle: { color: "#999" } },
              [
                _vm._v("为保证AI海报效果，请上传竖版背景干净的半身或大半身照 "),
                _c(
                  "el-button",
                  { attrs: { type: "text" }, on: { click: _vm.addJBAction } },
                  [_vm._v("添加嘉宾")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "guest-list" },
              _vm._l(_vm.showLiveGuest.data, function (m, i) {
                return _c(
                  "div",
                  { key: i, staticClass: "item", class: { ml20: i % 2 == 1 } },
                  [
                    _c(
                      "div",
                      { staticClass: "t" },
                      [
                        _vm._v("嘉宾" + _vm._s(i + 1) + " "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.delJBAction(i)
                              },
                            },
                          },
                          [_vm._v("删除嘉宾")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "b" }, [
                      _c("div", { staticClass: "img" }, [
                        m.image
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "upload-image flex align-items-c justify-c",
                              },
                              [
                                _c("img", {
                                  attrs: { src: m.image + "!120a", alt: "" },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "del",
                                    on: {
                                      click: function ($event) {
                                        return _vm.delImgAction(i)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-close" })]
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "upload-image flex align-items-c justify-c",
                                on: {
                                  click: function ($event) {
                                    return _vm.changeImg(i)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "el-icon-upload" }),
                                _vm._v(" "),
                                _c("div", [_vm._v("上传形象照")]),
                              ]
                            ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "desc" }, [
                        _c(
                          "div",
                          { staticClass: "inp" },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "嘉宾姓名" },
                              model: {
                                value: m.name,
                                callback: function ($$v) {
                                  _vm.$set(m, "name", $$v)
                                },
                                expression: "m.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "inp mt10" },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "textarea",
                                rows: 5,
                                placeholder:
                                  "嘉宾职务（多个用回车换行）例：\n国家注册拍卖师\n艺天下瓷器拍卖师",
                              },
                              model: {
                                value: m.positions,
                                callback: function ($$v) {
                                  _vm.$set(m, "positions", $$v)
                                },
                                expression: "m.positions",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex align-items-c justify-c mt40" },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.showLiveGuest.btnLoading },
                  on: { click: _vm.closeLiveGuest },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.showLiveGuest.btnLoading,
                    type: "primary",
                  },
                  on: { click: _vm.saveLiveGuest },
                },
                [_vm._v("\n          确认\n        ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }