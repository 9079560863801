var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "screen-box", style: { color: _vm.word_color } }, [
      _c("div", { staticClass: "screen-goods-dis" }, [
        _c("img", {
          staticClass: "dis-img-left",
          attrs: {
            src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024725/image/1721893225558949630.jpg",
            alt: "",
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "productInfoBox",
            style: {
              width: (_vm.auction_width / 100) * 360 + "px",
              "background-color": _vm.auction_color,
              padding:
                (_vm.auction_top_padding / 100) * 270 +
                "px " +
                (_vm.auction_lr_padding / 100) * 360 +
                "px 0",
              "background-image": "url(" + _vm.auction_image + ")",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "screen-goods-title flex-end",
                style:
                  "font-size:" +
                  (_vm.album_font_size / 100) * 270 +
                  "px;margin-bottom: " +
                  (_vm.album_bottom_margin / 100) * 270 +
                  "px;",
              },
              [_c("span", [_vm._v("佳赏-中国古代陶瓷暨明清官窑瓷器")])]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "screen-goods-title flex-end",
                style:
                  "font-size:" +
                  (_vm.auction_font_size / 100) * 270 +
                  "px;margin-bottom: " +
                  (_vm.auction_bottom_margin / 100) * 270 +
                  "px;",
              },
              [
                _c("span", [
                  _vm._v("LOT1624 清乾隆 青花内岁寒三友外庭院仕女图盘"),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", {
              style: "height:" + (_vm.price_all_margin / 100) * 270 + "px",
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "screen-goods-list",
                style:
                  "font-size:" +
                  (_vm.price_font_size / 100) * 270 +
                  "px;margin-bottom: " +
                  (_vm.price_bottom_margin / 100) * 270 +
                  "px;",
              },
              [
                _c("div", { staticClass: "screen-goods-price" }, [
                  _vm._v("RMB"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "screen-goods-num" }, [_vm._v("0")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "screen-goods-list",
                style:
                  "font-size:" +
                  (_vm.price_font_size / 100) * 270 +
                  "px;margin-bottom: " +
                  (_vm.price_bottom_margin / 100) * 270 +
                  "px;",
              },
              [
                _c("div", { staticClass: "screen-goods-price" }, [
                  _vm._v("USD"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "screen-goods-num" }, [_vm._v("0")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "screen-goods-list",
                style:
                  "font-size:" +
                  (_vm.price_font_size / 100) * 270 +
                  "px;margin-bottom: " +
                  (_vm.price_bottom_margin / 100) * 270 +
                  "px;",
              },
              [
                _c("div", { staticClass: "screen-goods-price" }, [
                  _vm._v("EUR"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "screen-goods-num" }, [_vm._v("0")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "screen-goods-list",
                style:
                  "font-size:" +
                  (_vm.price_font_size / 100) * 270 +
                  "px;margin-bottom: " +
                  (_vm.price_bottom_margin / 100) * 270 +
                  "px;",
              },
              [
                _c("div", { staticClass: "screen-goods-price" }, [
                  _vm._v("HKD"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "screen-goods-num" }, [_vm._v("0")]),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "screen-center" }, [
        _vm.main_continue
          ? _c("div", {
              staticClass: "screen-center-box",
              style: {
                width: _vm.main_width + "%",
                "background-color": _vm.main_color,
                "background-image": "url(" + _vm.main_image + ")",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.origin != 3
          ? _c("div", { staticClass: "screen-center-slide" }, [
              _c("img", {
                style:
                  "" +
                  (_vm.showmodel == 1
                    ? "object-fit: contain;"
                    : "object-fit: cover"),
                attrs: {
                  src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024725/image/1721893225558949630.jpg",
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "screen-goods-dis" }, [
        _c("img", {
          staticClass: "dis-img-right",
          attrs: {
            src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024725/image/1721893225558949630.jpg",
            alt: "",
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "productRightInfoBox",
            style: {
              width: (_vm.auction_width / 100) * 360 + "px",
              "background-color": _vm.auction_color,
              padding:
                (_vm.auction_top_padding / 100) * 270 +
                "px " +
                (_vm.auction_lr_padding / 100) * 270 +
                "px 0",
              "background-image": "url(" + _vm.auction_image + ")",
            },
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between",
                },
                style:
                  "font-size:" +
                  (_vm.album_font_size / 100) * 270 +
                  "px;margin-bottom: " +
                  (_vm.album_bottom_margin / 100) * 270 +
                  "px;",
              },
              [
                _c("span", [_vm._v("LOT 415")]),
                _c("span", [_vm._v("已拍32 剩余83")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "screen-goods-title flex-end",
                style:
                  "font-size:" +
                  (_vm.auction_font_size / 100) * 270 +
                  "px;margin-bottom: " +
                  (_vm.auction_bottom_margin / 100) * 270 +
                  "px;",
              },
              [_c("span", [_vm._v("清乾隆 青花内岁寒三友外庭院仕女图盘")])]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex-end",
                style:
                  "font-size:" +
                  (_vm.auction_attr_font_size / 100) * 270 +
                  "px;margin-bottom: " +
                  (_vm.auction_attr_margin / 100) * 270 +
                  "px;",
              },
              [_c("span", [_vm._v("清乾隆")])]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "screen-goods-img-box item-flex-center" },
              [
                _c("div", { staticClass: "borderBox" }, [
                  _c("span", {
                    staticClass: "soildtr",
                    style: { background: _vm.word_color },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "soildrt",
                    style: { background: _vm.word_color },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "soildbl",
                    style: { background: _vm.word_color },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "soildlb",
                    style: { background: _vm.word_color },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "dashtr" }, [
                    _c("span", {
                      style: {
                        "background-image":
                          "linear-gradient(to right," +
                          _vm.word_color +
                          " 0%," +
                          _vm.word_color +
                          " 50%, transparent 50%)",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "dashrt" }, [
                    _c("span", {
                      style: {
                        "background-image":
                          "linear-gradient(to bottom," +
                          _vm.word_color +
                          " 0%," +
                          _vm.word_color +
                          " 50%, transparent 50%)",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "dashbr" }, [
                    _c("span", {
                      style: {
                        "background-image":
                          "linear-gradient(to right," +
                          _vm.word_color +
                          " 0%," +
                          _vm.word_color +
                          " 50%, transparent 50%)",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "dashrb" }, [
                    _c("span", {
                      style: {
                        "background-image":
                          "linear-gradient(to bottom," +
                          _vm.word_color +
                          " 0%," +
                          _vm.word_color +
                          " 50%, transparent 50%)",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    style:
                      "max-width:" +
                      (_vm.image_size / 100) * 270 +
                      "px;max-height:" +
                      (_vm.image_size / 100) * 270 +
                      "px; ",
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/image/20210129/5ee9bc05f909f664560fe751dac83b5f.jpg!m640",
                      alt: "",
                    },
                  }),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "screen-bottom" }, [
      _c(
        "div",
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "LED三屏设置", name: "1" } }),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "LED长屏设置", name: "2" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "screen-bottom-title" }, [_vm._v("风格设置")]),
      _vm._v(" "),
      _c("div", { staticClass: "screen-bottom-list" }, [
        _c(
          "div",
          { staticClass: "f-500", staticStyle: { "margin-right": "10px" } },
          [_vm._v("结拍展示落锤价")]
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-switch", {
              attrs: { "inactive-color": "#ccc" },
              model: {
                value: _vm.end_show_price,
                callback: function ($$v) {
                  _vm.end_show_price = $$v
                },
                expression: "end_show_price",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "screen-bottom-list" },
        [
          _c(
            "div",
            { staticClass: "corlor-box" },
            [
              _c("span", { staticClass: "color-name f-500" }, [
                _vm._v("主区域背景色"),
              ]),
              _vm._v(" "),
              _c("colour", {
                attrs: { colour: "#ffffff" },
                model: {
                  value: _vm.main_color,
                  callback: function ($$v) {
                    _vm.main_color = $$v
                  },
                  expression: "main_color",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "upload-img-view item-flex-center",
              on: {
                click: function ($event) {
                  return _vm.modalPicTap(1, "main_image")
                },
              },
            },
            [
              _vm.main_image
                ? _c(
                    "div",
                    {
                      staticClass: "del-upload-img",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.delImg("main_image")
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                          alt: "",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.main_image
                ? _c("img", { attrs: { src: _vm.main_image + "!300" } })
                : _c("img", {
                    staticClass: "upload-icon",
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                      alt: "",
                    },
                  }),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "color-name f-500",
              staticStyle: { "margin-right": "30px" },
            },
            [_vm._v("背景图")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "f-500 mr5 ml10" }, [_vm._v("设置宽度")]),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticStyle: { width: "120px" },
              attrs: { placeholder: "请输入内容", size: "normal" },
              model: {
                value: _vm.main_width,
                callback: function ($$v) {
                  _vm.main_width = $$v
                },
                expression: "main_width",
              },
            },
            [_c("template", { slot: "append" }, [_vm._v("%")])],
            2
          ),
          _vm._v(" "),
          _vm.activeName == 1
            ? [
                _c(
                  "el-checkbox",
                  {
                    staticClass: "f-500 mr5 ml10",
                    model: {
                      value: _vm.main_start_front,
                      callback: function ($$v) {
                        _vm.main_start_front = $$v
                      },
                      expression: "main_start_front",
                    },
                  },
                  [_vm._v("起拍前展示")]
                ),
                _vm._v(" "),
                _c(
                  "el-checkbox",
                  {
                    staticClass: "f-500 mr5 ml10",
                    model: {
                      value: _vm.main_continue,
                      callback: function ($$v) {
                        _vm.main_continue = $$v
                      },
                      expression: "main_continue",
                    },
                  },
                  [_vm._v("起拍后展示")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "screen-bottom-list" },
        [
          _c(
            "div",
            { staticClass: "corlor-box" },
            [
              _c("span", { staticClass: "color-name f-500" }, [
                _vm._v("拍品信息区背景"),
              ]),
              _vm._v(" "),
              _c("colour", {
                attrs: { colour: "#ffffff" },
                model: {
                  value: _vm.auction_color,
                  callback: function ($$v) {
                    _vm.auction_color = $$v
                  },
                  expression: "auction_color",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "upload-img-view item-flex-center",
              on: {
                click: function ($event) {
                  return _vm.modalPicTap(1, "auction_image")
                },
              },
            },
            [
              _vm.auction_image
                ? _c(
                    "div",
                    {
                      staticClass: "del-upload-img",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.delImg("auction_image")
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                          alt: "",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.auction_image
                ? _c("img", { attrs: { src: _vm.auction_image + "!300" } })
                : _c("img", {
                    staticClass: "upload-icon",
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                      alt: "",
                    },
                  }),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "color-name f-500",
              staticStyle: { "margin-right": "30px" },
            },
            [_vm._v("背景图")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "f-500 mr5 ml10" }, [_vm._v("设置宽度")]),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticStyle: { width: "120px" },
              attrs: { placeholder: "请输入内容", size: "normal" },
              model: {
                value: _vm.auction_width,
                callback: function ($$v) {
                  _vm.auction_width = $$v
                },
                expression: "auction_width",
              },
            },
            [_c("template", { slot: "append" }, [_vm._v("%")])],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "f-500 mr5 ml10" }, [_vm._v("左右间距")]),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticStyle: { width: "120px" },
              attrs: { placeholder: "请输入内容", size: "normal" },
              model: {
                value: _vm.auction_lr_padding,
                callback: function ($$v) {
                  _vm.auction_lr_padding = $$v
                },
                expression: "auction_lr_padding",
              },
            },
            [_c("template", { slot: "append" }, [_vm._v("vw")])],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "f-500 mr5 ml10" }, [_vm._v("上间距")]),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticStyle: { width: "120px" },
              attrs: { placeholder: "请输入内容", size: "normal" },
              model: {
                value: _vm.auction_top_padding,
                callback: function ($$v) {
                  _vm.auction_top_padding = $$v
                },
                expression: "auction_top_padding",
              },
            },
            [_c("template", { slot: "append" }, [_vm._v("vh")])],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "screen-bottom-list" },
        [
          _c("div", { staticClass: "f-500 mr5" }, [_vm._v("专场文字")]),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticStyle: { width: "120px" },
              attrs: { placeholder: "请输入内容", size: "normal" },
              model: {
                value: _vm.album_font_size,
                callback: function ($$v) {
                  _vm.album_font_size = $$v
                },
                expression: "album_font_size",
              },
            },
            [_c("template", { slot: "append" }, [_vm._v("vh")])],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "f-500 mr5 ml10" }, [
            _vm._v("专场文字底部距离"),
          ]),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticStyle: { width: "120px" },
              attrs: { placeholder: "请输入内容", size: "normal" },
              model: {
                value: _vm.album_bottom_margin,
                callback: function ($$v) {
                  _vm.album_bottom_margin = $$v
                },
                expression: "album_bottom_margin",
              },
            },
            [_c("template", { slot: "append" }, [_vm._v("vh")])],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "f-500 mr5 ml10" }, [_vm._v("拍品文字")]),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticStyle: { width: "120px" },
              attrs: { placeholder: "请输入内容", size: "normal" },
              model: {
                value: _vm.auction_font_size,
                callback: function ($$v) {
                  _vm.auction_font_size = $$v
                },
                expression: "auction_font_size",
              },
            },
            [_c("template", { slot: "append" }, [_vm._v("vh")])],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "f-500 mr5 ml10" }, [
            _vm._v("拍品文字底部距离"),
          ]),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticStyle: { width: "120px" },
              attrs: { placeholder: "请输入内容", size: "normal" },
              model: {
                value: _vm.auction_bottom_margin,
                callback: function ($$v) {
                  _vm.auction_bottom_margin = $$v
                },
                expression: "auction_bottom_margin",
              },
            },
            [_c("template", { slot: "append" }, [_vm._v("vh")])],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "f-500 mr5 ml10" }, [
            _vm._v("价格整体顶部"),
          ]),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticStyle: { width: "120px" },
              attrs: { placeholder: "请输入内容", size: "normal" },
              model: {
                value: _vm.price_all_margin,
                callback: function ($$v) {
                  _vm.price_all_margin = $$v
                },
                expression: "price_all_margin",
              },
            },
            [_c("template", { slot: "append" }, [_vm._v("vh")])],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "screen-bottom-list" },
        [
          _c("div", { staticClass: "f-500 mr5" }, [_vm._v("价格文字")]),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticStyle: { width: "120px" },
              attrs: { placeholder: "请输入内容", size: "normal" },
              model: {
                value: _vm.price_font_size,
                callback: function ($$v) {
                  _vm.price_font_size = $$v
                },
                expression: "price_font_size",
              },
            },
            [_c("template", { slot: "append" }, [_vm._v("vh")])],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "f-500 mr5 ml10" }, [
            _vm._v("价格文字底部距离"),
          ]),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticStyle: { width: "120px" },
              attrs: { placeholder: "请输入内容", size: "normal" },
              model: {
                value: _vm.price_bottom_margin,
                callback: function ($$v) {
                  _vm.price_bottom_margin = $$v
                },
                expression: "price_bottom_margin",
              },
            },
            [_c("template", { slot: "append" }, [_vm._v("vh")])],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "f-500 mr5 ml10" }, [_vm._v("属性文字")]),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticStyle: { width: "120px" },
              attrs: { placeholder: "请输入内容", size: "normal" },
              model: {
                value: _vm.auction_attr_font_size,
                callback: function ($$v) {
                  _vm.auction_attr_font_size = $$v
                },
                expression: "auction_attr_font_size",
              },
            },
            [_c("template", { slot: "append" }, [_vm._v("vh")])],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "f-500 mr5 ml10" }, [_vm._v("图片上距离")]),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticStyle: { width: "120px" },
              attrs: { placeholder: "请输入内容", size: "normal" },
              model: {
                value: _vm.auction_attr_margin,
                callback: function ($$v) {
                  _vm.auction_attr_margin = $$v
                },
                expression: "auction_attr_margin",
              },
            },
            [_c("template", { slot: "append" }, [_vm._v("vh")])],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "f-500 mr5 ml10" }, [_vm._v("图片大小")]),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticStyle: { width: "120px" },
              attrs: { placeholder: "请输入内容", size: "normal" },
              model: {
                value: _vm.image_size,
                callback: function ($$v) {
                  _vm.image_size = $$v
                },
                expression: "image_size",
              },
            },
            [_c("template", { slot: "append" }, [_vm._v("vh")])],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "screen-bottom-list" }, [
        _c(
          "div",
          { staticClass: "corlor-box" },
          [
            _c("span", { staticClass: "color-name f-500" }, [
              _vm._v("文字颜色"),
            ]),
            _vm._v(" "),
            _c("colour", {
              attrs: { colour: "#ffffff" },
              model: {
                value: _vm.word_color,
                callback: function ($$v) {
                  _vm.word_color = $$v
                },
                expression: "word_color",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "corlor-box ml10" },
          [
            _c("span", { staticClass: "color-name f-500" }, [
              _vm._v("图片边框"),
            ]),
            _vm._v(" "),
            _c("colour", {
              attrs: { colour: "#000000" },
              model: {
                value: _vm.image_border_color,
                callback: function ($$v) {
                  _vm.image_border_color = $$v
                },
                expression: "image_border_color",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.activeName == 1
        ? _c("div", { staticClass: "screen-bottom-title" }, [
            _vm._v("主屏 动态图设置"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.activeName == 1
        ? _c(
            "div",
            { staticClass: "screen-bottom-list" },
            [
              _c("div", { staticClass: "f-500 mr5" }, [_vm._v("主图来源")]),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  staticClass: "le-radio-group",
                  model: {
                    value: _vm.origin,
                    callback: function ($$v) {
                      _vm.origin = $$v
                    },
                    expression: "origin",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "3" } }, [_vm._v("不显示")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "1" } }, [
                    _vm._v("拍品主图"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "2" } }, [
                    _vm._v("全部拍品图"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm.origin == 2
                ? [
                    _c("div", { staticClass: "f-500 mr5 ml10" }, [
                      _vm._v("轮播张数"),
                    ]),
                    _vm._v(" "),
                    _c("el-input-number", {
                      staticStyle: { width: "80px" },
                      attrs: { min: 1, max: 20, step: 1, controls: false },
                      model: {
                        value: _vm.slideNum,
                        callback: function ($$v) {
                          _vm.slideNum = $$v
                        },
                        expression: "slideNum",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "f-500 mr5 ml10" }, [
                      _vm._v("轮播间隔"),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "setting-input",
                      attrs: { size: "normal" },
                      model: {
                        value: _vm.gap,
                        callback: function ($$v) {
                          _vm.gap = $$v
                        },
                        expression: "gap",
                      },
                    }),
                    _vm._v("\n        秒\n        "),
                    _c("div", { staticClass: "f-500 mr5 ml10" }, [
                      _vm._v("转场效果"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticClass: "setting-input",
                        model: {
                          value: _vm.transition,
                          callback: function ($$v) {
                            _vm.transition = $$v
                          },
                          expression: "transition",
                        },
                      },
                      [
                        _c("el-option", { attrs: { label: "无", value: "0" } }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "渐隐", value: "1" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "左右切换", value: "2" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "上下切换", value: "3" },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              [
                _c(
                  "el-checkbox",
                  {
                    staticClass: "ml10",
                    attrs: { label: "3" },
                    model: {
                      value: _vm.showvideo,
                      callback: function ($$v) {
                        _vm.showvideo = $$v
                      },
                      expression: "showvideo",
                    },
                  },
                  [_vm._v("展示拍品视频")]
                ),
                _vm._v(" "),
                _vm.showvideo
                  ? _c(
                      "el-radio-group",
                      {
                        staticClass: "le-radio-group ml10",
                        model: {
                          value: _vm.videoModelType,
                          callback: function ($$v) {
                            _vm.videoModelType = $$v
                          },
                          expression: "videoModelType",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "1" } }, [
                          _vm._v("全图"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "2" } }, [
                          _vm._v("裁切"),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.activeName == 1
        ? _c(
            "div",
            { staticClass: "screen-bottom-list" },
            [
              _c("div", { staticClass: "f-500 mr5" }, [_vm._v("显示方式")]),
              _vm._v(" "),
              _c(
                "el-radio-group",
                {
                  staticClass: "le-radio-group",
                  model: {
                    value: _vm.showmodel,
                    callback: function ($$v) {
                      _vm.showmodel = $$v
                    },
                    expression: "showmodel",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "1" } }, [_vm._v("全图")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "2" } }, [_vm._v("裁切")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "3" } }, [_vm._v("动态")]),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showmodel == 3
                ? [
                    _c("div", { staticClass: "f-500 mr5 ml10" }, [
                      _vm._v("运动速度"),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "setting-input",
                      model: {
                        value: _vm.second,
                        callback: function ($$v) {
                          _vm.second = $$v
                        },
                        expression: "second",
                      },
                    }),
                    _vm._v("\n        秒/100px\n      "),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "screen-bottom-title" }, [
        _vm._v("副屏 动态图设置"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "screen-bottom-list" },
        [
          _c("div", { staticClass: "f-500 mr5" }, [_vm._v("主图来源")]),
          _vm._v(" "),
          _c(
            "el-radio-group",
            {
              staticClass: "le-radio-group",
              model: {
                value: _vm.ViceOrigin,
                callback: function ($$v) {
                  _vm.ViceOrigin = $$v
                },
                expression: "ViceOrigin",
              },
            },
            [
              _c("el-radio", { attrs: { label: "3" } }, [_vm._v("不显示")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "1" } }, [_vm._v("拍品主图")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "2" } }, [_vm._v("全部拍品图")]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.ViceOrigin == 2
            ? [
                _c("div", { staticClass: "f-500 mr5 ml10" }, [
                  _vm._v("轮播张数"),
                ]),
                _vm._v(" "),
                _c("el-input-number", {
                  staticStyle: { width: "80px" },
                  attrs: { min: 1, max: 20, step: 1, controls: false },
                  model: {
                    value: _vm.viceslideNum,
                    callback: function ($$v) {
                      _vm.viceslideNum = $$v
                    },
                    expression: "viceslideNum",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "f-500 mr5 ml10" }, [
                  _vm._v("轮播间隔"),
                ]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "setting-input",
                  attrs: { size: "normal" },
                  model: {
                    value: _vm.ViceGap,
                    callback: function ($$v) {
                      _vm.ViceGap = $$v
                    },
                    expression: "ViceGap",
                  },
                }),
                _vm._v("\n        秒\n        "),
                _c("div", { staticClass: "f-500 mr5 ml10" }, [
                  _vm._v("转场效果"),
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "setting-input",
                    model: {
                      value: _vm.ViceTransition,
                      callback: function ($$v) {
                        _vm.ViceTransition = $$v
                      },
                      expression: "ViceTransition",
                    },
                  },
                  [
                    _c("el-option", { attrs: { label: "无", value: "0" } }),
                    _vm._v(" "),
                    _c("el-option", { attrs: { label: "渐隐", value: "1" } }),
                    _vm._v(" "),
                    _c("el-option", {
                      attrs: { label: "左右切换", value: "2" },
                    }),
                    _vm._v(" "),
                    _c("el-option", {
                      attrs: { label: "上下切换", value: "3" },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          [
            _c(
              "el-checkbox",
              {
                staticClass: "ml10",
                attrs: { label: "3" },
                model: {
                  value: _vm.ViceShowvideo,
                  callback: function ($$v) {
                    _vm.ViceShowvideo = $$v
                  },
                  expression: "ViceShowvideo",
                },
              },
              [_vm._v("展示拍品视频")]
            ),
            _vm._v(" "),
            _vm.ViceShowvideo
              ? _c(
                  "el-radio-group",
                  {
                    staticClass: "le-radio-group ml10",
                    model: {
                      value: _vm.vicevideoModelType,
                      callback: function ($$v) {
                        _vm.vicevideoModelType = $$v
                      },
                      expression: "vicevideoModelType",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "1" } }, [_vm._v("全图")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: "2" } }, [_vm._v("裁切")]),
                  ],
                  1
                )
              : _vm._e(),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "screen-bottom-list" },
        [
          _c("div", { staticClass: "f-500 mr5" }, [_vm._v("显示方式")]),
          _vm._v(" "),
          _c(
            "el-radio-group",
            {
              staticClass: "le-radio-group",
              model: {
                value: _vm.ViceShowmodel,
                callback: function ($$v) {
                  _vm.ViceShowmodel = $$v
                },
                expression: "ViceShowmodel",
              },
            },
            [
              _c("el-radio", { attrs: { label: "1" } }, [_vm._v("全图")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "2" } }, [_vm._v("裁切")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "3" } }, [_vm._v("动态")]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.ViceShowmodel == 3
            ? [
                _c("div", { staticClass: "f-500 mr5 ml10" }, [
                  _vm._v("运动速度"),
                ]),
                _vm._v(" "),
                _c("el-input", {
                  staticClass: "setting-input",
                  model: {
                    value: _vm.ViceSecond,
                    callback: function ($$v) {
                      _vm.ViceSecond = $$v
                    },
                    expression: "ViceSecond",
                  },
                }),
                _vm._v("\n        秒/100px\n      "),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "screen-button-box" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "default" },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "default" },
              on: { click: _vm.refresh },
            },
            [_vm._v("重置")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }