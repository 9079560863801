"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.number.constructor");
var _contract = require("@/api/contract");
var _auction = require("@/api/auction");
var _artai = require("@/api/artai");
var _PageSizeData = _interopRequireDefault(require("./PageSizeData"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var jifen = {
  1: 10,
  2: 20,
  3: 50
};
var _default = exports.default = {
  data: function data() {
    return {
      visible: false,
      posterList: [{
        id: 'venue',
        name: '拍卖会海报'
      }, {
        id: 'album',
        name: '专场海报'
      }, {
        id: 'live',
        name: '直播海报'
      }, {
        id: 'auction',
        name: '拍品海报'
      }],
      qualityList: [{
        id: '1',
        name: '标准'
      }, {
        id: '2',
        name: '高级'
      }, {
        id: '3',
        name: '定制'
      }],
      formValidate: {
        order_type: 1,
        poster_type: 'venue',
        poster_quality: '1',
        template_ids: '',
        is_pay: 0,
        demand_data: [{
          source: '1',
          venueList: [],
          albumList: [],
          auctList: [],
          liveList: []
        }]
      },
      venueList: [],
      albumList: [],
      auctList: [],
      liveList: [],
      templateList: [],
      sizeList: _PageSizeData.default,
      posterSize: '',
      butLoad: false
    };
  },
  computed: {
    pointNum: function pointNum() {
      var num = 0;
      if (this.formValidate.poster_quality && this.formValidate.generate_count) {
        num = Number(this.formValidate.generate_count) * this.formValidate.demand_data.length * jifen[Number(this.formValidate.poster_quality)];
      }
      return num;
    }
  },
  created: function created() {
    var _this = this;
    (0, _contract.getLastAuctionApi)({
      page: 1,
      limit: 20
    }).then(function (res) {
      _this.venueList = res.data.list;
    });
    (0, _artai.getLiveListApi)({
      page: 1,
      limit: 20
    }).then(function (res) {
      _this.liveList = res.data.list;
    });
  },
  methods: {
    onAdd: function onAdd() {
      this.formValidate.demand_data.push({
        source: '1',
        venue_id: '',
        album_id: '',
        auctList: [],
        liveList: this.liveList,
        albumList: [],
        venueList: this.venueList
      });
    },
    onRedu: function onRedu(index) {
      var arr = JSON.parse(JSON.stringify(this.formValidate.demand_data));
      arr.splice(index, 1);
      this.$set(this.formValidate, 'demand_data', arr);
    },
    onNext: function onNext() {
      this.butLoad = true;
      this.formValidate.is_pay = 1;
      this.onSubmit();
    },
    onSubmit: function onSubmit() {
      var _this2 = this;
      // this.$emit('onRefresh')
      // this.visible = false;
      var demand_data = [];
      if (this.formValidate.is_pay == 1) {
        demand_data = this.formValidate.demand_data.map(function (item) {
          return {
            live_id: item.live_id,
            venue_id: item.venue_id,
            source: item.source,
            album_id: item.album_id,
            product_id: item.product_id
          };
        });
      } else demand_data = this.formValidate.demand_data;
      var model = {
        order_type: this.formValidate.order_type,
        is_pay: this.formValidate.is_pay,
        generate_demand: {
          poster_type: this.formValidate.poster_type,
          demand_data: demand_data,
          poster_size: this.formValidate.poster_size,
          generate_count: this.formValidate.generate_count,
          poster_quality: this.formValidate.poster_quality,
          template_ids: this.formValidate.template_ids
        }
      };
      (0, _artai.marketMediaCreateApi)(model).then(function (res) {
        // console.log(res);
        _this2.butLoad = false;
        _this2.visible = false;
        // if (this.formValidate.order_type == 1) {
        _this2.$emit('onRefresh');
        // }
      }).catch(function (err) {
        return _this2.butLoad = false;
      });
    },
    onSizeChange: function onSizeChange(val) {
      var row = _PageSizeData.default.find(function (item) {
        return item.actual == val;
      });
      this.formValidate.poster_size = "".concat(row.width, "*").concat(row.height);
    },
    liveInit: function liveInit(val) {
      var _this3 = this;
      (0, _artai.getLiveListApi)({
        page: 1,
        limit: 20,
        keyword: val
      }).then(function (res) {
        _this3.liveList = res.data.list;
      });
    },
    venueInit: function venueInit(val, index) {
      var _this4 = this;
      (0, _contract.getLastAuctionApi)({
        title: val,
        page: 1,
        limit: 20
      }).then(function (res) {
        var row = _this4.formValidate.demand_data[index];
        row.venueList = res.data.list;
        _this4.$set(_this4.formValidate.demand_data, index, row);
      });
    },
    getTemplateInit: function getTemplateInit(data_type) {
      var _this5 = this;
      (0, _artai.getTemplateListApi)({
        data_type: data_type
      }).then(function (res) {
        _this5.templateList = res.data.rows;
      });
    },
    onVenueChange: function onVenueChange(val, index) {
      if (this.formValidate.poster_type == 'album' || this.formValidate.poster_type == 'auction') {
        this.albumInit(val, '', index);
      }
    },
    albumInit: function albumInit(venue_id, val, index) {
      var _this6 = this;
      (0, _auction.albumSearchList)({
        page: 1,
        limit: 20,
        keyword: val,
        venue_id: venue_id
      }).then(function (res) {
        // console.log('1------',res);
        var row = _this6.formValidate.demand_data[index];
        row.albumList = res.data.list;
        _this6.$set(_this6.formValidate.demand_data, index, row);
      });
    },
    onAlbumChange: function onAlbumChange(venue_id, album_id, index) {
      this.aucInit(venue_id, album_id, '', index);
    },
    aucInit: function aucInit(venue_id, album_id, val, index) {
      var _this7 = this;
      (0, _auction.aiAuctionSList)({
        page: 1,
        limit: 20,
        venue_id: venue_id,
        album_id: album_id,
        keyword: val
      }).then(function (res) {
        var row = _this7.formValidate.demand_data[index];
        row.auctList = res.data.list;
        _this7.$set(_this7.formValidate.demand_data, index, row);
      });
    },
    onChangeType: function onChangeType(val) {
      this.formValidate.poster_type = val;
    },
    onChangeQuality: function onChangeQuality(val) {
      this.formValidate.poster_quality = val;
      if (val == '3') this.getTemplateInit(this.formValidate.poster_type);
    },
    openSamePoster: function openSamePoster(row) {
      console.log(row);
      // return
      var obj = {
        order_type: 1,
        poster_type: row.data_type,
        poster_quality: '1',
        template_ids: row.template_id,
        is_pay: 1,
        generate_count: 1,
        poster_size: "".concat(row.width, "*").concat(row.height),
        isOut: true,
        demand_data: [{
          source: '1',
          venueList: this.venueList,
          albumList: [],
          auctList: [],
          liveList: this.liveList
        }]
      };
      this.$set(this, 'formValidate', obj);
      this.posterSize = '';
      this.visible = true;
    },
    openPoster: function openPoster() {
      var _this8 = this;
      (0, _artai.getGenerateDemandApi)({
        order_type: 1
      }).then(function (res) {
        console.log(res);
        // if (res.data.mediaOrderInfo) {
        // const infoData = res.data.mediaOrderInfo.generate_demand;

        // infoData.poster_type && this.$set(this.formValidate, 'poster_type', infoData.poster_type);
        // infoData.demand_data && this.$set(this.formValidate, 'demand_data', infoData.demand_data);
        // infoData.generate_count && this.$set(this.formValidate, 'generate_count', infoData.generate_count);
        // infoData.poster_quality && this.$set(this.formValidate, 'poster_quality', infoData.poster_quality);

        // if (infoData.poster_size) {
        //   this.$set(this.formValidate, 'poster_size', infoData.poster_size);
        //   const [width,height] = infoData.poster_size.split('*')
        //   const row = sizeList.find(item => item.width==width&&item.height==height)
        //   this.posterSize = row.actual
        // }
        // if (infoData.template_ids) {
        //   this.getTemplateInit(infoData.poster_type)
        //   this.$set(this.formValidate, 'template_ids', infoData.template_ids);
        // }
        // }else{
        var obj = {
          order_type: 1,
          poster_type: 'venue',
          poster_quality: '1',
          template_ids: '',
          is_pay: 0,
          generate_count: 1,
          demand_data: [{
            source: '1',
            venueList: _this8.venueList,
            albumList: [],
            auctList: [],
            liveList: _this8.liveList
          }]
        };
        // console.log(obj);

        _this8.$set(_this8, 'formValidate', obj);
        _this8.posterSize = '';
        // }
        _this8.visible = true;
      });
    },
    onClose: function onClose() {
      this.visible = false;
      // this.formValidate.is_pay = 0;
      // this.onSubmit();
    }
  }
};