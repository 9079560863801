"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _attribute = _interopRequireDefault(require("./attribute"));
var _attr_content = _interopRequireDefault(require("./attr_content"));
var _attr_list = _interopRequireDefault(require("./attr_list"));
var _attr_style = _interopRequireDefault(require("./attr_style"));
var _attr_template = _interopRequireDefault(require("./attr_template"));
var _element = _interopRequireDefault(require("./element"));
var _widget = _interopRequireDefault(require("./widget"));
/*
 * @Author: qinuoyun
 * @Date:   2020-08-31 15:42:11
 * @Last Modified by:   qinuoyun
 * @Last Modified time: 2021-02-11 22:12:16
 */
var _default = exports.default = {
  attribute: _attribute.default,
  attr_list: _attr_list.default,
  attr_content: _attr_content.default,
  attr_style: _attr_style.default,
  attr_template: _attr_template.default,
  element: _element.default,
  widget: _widget.default
};