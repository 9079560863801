var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.facade,
        "label-width": "80px",
        size: "mini",
        "label-position": "left",
      },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "最大高度" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 500 },
            model: {
              value: _vm.facade.maxHeight,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "maxHeight", $$v)
              },
              expression: "facade.maxHeight",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "背景颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#FFFFFF" },
            model: {
              value: _vm.facade.background_color,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "background_color", $$v)
              },
              expression: "facade.background_color",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "左右边距" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 375 },
            model: {
              value: _vm.facade.page_padding,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "page_padding", $$v)
              },
              expression: "facade.page_padding",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "圆角" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.radius,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "radius", $$v)
              },
              expression: "facade.radius",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "对齐方式" } },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "le-radio-group",
              model: {
                value: _vm.facade.align,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "align", $$v)
                },
                expression: "facade.align",
              },
            },
            [
              _c("el-radio", { attrs: { label: "center" } }, [_vm._v("中间")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "left" } }, [_vm._v("左")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "right" } }, [_vm._v("右")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }