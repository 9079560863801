var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.routeName == "promotionAccount" ||
      _vm.routeName == "taskList" ||
      _vm.routeName == "promotionPlan"
        ? _c(
            "el-card",
            [
              _c("div", { staticClass: "posterHead" }, [
                _c("div", { staticClass: "box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "item",
                      class: {
                        active:
                          _vm.routerUrl ==
                          "/merchant/pageManagement/auction/marketMatrix/promotionAccount",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab(
                            "/merchant/pageManagement/auction/marketMatrix/promotionAccount"
                          )
                        },
                      },
                    },
                    [_vm._v("我的账号")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "item",
                      class: {
                        active:
                          _vm.routerUrl ==
                          "/merchant/pageManagement/auction/marketMatrix/taskList",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab(
                            "/merchant/pageManagement/auction/marketMatrix/taskList"
                          )
                        },
                      },
                    },
                    [_vm._v("发布任务")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "item",
                      class: {
                        active:
                          _vm.routerUrl ==
                          "/merchant/pageManagement/auction/marketMatrix/promotionPlan",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab(
                            "/merchant/pageManagement/auction/marketMatrix/promotionPlan"
                          )
                        },
                      },
                    },
                    [_vm._v("日历看板")]
                  ),
                  _vm._v(" "),
                  _vm.routerUrl ==
                  "/merchant/pageManagement/auction/marketMatrix/promotionAccount"
                    ? _c(
                        "div",
                        {
                          staticClass: "quickly-create l",
                          on: { click: _vm.addAccount },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-circle-plus-outline",
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("添加账号")]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.routerUrl ==
                  "/merchant/pageManagement/auction/marketMatrix/promotionAccount"
                    ? _c(
                        "div",
                        {
                          staticClass: "quickly-create",
                          on: { click: _vm.checkAllLoginStatus },
                        },
                        [
                          _vm.butLoad
                            ? _c("i", { staticClass: "el-icon-loading" })
                            : _c("i", { staticClass: "el-icon-refresh" }),
                          _vm._v(" "),
                          _c("span", [_vm._v("一键检测登录状态")]),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "zw" }),
              ]),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "fade-transform", mode: "out-in" } },
                [_c("router-view", { key: _vm.key })],
                1
              ),
            ],
            1
          )
        : _vm.routerUrl &&
          _vm.routerUrl !=
            "/merchant/pageManagement/auction/marketMatrix/promotionAccount" &&
          _vm.routerUrl !=
            "/merchant/pageManagement/auction/marketMatrix/taskList" &&
          _vm.routerUrl !=
            "/merchant/pageManagement/auction/marketMatrix/promotionPlan"
        ? _c(
            "el-card",
            [
              _c(
                "div",
                {
                  staticClass: "top-view flex",
                  staticStyle: { "margin-top": "-20px" },
                },
                [
                  _c(
                    "el-menu",
                    {
                      staticClass: "el-menu-demo default-tabs mt10",
                      attrs: {
                        "default-active": _vm.routerUrl,
                        mode: "horizontal",
                      },
                      on: { select: _vm.changeTab },
                    },
                    [
                      _c(
                        "el-menu-item",
                        {
                          attrs: {
                            index:
                              "/merchant/pageManagement/auction/marketMatrix/materialProduction",
                          },
                        },
                        [_vm._v("生成素材")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticClass: "w300 mt10",
                      staticStyle: { "margin-left": "20px" },
                      attrs: {
                        placeholder: "请选择拍卖会",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.onSearchVenue,
                      },
                      on: { change: _vm.onVenueChange },
                      model: {
                        value: _vm.venue_id,
                        callback: function ($$v) {
                          _vm.venue_id = $$v
                        },
                        expression: "venue_id",
                      },
                    },
                    _vm._l(_vm.venueList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.title, value: item.venue_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "fade-transform", mode: "out-in" } },
                [_c("router-view", { key: _vm.key })],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }