"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auction = require("@/api/auction");
var _live = require("@/api/live");
var _setting = _interopRequireDefault(require("@/store/modules/setting"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      input: "",
      password: "",
      lianjieStatus: false,
      connected: false,
      imageurl: "",
      albumimage: "",
      livedata: {},
      heartbeat: {}
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _auction.albumDetail)(this.$route.query.id).then(function (res) {
      console.log(res);
      _this.albumimage = res.data.album_image;
      _this.getlivedata(res.data.live_id);
    });
    // 监听来自父页面的消息
    window.addEventListener("message", this.handleMessage);
  },
  beforeDestroy: function beforeDestroy() {
    // 移除监听事件
    window.removeEventListener("message", this.handleMessage);
  },
  methods: {
    getlivedata: function getlivedata(liveid) {
      var _this2 = this;
      (0, _live.getLivePushUrl)(liveid).then(function (res) {
        console.log(res);
        _this2.livedata = res.data;
      });
    },
    handleMessage: function handleMessage(event) {
      // 处理接收到的消息
      // console.log("Received message from parent:", event.data);
      if (event.data.type == "obsmessage") {
        var type = event.data.data.type;
        var data = event.data.data;
        if (type == "connect") {
          this.connected = data.connected;
        } else if (type == "createScene") {
          this.$message.success("创建场景成功");
          this.loading.close();
        } else if (type == "showposter") {
          this.$message.success("操作成功");
        } else if (type == "openautochange") {
          this.$message.success("开启自动切换成功");
        } else if (type == "screenshot") {
          this.imageurl = data.imageData;
        } else if (type == "heartbeat") {
          this.heartbeat = data.heartbeat;
        }
      }
    },
    contentlink: function contentlink() {
      window.parent.postMessage({
        type: "contentlink",
        data: {
          link: this.input,
          password: this.password
        }
      }, "*");
    },
    showposter: function showposter() {
      window.parent.postMessage({
        type: "showposter"
      }, "*");
    },
    startCreate: function startCreate() {
      window.parent.postMessage({
        type: "startCreate",
        data: {
          albumimage: this.albumimage,
          url: location.origin + "/merchant/obschange?id=" + this.$route.query.id + "&origin=norefresh&token=" + (0, _auth.getToken)()
        }
      }, "*");
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
    },
    openautochange: function openautochange() {
      window.parent.postMessage({
        type: "openautochange"
      }, "*");
    },
    stopPush: function stopPush() {
      window.parent.postMessage({
        type: "stopLive"
      }, "*");
    },
    startPush: function startPush() {
      window.parent.postMessage({
        type: "startLive"
      }, "*");
    },
    settingQuickUrl: function settingQuickUrl() {
      if (!this.livedata.webrtc) {
        return this.$message({
          message: "暂无快直播地址",
          type: "error"
        });
      }
      window.parent.postMessage({
        type: "setQuickLive",
        data: {
          url: "https://webrtcpush.tlivesource.com/webrtc/v2/whip",
          secret: this.livedata.webrtc
        }
      }, "*");
    },
    settingNormalUrl: function settingNormalUrl() {
      if (!this.livedata.rtmp) {
        return this.$message({
          message: "暂无普直播地址",
          type: "error"
        });
      }
      var url = this.livedata.rtmp;

      // 找到最后一个斜杠的位置
      var lastSlashIndex = url.lastIndexOf("/");

      // 分割成两部分
      var part1 = url.substring(0, lastSlashIndex + 1);
      var part2 = url.substring(lastSlashIndex + 1);
      window.parent.postMessage({
        type: "setNormalLive",
        data: {
          url: part1,
          secret: part2
        }
      }, "*");
    },
    back: function back() {
      this.$router.back();
    }
  }
};