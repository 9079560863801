"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
var _data_template = _interopRequireDefault(require("./modules/data_template"));
var _data_drawer_template = _interopRequireDefault(require("./modules/data_drawer_template.vue"));
var _auction = require("@/api/auction");
var _system = require("@/api/system");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    dataTemplate: _data_template.default,
    dataDrawerTemplate: _data_drawer_template.default,
    fileList: _fileList.default
  },
  data: function data() {
    return {
      id: this.$route.query.id || '',
      name: this.$route.query.name || '',
      album_id: '',
      albumStatistical: {},
      clickList: [],
      bidNumList: [],
      priceList: [],
      ideaPriceRanking: [],
      ideaNumRanking: [],
      bidNumRanking: []
    };
  },
  created: function created() {
    var _this = this;
    this.album_id = this.$route.query.id || '';
    (0, _auction.albumStatisticalInfoApi)({
      album_id: this.album_id
    }).then(function (res) {
      _this.albumStatistical = res.data;
    }).catch(function () {});
    var params = {
      page: 1,
      limit: 10,
      album_id: this.album_id
    };
    (0, _auction.auctionList)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, params), {}, {
      order: 'click_desc'
    })).then(function (res) {
      _this.clickList = res.data.list;
    });
    (0, _auction.auctionList)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, params), {}, {
      order: 'bid_num_desc'
    })).then(function (res) {
      _this.bidNumList = res.data.list;
    });
    (0, _auction.auctionList)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, params), {}, {
      order: 'price_desc'
    })).then(function (res) {
      _this.priceList = res.data.list;
    });
    (0, _auction.userIdeaPriceRankingApi)(params).then(function (res) {
      _this.ideaPriceRanking = res.data.list;
    });
    (0, _auction.userIdeaNumRankingApi)(params).then(function (res) {
      _this.ideaNumRanking = res.data.list;
    });
    (0, _auction.userBidNumRankingApi)(params).then(function (res) {
      _this.bidNumRanking = res.data.list;
    });
  },
  methods: {
    // 导出
    exportData: function exportData() {
      var _this2 = this;
      var data = {
        export_type: 'albumBidPriceRecord',
        album_id: this.id
      };
      (0, _system.exportexcel)(data).then(function (res) {
        var h = _this2.$createElement;
        _this2.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this2.$msgbox.close();
                _this2.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      var type = 'albumBidPriceRecord';
      this.fileVisible = true;
      this.$refs.exportList.exportFileList(type);
    },
    showMoreAction: function showMoreAction(type, title) {
      this.$refs.dataDrawerTemplateEL.init(type, title, 'album_id', this.album_id);
    },
    backAction: function backAction() {
      this.$router.back();
    }
  }
};