var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        width: _vm.cutWidth + 40 + "px",
        top: "5vh",
        "show-close": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }),
      _vm._v(" "),
      _c("ImgCutter", {
        ref: "imgCutterModal",
        attrs: {
          isModal: false,
          showChooseBtn: false,
          boxWidth: _vm.boxWidth,
          boxHeight: _vm.boxHeight,
          sizeChange: false,
          crossOrigin: true,
          crossOriginHeader: "*",
          cutWidth: _vm.cutWidth,
          cutHeight: _vm.cutHeight,
        },
        on: { cutDown: _vm.cutDown, onClearAll: _vm.onCancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }