var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "960px",
        visible: _vm.visible,
        "show-close": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("div", { staticClass: "poster-dialog" }, [
          _vm._v("生成需求\n      "),
          _c("img", {
            staticClass: "posterImg",
            attrs: {
              src: require("@/assets/images/poster/close.png"),
              alt: "",
            },
            on: { click: _vm.onClose },
          }),
        ]),
      ]),
      _vm._v(" "),
      _vm.formValidate.isOut
        ? _c(
            "div",
            { staticClass: "poster-content" },
            [
              _c("div", { staticClass: "poster-formtit" }, [
                _vm._v("选择数据"),
              ]),
              _vm._v(" "),
              _vm._l(_vm.formValidate.demand_data, function (ele, ind) {
                return _c(
                  "div",
                  { key: ind, staticClass: "poster-flex" },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { flex: "1" },
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: ele.source,
                          callback: function ($$v) {
                            _vm.$set(ele, "source", $$v)
                          },
                          expression: "ele.source",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "云总部数据", value: "1" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.formValidate.poster_type == "live"
                      ? _c(
                          "el-select",
                          {
                            staticStyle: { flex: "1", "margin-left": "16px" },
                            attrs: {
                              placeholder: "请选择直播",
                              filterable: "",
                              remote: "",
                              "remote-method": _vm.liveInit,
                            },
                            model: {
                              value: ele.live_id,
                              callback: function ($$v) {
                                _vm.$set(ele, "live_id", $$v)
                              },
                              expression: "ele.live_id",
                            },
                          },
                          _vm._l(ele.liveList, function (item, index) {
                            return _c("el-option", {
                              key: item.live_id,
                              attrs: {
                                label: item.live_title,
                                value: item.live_id,
                              },
                            })
                          }),
                          1
                        )
                      : _c(
                          "el-select",
                          {
                            staticStyle: { flex: "1", "margin-left": "16px" },
                            attrs: {
                              placeholder: "请选择拍卖会",
                              filterable: "",
                              remote: "",
                              "remote-method": function (e) {
                                return _vm.venueInit(e, ind)
                              },
                            },
                            on: {
                              change: function (e) {
                                return _vm.onVenueChange(e, ind)
                              },
                            },
                            model: {
                              value: ele.venue_id,
                              callback: function ($$v) {
                                _vm.$set(ele, "venue_id", $$v)
                              },
                              expression: "ele.venue_id",
                            },
                          },
                          _vm._l(ele.venueList, function (item, index) {
                            return _c("el-option", {
                              key: item.venue_id,
                              attrs: {
                                label: item.title,
                                value: item.venue_id,
                              },
                            })
                          }),
                          1
                        ),
                    _vm._v(" "),
                    _vm.formValidate.poster_type == "album" ||
                    _vm.formValidate.poster_type == "auction" ||
                    _vm.formValidate.poster_type == "auctionCertificate" ||
                    _vm.formValidate.poster_type == "festival" ||
                    _vm.formValidate.poster_type == "officialAccount"
                      ? _c(
                          "el-select",
                          {
                            staticStyle: { flex: "1", "margin-left": "16px" },
                            attrs: {
                              placeholder: "请选择专场",
                              filterable: "",
                              remote: "",
                              "remote-method": function (e) {
                                return _vm.albumInit(ele.venue_id, e, ind)
                              },
                            },
                            on: {
                              change: function (e) {
                                return _vm.onAlbumChange(ele.venue_id, e, ind)
                              },
                            },
                            model: {
                              value: ele.album_id,
                              callback: function ($$v) {
                                _vm.$set(ele, "album_id", $$v)
                              },
                              expression: "ele.album_id",
                            },
                          },
                          _vm._l(ele.albumList, function (item, index) {
                            return _c("el-option", {
                              key: item.album_id,
                              attrs: {
                                label: item.album_name,
                                value: item.album_id,
                              },
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.formValidate.poster_type == "auction" ||
                    _vm.formValidate.poster_type == "auctionCertificate" ||
                    _vm.formValidate.poster_type == "festival"
                      ? _c(
                          "el-select",
                          {
                            staticStyle: { flex: "1", "margin-left": "16px" },
                            attrs: {
                              placeholder: "请选择拍品",
                              filterable: "",
                              remote: "",
                              "remote-method": function (e) {
                                return _vm.aucInit(
                                  ele.venue_id,
                                  ele.album_id,
                                  e,
                                  ind
                                )
                              },
                            },
                            model: {
                              value: ele.product_id,
                              callback: function ($$v) {
                                _vm.$set(ele, "product_id", $$v)
                              },
                              expression: "ele.product_id",
                            },
                          },
                          _vm._l(ele.auctList, function (m, i) {
                            return _c("el-option", {
                              key: m.product_id,
                              attrs: {
                                value: m.product_id,
                                label: m.lot + "-" + m.product.store_name,
                              },
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    ind == 0
                      ? _c("img", {
                          staticClass: "posterFlexImg",
                          attrs: {
                            src: require("@/assets/images/poster/jia.png"),
                            alt: "",
                          },
                          on: { click: _vm.onAdd },
                        })
                      : _c("img", {
                          staticClass: "posterFlexImg",
                          attrs: {
                            src: require("@/assets/images/poster/jian.png"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onRedu(ind)
                            },
                          },
                        }),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _c(
            "div",
            { staticClass: "poster-content" },
            [
              _c("div", { staticClass: "poster-formtit" }, [
                _vm._v("海报类型"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "poster-type-container" },
                _vm._l(_vm.posterList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "poster-type-item",
                      class:
                        _vm.formValidate.poster_type == item.id
                          ? "type-active"
                          : "",
                      on: {
                        click: function ($event) {
                          return _vm.onChangeType(item.id)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "poster-formtit" }, [
                _vm._v("选择数据"),
              ]),
              _vm._v(" "),
              _vm._l(_vm.formValidate.demand_data, function (ele, ind) {
                return _c(
                  "div",
                  { key: ind, staticClass: "poster-flex" },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { flex: "1" },
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: ele.source,
                          callback: function ($$v) {
                            _vm.$set(ele, "source", $$v)
                          },
                          expression: "ele.source",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "云总部数据", value: "1" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.formValidate.poster_type == "live"
                      ? _c(
                          "el-select",
                          {
                            staticStyle: { flex: "1", "margin-left": "16px" },
                            attrs: {
                              placeholder: "请选择直播",
                              filterable: "",
                              remote: "",
                              "remote-method": _vm.liveInit,
                            },
                            model: {
                              value: ele.live_id,
                              callback: function ($$v) {
                                _vm.$set(ele, "live_id", $$v)
                              },
                              expression: "ele.live_id",
                            },
                          },
                          _vm._l(ele.liveList, function (item, index) {
                            return _c("el-option", {
                              key: item.live_id,
                              attrs: {
                                label: item.live_title,
                                value: item.live_id,
                              },
                            })
                          }),
                          1
                        )
                      : _c(
                          "el-select",
                          {
                            staticStyle: { flex: "1", "margin-left": "16px" },
                            attrs: {
                              placeholder: "请选择拍卖会",
                              filterable: "",
                              remote: "",
                              "remote-method": function (e) {
                                return _vm.venueInit(e, ind)
                              },
                            },
                            on: {
                              change: function (e) {
                                return _vm.onVenueChange(e, ind)
                              },
                            },
                            model: {
                              value: ele.venue_id,
                              callback: function ($$v) {
                                _vm.$set(ele, "venue_id", $$v)
                              },
                              expression: "ele.venue_id",
                            },
                          },
                          _vm._l(ele.venueList, function (item, index) {
                            return _c("el-option", {
                              key: item.venue_id,
                              attrs: {
                                label: item.title,
                                value: item.venue_id,
                              },
                            })
                          }),
                          1
                        ),
                    _vm._v(" "),
                    _vm.formValidate.poster_type == "album" ||
                    _vm.formValidate.poster_type == "auction"
                      ? _c(
                          "el-select",
                          {
                            staticStyle: { flex: "1", "margin-left": "16px" },
                            attrs: {
                              placeholder: "请选择专场",
                              filterable: "",
                              remote: "",
                              "remote-method": function (e) {
                                return _vm.albumInit(ele.venue_id, e, ind)
                              },
                            },
                            on: {
                              change: function (e) {
                                return _vm.onAlbumChange(ele.venue_id, e, ind)
                              },
                            },
                            model: {
                              value: ele.album_id,
                              callback: function ($$v) {
                                _vm.$set(ele, "album_id", $$v)
                              },
                              expression: "ele.album_id",
                            },
                          },
                          _vm._l(ele.albumList, function (item, index) {
                            return _c("el-option", {
                              key: item.album_id,
                              attrs: {
                                label: item.album_name,
                                value: item.album_id,
                              },
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.formValidate.poster_type == "auction"
                      ? _c(
                          "el-select",
                          {
                            staticStyle: { flex: "1", "margin-left": "16px" },
                            attrs: {
                              placeholder: "请选择拍品",
                              filterable: "",
                              remote: "",
                              "remote-method": function (e) {
                                return _vm.aucInit(
                                  ele.venue_id,
                                  ele.album_id,
                                  e,
                                  ind
                                )
                              },
                            },
                            model: {
                              value: ele.product_id,
                              callback: function ($$v) {
                                _vm.$set(ele, "product_id", $$v)
                              },
                              expression: "ele.product_id",
                            },
                          },
                          _vm._l(ele.auctList, function (m, i) {
                            return _c("el-option", {
                              key: m.product_id,
                              attrs: {
                                value: m.product_id,
                                label: m.lot + "-" + m.product.store_name,
                              },
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    ind == 0
                      ? _c("img", {
                          staticClass: "posterFlexImg",
                          attrs: {
                            src: require("@/assets/images/poster/jia.png"),
                            alt: "",
                          },
                          on: { click: _vm.onAdd },
                        })
                      : _c("img", {
                          staticClass: "posterFlexImg",
                          attrs: {
                            src: require("@/assets/images/poster/jian.png"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onRedu(ind)
                            },
                          },
                        }),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  {
                    staticClass: "flex-one",
                    staticStyle: { "margin-right": "16px" },
                  },
                  [
                    _c("div", { staticClass: "poster-formtit" }, [
                      _vm._v("海报尺寸"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "选择定制模版" },
                        on: { change: _vm.onSizeChange },
                        model: {
                          value: _vm.posterSize,
                          callback: function ($$v) {
                            _vm.posterSize = $$v
                          },
                          expression: "posterSize",
                        },
                      },
                      _vm._l(_vm.sizeList, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: {
                            label: item.actual + " " + item.name,
                            value: item.actual,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { width: "222px" } },
                  [
                    _c("div", { staticClass: "poster-formtit" }, [
                      _vm._v("生成数量"),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请输入生成数量" },
                      model: {
                        value: _vm.formValidate.generate_count,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formValidate,
                            "generate_count",
                            _vm._n($$v)
                          )
                        },
                        expression: "formValidate.generate_count",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "poster-formtit" }, [
                _vm._v("海报质量"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "poster-type-container" },
                _vm._l(_vm.qualityList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "poster-type-item",
                      class:
                        _vm.formValidate.poster_quality == item.id
                          ? "type-active"
                          : "",
                      on: {
                        click: function ($event) {
                          return _vm.onChangeQuality(item.id)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.formValidate.poster_quality == "3"
                ? _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%", "margin-top": "16px" },
                      attrs: { placeholder: "选择定制模版" },
                      model: {
                        value: _vm.formValidate.template_ids,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "template_ids", $$v)
                        },
                        expression: "formValidate.template_ids",
                      },
                    },
                    _vm._l(_vm.templateList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.template_id },
                      })
                    }),
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "poster-foot" },
        [
          _c("div", { staticClass: "poster-foot-text" }, [
            _vm._v("本次消耗 "),
            _c("img", {
              staticClass: "footImg",
              attrs: {
                src: require("@/assets/images/poster/jifen.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", { staticStyle: { color: "#C8A259" } }, [
              _vm._v(_vm._s(_vm.pointNum) + "积分"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "poster-btn",
              attrs: { size: "small", type: "primary", loading: _vm.butLoad },
              on: { click: _vm.onNext },
            },
            [_vm._v("开始生成")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }