var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.collectMoney.show,
        title: "",
        top: "5vh",
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.collectMoney, "show", $event)
        },
      },
    },
    [
      _c("div", { staticClass: "all-h-print" }, [
        _vm.collectMoney.show
          ? _c(
              "div",
              { attrs: { id: "printorder" } },
              [
                _c("div", { staticClass: "detail-box" }, [
                  _c("div", { staticClass: "flex" }, [
                    _vm.merData.config && _vm.merData.config.mer_print_logo
                      ? _c("img", {
                          staticStyle: {
                            height: "70px",
                            "margin-right": "20px",
                          },
                          attrs: {
                            src: _vm.merData.config.mer_print_logo + "!120a",
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "detail-left" }, [
                      _c("div", { staticClass: "detail-left-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.collectMoney.data.merConfig.company_name
                            ) +
                            " | " +
                            _vm._s(_vm.detail.title.china) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "detail-left-desc",
                          staticStyle: {
                            "font-size": "14px",
                            "font-family": "'printEN'",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.collectMoney.data.merConfig
                                  .company_english_name
                              ) +
                              " | " +
                              _vm._s(_vm.detail.title.english) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "secTit" }, [
                  _vm._v(_vm._s(_vm.detail.buyerCompany.china)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-left-desc",
                    staticStyle: {
                      "font-size": "13px",
                      "font-family": "'printEN'",
                      "font-weight": "500",
                      "word-break": "break-word",
                    },
                  },
                  [_vm._v(_vm._s(_vm.detail.buyerCompany.english))]
                ),
                _vm._v(" "),
                _vm.collectMoney.data.venueInfo
                  ? _c(
                      "div",
                      {
                        staticClass: "detail-left-title",
                        staticStyle: { "margin-top": "40px" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.collectMoney.data.venueInfo.title) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-list",
                    staticStyle: { "margin-top": "5px" },
                  },
                  [
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.switchLang("SellerName")) + " SELLER'S NAME "
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-weight": "500",
                            "font-size": "16px",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.collectMoney.data.entrustUser.real_name
                                ? _vm.collectMoney.data.entrustUser.real_name
                                : _vm.collectMoney.data.entrustUser.nickname
                            )
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.collectMoney.data.contractCount < 2
                      ? _c("div", [
                          _vm._v(
                            _vm._s(_vm.switchLang("ContractNo")) +
                              " CONTRACT NO. "
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "500",
                                "font-size": "16px",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.collectMoney.data.contractInfo
                                    ? _vm.collectMoney.data.contractInfo
                                        .contract_no
                                    : ""
                                )
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("交易日期 DATE OF SALE "),
                      _vm.collectMoney.data.createTimeArea.min !=
                      _vm.collectMoney.data.createTimeArea.max
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "500",
                                "font-size": "16px",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.collectMoney.data.createTimeArea.min
                                ) +
                                  " - " +
                                  _vm._s(
                                    _vm.collectMoney.data.createTimeArea.max
                                  )
                              ),
                            ]
                          )
                        : _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "500",
                                "font-size": "16px",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.collectMoney.data.createTimeArea.min)
                              ),
                            ]
                          ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "all-print-main-el" }),
                _vm._v(" "),
                _vm.isNo
                  ? _c(
                      "div",
                      { staticClass: "ptd-box" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "ptd",
                            staticStyle: { "font-weight": "bold" },
                          },
                          [
                            _vm._l(_vm.detail.tableColumn, function (m, i) {
                              return [
                                i == 1 &&
                                _vm.collectMoney.data.contractCount > 1
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          key: "col_no" + i,
                                          staticClass: "ptd-title",
                                          style:
                                            "width: " +
                                            _vm.detail.tableColumn[2].w +
                                            "px;",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.switchLang("KtNo"))
                                            ),
                                          ]),
                                          m.english
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-family": "'printEN'",
                                                    "white-space": "nowrap",
                                                  },
                                                },
                                                [_vm._v("CONTRACT NO.")]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                m.open
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          key: "col" + i,
                                          staticClass: "ptd-title",
                                          style: m.w
                                            ? "width: " + m.w + "px;"
                                            : "flex: 1",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(m.showName || m.name)
                                            ),
                                          ]),
                                          m.english
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-family": "'printEN'",
                                                    "white-space": "nowrap",
                                                  },
                                                },
                                                [_vm._v(_vm._s(m.english))]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm._l(
                          _vm.collectMoney.data.checkAuctionData,
                          function (m, i) {
                            return _c(
                              "div",
                              { key: "order" + i, staticClass: "ptd calcul" },
                              [
                                _vm.detail.tableColumn[0] &&
                                _vm.detail.tableColumn[0].open
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        style:
                                          "width: " +
                                          _vm.detail.tableColumn[0].w +
                                          "px;",
                                      },
                                      [_vm._v(_vm._s(m.pmAuction.lot))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.collectMoney.data.contractCount > 1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        style:
                                          "width: " +
                                          _vm.detail.tableColumn[2].w +
                                          "px;",
                                      },
                                      [_vm._v(_vm._s(m.contract.contract_no))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ptd-body",
                                    staticStyle: { flex: "1" },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(m.pmAuction.product.store_name)
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.detail.tableColumn[2] &&
                                _vm.detail.tableColumn[2].open
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        style:
                                          "width: " +
                                          _vm.detail.tableColumn[2].w +
                                          "px;",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            m.auctionStatusText == "交割失败"
                                              ? _vm.switchLang("NoPay")
                                              : m.auctionStatusText
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.detail.tableColumn[3] &&
                                _vm.detail.tableColumn[3].open
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        style:
                                          "width: " +
                                          _vm.detail.tableColumn[3].w +
                                          "px;",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            m.auction_status == 5 ||
                                              m.auction_status == 6
                                              ? Number(m.auction_now_price)
                                                ? _vm.formatPriceNumber(
                                                    m.auction_now_price
                                                  )
                                                : _vm.formatPriceNumber(
                                                    m.pmAuction.now_price,
                                                    2
                                                  )
                                              : 0
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.detail.tableColumn[4] &&
                                _vm.detail.tableColumn[4].open
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        staticStyle: {
                                          "flex-direction": "column",
                                        },
                                        style:
                                          "width: " +
                                          _vm.detail.tableColumn[4].w +
                                          "px;",
                                      },
                                      [
                                        m.auction_status <= 4
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  width: "100%",
                                                  "justify-content":
                                                    "space-around",
                                                },
                                              },
                                              _vm._l(
                                                m.price_data[1],
                                                function (e, ind) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: "1" + ind,
                                                      staticStyle: {
                                                        "line-height": "20px",
                                                        display: "flex",
                                                        "flex-direction":
                                                          "column",
                                                      },
                                                    },
                                                    [
                                                      e.name &&
                                                      Number(
                                                        e.deduction_amount
                                                      ) != 0
                                                        ? [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "white-space":
                                                                    "nowrap",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    e.name
                                                                  ) +
                                                                    _vm._s(
                                                                      e.unit ==
                                                                        1
                                                                        ? ""
                                                                        : e.number
                                                                    ) +
                                                                    _vm._s(
                                                                      e.unit ==
                                                                        1
                                                                        ? ""
                                                                        : "%"
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            e.deduction_amount
                                                              ? _c("div", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.formatPriceNumber(
                                                                        e.deduction_amount,
                                                                        2
                                                                      )
                                                                    ) + "元"
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  width: "100%",
                                                  "justify-content":
                                                    "space-around",
                                                },
                                              },
                                              _vm._l(
                                                m.price_data[0],
                                                function (e, ind) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: "0" + ind,
                                                      staticStyle: {
                                                        "line-height": "20px",
                                                        display: "flex",
                                                        "flex-direction":
                                                          "column",
                                                      },
                                                    },
                                                    [
                                                      e.name &&
                                                      Number(
                                                        e.deduction_amount
                                                      ) != 0
                                                        ? [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "white-space":
                                                                    "nowrap",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    e.name
                                                                  ) +
                                                                    _vm._s(
                                                                      e.unit ==
                                                                        1
                                                                        ? ""
                                                                        : e.number
                                                                    ) +
                                                                    _vm._s(
                                                                      e.unit ==
                                                                        1
                                                                        ? ""
                                                                        : "%"
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            e.deduction_amount
                                                              ? _c("div", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.formatPriceNumber(
                                                                        e.deduction_amount,
                                                                        2
                                                                      )
                                                                    ) + "元"
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              width: "100%",
                                              "justify-content": "space-around",
                                            },
                                          },
                                          _vm._l(
                                            m.price_data[2],
                                            function (e, ind) {
                                              return _c(
                                                "div",
                                                {
                                                  key: "2" + ind,
                                                  staticStyle: {
                                                    "line-height": "20px",
                                                    display: "flex",
                                                    "flex-direction": "column",
                                                  },
                                                },
                                                [
                                                  e.name &&
                                                  Number(e.deduction_amount) !=
                                                    0
                                                    ? [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "white-space":
                                                                "nowrap",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(e.name) +
                                                                _vm._s(
                                                                  e.unit == 1
                                                                    ? ""
                                                                    : e.number
                                                                ) +
                                                                _vm._s(
                                                                  e.unit == 1
                                                                    ? ""
                                                                    : "%"
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        e.deduction_amount
                                                          ? _c("div", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.formatPriceNumber(
                                                                    e.deduction_amount,
                                                                    2
                                                                  )
                                                                ) + "元"
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.detail.tableColumn[5] &&
                                _vm.detail.tableColumn[5].open
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        style:
                                          "width: " +
                                          _vm.detail.tableColumn[5].w +
                                          "px;",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatPriceNumber(
                                              m.settlement_amount,
                                              2
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ptd" }, [
                          _c("div", { staticClass: "ptd" }, [
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { "flex-direction": "column" },
                                style:
                                  "width: " +
                                  _vm.detail.tableColumn[0].w +
                                  "px;",
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.switchLang("Total"))),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: { "font-family": "'printEN'" },
                                  },
                                  [_vm._v("TOTAL")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.collectMoney.data.contractCount > 1
                              ? _c("div", {
                                  staticClass: "ptd-body",
                                  staticStyle: { "flex-direction": "column" },
                                  style:
                                    "width: " +
                                    _vm.detail.tableColumn[2].w +
                                    "px;",
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { flex: "1" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.collectMoney.data.checkAuctionData
                                        .length
                                    ) + "件"
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "ptd-body",
                              style:
                                "width: " + _vm.detail.tableColumn[2].w + "px;",
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                style:
                                  "width: " +
                                  _vm.detail.tableColumn[3].w +
                                  "px;",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.data.nowTotal,
                                      2
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { "flex-direction": "column" },
                                style:
                                  "width: " +
                                  _vm.detail.tableColumn[4].w +
                                  "px;",
                              },
                              [
                                _vm.collectMoney.data.obj.err > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          width: "100%",
                                          "justify-content": "space-around",
                                        },
                                      },
                                      _vm._l(
                                        _vm.collectMoney.data.totalPriceData[1],
                                        function (e, ind) {
                                          return _c(
                                            "div",
                                            {
                                              key: "1" + ind,
                                              staticStyle: {
                                                "line-height": "20px",
                                                display: "flex",
                                                "flex-direction": "column",
                                              },
                                            },
                                            [
                                              e.name && Number(e.price) != 0
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "white-space":
                                                            "nowrap",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(e.name) +
                                                            _vm._s(
                                                              e.unit == 1
                                                                ? ""
                                                                : e.number
                                                            ) +
                                                            _vm._s(
                                                              e.unit == 1
                                                                ? ""
                                                                : "%"
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    e.price
                                                      ? _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatPriceNumber(
                                                                e.price,
                                                                2
                                                              )
                                                            ) + "元"
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.collectMoney.data.obj.succ > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          width: "100%",
                                          "justify-content": "space-around",
                                        },
                                      },
                                      _vm._l(
                                        _vm.collectMoney.data.totalPriceData[0],
                                        function (e, ind) {
                                          return _c(
                                            "div",
                                            {
                                              key: "0" + ind,
                                              staticStyle: {
                                                "line-height": "20px",
                                                display: "flex",
                                                "flex-direction": "column",
                                              },
                                            },
                                            [
                                              e.name && Number(e.price) != 0
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "white-space":
                                                            "nowrap",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(e.name) +
                                                            _vm._s(
                                                              e.unit == 1
                                                                ? ""
                                                                : e.number
                                                            ) +
                                                            _vm._s(
                                                              e.unit == 1
                                                                ? ""
                                                                : "%"
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    e.price
                                                      ? _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatPriceNumber(
                                                                e.price,
                                                                2
                                                              )
                                                            ) + "元"
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      width: "100%",
                                      "justify-content": "space-around",
                                    },
                                  },
                                  _vm._l(
                                    _vm.collectMoney.data.totalPriceData[2],
                                    function (e, ind) {
                                      return _c(
                                        "div",
                                        {
                                          key: "2" + ind,
                                          staticStyle: {
                                            "line-height": "20px",
                                            display: "flex",
                                            "flex-direction": "column",
                                          },
                                        },
                                        [
                                          e.name && Number(e.price) != 0
                                            ? [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "white-space": "nowrap",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(e.name) +
                                                        _vm._s(
                                                          e.unit == 1
                                                            ? ""
                                                            : e.number
                                                        ) +
                                                        _vm._s(
                                                          e.unit == 1 ? "" : "%"
                                                        )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                e.price
                                                  ? _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formatPriceNumber(
                                                            e.price,
                                                            2
                                                          )
                                                        ) + "元"
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                style:
                                  "width: " +
                                  _vm.detail.tableColumn[5].w +
                                  "px;",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.data
                                        .totalSettlementAmount,
                                      2
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "ptd" }, [
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: {
                                "flex-direction": "column",
                                flex: "1",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "text-align": "left" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.switchLang("AdditionalAmount"))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-family": "'printEN'",
                                    "text-align": "left",
                                  },
                                },
                                [_vm._v("ADDITIONAL AMOUNT")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              style:
                                "width: " +
                                (_vm.detail.tableColumn[3].w +
                                  _vm.detail.tableColumn[2].w) +
                                "px;",
                            },
                            [
                              _vm._v(
                                "+" +
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.data
                                        .totalExtraSettlementAmount,
                                      2
                                    )
                                  )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              staticStyle: { "flex-direction": "column" },
                              style:
                                "width: " + _vm.detail.tableColumn[4].w + "px;",
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "text-align": "left" } },
                                [_vm._v(_vm._s(_vm.switchLang("PaidAmount")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-family": "'printEN'",
                                    "text-align": "left",
                                  },
                                },
                                [_vm._v("ACTUAL PAYMENT AMOUNT")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              style:
                                "width: " + _vm.detail.tableColumn[5].w + "px;",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPriceNumber(
                                    _vm.collectMoney.data
                                      .totalPaySettlementAmount,
                                    2
                                  )
                                )
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd blb",
                            style:
                              _vm.collectMoney.data.contractCount > 1
                                ? "padding: 0;"
                                : "padding: 0;",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: {
                                  "flex-direction": "column",
                                  flex: "1",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.switchLang("feeTypeText"))
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-family": "'printEN'",
                                      "text-align": "left",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "CAPITAL IN " +
                                        _vm._s(
                                          _vm.feeTypeObj.type == "CNY"
                                            ? "RMB"
                                            : _vm.feeTypeObj.type
                                        )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                style: _vm.getForeWidth,
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.collectMoney.data
                                      .totalPaySettlementAmountRmb
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      2
                    )
                  : [
                      _vm._l(_vm.result, function (item, ind) {
                        return _c(
                          "div",
                          {
                            key: ind,
                            staticClass: "ptd-box",
                            style:
                              ind < _vm.result.length - 1
                                ? "page-break-after: always;"
                                : "",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "ptd",
                                staticStyle: { "font-weight": "bold" },
                              },
                              [
                                _vm._l(_vm.detail.tableColumn, function (m, i) {
                                  return [
                                    i == 1 &&
                                    _vm.collectMoney.data.contractCount > 1
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              key: "col_no" + i,
                                              staticClass: "ptd-title",
                                              style:
                                                "width: " +
                                                _vm.detail.tableColumn[2].w +
                                                "px;",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.switchLang("KtNo"))
                                                ),
                                              ]),
                                              m.english
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-family":
                                                          "'printEN'",
                                                        "white-space": "nowrap",
                                                      },
                                                    },
                                                    [_vm._v("CONTRACT NO.")]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    m.open
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              key: "col" + i,
                                              staticClass: "ptd-title",
                                              style: m.w
                                                ? "width: " + m.w + "px;"
                                                : "flex: 1",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(m.showName || m.name)
                                                ),
                                              ]),
                                              m.english
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-family":
                                                          "'printEN'",
                                                        "white-space": "nowrap",
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(m.english))]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                  ]
                                }),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _vm._l(item, function (m, i) {
                              return _c(
                                "div",
                                { key: "order" + i, staticClass: "ptd" },
                                [
                                  _vm.detail.tableColumn[0] &&
                                  _vm.detail.tableColumn[0].open
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          style:
                                            "width: " +
                                            _vm.detail.tableColumn[0].w +
                                            "px;",
                                        },
                                        [_vm._v(_vm._s(m.pmAuction.lot))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.collectMoney.data.contractCount > 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          style:
                                            "width: " +
                                            _vm.detail.tableColumn[2].w +
                                            "px;",
                                        },
                                        [_vm._v(_vm._s(m.contract.contract_no))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-body",
                                      staticStyle: { flex: "1" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(m.pmAuction.product.store_name)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.detail.tableColumn[2] &&
                                  _vm.detail.tableColumn[2].open
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          style:
                                            "width: " +
                                            _vm.detail.tableColumn[2].w +
                                            "px;",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              m.auctionStatusText == "交割失败"
                                                ? _vm.switchLang("NoPay")
                                                : m.auctionStatusText
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.detail.tableColumn[3] &&
                                  _vm.detail.tableColumn[3].open
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          style:
                                            "width: " +
                                            _vm.detail.tableColumn[3].w +
                                            "px;",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              m.auction_status == 5 ||
                                                m.auction_status == 6
                                                ? Number(m.auction_now_price)
                                                  ? _vm.formatPriceNumber(
                                                      m.auction_now_price
                                                    )
                                                  : _vm.formatPriceNumber(
                                                      m.pmAuction.now_price,
                                                      2
                                                    )
                                                : 0
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.detail.tableColumn[4] &&
                                  _vm.detail.tableColumn[4].open
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          staticStyle: {
                                            "flex-direction": "column",
                                          },
                                          style:
                                            "width: " +
                                            _vm.detail.tableColumn[4].w +
                                            "px;",
                                        },
                                        [
                                          m.auction_status <= 4
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    width: "100%",
                                                    "justify-content":
                                                      "space-around",
                                                  },
                                                },
                                                _vm._l(
                                                  m.price_data[1],
                                                  function (e, ind) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: "1" + ind,
                                                        staticStyle: {
                                                          "line-height": "20px",
                                                          display: "flex",
                                                          "flex-direction":
                                                            "column",
                                                        },
                                                      },
                                                      [
                                                        e.name &&
                                                        Number(
                                                          e.deduction_amount
                                                        ) != 0
                                                          ? [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "white-space":
                                                                      "nowrap",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      e.name
                                                                    ) +
                                                                      _vm._s(
                                                                        e.unit ==
                                                                          1
                                                                          ? ""
                                                                          : e.number
                                                                      ) +
                                                                      _vm._s(
                                                                        e.unit ==
                                                                          1
                                                                          ? ""
                                                                          : "%"
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              e.deduction_amount
                                                                ? _c("div", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.formatPriceNumber(
                                                                          e.deduction_amount,
                                                                          2
                                                                        )
                                                                      ) + "元"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    width: "100%",
                                                    "justify-content":
                                                      "space-around",
                                                  },
                                                },
                                                _vm._l(
                                                  m.price_data[0],
                                                  function (e, ind) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: "0" + ind,
                                                        staticStyle: {
                                                          "line-height": "20px",
                                                          display: "flex",
                                                          "flex-direction":
                                                            "column",
                                                        },
                                                      },
                                                      [
                                                        e.name &&
                                                        Number(
                                                          e.deduction_amount
                                                        ) != 0
                                                          ? [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "white-space":
                                                                      "nowrap",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      e.name
                                                                    ) +
                                                                      _vm._s(
                                                                        e.unit ==
                                                                          1
                                                                          ? ""
                                                                          : e.number
                                                                      ) +
                                                                      _vm._s(
                                                                        e.unit ==
                                                                          1
                                                                          ? ""
                                                                          : "%"
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              e.deduction_amount
                                                                ? _c("div", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.formatPriceNumber(
                                                                          e.deduction_amount,
                                                                          2
                                                                        )
                                                                      ) + "元"
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                width: "100%",
                                                "justify-content":
                                                  "space-around",
                                              },
                                            },
                                            _vm._l(
                                              m.price_data[2],
                                              function (e, ind) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: "2" + ind,
                                                    staticStyle: {
                                                      "line-height": "20px",
                                                      display: "flex",
                                                      "flex-direction":
                                                        "column",
                                                    },
                                                  },
                                                  [
                                                    e.name &&
                                                    Number(
                                                      e.deduction_amount
                                                    ) != 0
                                                      ? [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "white-space":
                                                                  "nowrap",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(e.name) +
                                                                  _vm._s(
                                                                    e.unit == 1
                                                                      ? ""
                                                                      : e.number
                                                                  ) +
                                                                  _vm._s(
                                                                    e.unit == 1
                                                                      ? ""
                                                                      : "%"
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          e.deduction_amount
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.formatPriceNumber(
                                                                      e.deduction_amount,
                                                                      2
                                                                    )
                                                                  ) + "元"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.detail.tableColumn[5] &&
                                  _vm.detail.tableColumn[5].open
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          style:
                                            "width: " +
                                            _vm.detail.tableColumn[5].w +
                                            "px;",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatPriceNumber(
                                                m.settlement_amount,
                                                2
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                          ],
                          2
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ptd-box",
                          staticStyle: {
                            "margin-top": "0",
                            "border-top": "none",
                          },
                        },
                        [
                          _c("div", { staticClass: "ptd" }, [
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { "flex-direction": "column" },
                                style:
                                  "width: " +
                                  _vm.detail.tableColumn[0].w +
                                  "px;",
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.switchLang("Total"))),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: { "font-family": "'printEN'" },
                                  },
                                  [_vm._v("TOTAL")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.collectMoney.data.contractCount > 1
                              ? _c("div", {
                                  staticClass: "ptd-body",
                                  staticStyle: { "flex-direction": "column" },
                                  style:
                                    "width: " +
                                    _vm.detail.tableColumn[2].w +
                                    "px;",
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { flex: "1" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.collectMoney.data.checkAuctionData
                                        .length
                                    ) + "件"
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "ptd-body",
                              style:
                                "width: " + _vm.detail.tableColumn[2].w + "px;",
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                style:
                                  "width: " +
                                  _vm.detail.tableColumn[3].w +
                                  "px;",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.data.nowTotal,
                                      2
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { "flex-direction": "column" },
                                style:
                                  "width: " +
                                  _vm.detail.tableColumn[4].w +
                                  "px;",
                              },
                              [
                                _vm.collectMoney.data.obj.err > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          width: "100%",
                                          "justify-content": "space-around",
                                        },
                                      },
                                      _vm._l(
                                        _vm.collectMoney.data.totalPriceData[1],
                                        function (e, ind) {
                                          return _c(
                                            "div",
                                            {
                                              key: "1" + ind,
                                              staticStyle: {
                                                "line-height": "20px",
                                                display: "flex",
                                                "flex-direction": "column",
                                              },
                                            },
                                            [
                                              e.name && Number(e.price) != 0
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "white-space":
                                                            "nowrap",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(e.name) +
                                                            _vm._s(
                                                              e.unit == 1
                                                                ? ""
                                                                : e.number
                                                            ) +
                                                            _vm._s(
                                                              e.unit == 1
                                                                ? ""
                                                                : "%"
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    e.price
                                                      ? _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatPriceNumber(
                                                                e.price,
                                                                2
                                                              )
                                                            ) + "元"
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.collectMoney.data.obj.succ > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          width: "100%",
                                          "justify-content": "space-around",
                                        },
                                      },
                                      _vm._l(
                                        _vm.collectMoney.data.totalPriceData[0],
                                        function (e, ind) {
                                          return _c(
                                            "div",
                                            {
                                              key: "0" + ind,
                                              staticStyle: {
                                                "line-height": "20px",
                                                display: "flex",
                                                "flex-direction": "column",
                                              },
                                            },
                                            [
                                              e.name && Number(e.price) != 0
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "white-space":
                                                            "nowrap",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(e.name) +
                                                            _vm._s(
                                                              e.unit == 1
                                                                ? ""
                                                                : e.number
                                                            ) +
                                                            _vm._s(
                                                              e.unit == 1
                                                                ? ""
                                                                : "%"
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    e.price
                                                      ? _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatPriceNumber(
                                                                e.price,
                                                                2
                                                              )
                                                            ) + "元"
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      width: "100%",
                                      "justify-content": "space-around",
                                    },
                                  },
                                  _vm._l(
                                    _vm.collectMoney.data.totalPriceData[2],
                                    function (e, ind) {
                                      return _c(
                                        "div",
                                        {
                                          key: "2" + ind,
                                          staticStyle: {
                                            "line-height": "20px",
                                            display: "flex",
                                            "flex-direction": "column",
                                          },
                                        },
                                        [
                                          e.name && Number(e.price) != 0
                                            ? [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "white-space": "nowrap",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(e.name) +
                                                        _vm._s(
                                                          e.unit == 1
                                                            ? ""
                                                            : e.number
                                                        ) +
                                                        _vm._s(
                                                          e.unit == 1 ? "" : "%"
                                                        )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                e.price
                                                  ? _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formatPriceNumber(
                                                            e.price,
                                                            2
                                                          )
                                                        ) + "元"
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                style:
                                  "width: " +
                                  _vm.detail.tableColumn[5].w +
                                  "px;",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.data
                                        .totalSettlementAmount,
                                      2
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "ptd" }, [
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: {
                                  "flex-direction": "column",
                                  flex: "1",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.switchLang("AdditionalAmount"))
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-family": "'printEN'",
                                      "text-align": "left",
                                    },
                                  },
                                  [_vm._v("ADDITIONAL AMOUNT")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                style:
                                  "width: " +
                                  (_vm.detail.tableColumn[3].w +
                                    _vm.detail.tableColumn[2].w) +
                                  "px;",
                              },
                              [
                                _vm._v(
                                  "+" +
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        _vm.collectMoney.data
                                          .totalExtraSettlementAmount,
                                        2
                                      )
                                    )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { "flex-direction": "column" },
                                style:
                                  "width: " +
                                  _vm.detail.tableColumn[4].w +
                                  "px;",
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "text-align": "left" } },
                                  [_vm._v(_vm._s(_vm.switchLang("PaidAmount")))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-family": "'printEN'",
                                      "text-align": "left",
                                    },
                                  },
                                  [_vm._v("ACTUAL PAYMENT AMOUNT")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                style:
                                  "width: " +
                                  _vm.detail.tableColumn[5].w +
                                  "px;",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.data
                                        .totalPaySettlementAmount,
                                      2
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd blb",
                              style:
                                _vm.collectMoney.data.contractCount > 1
                                  ? "padding: 0;"
                                  : "padding: 0;",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  staticStyle: {
                                    "flex-direction": "column",
                                    flex: "1",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "text-align": "left" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.switchLang("feeTypeText"))
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'printEN'",
                                        "text-align": "left",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "CAPITAL IN " +
                                          _vm._s(
                                            _vm.feeTypeObj.type == "CNY"
                                              ? "RMB"
                                              : _vm.feeTypeObj.type
                                          )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  style: _vm.getForeWidth,
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.collectMoney.data
                                        .totalPaySettlementAmountRmb
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "secTit",
                    staticStyle: {
                      "margin-top": "30px",
                      "margin-bottom": "10px",
                    },
                  },
                  [_vm._v(_vm._s(_vm.detail.settleText.china))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-left-desc",
                    staticStyle: {
                      "font-size": "13px",
                      "font-family": "'printEN'",
                      "font-weight": "500",
                      "word-wrap": "break-word",
                      "word-break": "normal",
                    },
                  },
                  [_vm._v(_vm._s(_vm.detail.settleText.english))]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "print-line",
                  staticStyle: { "margin-top": "30px" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "detail-sign" }, [
                  _vm._v(_vm._s(_vm.switchLang("SellerSign"))),
                  _c("span", { staticStyle: { "font-family": "'printEN'" } }, [
                    _vm._v("（SIGNATURE）"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "print-line" }),
                _vm._v(" "),
                _vm.detail.signColumnTab == "system"
                  ? _c(
                      "div",
                      {
                        staticClass: "detail-list",
                        staticStyle: {
                          "margin-top": "30px",
                          "flex-wrap": "wrap",
                        },
                      },
                      [
                        _vm._l(_vm.detail.signColumn, function (m, i) {
                          return [
                            m.open
                              ? [
                                  _c(
                                    "div",
                                    {
                                      key: i,
                                      style:
                                        "width: " +
                                        (m.w == "auto" ? "auto" : m.w + "%") +
                                        ";margin-bottom: 10px;padding-right: 5px;white-space: nowrap;" +
                                        (m.name == "打印时间"
                                          ? "text-align: right;"
                                          : ""),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(m.showName || m.name) + ":"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      i == "accountName"
                                        ? [
                                            _c("span", { staticClass: "ml5" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.merData.config.bank_name
                                                )
                                              ),
                                            ]),
                                          ]
                                        : i == "accountBank"
                                        ? [
                                            _c("span", { staticClass: "ml5" }, [
                                              _vm._v(
                                                _vm._s(_vm.merData.config.bank)
                                              ),
                                            ]),
                                          ]
                                        : i == "accountNumber"
                                        ? [
                                            _c("span", { staticClass: "ml5" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.merData.config.bank_number
                                                )
                                              ),
                                            ]),
                                          ]
                                        : i == "accountAddress"
                                        ? [
                                            _c("span", { staticClass: "ml5" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.merData.config
                                                    .company_address
                                                )
                                              ),
                                            ]),
                                          ]
                                        : i == "printTime"
                                        ? [
                                            _c("span", { staticClass: "ml5" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.parseTime(new Date())
                                                )
                                              ),
                                            ]),
                                          ]
                                        : i == "creator" || i == "recipient"
                                        ? [
                                            _c("span", { staticClass: "ml5" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.collectMoney.data
                                                    .admin_name
                                                )
                                              ),
                                            ]),
                                          ]
                                        : i == "documentNumber"
                                        ? [
                                            _c("span", { staticClass: "ml5" }, [
                                              _vm._v(_vm._s(_vm.no)),
                                            ]),
                                          ]
                                        : i == "clientAccountName"
                                        ? [
                                            _c("span", { staticClass: "ml5" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.collectMoney.data &&
                                                    _vm.collectMoney.data
                                                      .entrustUser &&
                                                    _vm.collectMoney.data
                                                      .entrustUser.entrust
                                                    ? _vm.collectMoney.data
                                                        .entrustUser.entrust
                                                        .account_name
                                                    : ""
                                                )
                                              ),
                                            ]),
                                          ]
                                        : i == "clientAccountBank"
                                        ? [
                                            _c("span", { staticClass: "ml5" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.collectMoney.data &&
                                                    _vm.collectMoney.data
                                                      .entrustUser &&
                                                    _vm.collectMoney.data
                                                      .entrustUser.entrust
                                                    ? _vm.collectMoney.data
                                                        .entrustUser.entrust
                                                        .account_bank
                                                    : ""
                                                )
                                              ),
                                            ]),
                                          ]
                                        : i == "clientAccountNumber"
                                        ? [
                                            _c("span", { staticClass: "ml5" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.collectMoney.data &&
                                                    _vm.collectMoney.data
                                                      .entrustUser &&
                                                    _vm.collectMoney.data
                                                      .entrustUser.entrust
                                                    ? _vm.collectMoney.data
                                                        .entrustUser.entrust
                                                        .account_bank_card_number
                                                    : ""
                                                )
                                              ),
                                            ]),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "detail-list",
                        staticStyle: {
                          "margin-top": "30px",
                          "flex-wrap": "wrap",
                        },
                      },
                      [
                        _c("div", {
                          staticStyle: { flex: "1", overflow: "hidden" },
                          domProps: {
                            innerHTML: _vm._s(_vm.detail.signColumnCustome),
                          },
                        }),
                      ]
                    ),
              ],
              2
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "default" },
              on: { click: _vm.bindPrintorder },
            },
            [_vm._v("打印")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }