"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _basic = require("@/api/basic");
var _product = require("@/api/product");
var _utils = require("@/utils");
var _warehouse = require("@/api/warehouse");
var _setColumn = _interopRequireDefault(require("@/components/setColumn.vue"));
var _printJs = _interopRequireDefault(require("print-js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    SetColumn: _setColumn.default
  },
  data: function data() {
    return {
      formatPriceNumber: _utils.formatPriceNumber,
      searchQuery: {},
      listLoading: false,
      tableData: [],
      olddata: [],
      tableFrom: {
        is_auction_audit: 1,
        page: 1,
        limit: 20
      },
      total: 0,
      storeList: [],
      headerList: [],
      attributeList: [],
      attributejson: {},
      showfilterLine: false,
      copyForm: {},
      labelarrOne: [],
      labelarr: [],
      inputname: '',
      chkName: '',
      checkedPage: [],
      checkedIds: [],
      // 订单当前页选中的数据
      noChecked: [],
      // 订单全选状态下当前页不选中的数据
      allCheck: false,
      setLabelData: {
        show: false,
        labelIds: ''
      },
      printData: [],
      printType: 1
    };
  },
  computed: {
    hasSearchRecode: function hasSearchRecode() {
      return Object.keys(this.searchQuery).length > 0;
    }
  },
  created: function created() {
    var _this = this;
    this.getCategorySelect();
    this.getheaderList();
    (0, _product.getStoreList)({
      page: 1
    }).then(function (res) {
      _this.storeList = res.data.list.map(function (item) {
        return {
          label: item.name,
          value: item.warehouse_id
        };
      });
      _this.storeList.push({
        label: '无库存',
        value: '0'
      });
    });
    this.getlabel(function () {
      _this.getList(1);
    });
    this.onSelectFilterChange('');
  },
  methods: {
    cellClick: function cellClick(row, column, cell, event) {
      var _this2 = this;
      if (!this.$store.getters.userPression.merchantErpV2ProductUpdateField) return;
      // if (column.type == "content") {
      //   this.inputname = "";
      //   this.editgoods(row);
      //   return;
      // }
      // console.log(column.type);
      if (/^\d*$/.test(column.type)) {
        // if (column.type == 'attribute') {
        // this.inputname = column.type + column.index + row.id;
        this.inputname = column.type + row.id;
        // console.log()
      } else {
        this.inputname = column.type + row.id;
      }
      if (column.type == 'category') {
        if (this.cateoneclick) {
          return;
        }
        this.$nextTick(function () {
          var catestr = 'category' + row.id;
          // console.log(this.$refs[catestr]);
          _this2.cateoneclick = true;
          _this2.$refs[catestr][0].$el.click();
          setTimeout(function () {
            _this2.cateoneclick = false;
          }, 200);
        });
      }
      // console.log(this.inputname);
    },
    getlabel: function getlabel(cb) {
      var _this3 = this;
      (0, _basic.labelLstApi)({
        page: 1,
        limit: 100,
        system_page: 'erp_product_audit_list'
      }).then(function (res) {
        _this3.labelarr = res.data.list;
        var json = {};
        res.data.list.forEach(function (item) {
          json[item.label_id] = item.label_name;
          item.label = item.label_name;
          item.value = item.label_id + '';
        });
        _this3.labeljson = json;
        if (cb) cb();
      }).catch(function (res) {
        if (cb) cb();
        // this.$message.error(res.message)
      });
    },
    onSelectFilterChange: function onSelectFilterChange(val) {
      var _this4 = this;
      (0, _basic.labelLstApi)({
        page: 1,
        limit: 30,
        label_name: val
      }).then(function (res) {
        res.data.list.forEach(function (item) {
          item.label = item.label_name;
          item.value = item.label_id + '';
        });
        _this4.labelarrOne = res.data.list;
      });
    },
    changeheaderconfig: function changeheaderconfig() {
      this.$refs.setColumnEL.changeheaderconfig();
    },
    getheaderList: function getheaderList() {
      var _this5 = this;
      this.headerList = [];
      this.attributeList = [];
      // erp_product_list
      (0, _warehouse.getFieldSetAPI)({
        types: 'erp_v2_product_auction_audit_list'
      }).then(function (res) {
        res.data.erp_v2_product_auction_audit_list.show_list.forEach(function (item) {
          if (/^\d*$/.test(item.field)) {
            item.type = 'attribute';
            _this5.attributeList.push(item);
          }
        });
        _this5.headerList = res.data.erp_v2_product_auction_audit_list.show_list;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    onPrint: function onPrint(type) {
      var _this6 = this;
      if (this.listLoading) {
        return false;
      }
      this.printType = type;
      this.listLoading = true;
      this.imgCount = 0;
      this.loadImgCount = 0;
      var imgCount = 0;
      this.printData = [];
      (0, _basic.getAuctionAuditPrintListApi)(this.tableFrom).then(function (res) {
        var arr = [];
        res.data.list.forEach(function (m, i) {
          var image = '';
          if (m.warehouse_image[0]) {
            image = m.warehouse_image[0];
          } else if (m.contract_image[0]) {
            image = m.contract_image[0];
          }
          m.image = image;
          if (m.image) {
            imgCount += 1;
          }
          var oneIndex = parseInt(i / 20, 10);
          var index = i % 20;
          if (type === 2) {
            oneIndex = parseInt(i / 9, 10);
            index = i % 9;
          }
          if (index === 0) {
            arr[oneIndex] = [m];
          } else {
            arr[oneIndex][index] = m;
          }
        });
        _this6.imgCount = imgCount;
        _this6.printData = arr;
        if (imgCount == 0) {
          _this6.listLoading = false;
          _this6.$nextTick(function () {
            (0, _printJs.default)({
              printable: 'printorder',
              type: 'html',
              header: null,
              targetStyles: ['*'],
              scanStyles: true,
              maxWidth: 1000,
              font_size: 'auto',
              style: "@page { margin: 5mm 10mm 12mm;size: auto; } .paging{page-break-after: always;}"
            });
          });
        }
      });
    },
    loadImgEnd: function loadImgEnd() {
      console.log(this.loadImgCount, this.imgCount);
      if (++this.loadImgCount == this.imgCount) {
        this.listLoading = false;
        this.$nextTick(function () {
          (0, _printJs.default)({
            printable: 'printorder',
            type: 'html',
            header: null,
            targetStyles: ['*'],
            scanStyles: true,
            maxWidth: 1000,
            font_size: 'auto',
            style: "@page { margin: 5mm 10mm 12mm;size: auto; } .paging{page-break-after: always;}"
          });
        });
      }
    },
    onUpdStatus: function onUpdStatus(row, status) {
      var _this7 = this;
      (0, _basic.auditSwitchStatus)(row.id, {
        field_key: 'auction_audit_status',
        field_value: status
      }).then(function (res) {
        _this7.$message.success('操作成功');
        _this7.getList(1);
      });
    },
    onRemove: function onRemove(row) {
      var _this8 = this;
      this.$confirm("\u786E\u8BA4\u79FB\u9664?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _basic.auditDelete)({
          ids: row.id
        }).then(function (res) {
          _this8.$message.success('操作成功');
          _this8.getList(1);
        });
      }).catch(function () {});
    },
    onRemoveAll: function onRemoveAll() {
      var _this9 = this;
      this.$confirm("\u786E\u8BA4\u6279\u91CF\u79FB\u9664?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        // allCheck ? total - noChecked.length : checkedIds.length
        var params = {};
        if (_this9.allCheck) {
          params = Object.assign({}, _this9.tableFrom);
          params.no_checked = _this9.noChecked.join(',');
        } else {
          if (!_this9.checkedIds.length) {
            _this9.$message.error('请先选择要移除的货品');
            return false;
          }
          params.ids = _this9.checkedIds.join(',');
        }
        (0, _basic.auditDelete)(params).then(function (res) {
          _this9.$message.success('操作成功');
          _this9.noChecked = [];
          _this9.checkedIds = [];
          _this9.allCheck = false;
          _this9.getList(1);
        });
      }).catch(function () {});
    },
    editLabelAll: function editLabelAll() {
      var params = {};
      if (this.allCheck) {
        params = Object.assign({}, this.tableFrom);
        params.no_checked = this.noChecked.join(',');
      } else {
        if (!this.checkedIds.length) {
          this.$message.error('请先选择要移除的货品');
          return false;
        }
        params.ids = this.checkedIds.join(',');
      }
      this.setLabelData = {
        show: true,
        labelIds: '',
        params: params
      };
    },
    saveLabelAction: function saveLabelAction() {
      var _this10 = this;
      if (!this.setLabelData.labelIds) {
        this.$message.error('请选择标签');
        return false;
      }
      this.$confirm("\u786E\u8BA4\u6279\u91CF\u6DFB\u52A0\u6807\u7B7E?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        // allCheck ? total - noChecked.length : checkedIds.length
        var params = Object.assign({}, _this10.setLabelData.params);
        params.type = 1;
        params.field_key = 'label_ids';
        params.field_value = _this10.setLabelData.labelIds.join(',');
        _this10.noChecked = [];
        _this10.checkedIds = [];
        _this10.allCheck = false;
        (0, _basic.batchUpdateField)(params).then(function (res) {
          _this10.$message.success('操作成功');
          _this10.getList(1);
          _this10.clearSaveLabelAction();
        });
      }).catch(function () {});
    },
    clearSaveLabelAction: function clearSaveLabelAction() {
      this.setLabelData = {
        show: false,
        labelIds: '',
        params: ''
      };
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.tableFrom = Object.assign({
        is_auction_audit: 1,
        page: 1,
        limit: this.tableFrom.limit
      }, e);
      this.getList(1);
    },
    searchattrAction: function searchattrAction(e) {
      var json = e;
      if (Object.keys(e).length == 0) {
        if (Object.keys(this.searchQuery).length == 0) {
          json = {};
        } else if (Object.keys(this.searchQuery).length == 1) {
          if (this.searchQuery['1']) {
            json = {};
            this.searchQuery = {};
          } else {
            this.attributejson = {
              '1': 2
            };
          }
        }
      } else {
        this.$set(this.searchQuery, '1', 2);
      }
      this.attributejson = json;
      var newattributejson = JSON.parse(JSON.stringify(this.attributejson));
      delete newattributejson['1'];
      this.tableFrom.attributejson = newattributejson;
      this.getList(1);
    },
    getList: function getList(num) {
      var _this11 = this;
      this.listLoading = true;
      this.tableFrom.page = num || this.tableFrom.page;
      (0, _basic.getgoodsListV2Api)(this.tableFrom).then(function (res) {
        res.data.list.forEach(function (item, index) {
          item.image = item.warehouse_image.length ? item.warehouse_image : item.contract_image;
          item.sortindex = index;
          if (item.label_ids) {
            var label_ids = item.label_ids.split(',').map(function (item) {
              return Number(item);
            });
            var label_id_arr = [];
            var label_name_arr = [];
            var no_has_label_id = [];
            label_ids.forEach(function (m) {
              if (_this11.labeljson[m]) {
                label_name_arr.push(_this11.labeljson[m]);
                label_id_arr.push(m);
              } else {
                no_has_label_id.push(m);
              }
            });
            item.label_id_arr = label_id_arr;
            item.newlabel = label_name_arr.join(',');
            item.no_has_label_id = no_has_label_id;
          } else {
            item.label_id_arr = [];
            item.newlabel = [];
          }
          if (item.storeCategoryParent) {
            item.newcate = item.storeCategoryParent.map(function (item) {
              return item.cate_name;
            }).join('>');
          }
          item.attrjson = item.attribute || {};
        });
        _this11.olddata = JSON.parse(JSON.stringify(res.data.list));
        _this11.tableData = res.data.list;
        _this11.total = res.data.count;
        _this11.listLoading = false;
      });
    },
    selectblur: function selectblur(e, index, field, id, row) {
      if (!e) {
        this.saveoneflied(index, field, id, row);
      }
    },
    saveoneflied: function saveoneflied(index, type, id, row, am) {
      var _this12 = this;
      // console.log(index, type, id, row, am);
      var value = '';
      var newtype = '';
      this.inputname = '';
      this.inputITF = '';
      newtype = type;
      var oldrow = {};
      oldrow = this.olddata.filter(function (item) {
        return item.id == id;
      })[0] || {};
      if (type == 'brand') {
        newtype = 'brand_name';
        if (row.brand) {
          value = row.brand.brand_name;
        } else {
          value = row.newbrand;
        }
      } else if (type == 'content') {
        if (row.content == oldrow.content) {
          return;
        }
        oldrow.content = row.content;
        value = row.content;
      } else if (type == 'category') {
        // console.log(am);
        if (row.cate_id == oldrow.cate_id) {
          return;
        }
        oldrow.cate_id = row.cate_id;
        newtype = 'cate_id';
        value = row.cate_id;
        var pcatename = '';
        if (this.merCateJson[row.cate_id]) {
          pcatename = this.merCateJson[row.cate_id].cate_name;
          if (this.merCateJson[row.cate_id].parent) {
            pcatename = this.merCateJson[row.cate_id].parent.cate_name + '>' + pcatename;
          }
        }
        row.newcate = pcatename;
      } else if (type == 'label') {
        if (row.label_id_arr.join(',') == oldrow.label_id_arr.join(',')) {
          return;
        }
        oldrow.label_id_arr = row.label_id_arr;
        newtype = 'label_ids', value = row.label_id_arr.concat(row.no_has_label_id).join(',');
        row.newlabel = row.label_id_arr.map(function (item) {
          return _this12.labeljson[item];
        }).join(',');
      } else if (type == 'attribute') {
        if (row.attrjson[am] == oldrow.attrjson[am]) {
          return;
        }
        oldrow.attrjson[am] = row.attrjson[am];
        (0, _basic.editcellgoodsV2)(id, {
          field_key: newtype,
          field_value: "".concat(am, ":").concat(row.attrjson[am] || '')
        }).then(function (res) {});
        return;
      } else {
        if (row[type] == oldrow[type]) {
          return;
        }
        oldrow[type] = row[type];
        value = row[type];
      }
      (0, _basic.editcellgoodsV2)(id, {
        field_key: newtype,
        field_value: value
      }).then(function (res) {});
    },
    checkinput: function checkinput(index, type, id, row, am) {
      if (type == 'attribute') {
        if (!row.attrjson[am]) {
          return;
        }
        // .replace(/(\r\n|\n|\r)/gm, '')
        row.attrjson[am] = row.attrjson[am].replaceAll('\"', '');
      } else {
        // .replace(/(\r\n|\n|\r)/gm, '')
        row[type] = row[type].replaceAll('\"', '');
      }
    },
    click: function click(index, type, id, row, am) {
      this.copyForm = {
        index: index,
        type: type,
        id: id,
        row: row,
        am: am || ''
      };
    },
    // 商户分类；
    getCategorySelect: function getCategorySelect() {
      var _this13 = this;
      (0, _product.categorySelectApi)().then(function (res) {
        _this13.merCateList = res.data;
        var json = {};
        res.data.forEach(function (item) {
          json[item.store_category_id] = {
            cate_name: item.cate_name
          };
          if (item.children) {
            item.children.forEach(function (child) {
              json[child.store_category_id] = {
                cate_name: child.cate_name
              };
              json[child.store_category_id].parent = {
                cate_name: item.cate_name
              };
            });
          }
        });
        _this13.merCateJson = json;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    onHandle: function onHandle(name) {
      this.chkName = this.chkName === name ? '' : name;
      this.changeType(!(this.chkName === ''));
    },
    changeType: function changeType(v) {
      if (v) {
        if (!this.chkName) {
          this.chkName = 'dan';
        }
      } else {
        this.chkName = '';
        this.allCheck = false;
      }
      var index = this.checkedPage.indexOf(this.tableFrom.page);
      if (this.chkName === 'dan') {
        this.checkedPage.push(this.tableFrom.page);
      } else if (index > -1) {
        this.checkedPage.splice(index, 1);
      }
      this.syncCheckedId();
    },
    syncCheckedId: function syncCheckedId() {
      var _this14 = this;
      var ids = this.tableData.map(function (v) {
        return v.id;
      });
      if (this.chkName === 'duo') {
        this.checkedIds = [];
        this.allCheck = true;
      } else if (this.chkName === 'dan') {
        this.allCheck = false;
        ids.forEach(function (id) {
          var index = _this14.checkedIds.indexOf(id);
          if (index === -1) {
            _this14.checkedIds.push(id);
          }
        });
      } else {
        ids.forEach(function (id) {
          var index = _this14.checkedIds.indexOf(id);
          if (index > -1) {
            _this14.checkedIds.splice(index, 1);
          }
        });
      }
    },
    // 分开选择
    changeOne: function changeOne(v, user) {
      if (v) {
        if (this.chkName === 'duo') {
          var index = this.noChecked.indexOf(user.id);
          if (index > -1) this.noChecked.splice(index, 1);
        } else {
          var _index = this.checkedIds.indexOf(user.id);
          if (_index === -1) this.checkedIds.push(user.id);
        }
      } else {
        if (this.chkName === 'duo') {
          var _index2 = this.noChecked.indexOf(user.id);
          if (_index2 === -1) this.noChecked.push(user.id);
        } else {
          var _index3 = this.checkedIds.indexOf(user.id);
          if (_index3 > -1) this.checkedIds.splice(_index3, 1);
        }
      }
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    }
  }
};