var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "porBox" }, [
      _c(
        "div",
        { staticClass: "absoBox" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.onAdd },
            },
            [_vm._v("添加货品标签")]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { height: "calc(100vh - 175px)" } },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              border: "",
              data: _vm.tableData.data,
              height: "100%",
              size: "small",
              "highlight-current-row": "",
            },
          },
          [
            _c("template", { slot: "empty" }, [
              _c("div", { staticClass: "tableEmpty" }, [
                _c("img", {
                  attrs: {
                    src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
              ]),
            ]),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "ID", "min-width": "60" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$route.path.indexOf("group") !== -1
                              ? row.group_id
                              : row.label_id
                          ),
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label:
                  _vm.$route.path.indexOf("group") !== -1
                    ? "分组名称"
                    : "标签名称",
                "min-width": "180",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$route.path.indexOf("group") !== -1
                              ? row.group_name
                              : row.label_name
                          ),
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _vm.$route.path.indexOf("group") === -1
              ? _c("el-table-column", {
                  attrs: { label: "关联页面", "min-width": "150" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.pageDataText && row.pageDataText.length
                              ? _c("span", [
                                  _vm._v(_vm._s(row.pageDataText.join(","))),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    24897270
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "create_time",
                label: "创建时间",
                "min-width": "150",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "操作",
                width: "100",
                fixed: "right",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              _vm.onEdit(
                                _vm.$route.path.indexOf("group") !== -1
                                  ? scope.row.group_id
                                  : scope.row.label_id
                              )
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              _vm.handleDelete(
                                _vm.$route.path.indexOf("group") !== -1
                                  ? scope.row.group_id
                                  : scope.row.label_id,
                                scope.$index
                              )
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          2
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "paginaBox" },
      [
        _c("el-pagination", {
          attrs: {
            background: "",
            "page-sizes": [20, 40, 60, 80],
            "page-size": _vm.tableFrom.limit,
            "current-page": _vm.tableFrom.page,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.tableData.total,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.pageChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }