var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "posterMain" },
    [
      _c("posterHeader"),
      _vm._v(" "),
      _c("div", { staticClass: "flex justify-start" }, [
        _c(
          "div",
          { staticClass: "poster-type-container" },
          [
            _c(
              "div",
              {
                staticClass: "poster-type-itemOne",
                class: _vm.activeType == "" ? "type-active" : "",
                on: {
                  click: function ($event) {
                    return _vm.typeChange("")
                  },
                },
              },
              [_vm._v("全部")]
            ),
            _vm._v(" "),
            _vm._l(_vm.temTypeList, function (item) {
              return _c(
                "div",
                {
                  key: item.type,
                  staticClass: "poster-type-itemOne",
                  class: _vm.activeType == item.type ? "type-active" : "",
                  on: {
                    click: function ($event) {
                      return _vm.typeChange(item.type)
                    },
                  },
                },
                [_vm._v(_vm._s(item.name))]
              )
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "nodi", staticStyle: { "overflow-x": "auto" } },
        [
          _c(
            "div",
            {
              staticClass: "poster-type-container",
              staticStyle: { "margin-top": "0" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "poster-type-item",
                  class: _vm.activeTag == "" ? "type-activeOne" : "",
                  on: {
                    click: function ($event) {
                      return _vm.tagChange("")
                    },
                  },
                },
                [_vm._v("全部")]
              ),
              _vm._v(" "),
              _vm._l(_vm.tagList, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "poster-type-item",
                    class: _vm.activeTag == item.id ? "type-activeOne" : "",
                    on: {
                      click: function ($event) {
                        return _vm.tagChange(item.id)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c("grid-manager", {
        attrs: {
          gridList: _vm.templist,
          footHeight: 35,
          "is-loading": _vm.loadTemplate,
        },
        on: { loadMore: _vm.onloadMore },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  { staticClass: "img-box" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "hb-conStatus",
                        on: {
                          click: function ($event) {
                            return _vm.onClickTempl(row)
                          },
                        },
                      },
                      [_vm._v("做同款")]
                    ),
                    _vm._v(" "),
                    _vm.type_ids.includes(row.template_id + "")
                      ? _c(
                          "div",
                          {
                            staticClass: "position-img",
                            staticStyle: { right: "10px" },
                            on: {
                              click: function ($event) {
                                return _vm.onDelCollect(row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content: "收藏",
                                  placement: "top",
                                  enterable: false,
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/poster/sc.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "position-img",
                            staticStyle: { right: "10px" },
                            on: {
                              click: function ($event) {
                                return _vm.onCollect(row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content: "收藏",
                                  placement: "top",
                                  enterable: false,
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/poster/scBlack.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                    _vm._v(" "),
                    _c("el-image", {
                      staticClass: "posIimg",
                      attrs: {
                        src: "" + row.preview_image,
                        "preview-src-list": [row.preview_image],
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "flex possname" }, [
                  _c(
                    "div",
                    { staticClass: "flex-one flex align-items-c" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: row.level == 2,
                              expression: "row.level == 2",
                            },
                          ],
                          attrs: {
                            content: "高级",
                            placement: "top",
                            effect: "light",
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "leveImg",
                            attrs: {
                              src: require("@/assets/images/poster/leve2.png"),
                              alt: "",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: row.level == 3,
                              expression: "row.level == 3",
                            },
                          ],
                          attrs: {
                            content: "定制",
                            placement: "top",
                            effect: "light",
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "leveImg",
                            attrs: {
                              src: require("@/assets/images/poster/leveg.png"),
                              alt: "",
                            },
                          }),
                        ]
                      ),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.posterNameFilter(row)) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("createPoster", {
        ref: "createPosterRef",
        on: { onRefresh: _vm.onRefresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }