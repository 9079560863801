var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.collectMoney.show,
        title: "",
        top: "5vh",
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.collectMoney, "show", $event)
        },
      },
    },
    [
      _c("div", { staticClass: "all-h-print" }, [
        _vm.collectMoney.show
          ? _c(
              "div",
              { attrs: { id: "printorder" } },
              [
                _c("div", { staticClass: "detail-box" }, [
                  _c("div", { staticClass: "detail-left" }, [
                    _c("div", { staticClass: "detail-left-title" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.merData.company_name) +
                          " | " +
                          _vm._s(_vm.detail.title.china) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "detail-left-desc",
                        staticStyle: {
                          "font-size": "14px",
                          "font-family": "'printEN'",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.merData.company_english_name) +
                            " " +
                            _vm._s(_vm.detail.title.english)
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "secTit" }, [
                  _vm._v(_vm._s(_vm.detail.buyerCompany.china)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-left-desc",
                    staticStyle: {
                      "font-size": "13px",
                      "font-family": "'printEN'",
                      "font-weight": "500",
                      "word-break": "break-word",
                    },
                  },
                  [_vm._v(_vm._s(_vm.detail.buyerCompany.english))]
                ),
                _vm._v(" "),
                _vm.collectMoney.data.venueInfo
                  ? _c(
                      "div",
                      {
                        staticClass: "detail-left-title",
                        staticStyle: { "margin-top": "40px" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.collectMoney.data.venueInfo.title) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-list",
                    staticStyle: { "justify-content": "start" },
                  },
                  [
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.switchLang("SellerName")) + " SELLER'S NAME"
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-weight": "500",
                            "font-size": "16px",
                            "margin-left": "10px",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.collectMoney.data.entrustUser.real_name)
                          ),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-left": "10px",
                            "font-weight": "500",
                            "font-size": "16px",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.collectMoney.data.entrustUser.originPhone
                            )
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "all-print-main-el" }),
                _vm._v(" "),
                _vm.isNo
                  ? _c(
                      "div",
                      { staticClass: "ptd-box" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "ptd",
                            staticStyle: { "font-weight": "bold" },
                          },
                          [
                            _vm._l(_vm.detail.tableColumn, function (m, i) {
                              return [
                                m.open
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          key: "col" + i,
                                          staticClass: "ptd-title",
                                          style: m.w
                                            ? "width: " + m.w + "px;"
                                            : "flex: 1",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(m.showName || m.name)
                                            ),
                                          ]),
                                          m.english
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-family": "'printEN'",
                                                    "white-space": "nowrap",
                                                  },
                                                },
                                                [_vm._v(_vm._s(m.english))]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm._l(
                          _vm.collectMoney.data.auctionList,
                          function (m, i) {
                            return _c(
                              "div",
                              {
                                key: "order" + i,
                                staticClass: "ptd calcul",
                                staticStyle: { "font-weight": "600" },
                              },
                              [
                                _vm.detail.tableColumn[0].open
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        style:
                                          "width: " +
                                          _vm.detail.tableColumn[0].w +
                                          "px;",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            m.erpProduct &&
                                              m.erpProduct.contract
                                              ? m.erpProduct.contract
                                                  .contract_no
                                              : ""
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.detail.tableColumn[1].open
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        style:
                                          "width: " +
                                          _vm.detail.tableColumn[1].w +
                                          "px;",
                                      },
                                      [_vm._v(_vm._s(m.auction.lot))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.detail.tableColumn[2].open
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        style: "flex: 1;",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(m.auction.product.store_name)
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.detail.tableColumn[3].open
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        style:
                                          "width: " +
                                          _vm.detail.tableColumn[3].w +
                                          "px;",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatPriceNumber(
                                              m.erpProduct.retain_price,
                                              2
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.detail.tableColumn[4].open
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        style:
                                          "width: " +
                                          _vm.detail.tableColumn[4].w +
                                          "px;",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatPriceNumber(
                                              m.erpProduct.take_home_price,
                                              2
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.detail.tableColumn[5].open
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        style:
                                          "width: " +
                                          _vm.detail.tableColumn[5].w +
                                          "px;",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatPriceNumber(
                                              m.auction.now_price,
                                              2
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.detail.tableColumn[6].open
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        style:
                                          "width: " +
                                          _vm.detail.tableColumn[6].w +
                                          "px;",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            m.auction.auction_status > 4
                                              ? "成交"
                                              : "流拍"
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.detail.tableColumn[7].open
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        style:
                                          "width: " +
                                          _vm.detail.tableColumn[7].w +
                                          "px;",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(m.auction.album.album_name)
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ptd",
                            staticStyle: {
                              "border-left": "2px solid #000",
                              "border-right": "2px solid #000",
                              "font-weight": "600",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: {
                                  flex: "2",
                                  "flex-direction": "column",
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.switchLang("Total"))),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: { "font-family": "'printEN'" },
                                  },
                                  [_vm._v("TOTAL")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: {
                                  flex: "1",
                                  "flex-direction": "column",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.collectMoney.data.auctionList.length
                                  ) + "件"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { flex: "2" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.blTotal,
                                      2
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { flex: "2" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.sdTotal,
                                      2
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "ptd-body",
                                staticStyle: { flex: "2" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      _vm.collectMoney.lcTotal,
                                      2
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "1" },
                            }),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "ptd-body",
                              staticStyle: { flex: "2" },
                            }),
                          ]
                        ),
                      ],
                      2
                    )
                  : [
                      _vm._l(_vm.result, function (item, ind) {
                        return _c(
                          "div",
                          {
                            key: ind,
                            staticClass: "ptd-box",
                            style:
                              ind < _vm.result.length - 1
                                ? "page-break-after: always;"
                                : "",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "ptd",
                                staticStyle: { "font-weight": "bold" },
                              },
                              [
                                _vm._l(_vm.detail.tableColumn, function (m, i) {
                                  return [
                                    m.open
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              key: "col" + i,
                                              staticClass: "ptd-title",
                                              style: m.w
                                                ? "width: " + m.w + "px;"
                                                : "flex: 1",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(m.showName || m.name)
                                                ),
                                              ]),
                                              m.english
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-family":
                                                          "'printEN'",
                                                        "white-space": "nowrap",
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(m.english))]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                  ]
                                }),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _vm._l(item, function (m, i) {
                              return _c(
                                "div",
                                {
                                  key: "order" + i,
                                  staticClass: "ptd",
                                  staticStyle: { "font-weight": "600" },
                                },
                                [
                                  _vm.detail.tableColumn[0].open
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          style:
                                            "width: " +
                                            _vm.detail.tableColumn[0].w +
                                            "px;",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              m.erpProduct &&
                                                m.erpProduct.contract
                                                ? m.erpProduct.contract
                                                    .contract_no
                                                : ""
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.detail.tableColumn[1].open
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          style:
                                            "width: " +
                                            _vm.detail.tableColumn[1].w +
                                            "px;",
                                        },
                                        [_vm._v(_vm._s(m.auction.lot))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.detail.tableColumn[2].open
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          style: "flex: 1;",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(m.auction.product.store_name)
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.detail.tableColumn[3].open
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          style:
                                            "width: " +
                                            _vm.detail.tableColumn[3].w +
                                            "px;",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatPriceNumber(
                                                m.erpProduct.retain_price,
                                                2
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.detail.tableColumn[4].open
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          style:
                                            "width: " +
                                            _vm.detail.tableColumn[4].w +
                                            "px;",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatPriceNumber(
                                                m.erpProduct.take_home_price,
                                                2
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.detail.tableColumn[5].open
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          style:
                                            "width: " +
                                            _vm.detail.tableColumn[5].w +
                                            "px;",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatPriceNumber(
                                                m.auction.now_price,
                                                2
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.detail.tableColumn[6].open
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          style:
                                            "width: " +
                                            _vm.detail.tableColumn[6].w +
                                            "px;",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              m.auction.auction_status > 4
                                                ? "成交"
                                                : "流拍"
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.detail.tableColumn[7].open
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ptd-body",
                                          style:
                                            "width: " +
                                            _vm.detail.tableColumn[7].w +
                                            "px;",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(m.auction.album.album_name)
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                          ],
                          2
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ptd",
                          staticStyle: {
                            "border-left": "2px solid #000",
                            "border-right": "2px solid #000",
                            "font-weight": "600",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              style:
                                "width: " +
                                _vm.detail.tableColumn[0].w +
                                "px;flex-direction: column;",
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.switchLang("Total"))),
                              ]),
                              _c(
                                "span",
                                { staticStyle: { "font-family": "'printEN'" } },
                                [_vm._v("TOTAL")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ptd-body",
                              style:
                                "width: " +
                                _vm.detail.tableColumn[1].w +
                                "px;flex-direction: column;",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.collectMoney.data.auctionList.length
                                ) + "件"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "ptd-body",
                            staticStyle: { flex: "1" },
                          }),
                          _vm._v(" "),
                          _vm.detail.tableColumn[3].open
                            ? _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  style:
                                    "width: " +
                                    _vm.detail.tableColumn[3].w +
                                    "px;",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        _vm.collectMoney.blTotal,
                                        2
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.detail.tableColumn[4].open
                            ? _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  style:
                                    "width: " +
                                    _vm.detail.tableColumn[4].w +
                                    "px;",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        _vm.collectMoney.sdTotal,
                                        2
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.detail.tableColumn[5].open
                            ? _c(
                                "div",
                                {
                                  staticClass: "ptd-body",
                                  style:
                                    "width: " +
                                    _vm.detail.tableColumn[5].w +
                                    "px;",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatPriceNumber(
                                        _vm.collectMoney.lcTotal,
                                        2
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.detail.tableColumn[6].open
                            ? _c("div", {
                                staticClass: "ptd-body",
                                style:
                                  "width: " +
                                  _vm.detail.tableColumn[6].w +
                                  "px;",
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.detail.tableColumn[7].open
                            ? _c("div", {
                                staticClass: "ptd-body",
                                style:
                                  "width: " +
                                  _vm.detail.tableColumn[7].w +
                                  "px;",
                              })
                            : _vm._e(),
                        ]
                      ),
                    ],
                _vm._v(" "),
                _c("div", { staticClass: "edit-view" }, [
                  _c(
                    "div",
                    {
                      staticClass: "secTit",
                      staticStyle: { "margin-top": "30px" },
                    },
                    [_vm._v(_vm._s(_vm.detail.settleText.china))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "detail-left-desc",
                      staticStyle: {
                        "font-size": "13px",
                        "font-family": "'printEN'",
                        "font-weight": "500",
                        "word-break": "normal",
                      },
                    },
                    [_vm._v(_vm._s(_vm.detail.settleText.english))]
                  ),
                ]),
                _vm._v(" "),
                _vm.detail.signColumnTab == "system"
                  ? _c(
                      "div",
                      {
                        staticClass: "ptd-bottom",
                        staticStyle: { "margin-top": "30px" },
                        attrs: { id: "ptd-bottom" },
                      },
                      [
                        _c("div", { staticClass: "ptd-bottom-left" }, [
                          _c("div", { staticClass: "ptd-bottom-left-list" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.switchLang("CompanyAddress")) +
                                "：" +
                                _vm._s(_vm.merData.config.company_address) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "ptd-bottom-left-list" }, [
                            _vm._v(
                              "\n            Company Address: " +
                                _vm._s(
                                  _vm.merData.config.company_english_address ||
                                    ""
                                ) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "ptd-bottom-left-list" }, [
                            _vm._v(
                              _vm._s(_vm.switchLang("CompanyPhone")) +
                                "/TEL： " +
                                _vm._s(_vm.merData.config.contact_phone)
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "ptd-bottom-right" },
                          [
                            _vm._l(
                              _vm.merData.config.mer_qrcode_data,
                              function (item, index) {
                                return [
                                  item.imgUrl
                                    ? _c(
                                        "div",
                                        {
                                          key: "rcimg" + index,
                                          staticClass: "ptd-bottom-img-list",
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              crossorigin: "anonymous",
                                              src: item.imgUrl + "!120a",
                                              alt: "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "ptd-bottom-img-list-title",
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "detail-list",
                        staticStyle: {
                          "margin-top": "30px",
                          "flex-wrap": "wrap",
                        },
                      },
                      [
                        _c("div", {
                          staticStyle: { flex: "1", overflow: "hidden" },
                          domProps: {
                            innerHTML: _vm._s(_vm.detail.signColumnCustome),
                          },
                        }),
                      ]
                    ),
              ],
              2
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "default" },
              on: { click: _vm.bindPrintorder },
            },
            [_vm._v("打印")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }