"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user.js");
var _system = require("@/api/system.js");
var _index = require("@/utils/index");
var _order = require("@/api/order");
var _printJs = _interopRequireDefault(require("print-js"));
var _zhTW = _interopRequireDefault(require("@/utils/zh-TW.json"));
var _system2 = require("@/api/system");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default
  },
  filters: {
    filterPercent: function filterPercent(val) {
      var remainder = val % 10;
      if (remainder > 0) val;else return val / 10;
    }
  },
  props: {
    preview: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      parseTime: _index.parseTime,
      ids: '',
      uid: '',
      source: '',
      formatPriceNumber: _index.formatPriceNumber,
      collectMoney: {
        show: false,
        data: ''
      },
      total_price: 0,
      printObj2: {
        id: 'print1',
        popTitle: ''
      },
      fee_type: '',
      feeTypeInfo: {},
      isOutbound: false,
      qrcodeImg: '',
      merData: {},
      no: '',
      showSettings: false,
      settingKey: '',
      detail: {
        title: {
          china: '收款结算账单',
          english: 'Collection settlement bill'
        },
        buyerCompany: {
          china: '您好！非常感谢您对我司的厚爱。现将以下拍品交付给您，期待您一如既往的支持，谢谢！',
          english: "Thank you for your support. We're pleased to inform you that the following items have been delivered. We appreciate your kindness and understanding, and we look forward to your continued partnership!"
        },
        buyerColumn: {
          buyerName: {
            open: true,
            name: '买受人姓名',
            showName: "\u4E70\u53D7\u4EBA\u59D3\u540D BUYER'S NAME",
            w: 'auto'
          },
          number: {
            open: true,
            name: '号牌',
            showName: '号牌 PADDLE NO.',
            w: 'auto'
          },
          date: {
            open: true,
            name: '交易日期',
            showName: '交易日期 DATE OF SALE',
            w: 'auto'
          },
          address: {
            open: true,
            name: '收货人地址',
            showName: '',
            w: 'auto'
          },
          address_user_phone: {
            open: true,
            name: '收货人手机号',
            showName: '',
            w: 'auto'
          },
          address_real_name: {
            open: true,
            name: '收货人姓名',
            showName: '',
            w: 'auto'
          }
        },
        tableColumn: [{
          open: true,
          name: '图录号',
          showName: '',
          english: 'LOT NO.',
          w: 86
        }, {
          open: true,
          name: '拍品名称',
          showName: '',
          english: 'DESCRIPTION'
        }, {
          open: true,
          name: '落槌价',
          showName: '',
          english: 'HAMMER PRICE',
          w: 130
        }, {
          open: true,
          name: '佣金',
          showName: '',
          english: 'COMMISSION',
          w: 130
        }, {
          open: true,
          name: '其他',
          showName: '',
          english: 'OTHER',
          w: 130
        }, {
          open: true,
          name: '金额',
          showName: '',
          english: 'SUBTOTAL',
          w: 130
        }],
        settleText: {
          china: '* 本人已验收全部拍品，确认无误，本人签字表示同意。',
          english: 'I confirm that all the items listed above are in good condition after inspection, and I hereby accept delivery of the items.'
        },
        signColumnTab: 'system',
        signColumn: {
          accountName: {
            open: true,
            name: '开户名称',
            showName: '',
            w: 'auto'
          },
          accountBank: {
            open: true,
            name: '开户行',
            showName: '',
            w: 'auto'
          },
          accountNumber: {
            open: true,
            name: '帐号',
            showName: '',
            w: 'auto'
          },
          accountAddress: {
            open: true,
            name: '公司地址',
            showName: '',
            w: '100'
          },
          documentNumber: {
            open: true,
            name: '单据编号',
            showName: '',
            w: '25'
          },
          creator: {
            open: true,
            name: '制单人',
            showName: '',
            w: '25'
          },
          recipient: {
            open: true,
            name: '收款人',
            showName: '',
            w: '25'
          },
          reviewer: {
            open: false,
            name: '复核人',
            showName: '',
            w: '25'
          },
          companySeal: {
            open: false,
            name: '单位盖章',
            showName: '',
            w: '25'
          },
          printTime: {
            open: true,
            name: '打印时间',
            showName: '',
            w: '25'
          }
        },
        signColumnCustome: ''
      },
      editKey: ''
    };
  },
  computed: {
    feeTypeObj: function feeTypeObj() {
      return this.$store.getters.feeTypeInfo;
    },
    getWidth: function getWidth() {
      return 'width: 130px;';
    },
    getForeWidth: function getForeWidth() {
      var w = 0;
      if (this.detail.tableColumn[2] && this.detail.tableColumn[2].open && this.detail.tableColumn[2].w) {
        w += this.detail.tableColumn[2].w;
      }
      if (this.detail.tableColumn[3] && this.detail.tableColumn[3].open && this.detail.tableColumn[3].w) {
        w += this.detail.tableColumn[3].w;
      }
      if (this.detail.tableColumn[4] && this.detail.tableColumn[4].open && this.detail.tableColumn[4].w) {
        w += this.detail.tableColumn[4].w;
      }
      if (this.detail.tableColumn[5] && this.detail.tableColumn[5].open && this.detail.tableColumn[5].w) {
        w += this.detail.tableColumn[5].w;
      }
      return "width: ".concat(w, "px;");
    }
  },
  mounted: function mounted() {
    var _this = this;
    (0, _user.getBaseInfo)().then(function (res) {
      res.data.company_english_name = res.data.config.company_english_name;
      _this.merData = res.data;
    });
    (0, _system2.getConfigClassKeys)('print_config').then(function (res) {
      if (res.data.print_auction_order_bill_info) {
        var d = JSON.parse(res.data.print_auction_order_bill_info);
        if (!d.signColumnTab) d.signColumnTab = 'system';
        if (!d.title) {
          d.title = {
            china: '收款结算账单',
            english: 'Collection settlement bill'
          };
        }
        if (!d.buyerColumn || !d.buyerColumn.address_user_phone) {
          d.buyerColumn = {
            buyerName: {
              open: true,
              name: '买受人姓名',
              showName: "\u4E70\u53D7\u4EBA\u59D3\u540D BUYER'S NAME",
              w: 'auto'
            },
            number: {
              open: true,
              name: '号牌',
              showName: '号牌 PADDLE NO.',
              w: 'auto'
            },
            date: {
              open: true,
              name: '交易日期',
              showName: '交易日期 DATE OF SALE',
              w: 'auto'
            },
            address: {
              open: true,
              name: '收货人地址',
              showName: '',
              w: 'auto'
            },
            address_user_phone: {
              open: true,
              name: '收货人手机号',
              showName: '',
              w: 'auto'
            },
            address_real_name: {
              open: true,
              name: '收货人姓名',
              showName: '',
              w: 'auto'
            }
          };
        }
        // if (d.signColumnCustome) {
        //   this.$refs['editor'].setContent(d.signColumnCustome);
        // }
        _this.detail = d;
      }
    });
  },
  methods: {
    saveAction: function saveAction() {
      var _this2 = this;
      (0, _system2.configClassKeysSave)('print_config', {
        print_auction_order_bill_info: JSON.stringify(this.detail)
      }).then(function () {
        _this2.$message.success('保存成功');
      });
    },
    focus: function focus(e) {
      e.currentTarget.select();
    },
    goEditAction: function goEditAction(key) {
      this.settingKey = key;
      this.showSettings = true;
    },
    switchLang: function switchLang(filed) {
      if (this.feeTypeObj.mer_fee_type == 'HKD') {
        return _zhTW.default.TW[filed];
      } else return _zhTW.default.CN[filed];
    }
  }
};