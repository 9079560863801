var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb20" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "banner-control",
          attrs: {
            model: _vm.content,
            "label-width": "90px",
            size: "mini",
            "label-position": "left",
          },
        },
        [
          _vm.type == 1
            ? _c(
                "div",
                {
                  staticClass: "auction-select-add",
                  on: { click: _vm.handleAdditem },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-plus",
                    staticStyle: { "margin-right": "8px" },
                  }),
                  _vm._v(" "),
                  _c("a", { staticClass: "fontW500 ft16" }, [
                    _vm._v("选择拍品"),
                  ]),
                ]
              )
            : _vm.type == 2
            ? _c(
                "div",
                {
                  staticClass: "auction-select-add",
                  on: { click: _vm.uploadIconAction },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-plus",
                    staticStyle: { "margin-right": "8px" },
                  }),
                  _vm._v(" "),
                  _c("a", { staticClass: "fontW500 ft16" }, [
                    _vm._v("选择素材"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              { staticClass: "item-align-center mt20" },
              [
                _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                  _vm._v("数据选择"),
                ]),
                _vm._v(" "),
                _c(
                  "el-radio-group",
                  {
                    staticClass: "le-radio-group",
                    model: {
                      value: _vm.type,
                      callback: function ($$v) {
                        _vm.type = $$v
                      },
                      expression: "type",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 1 } }, [
                      _vm._v("选择拍品"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 2 } }, [
                      _vm._v("选择素材"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.content.data && _vm.content.data.length > 0
            ? _c(
                "div",
                { staticClass: "pt10" },
                [
                  _c(
                    "draggable",
                    _vm._b(
                      {
                        staticClass: "board-column-content flex flex-wrap",
                        attrs: { list: _vm.content.data },
                      },
                      "draggable",
                      _vm.$attrs,
                      false
                    ),
                    _vm._l(_vm.content.data, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "kuang1 item-align-center mt10",
                          staticStyle: { "padding-bottom": "0" },
                        },
                        [
                          _c("div", [
                            _c("img", {
                              staticStyle: {
                                width: "60px",
                                height: "60px",
                                "object-fit": "cover",
                                "border-radius": "4px",
                              },
                              attrs: { src: item.image },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-one ft12 ml10" }, [
                            _c("div", [_vm._v(_vm._s(item.name))]),
                          ]),
                          _vm._v(" "),
                          index === 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "go-down-img ml10",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goDown(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          index > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "go-up-img ml10",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goUp(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "del-img",
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(index)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("selectView", {
        ref: "selectView",
        attrs: { "has-more-data": "1", "all-image": "1", "current-data": [] },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }