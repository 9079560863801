var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt20 albumBox" },
    [
      _c("div", { staticClass: "porBox" }, [
        _c(
          "div",
          { staticClass: "absoBox" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.goLinkAction },
              },
              [_vm._v("链接")]
            ),
            _vm._v(" "),
            _c(
              "el-dropdown",
              { attrs: { type: "primary", placement: "top" } },
              [
                _c("el-button", { attrs: { type: "primary", size: "small" } }, [
                  _vm._v("导出记录"),
                ]),
                _vm._v(" "),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "el-dropdown-item",
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              width: "100%",
                              "text-align": "left",
                            },
                            attrs: { size: "small", type: "text" },
                            on: { click: _vm.getExportwwFileList },
                          },
                          [_vm._v("导出文物报备记录")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-item",
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              width: "100%",
                              "text-align": "left",
                            },
                            attrs: { size: "small", type: "text" },
                            on: { click: _vm.getExportFileList },
                          },
                          [_vm._v("导出拍品出价记录")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.venue_id
              ? _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: "merchantAuctionAlbumCreate",
                        expression: "'merchantAuctionAlbumCreate'",
                      },
                    ],
                    attrs: {
                      type: "primary",
                      size: "small",
                      icon: "el-icon-circle-plus-outline",
                    },
                    on: { click: _vm.createdAlbum },
                  },
                  [_vm._v("创建专场")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 175px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: {
                data: _vm.list,
                height: "100%",
                "element-loading-text": "Loading",
                fit: "",
                border: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "排序",
                  width: "65",
                  align: "center",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input-number", {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission:disabled",
                              value: "merchantAuctionAlbumEditOrderNum",
                              expression: "'merchantAuctionAlbumEditOrderNum'",
                              arg: "disabled",
                            },
                          ],
                          staticClass: "sortCol",
                          staticStyle: {
                            width: "50px",
                            "text-align": "center",
                          },
                          attrs: { min: 0, controls: false },
                          on: {
                            change: function (val) {
                              return _vm.editSortAction(val, scope.$index)
                            },
                            focus: function ($event) {
                              return _vm.focus($event)
                            },
                          },
                          model: {
                            value: scope.row.order_num,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "order_num", $$v)
                            },
                            expression: "scope.row.order_num",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "专场封面",
                  width: "80",
                  align: "center",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          staticStyle: { width: "30px" },
                          attrs: { src: scope.row.album_image + "!120a" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "状态",
                  width: "100",
                  prop: "",
                  "search-key": "status",
                  "search-type": "radio",
                  options: [
                    { label: "全部", value: "" },
                    { label: "进行中", value: "2" },
                    { label: "预展中", value: "1" },
                    { label: "已结束", value: "4" },
                  ],
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.album_status === 1
                          ? _c(
                              "el-tag",
                              { attrs: { size: "small", type: "danger" } },
                              [_vm._v("预展中")]
                            )
                          : scope.row.album_status === 2
                          ? _c("el-tag", { attrs: { size: "small" } }, [
                              _vm._v("进行中"),
                            ])
                          : scope.row.album_status === 3
                          ? _c(
                              "el-tag",
                              { attrs: { size: "small", type: "info" } },
                              [_vm._v("已结束")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.is_show === 0
                          ? _c(
                              "el-tag",
                              { attrs: { size: "small", type: "info" } },
                              [_vm._v("已隐藏")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "专场类型",
                  width: "100",
                  prop: "",
                  align: "center",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            scope.row.album_type == 1
                              ? [_vm._v("限时拍")]
                              : scope.row.album_type == 2
                              ? [_vm._v("即时拍")]
                              : scope.row.album_type == 3
                              ? [_vm._v("同步拍")]
                              : scope.row.album_type == 4
                              ? [_vm._v("即刻拍")]
                              : scope.row.album_type == 5
                              ? [_vm._v("抖音拍")]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              cursor: "pointer",
                              padding: "0 20px",
                            },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.goAuctionPage(
                                  scope.row.album_id,
                                  scope.row.album_type,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.auction_num) + "件")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "专场名称",
                  "min-width": "300",
                  prop: "",
                  "search-key": "keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.album_name))]),
                        _vm._v(" "),
                        scope.row.album_id
                          ? _c("div", [
                              _vm._v("ID:" + _vm._s(scope.row.album_id)),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.venue_id
                          ? _c("div", [
                              _vm._v(
                                "所属拍卖会：" + _vm._s(scope.row.venue.title)
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "拍卖时间",
                  "min-width": "220",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.scheduled_show_time != 0
                          ? _c("div", [
                              _c("span", { staticStyle: { color: "#999" } }, [
                                _vm._v("预展："),
                              ]),
                              _vm._v(
                                _vm._s(
                                  _vm.formatDate(
                                    scope.row.scheduled_show_time * 1000,
                                    "{y}-{m}-{d} {h}:{i}"
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", [
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v("开拍："),
                          ]),
                          _vm._v(
                            _vm._s(
                              _vm.formatDate(
                                scope.row.album_start_time * 1000,
                                "{y}-{m}-{d} {h}:{i}"
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        scope.row.album_type === 1 &&
                        scope.row.end_type === 2 &&
                        scope.row.album_status !== 3
                          ? _c("div", [
                              _c("span", { staticStyle: { color: "#999" } }, [
                                _vm._v("结拍："),
                              ]),
                              _vm._v(
                                _vm._s(
                                  _vm.formatDate(
                                    scope.row.first_end_time * 1000,
                                    "{y}-{m}-{d} {h}:{i}"
                                  )
                                ) + " 开始\n          "
                              ),
                            ])
                          : scope.row.album_type === 1
                          ? _c("div", [
                              _c("span", { staticStyle: { color: "#999" } }, [
                                _vm._v("结拍："),
                              ]),
                              _vm._v(
                                _vm._s(
                                  _vm.formatDate(
                                    scope.row.album_end_time * 1000,
                                    "{y}-{m}-{d} {h}:{i}"
                                  )
                                )
                              ),
                            ])
                          : scope.row.album_end_time
                          ? _c("div", [
                              _c("span", { staticStyle: { color: "#999" } }, [
                                _vm._v("结拍："),
                              ]),
                              _vm._v(
                                _vm._s(
                                  _vm.formatDate(
                                    scope.row.album_end_time * 1000,
                                    "{y}-{m}-{d} {h}:{i}"
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "预展效果",
                  "min-width": "150",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "has-click",
                            on: {
                              click: function ($event) {
                                return _vm.showAlbumVisitDataAction(
                                  scope.row.album_id,
                                  scope.row.click_num_uv,
                                  scope.row.click_num
                                )
                              },
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "#999" } }, [
                              _vm._v("围观："),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(scope.row.click_num_uv)),
                            ]),
                            _vm._v("人 "),
                            _c("span", [_vm._v(_vm._s(scope.row.click_num))]),
                            _vm._v("次"),
                          ]
                        ),
                        _vm._v(" "),
                        scope.row.bond_type == 1
                          ? _c(
                              "div",
                              {
                                staticClass: "has-click",
                                on: {
                                  click: function ($event) {
                                    return _vm.showBondMoneyUserAction(
                                      scope.row.album_id
                                    )
                                  },
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "#999" } }, [
                                  _vm._v("保证金："),
                                ]),
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.bond_num)),
                                ]),
                                _vm._v("人"),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "has-click",
                            on: {
                              click: function ($event) {
                                return _vm.showAlbumRelationDataAction(
                                  scope.row.album_id,
                                  scope.row.user_like_num
                                )
                              },
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "#999" } }, [
                              _vm._v("提醒："),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(scope.row.user_like_num)),
                            ]),
                            _vm._v("人"),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "参拍规则",
                  "min-width": "150",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.bond_type == 1
                          ? _c(
                              "div",
                              { staticStyle: { "text-align": "left" } },
                              [
                                _c("span", { staticStyle: { color: "#999" } }, [
                                  _vm._v("专场保证金："),
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.bond_price) + "元"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.bond_type == 2
                          ? _c(
                              "div",
                              { staticStyle: { "text-align": "left" } },
                              [
                                _c("span", { staticStyle: { color: "#999" } }, [
                                  _vm._v("专场保证金："),
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "1:" + _vm._s(scope.row.bond_bid_ratio)
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.bond_type == 3
                          ? _c(
                              "div",
                              { staticStyle: { "text-align": "left" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    "固定保证金：" +
                                      _vm._s(scope.row.bond_price) +
                                      "元"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "比例保证金：1:" +
                                      _vm._s(scope.row.bond_bid_ratio)
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "text-align": "left" } }, [
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v("买方佣金："),
                          ]),
                          _vm._v(
                            _vm._s(
                              scope.row.commission_config_json.commission
                            ) + "%"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "text-align": "left" } }, [
                          scope.row.album_config &&
                          scope.row.album_config.check_user_idCard == 1
                            ? _c("span", [_vm._v(" 不进行实名认证 ")])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.album_config &&
                          scope.row.album_config.check_user_idCard == 2
                            ? _c("span", [_vm._v(" 参拍前实名认证 ")])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.album_config &&
                          scope.row.album_config.check_user_idCard == 3
                            ? _c("span", [_vm._v(" 中拍后实名认证 ")])
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "结拍规则",
                  "min-width": "180",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.album_type == 2 || scope.row.album_type == 5
                          ? _c(
                              "div",
                              { staticStyle: { "text-align": "left" } },
                              [
                                scope.row.end_type == 1
                                  ? _c("span", [
                                      _vm._v(
                                        "每件拍品" +
                                          _vm._s(scope.row.interval_time / 60) +
                                          "分钟 " +
                                          _vm._s(
                                            _vm._f("handTiOne")(
                                              scope.row.reset_time
                                            )
                                          )
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.end_type == 2
                                  ? _c("span", [
                                      _vm._v(
                                        "倒数" +
                                          _vm._s(
                                            scope.row.album_config
                                              .click_hammer_num
                                          ) +
                                          "次落槌"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _c(
                              "div",
                              { staticStyle: { "text-align": "left" } },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("handTime")(scope.row.reset_time)
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                scope.row.album_type == 1 &&
                                scope.row.end_type == 2
                                  ? _c("div", [
                                      _vm._v(
                                        "每件间隔" +
                                          _vm._s(scope.row.interval_time / 60) +
                                          "分钟结拍"
                                      ),
                                    ])
                                  : scope.row.album_type == 4
                                  ? _c("div", [
                                      _vm._v(
                                        "出价后" +
                                          _vm._s(scope.row.interval_time / 60) +
                                          "分钟结拍"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "围观数",
                  width: "100",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.isSearch
                          ? [
                              _c("el-input-number", {
                                staticClass: "sortCol",
                                staticStyle: {
                                  width: "80px",
                                  "text-align": "center",
                                },
                                attrs: { min: 0, controls: false },
                                on: {
                                  change: function (val) {
                                    return _vm.editVirtualAction(
                                      val,
                                      scope.$index
                                    )
                                  },
                                },
                                model: {
                                  value: scope.row.virtual_click_num,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      scope.row,
                                      "virtual_click_num",
                                      $$v
                                    )
                                  },
                                  expression: "scope.row.virtual_click_num",
                                },
                              }),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                key: "20",
                attrs: {
                  label: "",
                  width: "36",
                  fixed: "right",
                  align: "center",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isSearch
                          ? [
                              _c(
                                "el-button",
                                {
                                  staticClass: "ft12 p5-0",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clearsearch("all")
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      "icon-class": "commondelete",
                                      "class-name": "blue",
                                    },
                                  }),
                                  _vm._v("清除"),
                                ],
                                1
                              ),
                            ]
                          : [
                              _c(
                                "TableRightBtn",
                                { attrs: { w: "150" } },
                                [
                                  scope.row.album_type == 4 &&
                                  scope.row.album_status < 3
                                    ? [
                                        _c(
                                          "el-button",
                                          {
                                            directives: [
                                              {
                                                name: "permission",
                                                rawName: "v-permission",
                                                value:
                                                  "merchantAuctionAlbumUpdate",
                                                expression:
                                                  "'merchantAuctionAlbumUpdate'",
                                              },
                                            ],
                                            staticStyle: { display: "block" },
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editAlbum(
                                                  scope.row.album_id,
                                                  scope.row.album_type,
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "commonedit",
                                                "class-name": "blue",
                                              },
                                            }),
                                            _vm._v("编辑专场"),
                                          ],
                                          1
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "el-button",
                                          {
                                            directives: [
                                              {
                                                name: "permission",
                                                rawName: "v-permission",
                                                value:
                                                  "merchantAuctionAlbumUpdate",
                                                expression:
                                                  "'merchantAuctionAlbumUpdate'",
                                              },
                                            ],
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editAlbum(
                                                  scope.row.album_id,
                                                  scope.row.album_type,
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "commonedit",
                                                "class-name": "blue",
                                              },
                                            }),
                                            _vm._v("编辑专场"),
                                          ],
                                          1
                                        ),
                                      ],
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value: "merchantAddAuction",
                                          expression: "'merchantAddAuction'",
                                        },
                                      ],
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addAuction(
                                            scope.row.album_id,
                                            scope.row.album_type,
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          "icon-class": "commonadd",
                                          "class-name": "blue",
                                        },
                                      }),
                                      _vm._v("添加拍品"),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "template",
                                    { slot: "pop_btn" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "permission",
                                              rawName: "v-permission",
                                              value:
                                                "merchantSystemExcelImport:product_auction",
                                              expression:
                                                "'merchantSystemExcelImport:product_auction'",
                                            },
                                          ],
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.batchUploadAuction(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              "icon-class": "commonimport",
                                              "class-name": "blue",
                                            },
                                          }),
                                          _vm._v("导入拍品"),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      scope.row.album_type == 4 &&
                                      scope.row.album_status < 3
                                        ? [
                                            _c(
                                              "el-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "permission",
                                                    rawName: "v-permission",
                                                    value:
                                                      "merchantAuctionAlbumEndInstantlyAlbum",
                                                    expression:
                                                      "'merchantAuctionAlbumEndInstantlyAlbum'",
                                                  },
                                                ],
                                                staticStyle: {
                                                  display: "block",
                                                },
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.endAlbum(
                                                      scope.row.album_id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    "icon-class": "commonend",
                                                    "class-name": "blue",
                                                  },
                                                }),
                                                _vm._v("结束"),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "permission",
                                                    rawName: "v-permission",
                                                    value:
                                                      "merchantAuctionAlbumDel",
                                                    expression:
                                                      "'merchantAuctionAlbumDel'",
                                                  },
                                                ],
                                                staticStyle: {
                                                  display: "block",
                                                  color: "red",
                                                },
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.delAlbum(
                                                      scope.row.album_id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    "icon-class":
                                                      "commondelete",
                                                    color: "red",
                                                  },
                                                }),
                                                _vm._v("删除"),
                                              ],
                                              1
                                            ),
                                          ]
                                        : [
                                            scope.row.album_type == 5 &&
                                            scope.row.album_status < 3
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "permission",
                                                        rawName: "v-permission",
                                                        value:
                                                          "merchantAuctionAlbumEndInstantlyAlbum",
                                                        expression:
                                                          "'merchantAuctionAlbumEndInstantlyAlbum'",
                                                      },
                                                    ],
                                                    staticStyle: {
                                                      display: "block",
                                                    },
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.endAlbum(
                                                          scope.row.album_id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      attrs: {
                                                        "icon-class":
                                                          "commonend",
                                                        "class-name": "blue",
                                                      },
                                                    }),
                                                    _vm._v("结束专场"),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "permission",
                                                    rawName: "v-permission",
                                                    value:
                                                      "merchantAuctionAlbumDel",
                                                    expression:
                                                      "'merchantAuctionAlbumDel'",
                                                  },
                                                ],
                                                staticStyle: { color: "red" },
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.delAlbum(
                                                      scope.row.album_id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    "icon-class":
                                                      "commondelete",
                                                    color: "red",
                                                  },
                                                }),
                                                _vm._v("删除专场"),
                                              ],
                                              1
                                            ),
                                          ],
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { color: "red" },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.hideAlbum(
                                                scope.row,
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          scope.row.is_show
                                            ? [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    "icon-class":
                                                      "zhuanchang-yincang",
                                                    color: "red",
                                                  },
                                                }),
                                                _vm._v("隐藏专场"),
                                              ]
                                            : [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    "icon-class": "commonlook",
                                                    color: "red",
                                                  },
                                                }),
                                                _vm._v("取消隐藏"),
                                              ],
                                        ],
                                        2
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                2
                              ),
                            ],
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paginaBox flex" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-size": _vm.queryParams.limit,
              "current-page": _vm.queryParams.page,
              layout: "total, prev, pager, next, sizes",
              total: _vm.total,
            },
            on: {
              "current-change": _vm.pageChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showBatchUpload
        ? _c("ImportAuction", {
            ref: "batchTemplate",
            attrs: { source: "product_auction" },
            on: {
              uploadOk: _vm.load,
              uploadClose: function ($event) {
                _vm.showBatchUpload = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("albumVisitData", { ref: "albumVisitEL" }),
      _vm._v(" "),
      _c("albumRelationData", { ref: "albumRelationEL" }),
      _vm._v(" "),
      _c("albumBondMoney", { ref: "albumBondMoneyEL" }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量打印成交确认书",
            visible: _vm.orderConfirmData.show,
            "close-on-click-modal": false,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.orderConfirmData, "show", $event)
            },
          },
        },
        [
          _vm.printData.length > 0
            ? _c("div", { staticClass: "h400" }, [
                _vm.albumPriceData.mer_id == 559615
                  ? _c(
                      "div",
                      {
                        ref: "print1Div",
                        staticClass: "print-div1",
                        attrs: { id: "printConfirm" },
                      },
                      _vm._l(_vm.printData, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            style:
                              "width: 555px;height: 800px;overflow: hidden;",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-family": "黑体",
                                  color: "#000",
                                  overflow: "hidden",
                                  "padding-right": "14px",
                                },
                              },
                              [
                                _c("div", { staticStyle: { height: "150px" } }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex align-items-c mt20",
                                    staticStyle: {
                                      "font-size": "14px",
                                      height: "60px",
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticStyle: { width: "220px" },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex-one",
                                        staticStyle: {
                                          "font-weight": "bold",
                                          overflow: "hidden",
                                          "white-space": "nowrap",
                                          "text-overflow": "ellipsis",
                                        },
                                      },
                                      [_vm._v(_vm._s(item.tuluhao))]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex align-items-c",
                                    staticStyle: {
                                      "font-size": "14px",
                                      height: "60px",
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticStyle: { width: "220px" },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "220px",
                                          "font-weight": "bold",
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(item.title))]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex align-items-c",
                                    staticStyle: {
                                      "font-size": "14px",
                                      height: "60px",
                                      "margin-top": "120px",
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticStyle: { width: "220px" },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex-one",
                                        staticStyle: {
                                          "font-weight": "bold",
                                          overflow: "hidden",
                                          "white-space": "nowrap",
                                          "text-overflow": "ellipsis",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.albumPriceData.commission
                                            ) +
                                            "%"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", {
                              staticStyle: { "page-break-after": "always" },
                            }),
                          ]
                        )
                      }),
                      0
                    )
                  : _c(
                      "div",
                      {
                        ref: "print1Div",
                        staticClass: "print-div1",
                        attrs: { id: "printConfirm" },
                      },
                      _vm._l(_vm.printData, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            style:
                              "height: 524px;padding-top: 40px;overflow: hidden;",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-family": "黑体",
                                  color: "#000",
                                  overflow: "hidden",
                                  "padding-right": "14px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "pt20 item-flex-center",
                                    staticStyle: { position: "relative" },
                                  },
                                  [
                                    _vm.albumPriceData.mLogo
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "img",
                                            staticStyle: {
                                              position: "absolute",
                                              left: "0",
                                              top: "0px",
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                "max-height": "40px",
                                                "max-width": "200px",
                                              },
                                              attrs: {
                                                src: _vm.albumPriceData.mLogo,
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "c-name",
                                        staticStyle: {
                                          "font-size": "26px",
                                          "font-weight": "bold",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(_vm.albumPriceData.mName)
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "font-size": "24px",
                                                },
                                              },
                                              [_vm._v("成交确认书")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex align-items-c mt20",
                                    staticStyle: {
                                      "border-bottom": "1px solid #000",
                                      "font-size": "14px",
                                      height: "40px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { width: "200px" } },
                                      [_vm._v("拍卖会名称")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex-one",
                                        staticStyle: {
                                          "font-weight": "bold",
                                          overflow: "hidden",
                                          "white-space": "nowrap",
                                          "text-overflow": "ellipsis",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.albumPriceData.name))]
                                    ),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(
                                        "日期：" + _vm._s(_vm.albumPriceData.d)
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex align-items-c",
                                    staticStyle: {
                                      "border-bottom": "1px solid #000",
                                      "font-size": "14px",
                                      height: "40px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { width: "200px" } },
                                      [_vm._v("图录号、名称")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex-one",
                                        staticStyle: {
                                          "font-weight": "bold",
                                          overflow: "hidden",
                                          "white-space": "nowrap",
                                          "text-overflow": "ellipsis",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(item.tuluhao) +
                                            " " +
                                            _vm._s(item.title)
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex align-items-c",
                                    staticStyle: {
                                      "border-bottom": "1px solid #000",
                                      "font-size": "14px",
                                      height: "40px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { width: "200px" } },
                                      [_vm._v("落槌价")]
                                    ),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "flex-one",
                                      staticStyle: { "font-weight": "bold" },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex align-items-c",
                                    staticStyle: {
                                      "border-bottom": "1px solid #000",
                                      "font-size": "14px",
                                      height: "40px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { width: "200px" } },
                                      [_vm._v("佣金")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex-one",
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.albumPriceData.commission
                                          ) + "%"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex align-items-c",
                                    staticStyle: {
                                      "border-bottom": "1px solid #000",
                                      "font-size": "14px",
                                      height: "80px",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "flex-one" }, [
                                      _c(
                                        "div",
                                        { staticStyle: { width: "200px" } },
                                        [_vm._v("竞投号牌")]
                                      ),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "flex-one",
                                        staticStyle: { "font-weight": "bold" },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "flex-one" }, [
                                      _c(
                                        "div",
                                        { staticStyle: { width: "200px" } },
                                        [_vm._v("买受人签字")]
                                      ),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "flex-one",
                                        staticStyle: { "font-weight": "bold" },
                                      }),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "flex align-items-c mt20" },
                                  [
                                    _c("div", { staticClass: "txt" }, [
                                      _vm._v(
                                        "·买受人已认真阅读" +
                                          _vm._s(_vm.albumPriceData.mName) +
                                          "的拍卖规则，同意在拍卖交易中遵守拍卖规则中的一切条款，并按规定向" +
                                          _vm._s(_vm.albumPriceData.mName) +
                                          "支付佣金及其他各项费用。"
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "flex align-items-c mt40" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-one flex align-items-c",
                                      },
                                      [_vm._v("记录员签字：")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-one flex align-items-c",
                                      },
                                      [_vm._v("拍卖师签字：")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-one flex align-items-c",
                                        staticStyle: { flex: "1.5" },
                                      },
                                      [
                                        _c("div", { staticClass: "bj" }, [
                                          _vm._v("拍卖人公章："),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "flex-one" }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", {
                              staticStyle: { "page-break-after": "always" },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex justify-c mt20" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.orderConfirmData.show = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "print",
                      rawName: "v-print",
                      value: _vm.printObj2,
                      expression: "printObj2",
                    },
                  ],
                  attrs: { type: "primary" },
                },
                [_vm._v("确认打印")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }