var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.gridList.length > 0
      ? _c(
          "div",
          {
            ref: "wapperRef",
            staticClass: "wapperBox",
            style: {
              height: (_vm.isHeight ? _vm.wapperHeigth : 0) + "px",
              "padding-bottom": (_vm.isHeight ? 20 : 0) + "px",
            },
          },
          _vm._l(_vm.gridList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "wapper-item",
                style: {
                  width: _vm.realWidth + "px",
                  height: "" + item.boxheight,
                  top: "" + item.top,
                  left: "" + item.left,
                },
              },
              [
                _vm._t("default", null, {
                  row: Object.assign({}, item),
                  index: index,
                }),
              ],
              2
            )
          }),
          0
        )
      : !_vm.isLoading
      ? _c("div", { staticClass: "noDataBox" }, [_vm._v("暂无数据")])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { attrs: { id: "footer" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }