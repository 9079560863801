var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.visible
        ? _c("confirm-index", {
            attrs: {
              title: _vm.title,
              content: _vm.content,
              "is-padding": _vm.isPadding,
              "no-cancel": _vm.noCancel,
              "cancel-txt": _vm.cancelTxt,
              "confirm-txt": _vm.confirmTxt,
            },
            on: { confirm: _vm.confirmAction, cancel: _vm.cancelAction },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }