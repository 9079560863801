var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "all-h-print" }, [
    _c(
      "div",
      { attrs: { id: "printorder" } },
      [
        _c("div", { staticClass: "detail-box" }, [
          _c("div", { staticClass: "flex" }, [
            _vm.merData.config && _vm.merData.config.mer_print_logo
              ? _c("img", {
                  staticStyle: { height: "70px", "margin-right": "20px" },
                  attrs: {
                    src: _vm.merData.config.mer_print_logo + "!120a",
                    alt: "",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "detail-left" }, [
              _c("div", { staticClass: "detail-left-title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.merData.company_name) +
                    " | " +
                    _vm._s(_vm.switchLang("pprkqd")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-left-desc",
                  staticStyle: { "font-size": "14px" },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.merData.company_english_name) +
                      "  AUCTION INBOUND ORDER\n      "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "detail-right" }, [
            _c("img", {
              staticStyle: { width: "200px" },
              attrs: {
                src: "data:image/png;base64," + _vm.detailjson.bar_code_image,
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "detail-code" }, [
              _vm._v(_vm._s(_vm.detailjson.order_no)),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.isNo
          ? _c(
              "div",
              { staticClass: "ptd-box" },
              [
                _c(
                  "div",
                  {
                    staticClass: "ptd",
                    staticStyle: { "font-weight": "bold" },
                  },
                  _vm._l(_vm.showFiled, function (item, index) {
                    return _c("div", { key: index, staticClass: "ptd-title" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm._f("nameFirter")(item.name))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticStyle: { "font-family": "'printEN'" } },
                        [_vm._v(_vm._s(_vm._f("englis")(item.name)))]
                      ),
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _vm._l(
                  _vm.detailjson.warehouseOrderProduct,
                  function (item, index) {
                    return _c(
                      "div",
                      { key: "order" + index, staticClass: "ptd calcul" },
                      _vm._l(_vm.showFiled, function (ele, ind) {
                        return _c(
                          "div",
                          {
                            key: "order" + index + "-" + ind,
                            staticClass: "ptd-body",
                          },
                          [
                            ele.field == "image"
                              ? _c("div", { staticClass: "item-flex-center" }, [
                                  item.erpProduct.warehouse_image &&
                                  item.erpProduct.warehouse_image[0]
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "100%",
                                          "max-width": "50px",
                                          display: "block",
                                        },
                                        attrs: {
                                          src:
                                            item.erpProduct.warehouse_image[0] +
                                            "!120a",
                                          alt: "",
                                        },
                                      })
                                    : _vm._e(),
                                ])
                              : ele.field == "shelf_id"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      item.shelf ? item.shelf.shelf_no : ""
                                    )
                                  ),
                                ])
                              : Number(ele.field) < 100000000
                              ? _c("span", [
                                  item.erpProduct.attribute
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.erpProduct.attribute[ele.field]
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : ele.field == "label"
                              ? _c("span", [
                                  item.erpProduct[ele.field]
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.erpProduct[ele.field].join(",")
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : ele.field == "category"
                              ? _c("span", [
                                  item.erpProduct.storeCategoryParent
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.erpProduct.storeCategoryParent
                                              .map(function (m) {
                                                return m.cate_name
                                              })
                                              .join(">")
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : ele.field == "contract.contract_no"
                              ? _c("span", [
                                  item.erpProduct.contract
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.erpProduct.contract.contract_no
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(item.erpProduct[ele.field])),
                                ]),
                          ]
                        )
                      }),
                      0
                    )
                  }
                ),
              ],
              2
            )
          : [
              _vm._l(_vm.result, function (abc, ind) {
                return _c(
                  "div",
                  {
                    key: ind,
                    staticClass: "ptd-box",
                    style:
                      ind < _vm.result.length - 1
                        ? "page-break-after: always;"
                        : "",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ptd",
                        staticStyle: { "font-weight": "bold" },
                      },
                      _vm._l(_vm.showFiled, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "ptd-title" },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm._f("nameFirter")(item.name))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-family": "'printEN'" } },
                              [_vm._v(_vm._s(_vm._f("englis")(item.name)))]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm._l(abc, function (item, index) {
                      return _c(
                        "div",
                        { key: "order" + index, staticClass: "ptd" },
                        _vm._l(_vm.showFiled, function (ele, ind) {
                          return _c(
                            "div",
                            {
                              key: "order" + index + "-" + ind,
                              staticClass: "ptd-body",
                            },
                            [
                              ele.field == "image"
                                ? _c(
                                    "div",
                                    { staticClass: "item-flex-center" },
                                    [
                                      item.erpProduct.warehouse_image &&
                                      item.erpProduct.warehouse_image[0]
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "100%",
                                              "max-width": "50px",
                                              display: "block",
                                            },
                                            attrs: {
                                              src:
                                                item.erpProduct
                                                  .warehouse_image[0] + "!120a",
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                : ele.field == "shelf_id"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        item.shelf ? item.shelf.shelf_no : ""
                                      )
                                    ),
                                  ])
                                : Number(ele.field) < 100000000
                                ? _c("span", [
                                    item.erpProduct.attribute
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.erpProduct.attribute[
                                                ele.field
                                              ]
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : ele.field == "label"
                                ? _c("span", [
                                    item.erpProduct[ele.field]
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.erpProduct[ele.field].join(
                                                ","
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : ele.field == "category"
                                ? _c("span", [
                                    item.erpProduct.storeCategoryParent
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.erpProduct.storeCategoryParent
                                                .map(function (m) {
                                                  return m.cate_name
                                                })
                                                .join(">")
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : ele.field == "contract.contract_no"
                                ? _c("span", [
                                    item.erpProduct.contract
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.erpProduct.contract
                                                .contract_no
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _c("span", [
                                    _vm._v(_vm._s(item.erpProduct[ele.field])),
                                  ]),
                            ]
                          )
                        }),
                        0
                      )
                    }),
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "ptd-box",
                  staticStyle: { "margin-top": "0", "border-top": "none" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "ptd" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "ptd-body",
                          staticStyle: { "line-height": "1" },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.switchLang("Total")))]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticStyle: { "font-family": "'printEN'" } },
                            [_vm._v("TOTAL")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ptd-body",
                          staticStyle: { display: "flex" },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.detailjson.warehouseOrderProduct.length
                              )
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.showFiled, function (ele, ind) {
                        return [
                          ind < _vm.showFiled.length - 2
                            ? _c("div", { key: ind, staticClass: "ptd-body" })
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ],
        _vm._v(" "),
        _vm.detailjson.type
          ? [
              _c(
                "div",
                {
                  staticStyle: {
                    margin: "20px 0 10px",
                    "font-size": "16px",
                    "font-weight": "bold",
                    color: "#000",
                  },
                },
                [_vm._v(_vm._s(_vm.switchLang("rkpz")))]
              ),
              _vm._v(" "),
              _vm.detailjson.enter_image
                ? _c(
                    "div",
                    {
                      staticClass: "flex",
                      staticStyle: { "flex-wrap": "wrap" },
                    },
                    [
                      _vm._l(
                        _vm.detailjson.enterImageList,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass:
                                "upLoadPicBox flex align-items-c flex-wrap",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "pictrue mr10 mb10" },
                                [
                                  _c("el-image", {
                                    attrs: {
                                      src: item + "!120a",
                                      "preview-src-list":
                                        _vm.detailjson.enterImageList,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        }
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "upLoadPicBox flex align-items-c flex-wrap",
                          on: {
                            click: function ($event) {
                              return _vm.modalPicTap("1")
                            },
                          },
                        },
                        [_vm._m(0)]
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.isShow && !_vm.detailjson.type
          ? _c("div", { staticClass: "detail-sign" }, [
              _vm._v(_vm._s(_vm.switchLang("shrqz"))),
              _c("span", { staticStyle: { "font-family": "'printEN'" } }, [
                _vm._v("（Name in Print）"),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isShow && !_vm.detailjson.type
          ? _c("div", { staticClass: "print-line" })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "detail-list",
            staticStyle: {
              "margin-top": "38px",
              "font-weight": "bold",
              "font-size": "16px",
            },
          },
          [
            _c(
              "div",
              [
                _vm._v(
                  "\n    " + _vm._s(_vm.switchLang("OrderMak")) + "：\n    "
                ),
                _vm.detailjson.createAdmin
                  ? [_vm._v(_vm._s(_vm.detailjson.createAdmin.real_name))]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm._v("\n      " + _vm._s(_vm.switchLang("spr")) + "：\n    "),
                _vm.detailjson.auditAdmin
                  ? [_vm._v(_vm._s(_vm.detailjson.auditAdmin.real_name))]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm._v("\n    " + _vm._s(_vm.switchLang("jsr")) + "：\n    "),
                _vm.detailjson.enterAdmin
                  ? [_vm._v(_vm._s(_vm.detailjson.enterAdmin.real_name))]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm._v("\n    " + _vm._s(_vm.switchLang("rkkf")) + "：\n    "),
                _vm.detailjson.enterWarehouse
                  ? [_vm._v(_vm._s(_vm.detailjson.enterWarehouse.name))]
                  : _vm._e(),
              ],
              2
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "detail-list" }, [
          _c("div", [
            _vm._v(
              _vm._s(_vm.switchLang("DocumentNumber")) +
                "：" +
                _vm._s(_vm.detailjson.order_no)
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              _vm._s(_vm.switchLang("zdsj")) +
                "：" +
                _vm._s(_vm.detailjson.create_time)
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              _vm._s(_vm.switchLang("rkkf")) +
                "：" +
                _vm._s(_vm.detailjson.enterTypeText)
            ),
          ]),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "upLoad mb10" }, [
      _c("i", { staticClass: "el-icon-camera cameraIconfont" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }