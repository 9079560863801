"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var auctionreportRouter = {
  path: "".concat(_settings.roterPre, "/auction"),
  component: _layout.default,
  meta: {
    title: "报表",
    icon: "el-icon-s-help"
  },
  children: [{
    path: "report",
    name: "report",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/auction/report/index"));
      });
    },
    meta: {
      title: "报表",
      noCache: true,
      permissionKey: "/pageManagement/auction/meeting",
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
    },
    children: [{
      path: "auctionreport",
      name: "auctionreport",
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/auction/report/reportlist"));
        });
      },
      meta: {
        title: "上拍通知书",
        noCache: true,
        permissionKey: "/pageManagement/auction/meeting",
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
      }
    }, {
      path: "afterNotice",
      name: "afterNotice",
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/auction/report/afterNotice"));
        });
      },
      meta: {
        title: "拍后告知书",
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
      }
    }, {
      path: 'antique',
      name: 'antique',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/auction/meetingData/antique'));
        });
      },
      meta: {
        title: '文物局报表',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
      }
    }, {
      path: 'dealConfirm',
      name: 'dealConfirm',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/auction/report/dealConfirm'));
        });
      },
      meta: {
        title: '成交确认书',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
      }
    }]
  }, {
    path: 'reportsetting',
    name: 'reportsetting',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/auction/report/reportsetting'));
      });
    },
    meta: {
      title: '报表设置',
      noCache: true,
      permissionKey: '/pageManagement/auction/meeting',
      activeMenu: "".concat(_settings.roterPre, "/pageManagement/auction/meeting")
    }
  }]
};
var _default = exports.default = auctionreportRouter;