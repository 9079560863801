var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.facade,
        "label-width": "90px",
        size: "mini",
        "label-position": "left",
      },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "区域外间距" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "radius-list t" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageMarginTop,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageMarginTop", $$v)
                    },
                    expression: "facade.pageMarginTop",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list b" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageMarginBottom,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageMarginBottom", $$v)
                    },
                    expression: "facade.pageMarginBottom",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list l" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageMarginLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageMarginLeft", $$v)
                    },
                    expression: "facade.pageMarginLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list r" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageMarginRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageMarginRight", $$v)
                    },
                    expression: "facade.pageMarginRight",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "区域内间距" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "radius-list t" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pagePaddingTop,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pagePaddingTop", $$v)
                    },
                    expression: "facade.pagePaddingTop",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list b" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pagePaddingBottom,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pagePaddingBottom", $$v)
                    },
                    expression: "facade.pagePaddingBottom",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list l" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pagePaddingLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pagePaddingLeft", $$v)
                    },
                    expression: "facade.pagePaddingLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list r" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pagePaddingRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pagePaddingRight", $$v)
                    },
                    expression: "facade.pagePaddingRight",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "区域圆角" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "radius-list tl" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageRadiusTopLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageRadiusTopLeft", $$v)
                    },
                    expression: "facade.pageRadiusTopLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list tr" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageRadiusTopRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageRadiusTopRight", $$v)
                    },
                    expression: "facade.pageRadiusTopRight",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list bl" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageRadiusBottomLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageRadiusBottomLeft", $$v)
                    },
                    expression: "facade.pageRadiusBottomLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list br" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.pageRadiusBottomRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "pageRadiusBottomRight", $$v)
                    },
                    expression: "facade.pageRadiusBottomRight",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "背景颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#F5F5F5" },
            model: {
              value: _vm.facade.pageBackground,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "pageBackground", $$v)
              },
              expression: "facade.pageBackground",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "边框大小" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "radius-list t" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.borderTop,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "borderTop", $$v)
                    },
                    expression: "facade.borderTop",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list b" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.borderBottom,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "borderBottom", $$v)
                    },
                    expression: "facade.borderBottom",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list l" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.borderLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "borderLeft", $$v)
                    },
                    expression: "facade.borderLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list r" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.borderRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "borderRight", $$v)
                    },
                    expression: "facade.borderRight",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "边框颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#F5F5F5" },
            model: {
              value: _vm.facade.borderColor,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "borderColor", $$v)
              },
              expression: "facade.borderColor",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "图片圆角" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "radius-list tl" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.imgRadiusTopLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "imgRadiusTopLeft", $$v)
                    },
                    expression: "facade.imgRadiusTopLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list tr" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.imgRadiusTopRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "imgRadiusTopRight", $$v)
                    },
                    expression: "facade.imgRadiusTopRight",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list bl" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.imgRadiusBottomLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "imgRadiusBottomLeft", $$v)
                    },
                    expression: "facade.imgRadiusBottomLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list br" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.imgRadiusBottomRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "imgRadiusBottomRight", $$v)
                    },
                    expression: "facade.imgRadiusBottomRight",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字位置" } },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.facade.textArea,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "textArea", $$v)
                    },
                    expression: "facade.textArea",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "1" } }, [
                    _vm._v("图片右边"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "2" } }, [
                    _vm._v("图片下边"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.facade.textArea == 1
        ? _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "文字宽度" } },
            [
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.textWidth,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "textWidth", $$v)
                      },
                      expression: "facade.textWidth",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.facade.textArea == 1
        ? _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "文字垂直位置" } },
            [
              _c(
                "div",
                { staticClass: "flex align-items-c" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.textItemArea,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "textItemArea", $$v)
                        },
                        expression: "facade.textItemArea",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "flex-start" } }, [
                        _vm._v("顶部"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "center" } }, [
                        _vm._v("垂直居中"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "flex-end" } }, [
                        _vm._v("底部"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字对齐" } },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.facade.textAlign,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "textAlign", $$v)
                    },
                    expression: "facade.textAlign",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "left" } }, [
                    _vm._v("居左"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "center" } }, [
                    _vm._v("居中"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "right" } }, [
                    _vm._v("居右"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字背景" } },
        [
          _c("colour", {
            attrs: { colour: "#F5F5F5" },
            model: {
              value: _vm.facade.textBackground,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "textBackground", $$v)
              },
              expression: "facade.textBackground",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字距离" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "radius-list t" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.textPaddingTop,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "textPaddingTop", $$v)
                    },
                    expression: "facade.textPaddingTop",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list b" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.textPaddingBottom,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "textPaddingBottom", $$v)
                    },
                    expression: "facade.textPaddingBottom",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list l" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.textPaddingLeft,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "textPaddingLeft", $$v)
                    },
                    expression: "facade.textPaddingLeft",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list r" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.textPaddingRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "textPaddingRight", $$v)
                    },
                    expression: "facade.textPaddingRight",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "h30" }),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "距上距离" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 50 },
            model: {
              value: _vm.facade.marginTop,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "marginTop", $$v)
              },
              expression: "facade.marginTop",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#999999" },
            model: {
              value: _vm.facade.color,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "color", $$v)
              },
              expression: "facade.color",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字字号" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 50 },
            model: {
              value: _vm.facade.fontSize,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "fontSize", $$v)
              },
              expression: "facade.fontSize",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字样式" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.fontWeight,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "fontWeight", $$v)
                },
                expression: "facade.fontWeight",
              },
            },
            [
              _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }