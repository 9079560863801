var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "mb20",
      attrs: {
        model: _vm.content,
        "label-width": "80px",
        size: "mini",
        "label-position": "left",
      },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c("div", { staticClass: "item-align-center" }, [
        _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
          _vm._v("工作时间"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-one" },
          [
            _c("el-input", {
              attrs: { type: "text", placeholder: "设置工作时间" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return (function () {})($event)
                },
              },
              model: {
                value: _vm.content.time,
                callback: function ($$v) {
                  _vm.$set(_vm.content, "time", $$v)
                },
                expression: "content.time",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item-align-center mt20" }, [
        _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
          _vm._v("总部别称"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-one" },
          [
            _c("el-input", {
              attrs: { type: "text", placeholder: "设置文字" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return (function () {})($event)
                },
              },
              model: {
                value: _vm.content.headOfficeTitle,
                callback: function ($$v) {
                  _vm.$set(_vm.content, "headOfficeTitle", $$v)
                },
                expression: "content.headOfficeTitle",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item-align-center mt20" }, [
        _c("div", { staticClass: "flex-one fontW500 ft14 color-black" }, [
          _vm._v("设置总部联系方式"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "cur ft14",
            staticStyle: { color: "#1772f6" },
            on: {
              click: function ($event) {
                return _vm.addAction("headOffice")
              },
            },
          },
          [_vm._v("新增")]
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.content.headOffice, function (m, i) {
        return _c(
          "div",
          {
            key: "headOffice" + i,
            staticClass: "item-align-center mt20",
            staticStyle: {
              "border-bottom": "1px solid #f5f5f5",
              "padding-bottom": "20px",
            },
          },
          [
            _c("div", { staticClass: "flex-one" }, [
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "设置名称" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return (function () {})($event)
                      },
                    },
                    model: {
                      value: m.name,
                      callback: function ($$v) {
                        _vm.$set(m, "name", $$v)
                      },
                      expression: "m.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt10" },
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "设置地址" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return (function () {})($event)
                      },
                    },
                    model: {
                      value: m.address,
                      callback: function ($$v) {
                        _vm.$set(m, "address", $$v)
                      },
                      expression: "m.address",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt10" },
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "设置联系方式" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return (function () {})($event)
                      },
                    },
                    model: {
                      value: m.other,
                      callback: function ($$v) {
                        _vm.$set(m, "other", $$v)
                      },
                      expression: "m.other",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "del-img",
                on: {
                  click: function ($event) {
                    return _vm.delAction("headOffice", i)
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                    alt: "",
                  },
                }),
              ]
            ),
          ]
        )
      }),
      _vm._v(" "),
      _c("div", { staticClass: "item-align-center mt20" }, [
        _c("div", { staticClass: "flex-one fontW500 ft14 color-black" }, [
          _vm._v("设置分公司联系方式"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "cur ft14",
            staticStyle: { color: "#1772f6" },
            on: {
              click: function ($event) {
                return _vm.addAction("company")
              },
            },
          },
          [_vm._v("新增")]
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.content.company, function (m, i) {
        return _c(
          "div",
          {
            key: "company" + i,
            staticClass: "item-align-center mt20",
            staticStyle: {
              "border-bottom": "1px solid #f5f5f5",
              "padding-bottom": "20px",
            },
          },
          [
            _c("div", { staticClass: "flex-one" }, [
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "设置名称" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return (function () {})($event)
                      },
                    },
                    model: {
                      value: m.name,
                      callback: function ($$v) {
                        _vm.$set(m, "name", $$v)
                      },
                      expression: "m.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt10" },
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "设置地址" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return (function () {})($event)
                      },
                    },
                    model: {
                      value: m.address,
                      callback: function ($$v) {
                        _vm.$set(m, "address", $$v)
                      },
                      expression: "m.address",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt10" },
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "设置联系方式" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return (function () {})($event)
                      },
                    },
                    model: {
                      value: m.other,
                      callback: function ($$v) {
                        _vm.$set(m, "other", $$v)
                      },
                      expression: "m.other",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "del-img",
                on: {
                  click: function ($event) {
                    return _vm.delAction("company", i)
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                    alt: "",
                  },
                }),
              ]
            ),
          ]
        )
      }),
      _vm._v(" "),
      _c("div", { staticClass: "item-align-center mt20" }, [
        _c("div", { staticClass: "flex-one fontW500 ft14 color-black" }, [
          _vm._v("设置办事处联系方式"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "cur ft14",
            staticStyle: { color: "#1772f6" },
            on: {
              click: function ($event) {
                return _vm.addAction("office")
              },
            },
          },
          [_vm._v("新增")]
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.content.office, function (m, i) {
        return _c(
          "div",
          {
            key: "office" + i,
            staticClass: "item-align-center mt20",
            staticStyle: {
              "border-bottom": "1px solid #f5f5f5",
              "padding-bottom": "20px",
            },
          },
          [
            _c("div", { staticClass: "flex-one" }, [
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "设置名称" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return (function () {})($event)
                      },
                    },
                    model: {
                      value: m.name,
                      callback: function ($$v) {
                        _vm.$set(m, "name", $$v)
                      },
                      expression: "m.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt10" },
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "设置地址" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return (function () {})($event)
                      },
                    },
                    model: {
                      value: m.address,
                      callback: function ($$v) {
                        _vm.$set(m, "address", $$v)
                      },
                      expression: "m.address",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt10" },
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "设置联系方式" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return (function () {})($event)
                      },
                    },
                    model: {
                      value: m.other,
                      callback: function ($$v) {
                        _vm.$set(m, "other", $$v)
                      },
                      expression: "m.other",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "del-img",
                on: {
                  click: function ($event) {
                    return _vm.delAction("office", i)
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                    alt: "",
                  },
                }),
              ]
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }