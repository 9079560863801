"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
var _createPoster = _interopRequireDefault(require("../createPoster.vue"));
var _artai = require("@/api/artai");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    createPoster: _createPoster.default
  },
  data: function data() {
    return {
      headNumber: {},
      routeName: this.$route.name
    };
  },
  // computed: {
  // },
  created: function created() {
    var _this = this;
    var headNumberJSON = sessionStorage.getItem('headNumber');
    var headNumber = headNumberJSON ? JSON.parse(headNumberJSON) : {};
    this.headNumber = headNumber;
    var hasWz = this.$route.name.indexOf('wz_') == 0;
    this.hasWz = hasWz;
    var order_type = 3;
    if (hasWz) {
      order_type = 3;
    }
    (0, _artai.getHeaderNumberApi)({
      order_type: order_type
    }).then(function (res) {
      if (order_type == 3) {
        headNumber['wz_index'] = res.data.orderCount;
        headNumber['wz_MyTemplate'] = res.data.myTemplateCount;
        headNumber['wz_template'] = res.data.templateSquareCount;
      }
      _this.headNumber = headNumber;
      sessionStorage.setItem('headNumber', JSON.stringify(headNumber));
      _this.$emit('getNumber', res.data);
    });
  },
  methods: {
    onRefresh: function onRefresh() {
      this.$emit('onRefresh');
    },
    onOpenModule: function onOpenModule() {
      this.$router.replace('/merchant/artai/myTemplate');
    },
    onOpenModule1: function onOpenModule1() {
      this.$router.replace('/merchant/artai/wz');
    },
    onOpenTemplate: function onOpenTemplate() {
      this.$router.replace('/merchant/artai/wzTemplate');
    },
    onOpenPoster: function onOpenPoster() {
      this.$refs.createPosterRef.openPoster();
    },
    openPoster: function openPoster(tempId) {
      this.$refs.createPosterRef.openPoster(tempId);
    },
    goonSubmit: function goonSubmit(order_id, template_id, dData) {
      this.$refs.createPosterRef.goonSubmit(order_id, template_id, dData);
    },
    createModel: function createModel() {
      this.$emit('createTemplate');
    }
  }
};