var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { "min-height": "100vh" } },
    [
      _c(
        "header-breadcrumb",
        { attrs: { name: "审核清单", "has-back": "" } },
        [
          _c(
            "template",
            { slot: "btn" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", icon: "el-icon-setting" },
                  on: { click: _vm.changeheaderconfig },
                },
                [_vm._v("字段配置")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("el-card", { staticClass: "box-card goods-list-custom" }, [
        _c(
          "div",
          {
            staticStyle: { height: "calc(100vh - 140px)", overflow: "hidden" },
          },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                class: { "has-search-table": _vm.hasSearchRecode },
                attrs: {
                  data: _vm.tableData,
                  height: "100%",
                  size: "mini",
                  border: "",
                },
                on: { "cell-click": _vm.cellClick },
              },
              [
                _c("template", { slot: "empty" }, [
                  _c("div", { staticClass: "tableEmpty" }, [
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "tableDiv" }, [
                      _vm._v("暂无数据"),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { width: "36", resizable: false, align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-popover",
                              {
                                staticClass: "tabPop",
                                attrs: {
                                  placement: "top-start",
                                  width: "100",
                                  trigger: "hover",
                                },
                              },
                              [
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "spBlock onHand",
                                      class: { check: _vm.chkName === "dan" },
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onHandle(
                                            "dan",
                                            scope.$index
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("选中本页")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "spBlock onHand",
                                      class: { check: _vm.chkName === "duo" },
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onHandle("duo")
                                        },
                                      },
                                    },
                                    [_vm._v("选中全部")]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-checkbox", {
                                  attrs: {
                                    slot: "reference",
                                    value:
                                      (_vm.chkName === "dan" &&
                                        _vm.checkedPage.indexOf(
                                          _vm.tableFrom.page
                                        ) > -1) ||
                                      _vm.chkName === "duo",
                                  },
                                  on: { change: _vm.changeType },
                                  slot: "reference",
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.id
                            ? [
                                !_vm.showfilterLine ||
                                (_vm.showfilterLine && scope.$index > 0)
                                  ? [
                                      _c("el-checkbox", {
                                        attrs: {
                                          value:
                                            _vm.checkedIds.indexOf(
                                              scope.row.id
                                            ) > -1 ||
                                            (_vm.chkName === "duo" &&
                                              _vm.noChecked.indexOf(
                                                scope.row.id
                                              ) === -1),
                                        },
                                        on: {
                                          change: function (v) {
                                            return _vm.changeOne(v, scope.row)
                                          },
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                              ]
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _vm._v(" "),
                _vm._l(_vm.headerList, function (item, index) {
                  return [
                    item.field == "audit_expert_name"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              width: item.width,
                              "search-key": "audit_expert_name",
                              prop: "",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.inputname == item.field + scope.row.id
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "fo",
                                                    rawName: "v-fo",
                                                  },
                                                ],
                                                staticClass: "edit-textareabox",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "edit-textareabox-span",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      scope.row[item.field] ||
                                                        "&nbsp;"
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        scope.row[item.field],
                                                      expression:
                                                        "scope.row[item.field]",
                                                    },
                                                  ],
                                                  staticClass: "edit-textarea",
                                                  style: { resize: "none" },
                                                  attrs: {
                                                    id: "",
                                                    resize: "none",
                                                  },
                                                  domProps: {
                                                    value:
                                                      scope.row[item.field],
                                                  },
                                                  on: {
                                                    input: [
                                                      function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          scope.row,
                                                          item.field,
                                                          $event.target.value
                                                        )
                                                      },
                                                      function ($event) {
                                                        return _vm.checkinput(
                                                          scope.$index,
                                                          item.field,
                                                          scope.row.id,
                                                          scope.row
                                                        )
                                                      },
                                                    ],
                                                    blur: function ($event) {
                                                      return _vm.saveoneflied(
                                                        scope.$index,
                                                        item.field,
                                                        scope.row.id,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ])
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "nowrap",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.click(
                                                    scope.$index,
                                                    item.field,
                                                    scope.row.id,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row[item.field]
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "auditItem.audit_status"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              width: item.width,
                              prop: "",
                              type: item.field,
                              "search-key": "audit_status",
                              "search-type": "radio",
                              options: [
                                { label: "待审核", value: "0" },
                                { label: "通过", value: "1" },
                                { label: "未通过", value: "-1" },
                              ],
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.auditItem
                                        ? _c("div", { staticClass: "nowrap" }, [
                                            scope.row.auditItem.audit_status ==
                                            0
                                              ? _c("span", [_vm._v("待审核")])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            scope.row.auditItem.audit_status ==
                                            1
                                              ? _c("span", [_vm._v("通过")])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            scope.row.auditItem.audit_status ==
                                            -1
                                              ? _c("span", [_vm._v("未通过")])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "product_warehouse_time"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              width: item.width,
                              prop: "",
                              "search-key": "product_warehouse_date",
                              "search-type": "date",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.product_warehouse_time
                                        ? _c("div", { staticClass: "nowrap" }, [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row
                                                    .product_warehouse_time
                                                ) +
                                                "\n                "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "image"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              width: item.width,
                              prop: "",
                              "search-query": _vm.searchQuery,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.image
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "demo-image__preview nowrap",
                                              },
                                              [
                                                scope.row.image.split(",")
                                                  .length > 1
                                                  ? [
                                                      _c(
                                                        "el-badge",
                                                        {
                                                          staticClass: "item",
                                                          attrs: {
                                                            value:
                                                              scope.row.image.split(
                                                                ","
                                                              ).length,
                                                          },
                                                        },
                                                        [
                                                          _c("el-image", {
                                                            staticStyle: {
                                                              width: "20px",
                                                              height: "20px",
                                                              "margin-right":
                                                                "5px",
                                                              display:
                                                                "inline-block!important",
                                                            },
                                                            attrs: {
                                                              src:
                                                                scope.row.image.split(
                                                                  ","
                                                                )[0] + "!120a",
                                                              "preview-src-list":
                                                                scope.row.image.split(
                                                                  ","
                                                                ),
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  : _c("el-image", {
                                                      staticStyle: {
                                                        width: "20px",
                                                        height: "20px",
                                                        "margin-right": "5px",
                                                        display:
                                                          "inline-block!important",
                                                      },
                                                      attrs: {
                                                        src:
                                                          scope.row.image.split(
                                                            ","
                                                          )[0] + "!120a",
                                                        "preview-src-list":
                                                          scope.row.image.split(
                                                            ","
                                                          ),
                                                      },
                                                    }),
                                              ],
                                              2
                                            ),
                                          ]
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "video_link"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              width: item.width,
                              prop: "",
                              "search-query": _vm.searchQuery,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.video_link
                                        ? _c("video", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              src: scope.row.video_link,
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "product_no"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              "search-key": "start_product_no",
                              "search-other-key": "end_product_no",
                              "search-type": "inputPacker",
                              index: index,
                              label: item.name,
                              width: 110,
                              prop: "product_no",
                              sortable: "custom",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.inputname == item.field + scope.row.id
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "fo",
                                                    rawName: "v-fo",
                                                  },
                                                ],
                                                staticClass: "edit-textareabox",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "edit-textareabox-span",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      scope.row[item.field] ||
                                                        "&nbsp;"
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        scope.row[item.field],
                                                      expression:
                                                        "scope.row[item.field]",
                                                    },
                                                  ],
                                                  staticClass: "edit-textarea",
                                                  style: { resize: "none" },
                                                  attrs: {
                                                    id: "",
                                                    resize: "none",
                                                  },
                                                  domProps: {
                                                    value:
                                                      scope.row[item.field],
                                                  },
                                                  on: {
                                                    input: [
                                                      function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          scope.row,
                                                          item.field,
                                                          $event.target.value
                                                        )
                                                      },
                                                      function ($event) {
                                                        return _vm.checkinput(
                                                          scope.$index,
                                                          item.field,
                                                          scope.row.id,
                                                          scope.row
                                                        )
                                                      },
                                                    ],
                                                    blur: function ($event) {
                                                      return _vm.saveoneflied(
                                                        scope.$index,
                                                        item.field,
                                                        scope.row.id,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ])
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "nowrap",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.click(
                                                    scope.$index,
                                                    item.field,
                                                    scope.row.id,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row[item.field]
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "warehouse_image"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              width: 50,
                              label: "图片",
                              "search-query": _vm.searchQuery,
                              prop: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.image.length
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "demo-image__preview nowrap",
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c("el-image", {
                                                  staticStyle: {
                                                    width: "20px",
                                                    height: "20px",
                                                    display: "block!important",
                                                    "object-fit": "contain",
                                                  },
                                                  attrs: {
                                                    src:
                                                      scope.row.image[0] +
                                                      "!m640",
                                                    "preview-src-list":
                                                      scope.row.image,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "salesmanAdmin.real_name"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              width: item.width,
                              "search-key": "salesman_admin_keyword",
                              prop: "",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.contract
                                        ? _c("div", { staticClass: "nowrap" }, [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.contract &&
                                                    scope.row.contract
                                                      .salesmanAdmin
                                                    ? scope.row.contract
                                                        .salesmanAdmin.real_name
                                                    : ""
                                                ) +
                                                "\n                "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "label"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              "search-key": "label_ids",
                              "search-type": "radio",
                              index: index,
                              label: item.name,
                              width: item.width,
                              prop: "",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                              options: _vm.labelarr,
                              "search-clear": "",
                              fixed: "right",
                            },
                            on: {
                              changeVal: _vm.searchAction,
                              selectFilterChange: _vm.onSelectFilterChange,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", [
                                        _vm.inputname ==
                                        item.field + scope.row.id
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "selected",
                                                      rawName: "v-selected",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "edit-textareabox",
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        id: "select",
                                                        placeholder: "请选择",
                                                        multiple: "",
                                                        "value-key": "label_id",
                                                        "automatic-dropdown": true,
                                                      },
                                                      on: {
                                                        "visible-change":
                                                          function ($event) {
                                                            return _vm.selectblur(
                                                              $event,
                                                              scope.$index,
                                                              item.field,
                                                              scope.row.id,
                                                              scope.row
                                                            )
                                                          },
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .label_id_arr,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "label_id_arr",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.label_id_arr",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.labelarr,
                                                      function (item) {
                                                        return _c("el-option", {
                                                          key: item.label_id,
                                                          attrs: {
                                                            label:
                                                              item.label_name,
                                                            value:
                                                              item.label_id,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ])
                                          : _c(
                                              "div",
                                              { staticClass: "nowrap" },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(scope.row.newlabel) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "enter_warehouse_type"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              "search-key": "enter_warehouse_type",
                              "search-type": "radio",
                              index: index,
                              label: item.name,
                              width: item.width,
                              prop: "",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                              options: [
                                {
                                  label: "委托入库",
                                  value: "enter_warehouse_entrust",
                                },
                                {
                                  label: "退回入库",
                                  value: "enter_warehouse_refund",
                                },
                                {
                                  label: "返还入库",
                                  value: "enter_warehouse_return",
                                },
                                {
                                  label: "退回出库",
                                  value: "out_warehouse_refund",
                                },
                                {
                                  label: "销售出库",
                                  value: "out_warehouse_order",
                                },
                                {
                                  label: "调拨出库",
                                  value: "out_warehouse_transfers",
                                },
                                {
                                  label: "借出出库",
                                  value: "out_warehouse_borrow",
                                },
                              ],
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", { staticClass: "nowrap" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              scope.row.enterWarehouseTypeText
                                            ) +
                                            "\n                "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "extend_data.refund_status"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              "search-key": "refund_status",
                              "search-type": "radio",
                              index: index,
                              label: item.name,
                              width: item.width,
                              prop: "",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                              options: [
                                { label: "已退回", value: 1 },
                                { label: "未退回", value: -1 },
                              ],
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.extend_data
                                        ? _c("div", { staticClass: "nowrap" }, [
                                            scope.row.extend_data
                                              .refund_status == 1
                                              ? _c("span", [_vm._v("已退回")])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            scope.row.extend_data
                                              .refund_status == -1
                                              ? _c("span", [_vm._v("未退回")])
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "warehouse"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              "search-key": "warehouse_id",
                              "search-type": "radio",
                              index: index,
                              label: item.name,
                              width: item.width,
                              prop: "",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                              options: _vm.storeList,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "nowrap" },
                                        [
                                          scope.row.warehouse &&
                                          scope.row.warehouse.name
                                            ? [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.warehouse.name
                                                  )
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "product_name"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              label: item.name,
                              type: item.field,
                              width: 200,
                              permission: "merchantErpV2ProductList",
                              "permission-column": "list.product_name",
                              prop: "",
                              "search-key": "product_name",
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", { staticClass: "nowrap" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(scope.row[item.field]) +
                                            "\n                "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "auction_name"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              "search-key": "auction_name",
                              index: index,
                              label: item.name,
                              width: item.width,
                              permission: "merchantErpV2ProductList",
                              "permission-column": "list.auction_name",
                              prop: "",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.inputname == item.field + scope.row.id
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "fo",
                                                    rawName: "v-fo",
                                                  },
                                                ],
                                                staticClass: "edit-textareabox",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "edit-textareabox-span",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      scope.row[item.field] ||
                                                        "&nbsp;"
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        scope.row[item.field],
                                                      expression:
                                                        "scope.row[item.field]",
                                                    },
                                                  ],
                                                  staticClass: "edit-textarea",
                                                  style: { resize: "none" },
                                                  attrs: {
                                                    id: "",
                                                    resize: "none",
                                                  },
                                                  domProps: {
                                                    value:
                                                      scope.row[item.field],
                                                  },
                                                  on: {
                                                    input: [
                                                      function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          scope.row,
                                                          item.field,
                                                          $event.target.value
                                                        )
                                                      },
                                                      function ($event) {
                                                        return _vm.checkinput(
                                                          scope.$index,
                                                          item.field,
                                                          scope.row.id,
                                                          scope.row
                                                        )
                                                      },
                                                    ],
                                                    blur: function ($event) {
                                                      return _vm.saveoneflied(
                                                        scope.$index,
                                                        item.field,
                                                        scope.row.id,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ])
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "nowrap",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.click(
                                                    scope.$index,
                                                    item.field,
                                                    scope.row.id,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row[item.field]
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "lot"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              "search-key": "start_lot",
                              "search-other-key": "end_lot",
                              "search-type": "inputPacker",
                              index: index,
                              label: item.name,
                              width: item.width,
                              prop: "lot",
                              sortable: "custom",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.inputname == item.field + scope.row.id
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "fo",
                                                    rawName: "v-fo",
                                                  },
                                                ],
                                                staticClass: "edit-textareabox",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "edit-textareabox-span",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      scope.row[item.field] ||
                                                        "&nbsp;"
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        scope.row[item.field],
                                                      expression:
                                                        "scope.row[item.field]",
                                                    },
                                                  ],
                                                  staticClass: "edit-textarea",
                                                  staticStyle: {
                                                    resize: "none",
                                                  },
                                                  attrs: {
                                                    id: "",
                                                    resize: "none",
                                                  },
                                                  domProps: {
                                                    value:
                                                      scope.row[item.field],
                                                  },
                                                  on: {
                                                    input: [
                                                      function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          scope.row,
                                                          item.field,
                                                          $event.target.value
                                                        )
                                                      },
                                                      function ($event) {
                                                        return _vm.checkinput(
                                                          scope.$index,
                                                          item.field,
                                                          scope.row.id,
                                                          scope.row
                                                        )
                                                      },
                                                    ],
                                                    blur: function ($event) {
                                                      return _vm.saveoneflied(
                                                        scope.$index,
                                                        item.field,
                                                        scope.row.id,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ])
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "nowrap",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.click(
                                                    scope.$index,
                                                    item.field,
                                                    scope.row.id,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row[item.field]
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "create_time"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              type: item.field,
                              width: item.width,
                              prop: "",
                              "search-key": "date",
                              "search-query": _vm.searchQuery,
                              "search-type": "date",
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", { staticClass: "nowrap" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(scope.row[item.field]) +
                                            "\n                "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "createAdmin"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              type: item.field,
                              width: item.width,
                              prop: "",
                              "search-key": "create_admin_keyword",
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "nowrap" },
                                        [
                                          scope.row.createAdmin
                                            ? [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.createAdmin
                                                      .real_name
                                                  )
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "retain_price"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              width: 100,
                              permission: "merchantErpV2ProductList",
                              "permission-column": "list.retain_price",
                              prop: "",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", { staticClass: "nowrap" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              scope.row[item.field]
                                                ? _vm.formatPriceNumber(
                                                    scope.row[item.field]
                                                  )
                                                : ""
                                            ) +
                                            "\n                "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "take_home_price"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              width: 100,
                              prop: "",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", { staticClass: "nowrap" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              Number(scope.row[item.field])
                                                ? _vm.formatPriceNumber(
                                                    scope.row[item.field]
                                                  )
                                                : ""
                                            ) +
                                            "\n                "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "history_pm_number"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: "历史拍卖会",
                              permission: "merchantErpV2ProductList",
                              "permission-column": "list.history_pm_number",
                              "search-key": "history_pm_venue_keyword",
                              width: item.width,
                              prop: "",
                              type: item.field,
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "nowrap" },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content:
                                                  scope.row.historyPmVenue
                                                    .map(function (m) {
                                                      return m.title
                                                    })
                                                    .join(","),
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "nowrap line1" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.historyPmVenue
                                                        .map(function (m) {
                                                          return m.title
                                                        })
                                                        .join(",")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "entrustUser"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              type: item.field,
                              width: item.width,
                              permission: "merchantErpV2ProductList",
                              "permission-column": "list.entrustUser",
                              prop: "",
                              "search-key": "entrust_keyword",
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "nowrap" },
                                        [
                                          scope.row.entrustUser
                                            ? [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.entrustUser
                                                      .nickname
                                                  ) + "\n                    "
                                                ),
                                                scope.row.entrustUser.real_name
                                                  ? [
                                                      _vm._v(
                                                        "\n                      (" +
                                                          _vm._s(
                                                            scope.row
                                                              .entrustUser
                                                              .real_name
                                                          ) +
                                                          ")\n                    "
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "contract"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              type: item.field,
                              width: item.width,
                              prop: "",
                              permission: "merchantErpV2ProductList",
                              "permission-column": "list.contract",
                              "search-key": "contract_keyword",
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.contract
                                        ? _c("div", { staticClass: "nowrap" }, [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.contract.contract_no
                                                ) +
                                                "\n                "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "content"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              type: item.field,
                              width: item.width,
                              prop: "",
                              "search-key": "content",
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.inputname == item.field + scope.row.id
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "fo",
                                                    rawName: "v-fo",
                                                  },
                                                ],
                                                staticClass: "edit-textareabox",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "edit-textareabox-span",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      scope.row[item.field] ||
                                                        "&nbsp;"
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        scope.row[item.field],
                                                      expression:
                                                        "scope.row[item.field]",
                                                    },
                                                  ],
                                                  staticClass: "edit-textarea",
                                                  style: { resize: "none" },
                                                  attrs: {
                                                    id: "",
                                                    resize: "none",
                                                  },
                                                  domProps: {
                                                    value:
                                                      scope.row[item.field],
                                                  },
                                                  on: {
                                                    input: [
                                                      function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          scope.row,
                                                          item.field,
                                                          $event.target.value
                                                        )
                                                      },
                                                      function ($event) {
                                                        return _vm.checkinput(
                                                          scope.$index,
                                                          item.field,
                                                          scope.row.id,
                                                          scope.row
                                                        )
                                                      },
                                                    ],
                                                    blur: function ($event) {
                                                      return _vm.saveoneflied(
                                                        scope.$index,
                                                        item.field,
                                                        scope.row.id,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ])
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "nowrap",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.click(
                                                    scope.$index,
                                                    item.field,
                                                    scope.row.id,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row[item.field]
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "remark"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              type: item.field,
                              width: item.width,
                              prop: "",
                              "search-key": "remark",
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.inputname == item.field + scope.row.id
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "fo",
                                                    rawName: "v-fo",
                                                  },
                                                ],
                                                staticClass: "edit-textareabox",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "edit-textareabox-span",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      scope.row[item.field] ||
                                                        "&nbsp;"
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        scope.row[item.field],
                                                      expression:
                                                        "scope.row[item.field]",
                                                    },
                                                  ],
                                                  staticClass: "edit-textarea",
                                                  style: { resize: "none" },
                                                  attrs: {
                                                    id: "",
                                                    resize: "none",
                                                  },
                                                  domProps: {
                                                    value:
                                                      scope.row[item.field],
                                                  },
                                                  on: {
                                                    input: [
                                                      function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          scope.row,
                                                          item.field,
                                                          $event.target.value
                                                        )
                                                      },
                                                      function ($event) {
                                                        return _vm.checkinput(
                                                          scope.$index,
                                                          item.field,
                                                          scope.row.id,
                                                          scope.row
                                                        )
                                                      },
                                                    ],
                                                    blur: function ($event) {
                                                      return _vm.saveoneflied(
                                                        scope.$index,
                                                        item.field,
                                                        scope.row.id,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ])
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "nowrap",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.click(
                                                    scope.$index,
                                                    item.field,
                                                    scope.row.id,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    scope.row[item.field]
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.type == "attribute"
                      ? [
                          item.name == "尺寸"
                            ? _c("ytx-table-column", {
                                key: "table" + index,
                                attrs: {
                                  "search-key": item.field,
                                  index: index,
                                  label: item.name,
                                  width: item.width,
                                  prop: "",
                                  type: item.field,
                                  "search-query": _vm.attributejson,
                                  "show-overflow-tooltip": "",
                                },
                                on: { changeVal: _vm.searchattrAction },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("div", {
                                            staticClass: "nowrap cur",
                                            staticStyle: { height: "100%" },
                                            domProps: {
                                              innerHTML: _vm._s(
                                                scope.row.attrjson[item.field]
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onClickSize(
                                                  scope.row.attrjson[
                                                    item.field
                                                  ],
                                                  scope.$index,
                                                  scope.row,
                                                  item.field
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            : _c("ytx-table-column", {
                                key: "table" + index,
                                attrs: {
                                  "search-key": item.field,
                                  index: index,
                                  label: item.name,
                                  width: 80,
                                  prop: "",
                                  type: item.field,
                                  "search-query": _vm.attributejson,
                                },
                                on: { changeVal: _vm.searchattrAction },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("div", [
                                            _vm.inputname ==
                                            item.field + scope.row.id
                                              ? _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "fo",
                                                          rawName: "v-fo",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "edit-textareabox",
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass:
                                                          "edit-textareabox-span",
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            scope.row.attrjson[
                                                              item.field
                                                            ] || "&nbsp;"
                                                          ),
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("textarea", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              scope.row
                                                                .attrjson[
                                                                item.field
                                                              ],
                                                            expression:
                                                              "scope.row.attrjson[item.field]",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "edit-textarea",
                                                        style: {
                                                          resize: "none",
                                                        },
                                                        attrs: {
                                                          id: "",
                                                          resize: "none",
                                                        },
                                                        domProps: {
                                                          value:
                                                            scope.row.attrjson[
                                                              item.field
                                                            ],
                                                        },
                                                        on: {
                                                          input: [
                                                            function ($event) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                scope.row
                                                                  .attrjson,
                                                                item.field,
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                            function ($event) {
                                                              return _vm.checkinput(
                                                                scope.$index,
                                                                "attribute",
                                                                scope.row.id,
                                                                scope.row,
                                                                item.field
                                                              )
                                                            },
                                                          ],
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.saveoneflied(
                                                              scope.$index,
                                                              "attribute",
                                                              scope.row.id,
                                                              scope.row,
                                                              item.field
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ])
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass: "nowrap",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.click(
                                                          scope.$index,
                                                          "attribute",
                                                          scope.row.id,
                                                          scope.row,
                                                          item.field
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          scope.row.attrjson[
                                                            item.field
                                                          ] || " "
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                        ]
                      : item.field == "currentPmAlbum"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              "search-type": "cascader",
                              "select-prop": _vm.optionsAuction,
                              options: _vm.erpAuctionList,
                              label: item.name,
                              type: item.field,
                              width: item.width,
                              permission: "merchantErpV2ProductList",
                              "permission-column": "list.currentPmAlbum",
                              prop: "",
                              "search-key": "current_pm_album_id",
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "nowrap" },
                                        [
                                          scope.row.currentPmAlbum
                                            ? [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.currentPmAlbum
                                                      .album_name
                                                  ) +
                                                    " -\n                    " +
                                                    _vm._s(
                                                      scope.row.currentPmAlbum
                                                        .venue.title
                                                    )
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : item.field == "category"
                      ? [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              "search-type": "cascader",
                              "select-prop": _vm.propsMer,
                              options: _vm.merCateList,
                              label: item.name,
                              type: item.field,
                              width: item.width,
                              prop: "",
                              "search-key": "cate_id",
                              "search-query": _vm.searchQuery,
                            },
                            on: { changeVal: _vm.searchAction },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", [
                                        _vm.inputname ==
                                        item.field + scope.row.id
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "edit-textareabox",
                                                },
                                                [
                                                  _c("el-cascader", {
                                                    ref:
                                                      "category" + scope.row.id,
                                                    refInFor: true,
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      options: _vm.merCateList,
                                                      props: _vm.props,
                                                      filterable: "",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.saveoneflied(
                                                          scope.$index,
                                                          item.field,
                                                          scope.row.id,
                                                          scope.row,
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: scope.row.cate_id,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "cate_id",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.cate_id",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ])
                                          : _c(
                                              "div",
                                              {
                                                staticClass: "nowrap",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.click(
                                                      scope.$index,
                                                      item.field
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      scope.row.newcate || " "
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      : [
                          _c("ytx-table-column", {
                            key: "table" + index,
                            attrs: {
                              index: index,
                              label: item.name,
                              type: item.field,
                              width: 100,
                              prop: "",
                              "search-query": _vm.searchQuery,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [_c("div")]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                  ]
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  attrs: {
                    label: " ",
                    prop: "",
                    align: "center",
                    "search-query": _vm.searchQuery,
                  },
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  attrs: {
                    label: "审核结果",
                    prop: "",
                    align: "center",
                    fixed: "right",
                    width: "100",
                    "search-query": _vm.searchQuery,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-dropdown",
                            { attrs: { type: "primary", placement: "bottom" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ft14 cur",
                                  style: {
                                    color: scope.row.auction_audit_status
                                      ? scope.row.auction_audit_status == 1
                                        ? "#6c8c25"
                                        : "#91381e"
                                      : "",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        scope.row.auction_audit_status
                                          ? scope.row.auction_audit_status == 1
                                            ? "通过"
                                            : "未通过"
                                          : "点击选择"
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            "text-align": "left",
                                          },
                                          attrs: {
                                            size: "small",
                                            type: "text",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onUpdStatus(
                                                scope.row,
                                                1
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("通过")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-item",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            "text-align": "left",
                                          },
                                          attrs: {
                                            size: "small",
                                            type: "text",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onUpdStatus(
                                                scope.row,
                                                -1
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("未通过")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("ytx-table-column", {
                  attrs: {
                    label: "操作",
                    prop: "",
                    fixed: "right",
                    align: "center",
                    width: "80",
                    "search-query": _vm.searchQuery,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.onRemove(scope.row)
                                },
                              },
                            },
                            [_vm._v("移除")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                false
                  ? [
                      _c("ytx-table-column", {
                        attrs: {
                          "search-key": "start_product_no",
                          "search-other-key": "end_product_no",
                          "search-type": "inputPacker",
                          label: "库号",
                          prop: "",
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "nowrap" }, [
                                    _vm._v(_vm._s(scope.row.product_no)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3896904535
                        ),
                      }),
                      _vm._v(" "),
                      _c("ytx-table-column", {
                        attrs: { label: "图片", prop: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.image.length
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "demo-image__preview nowrap",
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center",
                                            },
                                          },
                                          [
                                            _c("el-image", {
                                              staticStyle: {
                                                width: "20px",
                                                height: "20px",
                                                "margin-right": "5px",
                                                display: "block!important",
                                              },
                                              attrs: {
                                                src:
                                                  scope.row.image[0] + "!120a",
                                                "preview-src-list":
                                                  scope.row.image,
                                              },
                                            }),
                                            _vm._v(" "),
                                            scope.row.image.length > 1
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "12px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "共" +
                                                        _vm._s(
                                                          scope.row.image.length
                                                        ) +
                                                        "张"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3748377424
                        ),
                      }),
                      _vm._v(" "),
                      _c("ytx-table-column", {
                        attrs: {
                          "search-key": "product_name",
                          label: "货品名称",
                          prop: "",
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "nowrap" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(scope.row.product_name) +
                                        "\n              "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          405522961
                        ),
                      }),
                      _vm._v(" "),
                      _c("ytx-table-column", {
                        attrs: {
                          "search-key": "warehouse_id",
                          "search-type": "radio",
                          label: "所在库房",
                          prop: "",
                          "search-query": _vm.searchQuery,
                          options: _vm.storeList,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "nowrap" },
                                    [
                                      scope.row.warehouse &&
                                      scope.row.warehouse.name
                                        ? [
                                            _vm._v(
                                              _vm._s(scope.row.warehouse.name)
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1706206272
                        ),
                      }),
                      _vm._v(" "),
                      _c("ytx-table-column", {
                        attrs: { label: "货架", prop: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "nowrap" }, [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.shelf
                                          ? scope.row.shelf.shelf_no
                                          : ""
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3271846115
                        ),
                      }),
                      _vm._v(" "),
                      _c("ytx-table-column", {
                        attrs: {
                          "search-key": "entrust_keyword",
                          label: "委托方",
                          prop: "",
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.entrustUser
                                    ? _c("div", { staticClass: "nowrap" }, [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.entrustUser.real_name
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3591794481
                        ),
                      }),
                      _vm._v(" "),
                      _c("ytx-table-column", {
                        attrs: {
                          "search-key": "salesman_admin_keyword",
                          label: "业务员",
                          prop: "",
                          "search-query": _vm.searchQuery,
                        },
                        on: { changeVal: _vm.searchAction },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.contract
                                    ? _c("div", { staticClass: "nowrap" }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              scope.row.contract &&
                                                scope.row.contract.salesmanAdmin
                                                ? scope.row.contract
                                                    .salesmanAdmin.real_name
                                                : ""
                                            ) +
                                            "\n              "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3586512308
                        ),
                      }),
                      _vm._v(" "),
                      _c("ytx-table-column", {
                        attrs: { label: "审核专家", prop: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "nowrap" }, [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.auditItem &&
                                          scope.row.auditItem.auditAdmin
                                          ? scope.row.auditItem.auditAdmin
                                              .real_name
                                          : ""
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3347823827
                        ),
                      }),
                      _vm._v(" "),
                      _c("ytx-table-column", {
                        attrs: { label: "审核时间", prop: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "nowrap" }, [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.auditItem
                                          ? scope.row.auditItem.audit_time
                                          : ""
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          985682094
                        ),
                      }),
                      _vm._v(" "),
                      _c("ytx-table-column", {
                        attrs: { label: "审核结果", prop: "", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-dropdown",
                                    {
                                      attrs: {
                                        type: "primary",
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "ft12 cur",
                                          style: {
                                            color:
                                              scope.row.auditItem &&
                                              scope.row.auditItem.audit_status
                                                ? scope.row.auditItem
                                                    .audit_status == 1
                                                  ? "#6c8c25"
                                                  : "#91381e"
                                                : "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                scope.row.auditItem &&
                                                  scope.row.auditItem
                                                    .audit_status
                                                  ? scope.row.auditItem
                                                      .audit_status == 1
                                                    ? "通过"
                                                    : "未通过"
                                                  : "点击选择"
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown",
                                        },
                                        [
                                          _c(
                                            "el-dropdown-item",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                    "text-align": "left",
                                                  },
                                                  attrs: {
                                                    size: "small",
                                                    type: "text",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onUpdStatus(
                                                        scope.row,
                                                        1
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("通过")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-dropdown-item",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                    "text-align": "left",
                                                  },
                                                  attrs: {
                                                    size: "small",
                                                    type: "text",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onUpdStatus(
                                                        scope.row,
                                                        -1
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("未通过")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1875756734
                        ),
                      }),
                      _vm._v(" "),
                      _c("ytx-table-column", {
                        attrs: { label: "操作", prop: "", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small", type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onRemove(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("移除")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2004242341
                        ),
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  position: "fixed",
                  left: "-9999px",
                  top: "-9999px",
                },
              },
              [
                _vm.printType == 1
                  ? _c(
                      "div",
                      {
                        staticStyle: { width: "1000px" },
                        attrs: { id: "printorder" },
                      },
                      _vm._l(_vm.printData, function (pageData, pageIndex) {
                        return _c("div", { key: "page_" + pageIndex }, [
                          _c(
                            "div",
                            { staticClass: "ptd-box" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ptd",
                                  staticStyle: { "font-weight": "bold" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-title",
                                      staticStyle: { flex: "1" },
                                    },
                                    [_c("span", [_vm._v("库号")])]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-title",
                                      staticStyle: { width: "60px" },
                                    },
                                    [_c("span", [_vm._v("图片")])]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-title",
                                      staticStyle: { flex: "2" },
                                    },
                                    [_c("span", [_vm._v("货品名称")])]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.headerList,
                                    function (item, index) {
                                      return [
                                        item.attr_type == 1
                                          ? _c(
                                              "div",
                                              {
                                                key: "header_" + index,
                                                staticClass: "ptd-title",
                                                staticStyle: { flex: "1" },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.name)),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-title",
                                      staticStyle: { flex: "1" },
                                    },
                                    [_c("span", [_vm._v("保留价")])]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ptd-title",
                                      staticStyle: { flex: "1" },
                                    },
                                    [_c("span", [_vm._v("实得价")])]
                                  ),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm._l(pageData, function (m, i) {
                                return _c(
                                  "div",
                                  {
                                    key: "order_" + pageIndex + "_" + i,
                                    staticClass: "ptd",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        staticStyle: { flex: "1" },
                                      },
                                      [
                                        _c("div", { staticClass: "line2" }, [
                                          _vm._v(_vm._s(m.product_no)),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        staticStyle: { width: "60px" },
                                      },
                                      [
                                        m.image
                                          ? [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "40px",
                                                  height: "40px",
                                                  display: "block!important",
                                                  "object-fit": "contain",
                                                },
                                                attrs: {
                                                  src:
                                                    m.image +
                                                    "?x-oss-process=image/auto-orient,1/resize,m_pad,w_100,h_100/quality,q_84/format,webp",
                                                },
                                                on: { load: _vm.loadImgEnd },
                                              }),
                                            ]
                                          : [
                                              _c("div", {
                                                staticStyle: {
                                                  width: "40px",
                                                  height: "40px",
                                                },
                                              }),
                                            ],
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        staticStyle: { flex: "2" },
                                      },
                                      [
                                        _c("div", { staticClass: "line2" }, [
                                          _vm._v(_vm._s(m.product_name)),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.headerList,
                                      function (item, index) {
                                        return [
                                          item.attr_type == 1
                                            ? _c(
                                                "div",
                                                {
                                                  key: "body_" + index,
                                                  staticClass: "ptd-body",
                                                  staticStyle: { flex: "1" },
                                                },
                                                [
                                                  m.attribute
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass: "line2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              m.attribute[
                                                                item.field
                                                              ]
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      }
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        staticStyle: { flex: "1" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            Number(m.retain_price)
                                              ? _vm.formatPriceNumber(
                                                  m.retain_price
                                                )
                                              : ""
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ptd-body",
                                        staticStyle: { flex: "1" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            Number(m.take_home_price)
                                              ? _vm.formatPriceNumber(
                                                  m.take_home_price
                                                )
                                              : ""
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  2
                                )
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "page-break-after": "always",
                                "text-align": "center",
                                "font-size": "10px",
                                "padding-top": "40px",
                              },
                            },
                            [_vm._v("第 " + _vm._s(pageIndex + 1) + " 页")]
                          ),
                        ])
                      }),
                      0
                    )
                  : _vm.printType == 2
                  ? _c(
                      "div",
                      {
                        staticStyle: { width: "1000px" },
                        attrs: { id: "printorder" },
                      },
                      _vm._l(_vm.printData, function (pageData, pageIndex) {
                        return _c("div", { key: "page_" + pageIndex }, [
                          _c(
                            "div",
                            { staticClass: "nine-square-grid" },
                            _vm._l(pageData, function (m, i) {
                              return _c(
                                "div",
                                {
                                  key: "order_" + pageIndex + "_" + i,
                                  staticClass: "nine-square-grid-item",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "pic" },
                                    [
                                      m.image
                                        ? [
                                            _c("img", {
                                              attrs: {
                                                src:
                                                  m.image +
                                                  "?x-oss-process=image/auto-orient,1/resize,m_pad,w_300,h_300/quality,q_90/format,webp",
                                              },
                                              on: { load: _vm.loadImgEnd },
                                            }),
                                          ]
                                        : [_c("div")],
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "desc" }, [
                                    _c("div", { staticClass: "name" }, [
                                      _vm._v(_vm._s(m.product_name)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "info" },
                                      [
                                        _vm._l(
                                          _vm.headerList,
                                          function (item, index) {
                                            return [
                                              item.attr_type == 1 &&
                                              m.attribute &&
                                              m.attribute[item.field]
                                                ? _c(
                                                    "span",
                                                    { key: "body_" + index },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          m.attribute[
                                                            item.field
                                                          ]
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          }
                                        ),
                                        _vm._v(" "),
                                        Number(m.retain_price)
                                          ? _c("span", [
                                              _vm._v(
                                                "保留价 " +
                                                  _vm._s(
                                                    _vm.formatPriceNumber(
                                                      m.retain_price
                                                    )
                                                  )
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        Number(m.take_home_price)
                                          ? _c("span", [
                                              _vm._v(
                                                "实得价 " +
                                                  _vm._s(
                                                    _vm.formatPriceNumber(
                                                      m.take_home_price
                                                    )
                                                  )
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "page-break-after": "always",
                                "text-align": "center",
                                "font-size": "10px",
                                "padding-top": "40px",
                              },
                            },
                            [_vm._v("第 " + _vm._s(pageIndex + 1) + " 页")]
                          ),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex justify-b orderFoot" }, [
        _c("div", { staticClass: "flex-one flex align-items-c" }, [
          _c(
            "span",
            { staticClass: "mr20", staticStyle: { "font-size": "15px" } },
            [
              _vm._v("已选"),
              _c(
                "span",
                { staticStyle: { "font-weight": "500", margin: "0 5px" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.allCheck
                        ? _vm.total - _vm.noChecked.length
                        : _vm.checkedIds.length
                    )
                  ),
                ]
              ),
              _vm._v("条"),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mr10" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    disabled: !_vm.allCheck && !_vm.checkedIds.length,
                  },
                  on: { click: _vm.editLabelAll },
                },
                [_vm._v("编辑标签")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mr10" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    disabled: !_vm.allCheck && !_vm.checkedIds.length,
                  },
                  on: { click: _vm.onRemoveAll },
                },
                [_vm._v("批量移除")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-dropdown",
                { attrs: { type: "primary", placement: "top" } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary", size: "small" } },
                    [_vm._v("打印清单")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                width: "100%",
                                "text-align": "center",
                              },
                              attrs: { size: "small", type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.onPrint(1)
                                },
                              },
                            },
                            [_vm._v("打印表格")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                width: "100%",
                                "text-align": "center",
                              },
                              attrs: { size: "small", type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.onPrint(2)
                                },
                              },
                            },
                            [_vm._v("打印九宫格")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-pagination", {
              staticStyle: { "margin-right": "10px" },
              attrs: {
                "page-sizes": [10, 20, 40, 60, 80],
                "page-size": _vm.tableFrom.limit,
                "current-page": _vm.tableFrom.page,
                layout: "total, prev, pager, next, sizes ",
                total: _vm.total,
                background: "",
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.pageChange,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置标签",
            visible: _vm.setLabelData.show,
            width: "750px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setLabelData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    placeholder: "请选择标签",
                    multiple: "",
                    "value-key": "label_id",
                  },
                  model: {
                    value: _vm.setLabelData.labelIds,
                    callback: function ($$v) {
                      _vm.$set(_vm.setLabelData, "labelIds", $$v)
                    },
                    expression: "setLabelData.labelIds",
                  },
                },
                _vm._l(_vm.labelarr, function (item) {
                  return _c("el-option", {
                    key: item.label_id,
                    attrs: { label: item.label_name, value: item.label_id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { margin: "20px 0 10px 0" } },
            [
              _c("el-button", { on: { click: _vm.clearSaveLabelAction } }, [
                _vm._v("取消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveLabelAction },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("SetColumn", {
        ref: "setColumnEL",
        attrs: { types: "erp_v2_product_auction_audit_list" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }