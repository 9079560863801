var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "posterMain" },
    [
      _c("posterHeader", {
        ref: "videoHeadRef",
        on: { getNumber: _vm.getNumber },
      }),
      _vm._v(" "),
      _c("img", {
        staticStyle: { width: "100%", "margin-top": "10px", display: "block" },
        attrs: { src: require("@/assets/images/poster/advert.png"), alt: "" },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "nodi",
          staticStyle: { "overflow-x": "auto", "margin-top": "20px" },
        },
        [
          _c("div", { staticClass: "poster-type-container" }, [
            _c(
              "div",
              {
                staticClass: "poster-type-item",
                class: _vm.activeType == "1" ? "type-active" : "",
                on: {
                  click: function ($event) {
                    return _vm.tagChange("1")
                  },
                },
              },
              [_vm._v("我的收藏 " + _vm._s(_vm.myRelationCount))]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "poster-type-item",
                class: _vm.activeType == "" ? "type-active" : "",
                on: {
                  click: function ($event) {
                    return _vm.tagChange("")
                  },
                },
              },
              [_vm._v("我的专属 " + _vm._s(_vm.myCustomCount))]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "poster-type-container ml24",
          staticStyle: { "margin-top": "10px" },
        },
        [
          _c(
            "div",
            {
              staticClass: "poster-type-item",
              class: _vm.levelType == 1 ? "type-active" : "",
              on: {
                click: function ($event) {
                  return _vm.leveTagChange(1)
                },
              },
            },
            [_vm._v("普通 " + _vm._s(_vm.myRelationOrdinaryCount))]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "poster-type-item",
              class: _vm.levelType == 2 ? "type-active" : "",
              on: {
                click: function ($event) {
                  return _vm.leveTagChange(2)
                },
              },
            },
            [_vm._v("高级 " + _vm._s(_vm.myRelationSeniorCount))]
          ),
        ]
      ),
      _vm._v(" "),
      _c("grid-manager", {
        staticStyle: { "margin-top": "20px" },
        attrs: {
          gridList: _vm.templist,
          footHeight: 35,
          "is-loading": _vm.loadTemplate,
        },
        on: { loadMore: _vm.onloadMore },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              var index = ref.index
              return [
                _c(
                  "div",
                  {
                    staticClass: "img-box",
                    on: {
                      mouseenter: function ($event) {
                        return _vm.playVideo(row, index)
                      },
                      mouseleave: function ($event) {
                        return _vm.stopVideo(row, index)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "img-view",
                        staticStyle: { height: "calc(100% - 35px)" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "hb-conStatus",
                            style: _vm.activeType == "" ? "right: 10px" : "",
                            on: {
                              click: function ($event) {
                                return _vm.onClickTempl(row)
                              },
                            },
                          },
                          [_vm._v("做同款")]
                        ),
                        _vm._v(" "),
                        _vm.activeType == "1"
                          ? [
                              _vm.type_ids.includes(row.id + "")
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "position-img",
                                      staticStyle: { right: "10px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onDelCollect(row)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "dark",
                                            content: "取消收藏",
                                            placement: "top",
                                            enterable: false,
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/poster/sc.png"),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass: "position-img",
                                      staticStyle: { right: "10px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onCollect(row)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "dark",
                                            content: "收藏",
                                            placement: "top",
                                            enterable: false,
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/poster/scBlack.png"),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c("video", {
                          ref: "videos" + index,
                          staticStyle: { width: "100%", display: "block" },
                          attrs: { src: row.exampleVideo, muted: "" },
                          domProps: { muted: true },
                        }),
                        _vm._v(" "),
                        _c("el-image", {
                          staticClass: "posIimg",
                          style:
                            _vm.videoIndex === index
                              ? "z-index: -1"
                              : "z-index: 9",
                          attrs: { src: "" + row.coverImage },
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex videoItemTit" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.levelType == 2,
                                expression: "row.levelType == 2",
                              },
                            ],
                            attrs: {
                              content: "高级",
                              placement: "top",
                              effect: "light",
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "leveImg",
                              attrs: {
                                src: require("@/assets/images/poster/leve2.png"),
                                alt: "",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.levelType == 3,
                                expression: "row.levelType == 3",
                              },
                            ],
                            attrs: {
                              content: "定制",
                              placement: "top",
                              effect: "light",
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "leveImg",
                              attrs: {
                                src: require("@/assets/images/poster/leveg.png"),
                                alt: "",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex flex-one" }, [
                          _c("div", { staticClass: "videott" }, [
                            _vm._v(_vm._s(row.templateName)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "10px" } },
                            [_vm._v(_vm._s(row.ratio))]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }