"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _control = _interopRequireDefault(require("@/components/customPage/control.vue"));
var _colour = _interopRequireDefault(require("@/components/customPage/colour.vue"));
var _slider = _interopRequireDefault(require("@/components/customPage/slider.vue"));
var _system = require("@/api/system");
var _ChangeMap = _interopRequireDefault(require("@/components/ChangeMap"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    control: _control.default,
    colour: _colour.default,
    slider: _slider.default,
    ChangeMap: _ChangeMap.default
  },
  data: function data() {
    return {};
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {
    content: {
      get: function get() {
        return this.$store.getters.content;
      },
      set: function set(value) {
        var data = {
          key: 'content',
          value: value
        };
        this.$store.commit('finish/setAttribute', data);
      }
    },
    facade: {
      get: function get() {
        return this.$store.getters.facade;
      },
      set: function set(value) {
        var data = {
          key: 'facade',
          value: value
        };
        this.$store.commit('finish/setAttribute', data);
      }
    }
  },
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {},
  /**
   * 页面渲染前
   * @return {[type]} [description]
   */
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    if (!this.facade.isSet) {
      (0, _system.getConfigClassKeys)('weChatTemplateContactInformation').then(function (res) {
        if (res.data.position) {
          _this.content = JSON.parse(res.data.position);
          if (_this.facade) {
            _this.facade.isSet = true;
          } else {
            _this.facade = {
              isSet: true
            };
          }
        }
      });
    }
  },
  methods: {
    getAddressDataAction: function getAddressDataAction(item) {
      this.content.address_name = item.title;
      this.content.address = item.address;
      this.content.address_item = item;
    }
  }
};