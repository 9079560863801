var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "mb20",
      attrs: {
        model: _vm.content,
        "label-width": "80px",
        size: "mini",
        "label-position": "left",
      },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c("div", { staticClass: "pt10 ft14" }, [
        _c("div", { staticStyle: { "margin-top": "5px" } }, [
          _vm._v("输入一个含有视频号的图文链接，或者新建一个图文，步骤如下："),
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { "margin-top": "5px" } }, [
          _vm._v("1.公众号后台新建一个图文，进入公众号编辑器"),
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { "margin-top": "5px" } }, [
          _vm._v(
            "2.编辑器顶部选择视频号，然后搜索需要添加的视频号，加入编辑区"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { "margin-top": "5px" } }, [
          _vm._v(
            "3.保存，回到后台素材管理页面，点击刚刚新建图文的标题，浏览器会打开它的临时链接"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { "margin-top": "5px" } }, [
          _vm._v("4.复制上述临时链接地址到输入框，然后确认。"),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 flex" },
        [
          _c(
            "div",
            { staticClass: "flex-one mr10" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入公众号临时链接地址" },
                model: {
                  value: _vm.content.inp,
                  callback: function ($$v) {
                    _vm.$set(_vm.content, "inp", $$v)
                  },
                  expression: "content.inp",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirmUrlAction } },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }