"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user.js");
var _system = require("@/api/system.js");
var _index = require("@/utils/index");
var _order = require("@/api/order");
var _printJs = _interopRequireDefault(require("print-js"));
var _zhTW = _interopRequireDefault(require("@/utils/zh-TW.json"));
var _system2 = require("@/api/system");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default
  },
  filters: {
    filterPercent: function filterPercent(val) {
      var remainder = val % 10;
      if (remainder > 0) val;else return val / 10;
    }
  },
  props: {
    preview: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      parseTime: _index.parseTime,
      ids: '',
      uid: '',
      source: '',
      formatPriceNumber: _index.formatPriceNumber,
      collectMoney: {
        show: false,
        data: ''
      },
      total_price: 0,
      printObj2: {
        id: 'print1',
        popTitle: ''
      },
      fee_type: '',
      feeTypeInfo: {},
      isOutbound: false,
      qrcodeImg: '',
      merData: {},
      no: '',
      showSettings: false,
      settingKey: '',
      detail: {
        title: {
          china: '上拍通知书',
          english: 'Auction Notice'
        },
        buyerCompany: {
          china: '您所委托的作品有 {count} 件上拍，具体情况如下，如有问题，请联系本公司业务，感谢您长期以来对本公司的支持！',
          english: "here are {count} works that you have commissioned for auction. The specific situation is as follows. If you have any questions, please contact our business. Thank you for your long-term support to our company!"
        },
        tableColumn: [{
          open: true,
          name: '图录号',
          showName: '',
          english: 'LOT NO.',
          w: 86
        }, {
          open: true,
          name: '专场名称',
          showName: '',
          english: 'Sess.Name'
        }, {
          open: true,
          name: '拍品名称',
          showName: '',
          english: 'DESCRIPTION'
        }, {
          open: true,
          name: '合同号',
          showName: '',
          english: 'CONTRACT NO.',
          w: 130
        }, {
          open: true,
          name: '起拍价',
          showName: '',
          english: '',
          w: 130
        }, {
          open: true,
          name: '尺寸',
          showName: '',
          english: '',
          w: 130
        }, {
          open: true,
          name: '作者',
          showName: '',
          english: '',
          w: 130
        }],
        settleText: {
          china: '本次拍卖会拍卖场次安排如下:',
          english: 'The auction schedule for this auction is arranged as follows.'
        },
        tableTwoColumn: [{
          open: true,
          name: '序号',
          showName: '',
          english: 'NO.',
          w: 150
        }, {
          open: true,
          name: '拍卖专场',
          showName: '',
          english: 'Auct. Sess.'
        }, {
          open: true,
          name: '图录号',
          showName: '',
          english: 'LOT NO.',
          w: 150
        }, {
          open: true,
          name: '拍卖日期',
          showName: '',
          english: 'Auct. Date',
          w: 150
        }, {
          open: true,
          name: '拍卖时间',
          showName: '',
          english: 'Auct. Time',
          w: 150
        }, {
          open: false,
          name: '结拍日期',
          showName: '',
          english: 'End Date',
          w: 150
        }, {
          open: false,
          name: '结拍时间',
          showName: '',
          english: 'End Time',
          w: 150
        }, {
          open: true,
          name: '拍卖厅',
          showName: '',
          english: 'Auct. Hall',
          w: 150
        }],
        signColumnTab: 'system',
        signColumn: {},
        signColumnCustome: ''
      },
      editKey: ''
    };
  },
  computed: {
    feeTypeObj: function feeTypeObj() {
      return this.$store.getters.feeTypeInfo;
    },
    getWidth: function getWidth() {
      return 'width: 130px;';
    },
    getForeWidth: function getForeWidth() {
      var w = 0;
      if (this.detail.tableColumn[2] && this.detail.tableColumn[2].open && this.detail.tableColumn[2].w) {
        w += this.detail.tableColumn[2].w;
      }
      if (this.detail.tableColumn[3] && this.detail.tableColumn[3].open && this.detail.tableColumn[3].w) {
        w += this.detail.tableColumn[3].w;
      }
      if (this.detail.tableColumn[4] && this.detail.tableColumn[4].open && this.detail.tableColumn[4].w) {
        w += this.detail.tableColumn[4].w;
      }
      if (this.detail.tableColumn[5] && this.detail.tableColumn[5].open && this.detail.tableColumn[5].w) {
        w += this.detail.tableColumn[5].w;
      }
      return "width: ".concat(w, "px;");
    }
  },
  mounted: function mounted() {
    var _this = this;
    (0, _user.getBaseInfo)().then(function (res) {
      res.data.company_english_name = res.data.config.company_english_name;
      _this.merData = res.data;
    });
    (0, _system2.getConfigClassKeys)('print_config').then(function (res) {
      if (res.data.print_entrust_auction_create) {
        var d = JSON.parse(res.data.print_entrust_auction_create);
        if (!d.signColumnTab) d.signColumnTab = 'system';
        if (!d.tableTwoColumn[6]) {
          d.tableTwoColumn.splice(5, 0, {
            open: false,
            name: '结拍日期',
            showName: '',
            english: 'End Date',
            w: 150
          });
          d.tableTwoColumn.splice(6, 0, {
            open: false,
            name: '结拍时间',
            showName: '',
            english: 'End Time',
            w: 150
          });
        }
        // if (d.signColumnCustome) {
        //   this.$refs['editor'].setContent(d.signColumnCustome);
        // }
        _this.detail = d;
      }
    });
  },
  methods: {
    saveAction: function saveAction() {
      var _this2 = this;
      (0, _system2.configClassKeysSave)('print_config', {
        print_entrust_auction_create: JSON.stringify(this.detail)
      }).then(function () {
        _this2.$message.success('保存成功');
      });
    },
    focus: function focus(e) {
      e.currentTarget.select();
    },
    goEditAction: function goEditAction(key) {
      this.settingKey = key;
      this.showSettings = true;
    },
    switchLang: function switchLang(filed) {
      if (this.feeTypeObj.mer_fee_type == 'HKD') {
        return _zhTW.default.TW[filed];
      } else return _zhTW.default.CN[filed];
    }
  }
};