var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "has-logo": _vm.showLogo },
      staticStyle: {
        background: "#fff",
        "box-shadow": "0px 0px 40px 0px rgba(0,0,0,0.05)",
      },
    },
    [
      _vm.showLogo
        ? _c("logo", {
            attrs: { menuType: _vm.menuType, collapse: _vm.isCollapse },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper myMenuType" } },
        [
          _c(
            "el-menu",
            {
              ref: "menuEL",
              class: _vm.menuType ? "newMenu" : "",
              attrs: {
                "default-active": _vm.activeMenu,
                "default-openeds": _vm.defaultOpen,
                collapse: _vm.isCollapse,
                "background-color": _vm.variables.menuBg,
                "text-color": _vm.variables.menuText,
                "unique-opened": false,
                "active-text-color": _vm.variables.menuActiveText,
                "collapse-transition": false,
                mode: "vertical",
              },
              on: { close: _vm.closeMenuAction },
            },
            _vm._l(_vm.menuList, function (route) {
              return _c("sidebar-item", {
                key: route.route,
                attrs: {
                  item: route,
                  active: _vm.activeMenu,
                  "base-path": route.route,
                  "menu-type": _vm.menuType,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dropdown",
        { attrs: { trigger: "click" } },
        [
          _c("div", { staticClass: "menuFootBox flex" }, [
            _c("img", {
              staticClass: "logoImg",
              attrs: { src: _vm.comInfo.mer_logo, alt: "" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "flex-one " }, [
              _vm._v(_vm._s(_vm.comInfo.mer_name)),
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: "dddimg",
              attrs: {
                src: require("@/assets/images/poster/ddd.png"),
                alt: "",
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c(
                "el-dropdown-item",
                {
                  nativeOn: {
                    click: function ($event) {
                      return _vm.goUser($event)
                    },
                  },
                },
                [
                  _c("span", { staticStyle: { display: "block" } }, [
                    _vm._v("个人中心"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-dropdown-item",
                {
                  attrs: { divided: "" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.goPassword($event)
                    },
                  },
                },
                [
                  _c("span", { staticStyle: { display: "block" } }, [
                    _vm._v("修改密码"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-dropdown-item",
                {
                  attrs: { divided: "" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.logout($event)
                    },
                  },
                },
                [
                  _c("span", { staticStyle: { display: "block" } }, [
                    _vm._v("退出"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }