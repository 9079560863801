"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _aiMark = require("@/api/aiMark");
var _user = require("@/api/user.js");
var _review = require("@/api/review");
var _videoDetailNew = _interopRequireDefault(require("@/views/aiMarketing/module/videoDetailNew"));
var _index = _interopRequireDefault(require("@/components/dialogComfirm/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    videoDetails: _videoDetailNew.default,
    dialogComfirm: _index.default
  },
  data: function data() {
    return {
      searchQuery: {},
      listLoading: true,
      resultData: [],
      total: 0,
      tableFrom: {
        page: 1,
        limit: 20
      },
      selectTableRows: [],
      moduleVisible: false,
      moduleForm: {
        marketMake: {}
      },
      level: '',
      merchant_admin_id: '',
      type: 1,
      mask: '',
      showreview: false,
      audit_id: '',
      rowLinkID: '',
      channelVisible: false,
      channelForm: {},
      channelType: {},
      channList: [],
      status: '',
      setType: '',
      setI: null,
      val: '',
      userList: [],
      userOptions: [],
      editData: {},
      tagVal: '',
      tagList: [],
      showSetLabel: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _user.getBaseInfo)().then(function (res) {
      _this.level = res.data.merchantAdmin.level;
      _this.merchant_admin_id = res.data.merchantAdmin.merchant_admin_id;
    });
    this.getList();
    (0, _aiMark.getChannelsTypeApi)().then(function (res) {
      var obj = {};
      for (var k in res.data) {
        var arr = [];
        for (var key in res.data[k]) {
          arr.push({
            label: res.data[k][key],
            value: key
          });
        }
        obj[k] = arr;
      }
      // console.log(obj);
      _this.channelType = obj;
    });
    (0, _aiMark.getPromotionAccountListApi)({
      page: 1,
      limit: 100
    }).then(function (res) {
      var userOptions = [];
      res.data.list.forEach(function (m) {
        m.label = "".concat(m.channelsTypeText, " | ").concat(m.account_name);
        userOptions.push({
          label: "".concat(m.channelsTypeText, " | ").concat(m.account_name),
          value: m.id
        });
      });
      _this.userList = res.data.list;
      _this.userOptions = userOptions;
    });
  },
  methods: {
    confirmTagAction: function confirmTagAction() {
      var _this2 = this;
      var data = {
        id: this.resultData[this.editData.setI].id,
        video_info: {
          label: this.tagList
        }
      };
      this.showSetLabel = false;
      (0, _aiMark.releaseUpdateApi)(data).then(function () {
        _this2.$message.success('操作成功');
        _this2.$set(_this2.resultData[_this2.editData.setI].extend_data.video_info, 'label', data.video_info.label);
      }).catch(function () {});
    },
    closeTagAction: function closeTagAction(i) {
      if (!this.tagList[i]) return;
      this.tagList.splice(i, 1);
    },
    changeTagAction: function changeTagAction() {
      if (!this.tagVal) return false;
      this.tagList = this.tagList.concat([this.tagVal]);
      this.tagVal = '';
    },
    setColumnAction: function setColumnAction(index, type, val) {
      var _this3 = this;
      this.val = val || '';
      this.setI = index;
      this.setType = type;
      this.editData = {
        setI: index,
        setType: type
      };
      if (type != 'video_label') {
        this.$nextTick(function () {
          if (_this3.$refs.valComponentEL) _this3.$refs.valComponentEL.focus();
        });
      } else {
        this.tagVal = '';
        if (val && typeof val == 'string') {
          this.tagList = val.split('');
        } else {
          this.tagList = val || [];
        }
        this.showSetLabel = true;
      }
    },
    clearSetAction: function clearSetAction() {
      var _this4 = this;
      setTimeout(function () {
        _this4.setI = null;
        _this4.setType = '';
        _this4.val = '';
      }, 100);
    },
    editDataAction: function editDataAction(e) {
      var _this5 = this;
      var item = this.resultData[this.editData.setI];
      var data = {
        id: item.id
      };
      if (this.editData.setType == 'date') {
        data.released_plan_time = "".concat(e, " ").concat(item.released_t || '08:00:00');
      } else if (this.editData.setType == 'time') {
        if (!item.released_d) {
          this.$message.error('请先设置发布日期');
          return false;
        }
        data.released_plan_time = "".concat(item.released_d, " ").concat(e);
      } else if (this.editData.setType == 'video_title') {
        data.video_info = {
          title: e
        };
      } else if (this.editData.setType == 'video_label') {
        data.video_info = {
          label: e
        };
      } else {
        data[this.editData.setType] = e;
      }
      (0, _aiMark.releaseUpdateApi)(data).then(function () {
        _this5.$message.success('操作成功');
        if (data.released_plan_time) {
          var r = data.released_plan_time.split(' ');
          data.released_d = r[0];
          var timeData = r[1].split(':');
          data.released_t = "".concat(timeData[0], ":").concat(timeData[1]);
          _this5.resultData[_this5.editData.setI] = Object.assign(_this5.resultData[_this5.editData.setI], data);
        } else if (_this5.editData.setType == 'video_title') {
          _this5.$set(_this5.resultData[_this5.editData.setI].extend_data.video_info, 'title', data.video_info.title);
        } else if (_this5.editData.setType == 'video_label') {
          _this5.$set(_this5.resultData[_this5.editData.setI].extend_data.video_info, 'label', data.video_info.label);
        } else {
          _this5.getList();
        }
      }).catch(function () {});
    },
    typeChange: function typeChange(val) {
      if (this.status != val) {
        this.status = val;
        this.tableFrom.page = 1;
        this.getList(1);
      }
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.tableFrom = Object.assign({
        page: 1,
        limit: this.tableFrom.limit
      }, e);
      this.getList(1);
    },
    onUpd: function onUpd(row) {
      this.channelForm = {
        id: row.id,
        channels_id: row.channels_id,
        channels: row.channels,
        released_plan_time: row.released_time
      };
      this.getChannList();
      this.channelVisible = true;
    },
    onSubmit: function onSubmit() {
      var _this6 = this;
      (0, _aiMark.promoUpdateApi)(this.channelForm.id, this.channelForm).then(function (res) {
        _this6.channelVisible = false;
        _this6.$message.success('操作成功');
        _this6.getList();
      });
    },
    getChannList: function getChannList() {
      var _this7 = this;
      (0, _aiMark.groupListApi)({
        channels: this.channelForm.channels
      }).then(function (res) {
        // console.log(res);
        _this7.channList = res.data.list;
      });
    },
    onDele: function onDele(row, con) {
      var _this8 = this;
      this.$modalConfirm({
        title: '确认',
        content: con || '确认删除此任务?',
        success: function success() {
          (0, _aiMark.releaseDeleteApi)({
            ids: row.id
          }).then(function (res) {
            _this8.$message.success('操作成功');
            _this8.getList();
          });
        }
      });
    },
    revieworder: function revieworder(row) {
      this.type = 1;
      this.audit_id = row.marketMake.audit.audit_id;
      this.rowLinkID = row.marketMake.market_make_id;
      this.showreview = true;
      this.mask = '';
    },
    delAll: function delAll() {
      if (this.selectTableRows.length <= 0) {
        this.$message.error('请选择任务');
        return false;
      }
      var idsArr = this.selectTableRows.map(function (item) {
        return item.id;
      });
      this.onDele({
        id: idsArr.join(',')
      }, '确认批量删除任务？');
    },
    handlereview: function handlereview(state) {
      var _this9 = this;
      if (state == -1 && !this.mask) {
        this.$message.error('请填写拒绝备注');
        return;
      }
      var aucUrl = '';
      if (this.type == 2) {
        aucUrl = (0, _review.batchReview)({
          status: state,
          remark: this.mask,
          type: 'market_media_make',
          link_ids: this.rowLinkID
        });
      } else {
        aucUrl = (0, _review.review)(this.audit_id, {
          status: state,
          remark: this.mask,
          type: 'market_media_make',
          link_id: this.rowLinkID
        });
      }
      aucUrl.then(function (res) {
        _this9.$message.success('操作成功');
        _this9.getList();
        _this9.showreview = false;
      }).catch(function (err) {
        _this9.$message.error(err.message);
      });
    },
    onOpenDetails: function onOpenDetails(row) {
      this.$refs.videoViewRef.onOpenInit(JSON.parse(JSON.stringify(row)));
    },
    getList: function getList() {
      var _this10 = this;
      this.listLoading = true;
      this.tableFrom.is_released = this.status;
      (0, _aiMark.releaseListApi)(this.tableFrom).then(function (res) {
        res.data.list.forEach(function (m) {
          if (m.released_plan_time) {
            var r = m.released_plan_time.split(' ');
            m.released_d = r[0];
            var timeData = r[1].split(':');
            m.released_t = "".concat(timeData[0], ":").concat(timeData[1]);
          } else {
            m.released_d = '';
            m.released_t = '';
          }
        });
        _this10.total = res.data.count;
        _this10.resultData = res.data.list;
        _this10.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    onRelease: function onRelease(_ref) {
      var _this11 = this;
      var id = _ref.id;
      (0, _aiMark.releasedApi)({
        ids: id
      }).then(function (res) {
        _this11.$message.success('操作成功');
        _this11.pageChange(1);
      });
    },
    onReleaseAll: function onReleaseAll() {
      var _this12 = this;
      var ids = [];
      if (this.selectTableRows.length <= 0) {
        this.$message.error('请选择任务');
        return false;
      }
      for (var index = 0; index < this.selectTableRows.length; index++) {
        var element = this.selectTableRows[index];
        if (element.releasedAdmin) {
          this.$message.error('请选择未发布状态');
          return false;
        } else if (!element.marketMake) {
          this.$message.error('请选择可以发布的内容');
          return false;
        } else {
          ids.push(element.id);
        }
      }
      (0, _aiMark.releasedApi)({
        ids: ids.join(',')
      }).then(function (res) {
        _this12.$message.success('操作成功');
        _this12.pageChange(1);
      });
    },
    handleSelectionChange: function handleSelectionChange(data) {
      this.selectTableRows = data;
    },
    onDownload: function onDownload() {
      var _this13 = this;
      fetch(this.moduleForm.extend_data.video_info.url).then(function (res) {
        return res.blob();
      }).then(function (blob) {
        var name;
        if (_this13.moduleForm.make_type == 1) name = "".concat(_this13.moduleForm.make_title, "-\u62CD\u5356\u4F1A\u89C6\u9891");
        if (_this13.moduleForm.make_type == 2) name = "".concat(_this13.moduleForm.make_title, "-\u4E13\u573A\u89C6\u9891");
        if (_this13.moduleForm.make_type == 3 || _this13.moduleForm.make_type == 4) name = "".concat(_this13.moduleForm.make_title, "-\u62CD\u54C1\u89C6\u9891");
        var a = document.createElement('a');
        var objectUrl = window.URL.createObjectURL(blob);
        a.download = "".concat(name, ".mp4");
        a.href = objectUrl;
        a.click();
        window.URL.revokeObjectURL(objectUrl);
        a.remove();
      });
    }
  }
};