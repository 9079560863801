"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _settings = require("@/settings");
var warehouseRouter = {
  path: "".concat(_settings.roterPre, "/warehouse"),
  name: 'warehouse',
  meta: {
    icon: '',
    title: '仓库管理'
  },
  alwaysShow: true,
  component: _layout.default,
  children: [{
    path: 'manage',
    name: 'warehousemanage',
    meta: {
      title: '仓库管理',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/index'));
      });
    },
    children: [{
      path: 'pushwarehouse',
      name: 'pushwarehouse',
      meta: {
        title: '入库单',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/warehouse/manage/pushwarehouse"),
        permissionKey: '/warehouse/manage/pushwarehouse'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/pushwarehouse'));
        });
      }
    }, {
      path: 'outwarehouse',
      name: 'outwarehouse',
      meta: {
        title: '出库单',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/warehouse/manage/pushwarehouse"),
        permissionKey: '/warehouse/manage/outwarehouse'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/outwarehouse'));
        });
      }
    }, {
      path: 'transfers',
      name: 'transfers',
      meta: {
        title: '调拨单',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/warehouse/manage/pushwarehouse"),
        permissionKey: '/warehouse/manage/transfers'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/transfers'));
        });
      }
    }, {
      path: 'check',
      name: 'check',
      meta: {
        title: '库存盘点',
        noCache: true,
        activeMenu: "".concat(_settings.roterPre, "/warehouse/manage/pushwarehouse"),
        permissionKey: '/warehouse/manage/check'
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/check'));
        });
      }
    }]
  }, {
    path: 'shelfList',
    name: 'ShelfList',
    meta: {
      title: '库房配置',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/warehouse/manage/pushwarehouse")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/setting/shelfList'));
      });
    }
  }, {
    path: 'warehouseList',
    name: 'WarehouseList',
    meta: {
      title: '库房配置',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/warehouse/manage/pushwarehouse")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/setting/productStore'));
      });
    }
  }, {
    path: 'warehouseRules',
    name: 'warehouseRules',
    meta: {
      title: '规则配置',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/warehouse/manage/pushwarehouse")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/setting/warehouseRules'));
      });
    }
  }, {
    path: 'setColumns',
    name: 'tableColumns',
    meta: {
      title: '库房配置',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/warehouse/manage/pushwarehouse")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/setting/tableColumns'));
      });
    }
  }, {
    path: 'pushwarehouse',
    name: 'pushwarehouse',
    meta: {
      title: '入库单',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/pushwarehouse'));
      });
    }
  }, {
    path: 'createpushorder',
    name: 'createpushorder',
    meta: {
      title: '创建入库单',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/warehouse/manage/pushwarehouse")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/createpushorder'));
      });
    }
  }, {
    path: 'outwarehouse',
    name: 'outwarehouse',
    meta: {
      title: '出库单',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/outwarehouse'));
      });
    }
  }, {
    path: 'createoutorder',
    name: 'createoutorder',
    meta: {
      title: '创建出库单',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/warehouse/manage/pushwarehouse")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/createoutorder'));
      });
    }
  }, {
    path: 'transfers',
    name: 'transfers',
    meta: {
      title: '调拨单',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/transfers'));
      });
    }
  }, {
    path: 'transferscreate',
    name: 'transferscreate',
    meta: {
      title: '创建调拨单',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/warehouse/manage/pushwarehouse")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/transferscreate'));
      });
    }
  }, {
    path: 'transfersgoods',
    name: 'transfersgoods',
    meta: {
      title: '创建调拨单',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/warehouse/manage/pushwarehouse")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/transfersgoods'));
      });
    }
  }, {
    path: 'check',
    name: 'check',
    meta: {
      title: '库存盘点',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/warehouse/manage/pushwarehouse")
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/check'));
      });
    }
  }, {
    path: 'checkrecord',
    name: 'checkrecord',
    meta: {
      title: '盘点记录',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/warehouse/manage/pushwarehouse"),
      permissionKey: '/warehouse/checkrecord'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/checkrecord'));
      });
    }
  }, {
    path: 'checkgoods',
    name: 'checkgoods',
    meta: {
      title: '盘点货品',
      noCache: true,
      activeMenu: "".concat(_settings.roterPre, "/warehouse/manage/pushwarehouse"),
      permissionKey: '/warehouse/checkgoods'
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/warehouse/checkgoods'));
      });
    }
  }]
};
var _default = exports.default = warehouseRouter;