var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-container", staticStyle: { "min-height": "100vh" } },
      [
        _c("header-breadcrumb", {
          attrs: { name: "库房相关配置", "has-back": "" },
        }),
        _vm._v(" "),
        _c("el-card", [
          _c(
            "div",
            [
              _c(
                "el-menu",
                {
                  staticClass: "el-menu-demo default-tabs",
                  attrs: { "default-active": _vm.tab, mode: "horizontal" },
                  on: { select: _vm.changeTab },
                },
                [
                  _c("el-menu-item", { attrs: { index: "1" } }, [
                    _vm._v("入库单"),
                  ]),
                  _vm._v(" "),
                  _c("el-menu-item", { attrs: { index: "2" } }, [
                    _vm._v("出库单"),
                  ]),
                  _vm._v(" "),
                  _c("el-menu-item", { attrs: { index: "3" } }, [
                    _vm._v("调拨单"),
                  ]),
                  _vm._v(" "),
                  _c("el-menu-item", { attrs: { index: "4" } }, [
                    _vm._v("仓库配置"),
                  ]),
                  _vm._v(" "),
                  _c("el-menu-item", { attrs: { index: "5" } }, [
                    _vm._v("规则配置"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "item flex align-items-c h50" }, [
            _c("div", { staticClass: "flex-one" }, [_vm._v("库号前缀规则")]),
            _vm._v(" "),
            _c(
              "div",
              {},
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.stateForm.erp_product_no_prefix_rule,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.stateForm,
                          "erp_product_no_prefix_rule",
                          $$v
                        )
                      },
                      expression: "stateForm.erp_product_no_prefix_rule",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 0 } }, [
                      _vm._v("统一前缀"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 1 } }, [
                      _vm._v("账号前缀"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.stateForm.erp_product_no_prefix_rule == "0"
            ? _c(
                "div",
                { staticClass: "item flex align-items-c h50 borderNone" },
                [
                  _c("div", { staticClass: "flex-one" }, [
                    _vm._v("库号前缀内容"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {},
                    [
                      _c("el-input", {
                        staticClass: "w200 ml10",
                        attrs: { placeholder: "请输入库号前缀内容" },
                        model: {
                          value: _vm.stateForm.erp_product_no_prefix_default,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.stateForm,
                              "erp_product_no_prefix_default",
                              $$v
                            )
                          },
                          expression: "stateForm.erp_product_no_prefix_default",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.stateForm.erp_product_no_prefix_rule == "1"
            ? _c(
                "div",
                {
                  staticClass: "item flex borderNone",
                  staticStyle: {
                    "flex-direction": "column",
                    padding: "15px 20px",
                  },
                },
                [
                  _c("div", { staticClass: "flex-one" }, [
                    _vm._v("库号前缀内容"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "flex",
                      staticStyle: { "flex-wrap": "wrap" },
                    },
                    _vm._l(_vm.userList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "flex",
                          staticStyle: { "margin-top": "10px", width: "220px" },
                        },
                        [
                          _c("div", { staticStyle: { width: "70px" } }, [
                            _vm._v(_vm._s(item.real_name)),
                          ]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "w100 ml10",
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "item flex align-items-c h50 mt20" }, [
            _c("div", { staticClass: "flex-one" }, [_vm._v("库号编号规则")]),
            _vm._v(" "),
            _c(
              "div",
              {},
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.stateForm.erp_product_no_entry_rule,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.stateForm,
                          "erp_product_no_entry_rule",
                          $$v
                        )
                      },
                      expression: "stateForm.erp_product_no_entry_rule",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 0 } }, [
                      _vm._v("人工手动录入"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 1 } }, [
                      _vm._v("系统自动生成"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.stateForm.erp_product_no_entry_rule == "1"
            ? _c(
                "div",
                { staticClass: "item flex align-items-c h50 borderNone" },
                [
                  _c("div", { staticClass: "flex-one flex align-items-c" }, [
                    _c("div", [_vm._v("自增起始编号")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ml20" },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            model: {
                              value:
                                _vm.stateForm.erp_product_no_entry_system_date,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.stateForm,
                                  "erp_product_no_entry_system_date",
                                  $$v
                                )
                              },
                              expression:
                                "stateForm.erp_product_no_entry_system_date",
                            },
                          },
                          [
                            _c("el-checkbox", { attrs: { label: "y" } }, [
                              _vm._v("年"),
                            ]),
                            _vm._v(" "),
                            _c("el-checkbox", { attrs: { label: "m" } }, [
                              _vm._v("月"),
                            ]),
                            _vm._v(" "),
                            _c("el-checkbox", { attrs: { label: "d" } }, [
                              _vm._v("日"),
                            ]),
                            _vm._v(" "),
                            _c("el-checkbox", { attrs: { label: "H" } }, [
                              _vm._v("时"),
                            ]),
                            _vm._v(" "),
                            _c("el-checkbox", { attrs: { label: "i" } }, [
                              _vm._v("分"),
                            ]),
                            _vm._v(" "),
                            _c("el-checkbox", { attrs: { label: "s" } }, [
                              _vm._v("秒"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.entry_date))]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {},
                    [
                      _c("el-input", {
                        staticClass: "w200 ml10",
                        attrs: { placeholder: "请输入自增起始编号" },
                        model: {
                          value:
                            _vm.stateForm.erp_product_no_entry_system_start,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.stateForm,
                              "erp_product_no_entry_system_start",
                              _vm._n($$v)
                            )
                          },
                          expression:
                            "stateForm.erp_product_no_entry_system_start",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "formFixedBox" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
          [_vm._v("保存")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }