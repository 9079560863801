"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user.js");
var _system = require("@/api/system.js");
var _index = require("@/utils/index");
var _order = require("@/api/order");
var _printJs = _interopRequireDefault(require("print-js"));
var _zhTW = _interopRequireDefault(require("@/utils/zh-TW.json"));
var _system2 = require("@/api/system");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default
  },
  filters: {
    filterPercent: function filterPercent(val) {
      var remainder = val % 10;
      if (remainder > 0) val;else return val / 10;
    }
  },
  props: {
    preview: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      parseTime: _index.parseTime,
      ids: '',
      uid: '',
      source: '',
      formatPriceNumber: _index.formatPriceNumber,
      collectMoney: {
        show: false,
        data: ''
      },
      total_price: 0,
      printObj2: {
        id: 'print1',
        popTitle: ''
      },
      fee_type: '',
      feeTypeInfo: {},
      isOutbound: false,
      qrcodeImg: '',
      merData: {},
      no: '',
      showSettings: false,
      settingKey: '',
      detail: {
        title: {
          china: '委托方拍后告知书',
          english: 'Notification letter from the client after the auction'
        },
        buyerCompany: {
          china: '您好！感谢您对我公司的大力支持，希望您能一如既往地支持我公司，谢谢！',
          english: "Thanks for your support to our company, we beg your understanding and also hope you can support us as usual!"
        },
        tableColumn: [{
          open: true,
          name: '合同号',
          showName: '',
          english: 'CONTRACT NO.',
          w: 135
        }, {
          open: true,
          name: '图录号',
          showName: '',
          english: 'LOT NO.',
          w: 80
        }, {
          open: true,
          name: '拍品名称',
          showName: '',
          english: 'DESCRIPTION'
        }, {
          open: true,
          name: '保留价',
          showName: '',
          english: 'SELES PRICE',
          w: 105
        }, {
          open: true,
          name: '实得价',
          showName: '',
          english: 'NET PROCEEDS',
          w: 125
        }, {
          open: true,
          name: '落槌价',
          showName: '',
          english: 'HAMMER PRICE',
          w: 125
        }, {
          open: true,
          name: '成交状态',
          showName: '',
          english: 'SALES STATUS',
          w: 125
        }, {
          open: true,
          name: '专场名称',
          showName: '',
          english: 'SESSION NAME',
          w: 125
        }],
        settleText: {
          china: '尊敬的委託人您好！非常感謝對我公司的支持和幫助，現將您委託我公司拍賣的作品成交結果呈上，請您查收並核對。懇請您一如既往地支援我公司。謝謝。',
          english: 'Dear client, thank you very much for your support and assistance to our company. We are now submitting the transaction results of the works you entrusted our company for auction. Please check and verify them. Please continue to support our company as always. Thank you.'
        },
        signColumnTab: 'system',
        signColumnCustome: ''
      },
      editKey: ''
    };
  },
  computed: {
    feeTypeObj: function feeTypeObj() {
      return this.$store.getters.feeTypeInfo;
    },
    getWidth: function getWidth() {
      return 'width: 130px;';
    },
    getForeWidth: function getForeWidth() {
      var w = 0;
      if (this.detail.tableColumn[2] && this.detail.tableColumn[2].open && this.detail.tableColumn[2].w) {
        w += this.detail.tableColumn[2].w;
      }
      if (this.detail.tableColumn[3] && this.detail.tableColumn[3].open && this.detail.tableColumn[3].w) {
        w += this.detail.tableColumn[3].w;
      }
      if (this.detail.tableColumn[4] && this.detail.tableColumn[4].open && this.detail.tableColumn[4].w) {
        w += this.detail.tableColumn[4].w;
      }
      if (this.detail.tableColumn[5] && this.detail.tableColumn[5].open && this.detail.tableColumn[5].w) {
        w += this.detail.tableColumn[5].w;
      }
      return "width: ".concat(w, "px;");
    }
  },
  mounted: function mounted() {
    var _this = this;
    (0, _user.getBaseInfo)().then(function (res) {
      res.data.company_english_name = res.data.config.company_english_name;
      _this.merData = res.data;
    });
    (0, _system2.getConfigClassKeys)('print_config').then(function (res) {
      if (res.data.print_entrust_auction_end) {
        var d = JSON.parse(res.data.print_entrust_auction_end);
        if (!d.signColumnTab) d.signColumnTab = 'system';
        if (!d.title) {
          d.title = {
            china: '委托方拍后告知书',
            english: 'Notification letter from the client after the auction'
          };
        }
        // if (d.signColumnCustome) {
        //   this.$refs['editor'].setContent(d.signColumnCustome);
        // }
        _this.detail = d;
      }
    });
  },
  methods: {
    saveAction: function saveAction() {
      var _this2 = this;
      (0, _system2.configClassKeysSave)('print_config', {
        print_entrust_auction_end: JSON.stringify(this.detail)
      }).then(function () {
        _this2.$message.success('保存成功');
      });
    },
    focus: function focus(e) {
      e.currentTarget.select();
    },
    goEditAction: function goEditAction(key) {
      this.settingKey = key;
      this.showSettings = true;
    },
    switchLang: function switchLang(filed) {
      if (this.feeTypeObj.mer_fee_type == 'HKD') {
        return _zhTW.default.TW[filed];
      } else return _zhTW.default.CN[filed];
    }
  }
};