var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "fant-form",
      attrs: {
        model: _vm.facade,
        "label-width": "100px",
        size: "mini",
        "label-position": "left",
      },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c("div", { staticClass: "item-align-center mt20" }, [
        _c("div", { staticClass: "fontW400 ft14 color-black" }, [
          _vm._v("拍品图片"),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "item-align-center flex-wrap" },
        _vm._l(_vm.styleData, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "change-type",
              staticStyle: { "margin-right": "20px", "margin-top": "15px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "img",
                  class: { curr: _vm.is_active(item) },
                  on: {
                    click: function ($event) {
                      return _vm.setStyle(item.value)
                    },
                  },
                },
                [_c("label", [_c("img", { attrs: { src: item.src } })])]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "name ft14 color-gray align-c" }, [
                _vm._v(_vm._s(item.name)),
              ]),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.facade.style == 1 || _vm.facade.style == 6 || _vm.facade.style == 7
        ? _c(
            "div",
            {
              staticClass: "attr-item flex mt20",
              staticStyle: { "align-items": "center" },
            },
            [
              _c("div", { staticClass: "attr-name" }, [_vm._v("轮播展示效果")]),
              _vm._v(" "),
              _c("div", { staticClass: "attr-sort-image" }),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px" },
                      model: {
                        value: _vm.content.playmodel,
                        callback: function ($$v) {
                          _vm.$set(_vm.content, "playmodel", $$v)
                        },
                        expression: "content.playmodel",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "左右滑动", value: 1 },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "上下滑动", value: 2 },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "进入放大移出", value: 3 },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "多方向进入", value: 4 },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "翻转切换", value: 5 },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "横向轮播", value: 6 },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "纵向滚动", value: 7 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.facade.style == 1 || _vm.facade.style == 6 || _vm.facade.style == 7
        ? _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "轮播图高度" } },
            [
              _c(
                "div",
                {
                  staticClass: "flex align-items-c",
                  staticStyle: { "justify-content": "flex-end" },
                },
                [
                  _c(
                    "div",
                    [
                      _c("el-input-number", {
                        staticClass: "w80",
                        attrs: { controls: false, disabled: "" },
                        model: {
                          value: _vm.facade.swiperWidth,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "swiperWidth", $$v)
                          },
                          expression: "facade.swiperWidth",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml5 mr5" }, [_vm._v("×")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("el-input-number", {
                        staticClass: "w80",
                        attrs: { controls: false },
                        model: {
                          value: _vm.facade.swiperHeight,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "swiperHeight", $$v)
                          },
                          expression: "facade.swiperHeight",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.content.playmodel == 1
        ? _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "动画提示间距" } },
            [
              _c("slider", {
                attrs: { min: 0, max: 100 },
                model: {
                  value: _vm.facade.animate_margin,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "animate_margin", $$v)
                  },
                  expression: "facade.animate_margin",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.content.playmodel == 1
        ? _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "动画提示颜色" } },
            [
              _c("colour", {
                attrs: { colour: "#FFFFFF" },
                model: {
                  value: _vm.facade.animate_color,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "animate_color", $$v)
                  },
                  expression: "facade.animate_color",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c("span", [_vm._v("长图特效")]),
          _vm._v(" "),
          _c("el-switch", {
            attrs: { "active-value": true, "inactive-value": false },
            model: {
              value: _vm.facade.showLongImage,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "showLongImage", $$v)
              },
              expression: "facade.showLongImage",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.facade.showLongImage
        ? [
            _c("div", { staticClass: "flex mt20" }, [
              _c("div", { staticClass: "flex-one" }, [
                _c(
                  "div",
                  { staticClass: "ft14" },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "1" },
                        model: {
                          value: _vm.facade.longMode,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "longMode", $$v)
                          },
                          expression: "facade.longMode",
                        },
                      },
                      [_vm._v("移动播放")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "item-align-center flex-wrap" },
                  _vm._l(_vm.longStyleData, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "change-type",
                        staticStyle: {
                          "margin-right": "20px",
                          "margin-top": "15px",
                        },
                      },
                      [
                        _c("div", { staticClass: "img" }, [
                          _c("label", [
                            _c("img", { attrs: { src: item.src } }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "name ft14 color-gray align-c" },
                          [_vm._v(_vm._s(item.name))]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-one" }, [
                _c(
                  "div",
                  { staticClass: "ft14" },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "2" },
                        model: {
                          value: _vm.facade.longMode,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "longMode", $$v)
                          },
                          expression: "facade.longMode",
                        },
                      },
                      [_vm._v("横图改竖图")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "mt20" }, [
                  _c("img", {
                    staticStyle: { width: "66px" },
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/202487/image/1722999889648769938.png!120a",
                      alt: "",
                    },
                  }),
                ]),
              ]),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "页面边距" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.page_margin,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "page_margin", $$v)
              },
              expression: "facade.page_margin",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "h30" }),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "拍品圆角" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.radius,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "radius", $$v)
              },
              expression: "facade.radius",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "图片背景" } },
        [
          _c("colour", {
            attrs: { colour: "#FFFFFF" },
            model: {
              value: _vm.facade.item_background,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "item_background", $$v)
              },
              expression: "facade.item_background",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.facade.style == 3
        ? _c(
            "el-form-item",
            { staticClass: "mt20", attrs: { label: "图片间距" } },
            [
              _c("slider", {
                attrs: { min: 0, max: 100 },
                model: {
                  value: _vm.facade.square_padding,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "square_padding", $$v)
                  },
                  expression: "facade.square_padding",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "图片边距" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.item_padding,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "item_padding", $$v)
              },
              expression: "facade.item_padding",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "h30" }),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字背景" } },
        [
          _c("colour", {
            attrs: { colour: "#FFFFFF" },
            model: {
              value: _vm.facade.content_background,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "content_background", $$v)
              },
              expression: "facade.content_background",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "距上距离" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.content_toppadding,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "content_toppadding", $$v)
              },
              expression: "facade.content_toppadding",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字边距" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "radius-list t" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.background_padding_top,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "background_padding_top", $$v)
                    },
                    expression: "facade.background_padding_top",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list b" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.background_padding_bottom,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "background_padding_bottom", $$v)
                    },
                    expression: "facade.background_padding_bottom",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list l" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.background_padding_left,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "background_padding_left", $$v)
                    },
                    expression: "facade.background_padding_left",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list r" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.background_padding_right,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "background_padding_right", $$v)
                    },
                    expression: "facade.background_padding_right",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "文字边框" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.border_width,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "border_width", $$v)
              },
              expression: "facade.border_width",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "边框样式" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.borderStyle,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "borderStyle", $$v)
                },
                expression: "facade.borderStyle",
              },
            },
            [
              _c("el-radio", { attrs: { label: "solid" } }, [_vm._v("实线")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "dotted" } }, [_vm._v("点状")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "dashed" } }, [_vm._v("虚线")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "边框颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#FFFFFF" },
            model: {
              value: _vm.facade.border_color,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "border_color", $$v)
              },
              expression: "facade.border_color",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "边框圆角" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.border_radius,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "border_radius", $$v)
              },
              expression: "facade.border_radius",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "边框内边距" } },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "radius-list t" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.border_padding_top,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "border_padding_top", $$v)
                    },
                    expression: "facade.border_padding_top",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list b" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.border_padding_bottom,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "border_padding_bottom", $$v)
                    },
                    expression: "facade.border_padding_bottom",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list l" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.border_padding_left,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "border_padding_left", $$v)
                    },
                    expression: "facade.border_padding_left",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "radius-list r" },
              [
                _c("el-input-number", {
                  staticClass: "w50",
                  attrs: { controls: false },
                  model: {
                    value: _vm.facade.border_padding_right,
                    callback: function ($$v) {
                      _vm.$set(_vm.facade, "border_padding_right", $$v)
                    },
                    expression: "facade.border_padding_right",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "边框阴影色" } },
        [
          _c("colour", {
            attrs: { colour: "#FFFFFF" },
            model: {
              value: _vm.facade.border_shawdow_color,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "border_shawdow_color", $$v)
              },
              expression: "facade.border_shawdow_color",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "阴影X距离" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.border_shawdow_x,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "border_shawdow_x", $$v)
              },
              expression: "facade.border_shawdow_x",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "阴影Y距离" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.border_shawdow_y,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "border_shawdow_y", $$v)
              },
              expression: "facade.border_shawdow_y",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "h30" }),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "内容间隔距离" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.item_margin,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "item_margin", $$v)
              },
              expression: "facade.item_margin",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "间距线颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#FFFFFF" },
            model: {
              value: _vm.facade.auction_spacing_color,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "auction_spacing_color", $$v)
              },
              expression: "facade.auction_spacing_color",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "间距线高度" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.auction_spacing_height,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "auction_spacing_height", $$v)
              },
              expression: "facade.auction_spacing_height",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "间距线样式" } },
        [
          _c(
            "el-radio-group",
            {
              staticClass: "item-align-center",
              staticStyle: { height: "30px" },
              model: {
                value: _vm.facade.auction_spacing_type,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "auction_spacing_type", $$v)
                },
                expression: "facade.auction_spacing_type",
              },
            },
            [
              _c(
                "el-radio",
                { staticClass: "item-align-center", attrs: { label: "solid" } },
                [_c("div", [_vm._v("默认")])]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  staticClass: "item-align-center",
                  attrs: { label: "dashed" },
                },
                [_c("div", [_vm._v("虚线")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "h30" }),
      _vm._v(" "),
      _c("div", { staticClass: "flex align-items-c" }, [
        _c(
          "div",
          {
            staticClass: "w80 fontW400 ft14 pr10",
            staticStyle: { color: "#606266" },
          },
          [_vm._v("间距图上传")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "upload-img-view flex align-items-c flex-one",
            staticStyle: { overflow: "hidden" },
            on: {
              click: function ($event) {
                return _vm.modalPicTap(1, "auction_spacing_image")
              },
            },
          },
          [
            _vm.facade.auction_spacing_image
              ? _c(
                  "div",
                  {
                    staticClass: "del-upload-img",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.delImg("auction_spacing_image")
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                        alt: "",
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.facade.auction_spacing_image
              ? _c("img", {
                  staticStyle: {
                    width: "70px",
                    height: "30px",
                    "object-fit": "contain",
                  },
                  attrs: { src: _vm.facade.auction_spacing_image + "!300" },
                })
              : _c("img", {
                  staticClass: "upload-icon",
                  attrs: {
                    src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                    alt: "",
                  },
                }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "图片大小" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.auction_spacing_image_width,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "auction_spacing_image_width", $$v)
              },
              expression: "facade.auction_spacing_image_width",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "图片左右间距" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.auction_spacing_image_margin,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "auction_spacing_image_margin", $$v)
              },
              expression: "facade.auction_spacing_image_margin",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "h30" }),
      _vm._v(" "),
      _c("div", { staticClass: "fWeight500 ft14 mt20" }, [
        _vm._v("文字风格设置"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "fWeight500 ft14 mt20" }, [_vm._v("图录号")]),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "上距离" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.lot_margin,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "lot_margin", $$v)
              },
              expression: "facade.lot_margin",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#000000" },
            model: {
              value: _vm.facade.lot_color,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "lot_color", $$v)
              },
              expression: "facade.lot_color",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "字号" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.lot_size,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "lot_size", $$v)
              },
              expression: "facade.lot_size",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "风格" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.lot_weight,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "lot_weight", $$v)
                },
                expression: "facade.lot_weight",
              },
            },
            [
              _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "对齐" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.lot_text_align,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "lot_text_align", $$v)
                },
                expression: "facade.lot_text_align",
              },
            },
            [
              _c("el-radio", { attrs: { label: "left" } }, [_vm._v("常规")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "center" } }, [_vm._v("居中")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "right" } }, [_vm._v("居右")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-form-item", { staticClass: "mt20", attrs: { label: "字体" } }, [
        _c(
          "div",
          { staticClass: "flex flex-wrap" },
          [
            _c(
              "el-select",
              {
                staticStyle: { width: "100%" },
                model: {
                  value: _vm.facade.lot_fontFamily,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "lot_fontFamily", $$v)
                  },
                  expression: "facade.lot_fontFamily",
                },
              },
              _vm._l(_vm.fontFamilyList, function (m, i) {
                return _c(
                  "el-option",
                  { key: i, attrs: { value: m.value, label: m.name } },
                  [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c("div", { staticClass: "flex-one" }, [
                        _vm._v(_vm._s(m.name)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ft12",
                          style: "font-family: " + m.value + ";",
                        },
                        [_vm._v("例：100")]
                      ),
                    ]),
                  ]
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("el-divider", {
        attrs: { direction: "horizontal", "content-position": "left" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "fWeight500 ft14 mt20" }, [_vm._v("名称")]),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "上距离" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.title_margin,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "title_margin", $$v)
              },
              expression: "facade.title_margin",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#000000" },
            model: {
              value: _vm.facade.title_color,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "title_color", $$v)
              },
              expression: "facade.title_color",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "字号" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.title_size,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "title_size", $$v)
              },
              expression: "facade.title_size",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "风格" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.title_weight,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "title_weight", $$v)
                },
                expression: "facade.title_weight",
              },
            },
            [
              _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "对齐" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.title_text_align,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "title_text_align", $$v)
                },
                expression: "facade.title_text_align",
              },
            },
            [
              _c("el-radio", { attrs: { label: "left" } }, [_vm._v("常规")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "center" } }, [_vm._v("居中")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "right" } }, [_vm._v("居右")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-form-item", { staticClass: "mt20", attrs: { label: "字体" } }, [
        _c(
          "div",
          { staticClass: "flex flex-wrap" },
          [
            _c(
              "el-select",
              {
                staticStyle: { width: "100%" },
                model: {
                  value: _vm.facade.title_fontFamily,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "title_fontFamily", $$v)
                  },
                  expression: "facade.title_fontFamily",
                },
              },
              _vm._l(_vm.fontFamilyList, function (m, i) {
                return _c(
                  "el-option",
                  { key: i, attrs: { value: m.value, label: m.name } },
                  [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c("div", { staticClass: "flex-one" }, [
                        _vm._v(_vm._s(m.name)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ft12",
                          style: "font-family: " + m.value + ";",
                        },
                        [_vm._v("例：100")]
                      ),
                    ]),
                  ]
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("el-divider", {
        attrs: { direction: "horizontal", "content-position": "left" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "fWeight500 ft14 mt20" }, [_vm._v("属性")]),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "上距离" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.attr_margin,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "attr_margin", $$v)
              },
              expression: "facade.attr_margin",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "属性分隔符" } },
        [
          _c("el-input", {
            model: {
              value: _vm.facade.attrSeparator,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "attrSeparator", $$v)
              },
              expression: "facade.attrSeparator",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "字号" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.attr_size,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "attr_size", $$v)
              },
              expression: "facade.attr_size",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "行高" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.attr_line_height,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "attr_line_height", $$v)
              },
              expression: "facade.attr_line_height",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "属性名颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#000000" },
            model: {
              value: _vm.facade.attr_color,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "attr_color", $$v)
              },
              expression: "facade.attr_color",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "属性名风格" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.attr_weight,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "attr_weight", $$v)
                },
                expression: "facade.attr_weight",
              },
            },
            [
              _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "属性值颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#000000" },
            model: {
              value: _vm.facade.attr_value_color,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "attr_value_color", $$v)
              },
              expression: "facade.attr_value_color",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "属性值风格" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.attr_value_weight,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "attr_value_weight", $$v)
                },
                expression: "facade.attr_value_weight",
              },
            },
            [
              _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "内容对齐" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.attr_text_align,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "attr_text_align", $$v)
                },
                expression: "facade.attr_text_align",
              },
            },
            [
              _c("el-radio", { attrs: { label: "left" } }, [_vm._v("常规")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "center" } }, [_vm._v("居中")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "right" } }, [_vm._v("居右")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-form-item", { staticClass: "mt20", attrs: { label: "字体" } }, [
        _c(
          "div",
          { staticClass: "flex flex-wrap" },
          [
            _c(
              "el-select",
              {
                staticStyle: { width: "100%" },
                model: {
                  value: _vm.facade.attr_fontFamily,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "attr_fontFamily", $$v)
                  },
                  expression: "facade.attr_fontFamily",
                },
              },
              _vm._l(_vm.fontFamilyList, function (m, i) {
                return _c(
                  "el-option",
                  { key: i, attrs: { value: m.value, label: m.name } },
                  [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c("div", { staticClass: "flex-one" }, [
                        _vm._v(_vm._s(m.name)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ft12",
                          style: "font-family: " + m.value + ";",
                        },
                        [_vm._v("例：100")]
                      ),
                    ]),
                  ]
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("el-divider", {
        attrs: { direction: "horizontal", "content-position": "left" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "fWeight500 ft14 mt20" }, [_vm._v("价格")]),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "上距离" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.price_margin,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "price_margin", $$v)
              },
              expression: "facade.price_margin",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#000000" },
            model: {
              value: _vm.facade.price_color,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "price_color", $$v)
              },
              expression: "facade.price_color",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "字号" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.price_size,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "price_size", $$v)
              },
              expression: "facade.price_size",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "行高" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.price_line_height,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "price_line_height", $$v)
              },
              expression: "facade.price_line_height",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "风格" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.price_weight,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "price_weight", $$v)
                },
                expression: "facade.price_weight",
              },
            },
            [
              _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "内容对齐" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.price_text_align,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "price_text_align", $$v)
                },
                expression: "facade.price_text_align",
              },
            },
            [
              _c("el-radio", { attrs: { label: "left" } }, [_vm._v("常规")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "center" } }, [_vm._v("居中")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "right" } }, [_vm._v("居右")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-form-item", { staticClass: "mt20", attrs: { label: "字体" } }, [
        _c(
          "div",
          { staticClass: "flex flex-wrap" },
          [
            _c(
              "el-select",
              {
                staticStyle: { width: "100%" },
                model: {
                  value: _vm.facade.price_fontFamily,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "price_fontFamily", $$v)
                  },
                  expression: "facade.price_fontFamily",
                },
              },
              _vm._l(_vm.fontFamilyList, function (m, i) {
                return _c(
                  "el-option",
                  { key: i, attrs: { value: m.value, label: m.name } },
                  [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c("div", { staticClass: "flex-one" }, [
                        _vm._v(_vm._s(m.name)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ft12",
                          style: "font-family: " + m.value + ";",
                        },
                        [_vm._v("例：100")]
                      ),
                    ]),
                  ]
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("el-divider", {
        attrs: { direction: "horizontal", "content-position": "left" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "fWeight500 ft14 mt20" }, [_vm._v("详情")]),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "展示位置" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.show_area,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "show_area", $$v)
                },
                expression: "facade.show_area",
              },
            },
            [
              _c("el-radio", { attrs: { label: "1" } }, [_vm._v("集合展示")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "2" } }, [_vm._v("单独展示")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "上距离" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.content_margin,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "content_margin", $$v)
              },
              expression: "facade.content_margin",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#000000" },
            model: {
              value: _vm.facade.content_color,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "content_color", $$v)
              },
              expression: "facade.content_color",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "字号" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.content_size,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "content_size", $$v)
              },
              expression: "facade.content_size",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "行高" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.content_line_height,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "content_line_height", $$v)
              },
              expression: "facade.content_line_height",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "风格" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.content_weight,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "content_weight", $$v)
                },
                expression: "facade.content_weight",
              },
            },
            [
              _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "内容对齐" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.content_text_align,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "content_text_align", $$v)
                },
                expression: "facade.content_text_align",
              },
            },
            [
              _c("el-radio", { attrs: { label: "left" } }, [_vm._v("常规")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "center" } }, [_vm._v("居中")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "right" } }, [_vm._v("居右")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-form-item", { staticClass: "mt20", attrs: { label: "字体" } }, [
        _c(
          "div",
          { staticClass: "flex flex-wrap" },
          [
            _c(
              "el-select",
              {
                staticStyle: { width: "100%" },
                model: {
                  value: _vm.facade.content_fontFamily,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "content_fontFamily", $$v)
                  },
                  expression: "facade.content_fontFamily",
                },
              },
              _vm._l(_vm.fontFamilyList, function (m, i) {
                return _c(
                  "el-option",
                  { key: i, attrs: { value: m.value, label: m.name } },
                  [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c("div", { staticClass: "flex-one" }, [
                        _vm._v(_vm._s(m.name)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ft12",
                          style: "font-family: " + m.value + ";",
                        },
                        [_vm._v("例：100")]
                      ),
                    ]),
                  ]
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("el-divider", {
        attrs: { direction: "horizontal", "content-position": "left" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "fWeight500 ft14 mt20" }, [_vm._v("其他")]),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "上距离" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.desc_margin,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "desc_margin", $$v)
              },
              expression: "facade.desc_margin",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "颜色" } },
        [
          _c("colour", {
            attrs: { colour: "#000000" },
            model: {
              value: _vm.facade.desc_color,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "desc_color", $$v)
              },
              expression: "facade.desc_color",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "字号" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.desc_size,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "desc_size", $$v)
              },
              expression: "facade.desc_size",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "行高" } },
        [
          _c("slider", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.facade.desc_line_height,
              callback: function ($$v) {
                _vm.$set(_vm.facade, "desc_line_height", $$v)
              },
              expression: "facade.desc_line_height",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "风格" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.desc_weight,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "desc_weight", $$v)
                },
                expression: "facade.desc_weight",
              },
            },
            [
              _c("el-radio", { attrs: { label: 400 } }, [_vm._v("正常")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 600 } }, [_vm._v("加粗")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 300 } }, [_vm._v("加细")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { staticClass: "mt20", attrs: { label: "对齐" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.facade.desc_text_align,
                callback: function ($$v) {
                  _vm.$set(_vm.facade, "desc_text_align", $$v)
                },
                expression: "facade.desc_text_align",
              },
            },
            [
              _c("el-radio", { attrs: { label: "left" } }, [_vm._v("常规")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "center" } }, [_vm._v("居中")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: "right" } }, [_vm._v("居右")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-form-item", { staticClass: "mt20", attrs: { label: "字体" } }, [
        _c(
          "div",
          { staticClass: "flex flex-wrap" },
          [
            _c(
              "el-select",
              {
                staticStyle: { width: "100%" },
                model: {
                  value: _vm.facade.desc_fontFamily,
                  callback: function ($$v) {
                    _vm.$set(_vm.facade, "desc_fontFamily", $$v)
                  },
                  expression: "facade.desc_fontFamily",
                },
              },
              _vm._l(_vm.fontFamilyList, function (m, i) {
                return _c(
                  "el-option",
                  { key: i, attrs: { value: m.value, label: m.name } },
                  [
                    _c("div", { staticClass: "flex align-items-c" }, [
                      _c("div", { staticClass: "flex-one" }, [
                        _vm._v(_vm._s(m.name)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ft12",
                          style: "font-family: " + m.value + ";",
                        },
                        [_vm._v("例：100")]
                      ),
                    ]),
                  ]
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }