"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
var _codemirror = require("codemirror");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      venue_name: this.$route.query.vname || "",
      venue_id: this.$route.query.vid || "",
      oneNum: 0,
      twoNum: 0,
      liveCount: 0,
      routerUrl: "",
      label: this.$route.query.label || ""
    };
  },
  computed: {
    key: function key() {
      return this.$route.path;
    }
  },
  created: function created() {
    this.oneNum = Number(this.$route.query.oneNumber);
    this.twoNum = Number(this.$route.query.twoNumber);
    this.liveCount = Number(this.$route.query.liveCount);
    this.routerUrl = this.$route.path;
    // liveGroupListApi({venue_id: this.$route.query.vid}).then(res => {
    //   let arr = []
    //   res.data.forEach(item => {
    //     arr.push(...item.data)
    //   });
    //   let newArr = arr.filter(item => [2,3].includes(item.album_type))
    //   this.isNo = newArr.length>0
    // });
  },
  methods: {
    changeTab: function changeTab(data) {
      if (data !== this.routerUrl) {
        this.$router.replace({
          path: data,
          query: this.$route.query
        });
        // this.routerUrl = data;
        // setTimeout(() => {
        //   this.routerUrl = this.$route.path;
        // });
      }
    },
    backAction: function backAction() {
      this.$router.replace("/merchant/pageManagement/auction/meeting");
    },
    onGOUrl: function onGOUrl(url, type) {
      this.$router.push({
        path: url
      });
    }
  }
};