var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "video-content",
      class: {
        "video-content-sdk": _vm.aspectRatio == "9:16",
        "video-content-sdk video-content-sdk34": _vm.aspectRatio == "3:4",
      },
    },
    [
      _c("div", {
        ref: "containerRef",
        staticStyle: { height: "100%", width: "100%" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }