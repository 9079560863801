var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.merData.config
    ? _c(
        "div",
        { class: { "no-previre": !_vm.preview } },
        [
          _c(
            "div",
            {
              staticClass: "detail-box",
              style: _vm.preview ? "margin: 20px 0;" : "",
            },
            [
              _c("div", { staticClass: "flex" }, [
                _vm.merData.config && _vm.merData.config.mer_print_logo
                  ? _c("img", {
                      staticStyle: { height: "70px", "margin-right": "20px" },
                      attrs: {
                        src: _vm.merData.config.mer_print_logo + "!120a",
                        alt: "",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "detail-left edit-view",
                    on: {
                      click: function ($event) {
                        return _vm.goEditAction("title")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "detail-left-title" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.merData.company_name) +
                          " | " +
                          _vm._s(_vm.detail.title.china) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "detail-left-desc",
                        staticStyle: {
                          "font-size": "14px",
                          "font-family": "'printEN'",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.merData.company_english_name) +
                              " " +
                              _vm._s(_vm.detail.title.english)
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "edit-view",
              on: {
                click: function ($event) {
                  return _vm.goEditAction("buyerCompany")
                },
              },
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "secTit" }, [
                _c("span", [_vm._v(_vm._s(_vm.detail.buyerCompany.china))]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-left-desc",
                  staticStyle: {
                    "font-size": "13px",
                    "font-family": "'printEN'",
                    "font-weight": "500",
                    "word-break": "break-word",
                  },
                },
                [_c("span", [_vm._v(_vm._s(_vm.detail.buyerCompany.english))])]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "detail-left-title",
              staticStyle: { "margin-top": "40px" },
            },
            [_vm._v("专场名称")]
          ),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "detail-list",
              staticStyle: { "margin-top": "5px", "white-space": "nowrap" },
            },
            [
              _c("div", [
                _c("div", [
                  _vm._v(
                    "\n              " + _vm._s(_vm.switchLang("yzsj")) + "："
                  ),
                  _c("span", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v("2024-11-13"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("Prev.Time：2024-11-13")]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("div", [
                  _vm._v(
                    "\n              " + _vm._s(_vm.switchLang("pmsj")) + "："
                  ),
                  _c("span", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v("2024-11-13"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("Auct.Time：2024-11-13")]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("div", [
                  _vm._v(
                    "\n              " + _vm._s(_vm.switchLang("zpdz")) + "："
                  ),
                  _c("span", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v("小程序尼尔"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("Exh. & Auct. Addr.：Mini Program Neil")]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ptd-box" }, [
            _c(
              "div",
              { staticClass: "ptd", staticStyle: { "font-weight": "bold" } },
              [
                _vm._l(_vm.detail.tableColumn, function (m, i) {
                  return [
                    m.open
                      ? [
                          _c(
                            "div",
                            {
                              key: "col" + i,
                              staticClass: "ptd-title",
                              style: m.w ? "width: " + m.w + "px;" : "flex: 1",
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(m.showName || m.name)),
                              ]),
                              m.english
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'printEN'",
                                        "white-space": "nowrap",
                                      },
                                    },
                                    [_vm._v(_vm._s(m.english))]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ptd" }, [
              _vm.detail.tableColumn[0].open
                ? _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      style: "width: " + _vm.detail.tableColumn[0].w + "px;",
                    },
                    [_vm._v("103")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableColumn[1].open
                ? _c(
                    "div",
                    { staticClass: "ptd-body", staticStyle: { flex: "1" } },
                    [_vm._v("清 大开门专场")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableColumn[2].open
                ? _c(
                    "div",
                    { staticClass: "ptd-body", staticStyle: { flex: "1" } },
                    [_vm._v("清 乾隆玉枕")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableColumn[3].open
                ? _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      style: "width: " + _vm.detail.tableColumn[3].w + "px;",
                    },
                    [_vm._v("202412120001")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableColumn[4].open
                ? _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      style:
                        "width: " +
                        _vm.detail.tableColumn[4].w +
                        "px;text-align: center;",
                    },
                    [_vm._v("30,000")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableColumn[5].open
                ? _c("div", {
                    staticClass: "ptd-body",
                    style: "width: " + _vm.detail.tableColumn[5].w + "px;",
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableColumn[6].open
                ? _c("div", {
                    staticClass: "ptd-body",
                    style: "width: " + _vm.detail.tableColumn[6].w + "px;",
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ptd" }, [
              _vm.detail.tableColumn[0].open
                ? _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      style: "width: " + _vm.detail.tableColumn[0].w + "px;",
                    },
                    [_vm._v("103")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableColumn[1].open
                ? _c(
                    "div",
                    { staticClass: "ptd-body", staticStyle: { flex: "1" } },
                    [_vm._v("清 大开门专场")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableColumn[2].open
                ? _c(
                    "div",
                    { staticClass: "ptd-body", staticStyle: { flex: "1" } },
                    [_vm._v("清 黄花梨圆桌")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableColumn[3].open
                ? _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      style: "width: " + _vm.detail.tableColumn[3].w + "px;",
                    },
                    [_vm._v("202412120001")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableColumn[4].open
                ? _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      style:
                        "width: " +
                        _vm.detail.tableColumn[4].w +
                        "px;text-align: center;",
                    },
                    [_vm._v("130,000")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableColumn[5].open
                ? _c("div", {
                    staticClass: "ptd-body",
                    style: "width: " + _vm.detail.tableColumn[5].w + "px;",
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableColumn[6].open
                ? _c("div", {
                    staticClass: "ptd-body",
                    style: "width: " + _vm.detail.tableColumn[6].w + "px;",
                  })
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "edit-view",
              on: {
                click: function ($event) {
                  return _vm.goEditAction("settleText")
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "secTit",
                  staticStyle: { "margin-top": "30px" },
                },
                [_vm._v(_vm._s(_vm.detail.settleText.china))]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "detail-left-desc",
                  staticStyle: {
                    "font-size": "13px",
                    "font-family": "'printEN'",
                    "font-weight": "500",
                  },
                },
                [_vm._v(_vm._s(_vm.detail.settleText.english))]
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ptd-box" }, [
            _c(
              "div",
              {
                staticClass: "ptd edit-view",
                staticStyle: { "font-weight": "bold" },
                on: {
                  click: function ($event) {
                    return _vm.goEditAction("tableTwoColumn")
                  },
                },
              },
              [
                _vm._l(_vm.detail.tableTwoColumn, function (m, i) {
                  return [
                    m.open
                      ? [
                          _c(
                            "div",
                            {
                              key: "col" + i,
                              staticClass: "ptd-title",
                              style: m.w ? "width: " + m.w + "px;" : "flex: 1",
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(m.showName || m.name)),
                              ]),
                              m.english
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "'printEN'",
                                        "white-space": "nowrap",
                                      },
                                    },
                                    [_vm._v(_vm._s(m.english))]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ptd" }, [
              _vm.detail.tableTwoColumn[0].open
                ? _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      style: "width: " + _vm.detail.tableTwoColumn[0].w + "px;",
                    },
                    [_vm._v("1")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableTwoColumn[1].open
                ? _c(
                    "div",
                    { staticClass: "ptd-body", staticStyle: { flex: "1" } },
                    [_vm._v("清 大开门专场")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableTwoColumn[2].open
                ? _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      style: "width: " + _vm.detail.tableTwoColumn[2].w + "px;",
                    },
                    [_vm._v("1-11")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableTwoColumn[3].open
                ? _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      style: "width: " + _vm.detail.tableTwoColumn[3].w + "px;",
                    },
                    [_vm._v("11月25日")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableTwoColumn[4].open
                ? _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      style: "width: " + _vm.detail.tableTwoColumn[4].w + "px;",
                    },
                    [_vm._v("11:56")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableTwoColumn[5].open
                ? _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      style: "width: " + _vm.detail.tableTwoColumn[5].w + "px;",
                    },
                    [_vm._v("11月25日")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableTwoColumn[6].open
                ? _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      style: "width: " + _vm.detail.tableTwoColumn[6].w + "px;",
                    },
                    [_vm._v("11:56")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableTwoColumn[7].open
                ? _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      style: "width: " + _vm.detail.tableTwoColumn[7].w + "px;",
                    },
                    [_vm._v("A")]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ptd" }, [
              _vm.detail.tableTwoColumn[0].open
                ? _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      style: "width: " + _vm.detail.tableTwoColumn[0].w + "px;",
                    },
                    [_vm._v("1")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableTwoColumn[1].open
                ? _c(
                    "div",
                    { staticClass: "ptd-body", staticStyle: { flex: "1" } },
                    [_vm._v("清 大开门专场")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableTwoColumn[2].open
                ? _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      style: "width: " + _vm.detail.tableTwoColumn[2].w + "px;",
                    },
                    [_vm._v("50-120")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableTwoColumn[3].open
                ? _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      style: "width: " + _vm.detail.tableTwoColumn[3].w + "px;",
                    },
                    [_vm._v("11月25日")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableTwoColumn[4].open
                ? _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      style: "width: " + _vm.detail.tableTwoColumn[4].w + "px;",
                    },
                    [_vm._v("13:56")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableTwoColumn[5].open
                ? _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      style: "width: " + _vm.detail.tableTwoColumn[5].w + "px;",
                    },
                    [_vm._v("11月25日")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableTwoColumn[6].open
                ? _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      style: "width: " + _vm.detail.tableTwoColumn[6].w + "px;",
                    },
                    [_vm._v("11:56")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.detail.tableTwoColumn[7].open
                ? _c(
                    "div",
                    {
                      staticClass: "ptd-body",
                      style: "width: " + _vm.detail.tableTwoColumn[7].w + "px;",
                    },
                    [_vm._v("B")]
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "edit-view",
              on: {
                click: function ($event) {
                  return _vm.goEditAction("signColumn")
                },
              },
            },
            [
              _vm.detail.signColumnTab == "system"
                ? _c(
                    "div",
                    {
                      staticClass: "detail-list flex-wrap",
                      staticStyle: { "margin-top": "30px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "ptd-bottom ft14",
                          attrs: { id: "ptd-bottom" },
                        },
                        [
                          _c("div", { staticClass: "ptd-bottom-left" }, [
                            _c("div", { staticClass: "ptd-bottom-left-list" }, [
                              _vm._v(
                                "\n            公司地址：" +
                                  _vm._s(_vm.merData.config.company_address) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "ptd-bottom-left-list" }, [
                              _vm._v(
                                "\n            Company Address: " +
                                  _vm._s(
                                    _vm.merData.config
                                      .company_english_address || ""
                                  ) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "ptd-bottom-left-list" }, [
                              _vm._v(
                                "公司电话/TEL： " +
                                  _vm._s(_vm.merData.config.contact_phone)
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "ptd-bottom-right" },
                            [
                              _vm._l(
                                _vm.merData.config.mer_qrcode_data,
                                function (item, index) {
                                  return [
                                    item.imgUrl
                                      ? _c(
                                          "div",
                                          {
                                            key: "rcimg" + index,
                                            staticClass: "ptd-bottom-img-list",
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                crossorigin: "anonymous",
                                                src: item.imgUrl + "!120a",
                                                alt: "",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ptd-bottom-img-list-title",
                                              },
                                              [_vm._v(_vm._s(item.name))]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm.detail.signColumnTab == "custome"
                ? _c(
                    "div",
                    {
                      staticClass: "custome-view",
                      staticStyle: { "margin-top": "30px" },
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.detail.signColumnCustome),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          !_vm.preview
            ? [
                _c(
                  "el-drawer",
                  {
                    attrs: {
                      visible: _vm.showSettings,
                      size: "900px",
                      direction: "rtl",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.showSettings = $event
                      },
                    },
                  },
                  [
                    _vm.settingKey == "title"
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "edit-title",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [_vm._v("标题文字修改")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "edit-content" }, [
                            _c(
                              "div",
                              { staticClass: "edit-input" },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    row: 3,
                                    placeholder: "输入中文自定义内容",
                                  },
                                  model: {
                                    value: _vm.detail.title.china,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.detail.title, "china", $$v)
                                    },
                                    expression: "detail.title.china",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "edit-input" },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    row: 3,
                                    placeholder: "输入英文自定义内容",
                                  },
                                  model: {
                                    value: _vm.detail.title.english,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.detail.title, "english", $$v)
                                    },
                                    expression: "detail.title.english",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.settingKey == "buyerCompany"
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "edit-title",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [_vm._v("顶部文字修改")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "edit-content" }, [
                            _c(
                              "div",
                              { staticClass: "edit-input" },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    row: 3,
                                    placeholder: "输入中文自定义内容",
                                  },
                                  model: {
                                    value: _vm.detail.buyerCompany.china,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.detail.buyerCompany,
                                        "china",
                                        $$v
                                      )
                                    },
                                    expression: "detail.buyerCompany.china",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "edit-input" },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    row: 3,
                                    placeholder: "输入英文自定义内容",
                                  },
                                  model: {
                                    value: _vm.detail.buyerCompany.english,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.detail.buyerCompany,
                                        "english",
                                        $$v
                                      )
                                    },
                                    expression: "detail.buyerCompany.english",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.settingKey == "tableColumn"
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "edit-title",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [_vm._v("表格展示列设置")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "edit-content" }, [
                            _c("div", { staticClass: "flex align-items-s" }, [
                              _c("div", { staticClass: "proof-table" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "proof-title item-flex-center",
                                  },
                                  [
                                    _c("div", { staticClass: "w100 td" }, [
                                      _vm._v("是否启用"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "flex-one td" }, [
                                      _vm._v("字段名称"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "flex-one td" }, [
                                      _vm._v("中文自定义"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "flex-one td" }, [
                                      _vm._v("英文自定义"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "w140 td" }, [
                                      _vm._v("列宽"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "proof-content" },
                                  _vm._l(
                                    _vm.detail.tableColumn,
                                    function (m, i) {
                                      return _c(
                                        "div",
                                        {
                                          key: i,
                                          staticClass: "item-flex-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "w100 td" },
                                            [
                                              _c("el-checkbox", {
                                                model: {
                                                  value: m.open,
                                                  callback: function ($$v) {
                                                    _vm.$set(m, "open", $$v)
                                                  },
                                                  expression: "m.open",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flex-one td" },
                                            [_vm._v(_vm._s(m.name))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flex-one td" },
                                            [
                                              _c("el-input", {
                                                staticStyle: {
                                                  "text-align": "center",
                                                },
                                                on: {
                                                  focus: function ($event) {
                                                    return _vm.focus($event)
                                                  },
                                                },
                                                model: {
                                                  value: m.showName,
                                                  callback: function ($$v) {
                                                    _vm.$set(m, "showName", $$v)
                                                  },
                                                  expression: "m.showName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flex-one td" },
                                            [
                                              _c("el-input", {
                                                staticStyle: {
                                                  "text-align": "center",
                                                },
                                                on: {
                                                  focus: function ($event) {
                                                    return _vm.focus($event)
                                                  },
                                                },
                                                model: {
                                                  value: m.english,
                                                  callback: function ($$v) {
                                                    _vm.$set(m, "english", $$v)
                                                  },
                                                  expression: "m.english",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "w140 td" },
                                            [
                                              i == 1
                                                ? [_vm._v("剩余全部宽度")]
                                                : [
                                                    _c("el-input-number", {
                                                      attrs: {
                                                        controls: false,
                                                      },
                                                      on: {
                                                        focus: function (
                                                          $event
                                                        ) {
                                                          return _vm.focus(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: m.w,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(m, "w", $$v)
                                                        },
                                                        expression: "m.w",
                                                      },
                                                    }),
                                                  ],
                                            ],
                                            2
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.settingKey == "tableTwoColumn"
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "edit-title",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [_vm._v("表格展示列设置")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "edit-content" }, [
                            _c("div", { staticClass: "flex align-items-s" }, [
                              _c("div", { staticClass: "proof-table" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "proof-title item-flex-center",
                                  },
                                  [
                                    _c("div", { staticClass: "w100 td" }, [
                                      _vm._v("是否启用"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "flex-one td" }, [
                                      _vm._v("字段名称"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "flex-one td" }, [
                                      _vm._v("中文自定义"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "flex-one td" }, [
                                      _vm._v("英文自定义"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "w140 td" }, [
                                      _vm._v("列宽"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "proof-content" },
                                  _vm._l(
                                    _vm.detail.tableTwoColumn,
                                    function (m, i) {
                                      return _c(
                                        "div",
                                        {
                                          key: i,
                                          staticClass: "item-flex-center",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "w100 td" },
                                            [
                                              _c("el-checkbox", {
                                                model: {
                                                  value: m.open,
                                                  callback: function ($$v) {
                                                    _vm.$set(m, "open", $$v)
                                                  },
                                                  expression: "m.open",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flex-one td" },
                                            [_vm._v(_vm._s(m.name))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flex-one td" },
                                            [
                                              _c("el-input", {
                                                staticStyle: {
                                                  "text-align": "center",
                                                },
                                                on: {
                                                  focus: function ($event) {
                                                    return _vm.focus($event)
                                                  },
                                                },
                                                model: {
                                                  value: m.showName,
                                                  callback: function ($$v) {
                                                    _vm.$set(m, "showName", $$v)
                                                  },
                                                  expression: "m.showName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flex-one td" },
                                            [
                                              _c("el-input", {
                                                staticStyle: {
                                                  "text-align": "center",
                                                },
                                                on: {
                                                  focus: function ($event) {
                                                    return _vm.focus($event)
                                                  },
                                                },
                                                model: {
                                                  value: m.english,
                                                  callback: function ($$v) {
                                                    _vm.$set(m, "english", $$v)
                                                  },
                                                  expression: "m.english",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "w140 td" },
                                            [
                                              i == 1
                                                ? [_vm._v("剩余全部宽度")]
                                                : [
                                                    _c("el-input-number", {
                                                      attrs: {
                                                        controls: false,
                                                      },
                                                      on: {
                                                        focus: function (
                                                          $event
                                                        ) {
                                                          return _vm.focus(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: m.w,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(m, "w", $$v)
                                                        },
                                                        expression: "m.w",
                                                      },
                                                    }),
                                                  ],
                                            ],
                                            2
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.settingKey == "settleText"
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "edit-title",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [_vm._v("签收确认文字修改")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "edit-content" }, [
                            _c(
                              "div",
                              { staticClass: "edit-input" },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    row: 3,
                                    placeholder: "输入中文自定义内容",
                                  },
                                  model: {
                                    value: _vm.detail.settleText.china,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.detail.settleText,
                                        "china",
                                        $$v
                                      )
                                    },
                                    expression: "detail.settleText.china",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "edit-input" },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    row: 3,
                                    placeholder: "输入英文自定义内容",
                                  },
                                  model: {
                                    value: _vm.detail.settleText.english,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.detail.settleText,
                                        "english",
                                        $$v
                                      )
                                    },
                                    expression: "detail.settleText.english",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.settingKey == "signColumn"
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "edit-title",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [_vm._v("签字区域修改")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "edit-content" },
                            [
                              _c(
                                "el-tabs",
                                {
                                  model: {
                                    value: _vm.detail.signColumnTab,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.detail, "signColumnTab", $$v)
                                    },
                                    expression: "detail.signColumnTab",
                                  },
                                },
                                [
                                  _c(
                                    "el-tab-pane",
                                    {
                                      attrs: {
                                        label: "系统内设",
                                        name: "system",
                                      },
                                    },
                                    [_vm._v("系统内设")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-tab-pane",
                                    {
                                      attrs: {
                                        label: "自定义",
                                        name: "custome",
                                      },
                                    },
                                    [
                                      _c("Tinymce", {
                                        ref: "editor",
                                        attrs: { height: 600 },
                                        model: {
                                          value: _vm.detail.signColumnCustome,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.detail,
                                              "signColumnCustome",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "detail.signColumnCustome",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "formFixedBox",
                    staticStyle: { left: "140px", "z-index": "10" },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.saveAction },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "secTit", staticStyle: { "margin-top": "30px" } },
      [
        _vm._v("\n      致\n      "),
        _c(
          "span",
          { staticStyle: { padding: "0 4px", "font-weight": "bold" } },
          [_vm._v("马老三")]
        ),
        _vm._v("\n      先生：\n    "),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "detail-left-desc",
        staticStyle: {
          "font-size": "13px",
          "font-family": "'printEN'",
          "font-weight": "500",
        },
      },
      [_c("span", [_vm._v("To Mr. Ma Lao San:")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "detail-left-desc",
        staticStyle: {
          "font-size": "13px",
          "font-family": "'printEN'",
          "font-weight": "500",
        },
      },
      [_c("span", [_vm._v("English Name")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }