var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "control",
    {
      attrs: {
        title: "拍品",
        "has-template": _vm.$route.query.type == "manage" ? "1" : "",
      },
    },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "banner-control",
              attrs: {
                model: _vm.content,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "auction-select-add",
                  on: { click: _vm.handleAdditem },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-plus",
                    staticStyle: { "margin-right": "8px" },
                  }),
                  _vm._v(" "),
                  _c("a", { staticClass: "fontW500 ft16" }, [
                    _vm._v("选择拍品"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.content.attrlist
                ? _c(
                    "div",
                    { staticClass: "attr-list mt20" },
                    [
                      _vm._l(_vm.content.attrlist, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "attr-item flex" },
                          [
                            _c("div", { staticClass: "attr-name" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "attr-sort-image" }, [
                              index === 0
                                ? _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                                      alt: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goattrDown(index)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              index > 0
                                ? _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                                      alt: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goattrUp(index)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("el-checkbox", {
                                  model: {
                                    value: item.selected,
                                    callback: function ($$v) {
                                      _vm.$set(item, "selected", $$v)
                                    },
                                    expression: "item.selected",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "attr-item flex" }, [
                        _c("div", { staticClass: "attr-name" }, [
                          _vm._v("拍品图片"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "attr-sort-image" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.content.auctionSlideFlag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.content, "auctionSlideFlag", $$v)
                                },
                                expression: "content.auctionSlideFlag",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.data && _vm.content.data.length > 0
                ? _c(
                    "div",
                    { staticClass: "mt20" },
                    [
                      _c(
                        "draggable",
                        _vm._b(
                          {
                            staticClass: "board-column-content flex flex-wrap",
                            attrs: { list: _vm.content.data },
                          },
                          "draggable",
                          _vm.$attrs,
                          false
                        ),
                        _vm._l(_vm.content.data, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "kuang1 item-align-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "flex-one ft12 flex",
                                  staticStyle: { overflow: "hidden" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "auction-img mr10" },
                                    [
                                      item.slider_image && item.slider_image[0]
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                item.slider_image[0] + "!120a",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.slider_image
                                        ? _c("div", { staticClass: "tips" }, [
                                            _vm._v(
                                              _vm._s(item.slider_image.length) +
                                                "张"
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.content.attrlist &&
                                  _vm.content.attrlist.length
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "flex-one",
                                          staticStyle: { overflow: "hidden" },
                                        },
                                        [
                                          _vm._l(
                                            _vm.content.attrlist,
                                            function (it, index) {
                                              return [
                                                it.selected &&
                                                item[it.key] &&
                                                it.key != "content"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: "attr" + index,
                                                        staticClass: "line1",
                                                        class:
                                                          index > 0 ? "" : "",
                                                        staticStyle: {
                                                          "line-height": "20px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item[it.key])
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "go-down-img ml10",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editAuction(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "20px",
                                      height: "20px",
                                      "margin-top": "2px",
                                      "margin-left": "2px",
                                    },
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024919/image/1726732000092969257.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              index === 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "go-down-img ml10",
                                      on: {
                                        click: function ($event) {
                                          return _vm.goDown(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "https://saas.cdn.yunzongbu.cn/image/20211012/c056e317a22dd6b814c824e4e61cbb4a.png",
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              index > 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "go-up-img ml10",
                                      on: {
                                        click: function ($event) {
                                          return _vm.goUp(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "https://saas.cdn.yunzongbu.cn/image/20211012/591152c4c067745bbcf090efc04a0a45.png",
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "del-img",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "https://saas.cdn.yunzongbu.cn/image/20211012/19a38db7df8eb69a69b765ab9ec36f17.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "style" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "fant-form",
              attrs: {
                model: _vm.facade,
                "label-width": "100px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "fontW400 ft14 color-black" }, [
                  _vm._v("拍品图片"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-align-center flex-wrap" },
                _vm._l(_vm.styleData, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "change-type",
                      staticStyle: {
                        "margin-right": "20px",
                        "margin-top": "15px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "img",
                          class: { curr: _vm.is_active(item) },
                          on: {
                            click: function ($event) {
                              return _vm.setStyle(item.value)
                            },
                          },
                        },
                        [_c("label", [_c("img", { attrs: { src: item.src } })])]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "name ft14 color-gray align-c" },
                        [_vm._v(_vm._s(item.name))]
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.facade.style == 1 ||
              _vm.facade.style == 6 ||
              _vm.facade.style == 7
                ? _c(
                    "div",
                    {
                      staticClass: "attr-item flex mt20",
                      staticStyle: { "align-items": "center" },
                    },
                    [
                      _c("div", { staticClass: "attr-name" }, [
                        _vm._v("轮播展示效果"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "attr-sort-image" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100px" },
                              model: {
                                value: _vm.content.playmodel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.content, "playmodel", $$v)
                                },
                                expression: "content.playmodel",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "左右滑动", value: 1 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "上下滑动", value: 2 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "进入放大移出", value: 3 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "多方向进入", value: 4 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "翻转切换", value: 5 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "横向轮播", value: 6 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "纵向滚动", value: 7 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.facade.style == 1 ||
              _vm.facade.style == 6 ||
              _vm.facade.style == 7
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "轮播图高度" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flex align-items-c",
                          staticStyle: { "justify-content": "flex-end" },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("el-input-number", {
                                staticClass: "w80",
                                attrs: { controls: false, disabled: "" },
                                model: {
                                  value: _vm.facade.swiperWidth,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "swiperWidth", $$v)
                                  },
                                  expression: "facade.swiperWidth",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "ml5 mr5" }, [_vm._v("×")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("el-input-number", {
                                staticClass: "w80",
                                attrs: { controls: false },
                                model: {
                                  value: _vm.facade.swiperHeight,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.facade, "swiperHeight", $$v)
                                  },
                                  expression: "facade.swiperHeight",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.playmodel == 1
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "动画提示间距" } },
                    [
                      _c("slider", {
                        attrs: { min: 0, max: 100 },
                        model: {
                          value: _vm.facade.animate_margin,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "animate_margin", $$v)
                          },
                          expression: "facade.animate_margin",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.content.playmodel == 1
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "动画提示颜色" } },
                    [
                      _c("colour", {
                        attrs: { colour: "#FFFFFF" },
                        model: {
                          value: _vm.facade.animate_color,
                          callback: function ($$v) {
                            _vm.$set(_vm.facade, "animate_color", $$v)
                          },
                          expression: "facade.animate_color",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt20" },
                [
                  _c("span", [_vm._v("长图展示")]),
                  _vm._v(" "),
                  _c("el-switch", {
                    attrs: { "active-value": true, "inactive-value": false },
                    model: {
                      value: _vm.facade.showLongImage,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "showLongImage", $$v)
                      },
                      expression: "facade.showLongImage",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.facade.showLongImage
                ? [
                    _c(
                      "div",
                      { staticClass: "mt20" },
                      [
                        _c("span", [_vm._v("长图移动播放模式")]),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "1" },
                            model: {
                              value: _vm.facade.longMode,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "longMode", $$v)
                              },
                              expression: "facade.longMode",
                            },
                          },
                          [_vm._v(" ")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item-align-center flex-wrap" },
                      _vm._l(_vm.longStyleData, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "change-type",
                            staticStyle: {
                              "margin-right": "20px",
                              "margin-top": "15px",
                            },
                          },
                          [
                            _c("div", { staticClass: "img" }, [
                              _c("label", [
                                _c("img", { attrs: { src: item.src } }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "name ft14 color-gray align-c" },
                              [_vm._v(_vm._s(item.name))]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mt20" },
                      [
                        _c("span", [_vm._v("横向长图改竖向展示")]),
                        _vm._v(" "),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "2" },
                            model: {
                              value: _vm.facade.longMode,
                              callback: function ($$v) {
                                _vm.$set(_vm.facade, "longMode", $$v)
                              },
                              expression: "facade.longMode",
                            },
                          },
                          [_vm._v(" ")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt20" }, [
                      _c("img", {
                        staticStyle: { width: "66px" },
                        attrs: {
                          src: "https://saas.cdn.yunzongbu.cn/merchant/23/202487/image/1722999889648769938.png!120a",
                          alt: "",
                        },
                      }),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "选色配色" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap" },
                    _vm._l(_vm.color_configuration, function (m, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "color",
                          on: {
                            click: function ($event) {
                              return _vm.setThemeAction(i)
                            },
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "color-top",
                            style: "height: 30px;background: " + m.sub_text,
                          }),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "AI配色" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex align-items-c" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "item-align-center",
                          staticStyle: { height: "30px" },
                          model: {
                            value: _vm.facade.ai_color,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "ai_color", $$v)
                            },
                            expression: "facade.ai_color",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              staticClass: "item-align-center",
                              attrs: { label: "1" },
                            },
                            [_c("div", [_vm._v("AI统一色")])]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              staticClass: "item-align-center",
                              attrs: { label: "2" },
                            },
                            [_c("div", [_vm._v("AI动态色")])]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ml20" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.generateColors },
                            },
                            [_vm._v("生成配色")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.configuration && _vm.configuration.length
                ? _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { label: "装饰" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap" },
                        _vm._l(_vm.configuration, function (m, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass: "configuration",
                              class: {
                                curr: _vm.facade.personalizedDecoration == i,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.changePersonalDecoration(i)
                                },
                              },
                            },
                            [_c("img", { attrs: { src: m.icon, alt: "" } })]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "排版" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "item-align-center",
                      staticStyle: { height: "30px" },
                      model: {
                        value: _vm.facade.img_position,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "img_position", $$v)
                        },
                        expression: "facade.img_position",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          staticClass: "item-align-center",
                          attrs: { label: "center" },
                        },
                        [_c("div", [_vm._v("默认")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          staticClass: "item-align-center",
                          attrs: { label: "right" },
                        },
                        [_c("div", [_vm._v("图片居右")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          staticClass: "item-align-center",
                          attrs: { label: "left" },
                        },
                        [_c("div", [_vm._v("图片居左")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "页面边距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.page_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "page_margin", $$v)
                      },
                      expression: "facade.page_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "拍品背景" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.item_background,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "item_background", $$v)
                      },
                      expression: "facade.item_background",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "拍品圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "radius", $$v)
                      },
                      expression: "facade.radius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "方格间距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.square_padding,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "square_padding", $$v)
                      },
                      expression: "facade.square_padding",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "拍品内间距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.item_padding,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "item_padding", $$v)
                      },
                      expression: "facade.item_padding",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "拍品间隔距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.item_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "item_margin", $$v)
                      },
                      expression: "facade.item_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "间距线颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.auction_spacing_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "auction_spacing_color", $$v)
                      },
                      expression: "facade.auction_spacing_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "间距线高度" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.auction_spacing_height,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "auction_spacing_height", $$v)
                      },
                      expression: "facade.auction_spacing_height",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "间距线样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "item-align-center",
                      staticStyle: { height: "30px" },
                      model: {
                        value: _vm.facade.auction_spacing_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "auction_spacing_type", $$v)
                        },
                        expression: "facade.auction_spacing_type",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          staticClass: "item-align-center",
                          attrs: { label: "solid" },
                        },
                        [_c("div", [_vm._v("默认")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          staticClass: "item-align-center",
                          attrs: { label: "dashed" },
                        },
                        [_c("div", [_vm._v("虚线")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "item-align-center mt20" }, [
                _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
                  _vm._v("拍品间距图片"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "upload-img-view item-flex-center",
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap(1, "auction_spacing_image")
                      },
                    },
                  },
                  [
                    _vm.facade.auction_spacing_image
                      ? _c(
                          "div",
                          {
                            staticClass: "del-upload-img",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.delImg("auction_spacing_image")
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                alt: "",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.facade.auction_spacing_image
                      ? _c("img", {
                          attrs: {
                            src: _vm.facade.auction_spacing_image + "!300",
                          },
                        })
                      : _c("img", {
                          staticClass: "upload-icon",
                          attrs: {
                            src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                            alt: "",
                          },
                        }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "间距图片大小" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.auction_spacing_image_width,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "auction_spacing_image_width", $$v)
                      },
                      expression: "facade.auction_spacing_image_width",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "图片左右间距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.auction_spacing_image_margin,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.facade,
                          "auction_spacing_image_margin",
                          $$v
                        )
                      },
                      expression: "facade.auction_spacing_image_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容边距" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.background_padding_top,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.facade,
                                "background_padding_top",
                                $$v
                              )
                            },
                            expression: "facade.background_padding_top",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.background_padding_bottom,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.facade,
                                "background_padding_bottom",
                                $$v
                              )
                            },
                            expression: "facade.background_padding_bottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.background_padding_left,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.facade,
                                "background_padding_left",
                                $$v
                              )
                            },
                            expression: "facade.background_padding_left",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.background_padding_right,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.facade,
                                "background_padding_right",
                                $$v
                              )
                            },
                            expression: "facade.background_padding_right",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容上边距" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.content_toppadding,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "content_toppadding", $$v)
                      },
                      expression: "facade.content_toppadding",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容背景" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.content_background,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "content_background", $$v)
                      },
                      expression: "facade.content_background",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容圆角" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.border_radius,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "border_radius", $$v)
                      },
                      expression: "facade.border_radius",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容边框" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.border_width,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "border_width", $$v)
                      },
                      expression: "facade.border_width",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "边框颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.border_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "border_color", $$v)
                      },
                      expression: "facade.border_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "边框样式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.borderStyle,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "borderStyle", $$v)
                        },
                        expression: "facade.borderStyle",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "solid" } }, [
                        _vm._v("实线"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "dotted" } }, [
                        _vm._v("点状"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "dashed" } }, [
                        _vm._v("虚线"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "边框内边距" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "radius-list t" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.border_padding_top,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "border_padding_top", $$v)
                            },
                            expression: "facade.border_padding_top",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list b" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.border_padding_bottom,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "border_padding_bottom", $$v)
                            },
                            expression: "facade.border_padding_bottom",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list l" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.border_padding_left,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "border_padding_left", $$v)
                            },
                            expression: "facade.border_padding_left",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "radius-list r" },
                      [
                        _c("el-input-number", {
                          staticClass: "w50",
                          attrs: { controls: false },
                          model: {
                            value: _vm.facade.border_padding_right,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "border_padding_right", $$v)
                            },
                            expression: "facade.border_padding_right",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "边框阴影颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#FFFFFF" },
                    model: {
                      value: _vm.facade.border_shawdow_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "border_shawdow_color", $$v)
                      },
                      expression: "facade.border_shawdow_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "阴影x距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.border_shawdow_x,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "border_shawdow_x", $$v)
                      },
                      expression: "facade.border_shawdow_x",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "阴影y距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.border_shawdow_y,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "border_shawdow_y", $$v)
                      },
                      expression: "facade.border_shawdow_y",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider", {
                attrs: { direction: "horizontal", "content-position": "left" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "fWeight500 ft14 mt20" }, [
                _vm._v("图录号"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "上距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.lot_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "lot_margin", $$v)
                      },
                      expression: "facade.lot_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.lot_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "lot_color", $$v)
                      },
                      expression: "facade.lot_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.lot_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "lot_size", $$v)
                      },
                      expression: "facade.lot_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.lot_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "lot_weight", $$v)
                        },
                        expression: "facade.lot_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 600 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "对齐" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.lot_text_align,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "lot_text_align", $$v)
                        },
                        expression: "facade.lot_text_align",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "left" } }, [
                        _vm._v("常规"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "center" } }, [
                        _vm._v("居中"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "right" } }, [
                        _vm._v("居右"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "字体" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap" },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.facade.lot_fontFamily,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "lot_fontFamily", $$v)
                            },
                            expression: "facade.lot_fontFamily",
                          },
                        },
                        _vm._l(_vm.fontFamilyList, function (m, i) {
                          return _c(
                            "el-option",
                            {
                              key: i,
                              attrs: { value: m.value, label: m.name },
                            },
                            [
                              _c("div", { staticClass: "flex align-items-c" }, [
                                _c("div", { staticClass: "flex-one" }, [
                                  _vm._v(_vm._s(m.name)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ft12",
                                    style: "font-family: " + m.value + ";",
                                  },
                                  [_vm._v("例：100")]
                                ),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("el-divider", {
                attrs: { direction: "horizontal", "content-position": "left" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "fWeight500 ft14 mt20" }, [
                _vm._v("名称"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "上距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.title_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "title_margin", $$v)
                      },
                      expression: "facade.title_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.title_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "title_color", $$v)
                      },
                      expression: "facade.title_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.title_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "title_size", $$v)
                      },
                      expression: "facade.title_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.title_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "title_weight", $$v)
                        },
                        expression: "facade.title_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 600 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "对齐" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.title_text_align,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "title_text_align", $$v)
                        },
                        expression: "facade.title_text_align",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "left" } }, [
                        _vm._v("常规"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "center" } }, [
                        _vm._v("居中"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "right" } }, [
                        _vm._v("居右"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "字体" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap" },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.facade.title_fontFamily,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "title_fontFamily", $$v)
                            },
                            expression: "facade.title_fontFamily",
                          },
                        },
                        _vm._l(_vm.fontFamilyList, function (m, i) {
                          return _c(
                            "el-option",
                            {
                              key: i,
                              attrs: { value: m.value, label: m.name },
                            },
                            [
                              _c("div", { staticClass: "flex align-items-c" }, [
                                _c("div", { staticClass: "flex-one" }, [
                                  _vm._v(_vm._s(m.name)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ft12",
                                    style: "font-family: " + m.value + ";",
                                  },
                                  [_vm._v("例：100")]
                                ),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("el-divider", {
                attrs: { direction: "horizontal", "content-position": "left" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "fWeight500 ft14 mt20" }, [
                _vm._v("属性"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "上距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.attr_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "attr_margin", $$v)
                      },
                      expression: "facade.attr_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "属性分隔符" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.facade.attrSeparator,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "attrSeparator", $$v)
                      },
                      expression: "facade.attrSeparator",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.attr_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "attr_size", $$v)
                      },
                      expression: "facade.attr_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "行高" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.attr_line_height,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "attr_line_height", $$v)
                      },
                      expression: "facade.attr_line_height",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "属性名颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.attr_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "attr_color", $$v)
                      },
                      expression: "facade.attr_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "属性名风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.attr_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "attr_weight", $$v)
                        },
                        expression: "facade.attr_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 600 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "属性值颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.attr_value_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "attr_value_color", $$v)
                      },
                      expression: "facade.attr_value_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "属性值风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.attr_value_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "attr_value_weight", $$v)
                        },
                        expression: "facade.attr_value_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 600 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容对齐" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.attr_text_align,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "attr_text_align", $$v)
                        },
                        expression: "facade.attr_text_align",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "left" } }, [
                        _vm._v("常规"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "center" } }, [
                        _vm._v("居中"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "right" } }, [
                        _vm._v("居右"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "字体" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap" },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.facade.attr_fontFamily,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "attr_fontFamily", $$v)
                            },
                            expression: "facade.attr_fontFamily",
                          },
                        },
                        _vm._l(_vm.fontFamilyList, function (m, i) {
                          return _c(
                            "el-option",
                            {
                              key: i,
                              attrs: { value: m.value, label: m.name },
                            },
                            [
                              _c("div", { staticClass: "flex align-items-c" }, [
                                _c("div", { staticClass: "flex-one" }, [
                                  _vm._v(_vm._s(m.name)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ft12",
                                    style: "font-family: " + m.value + ";",
                                  },
                                  [_vm._v("例：100")]
                                ),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("el-divider", {
                attrs: { direction: "horizontal", "content-position": "left" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "fWeight500 ft14 mt20" }, [
                _vm._v("价格"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "上距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.price_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "price_margin", $$v)
                      },
                      expression: "facade.price_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.price_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "price_color", $$v)
                      },
                      expression: "facade.price_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.price_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "price_size", $$v)
                      },
                      expression: "facade.price_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "行高" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.price_line_height,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "price_line_height", $$v)
                      },
                      expression: "facade.price_line_height",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.price_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "price_weight", $$v)
                        },
                        expression: "facade.price_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 600 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容对齐" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.price_text_align,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "price_text_align", $$v)
                        },
                        expression: "facade.price_text_align",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "left" } }, [
                        _vm._v("常规"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "center" } }, [
                        _vm._v("居中"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "right" } }, [
                        _vm._v("居右"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "字体" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap" },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.facade.price_fontFamily,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "price_fontFamily", $$v)
                            },
                            expression: "facade.price_fontFamily",
                          },
                        },
                        _vm._l(_vm.fontFamilyList, function (m, i) {
                          return _c(
                            "el-option",
                            {
                              key: i,
                              attrs: { value: m.value, label: m.name },
                            },
                            [
                              _c("div", { staticClass: "flex align-items-c" }, [
                                _c("div", { staticClass: "flex-one" }, [
                                  _vm._v(_vm._s(m.name)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ft12",
                                    style: "font-family: " + m.value + ";",
                                  },
                                  [_vm._v("例：100")]
                                ),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("el-divider", {
                attrs: { direction: "horizontal", "content-position": "left" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "fWeight500 ft14 mt20" }, [
                _vm._v("详情"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "展示位置" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.show_area,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "show_area", $$v)
                        },
                        expression: "facade.show_area",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("集合展示"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("单独展示"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "上距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.content_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "content_margin", $$v)
                      },
                      expression: "facade.content_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.content_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "content_color", $$v)
                      },
                      expression: "facade.content_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.content_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "content_size", $$v)
                      },
                      expression: "facade.content_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "行高" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.content_line_height,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "content_line_height", $$v)
                      },
                      expression: "facade.content_line_height",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.content_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "content_weight", $$v)
                        },
                        expression: "facade.content_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 600 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "内容对齐" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.content_text_align,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "content_text_align", $$v)
                        },
                        expression: "facade.content_text_align",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "left" } }, [
                        _vm._v("常规"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "center" } }, [
                        _vm._v("居中"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "right" } }, [
                        _vm._v("居右"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "字体" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap" },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.facade.content_fontFamily,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "content_fontFamily", $$v)
                            },
                            expression: "facade.content_fontFamily",
                          },
                        },
                        _vm._l(_vm.fontFamilyList, function (m, i) {
                          return _c(
                            "el-option",
                            {
                              key: i,
                              attrs: { value: m.value, label: m.name },
                            },
                            [
                              _c("div", { staticClass: "flex align-items-c" }, [
                                _c("div", { staticClass: "flex-one" }, [
                                  _vm._v(_vm._s(m.name)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ft12",
                                    style: "font-family: " + m.value + ";",
                                  },
                                  [_vm._v("例：100")]
                                ),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("el-divider", {
                attrs: { direction: "horizontal", "content-position": "left" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "fWeight500 ft14 mt20" }, [
                _vm._v("其他"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "上距离" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.desc_margin,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "desc_margin", $$v)
                      },
                      expression: "facade.desc_margin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "颜色" } },
                [
                  _c("colour", {
                    attrs: { colour: "#000000" },
                    model: {
                      value: _vm.facade.desc_color,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "desc_color", $$v)
                      },
                      expression: "facade.desc_color",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "字号" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.desc_size,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "desc_size", $$v)
                      },
                      expression: "facade.desc_size",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "行高" } },
                [
                  _c("slider", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.facade.desc_line_height,
                      callback: function ($$v) {
                        _vm.$set(_vm.facade, "desc_line_height", $$v)
                      },
                      expression: "facade.desc_line_height",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "风格" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.desc_weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "desc_weight", $$v)
                        },
                        expression: "facade.desc_weight",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 400 } }, [
                        _vm._v("正常"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 600 } }, [
                        _vm._v("加粗"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 300 } }, [
                        _vm._v("加细"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "对齐" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.facade.desc_text_align,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "desc_text_align", $$v)
                        },
                        expression: "facade.desc_text_align",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "left" } }, [
                        _vm._v("常规"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "center" } }, [
                        _vm._v("居中"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "right" } }, [
                        _vm._v("居右"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "字体" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap" },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.facade.desc_fontFamily,
                            callback: function ($$v) {
                              _vm.$set(_vm.facade, "desc_fontFamily", $$v)
                            },
                            expression: "facade.desc_fontFamily",
                          },
                        },
                        _vm._l(_vm.fontFamilyList, function (m, i) {
                          return _c(
                            "el-option",
                            {
                              key: i,
                              attrs: { value: m.value, label: m.name },
                            },
                            [
                              _c("div", { staticClass: "flex align-items-c" }, [
                                _c("div", { staticClass: "flex-one" }, [
                                  _vm._v(_vm._s(m.name)),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ft12",
                                    style: "font-family: " + m.value + ";",
                                  },
                                  [_vm._v("例：100")]
                                ),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "temp" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.facade,
                "label-width": "90px",
                size: "mini",
                "label-position": "left",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { staticClass: "mt20", attrs: { label: "模版字段" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.facade.temp,
                        callback: function ($$v) {
                          _vm.$set(_vm.facade, "temp", $$v)
                        },
                        expression: "facade.temp",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "item_background" } },
                            [_vm._v("拍品背景颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "animate_color" } },
                            [_vm._v("动画提示颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "auction_spacing_color" } },
                            [_vm._v("间距线颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "content_background" } },
                            [_vm._v("文字区域背景颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "border_color" } },
                            [_vm._v("文字区域边框颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "border_shawdow_color" } },
                            [_vm._v("文字区域边框阴影颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("el-checkbox", { attrs: { label: "lot_color" } }, [
                            _vm._v("LOT颜色"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "title_color" } },
                            [_vm._v("拍品名称颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "attr_color" } },
                            [_vm._v("属性名颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "attr_value_color" } },
                            [_vm._v("属性值颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "price_color" } },
                            [_vm._v("价格颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "content_color" } },
                            [_vm._v("详情颜色")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "desc_color" } },
                            [_vm._v("其他颜色")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectView", {
        ref: "selectView",
        attrs: {
          "has-more-data": "1",
          allImage: "true",
          query: { venue_id: _vm.venue_id, album_id: _vm.album_id },
          "current-data": [],
        },
        on: { selectMessage: _vm.selectMessage },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editAuctionShow,
            top: "5vh",
            width: "1000px",
            title: "修改拍品信息",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editAuctionShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "mt10" }, [
            _c(
              "div",
              { staticStyle: { "font-size": "16px", "font-weight": "500" } },
              [_vm._v("设置拍品图片")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "change-img flex flex-wrap mt10" },
              _vm._l(_vm.editAuctionData.sliderImageInfo, function (m, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "img",
                    class: {
                      curr:
                        _vm.editAuctionData.slider_image.indexOf(m.url) !== -1,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setSliderImg(m.url)
                      },
                    },
                  },
                  [_c("img", { attrs: { src: m.url + "!m640", alt: "" } })]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt20 flex" }, [
            _c("div", { staticClass: "flex-one" }, [
              _c(
                "div",
                { staticStyle: { "font-size": "16px", "font-weight": "500" } },
                [_vm._v("设置拍品信息")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "mt10",
                  staticStyle: {
                    "max-height": "530px",
                    "overflow-y": "scroll",
                    "padding-right": "30px",
                  },
                },
                [
                  _vm.content.attrlist && _vm.content.attrlist.length
                    ? [
                        _vm._l(_vm.content.attrlist, function (it, index) {
                          return [
                            it.key == "attr" && it.selected
                              ? [
                                  _vm.editAuctionData.attr_info &&
                                  _vm.editAuctionData.attr_info.length
                                    ? _vm._l(
                                        _vm.editAuctionData.attr_info,
                                        function (attrVal, attrIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key:
                                                "attr" +
                                                attrIndex +
                                                "_val" +
                                                index,
                                              staticClass: "flex align-items-c",
                                              class: index > 0 ? "mt10" : "",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "w200" },
                                                [
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      "font-size": "12px",
                                                    },
                                                    attrs: {
                                                      rows: "4",
                                                      placeholder:
                                                        "请输入属性名",
                                                    },
                                                    model: {
                                                      value: attrVal.attr_name,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          attrVal,
                                                          "attr_name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "attrVal.attr_name",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "ml5 mr5" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.facade.attrSeparator
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "flex-one" },
                                                [
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      "font-size": "12px",
                                                    },
                                                    attrs: {
                                                      rows: "4",
                                                      placeholder:
                                                        "请输入属性值",
                                                    },
                                                    model: {
                                                      value: attrVal.attr_value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          attrVal,
                                                          "attr_value",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "attrVal.attr_value",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "ml10 cur",
                                                  on: { click: _vm.addAttr },
                                                },
                                                [_vm._v("+")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "ml10 cur",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeAttr(
                                                        attrIndex
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("-")]
                                              ),
                                            ]
                                          )
                                        }
                                      )
                                    : _vm._e(),
                                ]
                              : it.selected
                              ? _c(
                                  "div",
                                  {
                                    key: "attr" + index,
                                    class: index > 0 ? "mt10" : "",
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { "font-size": "12px" },
                                      attrs: {
                                        type:
                                          it.key == "content" ? "textarea" : "",
                                        rows: "4",
                                        placeholder: "请输入" + it.name,
                                      },
                                      model: {
                                        value: _vm.editAuctionData[it.key],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editAuctionData,
                                            it.key,
                                            $$v
                                          )
                                        },
                                        expression: "editAuctionData[it.key]",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-one ml20" }, [
              _vm.facade.ai_color == 2
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "font-weight": "500",
                      },
                    },
                    [_vm._v("设置样式内容")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.facade.ai_color == 2
                ? _c(
                    "div",
                    {
                      staticClass: "mt10",
                      staticStyle: {
                        "max-height": "530px",
                        "overflow-y": "scroll",
                        "padding-right": "30px",
                      },
                    },
                    [
                      _vm.editAuctionData && _vm.editAuctionData.styleData
                        ? _c(
                            "el-form",
                            {
                              ref: "form",
                              attrs: {
                                "label-width": "120px",
                                size: "mini",
                                "label-position": "left",
                              },
                              nativeOn: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "拍品背景颜色" } },
                                [
                                  _c("colour", {
                                    attrs: { colour: "#FFFFFF" },
                                    model: {
                                      value:
                                        _vm.editAuctionData.styleData
                                          .item_background,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editAuctionData.styleData,
                                          "item_background",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editAuctionData.styleData.item_background",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mt20",
                                  attrs: { label: "内容背景颜色" },
                                },
                                [
                                  _c("colour", {
                                    attrs: { colour: "#FFFFFF" },
                                    model: {
                                      value:
                                        _vm.editAuctionData.styleData
                                          .content_background,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editAuctionData.styleData,
                                          "content_background",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editAuctionData.styleData.content_background",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mt20",
                                  attrs: { label: "内容边框颜色" },
                                },
                                [
                                  _c("colour", {
                                    attrs: { colour: "#FFFFFF" },
                                    model: {
                                      value:
                                        _vm.editAuctionData.styleData
                                          .border_color,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editAuctionData.styleData,
                                          "border_color",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editAuctionData.styleData.border_color",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mt20",
                                  attrs: { label: "内容阴影颜色" },
                                },
                                [
                                  _c("colour", {
                                    attrs: { colour: "#FFFFFF" },
                                    model: {
                                      value:
                                        _vm.editAuctionData.styleData
                                          .border_shawdow_color,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editAuctionData.styleData,
                                          "border_shawdow_color",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editAuctionData.styleData.border_shawdow_color",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mt20",
                                  attrs: { label: "图录号颜色" },
                                },
                                [
                                  _c("colour", {
                                    attrs: { colour: "#000000" },
                                    model: {
                                      value:
                                        _vm.editAuctionData.styleData.lot_color,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editAuctionData.styleData,
                                          "lot_color",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editAuctionData.styleData.lot_color",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mt20",
                                  attrs: { label: "拍品名称颜色" },
                                },
                                [
                                  _c("colour", {
                                    attrs: { colour: "#000000" },
                                    model: {
                                      value:
                                        _vm.editAuctionData.styleData
                                          .title_color,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editAuctionData.styleData,
                                          "title_color",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editAuctionData.styleData.title_color",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mt20",
                                  attrs: { label: "属性名颜色" },
                                },
                                [
                                  _c("colour", {
                                    attrs: { colour: "#000000" },
                                    model: {
                                      value:
                                        _vm.editAuctionData.styleData
                                          .attr_color,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editAuctionData.styleData,
                                          "attr_color",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editAuctionData.styleData.attr_color",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mt20",
                                  attrs: { label: "属性值颜色" },
                                },
                                [
                                  _c("colour", {
                                    attrs: { colour: "#000000" },
                                    model: {
                                      value:
                                        _vm.editAuctionData.styleData
                                          .attr_value_color,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editAuctionData.styleData,
                                          "attr_value_color",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editAuctionData.styleData.attr_value_color",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mt20",
                                  attrs: { label: "价格颜色" },
                                },
                                [
                                  _c("colour", {
                                    attrs: { colour: "#000000" },
                                    model: {
                                      value:
                                        _vm.editAuctionData.styleData
                                          .price_color,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editAuctionData.styleData,
                                          "price_color",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editAuctionData.styleData.price_color",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mt20",
                                  attrs: { label: "详情颜色" },
                                },
                                [
                                  _c("colour", {
                                    attrs: { colour: "#000000" },
                                    model: {
                                      value:
                                        _vm.editAuctionData.styleData
                                          .content_color,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editAuctionData.styleData,
                                          "content_color",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editAuctionData.styleData.content_color",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mt20",
                                  attrs: { label: "其他文字颜色" },
                                },
                                [
                                  _c("colour", {
                                    attrs: { colour: "#000000" },
                                    model: {
                                      value:
                                        _vm.editAuctionData.styleData
                                          .desc_color,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editAuctionData.styleData,
                                          "desc_color",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editAuctionData.styleData.desc_color",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mt20 item-flex-center",
              staticStyle: {
                "padding-top": "20px",
                "border-top": "1px solid #eee",
              },
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editAuctionShow = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveEditAuction },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }