var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "mb20",
      attrs: {
        model: _vm.content,
        "label-width": "80px",
        size: "mini",
        "label-position": "left",
      },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c("div", {}, [
        _c("div", { staticClass: "flex-one" }, [
          _c("div", { staticClass: "fontW400 ft14 color-black" }, [
            _vm._v("请在“企业资料”设置完内容后添加组件"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item-align-center mt20" }, [
        _c("div", { staticClass: "w80 fontW400 ft14 color-black" }, [
          _vm._v("标题"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-one" },
          [
            _c("el-input", {
              attrs: { type: "text", placeholder: "设置标题" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return (function () {})($event)
                },
              },
              model: {
                value: _vm.content.title,
                callback: function ($$v) {
                  _vm.$set(_vm.content, "title", $$v)
                },
                expression: "content.title",
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }