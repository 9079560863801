var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "all-view" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "view" }, [
        _c(
          "div",
          {
            staticClass: "flex-one flex align-items-c",
            staticStyle: { overflow: "hidden" },
          },
          [
            _c(
              "div",
              { staticClass: "img" },
              [
                _vm.detail.image && _vm.detail.image[0]
                  ? [
                      _c("el-image", {
                        attrs: {
                          src:
                            _vm.detail.image[0] +
                            "?x-oss-process=image/auto-orient,1/resize,m_pad,w_100,h_100,color_f7f7f7/quality,q_90/format,webp",
                          "preview-src-list": _vm.detail.image,
                        },
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "info flex-one",
                staticStyle: { overflow: "hidden" },
              },
              [
                _c("div", { staticClass: "name line1" }, [
                  _vm._v(_vm._s(_vm.detail.product_name)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "no" }, [
                  _vm._v(_vm._s(_vm.detail.product_no)),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "btn item-flex-center ml20" }, [
          _c(
            "div",
            { staticClass: "ml10" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-back",
                    disabled: !_vm.hasPrev,
                  },
                  on: { click: _vm.goPrevAction },
                },
                [_vm._v("上一件")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml10" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: !_vm.hasNext && !_vm.hasPrev,
                  },
                  on: { click: _vm.goNextAction },
                },
                [
                  _vm._v("下一件"),
                  _c("i", { staticClass: "el-icon-right el-icon--right" }),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "r flex align-items-c flex-one ml20",
            staticStyle: { "justify-content": "flex-end" },
          },
          [
            _vm.count
              ? _c("div", [
                  _vm._v("剩余 "),
                  _c("span", { staticStyle: { "font-weight": "500" } }, [
                    _vm._v(_vm._s(_vm.count)),
                  ]),
                  _vm._v(" 件"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ml10" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "success" },
                    on: { click: _vm.confirmAuditAction },
                  },
                  [_vm._v("确认审核")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.detailLoading,
            expression: "detailLoading",
          },
        ],
        staticClass: "img-content",
      },
      [
        _c(
          "draggable",
          _vm._b(
            {
              staticClass: "content",
              attrs: { list: _vm.auctionImage, "set-data": _vm.setData },
            },
            "draggable",
            {
              forceFallback: true,
              fallbackClass: "draggable-has",
            },
            false
          ),
          _vm._l(_vm.auctionImage, function (m, i) {
            return _c("div", { key: "img" + i, staticClass: "item" }, [
              _c(
                "div",
                {
                  staticClass: "pic",
                  class: { active: _vm.changeImage.indexOf(m.url) !== -1 },
                  on: {
                    click: function ($event) {
                      return _vm.changeImageAction(m.url)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "tag" },
                    _vm._l(m.label, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: "label_" + i + "_" + index,
                          staticClass: "tag-item",
                        },
                        [_vm._v(_vm._s(item))]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.changeImage.indexOf(m.url) !== -1
                    ? _c("div", { staticClass: "ranking" }, [
                        _vm._v(_vm._s(_vm.changeImage.indexOf(m.url) + 1)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("img", {
                    attrs: {
                      src:
                        m.url +
                        "?x-oss-process=image/auto-orient,1/resize,m_pad,w_300,h_300,color_f7f7f7/quality,q_90/format,webp",
                      alt: "",
                    },
                  }),
                ]
              ),
            ])
          }),
          0
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "set-tag" }, [
      _c("div", { staticClass: "view flex align-items-c" }, [
        _c("div", { staticClass: "flex-one l" }, [
          _c("span", { staticClass: "mr10" }, [_vm._v("批量设置标签")]),
          _vm._v(" "),
          _c(
            "div",
            {
              class: { hui: _vm.changeImage.length <= 0 },
              on: {
                click: function ($event) {
                  return _vm.setLabelAction("封面")
                },
              },
            },
            [_vm._v("#封面")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: { hui: _vm.changeImage.length <= 0 },
              on: {
                click: function ($event) {
                  return _vm.setLabelAction("款识")
                },
              },
            },
            [_vm._v("#款识")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: { hui: _vm.changeImage.length <= 0 },
              on: {
                click: function ($event) {
                  return _vm.setLabelAction("细节")
                },
              },
            },
            [_vm._v("#细节")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "r" }, [
          _c(
            "div",
            {
              staticClass: "btn",
              class: { hui: _vm.changeImage.length <= 0 },
              on: { click: _vm.delAction },
            },
            [_vm._v("删除")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }