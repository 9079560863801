"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _basic = require("@/api/basic");
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    draggable: _vuedraggable.default
  },
  data: function data() {
    return {
      showVisible: false,
      detailLoading: false,
      count: 0,
      detail: {},
      auctionImage: [],
      changeImage: [],
      delImage: [],
      imgShowType: 1,
      hasNext: true,
      hasPrev: false
    };
  },
  created: function created() {
    this.initFun();
  },
  mounted: function mounted() {
    document.addEventListener('keydown', this.keydownAction);
  },
  beforeDestroy: function beforeDestroy() {
    document.removeEventListener('keydown', this.keydownAction);
  },
  methods: {
    keydownAction: function keydownAction(e) {
      if (this.showVisible && !this.detailLoading) {
        if (e.keyCode == 39 && (this.hasPrev || this.hasNext)) {
          this.goNextAction();
        } else if (e.keyCode == 37 && this.hasPrev) {
          this.goPrevAction();
        }
      }
    },
    setData: function setData() {},
    setLabelAction: function setLabelAction(val) {
      var _this = this;
      if (this.changeImage.length) {
        var urls = this.auctionImage.map(function (m) {
          return m.url;
        });
        this.changeImage.forEach(function (m) {
          var index = urls.indexOf(m);
          if (index !== -1) {
            var item = _this.auctionImage[index];
            var labelIndex = item.label.indexOf(val);
            if (labelIndex === -1) {
              item.label = item.label.concat(val);
            } else {
              item.label.splice(labelIndex, 1);
            }
          }
        });
        // this.changeImage = [];
      }
    },
    delAction: function delAction() {
      var _this2 = this;
      if (this.changeImage.length) {
        if (this.changeImage.length >= this.auctionImage.length) {
          this.$message.error('最少保留一张图片');
          return false;
        }
        this.$confirm("\u786E\u8BA4\u5220\u9664\u56FE\u7247?", '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          var urls = _this2.auctionImage.map(function (m) {
            return m.url;
          });
          _this2.delImage = _this2.delImage.concat(_this2.changeImage);
          _this2.changeImage.forEach(function (m, i) {
            var index = urls.indexOf(m);
            if (index !== -1) {
              _this2.auctionImage.splice(index, 1);
              urls = _this2.auctionImage.map(function (m) {
                return m.url;
              });
            }
          });
          _this2.changeImage = [];
        }).catch(function () {});
      }
    },
    changeImageAction: function changeImageAction(m) {
      var index = this.changeImage.indexOf(m);
      if (index !== -1) {
        this.changeImage.splice(index, 1);
      } else {
        this.changeImage.push(m);
      }
    },
    hideAction: function hideAction() {
      this.showVisible = false;
      window.close();
    },
    initFun: function initFun() {
      this.detailLoading = true;
      this.showVisible = true;
      this.getAuctionDetail();
    },
    getAuctionDetail: function getAuctionDetail(type) {
      var _this3 = this;
      var params = {};
      if (type) {
        params.type = type;
        if (this.detail) {
          params.current_id = this.detail.id;
        }
        if (type == 'next' && !this.hasNext && this.count) {
          params = {};
        }
      }
      (0, _basic.getAuditAuctionImageDetailApi)(params).then(function (res) {
        if (res.data.erpProductInfo) {
          _this3.count = res.data.count;
          if (res.data.erpProductInfo.warehouse_image && res.data.erpProductInfo.warehouse_image[0]) {
            res.data.erpProductInfo.image = res.data.erpProductInfo.warehouse_image;
          } else if (res.data.erpProductInfo.contract_image && res.data.erpProductInfo.contract_image[0]) {
            res.data.erpProductInfo.image = res.data.erpProductInfo.contract_image;
          } else if (res.data.erpProductInfo.auction_image && res.data.erpProductInfo.auction_image[0]) {
            res.data.erpProductInfo.image = res.data.erpProductInfo.auction_image;
          }
          _this3.auctionImage = res.data.erpProductInfo.auction_image.map(function (m) {
            return {
              url: m,
              label: []
            };
          });
          _this3.changeImage = [];
          _this3.delImage = [];
          _this3.detail = res.data.erpProductInfo;
          _this3.detailLoading = false;
          _this3.hasNext = !!res.data.nextErpProductId;
          _this3.hasPrev = !!res.data.prevErpProductId;
        } else {
          _this3.$message.error('没有需要审核的上拍图');
          setTimeout(function () {
            _this3.hideAction();
          }, 1000);
        }
      });
    },
    confirmAuditAction: function confirmAuditAction() {
      var _this4 = this;
      if (this.lock) return false;
      this.lock = true;
      (0, _basic.auditAuctionImageApi)({
        id: this.detail.id,
        auction_image: this.auctionImage,
        delete_image: this.delImage
      }).then(function (res) {
        _this4.$message.success('审核成功');
        _this4.goNextAction();
        _this4.lock = false;
      }).catch(function () {
        _this4.lock = false;
      });
      // this.$confirm(`确认审核通过?`, '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // })
      //   .then(() => {

      //   })
      //   .catch(() => {
      //     this.lock = false;
      //   });
    },
    reset: function reset() {
      this.detailLoading = true;
      this.detail.id = '';
      this.auctionImage = [];
      this.changeImage = [];
      this.getAuctionDetail();
    },
    goPrevAction: function goPrevAction() {
      if (this.detailLoading) return;
      this.detailLoading = true;
      this.getAuctionDetail('prev');
    },
    goNextAction: function goNextAction() {
      if (this.detailLoading) return;
      this.detailLoading = true;
      this.getAuctionDetail('next');
    },
    closeAction: function closeAction() {
      this.detail = {};
      this.auctionImage = [];
      this.changeImage = [];
      this.delImage = [];
      this.showVisible = false;
      window.close();
    },
    showImgAction: function showImgAction(key) {}
  }
};