"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.replace");
var _typeof2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
var _contract = require("@/api/contract");
var _system = require("@/api/system");
var _setting = require("@/api/setting");
var _settings = require("@/settings");
var _pathToRegexp = require("path-to-regexp");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _auth = require("@/utils/auth");
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    fileList: _fileList.default
  },
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        page: 1,
        limit: 20
      },
      all: 0,
      status: {
        hasDel: 0,
        hasGive: 0,
        hasUse: 0,
        waitGive: 0
      },
      checkedIds: [],
      showset: false,
      userid: "",
      adminarr: [],
      myHeaders: {
        "X-Token": (0, _auth.getToken)()
      }
    };
  },
  computed: {
    fileUrl: function fileUrl() {
      return _settingMer.default.https + "/erp/contract/no/import_excel";
    }
  },
  mounted: function mounted() {
    var _this = this;
    console.log(this.$store.getters.mer_id);
    this.getList();
    this.getadminList();
    (0, _contract.contractNoStatusGet)().then(function (res) {
      if (res.data && res.data.status) {
        _this.status = res.data.status;
      }
    });
  },
  methods: {
    // 下载
    downLoad: function downLoad() {
      this.$downloadFile({
        url: _settingMer.default.https + "/excel/templateDownload?type=import_contract_no&token=" + (0, _auth.getToken)(),
        name: 'template.xlsx'
      });
    },
    getadminList: function getadminList() {
      var _this2 = this;
      (0, _setting.adminListApi)().then(function (res) {
        _this2.adminarr = res.data.list;
      });
    },
    setone: function setone(row) {
      this.oneflag = true;
      this.showset = true;
      this.row = row;
    },
    setmore: function setmore() {
      if (!this.checkedIds.length) {
        return;
      }
      this.oneflag = false;
      this.showset = true;
    },
    cursorset: function cursorset() {
      var _this3 = this;
      if (!this.userid) {
        this.$message.error("请选择分配人");
        return;
      }
      var json = {
        manage_admin_id: this.userid
      };
      if (this.oneflag) {
        json.ids = [this.row.id];
      } else {
        json.ids = this.checkedIds;
      }
      (0, _contract.contractNoset)(json).then(function (res) {
        _this3.$message.success("分配成功");
        _this3.showset = false;
        _this3.getList();
      }).catch(function (err) {
        _this3.$message.error(err);
      });
    },
    // 分开选择
    changeOne: function changeOne(v, row) {
      if (v) {
        var index = this.checkedIds.indexOf(row.id);
        if (index === -1) this.checkedIds.push(row.id);
      } else {
        var _index = this.checkedIds.indexOf(row.id);
        if (_index > -1) this.checkedIds.splice(_index, 1);
      }
    },
    setconfig: function setconfig() {
      this.showconfig = true;
    },
    delcontract: function delcontract(row) {
      var _this4 = this;
      this.$confirm("确认删除此合同编号？", "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "不删除",
        type: "warning"
      }).then(function () {
        (0, _contract.contractNodelete)(row.id).then(function () {
          _this4.$message.success("删除成功");
          _this4.getList();
        });
      });
    },
    losecontract: function losecontract(row) {
      var _this5 = this;
      this.$confirm("确认作废此合同编号？", "提示", {
        confirmButtonText: "作废",
        cancelButtonText: "不作废",
        type: "warning"
      }).then(function () {
        (0, _contract.contractInvild)({
          ids: [row.id]
        }).then(function () {
          _this5.$message.success("作废成功");
          _this5.getList();
        });
      });
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.contract_expiry_time = e ? this.timeVal.join("-") : "";
      this.getList(1);
    },
    showdetail: function showdetail(row) {
      this.$router.push({
        path: _settings.roterPre + "/contract/contractdetail/" + row.template_id
      });
    },
    // 列表
    getList: function getList(num) {
      var _this6 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _contract.contractNoList)(this.tableFrom).then(function (res) {
        _this6.tableData.data = res.data.list;
        _this6.tableData.total = res.data.count;
        _this6.listLoading = false;
      }).catch(function (res) {
        // this.$message.error(res.message);
        _this6.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList("");
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList("");
    },
    handleClose: function handleClose() {
      this.createflag = false;
    },
    parseTime: function parseTime(time, cFormat) {
      if (arguments.length === 0) {
        return null;
      }
      var format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
      var date;
      if ((0, _typeof2.default)(time) === "object") {
        date = time;
      } else {
        if (typeof time === "string") {
          if (/^[0-9]+$/.test(time)) {
            // support "1548221490638"
            time = parseInt(time);
          } else {
            // support safari
            // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
            time = time.replace(new RegExp(/-/gm), "/");
          }
        }
        if (typeof time === "number" && time.toString().length === 10) {
          time = time * 1000;
        }
        date = new Date(time);
      }
      var formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
      };
      var time_str = format.replace(/{([ymdhisa])+}/g, function (result, key) {
        var value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === "a") {
          return ["日", "一", "二", "三", "四", "五", "六"][value];
        }
        return value.toString().padStart(2, "0");
      });
      return time_str;
    },
    // 上传成功
    handleSuccess: function handleSuccess(response) {
      if (response.status === 200) {
        this.$message.success('上传成功');
        this.getList();
      } else {
        this.$message.error(response.message);
      }
      this.$refs.uploadImg.clearFiles();
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this7 = this;
      (0, _system.exportexcel)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.tableFrom), {}, {
        export_type: 'contract_no'
      })).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this7.$createElement;
        _this7.$msgbox({
          title: "提示",
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this7.$msgbox.close();
                _this7.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: "我知道了"
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList("contract_no");
    }
  }
};