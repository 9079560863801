"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _system = require("@/api/system");
var _index = require("@/utils/index");
var _setting = require("@/api/setting");
var _webSocket = _interopRequireDefault(require("@/utils/webSocket"));
var _auction = require("@/api/auction");
require("animate.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formatPriceNumber: _index.formatPriceNumber,
      parseTime: _index.parseTime,
      id: this.$route.query.id || '',
      background_color: '#2e3b58',
      background_image: '',
      main_color: '#ffffff',
      main_width: 100,
      main_image: '',
      word_color: '#ffffff',
      gap: '3',
      transition: '0',
      showvideo: false,
      selectall: [],
      showmodel: '1',
      second: '3',
      origin: '1',
      sdata: {
        socketServe: _webSocket.default.Instance
      },
      albumData: {},
      auctionData: {},
      animateindex: 0,
      slideAnimateindex: 0,
      playvideo: false,
      csstext: '',
      endcss: '',
      duration: '',
      clientWidth: 0,
      clientHeight: 0,
      scrollimgcss: '',
      speed: 0,
      video: null,
      showRmb: false,
      showUsd: false,
      showEur: false,
      showHkd: false,
      main_continue: false,
      main_start_front: false,
      showmain_image: true,
      rmbRate: 1,
      usdRate: 0,
      eurRate: 0,
      hkdRate: 0,
      imgdisplat: [],
      imgdisjson: {},
      abbreviationList: [],
      trapeWidth: 0,
      isTrapeBol: false,
      isWindUp: false,
      isWindUser: false,
      trapeRate: 0,
      nowPrice: 0,
      userName: '',
      fangwidth: 0,
      fangheight: 0,
      album_font_size: '10',
      auction_font_size: '10',
      price_font_size: '10',
      image_size: '30',
      auction_bottom_margin: '10',
      album_bottom_margin: '10',
      price_bottom_margin: '10',
      videoModelType: 1,
      price_all_margin: '10',
      auction_attr_font_size: '10',
      auction_attr_margin: '10',
      upindex: 0,
      upcss: '',
      end: false,
      albumEndData: {},
      endname: '',
      endtime: 0,
      showvence: false,
      total_num: 0,
      deal_num: 0,
      deal_rate: 0,
      deal_price: 0,
      fee_type: '',
      activeId: '',
      activeUrl: '',
      nextId: '',
      nextUrl: '',
      end_show_price: false,
      isDetail: false,
      showtext: false,
      showattr: false,
      showslide: true,
      showprice: false,
      auction_color: '',
      auction_image: '',
      auction_width: 75,
      auction_lr_padding: 25,
      auction_top_padding: 25,
      image_border_color: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.clientWidth = document.getElementById('screen-center').clientWidth;
    this.clientHeight = document.getElementById('screen-center').clientHeight;
    var width = window.innerWidth;
    var height = window.innerHeight;
    var a = width * 0.4;
    var c = Math.sqrt(a * a + height * height);
    var beta = Math.round(Math.acos(a / c) * 180 / Math.PI);
    this.trapeWidth = c;
    this.trapeRate = 90 - beta - 1;
    // console.log(c,this.trapeRate);

    (0, _system.getConfigClassKeys)('setting_led_config').then(function (res) {
      if (res.data.led_three_data) {
        var leddata = JSON.parse(res.data.led_three_data);
        console.log(leddata);
        _this.main_continue = leddata.main_continue;
        _this.main_start_front = leddata.main_start_front;
        _this.background_color = leddata.background_color;
        _this.main_color = leddata.main_color;
        _this.main_width = leddata.main_width;
        _this.clientWidth = (_this.clientWidth - _this.clientWidth * leddata.main_width / 100) / 2;
        _this.word_color = leddata.word_color;
        _this.gap = leddata.ViceGap;
        _this.transition = leddata.ViceTransition;
        _this.second = leddata.ViceSecond;
        _this.showvideo = leddata.ViceShowvideo;
        _this.selectall = leddata.selectall;
        _this.showmodel = leddata.ViceShowmodel;
        _this.origin = leddata.ViceOrigin;
        _this.background_image = leddata.background_image || '';
        _this.main_image = leddata.main_image || '';
        _this.album_font_size = leddata.album_font_size;
        _this.auction_font_size = leddata.auction_font_size;
        _this.price_font_size = leddata.price_font_size;
        _this.image_size = leddata.image_size;
        _this.auction_bottom_margin = leddata.auction_bottom_margin;
        _this.album_bottom_margin = leddata.album_bottom_margin;
        _this.price_bottom_margin = leddata.price_bottom_margin;
        _this.slideNum = leddata.slideNum || 9;
        _this.videoModelType = leddata.videoModelType || '1';
        _this.end_show_price = leddata.end_show_price || false;
        _this.auction_color = leddata.auction_color;
        _this.auction_image = leddata.auction_image;
        _this.auction_width = leddata.auction_width;
        _this.auction_lr_padding = leddata.auction_lr_padding;
        _this.auction_top_padding = leddata.auction_top_padding;
        _this.price_all_margin = leddata.price_all_margin;
        _this.image_border_color = leddata.image_border_color || "#000000";
        _this.auction_attr_font_size = leddata.auction_attr_font_size;
        _this.auction_attr_margin = leddata.auction_attr_margin;
        // if (this.selectall.includes("0")) {
        //   this.showRmb = true;
        // }
        // if (this.selectall.includes("1")) {
        //   this.showUsd = true;
        // }
        // if (this.selectall.includes("2")) {
        //   this.showEur = true;
        // }
        // if (this.selectall.includes("3")) {
        //   this.showHkd = true;
        // }
        var csstext = '';
        var endcss = '';
        var transition = _this.transition;
        if (transition != 0) {
          _this.duration = 'transition-duration: 1s;';
          if (transition == 1) {
            csstext += 'opacity:0;';
            endcss += 'opacity:0;';
          } else if (transition == 2) {
            csstext += 'transform:translateX(100%);';
            endcss += 'transform:translateX(-100%);';
          } else if (transition == 3) {
            csstext += 'transform:translateY(100%);';
            endcss += 'transform:translateY(-100%);';
          }
        } else {
          csstext += 'z-index: -1;';
        }
        _this.csstext = csstext;
        _this.endcss = endcss;
        _this.speed = 100 / _this.second;
      }
    });
    (0, _setting.getExchangeRateConfigListApi)('0').then(function (res) {
      _this.abbreviationList = res.data.exchangeRateData;
    });
    if (!_webSocket.default.Instance.ws) {
      _webSocket.default.Instance.connect();
      this.sdata.socketServe = _webSocket.default.Instance;
      this.sdata.socketServe.registerCallBack('getMessage', function (data) {
        _this.sockedMes(data);
      });
      this.sdata.socketServe.registerCallBack('openSocket', function () {});
    } else if (this.client_id) {
      this.joinRoomAction();
    }
  },
  methods: {
    getdetail: function getdetail() {
      var _this2 = this;
      (0, _auction.auctionDetail)(this.auctionData.product_id).then(function (res) {
        _this2.$set(_this2.auctionData, 'attr_info', res.data.attr_info);
      });
    },
    sockedMes: function sockedMes(d) {
      var _this3 = this;
      console.log('socket', d.type);
      if (d.type === 'init') {
        // 初始化
        this.client_id = d.client_id;
        this.joinRoomAction();
      } else if (d.type === 'auction_start') {
        // 确认起拍价
        if (this.animateTimeout) {
          clearTimeout(this.animateTimeout);
        }
        this.scrollimgcss = '';
        this.upcss = '';
        if (this.origin == 2) {
          this.animateindex = 0;
          setTimeout(function () {
            _this3.animate();
          }, 1000);
        }
        if (d.data.auction_info && d.data.auction_info.product && d.data.auction_info.product.slider_image) {
          if (d.data.auction_info.product.slider_image.length == 1) {
            d.data.auction_info.product.slider_image = [d.data.auction_info.product.slider_image[0], d.data.auction_info.product.slider_image[0]];
          }
          if (d.data.auction_info.product.slider_image.length > this.slideNum) {
            d.data.auction_info.product.slider_image = d.data.auction_info.product.slider_image.slice(0, this.slideNum);
          }
        }
        this.isDetail = true;
        setTimeout(function () {
          _this3.showtext = true;
          setTimeout(function () {
            _this3.showprice = true;
            _this3.showattr = true;
          }, 300);
        }, 700);
        this.auctionData = d.data.auction_info;
        this.getdetail();
        // if (this.main_continue) {
        //   this.showmain_image = true;
        // } else {
        //   this.showmain_image = false;
        // }
      } else if (d.type === 'auction_bid') {
        // 出价
        if (this.auctionData && this.auctionData.product_id === d.data.id) {
          if (d.data.bid_record.is_confirm !== 0) {
            this.auctionData.now_price = d.data.now_price;
          }
        }
      } else if (d.type === 'auction_bid_proxy') {
        // 出价
        if (this.auctionData && this.auctionData.product_id === d.data.id) {
          this.auctionData.now_price = d.data.now_price;
        }
      } else if (d.type === 'auction_bid_del') {
        // 删除最高价
        if (this.auctionData && this.auctionData.product_id === d.data.id) {
          this.auctionData.now_price = d.data.now_price;
          // this.settingPrice(this.auctionData.now_price);
        }
      } else if (d.type === 'auction_end' || d.type === 'auction_revoke') {
        // 拍品结束
        if (d.type === 'auction_end' && d.data.auction_info && d.data.auction_info.userInfo) {
          this.nowPrice = d.data.auction_info.now_price;
          this.userName = d.data.auction_info.userInfo.nickname;
        }
        this.isDetail = false;
        this.showtext = false;
        this.showprice = false;
        this.showattr = false;
        this.showslide = false;
        this.isTrapeBol = true;
        // if (this.main_start_front) {
        //   this.showmain_image = true;
        // } else {
        //   this.showmain_image = false;
        // }
        setTimeout(function () {
          if (d.data.auction_info && d.data.auction_info.auction_status != 4 && d.type != 'auction_revoke') {
            _this3.isWindUp = true;
            setTimeout(function () {
              _this3.isWindUser = true;
            }, 100);
          }
          if (d.data.next_auction) {
            var val = JSON.parse(JSON.stringify(_this3.auctionData));
            _this3.lastEndAuctionInfo = {
              lot: val.lot,
              userInfo: null,
              now_price: ''
            };
            if (d.data.auction_info && d.data.type === 1) {
              _this3.lastEndAuctionInfo.now_price = val.now_price;
            }
            if (_this3.huiscrool) {
              clearTimeout(_this3.huiscrool);
            }
            if (_this3.animateTimeout) {
              clearTimeout(_this3.animateTimeout);
            }
            _this3.scrollimgcss = '';
            _this3.upcss = '';
            _this3.auctionData.product = null;
            if (d.data.next_auction && d.data.next_auction.product && d.data.next_auction.product.slider_image) {
              if (d.data.next_auction.product.slider_image.length == 1) {
                d.data.next_auction.product.slider_image = [d.data.next_auction.product.slider_image[0], d.data.next_auction.product.slider_image[0]];
              }
              if (d.data.next_auction.product.slider_image.length > _this3.slideNum) {
                d.data.next_auction.product.slider_image = d.data.next_auction.product.slider_image.slice(0, _this3.slideNum);
              }
            }
            _this3.auctionData = d.data.next_auction;
            _this3.showslide = true;
            setTimeout(function () {
              _this3.preploadnext(_this3.auctionData.product_id);
            }, 3000);
            _this3.getdetail();
            if (_this3.origin == 2) {
              _this3.animateindex = 0;
              setTimeout(function () {
                _this3.animate();
              }, 1000);
            }
          } else {
            _this3.getAuctionStatistical(_this3.id);
          }
        }, 1000);
        if (d.data.auction_info && d.data.auction_info.auction_status != 4 && d.type != 'auction_revoke') {
          setTimeout(function () {
            _this3.isWindUp = false;
            _this3.isWindUser = false;
            _this3.isTrapeBol = false;
          }, 4000);
        } else {
          setTimeout(function () {
            _this3.isWindUp = false;
            _this3.isWindUser = false;
            _this3.isTrapeBol = false;
          }, 1500);
        }
      } else if (d.type === 'auction_remake') {
        // 拍品重拍
        this.isDetail = false;
        this.showtext = false;
        this.showprice = false;
        this.showattr = false;
        if (this.huiscrool) {
          clearTimeout(this.huiscrool);
        }
        if (this.animateTimeout) {
          clearTimeout(this.animateTimeout);
        }
        this.scrollimgcss = '';
        this.upcss = '';
        if (d.data.auction_info && d.data.auction_info.product && d.data.auction_info.product.slider_image) {
          if (d.data.auction_info.product.slider_image.length == 1) {
            d.data.auction_info.product.slider_image = [d.data.auction_info.product.slider_image[0], d.data.auction_info.product.slider_image[0]];
          }
          if (d.data.auction_info.product.slider_image.length > this.slideNum) {
            d.data.auction_info.product.slider_image = d.data.auction_info.product.slider_image.slice(0, this.slideNum);
          }
        }
        this.auctionData = d.data.auction_info;
        this.getdetail();

        // this.showmain_image = true;
        // if (this.main_start_front) {
        //   this.showmain_image = true;
        // } else {
        //   this.showmain_image = false;
        // }
        if (this.origin == 2) {
          this.animateindex = 0;
          this.animate();
        } else if (this.origin == 1 && this.showmodel == 3) {
          this.animateload();
        }
      } else if (d.type === 'auction_bid_confirm') {
        // 更新价格
        this.auctionData.now_price = d.data.now_price;
      } else if (d.type === 'album_switch' && d.data && d.data.next_album_info) {
        // 切换专场
        window.location.href = '/merchant/ledThereCenter?id=' + d.data.next_album_info.album_id;
      }
    },
    preploadnext: function preploadnext(id) {
      var _this4 = this;
      (0, _auction.getnextAuction)({
        auction_id: id
      }).then(function (res) {
        if (res.data.nextAuction && res.data.nextAuction.product_id) {
          _this4.nextId = res.data.nextAuction.product_id;
          _this4.nextUrl = res.data.nextAuction.product.video_link;
          if (res.data.nextAuction.product.slider_image && res.data.nextAuction.product.slider_image.length) {
            res.data.nextAuction.product.slider_image.forEach(function (item) {
              _this4.preloadImage(item);
            });
          }
          // slider_image
        } else {
          _this4.nextId = 0;
          _this4.nextUrl = '';
        }
      });
    },
    joinRoomAction: function joinRoomAction() {
      var _this5 = this;
      (0, _auction.joinRoom)({
        album_id: this.id,
        client_id: this.client_id
      }).then(function (res) {
        if (res.data.album_info && res.data.album_info.feeTypeInfo && res.data.album_info.feeTypeInfo.type) {
          _this5.fee_type = res.data.album_info.feeTypeInfo.type;
        }
        _this5.albumData = res.data.album_info;
        if (res.data.now_auction && res.data.now_auction.product && res.data.now_auction.product.slider_image) {
          if (res.data.now_auction.product.slider_image.length == 1) {
            res.data.now_auction.product.slider_image = [res.data.now_auction.product.slider_image[0], res.data.now_auction.product.slider_image[0]];
          }
          // 如果数量大于slidenum
          if (res.data.now_auction.product.slider_image.length > _this5.slideNum) {
            res.data.now_auction.product.slider_image = res.data.now_auction.product.slider_image.slice(0, _this5.slideNum);
          }
        }
        // if (res.data.now_auction && res.data.now_auction.auction_status == 1) {
        //   if (this.main_continue) {
        //     this.showmain_image = true;
        //   } else {
        //     this.showmain_image = false;
        //   }
        // } else {
        //   if (this.main_start_front) {
        //     this.showmain_image = true;
        //   } else {
        //     this.showmain_image = false;
        //   }
        // }
        _this5.auctionData = res.data.now_auction;
        _this5.getdetail();
        setTimeout(function () {
          _this5.preploadnext(_this5.auctionData.product_id);
        }, 3000);
        setTimeout(function () {
          _this5.preploadnext(_this5.auctionData.product_id);
        }, 3000);
        if (res.data.now_auction && res.data.now_auction.auction_status == 1) {
          _this5.isDetail = true;
          setTimeout(function () {
            _this5.showtext = true;
            setTimeout(function () {
              _this5.showprice = true;
              _this5.showattr = true;
            }, 100);
          }, 800);
        }
        _this5.lastEndAuctionInfo = res.data.lastEndAuctionInfo;
        _this5.$nextTick(function () {
          setTimeout(function () {
            _this5.animateindex = 0;
            if (_this5.origin == 2) {
              _this5.animate();
            } else {
              // this.animateImg();
            }
          }, 1000);
        });
      }).catch(function (err) {
        console.log(err);
        if (err.message == '专场已结束') {
          _this5.getAuctionStatistical(_this5.id);
        }
      });
    },
    getAuctionStatistical: function getAuctionStatistical(id) {
      var _this6 = this;
      (0, _auction.auctionVStatistical)({
        album_id: id
      }).then(function (res) {
        if (res.data.venue_statistical_data && res.data.venue_statistical_data.end_time) {
          _this6.showvence = true;
          _this6.endname = res.data.album_data.venue.title;
          _this6.endtime = res.data.venue_statistical_data.end_time;
          _this6.total_num = res.data.venue_statistical_data.auction_num;
          _this6.deal_num = res.data.venue_statistical_data.deal_num;
          _this6.deal_rate = res.data.venue_statistical_data.deal_rate;
          _this6.deal_price = res.data.venue_statistical_data.deal_price;
        } else {
          _this6.showvence = false;
          _this6.endname = res.data.album_data.album_name;
          _this6.endtime = res.data.album_statistical_data.album_end_time;
          _this6.total_num = res.data.album_statistical_data.total_num;
          _this6.deal_num = res.data.album_statistical_data.deal_num;
          _this6.deal_rate = res.data.album_statistical_data.deal_rate;
          _this6.deal_price = res.data.album_statistical_data.deal_price;
        }
        _this6.end = true;
      });
    },
    moneyAdd: function moneyAdd(money, rate, decimal) {
      var decimal1 = decimal > 0 && decimal <= 20 ? decimal : 2;
      var money2 = money;
      if (this.albumData && this.albumData.feeTypeInfo && this.albumData.feeTypeInfo.type !== this.albumData.feeTypeInfo.mer_fee_type) {
        money2 = money2 / this.albumData.feeTypeInfo.rate;
      }
      money2 = money2 * rate;
      if (!decimal) {
        money2 = money2.toFixed(0);
      }
      // const money1 = `${parseFloat((`${money2}`).replace(/[^\d-]/g, '')).toFixed(decimal1)}`;
      var money1 = "".concat(money2);
      var d = money1.split('.')[0].split('').reverse();
      var y = money1.split('.')[1] ? ".".concat(money1.split('.')[1]) : '';
      var q = '';
      for (var i = 0; i < d.length; i += 1) {
        q += d[i] + ((i + 1) % 3 === 0 && i + 1 !== d.length ? ',' : '');
      }
      var w = '';
      if (decimal1 > 0 && y) {
        w = y.substr(0, decimal1 + 1);
      }
      return "".concat(q.split('').reverse().join('')).concat(w == '.00' ? '' : w);
    },
    onVideoLoadedMetadata: function onVideoLoadedMetadata() {
      var _this7 = this;
      this.video = document.getElementById('video');
      this.video1 = document.getElementById('video1');
      setTimeout(function () {
        if (_this7.video) {
          _this7.video.play();
          // 监听'ended'事件
          _this7.video.addEventListener('ended', function () {
            _this7.animateindex = 0;
            _this7.playvideo = false;
            _this7.animate();
          });
        }
        if (_this7.video1) {
          _this7.video1.play();
          // 监听'ended'事件
          // this.video1.addEventListener('ended', () => {
          //   this.animateindex = 0;
          //   this.playvideo = false;
          //   this.animate();
          // });
        }
      }, 600);
    },
    animate: function animate() {
      var _this8 = this;
      if (this.auctionData.product && this.auctionData.product.slider_image) {} else {
        this.animateindex = 0;
      }
      setTimeout(function () {
        _this8.animateImg(_this8.imgdisplat[_this8.animateindex]);
      }, 600);
      if (this.animateTimeout) {
        clearTimeout(this.animateTimeout);
      }
      this.scrollimgcss = '';
      this.animateTimeout = setTimeout(function () {
        _this8.upindex = _this8.animateindex;
        _this8.upcss = _this8.scrollimgcss;
        if (_this8.animateindex < _this8.auctionData.product.slider_image.length - 1) {
          _this8.animateindex++;
        } else {
          _this8.animateindex = 0;
        }
        _this8.animate(_this8.imgdisplat[_this8.animateindex]);
      }, this.gap * 1000);
    },
    animateload: function animateload(e) {
      var _this9 = this;
      this.animateindex = 0;
      var imgdom = document.getElementsByClassName('solidimg')[0];
      if (!imgdom) return;
      var radio = 1.5;
      var winraido = 1;
      if (this.clientHeight > this.clientWidth) {
        winraido = this.clientHeight / this.clientWidth;
      } else if (this.clientHeight < this.clientWidth) {
        winraido = this.clientWidth / this.clientHeight;
      }
      // 如果图片已加载，直接获取宽度和高度
      var width = imgdom.naturalWidth;
      var height = imgdom.naturalHeight;
      if (width < height && height / width >= radio * winraido) {
        this.$set(this.imgdisplat, 0, 'shu');
      } else if (width > height && width / height >= radio * winraido) {
        this.$set(this.imgdisplat, 0, 'heng');
      } else {
        if (width > height) {
          console.log('width', this.clientHeight / height * width);
          this.imgdisjson[0] = {
            width: this.clientHeight / height * width * 2.5,
            height: this.clientHeight * 2.5
          };
          this.$set(this.imgdisplat, 0, 'fangheng');
        } else if (width < height) {
          console.log('height', this.clientWidth / width * height);
          this.imgdisjson[0] = {
            width: this.clientWidth * 2.5,
            height: this.clientWidth / width * height * 2.5
          };
          this.$set(this.imgdisplat, 0, 'fangshu');
        } else {
          this.imgdisjson[0] = {
            width: this.clientWidth * 2.5,
            height: this.clientWidth * 2.5
          };
          this.$set(this.imgdisplat, 0, 'fang');
        }
      }
      console.log(width, height);
      if (this.showmodel == 3) {
        this.$nextTick(function () {
          setTimeout(function () {
            _this9.animateImg(_this9.imgdisplat[0]);
          }, 500);
        });
      }
    },
    animatesolid: function animatesolid(item, index) {
      var imgdom = document.getElementsByClassName('solidimg')[index];
      var radio = 1.5;
      var winraido = 1;
      if (this.clientHeight > this.clientWidth) {
        winraido = this.clientHeight / this.clientWidth;
      } else if (this.clientHeight < this.clientWidth) {
        winraido = this.clientWidth / this.clientHeight;
      }
      // 如果图片已加载，直接获取宽度和高度
      var width = imgdom.naturalWidth;
      var height = imgdom.naturalHeight;
      console.log(width, height);
      if (width < height && height / width >= radio * winraido) {
        this.$set(this.imgdisplat, index, 'shu');
      } else if (width > height && width / height >= radio * winraido) {
        this.$set(this.imgdisplat, index, 'heng');
      } else {
        if (width > height) {
          this.imgdisjson[index] = {
            width: this.clientHeight / height * width * 2,
            height: this.clientHeight * 2
          };
          this.$set(this.imgdisplat, index, 'fangheng');
        } else if (width < height) {
          this.imgdisjson[index] = {
            width: this.clientWidth * 2,
            height: this.clientWidth / width * height * 2
          };
          this.$set(this.imgdisplat, index, 'fangshu');
        } else {
          this.$set(this.imgdisjson, index, {
            width: this.clientWidth * 2,
            height: this.clientWidth * 2
          });
          this.$set(this.imgdisplat, index, 'fang');
        }
      }
    },
    animateImg: function animateImg(type) {
      var _this10 = this;
      this.slideAnimateindex = this.animateindex;
      var imagebox = document.getElementsByClassName('solidimg');
      if (!imagebox || !imagebox.length || !imagebox[this.slideAnimateindex]) {
        return;
      }
      // console.log('1----',imagebox,this.animateindex);
      console.log(type);
      var clientWidth = imagebox[this.slideAnimateindex].clientWidth;
      var clientHeight = imagebox[this.slideAnimateindex].clientHeight;
      console.log(clientWidth, clientHeight);
      console.log(this.clientWidth, this.clientHeight);
      var css = '';
      var time = 0;
      if (clientHeight > this.clientHeight && type == 'shu') {
        time = (clientHeight - this.clientHeight) / this.speed;
        css += 'transition-duration: ' + time + 's;';
        css += 'transform:translateY(' + (-clientHeight + this.clientHeight) + 'px);';
      } else if (clientWidth > this.clientWidth && type == 'heng') {
        time = (clientWidth - this.clientWidth) / this.speed;
        css += 'transition-duration: ' + time + 's;';
        css += 'transform:translateX(' + (-clientWidth + this.clientWidth) + 'px);';
        // this.scrollimgcss = css;
      } else if (type == 'fang' || type == 'fangheng' || type == 'fangshu') {
        time = 10;
        css += 'transition-duration: ' + time + 's;';
        css += 'transform:translate(' + -clientWidth * 0.10 + 'px, ' + -clientHeight * 0.10 + 'px);';
      }
      if ((type == 'fang' || type == 'fangheng' || type == 'fangshu') && this.origin == 2) {
        css = '';
        time = 10;
        css += 'transition-duration: ' + time + 's;';
        css += 'transform:translate(' + (-clientWidth * 0.90 + this.clientWidth) + 'px, ' + (-clientHeight * 0.90 + this.clientHeight) + 'px);';
      }
      if (this.origin == 1) {
        if (this.animateO && this.slideAnimateindex == 0) {
          this.animateO = false;
          if (type == 'fang' || type == 'fangheng' || type == 'fangshu') {
            this.scrollimgcss = 'transition-duration: ' + time + 's;' + 'transform:translate(' + (-clientWidth * 0.90 + this.clientWidth) + 'px, ' + (-clientHeight * 0.90 + this.clientHeight) + 'px);';
          } else {
            this.scrollimgcss = 'transition-duration: ' + time + 's;';
          }
        } else {
          this.animateO = true;
          this.scrollimgcss = css;
        }
        if (this.huiscrool) {
          clearTimeout(this.huiscrool);
        }
        this.huiscrool = setTimeout(function () {
          _this10.animateImg(type);
        }, time * 1000 + 2000);
      } else {
        this.scrollimgcss = css;
        if (this.animateTimeout) {
          clearTimeout(this.animateTimeout);
        }
        this.animateTimeout = setTimeout(function () {
          _this10.upindex = _this10.animateindex;
          _this10.upcss = _this10.scrollimgcss;
          if (_this10.animateindex < _this10.auctionData.product.slider_image.length - 1) {
            _this10.animateindex++;
          } else {
            _this10.animateindex = 0;
          }
          _this10.animate(type);
        }, time * 1000 + this.gap * 1000);
      }
    },
    preloadImage: function preloadImage(url) {
      var img = new Image();
      img.src = url + '?x-oss-process=image/auto-orient,1/resize,m_lfit,w_1080/quality,q_100/format,webp';
      if (img.complete) {
        // 如果图片已经存在于浏览器缓存中，可以直接使用
        console.log('Image is cached:', img.src);
      } else {
        // 图片还没有加载完成，可以使用 onload 或者 onerror 监听器来确定图片何时加载完成
        img.onload = function () {
          console.log('Image loaded:', img.src);
        };
        img.onerror = function () {
          console.log('Image load error:', img.src);
        };
      }
    }
  }
};