var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { height: "99vh" } }, [
    _c("iframe", {
      attrs: {
        id: "poster-iframe",
        src: _vm.iframeUrl,
        width: "100%",
        height: "100%",
        frameborder: "0",
        allowfullscreen: "",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }