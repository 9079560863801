var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            width: "960px",
            visible: _vm.visible,
            "show-close": false,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "poster-dialog" }, [
              _vm._v("选择数据\n        "),
              _c("img", {
                staticClass: "posterImg",
                attrs: {
                  src: require("@/assets/images/poster/close.png"),
                  alt: "",
                },
                on: { click: _vm.onClose },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "poster-content" },
            [
              !_vm.formValidate.same
                ? [
                    _c("div", { staticClass: "poster-formtit" }, [
                      _vm._v("内容类型"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "poster-type-container" }, [
                      _c(
                        "div",
                        {
                          staticClass: "poster-type-item",
                          class:
                            _vm.formValidate.make_type == "1"
                              ? "type-active"
                              : "",
                          on: {
                            click: function ($event) {
                              return _vm.onChangeType("make_type", "1")
                            },
                          },
                        },
                        [_vm._v("拍卖会")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "poster-type-item",
                          class:
                            _vm.formValidate.make_type == "2"
                              ? "type-active"
                              : "",
                          on: {
                            click: function ($event) {
                              return _vm.onChangeType("make_type", "2")
                            },
                          },
                        },
                        [_vm._v("专场")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "poster-type-item",
                          class:
                            _vm.formValidate.make_type == "3"
                              ? "type-active"
                              : "",
                          on: {
                            click: function ($event) {
                              return _vm.onChangeType("make_type", "3")
                            },
                          },
                        },
                        [_vm._v("拍品")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "poster-formtit" }, [
                      _vm._v("内容定义"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "poster-type-container" }, [
                      _c(
                        "div",
                        {
                          staticClass: "poster-type-item",
                          class:
                            _vm.formValidate.make_tag == "1"
                              ? "type-active"
                              : "",
                          on: {
                            click: function ($event) {
                              return _vm.onChangeType("make_tag", "1")
                            },
                          },
                        },
                        [_vm._v("拍卖导览")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "poster-type-item",
                          class:
                            _vm.formValidate.make_tag == "2"
                              ? "type-active"
                              : "",
                          on: {
                            click: function ($event) {
                              return _vm.onChangeType("make_tag", "2")
                            },
                          },
                        },
                        [_vm._v("结拍快讯")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "poster-formtit" }, [
                      _vm._v("画面比例"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "poster-type-container" },
                      _vm._l(_vm.ratioList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "poster-type-item",
                            class:
                              _vm.formValidate.ratio == item.id
                                ? "type-active"
                                : "",
                            on: {
                              click: function ($event) {
                                return _vm.onChangeQuality(item.id, "ratio")
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                      0
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "poster-formtit" }, [
                _vm._v("选择数据"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "poster-flex" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { flex: "1" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.formValidate.source,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "source", $$v)
                        },
                        expression: "formValidate.source",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "云总部数据", value: "1" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { flex: "1", "margin-left": "16px" },
                      attrs: {
                        placeholder: "请选择拍卖会",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.venueInit,
                      },
                      on: { change: _vm.onVenueChange },
                      model: {
                        value: _vm.formValidate.venue_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "venue_id", $$v)
                        },
                        expression: "formValidate.venue_id",
                      },
                    },
                    _vm._l(_vm.venueList, function (item) {
                      return _c("el-option", {
                        key: item.venue_id,
                        attrs: { label: item.title, value: item.venue_id },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _vm.formValidate.make_type == "2"
                    ? _c(
                        "el-select",
                        {
                          staticStyle: { flex: "1", "margin-left": "16px" },
                          attrs: {
                            placeholder: "请选择专场",
                            filterable: "",
                            remote: "",
                            "remote-method": _vm.albumInit,
                          },
                          on: { change: _vm.onAlbumChange },
                          model: {
                            value: _vm.formValidate.album_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "album_id", $$v)
                            },
                            expression: "formValidate.album_id",
                          },
                        },
                        _vm._l(_vm.albumList, function (item) {
                          return _c("el-option", {
                            key: item.album_id,
                            attrs: {
                              label: item.album_name,
                              value: item.album_id,
                            },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: { flex: "1", "margin-left": "16px" },
                      on: { click: _vm.handleAdditem },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { readonly: "", placeholder: "请选择拍品" },
                        model: {
                          value: _vm.formValidate.productName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "productName", $$v)
                          },
                          expression: "formValidate.productName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "poster-foot" }, [
            _c(
              "div",
              { staticClass: "poster-btn", on: { click: _vm.onNext } },
              [_vm._v("确认")]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("selectView", {
        ref: "selectView",
        attrs: {
          "has-more-data": "1",
          "all-image": "true",
          "current-data": [],
        },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }