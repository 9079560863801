var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "screen-box", style: { color: _vm.word_color } },
    [
      _vm.end_show_price
        ? _c("div", { staticClass: "DiagonalBox" }, [
            _vm.isWindUp
              ? _c("div", { staticClass: "windUp" }, [
                  _vm.isWindUser
                    ? _c(
                        "div",
                        {
                          staticClass: "animate__bounceInDown",
                          staticStyle: {
                            "animation-duration": "1s",
                            "font-size": "6vw",
                            "font-weight": "bold",
                            "margin-top": "-7vh",
                          },
                        },
                        [_vm._v(_vm._s(_vm.userName))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "animate__bounceInDown",
                      staticStyle: {
                        "animation-duration": "1s",
                        "font-size": "12vw",
                      },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "6vw" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.albumData.feeTypeInfo.type == "CNY"
                              ? "RMB"
                              : _vm.albumData.feeTypeInfo.type
                          )
                        ),
                      ]),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.formatPriceNumber(_vm.nowPrice || 0, 2)) +
                          "\n      "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "leftTrapeBox",
                class: _vm.isTrapeBol ? "isTrapeLeft" : "isTrapeNoLeft",
              },
              [
                _c("div", {
                  style:
                    "height: " +
                    _vm.trapeWidth +
                    "px;transform: rotate(" +
                    _vm.trapeRate +
                    "deg);",
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "rightTrapeBox",
                class: _vm.isTrapeBol ? "isTrapeRight" : "isTrapeNoRight",
              },
              [
                _c("div", {
                  style:
                    "height: " +
                    _vm.trapeWidth +
                    "px;transform: rotate(" +
                    _vm.trapeRate +
                    "deg);",
                }),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "flex", staticStyle: { width: "100vw" } }, [
        _c(
          "div",
          {
            staticClass: "flex-one l",
            staticStyle: { overflow: "hidden", position: "relative" },
          },
          [
            _c(
              "div",
              {
                staticClass: "screen-slide-box",
                style: _vm.showslide ? "opacity:1;" : "opacity:0;",
              },
              [
                _vm.origin == 1
                  ? _c(
                      "div",
                      { staticClass: "screen-center-slide" },
                      [
                        _vm.nextUrl
                          ? _c("video", {
                              staticStyle: {
                                width: "0px",
                                height: "0px",
                                opacity: "0",
                              },
                              style:
                                "" +
                                (_vm.videoModelType == 1
                                  ? "object-fit: contain;"
                                  : "object-fit: cover"),
                              attrs: {
                                id: "videonew",
                                muted: "",
                                src: _vm.nextUrl,
                                preload: "auto",
                              },
                              domProps: { muted: true },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.auctionData.product &&
                        _vm.auctionData.product.video_link &&
                        _vm.showvideo
                          ? _c("div", { staticClass: "video-all-view" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "video-view",
                                  class: _vm.isDetail ? "gol" : "",
                                },
                                [
                                  _c("video", {
                                    staticStyle: {
                                      "object-fit": "contain",
                                      height: "100%",
                                      width: "auto",
                                    },
                                    attrs: {
                                      id: "video",
                                      muted: "",
                                      src: _vm.auctionData.product.video_link,
                                      loop: "loop",
                                    },
                                    domProps: { muted: true },
                                    on: {
                                      loadedmetadata: _vm.onVideoLoadedMetadata,
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.auctionData.product
                                ? _c("img", {
                                    staticClass: "video-img-bg",
                                    attrs: {
                                      src:
                                        _vm.auctionData.product.image +
                                        "?x-oss-process=image/auto-orient,1/resize,m_lfit,w_1080/quality,q_100/format,webp",
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                            ])
                          : _vm.showmodel == 3
                          ? [
                              _c("div", { staticClass: "img-box 1" }, [
                                _vm.auctionData.product
                                  ? _c("img", {
                                      staticClass: "solidimg",
                                      style:
                                        (_vm.imgdisplat[0] == "shu" ||
                                        _vm.imgdisplat[0] == "fangshu"
                                          ? "height: auto;"
                                          : "") +
                                        (_vm.imgdisplat[0] == "heng" ||
                                        _vm.imgdisplat[0] == "fangheng"
                                          ? "width: auto;"
                                          : "") +
                                        (_vm.imgdisplat[0] == "fang" ||
                                        _vm.imgdisplat[0] == "fangheng" ||
                                        _vm.imgdisplat[0] == "fangshu"
                                          ? "width:" +
                                            _vm.imgdisjson[0].width +
                                            "px;height:" +
                                            _vm.imgdisjson[0].height +
                                            "px;" +
                                            "transform: translate(-" +
                                            (_vm.imgdisjson[0].width -
                                              _vm.clientWidth) /
                                              2 +
                                            "px, -" +
                                            (_vm.imgdisjson[0].height -
                                              _vm.clientHeight) /
                                              2 +
                                            "px);"
                                          : "") +
                                        _vm.scrollimgcss,
                                      attrs: {
                                        src:
                                          _vm.auctionData.product.image +
                                          "?x-oss-process=image/auto-orient,1/resize,m_lfit,w_1080/quality,q_100/format,webp",
                                        alt: "",
                                      },
                                      on: { load: _vm.animateload },
                                    })
                                  : _vm._e(),
                              ]),
                            ]
                          : _c("div", { staticClass: "video-all-view" }, [
                              _vm.auctionData.product
                                ? _c("img", {
                                    staticClass: "show-one-img-l",
                                    class: _vm.isDetail ? "gol" : "",
                                    style:
                                      "" +
                                      (_vm.showmodel == 1
                                        ? "object-fit: contain;"
                                        : "object-fit: cover;width:100%;"),
                                    attrs: {
                                      src:
                                        _vm.auctionData.product.image +
                                        "?x-oss-process=image/auto-orient,1/resize,m_lfit,w_1080/quality,q_100/format,webp",
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                            ]),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.origin == 2 && _vm.auctionData.product
                  ? [
                      _vm.nextUrl
                        ? _c("video", {
                            staticStyle: {
                              width: "0px",
                              height: "0px",
                              opacity: "0",
                            },
                            style:
                              "" +
                              (_vm.videoModelType == 1
                                ? "object-fit: contain;"
                                : "object-fit: cover"),
                            attrs: {
                              id: "videonew",
                              muted: "",
                              src: _vm.nextUrl,
                              preload: "auto",
                            },
                            domProps: { muted: true },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.auctionData.product &&
                      _vm.auctionData.product.video_link &&
                      _vm.showvideo
                        ? _c("div", { staticClass: "video-all-view" }, [
                            _c(
                              "div",
                              {
                                staticClass: "video-view",
                                class: _vm.isDetail ? "gol" : "",
                              },
                              [
                                _c("video", {
                                  staticStyle: {
                                    "object-fit": "contain",
                                    height: "100%",
                                    width: "auto",
                                  },
                                  attrs: {
                                    id: "video",
                                    muted: "",
                                    src: _vm.auctionData.product.video_link,
                                    loop: "loop",
                                  },
                                  domProps: { muted: true },
                                  on: {
                                    loadedmetadata: _vm.onVideoLoadedMetadata,
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.auctionData.product
                              ? _c("img", {
                                  staticClass: "video-img-bg",
                                  attrs: {
                                    src:
                                      _vm.auctionData.product.image +
                                      "?x-oss-process=image/auto-orient,1/resize,m_lfit,w_1080/quality,q_100/format,webp",
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                          ])
                        : _vm._l(
                            _vm.auctionData.product.slider_image,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "screen-center-slide",
                                  style:
                                    "z-index:" +
                                    (index + 1) +
                                    ";" +
                                    _vm.duration +
                                    (_vm.animateindex < index
                                      ? _vm.csstext
                                      : ""),
                                },
                                [
                                  _vm.showmodel == 3
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "img-box 2" },
                                          [
                                            _c("img", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    !_vm.playvideo &&
                                                    _vm.imgdisplat[index],
                                                  expression:
                                                    "!playvideo && imgdisplat[index]",
                                                },
                                              ],
                                              staticClass: "solidimg",
                                              style:
                                                (_vm.imgdisplat[index] == "shu"
                                                  ? "height: auto;"
                                                  : "") +
                                                (_vm.imgdisplat[index] == "heng"
                                                  ? "width: auto;"
                                                  : "") +
                                                (_vm.imgdisplat[index] ==
                                                  "fang" ||
                                                _vm.imgdisplat[index] ==
                                                  "fangheng" ||
                                                _vm.imgdisplat[index] ==
                                                  "fangshu"
                                                  ? "width:" +
                                                    _vm.imgdisjson[index]
                                                      .width +
                                                    "px;height:" +
                                                    _vm.imgdisjson[index]
                                                      .height +
                                                    "px;" +
                                                    "transform: translate(-" +
                                                    _vm.imgdisjson[index]
                                                      .width *
                                                      0.1 +
                                                    "px, -" +
                                                    _vm.imgdisjson[index]
                                                      .height *
                                                      0.1 +
                                                    "px);"
                                                  : "") +
                                                (_vm.slideAnimateindex == index
                                                  ? _vm.scrollimgcss
                                                  : "") +
                                                (_vm.upindex == index
                                                  ? _vm.upcss
                                                  : ""),
                                              attrs: {
                                                src:
                                                  item +
                                                  "?x-oss-process=image/auto-orient,1/resize,m_lfit,w_1080/quality,q_100/format,webp",
                                                alt: "",
                                              },
                                              on: {
                                                load: function ($event) {
                                                  return _vm.animatesolid(
                                                    item,
                                                    index
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    : _c(
                                        "div",
                                        { staticClass: "video-all-view" },
                                        [
                                          _c("img", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.animateindex == index,
                                                expression:
                                                  "animateindex == index",
                                              },
                                            ],
                                            staticClass: "show-one-img-l",
                                            class: _vm.isDetail ? "gol" : "",
                                            style:
                                              "" +
                                              (_vm.showmodel == 1
                                                ? "object-fit: contain;"
                                                : "object-fit: cover;width:100%;"),
                                            attrs: {
                                              src:
                                                item +
                                                "?x-oss-process=image/auto-orient,1/resize,m_lfit,w_1080/quality,q_100/format,webp",
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                ],
                                2
                              )
                            }
                          ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "productInfoBox",
                style: {
                  width: _vm.auction_width + "%",
                  "background-color": _vm.auction_color,
                  padding:
                    _vm.auction_top_padding +
                    "% " +
                    _vm.auction_lr_padding +
                    "% 0",
                  "background-image": "url(" + _vm.auction_image + ")",
                  transform: _vm.isDetail
                    ? "translateX(0%)"
                    : "translateX(100%)",
                },
              },
              [
                _vm.showtext
                  ? _c(
                      "div",
                      {
                        staticClass: "animate__fadeInDownBig",
                        staticStyle: { "animation-duration": "3s" },
                        style:
                          "font-size:" +
                          _vm.album_font_size +
                          "vh;margin-bottom: " +
                          _vm.album_bottom_margin +
                          "vh;",
                      },
                      [_vm._v(_vm._s(_vm.albumData.album_name))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showtext
                  ? _c(
                      "div",
                      {
                        staticClass: "animate__fadeInRightBig",
                        staticStyle: { "animation-duration": "3s" },
                        style:
                          "font-size:" +
                          _vm.auction_font_size +
                          "vh;margin-bottom: " +
                          _vm.auction_bottom_margin +
                          "vh;",
                      },
                      [
                        _vm._v(
                          "\n          LOT " +
                            _vm._s(_vm.auctionData.lot) +
                            "\n          "
                        ),
                        _vm.auctionData.product &&
                        _vm.auctionData.product.store_name
                          ? [
                              _vm.auctionData.product.store_name.length > 30
                                ? [
                                    _vm._v(
                                      _vm._s(
                                        _vm.auctionData.product.store_name.slice(
                                          0,
                                          30
                                        )
                                      ) + "..."
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      _vm._s(_vm.auctionData.product.store_name)
                                    ),
                                  ],
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { style: "height:" + _vm.price_all_margin + "vh" }),
                _vm._v(" "),
                _vm.albumData &&
                _vm.albumData.feeTypeInfo &&
                _vm.albumData.feeTypeInfo.type
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "screen-goods-list",
                          style:
                            "transition-duration: 2s;font-size:" +
                            _vm.price_font_size +
                            "vh;margin-bottom: " +
                            _vm.price_bottom_margin +
                            "vh;transform:" +
                            (_vm.showprice
                              ? "translateX(0%)"
                              : "translateX(100vw)"),
                        },
                        [
                          _c("div", { staticClass: "screen-goods-price" }, [
                            _vm._v(
                              _vm._s(
                                _vm.albumData.feeTypeInfo.type == "CNY"
                                  ? "RMB"
                                  : _vm.albumData.feeTypeInfo.type
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "screen-goods-num" }, [
                            _vm._v(
                              _vm._s(
                                _vm.formatPriceNumber(
                                  _vm.auctionData.now_price || 0,
                                  2
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.albumData.feeTypeInfo.type !=
                      _vm.albumData.feeTypeInfo.mer_fee_type
                        ? _c(
                            "div",
                            {
                              staticClass: "screen-goods-list",
                              style:
                                "transition-duration: 2.2s;font-size:" +
                                _vm.price_font_size +
                                "vh;margin-bottom: " +
                                _vm.price_bottom_margin +
                                "vh;transform:" +
                                (_vm.showprice
                                  ? "translateX(0%)"
                                  : "translateX(100vw)"),
                            },
                            [
                              _c("div", { staticClass: "screen-goods-price" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.albumData.feeTypeInfo.mer_fee_type ==
                                      "CNY"
                                      ? "RMB"
                                      : _vm.albumData.feeTypeInfo.mer_fee_type
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "screen-goods-num" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatPriceNumber(
                                      (
                                        (_vm.auctionData.now_price || 0) /
                                        _vm.albumData.feeTypeInfo.rate
                                      ).toFixed(2),
                                      2
                                    )
                                  )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.abbreviationList, function (m, i) {
                  return [
                    _vm.albumData &&
                    _vm.albumData.feeTypeInfo &&
                    m.type != _vm.albumData.feeTypeInfo.type
                      ? _c(
                          "div",
                          {
                            key: "left" + i,
                            staticClass: "screen-goods-list",
                            style:
                              "font-size:" +
                              _vm.price_font_size +
                              "vh;margin-bottom: " +
                              _vm.price_bottom_margin +
                              "vh;transform:" +
                              (_vm.showprice
                                ? "translateX(0%)"
                                : "translateX(100vw)") +
                              ";transition-duration:" +
                              (2 +
                                (i +
                                  (_vm.albumData.feeTypeInfo &&
                                  _vm.albumData.feeTypeInfo.type !=
                                    _vm.albumData.feeTypeInfo.mer_fee_type
                                    ? 2
                                    : 1)) *
                                  0.2) +
                              "s;",
                          },
                          [
                            _c("div", { staticClass: "screen-goods-price" }, [
                              _vm._v(_vm._s(m.type)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "screen-goods-num" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.moneyAdd(
                                    _vm.auctionData.now_price || 0,
                                    m.set_rate || m.rate,
                                    2
                                  )
                                )
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "screen-center",
            style: "width: " + _vm.main_width + "vw;",
            attrs: { id: "screen-center" },
          },
          [
            _c(
              "div",
              {
                staticClass: "screen-center-box ",
                style: {
                  width: "100%",
                  "background-color": _vm.main_color,
                  opacity: _vm.showmain_image ? 1 : 0,
                },
              },
              [
                _c("img", {
                  staticStyle: {
                    width: "100%",
                    height: "100%",
                    "object-fit": "cover",
                  },
                  attrs: { src: _vm.main_image, alt: "" },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "flex-one l",
            staticStyle: { overflow: "hidden", position: "relative" },
          },
          [
            _c(
              "div",
              {
                staticClass: "screen-slide-box",
                style: _vm.showslide ? "opacity:1;" : "opacity:0;",
              },
              [
                _vm.origin == 1
                  ? _c(
                      "div",
                      { staticClass: "screen-center-slide" },
                      [
                        _vm.nextUrl
                          ? _c("video", {
                              staticStyle: {
                                width: "0px",
                                height: "0px",
                                opacity: "0",
                              },
                              style:
                                "" +
                                (_vm.videoModelType == 1
                                  ? "object-fit: contain;"
                                  : "object-fit: cover"),
                              attrs: {
                                id: "videonew1",
                                muted: "",
                                src: _vm.nextUrl,
                                preload: "auto",
                              },
                              domProps: { muted: true },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.auctionData.product &&
                        _vm.auctionData.product.video_link &&
                        _vm.showvideo
                          ? _c("div", { staticClass: "video-all-view" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "video-view-r",
                                  class: _vm.isDetail ? "gol" : "",
                                },
                                [
                                  _c("video", {
                                    staticStyle: {
                                      "object-fit": "contain",
                                      height: "100%",
                                      width: "auto",
                                    },
                                    attrs: {
                                      id: "video1",
                                      muted: "",
                                      src: _vm.auctionData.product.video_link,
                                      loop: "loop",
                                    },
                                    domProps: { muted: true },
                                    on: {
                                      loadedmetadata: _vm.onVideoLoadedMetadata,
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.auctionData.product
                                ? _c("img", {
                                    staticClass: "video-img-bg",
                                    attrs: {
                                      src:
                                        _vm.auctionData.product.image +
                                        "?x-oss-process=image/auto-orient,1/resize,m_lfit,w_1080/quality,q_100/format,webp",
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                            ])
                          : _vm.showmodel == 3
                          ? [
                              _c("div", { staticClass: "img-box 1" }, [
                                _vm.auctionData.product
                                  ? _c("img", {
                                      staticClass: "solidimg",
                                      style:
                                        (_vm.imgdisplat[0] == "shu" ||
                                        _vm.imgdisplat[0] == "fangshu"
                                          ? "height: auto;"
                                          : "") +
                                        (_vm.imgdisplat[0] == "heng" ||
                                        _vm.imgdisplat[0] == "fangheng"
                                          ? "width: auto;"
                                          : "") +
                                        (_vm.imgdisplat[0] == "fang" ||
                                        _vm.imgdisplat[0] == "fangheng" ||
                                        _vm.imgdisplat[0] == "fangshu"
                                          ? "width:" +
                                            _vm.imgdisjson[0].width +
                                            "px;height:" +
                                            _vm.imgdisjson[0].height +
                                            "px;" +
                                            "transform: translate(-" +
                                            (_vm.imgdisjson[0].width -
                                              _vm.clientWidth) /
                                              2 +
                                            "px, -" +
                                            (_vm.imgdisjson[0].height -
                                              _vm.clientHeight) /
                                              2 +
                                            "px);"
                                          : "") +
                                        _vm.scrollimgcss,
                                      attrs: {
                                        src:
                                          _vm.auctionData.product.image +
                                          "?x-oss-process=image/auto-orient,1/resize,m_lfit,w_1080/quality,q_100/format,webp",
                                        alt: "",
                                      },
                                      on: { load: _vm.animateload },
                                    })
                                  : _vm._e(),
                              ]),
                            ]
                          : _c("div", { staticClass: "video-all-view" }, [
                              _vm.auctionData.product
                                ? _c("img", {
                                    staticClass: "show-one-img",
                                    class: _vm.isDetail ? "gol" : "",
                                    style:
                                      "" +
                                      (_vm.showmodel == 1
                                        ? "object-fit: contain;"
                                        : "object-fit: cover;width:100%;"),
                                    attrs: {
                                      src:
                                        _vm.auctionData.product.image +
                                        "?x-oss-process=image/auto-orient,1/resize,m_lfit,w_1080/quality,q_100/format,webp",
                                    },
                                  })
                                : _vm._e(),
                            ]),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.origin == 2 && _vm.auctionData.product
                  ? [
                      _vm.nextUrl
                        ? _c("video", {
                            staticStyle: {
                              width: "0px",
                              height: "0px",
                              opacity: "0",
                            },
                            style:
                              "" +
                              (_vm.videoModelType == 1
                                ? "object-fit: contain;"
                                : "object-fit: cover"),
                            attrs: {
                              id: "videonew1",
                              muted: "",
                              src: _vm.nextUrl,
                              preload: "auto",
                            },
                            domProps: { muted: true },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.auctionData.product &&
                      _vm.auctionData.product.video_link &&
                      _vm.showvideo
                        ? _c("div", { staticClass: "video-all-view" }, [
                            _c(
                              "div",
                              {
                                staticClass: "video-view-r",
                                class: _vm.isDetail ? "gol" : "",
                              },
                              [
                                _c("video", {
                                  staticStyle: {
                                    "object-fit": "contain",
                                    height: "100%",
                                    width: "auto",
                                  },
                                  attrs: {
                                    id: "video1",
                                    muted: "",
                                    src: _vm.auctionData.product.video_link,
                                    loop: "loop",
                                  },
                                  domProps: { muted: true },
                                  on: {
                                    loadedmetadata: _vm.onVideoLoadedMetadata,
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.auctionData.product
                              ? _c("img", {
                                  staticClass: "video-img-bg",
                                  attrs: {
                                    src:
                                      _vm.auctionData.product.image +
                                      "?x-oss-process=image/auto-orient,1/resize,m_lfit,w_1080/quality,q_100/format,webp",
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                          ])
                        : _vm._l(
                            _vm.auctionData.product.slider_image,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "screen-center-slide is-r",
                                  style:
                                    "z-index:" +
                                    (index + 1) +
                                    ";" +
                                    _vm.duration +
                                    (_vm.animateindex < index
                                      ? _vm.csstext
                                      : ""),
                                },
                                [
                                  _vm.showmodel == 3
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "img-box 2" },
                                          [
                                            _c("img", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    !_vm.playvideo &&
                                                    _vm.imgdisplat[index],
                                                  expression:
                                                    "!playvideo && imgdisplat[index]",
                                                },
                                              ],
                                              staticClass: "solidimg",
                                              style:
                                                (_vm.imgdisplat[index] == "shu"
                                                  ? "height: auto;"
                                                  : "") +
                                                (_vm.imgdisplat[index] == "heng"
                                                  ? "width: auto;"
                                                  : "") +
                                                (_vm.imgdisplat[index] ==
                                                  "fang" ||
                                                _vm.imgdisplat[index] ==
                                                  "fangheng" ||
                                                _vm.imgdisplat[index] ==
                                                  "fangshu"
                                                  ? "width:" +
                                                    _vm.imgdisjson[index]
                                                      .width +
                                                    "px;height:" +
                                                    _vm.imgdisjson[index]
                                                      .height +
                                                    "px;" +
                                                    "transform: translate(-" +
                                                    _vm.imgdisjson[index]
                                                      .width *
                                                      0.1 +
                                                    "px, -" +
                                                    _vm.imgdisjson[index]
                                                      .height *
                                                      0.1 +
                                                    "px);"
                                                  : "") +
                                                (_vm.slideAnimateindex == index
                                                  ? _vm.scrollimgcss
                                                  : "") +
                                                (_vm.upindex == index
                                                  ? _vm.upcss
                                                  : ""),
                                              attrs: {
                                                src:
                                                  item +
                                                  "?x-oss-process=image/auto-orient,1/resize,m_lfit,w_1080/quality,q_100/format,webp",
                                                alt: "",
                                              },
                                              on: {
                                                load: function ($event) {
                                                  return _vm.animatesolid(
                                                    item,
                                                    index
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    : _c(
                                        "div",
                                        { staticClass: "video-all-view" },
                                        [
                                          _c("img", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.animateindex == index,
                                                expression:
                                                  "animateindex == index",
                                              },
                                            ],
                                            staticClass: "show-one-img",
                                            class: _vm.isDetail ? "gol" : "",
                                            style:
                                              "" +
                                              (_vm.showmodel == 1
                                                ? "object-fit: contain;"
                                                : "object-fit: cover;width:100%;"),
                                            attrs: {
                                              src:
                                                item +
                                                "?x-oss-process=image/auto-orient,1/resize,m_lfit,w_1080/quality,q_100/format,webp",
                                            },
                                          }),
                                        ]
                                      ),
                                ],
                                2
                              )
                            }
                          ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "productInfoBox",
                style: {
                  right: "unset",
                  left: 0,
                  width: _vm.auction_width + "%",
                  "background-color": _vm.auction_color,
                  padding:
                    _vm.auction_top_padding +
                    "% " +
                    _vm.auction_lr_padding +
                    "% 0",
                  "background-image": "url(" + _vm.auction_image + ")",
                  transform: _vm.isDetail
                    ? "translateX(0%)"
                    : "translateX(-100%)",
                },
              },
              [
                _vm.showtext
                  ? _c(
                      "div",
                      {
                        staticClass: "animate__fadeInDownBig",
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          "animation-duration": "3s",
                        },
                        style:
                          "font-size:" +
                          _vm.album_font_size +
                          "vh;margin-bottom: " +
                          _vm.album_bottom_margin +
                          "vh;",
                      },
                      [
                        _c("span", [
                          _vm._v("LOT " + _vm._s(_vm.auctionData.lot) + " "),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "screen-goods-sign" }, [
                          _c("div", [
                            _vm._v("已拍 "),
                            _vm.auctionData.order_num
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.auctionData.order_num - 1)),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "ml20" }, [
                            _vm._v("剩余 "),
                            _vm.auctionData.order_num
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.albumData.auction_num -
                                        _vm.auctionData.order_num
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showtext
                  ? _c(
                      "div",
                      {
                        staticClass: "animate__fadeInLeftBig",
                        staticStyle: { "animation-duration": "3s" },
                        style:
                          "font-size:" +
                          _vm.auction_font_size +
                          "vh;margin-bottom: " +
                          _vm.auction_bottom_margin +
                          "vh;",
                      },
                      [
                        _vm.auctionData.product &&
                        _vm.auctionData.product.store_name
                          ? [
                              _vm.auctionData.product.store_name.length > 30
                                ? [
                                    _vm._v(
                                      _vm._s(
                                        _vm.auctionData.product.store_name.slice(
                                          0,
                                          30
                                        )
                                      ) + "..."
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      _vm._s(_vm.auctionData.product.store_name)
                                    ),
                                  ],
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showattr &&
                _vm.auctionData.attr_info &&
                _vm.auctionData.attr_info.length > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "animate__fadeInLeftBig line2",
                        staticStyle: {
                          "animation-duration": "3s",
                          "font-style": "italic",
                          "word-break": "break-all",
                        },
                        style:
                          "font-size:" + _vm.auction_attr_font_size + "vh;",
                      },
                      [
                        _vm._l(
                          _vm.auctionData.attr_info,
                          function (item, index) {
                            return [
                              item.attr_value_name
                                ? _c("span", { key: "" + index }, [
                                    _vm._v(
                                      _vm._s(
                                        item.attr_value_name.replace(/×/g, "x")
                                      ) + "  "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showtext
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "screen-goods-img-box item-flex-center animate__fadeInUpBig",
                        staticStyle: { "animation-duration": "3s" },
                        style: "margin-top: " + _vm.auction_attr_margin + "vh",
                      },
                      [
                        _vm.auctionData.product
                          ? _c("div", { staticClass: "borderBox" }, [
                              _c("span", {
                                staticClass: "soildtr",
                                style: "background: " + _vm.image_border_color,
                              }),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "soildrt",
                                style: "background: " + _vm.image_border_color,
                              }),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "soildbl",
                                style: "background: " + _vm.image_border_color,
                              }),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "soildlb",
                                style: "background: " + _vm.image_border_color,
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "dashtr" }, [
                                _c("span", {
                                  style:
                                    "background-image: linear-gradient(to right, " +
                                    _vm.image_border_color +
                                    " 0%, " +
                                    _vm.image_border_color +
                                    " 50%, transparent 50%)",
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "dashrt" }, [
                                _c("span", {
                                  style:
                                    "background-image: linear-gradient(to bottom," +
                                    _vm.image_border_color +
                                    " 0%," +
                                    _vm.image_border_color +
                                    " 50%, transparent 50%)",
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "dashbr" }, [
                                _c("span", {
                                  style:
                                    "background-image: linear-gradient(to right," +
                                    _vm.image_border_color +
                                    " 0%," +
                                    _vm.image_border_color +
                                    " 50%, transparent 50%)",
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "dashrb" }, [
                                _c("span", {
                                  style:
                                    "background-image: linear-gradient(to bottom," +
                                    _vm.image_border_color +
                                    " 0%," +
                                    _vm.image_border_color +
                                    " 50%, transparent 50%)",
                                }),
                              ]),
                              _vm._v(" "),
                              _c("img", {
                                style:
                                  "max-width:" +
                                  _vm.image_size +
                                  "vh;max-height:" +
                                  _vm.image_size +
                                  "vh; ",
                                attrs: {
                                  src: _vm.auctionData.product.image + "!m640",
                                  alt: "",
                                },
                              }),
                            ])
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.end
        ? _c("div", { staticClass: "is-end item-flex-center" }, [
            _c("div", { staticClass: "end-view" }, [
              _c("div", { staticClass: "top-name" }, [
                _vm._v(_vm._s(_vm.endname)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "top1" }, [
                _vm._v(
                  _vm._s(
                    _vm.parseTime(_vm.endtime || 0, "{y}年{m}月{d}日 {h}:{i}")
                  ) + " 结拍"
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "center1" }, [
                _vm._v(
                  "本" +
                    _vm._s(_vm.showvence ? "拍卖会" : "场") +
                    _vm._s(_vm.total_num) +
                    "件/成交" +
                    _vm._s(_vm.deal_num) +
                    "件/成交率" +
                    _vm._s(_vm.deal_rate) +
                    "\n        "
                ),
                _c("span", { staticStyle: { "font-family": "none" } }, [
                  _vm._v("%"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "center2" }, [
                _vm._v(
                  "本" +
                    _vm._s(_vm.showvence ? "拍卖会" : "场") +
                    "成交额" +
                    _vm._s(_vm.fee_type == "CNY" ? "RMB" : _vm.fee_type) +
                    " " +
                    _vm._s(_vm.formatPriceNumber(Number(_vm.deal_price), 2))
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bottom-copyright" }, [
                _vm._v("艺天下提供技术支持"),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }