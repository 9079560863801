"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _user = require("@/api/user");
var _system = require("@/api/system");
var _couponList = _interopRequireDefault(require("./couponList"));
var _userdetail = _interopRequireDefault(require("./userdetail"));
var _fileList = _interopRequireDefault(require("@/components/exportFile/fileList"));
var _request = _interopRequireDefault(require("@/api/request"));
var _addUser = _interopRequireDefault(require("../../../components/addUser.vue"));
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'UserList',
  components: {
    couponList: _couponList.default,
    userdetail: _userdetail.default,
    fileList: _fileList.default,
    AddUserCom: _addUser.default
  },
  // filters: {
  //   groupHand(val) {
  //     if (!val) return ''
  //     else{
  //       console.log(val);
  //       const arr = val.map(item => item.group_name)
  //       return arr.join(',') 
  //     }
  //   },
  // },
  data: function data() {
    return (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
      moren: require('@/assets/images/f.png'),
      myHeaders: {
        "X-Token": (0, _auth.getToken)()
      },
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '昨天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1)));
            end.setTime(end.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())));
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近7天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近30天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '本月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '本年',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.setTime(new Date(new Date().getFullYear(), 0, 1)));
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      timeVal: [],
      collapse: false,
      visibleDetail: false,
      visibleCoupon: false,
      maxCols: 3,
      isShowSend: true,
      visible: false,
      user_type: 'all',
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      wechatIds: '',
      row: '',
      labelPosition: 'right',
      userProps: {
        children: 'children',
        label: 'name',
        value: 'name'
      },
      userFrom: {
        // label_id: '',
        // user_type: '',
        // sex: '',
        // is_promoter: '',
        // country: '',
        // pay_count: '',
        // user_time_type: '',
        // user_time: '',
        // nickname: '',
        // province: '',
        // city: '',
        // group_id: ''
        page: 1,
        limit: 20
        // isSearch: false,
      },
      formState: {
        page: 1,
        limit: 20
      },
      couponForm: {
        用户标签: '',
        用户类型: '',
        性别: '',
        身份: '',
        消费情况: '',
        访问情况: '',
        访问时间: '',
        昵称: ''
      },
      address: [],
      grid: {
        xl: 8,
        lg: 12,
        md: 12,
        sm: 24,
        xs: 24
      },
      grid2: {
        xl: 18,
        lg: 16,
        md: 12,
        sm: 24,
        xs: 24
      },
      grid3: {
        xl: 8,
        lg: 12,
        md: 12,
        sm: 24,
        xs: 24
      },
      addresData: [],
      groupList: [],
      labelLists: [],
      chkName: '',
      checkedPage: [],
      checkedIds: [],
      // 订单当前页选中的数据
      noChecked: [],
      // 订单全选状态下当前页不选中的数据
      allCheck: false,
      showdetail: false,
      sendIntegralUid: '',
      sendIntegralUname: '',
      sendIntegralVal: '',
      visibleIntegral: false,
      userProhibitSet: {
        show: false,
        nickname: '',
        uid: '',
        status: 1,
        ban_function: []
      },
      sendIntegralType: 1,
      showImg: false,
      // 查看图片 控制器
      imgUrl: '',
      // 放大查看的图片
      oppvisible: false
    }, "groupList", []), "labelList", []), "labelNameArr", []), "integType", 'radio'), "labelVisible", false), "labelFrom", {
      label_id: []
    }), "searchQuery", {}), "cardOption", [{
      label: '中国',
      value: '1'
    }, {
      label: '香港',
      value: '2'
    }, {
      label: '澳门',
      value: '3'
    }, {
      label: '台湾',
      value: '4'
    }]);
  },
  computed: {
    fileUrl: function fileUrl() {
      return _settingMer.default.https + "/excel/import/import_pm_number_plate";
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.getTagList();
    this.getList('');
    (0, _user.groupLstApi)({
      page: 1,
      limit: 9999999
    }).then(function (res) {
      _this.groupList = res.data.list.map(function (item) {
        return {
          label: item.group_name,
          value: item.group_id
        };
      });
    });
    (0, _user.labelLstApi)({
      page: 1,
      limit: 9999999
    }).then(function (res) {
      _this.labelList = res.data.list.map(function (item) {
        return {
          label: item.label_name,
          value: item.label_id
        };
      });
    });
  },
  methods: {
    handleSuccess: function handleSuccess(response) {
      if (response.status === 200) {
        this.$message.success('上传成功');
        this.getList('');
      } else {
        this.$message.error(response.message);
      }
      this.$refs.uploadImg.clearFiles();
    },
    downloadTemplate: function downloadTemplate() {
      this.$downloadFile({
        url: _settingMer.default.https + "/excel/templateDownload?type=import_user_entrust&token=" + (0, _auth.getToken)(),
        name: 'template.xlsx'
      });
    },
    onAdd: function onAdd() {
      this.$refs.userREf.adduser(this.$route.query.is_entrust);
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      if (e.paymax) this.searchQuery.pay_count = "".concat(this.searchQuery.paymin, "-").concat(this.searchQuery.paymax);
      if (e.primax) this.searchQuery.pay_price = "".concat(this.searchQuery.primin, "-").concat(this.searchQuery.primax);
      this.userFrom = Object.assign({
        page: 1,
        limit: this.userFrom.limit
      }, this.searchQuery);
      this.getList(1);
    },
    onUpdUser: function onUpdUser(row) {
      this.$refs.userREf.onUpdRow(row, this.$route.query.is_entrust);
    },
    onOpenLabel: function onOpenLabel() {
      this.labelFrom = {
        label_id: []
      };
      this.labelVisible = true;
    },
    checkLabelSub: function checkLabelSub() {
      var _this2 = this;
      if (this.labelFrom.label_id.length <= 0) {
        this.$message.error('请选择标签');
        return;
      }
      var model = {
        ids: this.checkedIds,
        label_id: this.labelFrom.label_id
      };
      (0, _user.changeLabelsApi)(model).then(function (res) {
        _this2.labelVisible = false;
        _this2.$message.success('操作成功');
        _this2.getList();
      });
    },
    // 列表
    getList: function getList(num) {
      var _this3 = this;
      this.listLoading = true;
      this.userFrom.page = num || this.userFrom.page;
      if (this.$route.query.is_entrust) this.userFrom.is_entrust = this.$route.query.is_entrust ? 1 : 0;
      (0, _user.userLstApi)(this.userFrom).then(function (res) {
        _this3.tableData.data = res.data.list;
        _this3.tableData.total = res.data.count;
        _this3.listLoading = false;
        _this3.$nextTick(function () {
          if (_this3.$refs.userRef) _this3.$refs.userRef.doLayout();
        });
        _this3.getCoupounParmas();
      }).catch(function (res) {
        _this3.listLoading = false;
        // this.$message.error(res.message);
      });
    },
    onAfterEnter: function onAfterEnter(str) {
      this.$refs[str].focus();
    },
    changeTypeAction: function changeTypeAction(tab) {
      if (tab.name === 'group') {
        this.$router.push('/merchant/user/group');
        return false;
      } else if (tab.name === 'label') {
        this.$router.push('/merchant/user/label');
        return false;
      } else if (tab.name === 'authentication') {
        this.$router.push('/merchant/user/verify');
        return false;
      } else if (tab.name === 'regiest_set') {
        this.$router.push('/merchant/user/regiest_set');
        return false;
      } else if (tab.name === 'canel') {
        this.$router.push('/merchant/user/cancellation');
        return false;
      }
      this.user_type = tab.name || 'all';
      this.getList(1);
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = false;
    },
    // 用户
    onHandle: function onHandle(name) {
      this.chkName = this.chkName === name ? '' : name;
      this.changeType(!(this.chkName === ''));
    },
    changeType: function changeType(v) {
      if (v) {
        if (!this.chkName) {
          this.chkName = 'dan';
        }
      } else {
        this.chkName = '';
        this.allCheck = false;
      }
      var index = this.checkedPage.indexOf(this.userFrom.page);
      if (this.chkName === 'dan') {
        this.checkedPage.push(this.userFrom.page);
      } else if (index > -1) {
        this.checkedPage.splice(index, 1);
      }
      this.syncCheckedId();
    },
    syncCheckedId: function syncCheckedId() {
      var _this4 = this;
      var ids = this.tableData.data.map(function (v) {
        return v.uid;
      });
      if (this.chkName === 'duo') {
        this.checkedIds = [];
        this.allCheck = true;
      } else if (this.chkName === 'dan') {
        this.allCheck = false;
        ids.forEach(function (id) {
          var index = _this4.checkedIds.indexOf(id);
          if (index === -1) {
            _this4.checkedIds.push(id);
          }
        });
      } else {
        ids.forEach(function (id) {
          var index = _this4.checkedIds.indexOf(id);
          if (index > -1) {
            _this4.checkedIds.splice(index, 1);
          }
        });
      }
    },
    // 发送优惠券
    sendCoupon: function sendCoupon() {
      if (this.checkedIds.length == 0 && this.allCheck == false) {
        this.$message.warning('请选择用户');
      } else {
        this.visibleCoupon = true;
      }
    },
    // 分开选择
    changeOne: function changeOne(v, user) {
      if (v) {
        if (this.chkName === 'duo') {
          var index = this.noChecked.indexOf(user.uid);
          if (index > -1) this.noChecked.splice(index, 1);
        } else {
          var _index = this.checkedIds.indexOf(user.uid);
          if (_index === -1) this.checkedIds.push(user.uid);
        }
      } else {
        if (this.chkName === 'duo') {
          var _index2 = this.noChecked.indexOf(user.uid);
          if (_index2 === -1) this.noChecked.push(user.uid);
        } else {
          var _index3 = this.checkedIds.indexOf(user.uid);
          if (_index3 > -1) this.checkedIds.splice(_index3, 1);
        }
      }
    },
    sendSuccess: function sendSuccess() {
      this.visibleCoupon = false;
    },
    getCoupounParmas: function getCoupounParmas() {
      var label_id = this.userFrom.label_id == '' ? '' : this.getLabelValue();
      var user_type = this.findKey(this.userFrom.user_type, {
        '': '',
        微信用户: 'wechat',
        小程序用户: 'routine',
        H5用户: 'h5'
      });
      var sex = this.findKey(this.userFrom.sex, {
        男: '0',
        女: '1',
        未知: '2',
        '': ''
      });
      var pay_count = this.findKey(this.userFrom.sex, {
        '0次': '-1',
        '1次以上': '0',
        '2次以上': '1',
        '3次以上': '2',
        '4次以上': '3',
        '5次以上': '4',
        '': ''
      });
      var is_promoter = this.findKey(this.userFrom.is_promoter, {
        推广员: '1',
        普通用户: '0',
        '': ''
      });
      var user_time_type = this.userFrom.user_time_type == 'visit' ? '最后访问' : this.userFrom.user_time_type == 'add_time' ? '首次访问' : '';
      this.couponForm = {
        用户标签: label_id,
        用户类型: user_type,
        性别: sex,
        消费情况: pay_count,
        身份: is_promoter,
        访问情况: user_time_type,
        访问时间: this.userFrom.user_time,
        昵称: this.userFrom.nickname
      };
    },
    findKey: function findKey(value, data) {
      var compare = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function (a, b) {
        return a === b;
      };
      return Object.keys(data).find(function (k) {
        return compare(data[k], value);
      });
    },
    getLabelValue: function getLabelValue() {
      var labelName = '';
      for (var i = 0; i < this.labelLists.length; i++) {
        if (this.labelLists[i]['label_id'] === this.userFrom.label_id) {
          labelName = this.labelLists[i]['label_name'];
          return labelName;
        }
      }
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.userFrom.user_time = tab;
      this.getList('');
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.userFrom.user_time = e ? this.timeVal.join('-') : '';
    },
    // userSearchs() {
    //   if (this.userFrom.user_time_type && !this.userFrom.user_time) {
    //     this.$message.error('请选择访问时间');
    //   } else if (!this.userFrom.user_time_type && this.userFrom.user_time) {
    //     this.$message.error('请选择访问情况');
    //   } else {
    //     this.getList(1);
    //   }
    // },
    // 标签列表
    getTagList: function getTagList() {
      var _this5 = this;
      (0, _user.labelLstApi)({
        page: 1,
        limit: 9999,
        all: 1
      }).then(function (res) {
        _this5.labelLists = res.data.list;
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    // 详情
    onDetails: function onDetails(row) {
      this.row = row;
      this.uid = row.uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    Close: function Close() {
      this.visibleDetail = false;
    },
    handleClose: function handleClose() {
      this.visible = false;
    },
    // 修改分组
    setGroup: function setGroup(row) {
      this.$modalForm((0, _user.changeGroupApi)(row.uid));
    },
    // 批量分组
    batchGroup: function batchGroup() {
      var _this6 = this;
      if (this.checkedIds.length === 0) {
        return this.$message.warning('请先选择用户');
      }
      this.$modalForm((0, _user.batchChangeGroupApi)({
        ids: this.checkedIds.join(',')
      })).then(function () {
        _this6.getList('');
      });
    },
    // 修改标签
    setLabel: function setLabel(id) {
      var _this7 = this;
      this.$modalForm((0, _user.changeGroupApi)(id)).then(function () {
        return _this7.getList('');
      });
    },
    pageChange: function pageChange(page) {
      this.userFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.userFrom.limit = val;
      this.getList('');
    },
    handleClick: function handleClick() {
      this.getList('');
    },
    // 重置
    reset: function reset() {
      this.userFrom = {
        label_id: '',
        user_type: 'all',
        sex: '',
        is_promoter: '',
        country: '',
        pay_count: '',
        user_time_type: '',
        user_time: '',
        nickname: '',
        province: '',
        city: '',
        page: 1,
        limit: 20,
        group_id: '',
        date: ''
      }, this.timeVal = [];
    },
    // 导出
    exportOrder: function exportOrder() {
      var _this8 = this;
      this.userFrom.export_type = 'merchant_user';
      (0, _system.exportexcel)(this.userFrom).then(function (res) {
        // this.$message.success(res.message);
        // this.fileVisible = true;
        // this.$refs.exportList.exportFileList();
        var h = _this8.$createElement;
        _this8.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', null, '文件正在生成中，请稍后点击"'), h('span', {
            style: 'color: #69d',
            class: 'cur',
            on: {
              click: function click() {
                _this8.$msgbox.close();
                _this8.getExportFileList();
              }
            }
          }, '导出记录'), h('span', null, '"查看~ ')]),
          confirmButtonText: '我知道了'
        }).then(function (action) {});
      }).catch(function (res) {
        // this.$message.error(res.message);
      });
    },
    getExportFileList: function getExportFileList() {
      this.fileVisible = true;
      this.$refs.exportList.exportFileList('merchant_user');
    },
    openLockAction: function openLockAction(uid) {
      var _this9 = this;
      this.$confirm("\u786E\u8BA4\u89E3\u9501\u8BE5\u7528\u6237?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _user.unlockOrderApi)({
          uid: uid
        }).then(function () {
          _this9.$message.success('操作成功');
          _this9.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    openLockAllAction: function openLockAllAction() {
      var _this10 = this;
      this.$confirm("\u786E\u8BA4\u89E3\u9501\u5168\u90E8\u7528\u6237?", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _user.unlockOrderAllApi)().then(function () {
          _this10.$message.success('操作成功');
          _this10.getList();
        }).catch(function (message) {});
      }).catch(function () {});
    },
    // 发送优惠券
    sendIntegralAction: function sendIntegralAction(m) {
      this.integType = 'radio';
      this.sendIntegralUid = m.uid;
      this.sendIntegralUname = m.nickname;
      this.sendIntegralVal = '';
      this.sendIntegralType = 1;
      this.visibleIntegral = true;
    },
    sendIntegralAllAction: function sendIntegralAllAction() {
      this.integType = 'check';
      this.visibleIntegral = true;
    },
    sendIntegralConfirm: function sendIntegralConfirm() {
      var _this11 = this;
      if (!this.sendIntegralVal) {
        this.$message.error('请输入要赠送的积分数');
        return false;
      }
      if (this.integType == 'radio') {
        this.$confirm("\u786E\u8BA4".concat(this.sendIntegralType === 1 ? '赠送' : '扣减').concat(this.sendIntegralUname, "\u7528\u6237 ").concat(this.sendIntegralVal, "\u79EF\u5206?"), '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          var action = _user.issueIntegralApi;
          if (_this11.sendIntegralType === 2) {
            action = _user.reduceIntegralApi;
          }
          action({
            uid: _this11.sendIntegralUid,
            num: _this11.sendIntegralVal
          }).then(function () {
            _this11.visibleIntegral = false;
            _this11.$message.success('操作成功');
          }).catch(function (message) {});
        }).catch(function () {});
      }
      if (this.integType == 'check') {
        this.$confirm("\u786E\u8BA4".concat(this.sendIntegralType === 1 ? '赠送' : '扣减').concat(this.sendIntegralVal, "\u79EF\u5206?"), '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          var action = _user.issueIntegralApi;
          if (_this11.sendIntegralType === 2) {
            action = _user.reduceIntegralApi;
          }
          action({
            uid: _this11.checkedIds,
            num: _this11.sendIntegralVal
          }).then(function () {
            _this11.visibleIntegral = false;
            _this11.checkedIds = [];
            _this11.$message.success('操作成功');
            _this11.pageChange(1);
          }).catch(function (message) {});
        }).catch(function () {});
      }
    },
    showUserProhibitSetAction: function showUserProhibitSetAction(row) {
      this.userProhibitSet = {
        show: true,
        status: row.status,
        uid: row.uid,
        nickname: row.nickname,
        ban_function: row.ban_function ? row.ban_function.split(',') : []
      };
    },
    sendUserProhibitSet: function sendUserProhibitSet() {
      var _this12 = this;
      if (this.userProhibitSet.status === 2 && this.userProhibitSet.ban_function.length <= 0) {
        this.$message.error('请选择要禁用的功能');
        return false;
      }
      this.$confirm("\u786E\u8BA4\u4FEE\u6539 ".concat(this.userProhibitSet.nickname, " \u7528\u6237\u7684\u7981\u7528\u8BBE\u7F6E?"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var data = {
          status: _this12.userProhibitSet.status
        };
        if (data.status === 2) {
          data.ban_function = _this12.userProhibitSet.ban_function.join(',');
        }
        (0, _user.switchUserStatusApi)(_this12.userProhibitSet.uid, data).then(function () {
          _this12.userProhibitSet.show = false;
          _this12.getList();
          _this12.$message.success('操作成功');
        }).catch(function (message) {});
      }).catch(function () {});
    },
    editMarkAction: function editMarkAction(m) {
      var _this13 = this;
      this.$prompt('请输入备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: m.mark
      }).then(function (_ref2) {
        var value = _ref2.value;
        (0, _user.updateUserMarkApi)(m.uid, {
          mark: value
        }).then(function (res) {
          _this13.getList();
        });
      }).catch(function () {});
    },
    xcxShareAction: function xcxShareAction(m) {
      var _this14 = this;
      this.imgUrl = '';
      var action = process.env.VUE_APP_BASE_API + '/api/sharePoster';
      var mer_id = this.$store.getters.mer_id;
      action += '?return_format=2&qrcode_type=1&poster_type=userShare&Independent_qrcode=1&id=' + m.uid + '&path=' + encodeURIComponent("/pages/home/home?inviteuid=".concat(m.uid)) + '&mer_id=' + mer_id;
      // this.imgData.imgUrl = action;
      this.showImg = true;
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      _request.default.get(action).then(function (res) {
        loading.close();
        _this14.imgUrl = res.data.base_image;
      });
    }
  }
};