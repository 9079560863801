"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MakeSwitchLikeApi = MakeSwitchLikeApi;
exports.accountBalanceApi = accountBalanceApi;
exports.accountBalanceRecordListApi = accountBalanceRecordListApi;
exports.aiGeneratedIntroApi = aiGeneratedIntroApi;
exports.batchLabelApi = batchLabelApi;
exports.getGenerateDemandApi = getGenerateDemandApi;
exports.getHeaderNumberApi = getHeaderNumberApi;
exports.getLiveListApi = getLiveListApi;
exports.getTagListApi = getTagListApi;
exports.getTemplateListApi = getTemplateListApi;
exports.getTemplateTypeListApi = getTemplateTypeListApi;
exports.getmarketMediaCreateApi = getmarketMediaCreateApi;
exports.getmarketMediaDetailApi = getmarketMediaDetailApi;
exports.getmarketMediaListApi = getmarketMediaListApi;
exports.getmarketMediaUpdateApi = getmarketMediaUpdateApi;
exports.joinRoom = joinRoom;
exports.labelCreateApi = labelCreateApi;
exports.marketMediaCollCreateApi = marketMediaCollCreateApi;
exports.marketMediaCreateApi = marketMediaCreateApi;
exports.orderBatchLabelApi = orderBatchLabelApi;
exports.orderDataListApi = orderDataListApi;
exports.orderDeleteApi = orderDeleteApi;
exports.ordinaryListApi = ordinaryListApi;
exports.posterOrderDeleteApi = posterOrderDeleteApi;
exports.relationDeleteApi = relationDeleteApi;
exports.relationGetTypeIdsApi = relationGetTypeIdsApi;
exports.switchGenerateResultStatusApi = switchGenerateResultStatusApi;
exports.switchLikeApi = switchLikeApi;
exports.templateUpdateApi = templateUpdateApi;
exports.tweetLabelListApi = tweetLabelListApi;
exports.tweetOrderDeleteApi = tweetOrderDeleteApi;
exports.tweetTemplateDeleteAction = tweetTemplateDeleteAction;
exports.tweetTemplateDetailApi = tweetTemplateDetailApi;
exports.tweetTemplateListApi = tweetTemplateListApi;
exports.tweetTemplateTypeApi = tweetTemplateTypeApi;
exports.veTemplateListApi = veTemplateListApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 获取订单生成需求
 */
function getGenerateDemandApi(data) {
  return _request.default.get("marketMedia/order/getGenerateDemand", data);
}

/**
 * @description 创建订单
 */
function marketMediaCreateApi(data) {
  return _request.default.post("marketMedia/order/create", data);
}

/**
 * @description 获取订单列表
 */
function getmarketMediaListApi(data) {
  return _request.default.get("marketMedia/order/list", data);
}

/**
 * @description 添加订单数据
 */
function getmarketMediaCreateApi(data) {
  return _request.default.post("marketMedia/orderData/create", data);
}

/**
 * @description 修改订单数据
 */
function getmarketMediaUpdateApi(data) {
  return _request.default.post("marketMedia/orderData/update", data);
}

/**
 * @description 订单详情
 */
function getmarketMediaDetailApi(data) {
  return _request.default.get("marketMedia/orderData/detail", data);
}

/**
 * @description 直播列表
 */
function getLiveListApi(data) {
  return _request.default.get("live/room/searchList", data);
}

/**
 * @description 海报模版列表
 */
function getTemplateListApi(data) {
  return _request.default.get("marketMedia/posterDesign/getTemplateList", data);
}

// 加入直播间
function joinRoom(data) {
  return _request.default.post("system/socket/joinRoom", data);
}

// 订单数据修改生成结果
function switchGenerateResultStatusApi(data) {
  return _request.default.post("marketMedia/orderData/switchGenerateResultStatus", data);
}

// 订单数据点赞
function switchLikeApi(data) {
  return _request.default.post("marketMedia/orderData/switchLike", data);
}

/**
 * @description 获取账户余额
 */
function accountBalanceApi(data) {
  return _request.default.get("artart/resource/accountBalance", data);
}

/**
 * @description 获取账户余额记录
 */
function accountBalanceRecordListApi(data) {
  return _request.default.get("artart/resource/accountBalanceRecordList", data);
}

/**
 * @description 海报模版列表
 */
function getTagListApi(data) {
  return _request.default.get("marketMedia/posterDesign/getTagList", data);
}

/**
 * @description 海报模板类型列表
 */
function getTemplateTypeListApi(data) {
  return _request.default.get("marketMedia/posterDesign/getTemplateTypeList", data);
}

// 
function templateUpdateApi(data) {
  return _request.default.post("marketMedia/posterDesign/templateUpdate", data);
}

/**
 * @description 文章类型
 */
function tweetTemplateTypeApi(data) {
  return _request.default.get("marketMedia/tweetTemplate/tweetType", data);
}

// 模板详情
function tweetTemplateDetailApi(data) {
  return _request.default.get("marketMedia/tweetTemplate/info", data);
}

// 模板列表
function tweetTemplateListApi(data) {
  return _request.default.get("marketMedia/tweetTemplate/list", data);
}

// 
function orderDeleteApi(data) {
  return _request.default.post("marketMedia/order/delete", data);
}

// 
function getHeaderNumberApi(data) {
  return _request.default.get("marketMedia/order/headerNumber", data);
}

// 智能生成
function aiGeneratedIntroApi(data) {
  return _request.default.get("auction/statistical/aiGeneratedIntro", data);
}

// 删除推文模版
function tweetTemplateDeleteAction(data) {
  return _request.default.post("marketMedia/tweetTemplate/delete", data);
}

// 删除推文
function tweetOrderDeleteApi(data) {
  return _request.default.post("marketMedia/order/delete", data);
}

// 删除海报
function posterOrderDeleteApi(data) {
  return _request.default.post("marketMedia/orderData/delete", data);
}

// 
function marketMediaCollCreateApi(data) {
  return _request.default.post("marketMedia/relation/create", data);
}

// 
function relationGetTypeIdsApi(data) {
  return _request.default.get("marketMedia/relation/getTypeIds", data);
}

// 
function relationDeleteApi(data) {
  return _request.default.post("marketMedia/relation/delete", data);
}

// 
function veTemplateListApi(data) {
  return _request.default.get("artart/ve/template/list", data);
}

// 推文标签列表
function tweetLabelListApi(data) {
  return _request.default.get("marketMedia/tweetTemplate/labelNumber", data);
}

// 
function orderDataListApi(data) {
  return _request.default.get("marketMedia/orderData/list", data);
}

// 
function ordinaryListApi(data) {
  return _request.default.get("artart/ve/template/ordinaryList", data);
}

// 
function labelCreateApi(data) {
  return _request.default.post("store/label/create", data);
}

// 
function batchLabelApi(data) {
  return _request.default.post("marketMedia/orderData/batchLabel", data);
}

// 
function orderBatchLabelApi(data) {
  return _request.default.post("marketMedia/order/againGenerate", data);
}

// 
function MakeSwitchLikeApi(data) {
  return _request.default.post("marketMedia/switchLike", data);
}