var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "posterMain", staticStyle: { position: "relative" } },
      [
        _c("posterHeader", {
          ref: "posterHeaderEL",
          on: { onRefresh: _vm.onRefresh, createTemplate: _vm.createTemplate },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "flex justify-start" }, [
          _c(
            "div",
            { staticClass: "poster-type-container" },
            [
              _c(
                "div",
                {
                  staticClass: "poster-type-itemOne",
                  class: _vm.activeType == "" ? "type-active" : "",
                  on: {
                    click: function ($event) {
                      return _vm.typeChange("")
                    },
                  },
                },
                [_vm._v("全部")]
              ),
              _vm._v(" "),
              _vm._l(_vm.temTypeList, function (item, key) {
                return _c(
                  "div",
                  {
                    key: key,
                    staticClass: "poster-type-itemOne",
                    class: _vm.activeType == key ? "type-active" : "",
                    on: {
                      click: function ($event) {
                        return _vm.typeChange(key)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "poster-type-container ml24" }, [
            _c(
              "div",
              {
                staticClass: "poster-type-itemOne",
                class: _vm.activeLevel == "" ? "type-active" : "",
                on: {
                  click: function ($event) {
                    return _vm.levelChange("")
                  },
                },
              },
              [_vm._v("全部")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "poster-type-itemOne",
                class: _vm.activeLevel == 1 ? "type-active" : "",
                on: {
                  click: function ($event) {
                    return _vm.levelChange(1)
                  },
                },
              },
              [_vm._v("普通")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "poster-type-itemOne",
                class: _vm.activeLevel == 2 ? "type-active" : "",
                on: {
                  click: function ($event) {
                    return _vm.levelChange(2)
                  },
                },
              },
              [_vm._v("高级")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "poster-type-container no-bor" },
          [
            _c(
              "div",
              {
                staticClass: "poster-type-item",
                class: _vm.activeTag == "" ? "type-activeOne" : "",
                on: {
                  click: function ($event) {
                    return _vm.tagChange("")
                  },
                },
              },
              [_vm._v("全部")]
            ),
            _vm._v(" "),
            _vm._l(_vm.tagList, function (item, key) {
              return _c(
                "div",
                {
                  key: "tag_" + key,
                  staticClass: "poster-type-item",
                  class: _vm.activeTag == item.id ? "type-activeOne" : "",
                  on: {
                    click: function ($event) {
                      return _vm.tagChange(item.id)
                    },
                  },
                },
                [_vm._v(_vm._s(item.name) + " " + _vm._s(item.templateCount))]
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("grid-manager", {
          attrs: {
            "foot-height": 80,
            gap: 24,
            "is-loading": _vm.loadTemplate,
            "grid-list": _vm.templist,
          },
          on: { loadMore: _vm.loadmore },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var row = ref.row
                return [
                  _c(
                    "div",
                    {
                      staticClass: "img-box",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.previewTemplate(row)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "cover-img" }, [
                        _c("div", { staticClass: "flex videoItemTit ft14" }, [
                          _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.template_level == 2,
                                expression: "row.template_level == 2",
                              },
                            ],
                            staticClass: "leveImg",
                            attrs: {
                              src: require("@/assets/images/poster/leve2.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.template_level == 3,
                                expression: "row.template_level == 3",
                              },
                            ],
                            staticClass: "leveImg",
                            attrs: {
                              src: require("@/assets/images/poster/leveg.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "flex-one",
                              staticStyle: { overflow: "hidden" },
                            },
                            [
                              _c("div", { staticClass: "line1" }, [
                                _vm._v(
                                  _vm._s(
                                    row.templateTagText
                                      ? "#" + row.templateTagText
                                      : row.title
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.artaiTweetManagement
                          ? _c(
                              "div",
                              {
                                staticClass: "position-img",
                                staticStyle: { right: "90px" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.goTemplateAction(row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "编辑",
                                      placement: "top",
                                      enterable: false,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/poster/upd.png"),
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.artaiTweetManagement
                          ? _c(
                              "div",
                              {
                                staticClass: "position-img",
                                staticStyle: { right: "50px" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.delAction(row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "删除",
                                      placement: "top",
                                      enterable: false,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/poster/del.png"),
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.type_ids.includes(row.template_id + "")
                          ? _c(
                              "div",
                              {
                                staticClass: "position-img",
                                staticStyle: { right: "10px" },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: "取消收藏",
                                      placement: "top",
                                      enterable: false,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/poster/sc.png"),
                                        alt: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.onDelCollect(row)
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "position-img",
                                staticStyle: { right: "10px" },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: "收藏",
                                      placement: "top",
                                      enterable: false,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/poster/scBlack.png"),
                                        alt: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.onCollect(row)
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "sameBtn",
                            style: _vm.artaiTweetManagement
                              ? ""
                              : "right: 50px;",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onClickTempl(row)
                              },
                            },
                          },
                          [_vm._v("做同款")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "img-view" }, [
                          _c("img", {
                            staticClass: "posIimg",
                            attrs: { src: "" + row.image },
                          }),
                        ]),
                      ]),
                    ]
                  ),
                ]
              },
            },
          ]),
        }),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.visible,
              title: " ",
              width: "430px",
              top: "5vh",
              "custom-class": "dialog-hide-bg",
              "show-close": false,
              "close-on-click-modal": false,
              "before-close": _vm.closeAction,
            },
          },
          [
            _vm.visible
              ? _c("preview", {
                  attrs: {
                    pages: _vm.previewData,
                    temid: _vm.previewDetail.template_id,
                    title: _vm.previewDetail.title,
                    "page-info": _vm.previewDetail.config_data,
                    price: _vm.previewDetail.template_price,
                    type: "outer",
                  },
                  on: { closeAction: _vm.closeAction },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }