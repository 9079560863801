var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contactlist" },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "300px" },
              attrs: { clearable: "", filterable: "" },
              on: { change: _vm.changeAuctionId },
              model: {
                value: _vm.lastAuctionId,
                callback: function ($$v) {
                  _vm.lastAuctionId = $$v
                },
                expression: "lastAuctionId",
              },
            },
            _vm._l(_vm.lastAuction, function (item) {
              return _c("el-option", {
                key: item.venue_id,
                attrs: { label: item.title, value: item.venue_id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 230px)" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "conRef",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                height: "100%",
                size: "mini",
                "highlight-current-row": "",
                "row-key": "id",
                lazy: true,
                load: _vm.loadExpandData,
                "expand-row-keys": _vm.rowkeys,
                border: "",
              },
              on: {
                "expand-change": _vm.exchangeExpand,
                "cell-click": _vm.cellClick,
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _c("template", { slot: "empty" }, [
                _c("div", { staticClass: "tableEmpty" }, [
                  _c("img", {
                    attrs: {
                      src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tableDiv" }, [_vm._v("暂无数据")]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-table-column", {
                key: "1",
                attrs: { type: "selection", width: "36", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "expand", width: "36" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm.showsearch && props.$index == 0 ? void 0 : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "expand-table-box" },
                          [
                            _c(
                              "el-table",
                              {
                                attrs: {
                                  data: props.row.expandData,
                                  size: "small",
                                  "highlight-current-row": "",
                                },
                              },
                              [
                                _c("template", { slot: "empty" }, [
                                  props.row.loading
                                    ? _c("div", [_vm._v("加载中...")])
                                    : _c("div", [
                                        _vm.$store.getters.userPression
                                          .merchantErpV2ContractProductList
                                          ? _c(
                                              "div",
                                              { staticClass: "tableEmpty" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718947444492164850.png!120a",
                                                    alt: "",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "tableDiv" },
                                                  [_vm._v("暂无数据")]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "tableEmpty" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: "https://saas.cdn.yunzongbu.cn/merchant/23/2024621/image/1718954313071950674.png!120a",
                                                    alt: "",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "tableDiv" },
                                                  [_vm._v("无权限查看")]
                                                ),
                                              ]
                                            ),
                                      ]),
                                ]),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "拍品库号",
                                    prop: "product_no",
                                    width: "150",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "nowrap" },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      scope.row.erpProduct
                                                        .product_no
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "图录号",
                                    prop: "lot",
                                    width: "150",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "nowrap" },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.getProductDetail(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.erpProduct.lot
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "货品名称" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  scope.row.erpProduct
                                                    .product_name
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { label: "底价" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(scope.row.retain_price) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.erpsettingList,
                                  function (item, index) {
                                    return [
                                      item.types ==
                                      "erp_v2_contract_create_product"
                                        ? [
                                            item.newtype == "attribute"
                                              ? [
                                                  item.name == "尺寸"
                                                    ? [
                                                        _c("el-table-column", {
                                                          key: index,
                                                          attrs: {
                                                            label: item.name,
                                                            "show-overflow-tooltip":
                                                              "",
                                                            width: "100",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _c("div", {
                                                                      staticClass:
                                                                        "nowrap cur",
                                                                      staticStyle:
                                                                        {
                                                                          height:
                                                                            "100%",
                                                                        },
                                                                      domProps:
                                                                        {
                                                                          innerHTML:
                                                                            _vm._s(
                                                                              scope
                                                                                .row
                                                                                .erpProduct
                                                                                .attribute[
                                                                                item
                                                                                  .field
                                                                              ]
                                                                            ),
                                                                        },
                                                                    }),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ]
                                                    : _c("el-table-column", {
                                                        key: index,
                                                        attrs: {
                                                          label: item.name,
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function (
                                                                scope
                                                              ) {
                                                                return [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        scope
                                                                          .row
                                                                          .erpProduct
                                                                          ? scope
                                                                              .row
                                                                              .erpProduct
                                                                              .attribute[
                                                                              item
                                                                                .field
                                                                            ]
                                                                          : ""
                                                                      ) +
                                                                      "\n                      "
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                ]
                                              : _vm._e(),
                                          ]
                                        : item.types == "store_attribute"
                                        ? [
                                            _c("el-table-column", {
                                              key: index,
                                              attrs: { label: item.name },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              scope.row
                                                                .erpProduct
                                                                ? scope.row
                                                                    .erpProduct
                                                                    .attribute[
                                                                    item.field
                                                                  ]
                                                                : ""
                                                            ) +
                                                            "\n                    "
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ]
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                                _vm._v(" "),
                                _vm.inventoryConfig.switch.sold_price_switch
                                  ? _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          label: "成交收费",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _vm._l(
                                          _vm.inventoryConfig.price.sold_price,
                                          function (item, index) {
                                            return [
                                              item.name
                                                ? _c("el-table-column", {
                                                    key: "s_" + index,
                                                    attrs: { label: item.name },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              scope.row
                                                                .price_data
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        scope
                                                                          .row
                                                                          .price_data[0][
                                                                          index
                                                                        ].number
                                                                      ) +
                                                                        _vm._s(
                                                                          item.unit ==
                                                                            1
                                                                            ? "元"
                                                                            : "%"
                                                                        )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  })
                                                : _vm._e(),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.inventoryConfig.switch.unsold_price_switch
                                  ? _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          label: "未成交收费",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _vm._l(
                                          _vm.inventoryConfig.price
                                            .unsold_price,
                                          function (item, index) {
                                            return [
                                              item.name
                                                ? _c("el-table-column", {
                                                    key: "s_" + index,
                                                    attrs: { label: item.name },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              scope.row
                                                                .price_data
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        scope
                                                                          .row
                                                                          .price_data[1][
                                                                          index
                                                                        ].number
                                                                      ) +
                                                                        _vm._s(
                                                                          item.unit ==
                                                                            1
                                                                            ? "元"
                                                                            : "%"
                                                                        )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  })
                                                : _vm._e(),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.inventoryConfig.switch.other_price_switch
                                  ? _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          label: "其他费用",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _vm._l(
                                          _vm.inventoryConfig.price.other_price,
                                          function (item, index) {
                                            return [
                                              item.name
                                                ? _c("el-table-column", {
                                                    key: "s_" + index,
                                                    attrs: { label: item.name },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (scope) {
                                                            return [
                                                              scope.row
                                                                .price_data
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        scope
                                                                          .row
                                                                          .price_data[2][
                                                                          index
                                                                        ].number
                                                                      ) +
                                                                        _vm._s(
                                                                          item.unit ==
                                                                            1
                                                                            ? "元"
                                                                            : "%"
                                                                        )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  })
                                                : _vm._e(),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "合同编号",
                  "min-width": "150",
                  prop: "",
                  "show-overflow-tooltip": "",
                  "search-key": "contract_no",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "nowrap" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.getdetail(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.contract_no))]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "内部编号",
                  "min-width": "150",
                  "show-overflow-tooltip": "",
                  prop: "contract_inter_no",
                  "search-key": "contract_inter_no",
                  sortable: "custom",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "nowrap",
                            staticStyle: { height: "100%" },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.contract_inter_no) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "审核记录",
                  "min-width": "150",
                  prop: "",
                  "show-overflow-tooltip": "",
                  "search-key": "auction_audit_admin_id",
                  "search-type": "radio",
                  options: _vm.searchAdminarr,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.contractAuctionAudit &&
                        scope.row.contractAuctionAudit.length
                          ? _c("div", { staticClass: "nowrap" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    scope.row.contractAuctionAudit
                                      .map(function (m) {
                                        return m.auditAdmin.real_name
                                      })
                                      .join(",")
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "委托方",
                  "min-width": "130",
                  prop: "",
                  "search-key": "entrust_name_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.entrustUser
                          ? [
                              _c(
                                "el-button",
                                {
                                  staticClass: "nowrap",
                                  staticStyle: {
                                    "padding-bottom": "0",
                                    width: "100%",
                                    "text-align": "left",
                                  },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onDetails(
                                        scope.row.entrustUser
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        scope.row.entrustUser.real_name
                                          ? scope.row.entrustUser.real_name
                                          : scope.row.entrustUser.nickname
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "委托方手机号",
                  "min-width": "130",
                  prop: "",
                  "search-key": "entrust_phone_keyword",
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.entrustUser
                          ? [
                              _c("div", [
                                _vm._v(_vm._s(scope.row.entrustUser.phone)),
                              ]),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "委托方身份证号",
                  "min-width": "130",
                  prop: "",
                  "search-query": _vm.searchQuery,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.entrustUser
                          ? _c("span", [
                              _vm._v(_vm._s(scope.row.entrustUser.idcard)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("ytx-table-column", {
                attrs: {
                  label: "操作",
                  width: "80",
                  align: "center",
                  "search-clear": true,
                  "search-query": _vm.searchQuery,
                },
                on: { changeVal: _vm.searchAction },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.auction_audit_admin_status &&
                        _vm.getBtnPression
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.aduitreview(scope.row)
                                  },
                                },
                              },
                              [_vm._v("审核")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex align-items-c goodslist-page-box" },
        [
          _c(
            "div",
            { staticClass: "flex-one flex" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantSystemExcelExport:erpV2Contract",
                      expression: "'merchantSystemExcelExport:erpV2Contract'",
                    },
                  ],
                  staticStyle: { "margin-left": "4px" },
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-download",
                  },
                  on: { click: _vm.exportOrder },
                },
                [_vm._v("导出合同")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "merchantSystemExcelExport:erpV2Contract",
                      expression: "'merchantSystemExcelExport:erpV2Contract'",
                    },
                  ],
                  attrs: { size: "small", icon: "el-icon-document" },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("导出记录")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticStyle: { "margin-right": "10px" },
            attrs: {
              "page-sizes": [20, 40, 60, 80],
              "page-size": _vm.tableFrom.limit,
              "current-page": _vm.tableFrom.page,
              layout: "total, prev, pager, next, sizes ",
              total: _vm.tableData.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改合同",
            visible: _vm.createflag,
            width: "700px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.createflag = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "divBox" },
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "合同类型：",
                        "label-width": "120px",
                        required: "",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.formConcatValidate.contract_type,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formConcatValidate,
                                "contract_type",
                                $$v
                              )
                            },
                            expression: "formConcatValidate.contract_type",
                          },
                        },
                        [_vm._v("电子合同")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.formConcatValidate.contract_type,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formConcatValidate,
                                "contract_type",
                                $$v
                              )
                            },
                            expression: "formConcatValidate.contract_type",
                          },
                        },
                        [_vm._v("纸质合同")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "业务员",
                        "label-width": "120px",
                        required: "",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selwidth",
                          attrs: {
                            placeholder: "请选择业务员",
                            clearable: "",
                            filterable: "",
                          },
                          on: { change: _vm.saleChange },
                          model: {
                            value: _vm.formConcatValidate.salesman,
                            callback: function ($$v) {
                              _vm.$set(_vm.formConcatValidate, "salesman", $$v)
                            },
                            expression: "formConcatValidate.salesman",
                          },
                        },
                        _vm._l(_vm.adminarr, function (item) {
                          return _c("el-option", {
                            key: item.merchant_admin_id,
                            attrs: {
                              label: item.real_name,
                              value: item.merchant_admin_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "签约人",
                        "label-width": "120px",
                        required: "",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selwidth",
                          attrs: {
                            placeholder: "请选择签约人",
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.formConcatValidate.sign_admin_id,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formConcatValidate,
                                "sign_admin_id",
                                $$v
                              )
                            },
                            expression: "formConcatValidate.sign_admin_id",
                          },
                        },
                        _vm._l(_vm.adminarr, function (item) {
                          return _c("el-option", {
                            key: item.merchant_admin_id,
                            attrs: {
                              label: item.real_name,
                              value: item.merchant_admin_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.formConcatValidate.contract_type == 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "合同模板",
                            "label-width": "120px",
                            required: "",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selwidth",
                              attrs: {
                                placeholder: "请选择模板",
                                clearable: "",
                                filterable: "",
                              },
                              on: { change: _vm.changetemp },
                              model: {
                                value: _vm.formConcatValidate.template,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formConcatValidate,
                                    "template",
                                    $$v
                                  )
                                },
                                expression: "formConcatValidate.template",
                              },
                            },
                            _vm._l(_vm.templist, function (item) {
                              return _c("el-option", {
                                key: item.template_id,
                                attrs: {
                                  label: item.name,
                                  value: item.template_id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "委托人：",
                        "label-width": "120px",
                        required: "",
                      },
                    },
                    [
                      _vm.createflag
                        ? _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择委托人",
                                filterable: "",
                                remote: "",
                                "remote-method": _vm.searchUserAction,
                                loading: _vm.searchLoading,
                              },
                              on: {
                                change: _vm.onUserChange,
                                blur: _vm.createUserb,
                              },
                              model: {
                                value: _vm.formConcatValidate.entrust,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formConcatValidate,
                                    "entrust",
                                    $$v
                                  )
                                },
                                expression: "formConcatValidate.entrust",
                              },
                            },
                            [
                              _vm._l(_vm.userList, function (m, i) {
                                return _c(
                                  "el-option",
                                  {
                                    key: "user" + i,
                                    attrs: { value: m.uid, label: m.nickname },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            width: "40px",
                                            height: "40px",
                                            "border-radius": "50%",
                                            "margin-right": "12px",
                                          },
                                          attrs: { src: m.avatar, alt: "" },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                              "line-height": "18px",
                                            },
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(m.nickname) +
                                                  " "
                                              ),
                                              m.real_name
                                                ? _c("span", [
                                                    _vm._v(
                                                      "(" +
                                                        _vm._s(m.real_name) +
                                                        ")"
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]),
                                            _vm._v(" "),
                                            _c("div", [
                                              _vm._v(_vm._s(m.phone)),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              _vm._v(" "),
                              _c("template", { slot: "empty" }, [
                                _c("div", { staticClass: "select-empty" }, [
                                  _vm._v("没有此用户"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "select-button" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "small" },
                                        on: { click: _vm.createUser },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  确认创建\n                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: { click: _vm.onAdd },
                        },
                        [_vm._v("创建委托方")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.goodsRuleConfigForm.erp_contract_create_special_switch ==
                  1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "特别",
                            "label-width": "120px",
                            required: "",
                          },
                        },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: _vm.formConcatValidate.is_special,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formConcatValidate,
                                  "is_special",
                                  $$v
                                )
                              },
                              expression: "formConcatValidate.is_special",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", "label-width": "120px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.cursoradd },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("span", {
            staticClass: "dialog-footer",
            attrs: { slot: "footer" },
            slot: "footer",
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "预览合同",
            visible: _vm.showpreview,
            width: "1200px",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showpreview = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "max-height": "70vh", "overflow-y": "auto" } },
            [
              _vm.showpreviewType == 1
                ? [
                    _vm.contactImg
                      ? [
                          _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: { src: _vm.contactImg, alt: "" },
                          }),
                        ]
                      : _c("div", { staticClass: "no-data color-black" }, [
                          _vm._v("暂无附件"),
                        ]),
                  ]
                : _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.html_content) },
                  }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "item-flex-center",
              staticStyle: { padding: "20px" },
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.editcontract } },
                [_vm._v("修改合同")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "发送审批", visible: _vm.showreview, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.showreview = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "divBox" },
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "审批人",
                        "label-width": "120px",
                        required: "",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "selwidth",
                          attrs: {
                            placeholder: "请选择审批人",
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.reviewAduitId,
                            callback: function ($$v) {
                              _vm.reviewAduitId = $$v
                            },
                            expression: "reviewAduitId",
                          },
                        },
                        _vm._l(_vm.reductjson.adminData, function (item) {
                          return _c("el-option", {
                            key: item.merchant_admin_id,
                            attrs: {
                              label: item.real_name,
                              value: item.merchant_admin_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", "label-width": "120px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.cursorsendreview },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { attrs: { slot: "footer" }, slot: "footer" }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: { visible: _vm.showconfig, direction: "rtl", size: "450px" },
          on: {
            "update:visible": function ($event) {
              _vm.showconfig = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _vm._l(_vm.showList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: "show_" + index,
                    staticClass: "draw-list margin-t-16",
                    attrs: { draggable: "false" },
                  },
                  [
                    _c("el-checkbox", {
                      staticClass: "checkbox",
                      attrs: { disabled: item.type == "diabled" },
                      model: {
                        value: item.select,
                        callback: function ($$v) {
                          _vm.$set(item, "select", $$v)
                        },
                        expression: "item.select",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm._l(_vm.hideList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: "hide_" + index,
                    staticClass: "draw-list margin-t-16",
                    attrs: { draggable: "false" },
                  },
                  [
                    _c("el-checkbox", {
                      staticClass: "checkbox",
                      attrs: { disabled: item.type == "diabled" },
                      model: {
                        value: item.select,
                        callback: function ($$v) {
                          _vm.$set(item, "select", $$v)
                        },
                        expression: "item.select",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
      _vm._v(" "),
      _c("AddUserCom", { ref: "userREf" }),
      _vm._v(" "),
      _vm.showdetail
        ? [
            _c("userdetail", {
              attrs: { showdetail: _vm.showdetail, row: _vm.row, uid: _vm.uid },
              on: { editMark: _vm.getList, close: _vm.userdetailclose },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "",
            visible: _vm.showProductDetail,
            direction: "rtl",
            size: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showProductDetail = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "drawer-title" }, [
              _c(
                "div",
                {
                  staticClass: "drawer-title-list",
                  class: { active: _vm.drawtype == "detail" },
                  on: {
                    click: function ($event) {
                      return _vm.changedrawtype("detail")
                    },
                  },
                },
                [_vm._v("\n            货品详情\n          ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "drawer-title-list",
                  class: { active: _vm.drawtype == "log" },
                  on: {
                    click: function ($event) {
                      return _vm.changedrawtype("log")
                    },
                  },
                },
                [_vm._v("\n            日志记录\n          ")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "drawer-title-bg" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "drawer-body" },
            [
              _vm.drawtype == "detail"
                ? [
                    _c(
                      "div",
                      { staticClass: "drawer-name flex" },
                      [
                        _c("div", { staticClass: "flex-one mr20" }, [
                          _vm._v(_vm._s(_vm.productdetail.product_name)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.goEditProductAction },
                          },
                          [_vm._v("修改货品")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.productdetail.contract_image &&
                    _vm.productdetail.contract_image.length
                      ? _c("div", { staticClass: "drawer-image-box" }, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#708295",
                                "font-size": "14px",
                              },
                            },
                            [_vm._v("合同图：")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-wrap": "wrap",
                                flex: "1",
                                overflow: "hidden",
                              },
                            },
                            [
                              _vm._l(
                                _vm.productdetail.contract_image,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: "contract_image" + index,
                                      staticClass: "drawer-image",
                                    },
                                    [
                                      _c("el-image", {
                                        attrs: {
                                          src: item + "!120a",
                                          "preview-src-list": [item],
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _vm.productdetail.video_link
                                ? _c("div", { staticClass: "drawer-image" }, [
                                    _c("video", {
                                      attrs: {
                                        src: _vm.productdetail.video_link,
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.productdetail.warehouse_image &&
                    _vm.productdetail.warehouse_image.length
                      ? _c("div", { staticClass: "drawer-image-box" }, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#708295",
                                "font-size": "14px",
                              },
                            },
                            [_vm._v("入库图：")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-wrap": "wrap",
                                flex: "1",
                                overflow: "hidden",
                              },
                            },
                            _vm._l(
                              _vm.productdetail.warehouse_image,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "warehouse_image" + index,
                                    staticClass: "drawer-image",
                                  },
                                  [
                                    _c("el-image", {
                                      attrs: {
                                        src: item + "!120a",
                                        "preview-src-list": [item],
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.productdetail.auction_image &&
                    _vm.productdetail.auction_image.length
                      ? _c("div", { staticClass: "drawer-image-box" }, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#708295",
                                "font-size": "14px",
                              },
                            },
                            [_vm._v("上拍图：")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-wrap": "wrap",
                                flex: "1",
                                overflow: "hidden",
                              },
                            },
                            _vm._l(
                              _vm.productdetail.auction_image,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "auction_image" + index,
                                    staticClass: "drawer-image",
                                  },
                                  [
                                    _c("el-image", {
                                      attrs: {
                                        src: item + "!120a",
                                        "preview-src-list": [item],
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "drawer-text-box",
                        staticStyle: { "margin-top": "10px" },
                      },
                      [
                        _c("div", { staticClass: "drawer-text" }, [
                          _vm._v("\n              货品ID："),
                          _c("span", { staticClass: "color-334" }, [
                            _vm._v(_vm._s(_vm.productdetail.id)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.productdetail.storeCategoryParent
                          ? _c(
                              "div",
                              { staticClass: "drawer-text" },
                              [
                                _vm._v("\n              货品分类："),
                                _vm._l(
                                  _vm.productdetail.storeCategoryParent,
                                  function (item, index) {
                                    return _c(
                                      "span",
                                      {
                                        key: "cateparent" + index,
                                        staticClass: "color-334",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(item.cate_name) +
                                            "\n              "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "drawer-text" }, [
                          _vm._v("\n              作者/品牌："),
                          _vm.productdetail.brand
                            ? _c("span", { staticClass: "color-334" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.productdetail.brand.brand_name || ""
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "drawer-text" }, [
                          _vm._v("\n              创建时间："),
                          _c("span", { staticClass: "color-334" }, [
                            _vm._v(_vm._s(_vm.productdetail.create_time)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "drawer-text" }, [
                          _vm._v("\n              录入人员："),
                          _vm.productdetail.createAdmin
                            ? _c("span", { staticClass: "color-334" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.productdetail.createAdmin.real_name ||
                                      ""
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "drawer-text" }, [
                          _vm._v("\n              货品属性："),
                          _vm.productdetail.attribute
                            ? _c(
                                "span",
                                { staticClass: "color-334" },
                                _vm._l(
                                  _vm.productdetail.attribute,
                                  function (m, i) {
                                    return _c("span", { key: "attr_" + i }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(m + "/" || "") +
                                          "\n              "
                                      ),
                                    ])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "drawer-text" }, [
                          _vm._v("\n              货品标签："),
                          _vm.productdetail.labelNames &&
                          _vm.productdetail.labelNames.length
                            ? _c(
                                "span",
                                { staticClass: "color-334" },
                                _vm._l(
                                  _vm.productdetail.labelNames,
                                  function (item, index) {
                                    return _c(
                                      "span",
                                      { key: "label_" + index },
                                      [
                                        _vm._v(
                                          "#" +
                                            _vm._s(item) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "drawer-list" }, [
                      _c("div", { staticClass: "drawer-list-left" }, [
                        _vm._v("货品介绍"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "drawer-list-right" }, [
                        _vm.productdetail.content
                          ? _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.productdetail.content),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.drawtype != "log"
                ? _c("div", { staticClass: "drawer-body-title" }, [
                    _vm._v("时间轴"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.drawtype != "log"
                ? _c(
                    "div",
                    { staticClass: "timeline-box" },
                    _vm._l(_vm.timelog, function (item, index) {
                      return _c(
                        "div",
                        { key: "timelog_" + index, staticClass: "timeline" },
                        [
                          _c("div", { staticClass: "timeline-icon" }, [
                            _c("img", {
                              attrs: {
                                src: "https://saas.cdn.yunzongbu.cn/image/20220119/677fae73c3e1c270cae37b6f76d23f74.png",
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "timeline-content",
                              class: index % 2 == 0 ? "left" : "right",
                            },
                            [
                              index % 2 == 0
                                ? _c(
                                    "div",
                                    { staticClass: "timeline-oneline" },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.create_time) + " "),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "timeline-title" },
                                        [_vm._v(_vm._s(item.typeText))]
                                      ),
                                      _vm._v(" "),
                                      item.category == "pm"
                                        ? _c(
                                            "span",
                                            { staticClass: "timeline-title" },
                                            [
                                              _vm._v(
                                                "：" +
                                                  _vm._s(
                                                    item.linkItem.album
                                                      .album_name
                                                  )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "timeline-oneline" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "timeline-title" },
                                        [_vm._v(_vm._s(item.typeText) + " ")]
                                      ),
                                      _vm._v(" "),
                                      item.category == "pm"
                                        ? _c(
                                            "span",
                                            { staticClass: "timeline-title" },
                                            [
                                              _vm._v(
                                                "：" +
                                                  _vm._s(
                                                    item.linkItem.album
                                                      .album_name
                                                  )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          " " + _vm._s(item.create_time) + " "
                                        ),
                                      ]),
                                    ]
                                  ),
                              _vm._v(" "),
                              index % 2 == 0
                                ? _c(
                                    "div",
                                    { staticClass: "timeline-twoline" },
                                    [
                                      item.category == "push_warehouse"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "timeline-handle",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.prieviewtype(
                                                    "push_warehouse",
                                                    item.link_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "入库单 " +
                                                  _vm._s(
                                                    item.linkItem
                                                      ? item.linkItem.order_no
                                                      : ""
                                                  )
                                              ),
                                            ]
                                          )
                                        : item.category == "out_warehouse"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "timeline-handle",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.prieviewtype(
                                                    "out_warehouse",
                                                    item.link_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "出库单 " +
                                                  _vm._s(
                                                    item.linkItem
                                                      ? item.linkItem.order_no
                                                      : ""
                                                  )
                                              ),
                                            ]
                                          )
                                        : item.category == "entrust"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "timeline-handle",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.prieviewtype(
                                                    "entrust",
                                                    item.link_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "委托单 " +
                                                  _vm._s(
                                                    item.linkItem
                                                      ? item.linkItem.no
                                                      : ""
                                                  )
                                              ),
                                            ]
                                          )
                                        : item.category == "contract"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "timeline-handle",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.prieviewtype(
                                                    "contract",
                                                    item.link_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "合同 " +
                                                  _vm._s(
                                                    item.linkItem
                                                      ? item.linkItem.no
                                                      : ""
                                                  )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.linkItem && item.linkItem.credentials
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "timeline-handle",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.prieviewimg(
                                                    item.linkItem.credentials
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("查看凭证")]
                                          )
                                        : _vm._e(),
                                      _vm._v("\n                操作人："),
                                      item.createAdmin
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(item.createAdmin.real_name)
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "timeline-twoline" },
                                    [
                                      _vm._v("\n                操作人："),
                                      item.createAdmin
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(item.createAdmin.real_name)
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.category == "push_warehouse"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "timeline-handle",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.prieviewtype(
                                                    "push_warehouse",
                                                    item.link_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "入库单 " +
                                                  _vm._s(
                                                    item.linkItem
                                                      ? item.linkItem.order_no
                                                      : ""
                                                  )
                                              ),
                                            ]
                                          )
                                        : item.category == "out_warehouse"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "timeline-handle",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.prieviewtype(
                                                    "out_warehouse",
                                                    item.link_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "出库单 " +
                                                  _vm._s(
                                                    item.linkItem
                                                      ? item.linkItem.order_no
                                                      : ""
                                                  )
                                              ),
                                            ]
                                          )
                                        : item.category == "entrust"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "timeline-handle",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.prieviewtype(
                                                    "entrust",
                                                    item.link_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "委托单 " +
                                                  _vm._s(
                                                    item.linkItem
                                                      ? item.linkItem.no
                                                      : ""
                                                  )
                                              ),
                                            ]
                                          )
                                        : item.category == "contract"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "timeline-handle",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.prieviewtype(
                                                    "contract",
                                                    item.link_id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "合同 " +
                                                  _vm._s(
                                                    item.linkItem
                                                      ? item.linkItem.no
                                                      : ""
                                                  )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.linkItem && item.linkItem.credentials
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "timeline-handle",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.prieviewimg(
                                                    item.linkItem.credentials
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("查看凭证")]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.drawtype == "log"
                ? [
                    _vm.catelog.pm
                      ? _c("div", { staticClass: "drawer-list" }, [
                          _c("div", { staticClass: "drawer-list-left" }, [
                            _vm._v("上拍记录"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "drawer-list-right" },
                            _vm._l(_vm.catelog.pm, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: "pm_" + index,
                                  staticClass: "drawer-l-r-list",
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(item.create_time)),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "drawer-l-r-split" },
                                    [_vm._v("/")]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(item.linkItem.venue.title)),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "drawer-l-r-split" },
                                    [_vm._v("/")]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(item.linkItem.album.album_name)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "drawer-l-r-split" },
                                    [_vm._v("/")]
                                  ),
                                  _vm._v(" "),
                                  item.linkItem.auction.auction_status == -1
                                    ? _c("span", [_vm._v("已违约")])
                                    : item.linkItem.auction.auction_status == 1
                                    ? _c("span", [_vm._v("进行中")])
                                    : item.linkItem.auction.auction_status == 2
                                    ? _c("span", [_vm._v("预展中")])
                                    : item.linkItem.auction.auction_status == 3
                                    ? _c("span", [_vm._v("拍卖结束")])
                                    : item.linkItem.auction.auction_status == 4
                                    ? _c("span", [_vm._v("流拍")])
                                    : item.linkItem.auction.auction_status == 5
                                    ? _c("span", [_vm._v("成交待付款")])
                                    : item.linkItem.auction.auction_status == 6
                                    ? _c("span", [_vm._v("成交已付款")])
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.catelog.product
                      ? _c("div", { staticClass: "drawer-list" }, [
                          _c("div", { staticClass: "drawer-list-left" }, [
                            _vm._v("货品档案"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "drawer-list-right" },
                            _vm._l(_vm.catelog.product, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: "push_" + index,
                                  staticClass: "drawer-l-r-list",
                                },
                                [
                                  item.createAdmin
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(item.createAdmin.real_name)
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "drawer-l-r-split" },
                                    [_vm._v("/")]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(item.create_time)),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "drawer-l-r-split" },
                                    [_vm._v("/")]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(item.typeText) + " "),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.catelog.entrust
                      ? _c("div", { staticClass: "drawer-list" }, [
                          _c("div", { staticClass: "drawer-list-left" }, [
                            _vm._v("委托单"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "drawer-list-right" },
                            _vm._l(_vm.catelog.entrust, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: "push_" + index,
                                  staticClass: "drawer-l-r-list",
                                },
                                [
                                  item.creator
                                    ? _c("span", [
                                        _vm._v(_vm._s(item.creator.real_name)),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "drawer-l-r-split" },
                                    [_vm._v("/")]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(item.create_time)),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "drawer-l-r-split" },
                                    [_vm._v("/")]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(item.typeText) + " "),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "drawer-l-r-split" },
                                    [_vm._v("/")]
                                  ),
                                  _vm._v(" "),
                                  item.linkItem && item.linkItem.no
                                    ? _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.prieviewtype(
                                                "entrust",
                                                item.link_id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(item.linkItem.no))]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.catelog.contract
                      ? _c("div", { staticClass: "drawer-list" }, [
                          _c("div", { staticClass: "drawer-list-left" }, [
                            _vm._v("合同"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "drawer-list-right" },
                            _vm._l(
                              _vm.catelog.contract,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "push_" + index,
                                    staticClass: "drawer-l-r-list",
                                  },
                                  [
                                    item.createAdmin
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(item.createAdmin.real_name)
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.create_time)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.typeText) + " "),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    item.linkItem && item.linkItem.no
                                      ? _c(
                                          "span",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.prieviewtype(
                                                  "contract",
                                                  item.link_id
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(item.linkItem.no))]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.catelog.enter_warehouse
                      ? _c("div", { staticClass: "drawer-list" }, [
                          _c("div", { staticClass: "drawer-list-left" }, [
                            _vm._v("入库单"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "drawer-list-right" },
                            _vm._l(
                              _vm.catelog.enter_warehouse,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "push_" + index,
                                    staticClass: "drawer-l-r-list",
                                  },
                                  [
                                    item.createAdmin
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(item.createAdmin.real_name)
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.create_time)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.typeText) + " "),
                                    ]),
                                    _vm._v(" "),
                                    item.linkItem && item.linkItem.order_no
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "drawer-l-r-handle",
                                            on: {
                                              click: function ($event) {
                                                return _vm.prieviewtype(
                                                  "push_warehouse",
                                                  item.link_id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.linkItem.order_no)
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    item.linkItem && item.linkItem.pushWarehouse
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.linkItem.pushWarehouse.name
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.catelog.push_shelf
                      ? _c("div", { staticClass: "drawer-list" }, [
                          _c("div", { staticClass: "drawer-list-left" }, [
                            _vm._v("入位"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "drawer-list-right" },
                            _vm._l(
                              _vm.catelog.push_shelf,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "push_" + index,
                                    staticClass: "drawer-l-r-list",
                                  },
                                  [
                                    item.creator
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(item.creator.real_name)
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.create_time)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.typeText) + " "),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    item.linkItem.credentials
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "drawer-l-r-handle",
                                            on: {
                                              click: function ($event) {
                                                return _vm.prieviewimg(
                                                  item.linkItem.credentials
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("查看凭证")]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.catelog.stock_take
                      ? _c("div", { staticClass: "drawer-list" }, [
                          _c("div", { staticClass: "drawer-list-left" }, [
                            _vm._v("盘点记录"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "drawer-list-right" },
                            _vm._l(
                              _vm.catelog.stock_take,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "stock_" + index,
                                    staticClass: "drawer-l-r-list",
                                  },
                                  [
                                    item.creator
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(item.creator.real_name)
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.create_time)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          item.linkItem.abnormal_status == 1
                                            ? "盘点异常"
                                            : "盘点正常"
                                        ) + "\n                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    item.linkItem.credentials
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "drawer-l-r-handle",
                                            on: {
                                              click: function ($event) {
                                                return _vm.prieviewimg(
                                                  item.linkItem.credentials
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("查看存证")]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.catelog.out_warehouse
                      ? _c("div", { staticClass: "drawer-list" }, [
                          _c("div", { staticClass: "drawer-list-left" }, [
                            _vm._v("出库单"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "drawer-list-right" },
                            _vm._l(
                              _vm.catelog.out_warehouse,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "out_" + index,
                                    staticClass: "drawer-l-r-list",
                                  },
                                  [
                                    item.creator
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(item.creator.real_name)
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.create_time)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(item.typeText) + " "),
                                    ]),
                                    _vm._v(" "),
                                    item.linkItem && item.linkItem.order_no
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "drawer-l-r-handle",
                                            on: {
                                              click: function ($event) {
                                                return _vm.prieviewtype(
                                                  "out_warehouse",
                                                  item.link_id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.linkItem.order_no)
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "drawer-l-r-split" },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    item.linkItem && item.linkItem.outWarehouse
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.linkItem.outWarehouse.name
                                            ) + "\n                "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.title || "添加货品",
            visible: _vm.showgoodsadd,
            direction: "rtl",
            size: "1000px",
            "before-close": _vm.beforeclose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showgoodsadd = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate mt20",
              staticStyle: { "overflow-x": "hidden", padding: "0 12px" },
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "货品名称：", prop: "product_name" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "selWidth",
                            attrs: { placeholder: "请输入货品名称" },
                            model: {
                              value: _vm.formValidate.product_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "product_name", $$v)
                              },
                              expression: "formValidate.product_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "拍品名称：", prop: "auction_name" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "selWidth",
                            attrs: { placeholder: "请输入拍品名称" },
                            model: {
                              value: _vm.formValidate.auction_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "auction_name", $$v)
                              },
                              expression: "formValidate.auction_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "库号：", prop: "product_no" } },
                        [
                          _c("el-input", {
                            staticClass: "selWidth",
                            attrs: { placeholder: "请输入库号：" },
                            model: {
                              value: _vm.formValidate.product_no,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "product_no", $$v)
                              },
                              expression: "formValidate.product_no",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "货品分类：", prop: "cate_id" } },
                        [
                          _c("el-cascader", {
                            staticClass: "selWidth",
                            attrs: {
                              options: _vm.merCateList,
                              props: _vm.props,
                              filterable: "",
                              clearable: "",
                            },
                            on: { change: _vm.handleChange },
                            model: {
                              value: _vm.formValidate.cate_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "cate_id", $$v)
                              },
                              expression: "formValidate.cate_id",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "属性", required: "" } },
                        _vm._l(_vm.attrList, function (item, index) {
                          return _c(
                            "el-form-item",
                            {
                              key: "attrr" + index,
                              style: index > 0 ? "margin-top:20px" : "",
                              attrs: { label: item.attr_name },
                            },
                            [
                              item.attr_type == 1
                                ? [
                                    _c("el-input", {
                                      attrs: { placeholder: "请输入" },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    }),
                                  ]
                                : item.attr_type == 2
                                ? [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.value,
                                          callback: function ($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value",
                                        },
                                      },
                                      _vm._l(item.attrValue, function (m, i) {
                                        return _c(
                                          "el-radio",
                                          {
                                            key: "attrv" + i,
                                            attrs: { label: m.attr_value_id },
                                          },
                                          [_vm._v(_vm._s(m.attr_value_name))]
                                        )
                                      }),
                                      1
                                    ),
                                  ]
                                : [
                                    _c(
                                      "el-checkbox-group",
                                      {
                                        model: {
                                          value: item.value,
                                          callback: function ($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value",
                                        },
                                      },
                                      _vm._l(item.attrValue, function (m, i) {
                                        return _c(
                                          "el-checkbox",
                                          {
                                            key: "mattr" + i,
                                            attrs: { label: m.attr_value_id },
                                          },
                                          [_vm._v(_vm._s(m.attr_value_name))]
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                            ],
                            2
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "货品标签：", prop: "label" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "selWidth",
                              attrs: {
                                filterable: "",
                                placeholder: "请选择",
                                multiple: "",
                              },
                              model: {
                                value: _vm.formValidate.label,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "label", $$v)
                                },
                                expression: "formValidate.label",
                              },
                            },
                            _vm._l(_vm.labelarr, function (item) {
                              return _c("el-option", {
                                key: item.label_id,
                                attrs: {
                                  label: item.label_name,
                                  value: item.label_id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.title == "编辑货品"
                    ? _c(
                        "el-col",
                        _vm._b({}, "el-col", _vm.grid2, false),
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "保留价：",
                                prop: "retain_price",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "selWidth",
                                attrs: {
                                  disabled: "",
                                  placeholder: "请输入货品保留价",
                                },
                                model: {
                                  value: _vm.formValidate.retain_price,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "retain_price",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.retain_price",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "wrap",
                          attrs: { label: "入库图", prop: "warehouse_image" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "acea-row" },
                            [
                              _vm._l(
                                _vm.formValidate.warehouse_image,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "pictrue",
                                      attrs: { draggable: "false" },
                                      on: {
                                        dragstart: function ($event) {
                                          return _vm.handleDragStart(
                                            $event,
                                            item
                                          )
                                        },
                                        dragover: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleDragOver(
                                            $event,
                                            item
                                          )
                                        },
                                        dragenter: function ($event) {
                                          return _vm.handleDragEnter(
                                            $event,
                                            item
                                          )
                                        },
                                        dragend: function ($event) {
                                          return _vm.handleDragEnd($event, item)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", { attrs: { src: item } }),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "el-icon-error btndel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemove(
                                              "warehouse_image",
                                              index
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "uploadCont",
                                  attrs: { title: "750*750px" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "upLoadPicBox",
                                      on: {
                                        click: function ($event) {
                                          return _vm.modalPicTap("3")
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "wrap", attrs: { label: "合同图" } },
                        [
                          _c(
                            "div",
                            { staticClass: "acea-row" },
                            [
                              _vm._l(
                                _vm.formValidate.contract_image,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "pictrue",
                                      attrs: { draggable: "false" },
                                      on: {
                                        dragstart: function ($event) {
                                          return _vm.handleDragStart(
                                            $event,
                                            item
                                          )
                                        },
                                        dragover: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleDragOver(
                                            $event,
                                            item
                                          )
                                        },
                                        dragenter: function ($event) {
                                          return _vm.handleDragEnter(
                                            $event,
                                            item
                                          )
                                        },
                                        dragend: function ($event) {
                                          return _vm.handleDragEnd($event, item)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", { attrs: { src: item } }),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "el-icon-error btndel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemove(
                                              "contract_image",
                                              index
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _vm.formValidate.contract_image.length < 10
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "uploadCont",
                                      attrs: { title: "750*750px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "upLoadPicBox",
                                          on: {
                                            click: function ($event) {
                                              return _vm.modalPicTap("2")
                                            },
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "upLoad" }, [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-camera cameraIconfont",
                                            }),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "wrap", attrs: { label: "拍品图" } },
                        [
                          _c(
                            "div",
                            { staticClass: "acea-row" },
                            [
                              _vm._l(
                                _vm.formValidate.auction_image,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "pictrue",
                                      attrs: { draggable: "false" },
                                      on: {
                                        dragstart: function ($event) {
                                          return _vm.handleDragStart(
                                            $event,
                                            item
                                          )
                                        },
                                        dragover: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleDragOver(
                                            $event,
                                            item
                                          )
                                        },
                                        dragenter: function ($event) {
                                          return _vm.handleDragEnter(
                                            $event,
                                            item
                                          )
                                        },
                                        dragend: function ($event) {
                                          return _vm.handleDragEnd($event, item)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", { attrs: { src: item } }),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "el-icon-error btndel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemove(
                                              "auction_image",
                                              index
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _vm.formValidate.auction_image.length < 10
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "uploadCont",
                                      attrs: { title: "750*750px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "upLoadPicBox",
                                          on: {
                                            click: function ($event) {
                                              return _vm.modalPicTap("4")
                                            },
                                          },
                                        },
                                        [
                                          _c("div", { staticClass: "upLoad" }, [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-camera cameraIconfont",
                                            }),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "主图视频：", prop: "video_link" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "50%" },
                            attrs: { placeholder: "请输入视频链接" },
                            model: {
                              value: _vm.videoLink,
                              callback: function ($$v) {
                                _vm.videoLink = $$v
                              },
                              expression: "videoLink",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "uploadVideo",
                              attrs: {
                                type: "primary",
                                icon: "ios-cloud-upload-outline",
                              },
                              on: { click: _vm.zh_uploadFile_change },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.videoLink ? "确认添加" : "上传视频")
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.upload.videoIng
                            ? _c("el-progress", {
                                attrs: {
                                  percentage: _vm.progress,
                                  "text-inside": true,
                                  "stroke-width": 26,
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.formValidate.video_link
                            ? _c(
                                "div",
                                { staticClass: "iview-video-style mt20" },
                                [
                                  _c(
                                    "video",
                                    {
                                      staticStyle: {
                                        width: "200px",
                                        "border-radius": "10px",
                                      },
                                      attrs: {
                                        src: _vm.formValidate.video_link,
                                        controls: "controls",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  您的浏览器不支持 video 标签。\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "mark" }),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "el-icon-delete iconv",
                                    on: { click: _vm.delVideo },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 23 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "货品详情：" } },
                        [
                          _c("el-input", {
                            staticStyle: { "margin-right": "10px" },
                            attrs: {
                              placeholder: "请输入货品详情",
                              size: "normal",
                              rows: "8",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.formValidate.content,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "content", $$v)
                              },
                              expression: "formValidate.content",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "submission",
                      staticStyle: { width: "200px" },
                      attrs: {
                        loading: _vm.loading,
                        type: "primary",
                        size: "small",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }