"use strict";

var _interopRequireDefault = require("/root/workspace/merchant_UEas/admin_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _auction = require("@/api/auction");
var _index = require("@/utils/index");
var _user = require("@/api/user");
var _webSocket = _interopRequireDefault(require("@/utils/webSocket"));
var _userdetail = _interopRequireDefault(require("../../user/list/userdetail"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    userdetail: _userdetail.default
  },
  data: function data() {
    return {
      parseTime: _index.parseTime,
      formatPriceNumber: _index.formatPriceNumber,
      venue_id: '',
      albumlist: [],
      albumId: '',
      listLoading: false,
      list: [],
      sdata: {
        socketServe: _webSocket.default.Instance
      },
      showdetail: null,
      uid: '',
      queryParams: {
        page: 1,
        limit: 20,
        album_id: ''
      },
      total: 0,
      // 委托出价
      proxyData: {
        uid: '',
        price: null,
        auctionId: '',
        show: false,
        tuluhao: '',
        title: '',
        btnLoading: false,
        anonymous: 1,
        bid_type: 1
      },
      searchLoading: false,
      userList: [],
      showBidRecord: false,
      showLoading: false,
      showBidRecordData: {
        data: {
          product: {}
        },
        list: [],
        idsList: []
      },
      albumData: {},
      allCzArray: [],
      proxyBidData: {
        show: false,
        num: 0,
        uv: 0,
        list: [],
        page: 1,
        limit: 20,
        total: 0
      },
      albumVisitData: {
        show: false,
        uv: 0,
        num: 0,
        list: []
      },
      auctionVisitData: {
        show: false,
        store_name: '',
        lot: '',
        list: []
      },
      albumRelationData: {
        show: false,
        uv: 0,
        num: 0,
        list: []
      },
      otherTotal: 0,
      query: {
        page: 1,
        limit: 20
      },
      showNumberList: [],
      changeUser: {},
      searchQuery: {},
      isNo: true,
      liveCount: 0,
      priceData: [{
        min: 0,
        max: '∞',
        price: 100000,
        type: 1
      }],
      changeM: {}
    };
  },
  created: function created() {
    this.liveCount = Number(this.$route.query.liveCount);
  },
  mounted: function mounted() {
    var _this = this;
    this.venue_id = this.$route.query.vid || '';
    this.albumId = this.$route.query.zcid || '';
    if (this.venue_id) {
      (0, _auction.albumOptionList)({
        page: 1,
        limit: 100,
        venue_id: this.venue_id
      }).then(function (res) {
        console.log(res.data.list);
        _this.albumlist = res.data.list;
        if (!_this.albumId) {
          _this.albumId = res.data.list[0].album_id;
          _this.isNo = res.data.list[0].album_status != 3;
        }
        _this.loadAlbumDetail();
        _this.loadAuction();
      });
    }
    this.delayRequest();
    (0, _index.countDown)(99999, function () {
      if (_this.list && _this.list.length > 0) {
        for (var key in _this.list) {
          var m = _this.list[key];
          if (m.auction_status === 1 && m.end_time || m.auction_status === 2 && m.start_time) {
            var time = m.auction_status === 1 ? m.end_time : m.start_time;
            var newD = Date.parse(new Date()) / 1000;
            var goTime = time - newD;
            var day = parseInt(goTime / 3600 / 24);
            var hour = parseInt(Math.floor(goTime % (3600 * 24)) / 3600);
            var minite = Math.floor(goTime / 60 % 60);
            var second = Math.floor(goTime % 60);
            if (!day && !hour && !minite && !second) {
              if (m.auction_status === 1) {
                _this.list[key].auction_status = 5;
              } else if (m.auction_status === 2) {
                _this.list[key].auction_status = 1;
              }
            }
            if (goTime === 5) {
              _this.loadAuction(1, 1);
            }
            _this.list[key].time = {
              goTime: goTime,
              day: day,
              hour: hour,
              minite: minite,
              second: second
            };
          }
        }
      }
    }, function (setinter) {
      _this.inter = setinter;
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (this.inter) clearInterval(this.inter);
    if (this.albumTimeout) clearTimeout(this.albumTimeout);
  },
  methods: {
    onUseAllNumber: function onUseAllNumber() {
      var _this2 = this;
      (0, _auction.recoverAdminBidPriceStatusApi)({
        venue_id: this.proxyData.venue_id
      }).then(function (res) {
        _this2.loadNumberList(_this2.proxyData.auctionId, _this2.proxyData.venue_id);
      });
    },
    onCloseNumber: function onCloseNumber(row) {
      var _this3 = this;
      // console.log(row,this.proxyData);
      (0, _auction.switchAdminBidPriceStatusApi)(row.id, {
        status: 0
      }).then(function (res) {
        _this3.loadNumberList(_this3.proxyData.auctionId, _this3.proxyData.venue_id);
      });
    },
    searchAction: function searchAction(e) {
      this.searchQuery = e;
      this.queryParams = Object.assign({
        album_id: this.queryParams.album_id,
        page: 1,
        limit: this.queryParams.limit
      }, e);
      this.loadAuction();
    },
    onDetails: function onDetails(uid) {
      this.uid = uid;
      // this.visibleDetail = true
      this.showdetail = true;
    },
    userdetailclose: function userdetailclose() {
      this.showdetail = null;
    },
    showAlbumRelationDataAction: function showAlbumRelationDataAction() {
      this.otherTotal = 0;
      this.albumRelationData.show = true;
      this.albumRelationData.uv = this.albumData.album_care_count.uv;
      this.albumRelationData.num = this.albumData.album_care_count.num;
      this.query = {
        page: 1,
        limit: 20,
        album_id: this.albumId
      };
      this.loadAlbumRelationData();
    },
    loadAlbumRelationData: function loadAlbumRelationData() {
      var _this4 = this;
      (0, _auction.albumRelationListApi)(this.query).then(function (res) {
        _this4.albumRelationData.list = res.data.list;
        _this4.otherTotal = res.data.count;
      }).catch(function () {});
    },
    showAuctionVisitDataAction: function showAuctionVisitDataAction(m) {
      this.otherTotal = 0;
      this.auctionVisitData.show = true;
      this.auctionVisitData.lot = m.lot;
      this.auctionVisitData.store_name = m.product.store_name;
      this.query = {
        page: 1,
        limit: 20,
        auction_id: m.product_id
      };
      this.loadAuctionVisitData();
    },
    loadAuctionVisitData: function loadAuctionVisitData() {
      var _this5 = this;
      (0, _auction.auctionVisitListApi)(this.query).then(function (res) {
        _this5.auctionVisitData.list = res.data.list;
        _this5.otherTotal = res.data.count;
      }).catch(function () {});
    },
    showAlbumVisitDataAction: function showAlbumVisitDataAction() {
      this.otherTotal = 0;
      this.albumVisitData.show = true;
      this.albumVisitData.uv = this.albumData.click_num_uv;
      this.albumVisitData.num = this.albumData.click_num_uv_product;
      this.query = {
        page: 1,
        limit: 20,
        album_id: this.albumId
      };
      this.loadAlbumVisitData();
    },
    loadAlbumVisitData: function loadAlbumVisitData() {
      var _this6 = this;
      (0, _auction.albumVisitListApi)(this.query).then(function (res) {
        _this6.albumVisitData.list = res.data.list;
        _this6.otherTotal = res.data.count;
      }).catch(function () {});
    },
    pageOtherChange: function pageOtherChange(page) {
      this.query.page = page;
      if (this.albumVisitData.show) {
        this.loadAlbumVisitData();
      } else if (this.auctionVisitData.show) {
        this.loadAuctionVisitData();
      } else if (this.albumRelationData.show) {
        this.loadAlbumRelationData();
      }
    },
    showProxyBidDataAction: function showProxyBidDataAction() {
      this.proxyBidData.show = true;
      this.proxyBidData.uv = this.albumData.proxy_bid_uv;
      this.proxyBidData.num = this.albumData.proxy_bid_num;
      // let model = {
      //   page: 1,
      //   limit: 20,
      //   album_id: this.albumId
      // };
      this.loadProxyBidData();
    },
    loadProxyBidData: function loadProxyBidData() {
      var _this7 = this;
      var model = {
        page: this.proxyBidData.page,
        limit: this.proxyBidData.limit,
        album_id: this.albumId
      };
      (0, _auction.proxyBidListApi)(model).then(function (res) {
        _this7.proxyBidData.list = res.data.list;
        _this7.proxyBidData.total = res.data.count;
      }).catch(function () {});
    },
    proxyPageChange: function proxyPageChange(val) {
      this.proxyBidData.page = val;
      this.loadProxyBidData();
    },
    delayRequest: function delayRequest() {
      var _this8 = this;
      this.albumTimeout = setTimeout(function () {
        _this8.loadAlbumDetail(1);
        _this8.loadAuction(1, 1);
        _this8.delayRequest();
      }, 10000);
    },
    loadAlbumDetail: function loadAlbumDetail(refresh) {
      var _this9 = this;
      (0, _auction.albumDetail)(this.albumId).then(function (res) {
        _this9.albumData = res.data;
        if (_this9.albumData.album_status === 2 && !refresh) {
          if (!_webSocket.default.Instance.ws) {
            _webSocket.default.Instance.connect();
            _this9.sdata.socketServe = _webSocket.default.Instance;
            _this9.sdata.socketServe.registerCallBack('getMessage', function (data) {
              _this9.sockedMes(data);
            });
            _this9.sdata.socketServe.registerCallBack('openSocket', function () {});
          } else if (_this9.client_id) {
            _this9.joinRoomAction();
          }
        }
        if (_this9.albumData.album_status !== 2) {
          if (_this9.albumTimeout) clearTimeout(_this9.albumTimeout);
        }
      });
    },
    loadAuction: function loadAuction(refresh, noLoading) {
      var _this10 = this;
      this.queryParams.album_id = this.albumId;
      if (!noLoading) {
        this.listLoading = true;
      }
      (0, _auction.statisticalAuctionListApi)(this.queryParams).then(function (res) {
        if (refresh) {
          var auctionIds = res.data.list.map(function (m) {
            return m.product_id;
          });
          _this10.list.forEach(function (m, i) {
            var index = auctionIds.indexOf(m.product_id);
            if (index !== -1) {
              var item = res.data.list[index];
              _this10.list[i].online_num = item.online_num;
              _this10.list[i].bid_num_uv = item.bid_num_uv;
              _this10.list[i].bid_num = item.bid_num;
              _this10.list[i].click_uv = item.click_uv;
              _this10.list[i].now_price = item.now_price;
              _this10.list[i].start_time = item.start_time;
              _this10.list[i].end_time = item.end_time;
              _this10.list[i].auction_status = item.auction_status;
              _this10.list[i].user = item.user;
              if (item.number) {
                _this10.list[i].number = item.number;
              }
            }
          });
        } else {
          res.data.list.forEach(function (m) {
            m.time = {
              goTime: '',
              day: '',
              hour: '',
              minite: '',
              second: ''
            };
          });
          _this10.total = res.data.count;
          _this10.list = res.data.list;
        }
        if (!noLoading) {
          _this10.listLoading = false;
        }
      });
    },
    changeSelect: function changeSelect(val) {
      var albumRow = this.albumlist.find(function (item) {
        return item.album_id == val;
      });
      this.isNo = albumRow.album_status != 3;
      if (this.albumTimeout) clearTimeout(this.albumTimeout);
      this.loadAlbumDetail();
      this.loadAuction();
      this.delayRequest();
    },
    proxyBidAction: function proxyBidAction(m) {
      this.loadNumberList(m.product_id, m.venue_id);
      // 委托出价点击
      this.changeM = Object.assign({}, m);
      this.proxyData = {
        uid: '',
        price: null,
        auctionId: m.product_id,
        show: true,
        tuluhao: m.lot,
        title: m.product.store_name,
        btnLoading: false,
        anonymous: 1,
        venue_id: m.venue_id,
        bid_type: 1
      };
      this.initPriceRule(m);
      this.initPrice(m);
      // this.setNextPrice();
    },
    clearProxyBid: function clearProxyBid() {
      // 取消委托出价
      this.proxyData.show = false;
    },
    proxyBidConfirmAction: function proxyBidConfirmAction() {
      var _this11 = this;
      // 确认委托出价
      this.proxyData.btnLoading = true;
      var action = _auction.proxyBidConfirm;
      if (this.proxyData.bid_type === 1) {
        action = _auction.bidPrice;
      }
      action({
        auction_id: this.proxyData.auctionId,
        bid_uid: this.changeUser.uid || this.proxyData.uid,
        bid_price: this.proxyData.price,
        name_hidden: this.proxyData.anonymous
      }).then(function (res) {
        _this11.$message.success('设置成功');
        _this11.loadAuction(1);
        _this11.proxyData.btnLoading = false;
        _this11.clearProxyBid();
      }).catch(function (message) {
        _this11.proxyData.btnLoading = false;
      });
    },
    searchUserAction: function searchUserAction(data) {
      var _this12 = this;
      var params = {
        keyword: data,
        page: 1,
        limit: 20
      };
      (0, _user.userLstApi)(params).then(function (res) {
        _this12.userList = res.data.list;
      });
    },
    showRecordAction: function showRecordAction(product) {
      this.showBidRecordData = {
        data: product,
        list: []
      };
      this.showBidRecord = true;
      this.loadRecordAction();
    },
    loadRecordAction: function loadRecordAction() {
      var _this13 = this;
      this.showLoading = true;
      (0, _auction.auctionBidRecordApi)({
        auction_id: this.showBidRecordData.data.product_id
      }).then(function (res) {
        _this13.showBidRecordData.idsList = res.data.map(function (m) {
          return m.user ? m.user.uid : '';
        });
        _this13.showBidRecordData.list = res.data;
        if (_this13.isLock) {
          _this13.allCzArray = [];
          _this13.isLock = false;
        }
        _this13.showLoading = false;
      });
    },
    sockedMes: function sockedMes(d) {
      if (d.type === 'init') {
        // 初始化
        this.client_id = d.client_id;
        this.joinRoomAction();
      } else if (d.type === 'auction_bid' || d.type === 'auction_bid_proxy') {
        if (this.showBidRecord && this.showBidRecordData.data && this.showBidRecordData.data.product_id) {
          var record = d.type === 'auction_bid_proxy' ? d.data.bid_record_list[0] : d.data.bid_record;
          this.allCzArray.push(record);
          this.setData();
        }
      }
    },
    setData: function setData() {
      if (this.allCzArray[0] && !this.isLock) {
        this.isLock = true;
        var record = JSON.parse(JSON.stringify(this.allCzArray[0]));
        this.allCzArray.splice(0, 1);
        var index = this.showBidRecordData.idsList.indexOf(record.uid);
        if (index === -1) {
          this.loadRecordAction();
        } else {
          this.showBidRecordData.list[index].bid_record = [record].concat(this.showBidRecordData.list[index].bid_record);
          var item = JSON.parse(JSON.stringify(this.showBidRecordData.list[index]));
          this.showBidRecordData.list.splice(index, 1);
          this.showBidRecordData.list.splice(0, 0, item);
          this.showBidRecordData.idsList = this.showBidRecordData.list.map(function (m) {
            return m.user.uid;
          });
          this.isLock = false;
          this.setData();
        }
      }
    },
    joinRoomAction: function joinRoomAction() {
      (0, _auction.joinRoom)({
        album_id: this.albumId,
        client_id: this.client_id
      }).then(function (res) {}).catch(function () {});
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.loadAuction();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.queryParams.limit = val;
      this.loadAuction();
    },
    loadNumberList: function loadNumberList(id, vId) {
      var _this14 = this;
      this.changeUser = {};
      this.showNumberList = [];
      (0, _auction.numberPlateUserList)({
        auction_id: id,
        page: 1,
        limit: 20,
        number_type: 2,
        venue_id: vId,
        is_admin_bid_price: 1
      }).then(function (res) {
        _this14.showNumberList = res.data.list;
      }).catch(function () {});
    },
    clickNumberAction: function clickNumberAction(m) {
      this.changeUser = m;
    },
    goLiveUpAction: function goLiveUpAction(m) {
      var _this15 = this;
      this.$confirm('确认置顶此拍品？', '提示', {
        confirmButtonText: '置顶',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _auction.setLiveTopAuction)({
          auction_id: m.product_id
        }).then(function () {
          _this15.$message.success('置顶成功');
          // this.load();
          _this15.list.forEach(function (item) {
            item.is_live_top = false;
          });
          m.is_live_top = true;
        });
      });
    },
    initPriceRule: function initPriceRule(newVal) {
      if (newVal.bid_range_id && newVal.bid_type === 2) {
        if (newVal.bidRange) {
          this.priceData = newVal.bidRange.content;
        }
      } else {
        this.priceData = [{
          min: 0,
          max: '∞',
          price: Number(newVal.plus_price),
          type: 1
        }];
      }
    },
    initPrice: function initPrice(newVal, price) {
      if (this.isEditPrice) return false;
      this.isEditPrice = true;
      // 初始化价格信息
      if (newVal && newVal.product_id) {
        if (price) {
          this.settingPrice(price);
        } else if (newVal.now_price) {
          this.settingPrice(newVal.now_price);
        } else {
          if (newVal.start_price) {
            this.setPrice(newVal.start_price);
          } else {
            this.settingPrice(0);
          }
        }
      }
    },
    // 计算下一口价格
    settingPrice: function settingPrice(price) {
      price = Number(price);
      var area = this.getPriceArea(price);
      var p = 0;
      switch (area.type) {
        case 1:
          p = price + area.price;
          break;
        case 2:
          p = this.getBidMoney258(price);
          break;
        case 3:
          p = this.getBidMoneyThree258(price);
          break;
        default:
          p = this.getBidMoney258(price);
          break;
      }
      this.setPrice(p);
    },
    setPrice: function setPrice(price) {
      this.proxyData.price = price;
      this.isEditPrice = false;
    },
    getPriceArea: function getPriceArea(price) {
      // 获取价格区间
      var data = this.priceData;
      var index = null;
      for (var i in data) {
        var m = data[i];
        if (price >= m.min && price < m.max) {
          index = i;
          break;
        }
      }
      if (index === null) {
        index = data.length - 1;
      }
      return data[index];
    },
    getBidMoney258: function getBidMoney258(price) {
      // 2580价格
      var money = price.toString();
      var numCount = money.length;
      var firstNum = Number(money.substr(0, 1));
      var secondNum = money.substr(1, 1);
      if (numCount <= 2) {
        return 200;
      } else if (numCount <= 3) {
        if (firstNum < 2) {
          return 200;
        } else if (firstNum < 5) {
          return 500;
        } else if (firstNum < 8) {
          return 800;
        } else {
          return 1000;
        }
      }
      if (firstNum && secondNum === 0) {
        secondNum = 2;
      } else {
        if (secondNum < 2) {
          secondNum = 2;
        } else if (secondNum < 5) {
          secondNum = 5;
        } else if (secondNum < 8) {
          secondNum = 8;
        } else {
          firstNum += 1;
          secondNum = 0;
          if ("".concat(firstNum).length > 1) {
            numCount += "".concat(firstNum).length - 1;
          }
        }
      }
      return Number(this.prefixZero("".concat(firstNum).concat(secondNum), numCount));
    },
    getBidMoneyThree258: function getBidMoneyThree258(price) {
      // 3位2580价格
      var money = price.toString();
      var numCount = money.length;
      if (numCount < 5) {
        return this.getBidMoney258(price);
      }
      var firstNum = Number(money.substr(0, 1));
      var secondNum = money.substr(1, 1);
      var threeNum = money.substr(2, 1);
      if (threeNum < 2) {
        threeNum = Number(this.prefixZero(2, numCount - 2));
      } else if (threeNum < 5) {
        threeNum = Number(this.prefixZero(5, numCount - 2));
      } else if (threeNum < 8) {
        threeNum = Number(this.prefixZero(8, numCount - 2));
      } else {
        threeNum = Number(this.prefixZero(1, numCount - 1));
      }
      return Number(this.prefixZero("".concat(firstNum).concat(secondNum), numCount)) + threeNum;
    },
    prefixZero: function prefixZero(num, n) {
      return (num + Array(n).join(0)).slice(0, n);
    }
  }
};