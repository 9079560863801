"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _auction = require("@/api/auction");
var _live = require("@/api/live");
var _index = require("@/utils/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formatDate: _index.parseTime,
      list: null,
      listLoading: true,
      queryParams: {},
      total: 0,
      venue_id: '',
      venue_type: '',
      venue_name: '',
      albumData: {},
      AData: [],
      BData: [],
      CData: [],
      DData: []
    };
  },
  mounted: function mounted() {
    this.venue_id = this.$route.query.vid || '';
    if (this.venue_id) {
      this.queryParams.venue_id = this.venue_id;
    }
    this.venue_type = this.$route.query.vtype || '';
    this.venue_name = this.$route.query.vname || '';
    this.load();
  },
  methods: {
    goPageAction: function goPageAction(page) {
      window.open(page);
    },
    load: function load() {
      var _this = this;
      (0, _auction.liveGroupListApi)(this.queryParams).then(function (res) {
        var list = [];
        res.data.forEach(function (m) {
          m.data.forEach(function (item, i) {
            item.group = m.group;
            if (m.group === 'other') {
              item.merge = 1;
            } else if (i === 0) {
              item.merge = m.data.length;
            } else {
              item.merge = 0;
            }
            list.push(item);
          });
        });
        _this.AData = list.filter(function (item) {
          return item.group == 'A';
        });
        _this.BData = list.filter(function (item) {
          return item.group == 'B';
        });
        _this.CData = list.filter(function (item) {
          return item.group == 'C';
        });
        _this.DData = list.filter(function (item) {
          return item.group == 'D';
        });
        // console.log(this.AData,this.BData,this.CData,this.DData);
        _this.list = list;
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.queryParams.page = page;
      this.load();
    },
    changeStatus: function changeStatus() {
      this.pageChange(1);
    },
    goConsoleAction: function goConsoleAction(item) {
      var _this2 = this;
      (0, _auction.getAlbumLiveGroupStartInfoApi)({
        venue_id: this.venue_id,
        group: item.group
      }).then(function (res) {
        window.open('/merchant/auctionConsole?id=' + res.data.data.album_id + '&v_id=' + _this2.venue_id);
      }).catch(function () {});
    },
    goOneScreen: function goOneScreen(group) {
      (0, _auction.getAlbumLiveGroupStartInfoApi)({
        venue_id: this.venue_id,
        group: group
      }).then(function (res) {
        window.open('/merchant/ledOneScreen?id=' + res.data.data.album_id);
      }).catch(function () {});
    },
    goScreenProjectionAction: function goScreenProjectionAction(group) {
      (0, _auction.getAlbumLiveGroupStartInfoApi)({
        venue_id: this.venue_id,
        group: group
      }).then(function (res) {
        window.open('/merchant/screen_projection?id=' + res.data.data.album_id);
      }).catch(function () {});
    },
    goHostAction: function goHostAction(group) {
      (0, _auction.getAlbumLiveGroupStartInfoApi)({
        venue_id: this.venue_id,
        group: group
      }).then(function (res) {
        window.open('/merchant/auctioneer?id=' + res.data.data.album_id);
      }).catch(function () {});
    },
    goLEDAction: function goLEDAction(group) {
      (0, _auction.getAlbumLiveGroupStartInfoApi)({
        venue_id: this.venue_id,
        group: group
      }).then(function (res) {
        window.open('/merchant/led?id=' + res.data.data.album_id);
      }).catch(function () {});
    },
    goLEDLangAction: function goLEDLangAction(group) {
      (0, _auction.getAlbumLiveGroupStartInfoApi)({
        venue_id: this.venue_id,
        group: group
      }).then(function (res) {
        window.open('/merchant/ledWideScreen?id=' + res.data.data.album_id);
      }).catch(function () {});
    },
    goLEDThereAction: function goLEDThereAction(group, url) {
      (0, _auction.getAlbumLiveGroupStartInfoApi)({
        venue_id: this.venue_id,
        group: group
      }).then(function (res) {
        window.open(url + res.data.data.album_id);
      }).catch(function () {});
    },
    objectSpanMethod: function objectSpanMethod(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (columnIndex > 5) {
        if (row.merge >= 1) {
          return {
            rowspan: row.merge,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    setAlbumGroup: function setAlbumGroup(item) {
      var _this3 = this;
      (0, _auction.updateAlbumLiveGroupApi)({
        album_id: item.album_id,
        group: item.group
      }).then(function (res) {
        _this3.load();
      }).catch(function () {});
    },
    goUpAction: function goUpAction(item) {
      var _this4 = this;
      (0, _auction.updateAlbumLiveGroupOrderApi)({
        album_id: item.album_id,
        order_num: item.group_order_num - 1,
        group: item.group
      }).then(function (res) {
        _this4.load();
      }).catch(function () {});
    },
    goPushLinkAction: function goPushLinkAction(item, key) {
      var _this5 = this;
      (0, _auction.getLiveGroupPushUrlApi)({
        venue_id: this.venue_id,
        group: item.group
      }).then(function (res) {
        if (res.data && res.data.webrtc) {
          _this5.copyText = res.data.webrtc;
          if (key) {
            _this5.copyText = res.data.rtmp;
          }
          var input = document.createElement('input'); // 直接构建input
          input.value = _this5.copyText; // 设置内容
          console.log(input.value);
          document.body.appendChild(input); // 添加临时实例
          input.select(); // 选择实例内容
          document.execCommand('Copy'); // 执行复制
          document.body.removeChild(input); // 删除临时实例
          _this5.$message.success('复制成功');
        } else {
          _this5.$message.error('复制失败');
        }
      }).catch(function () {});
    },
    getLivePushUrlAction: function getLivePushUrlAction(row, key) {
      var _this6 = this;
      (0, _live.getLivePushUrl)(row.live_id).then(function (res) {
        // this.pushData = {
        //   show: true,
        //   data: res.data
        // };
        if (res.data) {
          _this6.copyText = res.data.webrtc;
          if (key === 'LVB') {
            _this6.copyText = res.data.rtmp;
          }
          var input = document.createElement('input'); // 直接构建input
          input.value = _this6.copyText; // 设置内容
          console.log(input.value);
          document.body.appendChild(input); // 添加临时实例
          input.select(); // 选择实例内容
          document.execCommand('Copy'); // 执行复制
          document.body.removeChild(input); // 删除临时实例
          _this6.$message.success('复制成功');
        } else {
          _this6.$message.error('复制失败');
        }
      }).catch(function () {});
    }
  }
};