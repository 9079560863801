var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "posterHead" },
    [
      _c("div", { staticClass: "box" }, [
        _c(
          "div",
          {
            staticClass: "item",
            class: { active: _vm.routeName == "sp" },
            on: {
              click: function ($event) {
                return _vm.onOpenModule("sp")
              },
            },
          },
          [_vm._v("我的视频 " + _vm._s(_vm.headNumber.video_index))]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "item",
            class: { active: _vm.routeName == "spMyTemplate" },
            on: {
              click: function ($event) {
                return _vm.onOpenModule("spMyTemplate")
              },
            },
          },
          [_vm._v("我的模板 " + _vm._s(_vm.headNumber.video_MyTemplate))]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "item",
            class: { active: _vm.routeName == "spTemplate" },
            on: {
              click: function ($event) {
                return _vm.onOpenModule("spTemplate")
              },
            },
          },
          [
            _vm._v(
              "模板广场 " +
                _vm._s(
                  _vm.headNumber.video_template > 1000
                    ? _vm.headNumber.video_template + "+"
                    : _vm.headNumber.video_template
                )
            ),
          ]
        ),
        _vm._v(" "),
        _vm.routeName == "spTemplate" || _vm.routeName == "spTemplate"
          ? _c(
              "div",
              {
                staticClass: "quickly-create l",
                on: {
                  click: function ($event) {
                    return _vm.onOpenModule("spMyTemplate")
                  },
                },
              },
              [
                _c("img", {
                  attrs: { src: require("@/assets/images/article/add.png") },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("定制模版")]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "quickly-create",
            on: {
              click: function ($event) {
                return _vm.onOpenPosterBz()
              },
            },
          },
          [
            _c("img", {
              attrs: {
                src: "https://saas.cdn.yunzongbu.cn/merchant/333425/20241226/image/1735206531762109995.png",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("一键生成")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "zw" }),
      _vm._v(" "),
      _c("createPoster", {
        ref: "createPosterRef",
        on: { onRefresh: _vm.onRefresh },
      }),
      _vm._v(" "),
      _c("createPosterBz", {
        ref: "createPosterBzRef",
        on: { onRefresh: _vm.onRefresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }